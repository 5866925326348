import React, { useEffect, useRef, memo } from 'react';
import useAuth from '@/modules/auth/hooks';
import useOrganization from '../../../../../modules/auth/hooks/organization';
import { EstimationTabs } from '../types';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../store/types';
import { OrderData } from '../../../../../store/order/state';
import { useWidgetBarTabContext } from '../../../../../modules/property/hooks/useWidgetBar';
import useRole from '../../../../../modules/auth/guards/RoleGuard/useRole';
import { UserRole } from '../../../../../modules/common/types';
import { isThisDeviceIpad } from '../../../../../events/constants';

// The global _flutter namespace
declare let _flutter: any;

interface FFEstimationViewProps {
  assetBase?: string;
  src?: string;
  propertyId: number;
  estimateViewId: number;
  linkedTakeoffViewId: number;
  activePage: number;
  serviceItemMeasurementList: any; // TODO: add type
  featureDataList: any; // TODO: add type
  moduleState: string;
  showFlutterApp?: boolean;
  setLastSavedTimeText: (text: string) => void;
  setActiveEstimationTab: (tab: EstimationTabs) => void;
  orderDetail: any;
  userDetail: any;
}

export const FFEstimationView: React.FC<FFEstimationViewProps> = memo(({
  assetBase = '',
  src = 'main.dart.js',
  propertyId,
  estimateViewId,
  linkedTakeoffViewId,
  serviceItemMeasurementList,
  moduleState,
  activePage,
  showFlutterApp,
  setLastSavedTimeText,
  setActiveEstimationTab,
  featureDataList,
  orderDetail,
  userDetail,
}) => {
  const flutterState = useRef<any>(null);
  const ref = useRef<HTMLDivElement>(null);
  const token = localStorage.getItem('sr_token') ? JSON.parse(localStorage.getItem('sr_token')).token : null;
  const { user } = useAuth();
  const { id: workspaceId, avatar: workspaceLogoUrl, title: workspaceTitle } = useOrganization()!;
  const { customerName, address } = useSelector<IStore, OrderData | null>(state => state.order.orderData)!;
  const ENV = process.env.REACT_APP_ENV;
  const { selectedTab } = useWidgetBarTabContext();

  const { role } = useRole();

  const isViewerOrGuest = [UserRole.Viewer, UserRole.Guest].includes(role);

  const onFlutterAppLoaded = (state: any) => {
    flutterState.current = state;

    // Update Flutter state once when loaded
    state.updateIsAppInitialized(true);
    state.updateToken(token);
    state.updatePropertyId(propertyId);
    state.updateEstimateViewId(estimateViewId);
    state.updateLinkedTakeoffViewId(linkedTakeoffViewId);
    state.updateCurrentUserId(user.id);
    state.updateCurrentWorkspaceId(workspaceId);
    state.updateServiceItemMeasurementList(JSON.stringify(serviceItemMeasurementList));
    state.updateFeatureDataList(JSON.stringify(featureDataList));
    state.updateServiceItemMeasurementOldStatus(JSON.stringify({}));
    state.updatePropertyAddress(address);
    state.updateCustomerName(customerName);
    state.updateWorkspaceName(workspaceTitle);
    state.updateWorkspaceLogoUrl(workspaceLogoUrl);
    state.updateActivePage(activePage);
    state.updateEnv(ENV?.toString() === 'prod' ? 'app' : ENV);
    state.updateIsModuleEditable(!isViewerOrGuest);
    state.listenLastSavedTime(time => {
      console.log(">>> actual time: ", time);
      setLastSavedTimeText(time);
    });
    state.listenActivePage(page => {
      if (page === 0) {
        setActiveEstimationTab(EstimationTabs.SERVICE_MAP);
      }
    });
    state.updateOrderDetails(JSON.stringify(orderDetail));
    state.updateUserDetails(JSON.stringify(userDetail));
  };

  useEffect(() => {
    const target = ref.current;
    let engineInitializer;
    let appRunner;

    const initFlutterApp = async () => {
      engineInitializer = await new Promise<any>((resolve) => {
        _flutter.loader.loadEntrypoint({
          entrypointUrl: src,
          onEntrypointLoaded: resolve,
        });
      });

      console.log('initialize Flutter engine...');
      appRunner = await engineInitializer?.initializeEngine({
        hostElement: target,
        assetBase: assetBase,
      });

      console.log('run Flutter engine...');
      await appRunner?.runApp();
      // Save the appRunner for later cleanup
      flutterState.current = appRunner;
    };

    initFlutterApp();

    const eventListener = (event: Event) => {
      let state = (event as CustomEvent).detail;
      console.log('Flutter app initialized:', state);
      onFlutterAppLoaded(state);
    };

    target?.addEventListener('flutter-initialized', eventListener, { once: true });

    return () => {
      // Remove the event listener
      target?.removeEventListener('flutter-initialized', eventListener);

      // Cleanup Flutter app by removing the host element and resetting state
      if (flutterState.current) {
        console.log('Cleaning up Flutter app...');
        if (target && target.parentNode) {
          target.parentNode.removeChild(target);
        }
        flutterState.current = null;
      }

      // Ensure other state cleanup if necessary
      if (engineInitializer) {
        engineInitializer = null;
      }
      if (appRunner) {
        appRunner = null;
      }
    };
  }, [assetBase, src]);


  useEffect(() => {
    return () => {
      flutterState.current?.updateActivePage(0);
    };
  }, []);

  useEffect(() => {
    flutterState.current?.updateActivePage(0);
  }, [selectedTab]);

  useEffect(() => {
    flutterState.current?.updateActivePage(activePage);
  }, [activePage]);

  useEffect(() => {
    if (flutterState.current?.updateServiceItemMeasurementList) {
      flutterState.current.updateServiceItemMeasurementList(JSON.stringify(serviceItemMeasurementList));
    } else {
      console.warn('Flutter state is not ready to update service item measurement list.');
    }
  }, [serviceItemMeasurementList, flutterState.current]);

  useEffect(() => {
    if (flutterState.current?.updatePropertyId) {
      flutterState.current?.updatePropertyId(propertyId);
    } else {
      console.warn('Flutter state is not ready to update property id.');
    }
  }, [propertyId, flutterState.current]);

  useEffect(() => {
    if (flutterState.current?.updateEstimateViewId) {
      flutterState.current?.updateEstimateViewId(estimateViewId);
    } else {
      console.warn('Flutter state is not ready to update estimate view id.');
    }
  }, [estimateViewId, flutterState.current]);

  useEffect(() => {
    if (flutterState.current?.updateLinkedTakeoffViewId) {
      flutterState.current?.updateLinkedTakeoffViewId(linkedTakeoffViewId);
    } else {
      console.warn('Flutter state is not ready to update linked take off view id.');
    }
  }, [linkedTakeoffViewId, flutterState.current]);

  return (
    <div
      ref={ref}
      style={{
        display: showFlutterApp ? isThisDeviceIpad ? 'block' : 'flex' : 'none',
        zIndex: 99,
        position: activePage === 2 ? 'fixed' : 'static',
        top: activePage === 2 ? 50 : 'auto',
        right: activePage === 2 ? 0 : 'auto',
        bottom: activePage === 2 ? 0 : 'auto',
        left: activePage === 2 ? 56 : 'auto',
        flex: isThisDeviceIpad ? 'unset' : 1,
        height: ((activePage === 0 || activePage === 1) && isThisDeviceIpad) ? 'calc(100vh - 250px)' : activePage === 2 ? 'calc(100vh - 50px)' : 'calc(100vh - 250px)',
      }}
    ></div>
  );
});
