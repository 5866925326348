import { Modal, Row, Upload } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotifyError } from '@/helpers/notification-utils';
import { useHistory } from 'react-router-dom';
import {
  isBulkOrderSupportedFileType,
  supportedBulkOrderFileTypes,
} from '../../../../helpers/utilities';
import {
  cancelAxiosToken,
  uploadBulkOrderCSV,
} from '@/components/api/bulkProject';
import { closeBulkOrderDetailsModal } from '../../../../store/bulkOrder/actions';
import Text from '../../../elements/text/Text';
import { TAB_CATEGORY } from '../../../pages/myProperties/utils/enum';
import BulkProjectErrorList from '../../bulk-project-error-list/BulkProjectErrorList';
import { clearOrderArray } from './../../../../store/dashboard/action';
import SuccessUploadModal from './../../bulk-project-error-list/components/successModal/SuccessUploadModal';
import uploadImage from './assets/uploadIcon.svg';
import ProcessingFile from './components/processionFile/ProcessingFile';
import './style.less';
import { FileUploadStatus } from './UploadStatus';
import { showNotification } from '../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../storybook/NotificationToast/types';

const { Dragger } = Upload;

const UploadFile = ({
  visible, // Show Upload If Visible true
  onCancel, // Will Hide Upload Card
  bulkOrderId,
  isCSVFileUpdating = false,
  setVisible, // Will Hide Upload Card
  resetAllBulk = false,
  ...props
}) => {
  const [CSVFileUpdating, setCSVFileUpdating] = useState(false);
  const [typeOfModal, setTypeOfModal] = useState(FileUploadStatus.uploadFile);
  const [dragOver, setDragOver] = useState(false);
  const [errorListData, setErrorListData] = useState(null);
  const [successListData, setSuccessListData] = useState(null);
  const [filesData, setFileData] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [successLoading, setSuccessLoading] = useState(false);
  const [processionErrorMessage, setProcessionErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const progressEventHandler = useCallback((event) => {
    setUploadPercentage(+event);
  }, []);

  const addMoreBulkOrderDetailsModalVisible = useSelector(
    (state) => state.bulkOrder.addMoreBulkOrderDetailsModalVisible
  );
  // const updateResponseDataKey = useCallback((data) => {
  //   // This Is Only To Convert Response Data Key From Caps To Lowercase
  //   return data.reduce((updateValue, value) => {
  //     let temp = {};
  //     for (let obj in value) temp[obj.toLowerCase()] = value[obj];
  //     return [...updateValue, temp];
  //   }, []);
  // }, []);

  const uploadBulkOrderCSVFile = useCallback(
    async (file) => {
      if (!isBulkOrderSupportedFileType(file.name)) {
        NotifyError('Please upload a CSV or XLSX file');
        return;
      }

      setDragOver(false);
      setFileData(file);
      setSuccessLoading(false);

      setTypeOfModal(FileUploadStatus.processingFile);
      // setTimeout(() => {
      //   setTypeOfModal(FileUploadStatus.processingFile);
      // }, 200);
      const bulkOrderCSVFormData = new FormData();
      bulkOrderCSVFormData.append('bo_file', file);
      bulkOrderCSVFormData.append('is_upload', !CSVFileUpdating);
      try {
        setUploadPercentage(0);
        const response = await uploadBulkOrderCSV(
          bulkOrderId,
          bulkOrderCSVFormData,
          progressEventHandler
        );
        const { data } = response;
        if (data?.error_count <= 0) {
          setSuccessListData(data);
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            'Your file is uploaded successfully. We will notify you after processing your order.'
          );
          setTypeOfModal(FileUploadStatus.uploadingCompleted);
          setVisible(false);
        } else if (data?.error_count > 0) {
          setErrorListData(data);
          setTypeOfModal(FileUploadStatus.uploadingPartiallyFailed);
          setVisible(false);
        } else {
          setTypeOfModal(FileUploadStatus.uploadingFailed);
        }
        setSuccessLoading(false);
      } catch (error) {
        setSuccessLoading(false);
        if (error?.error_count > 0) {
          setErrorListData(error);
          setTypeOfModal(FileUploadStatus.uploadingPartiallyFailed);
        } else {
          setTypeOfModal(FileUploadStatus.uploadingFailed);
        }
      }
    },
    [CSVFileUpdating, bulkOrderId, progressEventHandler, setVisible]
  );

  const handleClose = useCallback(() => {
    if (addMoreBulkOrderDetailsModalVisible) {
      window.location.reload();
    }
    onCancel();
    setTypeOfModal(FileUploadStatus.uploadFile);
    dispatch(closeBulkOrderDetailsModal());
    if (resetAllBulk) {
      dispatch(
        clearOrderArray({
          tab: TAB_CATEGORY.bulk,
        })
      );
      history.push({
        search: `tab=${TAB_CATEGORY.bulk}`,
      });
    }
  }, [
    onCancel,
    dispatch,
    resetAllBulk,
    history,
    addMoreBulkOrderDetailsModalVisible,
  ]);

  const handleDragLeave = useCallback(() => {
    setDragOver(false);
  }, []);

  const handleDragOver = useCallback(() => {
    setDragOver(true);
  }, []);

  useEffect(() => {
    return () => {
      setTypeOfModal(FileUploadStatus.uploadFile);
    };
  }, []);

  useEffect(() => {
    if (isCSVFileUpdating) {
      setCSVFileUpdating(true);
    }

    return () => {
      setUploadPercentage(0);
    };
  }, [isCSVFileUpdating]);

  return (
    <>
      <Modal
        visible={visible}
        onCancel={handleClose}
        footer={null}
        className='uploadCsvModal'
        closable={true}
        centered
        width={'450px'}
      >
        <Row
          className='uploadCsvModal__body'
          style={{ flexDirection: 'column' }}
        >
          <Row
            align='middle'
            style={{ flexDirection: 'column' }}
            className='mb-3'
          >
            <Text type='p36' color={'light-gray'}>
              Step{' '}
              {typeOfModal === FileUploadStatus.uploadFile
                ? '2/5'
                : typeOfModal === FileUploadStatus.processingFile
                ? '2/5'
                : typeOfModal === FileUploadStatus.uploadingPartiallyFailed
                ? '2/5'
                : '3/5'}
            </Text>
            <Text type='h41'>Upload Excel File</Text>
          </Row>

          {typeOfModal === FileUploadStatus.uploadFile && (
            <Row
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              className={
                dragOver
                  ? 'uploadCsvModal__dropHere px-8 drag--over '
                  : 'uploadCsvModal__dropHere px-8'
              }
            >
              <Dragger
                className='droppable'
                style={{ height: '40px' }}
                beforeUpload={uploadBulkOrderCSVFile}
                accept={supportedBulkOrderFileTypes.join(',')}
                showUploadList={false}
              >
                <Row
                  className='uploadCsvModal__dropHere__body w-100'
                  justify='center'
                  align='middle'
                  style={{ flexDirection: 'column' }}
                >
                  <img src={uploadImage} alt='uploadImage' className='mb-4' />
                  <Row
                    style={{ width: '260px' }}
                    align='middle'
                    justify='center'
                  >
                    <Text type='p23' color='gray-color'>
                      Click to browse or drag and drop your files.
                      <br />
                      (Supported Files - .xls, .xlsx, .csv)
                    </Text>
                  </Row>
                </Row>
              </Dragger>
            </Row>
          )}

          {(typeOfModal === FileUploadStatus.processingFile ||
            typeOfModal === FileUploadStatus.uploadingFailed) && (
            <ProcessingFile
              typeOfModal={typeOfModal}
              uploadPercentage={uploadPercentage}
              setUploadPercentage={setUploadPercentage}
              uploadBulkOrderCSVFile={uploadBulkOrderCSVFile}
              filesData={filesData}
              setTypeOfModal={setTypeOfModal}
              cancelAxiosToken={cancelAxiosToken}
              errorMessage={processionErrorMessage}
            />
          )}

          {typeOfModal === FileUploadStatus.uploadingPartiallyFailed && (
            <BulkProjectErrorList
              errorBulkOrderDetails={errorListData}
              setTypeOfModal={setTypeOfModal}
              setCSVFileUpdating={setCSVFileUpdating}
              closeUploadModal={handleClose}
              setVisible={setVisible}
              bulkOrderId={bulkOrderId}
              uploadPercentage={uploadPercentage}
              setUploadPercentage={setUploadPercentage}
              {...props}
            />
          )}

          {typeOfModal === FileUploadStatus.uploadFile && (
            <Row align='middle' justify='center' className='mb-3'>
              <a
                rel='noreferrer'
                href='https://docs.google.com/spreadsheets/d/1IVezWQsZW9kiugxZqIR2ETt_55IMPMLGz1OOH9hgY8w/edit#gid=0'
                target='_blank'
                download
              >
                <Text
                  type='h9'
                  color='dark-gray'
                  style={{ textDecoration: 'underline' }}
                >
                  Download Sample sheet
                </Text>
              </a>
            </Row>
          )}
        </Row>

        {/* <div
        className='cancel-button-upload'
      >
        {!typeOfModal === FileUploadStatus.uploadFile && (
          <Text type='h8' color='dark-gray'>
            Cancel
          </Text>
        )}
        {typeOfModal === FileUploadStatus.uploadFile && (
          <a href='/sample_bulk_order_sheet.csv' download>
            <Text type='h8' color='dark-gray'>
              Download a sample format
            </Text>
          </a>
        )}
      </div> */}
        {/* <ImportantInstructions /> */}
      </Modal>

      {typeOfModal === FileUploadStatus.uploadingCompleted && (
        <SuccessUploadModal
          successBulkDetails={successListData}
          setTypeOfModal={setTypeOfModal}
          closeUploadModal={handleClose}
          setVisible={setVisible}
          loading={successLoading}
          bulkOrderId={bulkOrderId}
          {...props}
        />
      )}
    </>
  );
};

export default UploadFile;
