// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Subtract7IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Subtract7Icon(props: Subtract7IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 7 7"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M3.084 0A3.084 3.084 0 000 3.084V6.32h6.167V3.084A3.084 3.084 0 003.084 0zm0 4.543c.745 0 1.349-.619 1.349-1.383 0-.763-.604-1.382-1.35-1.382-.745 0-1.349.619-1.349 1.382 0 .764.604 1.383 1.35 1.383z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Subtract7Icon;
/* prettier-ignore-end */
