import { UserRole, UserRoleId } from '@/modules/common/types';

const RoleMap = {
  [UserRoleId.Admin]: UserRole.Admin,
  [UserRoleId.Creator]: UserRole.Creator,
  [UserRoleId.Editor]: UserRole.Editor,
  [UserRoleId.Viewer]: UserRole.Viewer,
  [UserRoleId.Guest]: UserRole.Guest,
};

export const roleById = (id: UserRoleId): UserRole | null => {
  return RoleMap[id] || null;
};
