import React from 'react';
import EodTakeoffPricingStatus from '../../../../PlasmicComponents/EodTakeoffPricingStatus';
import { getEstimationTimeRemainingInMinutes } from '../helpers';
import useFormattedMinutesTimer from '../hooks/useFormattedMinutesTimer';

// Functional component that calculates and passes the timer text
const EODPricingStatusWrapper = ({ startMinutes, orderTileData = {}, ...props }) => {
  const formattedTime = useFormattedMinutesTimer(orderTileData);

  return (
    <EodTakeoffPricingStatus
      timerText={formattedTime}
      orderStatus={props.orderStatus}
      feedbackCount={props.feedbackCount}
      onClick={props.onClick}
      boltInVerificationTimer={props.boltInVerificationTimer}
      feedbackType={props.feedbackType}
      takeoffDone={props.takeoffDone}
      pricingDone={props.pricingDone}
      withoutChevron={props.withoutChevron}
    />
  );
};

export default EODPricingStatusWrapper;
