import * as turf from '@turf/turf';
import { GeoJSON } from 'ol/format';
import { Draw } from 'ol/interaction';
import { Vector as VectorSource } from 'ol/source';
import { displayComponentName } from '../featuresUtils';

import moment from 'moment';
import { DRAW_STYLE_MODE, drawInteractionStyle, drawPathStyleFunction } from '../../mapGlobals/styles';
import { addCrossLayerSnap, resetCrossLayerSnap } from './snapping';

let geometryType;
let parcelLayer;
let drawInteraction;

export const drawInteractionActive = (
  sourceLayer,
  mapRef,
  type,
  callback,
  toolType,
  pathStyle = null,
  allLayerRefsObj,
  hasToolStart,
  args
) => {
  parcelLayer = sourceLayer;
  geometryType = type;
  if (parcelLayer && type) {
    if (toolType === 'path') {
      parcelLayer.setStyle(
        drawPathStyleFunction(parcelLayer.getSource().getFeatures(), pathStyle)
      );
    } else {
      parcelLayer.setStyle(
        DRAW_STYLE_MODE(type, parcelLayer.getStyle(), pathStyle)
      );
    }
  }

  if (drawInteraction) {
    mapRef.removeInteraction(drawInteraction);
    resetCrossLayerSnap(mapRef);
  }

  drawInteraction = new Draw({
    source: parcelLayer?.getSource(),
    type: type,
    condition: function (e) {
      if (e.originalEvent.buttons === 1) {
        return true;
      } else {
        return false;
      }
    },
    style: drawInteractionStyle,
  });

  mapRef.addInteraction(drawInteraction);
  addCrossLayerSnap(mapRef, allLayerRefsObj);
  //This function will be used to maintain Point level history
  args.interactionsDrawingPointLevelTracking(drawInteraction);
  onDrawStart(drawInteraction, hasToolStart);
  onDrawEnd(
    drawInteraction,
    mapRef,
    callback,
    type,
    toolType,
    pathStyle,
    allLayerRefsObj,
    hasToolStart,
    args
  );
};

export const removeDrawInteraction = (mapRef) => {
  if (drawInteraction) {
    mapRef.getInteractions().forEach((interaction) => {
      if (interaction === drawInteraction) {
        mapRef.removeInteraction(drawInteraction);
      }
    });
  }
};

const onDrawStart = (drawInteraction, hasToolStart) => {
  drawInteraction.on('drawstart', (event) => {
    hasToolStart(true);
  });
};

const onDrawEnd = (
  drawInteraction,
  mapRef,
  callback,
  type,
  toolType,
  pathStyle,
  allLayerRefsObj,
  hasToolStart,
  args
) => {
  drawInteraction.on('drawend', (event) => {
    let timeStamp = moment();
    let drawingAllowed = true;
    let geojson_geom = new GeoJSON();
    let drawGeometry = event.feature.getGeometry().clone();
    let drawCoordinates = geojson_geom.writeGeometry(
      drawGeometry.transform('EPSG:3857', 'EPSG:4326')
    );
    let drawnPolygon = JSON.parse(drawCoordinates);


    if (drawingAllowed) {
      /** Passed All tests */
      let tempId = `temp_${Math.random()}`;
      let nextId = parcelLayer.getSource().getFeatures().length;
      let totalFeature = parcelLayer?.getSource()?.getFeatures();
      let reqFeature = turf.feature(drawnPolygon, {
        temp_id: tempId,
        componentIndexing: displayComponentName(nextId + 1),
      });
      event.feature.setProperties({
        temp_id: tempId,
        componentIndexing: displayComponentName(nextId + 1),
      });
      totalFeature.push(event.feature);
      if (toolType === 'path') {
        parcelLayer.setStyle(drawPathStyleFunction(totalFeature, pathStyle));
      }
      let reqData = {
        geoJson: reqFeature,
        timeStamp,
      };
      if (callback) {
        callback('drawInteraction', reqData, tempId);
        hasToolStart(false);
        return;
      }
    } else {
      mapRef.removeLayer(parcelLayer);
      let listOfPolygons = [];
      parcelLayer
        .getSource()
        .getFeatures()
        .forEach((feature) => {
          geojson_geom = new GeoJSON();
          let geom = feature['values_']['geometry'].clone();
          let coord = geojson_geom.writeGeometry(
            geom.transform('EPSG:3857', 'EPSG:4326')
          );
          let parcelPolygon = JSON.parse(coord);
          let polygon = turf.feature(parcelPolygon, {
            ...feature.getProperties(),
          });
          listOfPolygons.push(polygon);
        });
      let featureCollection = turf.featureCollection(listOfPolygons);
      let vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(featureCollection, {
          featureProjection: 'EPSG:3857',
        }),
        crossOrigin: 'Anonymous',
      });
      parcelLayer.setSource(vectorSource);
      parcelLayer.setStyle(
        DRAW_STYLE_MODE(type, parcelLayer.getStyle(), pathStyle)
      );
      mapRef.addLayer(parcelLayer);
      drawInteractionActive(
        parcelLayer,
        mapRef,
        type,
        callback,
        toolType,
        pathStyle,
        allLayerRefsObj,
        hasToolStart,
        args
      );
      return;
    }
  });
};
