import React, { useEffect, useState } from 'react';
import { Strings } from '../../utils/strings';
import { Link } from 'react-router-dom';
import CountdownTimer from '../CountdownTimer';
import './style.less';
import '@/styles/base/utils.less';

import { ReactComponent as DelayedIcon } from '../../assets/delayed.svg';
import { addPluralSuffix } from '@project/utils/helpers';
import { Tooltip } from 'antd';
import { trackEvents } from '../../../../../helpers/utilities';

type SingleQueueCardProps = {
  orderId: string | number;
  address: string;
  city: string;
  state: string;
  author: string;
  orderTime: Date | string;
  containerStyle: React.CSSProperties;
  icon: any;
  estimatedDeliveryTime: Date | string;
  orderHashed: string;
  showOrderTime: boolean;
  estimatedStartTime: Date | string;
};
const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;
const MICROSECONDS_PER_SECOND = 1000;

const SingleQueueCard = ({
  orderId,
  orderHashed,
  address,
  city,
  state,
  author,
  orderTime,
  containerStyle,
  icon,
  estimatedDeliveryTime,
  estimatedStartTime,
  showOrderTime,
}: SingleQueueCardProps) => {
  const [isDelayed, setIsDelayed] = useState<boolean>(false);
  const startTime: Date = new Date(estimatedStartTime);
  const deliveryTime: Date = new Date(estimatedDeliveryTime);

  const time = deliveryTime.getTime() - startTime.getTime();
  const hours = Math.floor(time / (SECONDS_PER_HOUR * MICROSECONDS_PER_SECOND));

  const timeNow: string | Date = new Date().toISOString();
  useEffect(() => {
    if (timeNow > estimatedDeliveryTime) {
      setIsDelayed(true);
    } else {
      setIsDelayed(false);
    }
  }, []);

  return (
    <>
      <Link
        to={`/project/${orderHashed}`}
        target='_blank'
        rel='noopener noreferrer'
        onClick={() => trackEvents('order-queue__queue_card_clicked')}
      >
        <div
          className='queue-card rounded-4 '
          style={
            isDelayed && showOrderTime
              ? { backgroundColor: '#fff' }
              : { ...containerStyle }
          }
        >
          <div className='queue-card-inner d-flex d-row justify-between '>
            <div className='left-container'>
              <p className='order-id'>
                {Strings.OrderID} <span>{orderId}</span>
              </p>
              <Tooltip title={address}>
                <p className='address'>{address}</p>
              </Tooltip>
              <div className='flex-container'>
                <div className='created-by'>
                  {city}
                  {city && <>,</>} {state}
                </div>
              </div>
              <div className='flex-container' style={{ marginTop: '2px' }}>
                <div className='created-by'>
                  {Strings.CreatedBy} <span>{author}</span>
                </div>

                {showOrderTime && (
                  <>
                    <div className='dot'></div>
                    <div className='order-time'>
                      {Strings.OrderTime}{' '}
                      <span>
                        {hours}
                        {addPluralSuffix(hours, 'hr')}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='right-container'>
              <div className='icon-container'>
                {isDelayed && showOrderTime ? <DelayedIcon /> : icon}
              </div>
              <div className='time-container'>
                {showOrderTime && estimatedDeliveryTime && (
                  <CountdownTimer
                    endsAt={estimatedDeliveryTime}
                    delayedText='Delayed'
                    textStyle={{ color: '#dc2a2a' }}
                  />
                )}
              </div>
            </div>
          </div>
          {isDelayed && showOrderTime && (
            <>
              <div className='delayed-section bg-lightred'>
                <p className='fw-400 text-9 leading-12 text-warning'>
                  This order is taking longer than expected. We will deliver it
                  as soon as possible.
                </p>
              </div>
            </>
          )}
        </div>
      </Link>
    </>
  );
};

export default SingleQueueCard;
