export enum SubscriptionPlan {
  TRIAL = 'trial',
  ESSENTIAL = 'essential',
  PROFESSIONAL = 'professional',
  TEAMS = 'teams',
  ENTERPRISE = 'enterprise',
  LEGACY_PRO = 'legacy-professional',
  LEGACY_ADV = 'legacy-advanced',
}

export enum SubscriptionIntervalType {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum SubscriptionStatus {
  DRAFT = 'draft',
  ONGOING = 'ongoing',
  CANCELLED = 'cancelled',
  ON_HOLD = 'on-hold',
  PAST_DUE = 'past-due',
  FINISHED = 'finished',
}

export enum SubscriptionUpdateType {
  SEAT = 'seat',
  CREDIT = 'credit',
  INTERVAL = 'interval',
  BASE_PACKAGE = 'base_package',
}

export enum SubscriptionAddOnType {
  SEAT = 'seat',
  CREDIT = 'credit',
}

export enum SubscriptionChangeType {
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
}

export enum SubscriptionPaymentStatus {
  DRAFT = 'draft',
  SUCCESS = 'success',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export enum SubscriptionPaymentType {
  INITIAL = 'initial',
  RECURRING = 'recurring',
}

export enum PayGInvoiceStatus {
  PAID = 'paid',
  OPEN = 'open',
}

export enum PayGStatus {
  INITIAL = 'initial',
  PAYMENT_PROCESSING = 'processing',
  PAYMENT_SUCCESSFUL = 'successful',
  FAILED_PAYMENT_METHOD = 'failed_payment_method',
  PAYMENT_FAILED = 'failed',
  INVOICE_PROCESSING = 'invoice_processing',
}

export enum SubscriptionState {
  /** Professional Plan */
  PROFESSIONAL_ONGOING = 'professional-ongoing',

  /** Teams Plan */
  TEAMS_ONGOING = 'teams-ongoing',

  /** Enterprise Plan */
  ENTERPRISE_ONGOING = 'enterprise-ongoing',

  /** Trial Plan with X days left */
  TRIAL_ONGOING = 'trial-ongoing',

  /** Trial Expired */
  TRIAL_EXPIRED = 'trial-expired',

  /** Professional / Teams plan with payment failed but user is still on plan */
  PAYMENT_FAILED = 'payment-failed',

  /** Essential plan after the payment failed and workspace is auto-downgraded */
  AUTO_DOWNGRADED = 'auto-downgraded',

  /** After migration old essential users moved from v1 to v2 */
  MIGRATED_WORKSPACE = 'migrated-workspace',

  /** User has cancelled the subscription */
  PLAN_CANCELLED = 'plan-cancelled',

  /** User is on legacy professional plan */
  LEGACY_PROFESSIONAL = 'legacy-professional',

  /** User is on legacy advanced plan */
  LEGACY_ADVANCED = 'legacy-advanced',
}

export enum PlanChangeDescription {
  EnterpriseRequestCallback = 'enterprise_request_callback',
  TeamsRequestCallback = 'teams_request_callback',
  RequestAdminCallbackTeamsToEnterprise = 'request_admin_callback_teams_to_enterprise',
  RequestAdminTrialExpiredToProfessional = 'request_admin_trialexpired_to_professional',
  RequestAdminTrialToTeams = 'request_admin_trial_to_teams',
  RequestAdminProfessionalToTeams = 'request_admin_professional_to_teams',
  RequestAdminLegacyProfessionalToTeams = 'request_admin_legacy_professional_to_teams',
  LegacyAdvancedRequestCallback = 'legacy_advanced_request_callback',
  RequestAdminCallbackLegacyAdvancedToEnterprise = 'request_admin_callback_legacy_advanced_to_enterprise'
}
