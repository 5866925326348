import { IObject, ISODate, ISOTimestamp } from '@common/types';
import { TimelineMetadata } from './useNearmapSelectionDropdownPosition';
import { Coordinate } from 'ol/coordinate';

export type RenderNearmapCallback = (
  surveyId: string,
  date: string | null,
  viewType: ViewOption,

  /**
   * If `true`, nearmap would be rendered on the full page rather than around any parcel. Defaults to `false`
   * Use case : When there are no parcel available, we need to show LOW res. imagery to full screen
   **/
  fullMap?: boolean
) => void;

type OnModifySuccessCallback = () => void;

export interface NearmapObliqueSelectionDDProps {
  orderId: number;
  parcelArea: number;
  renderNearmap: RenderNearmapCallback;
  timelineMetadata: TimelineMetadata;
  defaultDate?: string;

  onModifySuccess?: OnModifySuccessCallback;

  /** Parcel Json for the property */
  parcelJson: string;

  /** Nearmap Parcel Version (Used to re-render the nearmap when parcel is being modified) */
  parcelVersion?: number;

  /** Center of property, if parcel geoJson is not available, this would be used for coverage API  */
  center?: Coordinate;

  /** Current parcel which is being modified */
  draftParcel?: IObject;

  propertyState?: string;
}

export interface NearmapApiSurveyLocation {
  country: string;
  region: string;
  state: string;
}

export interface NearmapApiSurveyTile {
  id: string;
  type: ObliqueType;
  scale: number;
}

export interface NearmapApiSurveyResources {
  tiles: NearmapApiSurveyTile[];
}

export interface NearmapApiSurvey {
  location: NearmapApiSurveyLocation;
  resources: NearmapApiSurveyResources;
  tags: any;
  captureDate: ISODate;
  captureDateTime: ISOTimestamp;
  firstPhotoTime: ISOTimestamp;
  id: string;
  lastPhotoTime: ISOTimestamp;
  onlineTime: ISOTimestamp;
  pixelSize: number;
  timezone: string;
  utcOffset: number;
}

export interface NearmapPricingItem {
  limitStart: number;
  limitEnd: number;
  costPerAcre: number;
}

export interface NearmapCoverageApiResponse {
  limit: number;
  offset: number;
  total: number;
  surveys: NearmapApiSurvey[];
  pricing: NearmapPricingItem[];
}

export interface NearmapSurvey {
  captureDate: Date;
  surveyId: string;
  obliques: NearmapSurveyOblique[];
}

export type NearmapSurveyOblique = NearmapApiSurveyTile;

export enum ObliqueTypeAngle {
  TOP = 'Vert',
  SIDE = 'Panorama',
}

export enum ObliqueType {
  VERT = 'Vert',
  NORTH = 'North',
  SOUTH = 'South',
  EAST = 'East',
  WEST = 'West',
}

export type NearmapOption = {
  id: number | string;
  nearMapTitle: string;
  nearMapDate: ISODate;
  viewOptions: Pick<NearmapSelectionWidgetViewOptions, 'isSideViewAvailable'>;
};

export type NearmapIntegrationOption = NearmapOption;

export enum ViewOption {
  TOP = 'Top',
  NORTH = 'North',
  EAST = 'East',
  SOUTH = 'South',
  WEST = 'West',
}

export interface ViewSelectionPopoverProps {
  isSideViewAvailable: boolean;
  selectedViewOption: ViewOption;
  onViewOptionChange: (viewOption: ViewOption) => void;
  viewPopoverVisible: boolean;
  setViewPopoverVisible: (visible: boolean) => void;
}

export interface NearmapSelectionWidgetViewOptions
  extends Pick<
    ViewSelectionPopoverProps,
    'isSideViewAvailable' | 'selectedViewOption' | 'onViewOptionChange'
  > {
  viewOptions?: ViewOption[];
}

export interface InsufficientBalanceProps {
  onCancel: () => void;
  onAddCredit: () => void;
  creditsRequired?: number;
  orderId: number;
  workspaceBalance: number;
}
