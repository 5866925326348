import { NotifyError } from '@/helpers/notification-utils';
import { logout } from '../../helpers/auth';

import {
  changePasswordFailure,
  changePasswordRequest,
  changePasswordSuccess,
  getAvatarImagesFailure,
  getAvatarImagesRequest,
  getAvatarImagesSuccess,
  getJobTitleFailure,
  getJobTitleRequest,
  getJobTitlesuccess,
  getUserDetail,
  isPaaswordTrueSuccess,
  loginByLinkFailure,
  loginByLinkRequest,
  loginByLinkSuccess,
  otpGenerationFailure,
  otpGenerationRequest,
  otpGenerationSuccess,
  otpVerificationFailure,
  otpVerificationRequest,
  otpVerificationSuccess,
  setMailStatus,
  setOptInfo,
  setTrialPopupAsSeen,
  setUserJobTitle,
  switchWorkspace,
  updateActiveState,
  updateJobTitleFailure,
  updateJobTitleRequest,
  updateJobTitlesuccess,
  updateUserCredits,
  userLoginFailure,
  userLoginRequest,
  userLoginSuccess,
  userSignUpFailure,
  userSignUpRequest,
  userSignUpSuccess,
  userTitleSetFailure,
  userTitleSetRequest,
  userTitleSetSuccess,
  userUpdateFailure,
  userUpdateRequest,
  userUpdateSuccess,
} from './actions';

import { authenticate, userAuthenticate, userInfo } from '@/helpers/auth';
import { identifyEvent, trackEvents } from '@/helpers/utilities';
import { getOrgListThunk } from '../teamManagement/thunk';
import { catchError, getAxiosInstance } from '@/helpers/utilities/api-utils';
import { HttpStatus } from '@/modules/common/types';
import { showNotification } from '../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../components/storybook/NotificationToast/types';
import { getWorkspaces } from '@/store/workspace/thunk';
import { dismissTrialPopup as dismissTrialPopupApi } from '@/modules/subscription/api';

const axiosInstance = getAxiosInstance();

export const userLoginThunk = (payload) => (dispatch) => {
  dispatch(userLoginRequest());
  let bodyFormData = new FormData();
  if (payload.googleToken) {
    bodyFormData.append('googleToken', payload.googleToken);
  } else if (payload.microsoft) {
    bodyFormData.append('microsoftToken', payload.microsoft.microsoftToken);
    bodyFormData.append('microsoftJwt', payload.microsoft.microsoftJwt);
  } else {
    bodyFormData.append('email', payload.email);
    bodyFormData.append('password', payload.password);
  }

  axiosInstance
    .post('/api/user/login/', bodyFormData)
    .then(async (response) => {
      if (response.status === 200) {
        let result = response.data;
        if (result.status === 200) {
          authenticate({
            token: result.token,
          });
          userInfo({ user: result.user });
          dispatch(
            userLoginSuccess({
              new_user: result.new_user,
              reqId: result.req_id,
              organisation: result.organization_name,
              invited_by: result.invited_by,
            })
          );
          identifyEvent(result.user.email, {
            dbUserId: result.user.id.toString(),
            name: result.user.first_name,
            last_login: result.user.last_login,
            signup_date: result.user.date_joined,
          });
          trackEvents('login_submit__successful', {
            name: result.user.first_name,
            credits: result.user.totalcredits,
            orgId: result.user.organization_id,
            role: result.user.user_role_id,
          });
          dispatch(getUserDetailThunk(payload.reqId));
          dispatch(getOrgListThunk(undefined, true));
          dispatch(getWorkspaces());
        } else if (result.status === 410) {
          NotifyError(result.error);
          dispatch(userLoginFailure(result.error));
          dispatch(
            setOptInfo({ email: result.email, reset_key: result.reset_key })
          );
          dispatch(updateActiveState('otp'));
        } else {
          NotifyError(result.error);
          dispatch(userLoginFailure(result.error));
        }
      }
    })
    .catch((errors) => {
      if (errors?.status === 410) {
        NotifyError(errors.error);
        dispatch(userLoginFailure(errors.error));
        dispatch(
          setOptInfo({ email: errors?.email, reset_key: errors?.reset_key })
        );
        dispatch(updateActiveState('otp'));
      } else {
        dispatch(userLoginFailure(errors?.error));
        NotifyError(errors?.error);
        trackEvents('login__submit_failure', { email: payload?.email });
      }
    });
};

export const userSignUpThunk = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(userSignUpRequest());
    let bodyFormData = new FormData();

    if (payload.googleToken) {
      bodyFormData.append('googleToken', payload.googleToken);
    } else if (payload.microsoft) {
      bodyFormData.append('microsoftToken', payload.microsoft.microsoftToken);
      bodyFormData.append('microsoftJwt', payload.microsoft.microsoftJwt);
    } else {
      bodyFormData.append('first_name', payload.name);
      bodyFormData.append('email', payload.email);
      bodyFormData.append('password', payload.password);
      bodyFormData.append('contact_number', payload.mobileNumber);
      bodyFormData.append('reset_key', payload?.reset_key);
    }

    // in case of google or microsoft verification,process for login and signup is the same
    const api =
      payload.googleToken || payload.microsoft
        ? '/api/user/login/'
        : '/api/user/';

    axiosInstance
      .post(api, bodyFormData)
      .then((response) => {
        if (response.status === 200) {
          let result = response.data;
          if (result.status === 200) {
            authenticate({
              token: result.token,
            });
            userInfo({ user: result.user });
            dispatch(
              userLoginSuccess({
                new_user: result.new_user,
                reqId: result.req_id,
                organisation: result.organization_name,
                invited_by: result.invited_by,
              })
            );
            identifyEvent(result.user.email, {
              dbUserId: result.user.id.toString(),
              name: result.user.first_name,
              last_login: result.user.last_login,
              signup_date: result.user.date_joined,
            });
            trackEvents('login_submit__successful', {
              name: result.user.first_name,
              credits: result.user.totalcredits,
              orgId: result.user.organization_id,
              role: result.user.user_role_id,
            });
            dispatch(getUserDetailThunk(payload.reqId));
            dispatch(getOrgListThunk(undefined, true));
            dispatch(getWorkspaces());
          }
        } else if (response.status === 201) {
          dispatch(updateActiveState('otp'));
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            'OTP has been sent to your email'
          );
          dispatch(userSignUpSuccess(payload));
          dispatch(setMailStatus(true));
          dispatch(
            setOptInfo({
              email: response.data.email,
              reset_key: response.data.reset_key,
              contact_number: response.data.contact_number,
            })
          );
          resolve(true);
          identifyEvent(payload.email, { name: payload.name });
          trackEvents('signup-form_submit__successful', {
            email: payload.email,
            name: payload.name,
          });
        } else {
          dispatch(userSignUpFailure());
          dispatch(setMailStatus(false));
          resolve(true);
          trackEvents('signup-form_submit__failure', {
            email: payload.email,
            name: payload.name,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.email) {
          NotifyError(error.response.data.email);
        }
        if (error?.email) {
          NotifyError(error?.email[0]);
        } else {
          NotifyError('Something went wrong');
        }
        dispatch(userSignUpFailure());
        trackEvents('signup-form_submit__failure', {
          email: payload.email,
          name: payload.name,
        });
      });
  });
};

export const getUserDetailThunk = (reqId) => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/api/user/getUserDetails/');

    if (response.status !== HttpStatus.OK) return Promise.reject();

    const data = response.data;
    if (data.status === 200) {
      dispatch(
        getUserDetail({
          data: data.user,
          reqId,
        })
      );
      localStorage.setItem('mapkey', data.user.nearmap_api_key);

      identifyEvent(data.user.email, {
        dbUserId: data.user.id.toString(),
        name: data.user.first_name,
        last_login: data.user.last_login,
        signup_date: data.user.date_joined,
      });

      if (data.user.organization_id) {
        userAuthenticate(true);
      }

      return data.user;
    }

    return Promise.reject();
  } catch (error) {
    if (error.detail === 'Invalid token.') {
      NotifyError.error('Please Login again to ensure your security!');
    }

    return Promise.reject(error);
  }
};

export const workspaceSwitchThunk = (payload) => async (dispatch) => {
  dispatch(userLoginRequest());
  dispatch(switchWorkspace());
  try {
    dispatch(userUpdateRequest());

    const id = payload.workspaceData.id || payload.workspaceData.workspaceId;

    if (id) {
      const response = await axiosInstance.post(
        `/api/idam/v1/workspace/${id}/switch`
      );

      if (response.status === 200) {
        const data = response.data;
        const user = { ...data.user, organization_id: id };
        authenticate({
          token: data.token,
          user: user,
        });
        await new Promise((r) => setTimeout(r, 2000));
        if (payload.mode === 'reload') {
          window.location.reload();
          return;
        }

        if (payload.redirect) {
          window.location.href = decodeURI(payload.redirect);
          return;
        }

        window.location.href = '/';
      }
    }

    if (payload.workspaceData.token) {
      const data = JSON.parse(localStorage.getItem('sr_token'));
      authenticate({ token: payload.workspaceData.token, user: data.user });
      window.location.href = '/';
    }
  } catch (err) {
    NotifyError(err.message);
    logout();
    dispatch(userLoginFailure(err));
    window.location.href = '/auth';
  }
};

export const updateUserProfileThunk = (payload) => async (dispatch) => {
  dispatch(userUpdateRequest());
  let profileData = new FormData();
  profileData.append('email', payload.email);
  profileData.append('profile_image', payload.profile_image);
  profileData.append('first_name', payload.name);
  profileData.append('contact_number', payload.phone);

  axiosInstance
    .post('/api/user/update/', profileData)
    .then(() => {
      dispatch(userUpdateSuccess());
      dispatch(getUserDetailThunk());
      identifyEvent(payload.email, {
        avatar: payload.profile_image,
        name: payload.name,
        phone: payload.phone.toString(),
      });
      showNotification(
        NOTIFICATIONS_TYPES.SUCCESS,
        'Your information has been updated successfully'
      );
    })
    .catch(() => {
      dispatch(userUpdateFailure());
    });
};

export const changePasswordThunk = (payload) => async (dispatch) => {
  dispatch(changePasswordRequest());
  let changePasswordData = new FormData();
  changePasswordData.append('email', payload.email);
  changePasswordData.append('password', payload.newPassword);

  axiosInstance
    .post('/api/user/update/', changePasswordData)
    .then((response) => {
      showNotification(
        NOTIFICATIONS_TYPES.SUCCESS,
        'Password updated successfully .'
      );
      dispatch(changePasswordSuccess());
    })
    .catch(() => {
      NotifyError('Something went wrong, please try again later .');
      dispatch(changePasswordFailure());
    });
};

export const setJobTitle = (payload) => (dispatch) => {
  axiosInstance
    .post('/api/v1/job-title/', {
      ...payload.title,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(setUserJobTitle({ ...payload.title }));
        dispatch(userTitleSetSuccess());
        trackEvents('job-title-form_submit__successful', {
          email: payload.email,
          title: payload.title,
        });
      } else {
        dispatch(userTitleSetFailure());
        trackEvents('job-title-form_submit__failure', {
          email: payload.email,
          title: payload.title,
        });
      }
    })
    .catch(catchError);
};

export const getJobTitleThunk = () => (dispatch) => {
  dispatch(getJobTitleRequest());

  axiosInstance
    .get('/api/v1/job-title/')
    .then(async (response) => {
      const data = response.data;
      dispatch(getJobTitlesuccess(data));
    })
    .catch((err) => {
      NotifyError(err.message, '', 3000);
      dispatch(getJobTitleFailure());
    });
};

export const updateJobTitleThunk = (payload) => (dispatch) => {
  dispatch(updateJobTitleRequest());
  axiosInstance
    .post('/api/v1/job-title/', {
      ti: payload?.title,
      id: payload?.id,
    })
    .then(async (response) => {
      if (response.status === 200) {
        showNotification(
          NOTIFICATIONS_TYPES.SUCCESS,
          'Job Title updated Successfully'
        );
        dispatch(updateJobTitlesuccess(payload));
        dispatch(getUserDetailThunk());
        identifyEvent(payload.email, { title: payload.title });
      } else {
        NotifyError(response.msg, '', 3000);
        dispatch(updateJobTitleFailure());
      }
    })
    .catch((err) => {
      NotifyError(err.message, '', 3000);
      dispatch(userLoginFailure());
    });
};

export const getAvatarImagesThunk = () => (dispatch) => {
  dispatch(getAvatarImagesRequest());
  axiosInstance
    .get('/api/user/getAvatar/')
    .then(async (response) => {
      const data = response.data;
      dispatch(getAvatarImagesSuccess(data));
    })
    .catch((err) => {
      NotifyError(err.message, '', 3000);
      dispatch(getAvatarImagesFailure());
    });
};

export const setOrganisation = (payload) => async (dispatch) => {
  dispatch(loginByLinkRequest());
  let data = new FormData();
  data.append('req_id', payload.requestId);
  const response = await axiosInstance.post('/api/user/setOrganization/', data);
  if (response && response.status === 200) {
    showNotification(
      NOTIFICATIONS_TYPES.SUCCESS,
      'Workspaces joined successfully'
    );
    dispatch(loginByLinkSuccess(payload));
    trackEvents('invitation-acceptance_submit__successful', {
      email: payload.email,
      orgName: payload.organisation,
    });
  } else {
    NotifyError('Error', '', 3000);
    dispatch(loginByLinkFailure());
    trackEvents('invitation-acceptance_submit__failure', {
      email: payload.email,
      orgName: payload.organisation,
    });
  }
};

export const isPasswordCorerctThunk = (payload) => async (dispatch) => {
  try {
    let checkCurrentPasswordData = new FormData();
    checkCurrentPasswordData.append('email', payload.email);
    checkCurrentPasswordData.append('password', payload.currentPassword);

    axiosInstance
      .post('/api/user/login/', checkCurrentPasswordData)
      .then(() => {
        dispatch(isPaaswordTrueSuccess());
      })
      .catch((errors) => {
        NotifyError(errors?.error, '', 3000);
        dispatch(changePasswordFailure());
      });

    return;
  } catch (error) {
    NotifyError('Something went wrong , please try again later.', '', 3000);
    dispatch(changePasswordFailure());
  }
};

export const updateUserCreditsThunk = (payload) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `/api/v1/user/credits/${payload.organisationId}/${payload.userId}`;
      const response = await axiosInstance.get(URL);
      if (response.status === 200) {
        if (payload.admin) {
          for (let orgUser of response.data.data.org_user_data) {
            if (orgUser.id === payload.userId) {
              dispatch(
                updateUserCredits({
                  totalcredits: orgUser.credits,
                })
              );
              break;
            }
          }
        } else {
          dispatch(
            updateUserCredits({
              totalcredits: response.data.data.available_credits,
            })
          );
        }
        resolve(true);
      }
    } catch (error) {
      NotifyError('Failed to fetch updated credits', '', 3000);
    }
  });
};

//OTP verification for Mobile
export const getMobileOtp = (payload) => (dispatch) => {
  dispatch(otpVerificationRequest());
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('reset_key', payload.reset_key);
    formData.append('contact_number', payload.contact_number);
    axiosInstance
      .post('/api/v1/user/mobile-otp', formData)
      .then(async (res) => {
        dispatch(updateActiveState('otp'));
        showNotification(
          NOTIFICATIONS_TYPES.SUCCESS,
          'OTP has been sent to your mobile'
        );
        dispatch(
          setOptInfo({
            reset_key: payload.reset_key,
            contact_number: payload.contact_number,
          })
        );
        resolve(true);
        identifyEvent(payload.email, {
          name: payload.name,
          phone: payload.contact_number.toString(),
        });
        trackEvents('signup-form_submit__successful', {
          contact_number: payload.contact_number,
          name: payload.name,
        });
      })
      .catch(() => {
        NotifyError('Something went wrong. Unable to send OTP to mobile');
        dispatch(otpVerificationFailure());
        trackEvents('otp-form_submit__failure', {
          email: payload.email,
          contact_number: payload.contact_number,
        });
      });
  });
};

export const verifyMobileOtp = (payload) => (dispatch) => {
  dispatch(otpVerificationRequest());
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('reset_key', payload.otpInfo.reset_key);
    formData.append('otp', payload.otp);
    formData.append('email', payload.otpInfo.email);
    formData.append('contact_number', payload.otpInfo.contact_number);
    axiosInstance
      .post('/api/user/verifyMobileOtp/', formData)
      .then(async (res) => {
        const data = res.data;
        if (data.status === 200) {
          authenticate({
            token: data.token,
          });
          dispatch(
            userLoginSuccess({
              new_user: data.new_user,
              reqId: data.req_id,
              organisation: data.organization_name,
              invited_by: data.invited_by,
            })
          );
          dispatch(getUserDetailThunk(payload.reqId));
          dispatch(otpVerificationSuccess());
          trackEvents('otp-form_submit__successful', {
            email: payload.otpInfo.email,
          });
        } else if (data.status === 411) {
          NotifyError(data.error);
          dispatch(otpVerificationFailure());
          trackEvents('otp-form_submit__failure', {
            email: payload.otpInfo.email,
            contact_number: payload.otpInfo.contact_number,
          });
        } else {
          NotifyError(data.error);
          dispatch(otpVerificationFailure());
          dispatch(userLoginFailure(data.error));
          trackEvents('otp-form_submit__failure', {
            email: payload.otpInfo.email,
            contact_number: payload.otpInfo.contact_number,
          });
        }
      })
      .catch((error) => {
        if (error.status === 411 || error.status === 400) {
          NotifyError(
            error?.error || 'Some error occurred while verifying the OTP'
          );
        }
        dispatch(otpVerificationFailure());
        trackEvents('otp-form_submit__failure', {
          email: payload.otpInfo.email,
          contact_number: payload.otpInfo.contact_number,
        });
      });
  });
};

export const resendMobileOtp = (payload) => () => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('reset_key', payload.otpInfo.reset_key);
    formData.append('contact_number', payload.otpInfo.contact_number);
    axiosInstance
      .post('/api/user/resendMobileOtp/', formData)
      .then(async (res) => {
        const data = res.data;
        if (res.status === 200) {
          showNotification(NOTIFICATIONS_TYPES.SUCCESS, data.success);
          trackEvents('otp-form_resend__successful', {
            email: payload.otpInfo.email,
          });
        } else {
          NotifyError(data.error);
          trackEvents('otp-form_resend__failure', {
            email: payload.otpInfo.email,
          });
        }
      })
      .catch(() => {
        trackEvents('otp-form_resend__failure', {
          email: payload.otpInfo.email,
        });
      });
  });
};

// OTP Verification for Email
export const verifyOtp = (payload) => (dispatch) => {
  dispatch(otpVerificationRequest());

  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('reset_key', payload.otpInfo.reset_key);
    formData.append('otp', payload.otp);
    formData.append('email', payload.otpInfo.email);
    axiosInstance
      .post('/api/user/verifyOtp/', formData)
      .then(async (res) => {
        const data = res.data;
        if (data.status === 200) {
          if (payload.source !== 'EMAILBAR') {
            authenticate({
              token: data.token,
            });
            dispatch(
              userLoginSuccess({
                new_user: data.new_user,
                reqId: data.req_id,
                organisation: data.organization_name,
                invited_by: data.invited_by,
              })
            );
            dispatch(getUserDetailThunk(payload.reqId));
          } else {
          }
          dispatch(otpVerificationSuccess());
          trackEvents('otp-form_submit__successful', {
            email: payload.otpInfo.email,
          });
        } else if (data.status === 411) {
          NotifyError(data.error);
          dispatch(otpVerificationFailure());
          trackEvents('otp-form_submit__failure', {
            email: payload.otpInfo.email,
          });
        } else {
          NotifyError(data.error);
          dispatch(otpVerificationFailure());
          dispatch(userLoginFailure(data.error));
          trackEvents('otp-form_submit__failure', {
            email: payload.otpInfo.email,
          });
        }
      })
      .catch((error) => {
        const data = error;
        if (data.status === 411 || data.status === 400) {
          NotifyError(data.error);
        }
        dispatch(otpVerificationFailure());
        trackEvents('otp-form_submit__failure', {
          email: payload.otpInfo.email,
        });
      });
  });
};

export const resendOtp = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('reset_key', payload.otpInfo.reset_key);
    formData.append('email', payload.otpInfo.email);
    axiosInstance
      .post('/api/user/resendOtp/', formData)
      .then(async (res) => {
        const data = res.data;
        if (res.status === 200) {
          showNotification(NOTIFICATIONS_TYPES.SUCCESS, data.success);
          trackEvents('otp-form_resend__successful', {
            email: payload.otpInfo.email,
          });
        } else {
          NotifyError(data.error);
          trackEvents('otp-form_resend__failure', {
            email: payload.otpInfo.email,
          });
        }
      })
      .catch(() => {
        reject();
        trackEvents('otp-form_resend__failure', {
          email: payload.otpInfo.email,
        });
      });
  });
};

export const updateEmailAndSendOtp = (payload) => async (dispatch) => {
  dispatch(otpGenerationRequest());
  let formData = new FormData();
  formData.append('email', payload.otpInfo.email);

  let res = null;
  try {
    res = await axiosInstance.post('/api/user/resendOtpV1/', formData);
    const data = res.data;
    if (res.status === 200) {
      dispatch(otpGenerationSuccess());
      showNotification(NOTIFICATIONS_TYPES.SUCCESS, data.success);
      trackEvents('otp-form_resend__successful', {
        email: payload.otpInfo.email,
      });
    } else {
      dispatch(otpGenerationFailure());
      trackEvents('otp-form_resend__failure', {
        email: payload.otpInfo.email,
      });
    }
  } catch (error) {
    if (error.toString().indexOf('400') > -1) {
      dispatch(otpGenerationFailure('Email address already Verified'));
    }
    trackEvents('otp-form_resend__failure', {
      email: payload.otpInfo.email,
    });
  }
};

// OTP Verification for Email
export const verifyEmailOtp = (payload) => (dispatch) => {
  dispatch(otpVerificationRequest());

  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('otp', payload.otp);
    formData.append('email', payload.otpInfo.email);
    axiosInstance
      .post('/api/user/verify-user', formData)
      .then(async (res) => {
        if (res.status === 200) {
          dispatch(otpVerificationSuccess());
          dispatch(getUserDetailThunk());
          trackEvents('otp-form_submit__successful', {
            email: payload.otpInfo.email,
          });
        } else if (res.status === 411) {
          NotifyError(res.data.error);
          dispatch(otpVerificationFailure(res.data.error));
          trackEvents('otp-form_submit__failure', {
            email: payload.otpInfo.email,
          });
        } else {
          NotifyError(res.data.error);
          dispatch(otpVerificationFailure(res.data.error));
          trackEvents('otp-form_submit__failure', {
            email: payload.otpInfo.email,
          });
        }
      })
      .catch((error) => {
        const data = error?.response?.data;
        if (data) {
          if (data.status === 411 || data.status === 400) {
            NotifyError(data.error);
          }
          dispatch(otpVerificationFailure(data.errorDesc));
        } else if (error.errorCode) {
          NotifyError(error.errorDesc);
          dispatch(otpVerificationFailure(error.errorDesc));
        }
        trackEvents('otp-form_submit__failure', {
          email: payload.otpInfo.email,
        });
      });
  });
};

export const dismissTrialPopup = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      await dismissTrialPopupApi();
      resolve(dispatch(setTrialPopupAsSeen()));
    } catch (error) {
      reject(error);
    }
  });
};
