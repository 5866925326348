// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector146IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector146Icon(props: Vector146IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 23 17"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M1 15.518c.385-.159 2.007-.925 2.36-1.086.838-.38 1.683-.826 2.458-1.333.759-.74 1.605-2.137 1.851-2.938C7.642 9.848 10.01 5 10.01 5c-1.15-.31-5.636 1.53-5.636 1.53l.268 2.023c1.129.398 1.018.43 1.502.499 2.759.54 5.126.018 6.95-.645 1.399-.508 2.288-1.25 3.229-2.37 1.033-1.23.864-2.602 2.116-3.615C19.53 1.538 20.792 1 22.187 1"
        }
        stroke={"currentColor"}
        strokeWidth={"1.172"}
        strokeLinecap={"round"}
      ></path>
    </svg>
  );
}

export default Vector146Icon;
/* prettier-ignore-end */
