export const zoomInKey = { title: 'Zoom In', key: '+', keyName: '=' };
export const zoomOutKey = { title: 'Zoom Out', key: '-', keyName: '-' };
export const undoKey = {
  title: 'Undo',
  key: 'Z',
  keyName: 'metaKey+z',
  windowsKey: 'ctrl+z',
};
export const redoKey = {
  title: 'Redo',
  key: 'Y',
  keyName: 'metaKey+y',
  windowsKey: 'ctrl+y',
};
export const escKey = {
  title: 'Redo',
  key: 'Y',
  keyName: 'metaKey+y',
  windowsKey: 'ctrl+y',
};
export const saveKey = {
  title: 'Save',
  key: 'S',
  keyName: 'metaKey+s',
  windowsKey: 'ctrl+s',
};
export const exitEditMode = {
  title: 'Exit',
  key: 'Escape',
  keyName: 'Escape',
  windowsKey: 'ctrl+s',
};
export const duplicateLayer = {
  title: 'duplicate layer',
  key: 'd',
  keyName: 'metaKey+d',
  windowsKey: 'ctrl+d',
};
//shift + left, right, top, bottom
export const shiftRightKey = {
  title: 'Right',
  key: 'shift+ArrowRight',
  keyName: 'shift+ArrowRight',
};
export const shiftLeftKey = {
  title: 'Left',
  key: 'shift+ArrowLeft',
  keyName: 'shift+ArrowLeft',
};
export const shiftTopKey = {
  title: 'Top',
  key: 'shift+ArrowUp',
  keyName: 'shift+ArrowUp',
};
export const shiftBottomKey = {
  title: 'Bottom',
  key: 'shift+ArrowDown',
  keyName: 'shift+ArrowDown',
};

//Layer  left, right, top, bottom
export const layerRightMove = {
  title: 'Right',
  key: 'ArrowRight',
  keyName: 'ArrowRight',
  windowsKey: 'ArrowRight',
};
export const layerLeftMove = {
  title: 'Left',
  key: 'ArrowLeft',
  keyName: 'ArrowLeft',
  windowsKey: 'ArrowLeft',
};
export const layerTopMove = {
  title: 'Top',
  key: 'ArrowUp',
  keyName: 'ArrowUp',
  windowsKey: 'ArrowUp',
};
export const layerBottomMove = {
  title: 'Bottom',
  key: 'ArrowDown',
  keyName: 'ArrowDown',
  windowsKey: 'ArrowDown',
};

//Layer fast  left, right, top, bottom
export const layerRightFastMove = {
  title: 'Right',
  key: 'shift+ArrowRight',
  keyName: 'shift+ArrowRight',
};
export const layerLeftFastMove = {
  title: 'Left',
  key: 'shift+ArrowLeft',
  keyName: 'shift+ArrowLeft',
};
export const layerTopFastMove = {
  title: 'Top',
  key: 'shift+ArrowUp',
  keyName: 'shift+ArrowUp',
};
export const layerBottomFastMove = {
  title: 'Bottom',
  key: 'shift+ArrowDown',
  keyName: 'shift+ArrowDown',
};

export const modifyEditKey = { title: 'Modify/Edit', key: 'e', keyName: 'e' };
export const cutKey = { title: 'Cut', key: 'c', keyName: 'c' };
export const sliceKey = { title: 'Slice', key: 'k', keyName: 'k' };

//draw tool type
export const drawMode = { title: 'Draw', key: 'd', keyName: 'd' };
export const drawPolygon = { title: 'Polygon Draw', key: 'p', keyName: 'p' };
export const drawLine = { title: 'Line Draw', key: 'l', keyName: 'l' };
export const drawPoint = { title: 'Point Draw', key: 'o', keyName: 'o' };
export const drawPath = { title: 'Path Draw', key: 'p', keyName: 'p' };
export const duplicateComponent = {
  title: 'Duplicate',
  key: 'i',
  keyName: 'metaKey+i',
  windowsKey: 'ctrl+i',
};
export const mergeComponent = {
  title: 'Merge',
  key: 'm',
  keyName: 'metaKey+m',
  windowsKey: 'ctrl+m',
};

export const deleteComponent = {
  title: 'Delete',
  key: 'Delete',
  keyName: 'Backspace',
  windowsKey: 'Delete',
};
