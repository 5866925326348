import { ORDER_QUEUE_ACTIONS } from './action_types';

const INITIAL_STATE = {
  orderQueueModalVisible: false,
};

const data = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ORDER_QUEUE_ACTIONS.OPEN_ORDER_QUEUE_MODAL:
      return {
        ...state,
        orderQueueModalVisible: true,
      };
    case ORDER_QUEUE_ACTIONS.CLOSE_ORDER_QUEUE_MODAL:
      return {
        ...state,
        orderQueueModalVisible: false,
      };
    default:
      return state;
  }
};

export default data;