// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector423IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector423Icon(props: Vector423IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 4 8"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M1.208 7.816c-.027 0-.04-.015-.04-.045v-.65a1.594 1.594 0 01-.83-.658A1.943 1.943 0 01.01 5.356V4.93c0-.03.013-.045.04-.045h.915c.02 0 .03.015.03.045v.427a.76.76 0 00.187.511.56.56 0 00.437.22.55.55 0 00.438-.22.762.762 0 00.175-.488c0-.232-.13-.43-.392-.595a8.152 8.152 0 00-.412-.258L.905 4.21a1.75 1.75 0 01-.689-.752A2.395 2.395 0 010 2.425c0-.42.109-.783.327-1.094.218-.311.491-.523.82-.635V.045c0-.03.014-.045.04-.045h.875c.034 0 .05.015.05.045v.662c.322.113.59.326.8.64.211.315.317.674.317 1.078v.753c0 .022-.01.034-.03.034h-.926c-.02 0-.03-.011-.03-.034l-.01-.753a.729.729 0 00-.187-.522.546.546 0 00-.855.017.771.771 0 00-.175.505.94.94 0 00.11.478c.074.13.207.26.402.387l.835.516c.275.165.492.37.65.618.157.247.236.558.236.932 0 .427-.104.796-.312 1.107a1.62 1.62 0 01-.805.645v.663c0 .03-.017.045-.05.045h-.875.001z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector423Icon;
/* prettier-ignore-end */
