// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Frame2IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Frame2Icon(props: Frame2IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.061 11.068l4.7-4.7-.933-.934-3.767 3.767-1.9-1.9-.933.933 2.833 2.834zm.934 3.6a6.492 6.492 0 01-2.6-.525 6.732 6.732 0 01-2.117-1.426 6.732 6.732 0 01-1.425-2.116A6.492 6.492 0 011.328 8c0-.922.175-1.789.525-2.6a6.732 6.732 0 011.425-2.117c.6-.6 1.306-1.075 2.117-1.425a6.492 6.492 0 012.6-.525c.922 0 1.789.175 2.6.525.81.35 1.516.825 2.116 1.425.6.6 1.075 1.306 1.425 2.117.35.811.526 1.678.526 2.6 0 .922-.176 1.789-.526 2.6a6.732 6.732 0 01-1.425 2.116 6.73 6.73 0 01-2.116 1.425 6.492 6.492 0 01-2.6.526z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Frame2Icon;
/* prettier-ignore-end */
