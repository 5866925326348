import { getAxiosInstance, getCancelToken } from "@/helpers/utilities/api-utils";

import { CONSTANTS } from './contants';

const axiosInstance = getAxiosInstance();

// axios cancel Token
const CancelToken = getCancelToken();
let cancel;

export const BulkApi = {
  getBulkOrderData: async (bulkOrderId, params) => {
    return await Promise.all([
      axiosInstance.get(`/api/bulk/v1/border/${bulkOrderId}`, {
        params,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      }),
    ]);
  },

  archiveBulkCard: async (bulkOrderId) => {
    return await axiosInstance.delete(`/api/bulk/v1/border/${bulkOrderId}`);
  },
  deleteBulkCard: async (bulkOrderId) => {
    return await axiosInstance.delete(`/api/bulk/v1/border/${bulkOrderId}`);
  },
  getBulkOrderAfterOrderPlacedData: async (bulkOrderId, params) => {
    return await Promise.all([
      axiosInstance.get(`/api/bulk/v1/border/${bulkOrderId}/order`, {
        params,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      }),
    ]);
  },

  getBulOrderSummery: async (bulkOrderId) => {
    return axiosInstance.get(`${CONSTANTS.BASE_ROUTE_SUMMERY}/${bulkOrderId}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  },

  editCustomerName: async (bulkOrderId, payload) => {
    return axiosInstance.put(
      `/api/bulk/v1/border/${bulkOrderId}/corder`,
      {data: payload}
    );
  },

  archiveBulkOrder: async (bulkOrderId, payload) => {
    return axiosInstance.delete(`/api/bulk/v1/border/${bulkOrderId}`);
  },

  editBulkNameAndDescriptionApi: (bulkOrderId, payload) => {
    return axiosInstance.put(`/api/v1/bulkorder/${bulkOrderId}`, payload);
  },

  getTableData: (bulkOrderId, payload) => {
    return axiosInstance.get(
      `/api/bulk/v1/border/${bulkOrderId}/area?buckets=${payload}`
    );
  },

  cancelToken: () => {
    if (cancel) cancel();
    cancel = null;
  },
};
