import React, { useMemo } from 'react';
import useSubscriptionHeaderWidget from '../../../subscription/components/SubscriptionHeaderWidget/useSubscriptionHeaderWidget';
import SubscriptionHeaderWidget from '../../../subscription/components/SubscriptionHeaderWidget/index';
import { useRole } from '@/modules/auth/guards/RoleGuard';

const SubscriptionHeaderWidgetOnNavbar = () => {
  const { type } = useSubscriptionHeaderWidget();
  const { isGuest } = useRole();
  const isSubscriptionWidgetVisible = useMemo(
    () => !isGuest && Boolean(type),
    [type]
  );

  if (isSubscriptionWidgetVisible) {
    return <SubscriptionHeaderWidget />;
  }

  return <></>;

};

export default SubscriptionHeaderWidgetOnNavbar;
