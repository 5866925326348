/* ##### ORDER STATUS ##### */
export const DRAFT_ORDER_STATUS_CODE = 1;
export const MEASUREMENT_ONGOING_ORDER_STATUS_CODE = 2;
export const AWAITING_APPROVAL_ORDER_STATUS_CODE = 3;
export const APPROVED_ORDER_STATUS_CODE = 4;
export const RESOLVING_FEEDBACK_ORDER_STATUS_CODE = 5;
export const ARCHIVED_ORDER_STATUS_CODE = 6;
export const SELF_MEASURED_ORDER_STATUS_CODE = 7;
export const BOLT_FAILED_ORDER_STATUS_CODE = 8;
export const BOLT_PREVIEW_ORDER_STATUS_CODE = 9;
export const BOLT_APPROVED_ORDER_STATUS_CODE = 10;
export const BOLT_IN_VERIFICATION_ORDER_STATUS_CODE = 11;
export const CONFIRMED_ORDER_STATUS_CODE = 12; // 12 Isn't A Status Code In DB - This Is Just For Frontend

/* ##### DEFAULT ORDER TYPE ##### */
export const DEFAULT_ORDER_TYPE_FASTRACK = 'FASTRACK';
export const DEFAULT_ORDER_TYPE_PLATFORM = 'PLATFORM';
export const DEFAULT_ORDER_TYPE_TABULA = 'TABULA';

/* ##### USER ROLE ##### */
export const MAINTAINER = 'siterecon,organization,user';
export const FEATURE_STATUS = 'active,closed';
export const APPROVED_MAINTAINER = 'siterecon';
export const ALLOWED_ACCESS_RIGHTS_FOR_TAGS_ACTIONS=['view']
