import { OrderStatus } from '@/modules/common/types';
import { createContext, useContext } from 'react';

interface IOrderStatusContext {
  status: OrderStatus | null | undefined;
}

/** We need to use Context for OrderStatus as redux store is not instantly updated when order status changes and causing errors */
const OrderStatusContext = createContext<IOrderStatusContext>({
  status: undefined,
});

export const useOrderStatusContext = () => useContext(OrderStatusContext);

export default OrderStatusContext;
