import { SubscriptionStore } from '@/store/subscription/index';

export const INITIAL_STATE: SubscriptionStore = {
  modal: {
    visible: false,
  },
  payAsYouGoModal: {
    visible: false,
    order: null,
  },
  subscriptionWidget: {
    visible: false,
  },
  repairSubscription: {
    visible: false,
    invoice: null,
  },
  isReminderVisible: false,
  currentPlan: null,
  features: [],
  response: null,
  subscription: null,
};
