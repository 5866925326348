import useActiveTab from '@/components/pages/myProperties/hooks/useActiveTab';
import { Button, Form, Input, Popover, Row, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Text from '../../elements/text/Text';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { NotifyError } from '../../../helpers/notification-utils';
import { ReactComponent as Pencil } from '../../../assets/EditPencil.svg';
import './style.less';
import { updateOrderThunk } from '../../../store/order/thunks';
import { updateOrderDetails } from '../../../store/order/actions';
import { setCustomerInfoDialogVisibility } from '../../../store/ui/actions';
import useRole from '../../../modules/auth/guards/RoleGuard/useRole';
import { ID, UserRole } from '../../../modules/common/types';
import { useSelector } from 'react-redux';
import { IStore } from '../../../store/types';

interface UpdateOrderFormProps {
  currentViewData: any;
  props: any;
  updatePropName: any;
}

const UpdateOrderForm = ({
  currentViewData,
  props,
  updatePropName,
}: UpdateOrderFormProps) => {
  const dispatch = useDispatch();
  const activeTab = useActiveTab();
  const axiosInstance = getAxiosInstance();
  const { role } = useRole();

  const isViewerOrGuest = [UserRole.Viewer, UserRole.Guest].includes(role);

  const orderId = useSelector<IStore, ID>(
    (state: any) => state.order.orderData?.orderNo
  );

  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState({
    customerName: props.customerName,
    customerId: props.customerId,
    description: props.description,
    propertyName: props.propertyName,
    orderAddress: props.orderAddress,
  });

  const isCustomerInfoDialogVisible = useSelector(
    (state: any) => state.ui.isCustomerInfoDialogVisible
  );

  const [updatePropertyOpen, setUpdatePropertyOpen] = useState<boolean>(
    isCustomerInfoDialogVisible
  );

  useEffect(() => {
    setUpdatePropertyOpen(isCustomerInfoDialogVisible);
  }, [isCustomerInfoDialogVisible]);

  useEffect(() => {
    setOrderDetails({
      customerName: props.customerName,
      customerId: props.customerId,
      description: props.description,
      propertyName: props.propertyName,
      orderAddress: props.orderAddress,
    });
  }, [props]);

  const updateProperty = async (orderData) => {
    if (orderData.propertyName.length < 5) {
      NotifyError('Property name must be at least 5 characters long');
      return;
    }
    if (orderData.customerName && orderData.customerName.length > 500) {
      NotifyError('Customer name must be less than 500 characters long');
      return;
    }
    setBtnLoading(true);

    const tempData = new FormData();
    tempData.append('customerName', orderData?.customerName);
    tempData.append('customerId', orderData?.customerId);
    tempData.append('description', orderData?.description);
    tempData.append('propertyName', orderData.propertyName);
    tempData.append('orderNo', orderId as string);
    dispatch(
      updateOrderDetails({
        propertyName: orderData.propertyName,
        customerId: orderData.customerId,
        customerName: orderData.customerName,
        description: orderData.description,
      })
    );

    dispatch(updateOrderThunk(tempData));

    setUpdatePropertyOpen(false);
    dispatch(setCustomerInfoDialogVisibility(false));
    setBtnLoading(false);
  };

  const handleOpenChangePropertyDetails = (newOpen) => {
    setUpdatePropertyOpen(newOpen);
    dispatch(setCustomerInfoDialogVisibility(newOpen));
  };

  return (
    <div className='add-info'>
      <Popover
        trigger='click'
        placement='bottom'
        overlayClassName='overlay-property'
        visible={updatePropertyOpen}
        onVisibleChange={handleOpenChangePropertyDetails}
        content={
          <div className='property-info-popup'>
            <Row className='header'>
              <Text type='p91' style={{ marginBottom: '9px' }}>
                Property Information
              </Text>
              <Text type='p33' style={{ color: '#666666' }}>
                Optional (for record purposes)
              </Text>
            </Row>

            <Form
              className='property-info-form'
              onFinish={updateProperty}
              autoComplete='off'
              fields={[
                {
                  name: ['address'],
                  value: orderDetails.orderAddress,
                },
                {
                  name: ['propertyName'],
                  value: orderDetails.propertyName,
                },
                {
                  name: ['customerName'],
                  value: orderDetails.customerName,
                },
                {
                  name: ['customerId'],
                  value: orderDetails.customerId,
                },
                {
                  name: ['description'],
                  value: orderDetails.description,
                },
              ]}
            >
              <Row className='info-item'>
                <Text type='p19'>Property Address</Text>
                <UpdateFormTooltipWrapper isViewerOrGuest={isViewerOrGuest}>
                  <Form.Item name='address'>
                    <Input className='info-input' disabled placeholder='' />
                  </Form.Item>
                </UpdateFormTooltipWrapper>
              </Row>

              <Row className='info-item'>
                <Text type='p19'>Property Name</Text>
                <UpdateFormTooltipWrapper isViewerOrGuest={isViewerOrGuest}>
                  <Form.Item name='propertyName'>
                    <Input
                      className='info-input'
                      disabled={isViewerOrGuest}
                      placeholder=''
                    />
                  </Form.Item>
                </UpdateFormTooltipWrapper>
              </Row>

              <Row className='info-item'>
                <Text type='p19'>Customer Name</Text>
                <UpdateFormTooltipWrapper isViewerOrGuest={isViewerOrGuest}>
                  <Form.Item name='customerName'>
                    <Input
                      className='info-input'
                      disabled={isViewerOrGuest}
                      placeholder='Ex: Jon Doe'
                    />
                  </Form.Item>
                </UpdateFormTooltipWrapper>
              </Row>
              <Row className='info-item'>
                <Text type='p19'>Customer ID</Text>
                <UpdateFormTooltipWrapper isViewerOrGuest={isViewerOrGuest}>
                  <Form.Item name='customerId'>
                    <Input
                      className='info-input'
                      disabled={isViewerOrGuest}
                      placeholder='Ex: 124543'
                    />
                  </Form.Item>
                </UpdateFormTooltipWrapper>
              </Row>
              <Row className='info-item'>
                <Text type='p19'>Description of services</Text>
                <UpdateFormTooltipWrapper isViewerOrGuest={isViewerOrGuest}>
                  <Form.Item name='description'>
                    <TextArea
                      rows={4}
                      className='info-input'
                      placeholder='Ex: Weekly Mowing, Mulching, etc'
                      disabled={isViewerOrGuest}
                    />
                  </Form.Item>
                </UpdateFormTooltipWrapper>
              </Row>
              <Row
                style={{
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  className='action-button'
                  style={{ marginRight: '28px' }}
                >
                  <Text
                    type='p47'
                    onClick={() => {
                      setUpdatePropertyOpen(false);
                      dispatch(setCustomerInfoDialogVisibility(false));
                    }}
                  >
                    Cancel
                  </Text>
                </Button>
                <Button
                  className='action-button'
                  type='primary'
                  htmlType='submit'
                  loading={btnLoading}
                  disabled={isViewerOrGuest}
                >
                  <Text type='p47'>Update</Text>
                </Button>
              </Row>
            </Form>
          </div>
        }
      >
        {orderDetails.customerId || orderDetails.customerName ? (
          <Tooltip
            title={
              orderDetails.customerId && orderDetails.customerName
                ? orderDetails.customerName + ' • ' + orderDetails.customerId
                : orderDetails.customerId
                  ? orderDetails.customerId
                  : orderDetails.customerName
            }
          >
            <div
              className='overflow'
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Text
                style={{ color: '#666666' }}
                type='p17'
                className='overflow'
              >
                {orderDetails.customerId && orderDetails.customerName
                  ? orderDetails.customerName + ' • ' + orderDetails.customerId
                  : orderDetails.customerId
                    ? orderDetails.customerId
                    : orderDetails.customerName}
              </Text>
              <Pencil style={{ marginLeft: '6px' }} />
            </div>
          </Tooltip>
        ) : (
          <Text type='p17' style={{ color: '#1055EB', cursor: 'pointer' }}>
            Add Property Info
          </Text>
        )}
      </Popover>
    </div>
  );
};

interface UpdateFormTooltipWrapperProps {
  children: React.ReactNode;
  isViewerOrGuest: boolean;
}

const UpdateFormTooltipWrapper: React.FC<UpdateFormTooltipWrapperProps> = ({ children, isViewerOrGuest }) => {
  const tooltipTitle = isViewerOrGuest ? 'You are not authorised to perform this action.' : '';
  return (
    <Tooltip title={tooltipTitle}>
      {children}
    </Tooltip>
  );
};

export default UpdateOrderForm;
