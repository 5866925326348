import { BetaFeature } from '@/modules/common/types';
import { useSelector } from 'react-redux';
import { BetaReleaseStore } from '@/store/betaRelease/state';
import { IStore } from '@/store/types';

type KeyMap = {
  [key in BetaFeature]: keyof BetaReleaseStore;
};

export const getKey = (feature: BetaFeature): keyof BetaReleaseStore => {
  const keyMap: KeyMap = {
    [BetaFeature.OrderQueue]: 'isOrderQueueVisible',
    [BetaFeature.StreetView]: 'isStreetViewVisible',
    [BetaFeature.Feed]: 'isFeedVisible',
    [BetaFeature.Attributes]: 'attributesVisible',
    [BetaFeature.AddMoreBulkOrder]: 'isAddMoreBulkOrder',
    [BetaFeature.GeoJsonUpload]: 'isGeoJSONUpload',
    [BetaFeature.Insights]: 'enableInsightsDashboard',
    [BetaFeature.GeoJsonDownload]: 'isDownloadGeojsonVisible',
    [BetaFeature.ParcelRepair]: 'allowParcelRepair',
    [BetaFeature.ImageryRepair]: 'allowImageryRepair',
    [BetaFeature.TabulaUpload]: 'allowTabulaUpload',
    [BetaFeature.OrderRating]: 'isOrderRatingVisible',
    [BetaFeature.ExportKml]: 'isExportKmlFilesEnabled',
    [BetaFeature.CopyViewId]: 'copyViewId',
    [BetaFeature.EnableNearmapPreview]: 'isNearmapPreviewEnabled',
    [BetaFeature.ServiceItem]: 'isServiceItem',
    [BetaFeature.Dashboards]: 'showReports',
    [BetaFeature.AddWorkspace]: 'isAddWorkspaceEnabled',
    [BetaFeature.Estimations]: 'isEstimationsEnabled',
  };

  return keyMap[feature] || '';
};

/** Checks if given BetaFeature is enabled or not  */
const useBetaRelease = (feature: BetaFeature): boolean => {
  return (
    useSelector<IStore, boolean>(
      (state) => state.betaRelease[getKey(feature)]
    ) || false
  );
};

export default useBetaRelease;
