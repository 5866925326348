import {
  getWorkspaces as getWorkspacesApi,
  getWorkspaceSummary as getWorkspaceSummaryApi,
} from '@/modules/workspace/api';
import { setWorkspaces, setWorkspaceSummary } from '@/store/workspace/actions';
import { ThunkAction } from '@common/types/redux';
import { WorkspaceApiTransformer } from '@/modules/workspace/api/transfomer';

export const getWorkspaces = (): ThunkAction => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await getWorkspacesApi();
      resolve(dispatch(setWorkspaces(data.data)));
    } catch (error) {
      reject(error);
    }
  });
};

export const getWorkspaceSummary = (): ThunkAction => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await getWorkspaceSummaryApi();
      resolve(
        dispatch(
          setWorkspaceSummary(WorkspaceApiTransformer.toWorkspaceSummary(data))
        )
      );
    } catch (error) {
      reject(error);
    }
  });
};
