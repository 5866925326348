/** Nearmap oblique v2 APIs */

import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { IObject, NumericId } from '@/modules/common/types';
import { BBox } from '@turf/helpers';
import { AxiosResponse } from 'axios';
import { NearmapCoverageApiResponse } from '../../containers/nearmap-oblique-selection-dd/types';
import { replaceParams } from '@/helpers/utilities/linkUtils';
import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import {
  GET_NEARMAP_SURVEYS,
  GET_NEARMAP_PREVIEW_DETAILS,
  PURCHASE_DRAFT_NEARMAP_LIMIT,
  CHECK_PARCEL_EXCEEDS_LIMIT,
} from '@/helpers/constants/APIEndpoints';
import {
  NearmapPreviewDetailsApiResponse,
  NearmapExhaustedPreviewApiResponse,
  OrderBreakupApiResponse,
  PurchaseDraftNearmapLimitApiRequestBody,
} from './schema';
import * as turf from '@turf/turf';

const axios = getAxiosInstance();

export const getNearmapSurveys = async (
  data: IObject | BBox,
  orderId: number
): Promise<AxiosResponse<NearmapCoverageApiResponse>> => {
  const bbox = Array.isArray(data) ? data : turf.bbox(data);
  const bboxPolygon = turf.bboxPolygon(bbox);

  const getCoordinates = () => {
    return bboxPolygon.geometry.coordinates[0].join(',');
  };

  const response = await axios.post<NearmapCoverageApiResponse>(
    replaceParams(GET_NEARMAP_SURVEYS, {
      ':coordinates': getCoordinates(),
    }),
    {
      orderId: orderId,
    },
    {
      headers: {
        Accept: 'application/json',
      },
    }
  );

  return response;
};

export const getOrderBreakup = async (
  orderId: NumericId
): Promise<AxiosResponse<OrderBreakupApiResponse>> => {
  return await axios.get<OrderBreakupApiResponse>(
    replaceParams(API_ENDPOINTS.TAKEOFFS_BREAKUP_ENDPOINT, {
      ':orderId': orderId.toString(),
      headers: {
        Accept: 'application/json',
      },
    })
  );
};

export const getNearmapPreviewDetails = async (): Promise<
  AxiosResponse<NearmapPreviewDetailsApiResponse>
> => {
  return axios.get(GET_NEARMAP_PREVIEW_DETAILS);
};

export const purchaseDraftNearmapLimit = async (
  data: PurchaseDraftNearmapLimitApiRequestBody
) => {
  return axios.post(PURCHASE_DRAFT_NEARMAP_LIMIT, data, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const checkIfParcelExceedsLimit = async (
  orderId: NumericId,
  geoJson: any
) => {
  return axios.post(
    replaceParams(CHECK_PARCEL_EXCEEDS_LIMIT, {
      ':orderId': orderId,
    }),
    geoJson,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
};
