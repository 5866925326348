import { useSelector } from 'react-redux';
import { IStore } from '@/store/types';
import { useEffect } from 'react';

/** Returns current Layer is editable or not. If view is loading returns "null" */
const useLayer = () => {
  return useSelector<IStore>(
    (state) => state.feature.currentLayer?.isEditable || false
  );
};

export default useLayer;
