import { Button, Input, Modal, Upload } from 'antd';
import { useState } from 'react';

import { NotifyError } from '@/helpers/notification-utils';
import './style.less';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { UploadOutlined } from '@/components/elements/icons/antd-icons';
import useAuth from '@/modules/auth/hooks';
import { showNotification } from '../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../storybook/NotificationToast/types';

const FeedbackModal = ({ visible, onOk, onCancel }) => {
  const axiosInstance = getAxiosInstance();
  const { TextArea } = Input;
  const { userId } = useAuth();
  const [uploadingFeedback, setUploadingFeedback] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [feedbackFileList, setFeedbackFileList] = useState([]);

  const handleFeedbackSubmission = () => {
    const formData = new FormData();
    if (!feedbackFileList.length && !feedback) {
      NotifyError('Please leave a feedback.');
      return;
    }
    setUploadingFeedback(true);
    formData.append('userId', userId);
    formData.append('feedBackComment', feedback);
    formData.append(
      'uploadFile',
      feedbackFileList.length ? feedbackFileList[0]['originFileObj'] : null
    );

    axiosInstance
      .post('/api/order/userFeedbacks/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setUploadingFeedback(false);
          setFeedback('');
          setFeedbackFileList([]);
          showNotification(NOTIFICATIONS_TYPES.SUCCESS, res.data.success);
        }
      });
  };

  const uploadFeedback = (e) => {
    if (e.file) {
      let fileList = [...e.fileList];
      fileList = fileList.slice(-1);
      setFeedbackFileList(fileList);
    } else {
      setFeedbackFileList([]);
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      footer={null}
      onOk={onOk}
      onCancel={onCancel}
      style={{ height: '500px' }}
    >
      <div
        style={{
          fontSize: '20px',
          fontWeight: 'bolder',
          color: '#333333',
        }}
      >
        Send Feedback
      </div>
      <div style={{ margin: '5px 0 5px 0', color: '#333333' }}>
        We'd love to hear from you. How are you feeling about SiteRecon?
      </div>

      <TextArea
        style={{ height: '130px' }}
        onChange={(e) => setFeedback(e.target.value)}
        value={feedback}
      />

      <Upload
        showUploadList={true}
        beforeUpload={() => false}
        onChange={(e) => uploadFeedback(e)}
        accept='image/*,.pdf,.doc,.docx,.txt'
        fileList={feedbackFileList}
        style={{ margin: '10px 0' }}
      >
        <a style={{ color: '#333333' }}>
          <UploadOutlined />
          Upload a file
        </a>
      </Upload>
      <p style={{ color: '#333333', fontSize: '12px' }}>
        Upload an image , a word document , a pdf , a text file or any other
        document.
      </p>
      <div style={{ color: '#333333', fontSize: '12px' }}>
        Share feedback, suggest new features, or report technical issues. For
        example, you can tell us-
        <ol>
          <li>
            If you prefer other means to input address like multiple addresses
            or bulk CSV list
          </li>
          <li>Suggest property measurements required by you</li>
          <li>Suggest preferred outputs formats</li>
          <li>Customer information fields you need</li>
        </ol>
      </div>
      <Button
        onClick={handleFeedbackSubmission}
        loading={uploadingFeedback}
        type='primary'
        style={{ margin: '10px 0' }}
      >
        Submit
      </Button>
    </Modal>
  );
};

export default FeedbackModal;
