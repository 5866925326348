import React, { KeyboardEvent } from 'react';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { Tooltip } from '@siterecon/sr-component/dist/sr-component-library';
import { Input, Row } from 'antd';
import { useWorkspace } from '../../../../../modules/workspace/hooks/useWorkspace';
import { showNotification } from '../../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../storybook/NotificationToast/types';
import { ReactComponent as Info } from '@/assets/icons/Info.svg';
import './style.less';

const WorkspaceSwitcher = () => {
  const { change } = useWorkspace();

  const workspaceSwitchHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    // @ts-ignore
    const token = event.target.value;
    if (!token) return;

    const switched = change({ token: token });
    if (!switched) {
      showNotification(NOTIFICATIONS_TYPES.ERROR, 'Invalid Token');
    }
  };

  return (
    <Box
      style={{ border: '1px solid #C9C9C9', borderRadius: '4px' }}
      className='p-2 workspace-switcher'
    >
      <Row align='middle'>
        <Tooltip
          title='Input the workspace token here, allowing for a seamless transition to that workspace, obviating the need for manual selection from the profile dropdown menu.'
          placement='leftTop'
        >
          <Info width={15} className='mr-1' />
        </Tooltip>
        <Typography fontSize={9} fontWeight={600}>
          Only for testing purpose
        </Typography>
      </Row>
      <Input
        placeholder='Enter workspace token'
        size='small'
        allowClear
        onPressEnter={workspaceSwitchHandler}
      />
    </Box>
  );
};

export default WorkspaceSwitcher;
