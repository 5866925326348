import React from 'react';

const Info = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M8 3C10.7617 3 13 5.23826 13 8C13 10.7617 10.7617 13 8 13C5.23826 13 3 10.7617 3 8C3 5.23826 5.23826 3 8 3ZM6.6848 7.30263C6.67758 6.80178 7.82309 6.52655 8.34249 6.6642C9.65978 7.0129 7.75372 10.0769 8.08278 10.53C8.2659 10.7825 9.09893 10.5838 9.09893 10.8125C9.09893 11.0423 8.53398 11.6373 7.5716 11.4396C6.60821 11.2409 6.86799 10.5684 6.99835 10.0261C7.1277 9.48391 7.68545 7.98559 7.68545 7.72578C7.68545 6.91145 6.6724 7.51676 6.68482 7.3026L6.6848 7.30263ZM8.56292 4.52112C8.9789 4.52112 9.31518 4.8574 9.31518 5.27338C9.31518 5.68936 8.9789 6.02565 8.56292 6.02565C8.14694 6.02565 7.81065 5.68936 7.81065 5.27338C7.81065 4.8574 8.14694 4.52112 8.56292 4.52112Z'
      fill='#999999'
    />
  </svg>
);

export default Info;
