// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector420IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector420Icon(props: Vector420IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 18 13"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M16.69 0c.363 1.344.47 2.796.266 4.275-.73 5.31-5.18 8.953-9.936 8.138C3.593 11.825.941 9.083 0 5.609l2.347-.791c.676 2.497 2.583 4.467 5.045 4.889 3.418.586 6.615-2.032 7.14-5.848a7.751 7.751 0 00-.19-3.073L16.688 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector420Icon;
/* prettier-ignore-end */
