export const AUTH_STATE = {
  NULL: null,
  LOGIN: 'login',
  RESET_PASSWORD: 'reset-password',
  FORGET_FORM: 'forget-form',
  SIGN_UP: 'sign-up',
  OTP: 'otp',
  INVITED_USER: 'invited-user',
  SELECT_ADMIN: 'select-admin',
  NEW_ORG: 'new-org',
  ORG_WORKSPACE: 'org-workspace',
  ORG_TITLE: 'org-title',
  LOGGED_IN: 'logged-in'
};

export const BACKGROUND_TYPE = {
  WITH_HOUSE: 'WITH_HOUSE',
  WITH_MOWER: 'WITH_MOWER',
};


export const API_STATUS = {
  "LOADING": "LOADING",
  "SUCCESS": "SUCCESS",
  "FAILURE": "FAILURE"
}