// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Subtract11IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Subtract11Icon(props: Subtract11IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 9 9"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M4.283 0A4.283 4.283 0 000 4.283v4.496h8.566V4.283A4.283 4.283 0 004.283 0zm-.001 6.31c1.035 0 1.874-.86 1.874-1.92 0-1.061-.84-1.921-1.874-1.921-1.035 0-1.874.86-1.874 1.92s.84 1.92 1.874 1.92z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Subtract11Icon;
/* prettier-ignore-end */
