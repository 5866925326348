import useOrganization from '@/modules/auth/hooks/organization';
import { Organization } from '@/store/teamManagement/state';
import { ID, Nullable, NumericId } from '@common/types';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@/store/types';
import { Workspace } from '@common/models/workspace';
import { workspaceSwitchThunk as switchWorkspace } from '@/store/user/thunks';

export interface ChangeWorkspaceFilter {
  title?: string;
  token?: string;
}

export type ChangeWorkspacePayload = NumericId | ChangeWorkspaceFilter;

export type ChangeWorkspaceFunction = (
  workspace: ChangeWorkspacePayload,

  /** The page where user should be redirected after switching workspace */
  redirect?: string
) => boolean;

export interface UseWorkspaceResponse {
  workspace: Nullable<Organization>;

  change: ChangeWorkspaceFunction;
}

/** This is an alias for `useOrganization` hook with additional functionalities. Use this instead of useOrganization, in future we'll replace all the usage of `useOrganization` to `useWorkspace` */
export const useWorkspace = (): UseWorkspaceResponse => {
  const dispatch = useDispatch();

  const workspace = useOrganization();
  const workspaces = useSelector<IStore, Workspace[]>(
    (state) => state.workspace.list
  );
  const reqId = useSelector<IStore, Nullable<ID>>((state) => state.user.reqId);

  const change: ChangeWorkspaceFunction = (newWorkspace, redirect) => {
    const getWorkspaceData = () => {
      if (typeof newWorkspace === 'object') {
        if (newWorkspace.title) {
          /** We have to find workspace by title */
          return workspaces.find(
            (workspace) => workspace.workspaceName === newWorkspace.title
          );
        }

        if (newWorkspace.token) {
          /** We have to find workspace by token */
          return { token: newWorkspace.token };
        }

        return;
      }

      const _workspace = workspaces.find(
        (workspace) => workspace.workspaceId === newWorkspace
      );

      return _workspace || { workspaceId: newWorkspace };
    };

    const data = getWorkspaceData();

    if (!data) {
      /** We don't have any matching workspace to switch */
      return false;
    }

    dispatch(switchWorkspace({ workspaceData: data, reqId, redirect }));
    return true;
  };

  return { workspace, change };
};
