import React, { useEffect } from 'react';
import ExclamationDark from '@/assets/icons/ExclamationDark';
import { NotificationBarType } from './types';
import useNotificationBar from './useNotificationBar';
import { SubscriptionState } from '../../../subscription/helpers/enum';
import { useSubscription } from '@/modules/subscription/hooks';
import { NotificationBar } from '@siterecon/sr-component/dist/sr-component-library';
import { format } from 'date-fns';
import { PreviousSubscription } from '../../../subscription/helpers/types';
import { capitalize } from 'lodash';
import { EXPLORE_SUBSCRIPTION_PLANS } from '../../../subscription/helpers/urls';
import { openUrl } from '@/components/utils';
import { Nullable } from '@common/types';
import { trackEvents } from '../../../../helpers/utilities';
import { NotificationBarEvt } from '../../../../segment';

const TYPE = NotificationBarType.SubscriptionTerminated;

const SubscriptionTerminatedNotificationBar = () => {
  const { isVisible, show, hide } = useNotificationBar();

  const { subscription, subscriptionState } = useSubscription();

  const previousSubscription: Nullable<PreviousSubscription> =
    subscription?.previousSubscription || null;

  const isLegacyPreviousPlan = previousSubscription && [SubscriptionState.LEGACY_PROFESSIONAL,
    SubscriptionState.LEGACY_ADVANCED].includes(previousSubscription?.platformAccessType)

  const handleReactivate = () => {
    trackEvents(NotificationBarEvt.ReactivateCtaClicked);
    openUrl(EXPLORE_SUBSCRIPTION_PLANS);
  };

  const handleClose = () => hide(TYPE);

  useEffect(() => {
    if (!subscriptionState) return;

    if (subscriptionState === SubscriptionState.AUTO_DOWNGRADED &&
      ![SubscriptionState.LEGACY_PROFESSIONAL,
      SubscriptionState.LEGACY_ADVANCED].includes(subscriptionState) &&
      !isLegacyPreviousPlan) {
      show(TYPE);
    }
  }, [subscriptionState]);

  if (!isVisible(TYPE) || !subscription || !previousSubscription) {
    return null;
  }

  if (isVisible(TYPE)) {
    trackEvents(NotificationBarEvt.ReactivateVisible);
  }

  const endedOnDate = format(
    new Date(previousSubscription.endedOn),
    'MMMM d, yyyy'
  );

  return (
    <NotificationBar
      icon={<ExclamationDark />}
      message={`Your ${capitalize(
        previousSubscription.platformAccessType
      )} subscription was terminated on ${endedOnDate}. Please reactivate your plan to access all its benefits and features.`}
      action={{ label: 'Reactivate', onClick: handleReactivate }}
      onClose={handleClose}
    />
  );
};

export default SubscriptionTerminatedNotificationBar;
