import React, { createContext, useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { WidgetBarTabs } from '../../../components/types';
import { setIsNotesPanelExpanded } from '@/store/notes/actions';
import { NotePanelType } from '../../../components/notes-reports/types';
import { Nullable, ViewType } from '../../common/types';
import useViewSelectionOrCreationUtil from './useViewSelectionOrCreationUtil';
import { useElementPositioningContext } from '../../../Contexts/ElementPositioningContext';
import { unByKey } from 'ol/Observable';
import { ToolsTypes } from '@/components/containers/navbar/tools/toolsTypes';
import { removeCutInteraction } from '@/helpers/mapUtils/tools/cutTool';
import { removeLineSliceInteraction } from '@/helpers/mapUtils/tools/lineSliceTool';
import { removeDoughnutInteraction } from '@/helpers/mapUtils/tools/doughnutTool';
import { removeDrawInteraction } from '@/helpers/mapUtils/tools/drawTool';
import { removeMeasureInteraction } from '@/helpers/mapUtils/tools/measurementTool';
import { removeModifyInteraction } from '@/helpers/mapUtils/tools/modifyTool';
import { removeSelectInteraction } from '@/helpers/mapUtils/featuresUtils';
import { removeSnipInteraction } from '@/helpers/mapUtils/tools/snipTool';
import { resetCrossLayerSnap } from '@/helpers/mapUtils/tools/snapping';
import { removeDragBoxInteraction } from '@/helpers/mapUtils/tools/multiSelecteTool';
import { mapClickHandlerToTrackDrawPointsForHistory } from '@/components/containers/map-tools-panel/MapToolsPanel';
import MapContext from '@/components/pages/project/MapContext';
import { setMapClassnames, setActiveTool } from '@/store/user/actions';
import OlMap from 'ol/Map';
import useViewType from '../../project/guards/ViewGuard/useViewType';
import { trackEvents } from '../../../helpers/utilities';
import { CreateOrderEvt } from '../../../segment';
import { VIEW_PANEL_SOURCE } from '../../../events/constants';
import { useOrderStatus } from '../../project/guards/OrderStatus';

interface WidgetBarTabContextValue {
  selectedTab: Nullable<WidgetBarTabs>;
  activeTabs: Nullable<WidgetBarTabs[]>;
  disabledTabs: WidgetBarTabs[];
  handleWidgetBarTabChange: (tab: WidgetBarTabs,prevTab?: Nullable<WidgetBarTabs>) => void;
  disableTab: (tab: WidgetBarTabs) => void;
  enableTab: (tab: WidgetBarTabs) => void;
  handleCloseAllWidgetBarTabs: () => void;
  handleDismissCurrentPanel: (newTab: WidgetBarTabs) => void;
  handleActiveWidgetBarTabs: (tabs: WidgetBarTabs[]) => void;
  handleCloseWidgetBarTab: (tab: WidgetBarTabs) => void;
}

const WidgetBarTabContext = createContext<WidgetBarTabContextValue | undefined>(
  undefined
);

export const useWidgetBarTabContext = () => {
  const context = useContext(WidgetBarTabContext);
  if (!context) {
    throw new Error(
      'useWidgetBarTabContext must be used within a WidgetBarTabProvider'
    );
  }
  return context;
};

export const WidgetBarTabProvider: React.FC<{
  initialTab: Nullable<WidgetBarTabs>;
  disabledTabs?: WidgetBarTabs[];
  children: React.ReactNode;
}> = ({ children, initialTab, disabledTabs = [] }) => {
  const mapRef: OlMap = useContext(MapContext);

  /** Selected tab is the tab content for which needs to be shown [Only one can be selected] */
  /** Active tabs are the tabs which are currently highlighed [Multiple can be active] */
  
  const [selectedTab, setSelectedTab] = useState<Nullable<WidgetBarTabs>>(initialTab);
  const [activeTabs, setActiveTabs] = useState<Nullable<WidgetBarTabs[]>>(
    initialTab ? [initialTab] : null
  );
  const [disabledTabsState, setDisabledTabsState] =
    useState<WidgetBarTabs[]>(disabledTabs);
  const dispatch = useDispatch();

  const { handleEstimationViewClick, handleStaticViewClick } =
    useViewSelectionOrCreationUtil();

  const orderStatus = useOrderStatus();

  const {
    setNormalLayerPanelOpen,
    setEstimationViewServiceMapTabOpen,
    setNotesReportsSnapsPanelOpen,
    isViewPinned,
  } = useElementPositioningContext();

  const currentViewType = useViewType();

  useEffect(() => {
    if (isViewPinned) {
      setActiveTabs([WidgetBarTabs.VIEWS, ...(activeTabs || [])]);
    } else {
      setActiveTabs(
        activeTabs?.filter((tab) => tab !== WidgetBarTabs.VIEWS) || null
      );
    }
  }, [isViewPinned]);

  const WIDGET_BAR_SUPERSET_TABS = [
    WidgetBarTabs.LAYERS,
    WidgetBarTabs.ESTIMATION,
  ];
  const WIDGET_BAR_SUBSET_TABS = [
    WidgetBarTabs.NOTES,
    WidgetBarTabs.REPORTS,
    WidgetBarTabs.SNAPSHOT,
  ];

  /** Only one of the superset tabs can be active, also only one of the subset tabs can be active in conjunction with the respective superset tab */
  const handleWidgetBarTabChange = (tab: WidgetBarTabs, prevTab?: Nullable<WidgetBarTabs>) => {
    trackEvents(CreateOrderEvt.WidgetBarTabChange, {
      prevTab: prevTab,
      nextTab: tab,
      viewType: currentViewType
    });

    if (disabledTabsState.includes(tab)) return;

    if (tab === WidgetBarTabs.LAYERS && prevTab === WidgetBarTabs.ESTIMATION) {
      trackEvents(CreateOrderEvt.ViewPanelSwitchToLinkedView, {
        source: VIEW_PANEL_SOURCE.WIDGET_BAR,
      });
    }

    if (tab === WidgetBarTabs.VIEWS) {
      if (!activeTabs?.includes(WidgetBarTabs.VIEWS)) {
        trackEvents(CreateOrderEvt.ViewPanelVisible, {
          source: VIEW_PANEL_SOURCE.WIDGET_BAR,
          orderStatus: orderStatus
        })
      }
      setActiveTabs((prevActiveTabs) => [...(prevActiveTabs || []), tab]);
    } else {
      setActiveTabs([tab, ...(isViewPinned ? [WidgetBarTabs.VIEWS] : [])]);
    }

    if (tab !== WidgetBarTabs.VIEWS) setSelectedTab(tab);

    if (tab === WidgetBarTabs.ESTIMATION) resetStateBeforeSwitchingViewType();

    if ([WidgetBarTabs.SNAPSHOT, WidgetBarTabs.NOTES, WidgetBarTabs.REPORTS].includes(tab)) {
      handleStaticViewClick();
    }
    
    // const handleViewSwitchOnEstView = () => {
    //   if ([ViewType.ESTIMATION, ViewType.DYNAMIC].includes(currentViewType)) {
    //     handleStaticViewClick();
    //   }
    // };

    // const handleSwitchToEstView = () => {
    //   handleEstimationViewClick();
    //   resetStateBeforeSwitchingViewType();
    //   setActiveTabs([tab, ...(isViewPinned ? [WidgetBarTabs.VIEWS] : [])]);
    // };

    // const tabHandlers = new Map([
    //   [WidgetBarTabs.LAYERS, handleStaticViewClick],
    //   [WidgetBarTabs.ESTIMATION, handleSwitchToEstView],
    //   [WidgetBarTabs.NOTES, handleViewSwitchOnEstView],
    //   [WidgetBarTabs.REPORTS, handleViewSwitchOnEstView],
    //   [WidgetBarTabs.SNAPSHOT, handleViewSwitchOnEstView],
    // ]);

    // const handler = tabHandlers.get(tab) || (() => {});
    // handler();
  };

  const disableTab = (tab: WidgetBarTabs) => {
    setDisabledTabsState((prevDisabledTabs) => [...prevDisabledTabs, tab]);
  };

  const enableTab = (tab: WidgetBarTabs) => {
    setDisabledTabsState((prevDisabledTabs) =>
      prevDisabledTabs.filter((t) => t !== tab)
    );
  };

  const resetStateBeforeSwitchingViewType = () => {
    /** We need to reset the active tool state */
    dispatch(setActiveTool(ToolsTypes.SelectTool.action));

    if (mapClickHandlerToTrackDrawPointsForHistory) {
      //To remove point level tracking onclick interactions
      unByKey(mapClickHandlerToTrackDrawPointsForHistory);
      // setActiveDeActiveInteraction(that.props.mapRef, DragPan, true);
    }

    if (mapRef) {
      removeDragBoxInteraction(mapRef);

      removeCutInteraction(mapRef);
      removeLineSliceInteraction(mapRef);
      removeDoughnutInteraction(mapRef);
      removeDrawInteraction(mapRef);
      removeMeasureInteraction(mapRef);
      removeModifyInteraction(mapRef);
      removeSelectInteraction(mapRef);
      removeSnipInteraction(mapRef);
      resetCrossLayerSnap(mapRef);
    }

    dispatch(setMapClassnames('select'));
  };

  useEffect(() => {
    const resetAllPanels = () => {
      setNotesReportsSnapsPanelOpen(false);
      setEstimationViewServiceMapTabOpen(false);
      setNormalLayerPanelOpen(false);
    };

    resetAllPanels();

    if (selectedTab) {
      switch (selectedTab) {
        case WidgetBarTabs.NOTES:
          dispatch(setIsNotesPanelExpanded(NotePanelType.NOTES));
          setNotesReportsSnapsPanelOpen(true);
          break;
        case WidgetBarTabs.REPORTS:
          dispatch(setIsNotesPanelExpanded(NotePanelType.REPORTS));
          setNotesReportsSnapsPanelOpen(true);
          break;
        case WidgetBarTabs.SNAPSHOT:
          dispatch(setIsNotesPanelExpanded(NotePanelType.SNAPSHOTS));
          setNotesReportsSnapsPanelOpen(true);
          break;
        case WidgetBarTabs.ESTIMATION:
          dispatch(setIsNotesPanelExpanded(null));
          setEstimationViewServiceMapTabOpen(true);
          break;
        case WidgetBarTabs.LAYERS:
          dispatch(setIsNotesPanelExpanded(null));
          setNormalLayerPanelOpen(true);
          break;
        case WidgetBarTabs.ORDER:
          setNormalLayerPanelOpen(false);
          setEstimationViewServiceMapTabOpen(false);
        case WidgetBarTabs.VIEWS:
          break;
        default:
          break;
      }
    }
  }, [selectedTab]);

  const handleCloseAllWidgetBarTabs = () => {
    dispatch(setIsNotesPanelExpanded(null));
    setSelectedTab(null);
    setActiveTabs(null);
  };

  const handleCloseWidgetBarTab = (tab: WidgetBarTabs) => {
    setSelectedTab(null);
    setActiveTabs((prevActiveTabs) => {
      if (prevActiveTabs === null) {
        return null;
      } else {
        return prevActiveTabs.filter((t) => t !== tab);
      }
    });
  }

  const handleDismissCurrentPanel = (newTab: WidgetBarTabs) => {
    setSelectedTab(null);
    // setActiveTabs((prevActiveTabs) => prevActiveTabs.filter((tab) => tab !== newTab));
    setActiveTabs((prevActiveTabs) => {
      if (prevActiveTabs === null) {
        return null;
      } else {
        return prevActiveTabs.filter((tab) => tab !== newTab);
      }
    });

    switch (newTab) {
      case WidgetBarTabs.NOTES:
      case WidgetBarTabs.REPORTS:
      case WidgetBarTabs.SNAPSHOT:
        dispatch(setIsNotesPanelExpanded(null));
        break;
    }
  };

  const handleActiveWidgetBarTabs = (tabs: WidgetBarTabs[]) => {
    setActiveTabs(tabs);
  };

  const value = {
    selectedTab,
    activeTabs,
    disabledTabs: disabledTabsState,
    handleWidgetBarTabChange,
    disableTab,
    enableTab,
    handleCloseAllWidgetBarTabs,
    handleDismissCurrentPanel,
    handleActiveWidgetBarTabs,
    handleCloseWidgetBarTab
  };

  return (
    <WidgetBarTabContext.Provider value={value}>
      {children}
    </WidgetBarTabContext.Provider>
  );
};
