import React from 'react';

const SelectTool = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      d='M5 4.12531C4.99993 3.91198 5.06052 3.70303 5.17471 3.52283C5.28889 3.34264 5.45195 3.19862 5.64487 3.10758C5.8378 3.01655 6.05263 2.98224 6.26432 3.00867C6.476 3.03509 6.67581 3.12116 6.84043 3.25684L19.2217 13.4512C20.0362 14.1217 19.5614 15.4446 18.5062 15.4446H12.2155C11.9735 15.4446 11.7343 15.4966 11.5142 15.5972C11.2941 15.6977 11.0982 15.8444 10.9398 16.0273L6.97542 20.6093C6.2937 21.3967 5 20.9141 5 19.8724V4.12531ZM18.5062 14.3196L6.12496 4.12531V19.8724L10.0893 15.2916C10.3533 14.9866 10.6797 14.7419 11.0466 14.5742C11.4135 14.4065 11.8121 14.3197 12.2155 14.3196H18.5062Z'
      fill='#333333'
    />
  </svg>
);

export default SelectTool;
