import { BASE_URL } from './index';

const PHYLAX_V1 = `${BASE_URL}/api/phylax/v1`;
const MINT_V1 = `${BASE_URL}/api/mint/v1`;
const MINT_V2 = `${BASE_URL}/api/mint/v2`;
const EMPORIO_V1 = `${BASE_URL}/api/emporio/v1`
const PLUTUS_V1 = `${BASE_URL}/api/plutus/v1`;
const TEMPLATING_V1 = `${BASE_URL}/api/templating/v1`
const TEMPLATING_V2 = `${BASE_URL}/api/templating/v2`

const EMPORIO_V2 = `${BASE_URL}/api/emporio/v2`
const PLUTUS_V2 = `${BASE_URL}/api/plutus/v2`;

export const GET_ORGANISATION_AND_USER_CREDITS_DETAILS = `${BASE_URL}/api/plutus/v2/credits`;
export const ADD_CREDITS = `${PLUTUS_V1}/credits/add`;
export const REMOVE_CREDITS = `${PLUTUS_V1}/credits/remove`;
export const GET_ANNOUNCEMENTS_LIST = `${BASE_URL}/api/backend/v1/announcement`;
export const SHOW_ME_LATER = `${BASE_URL}/api/backend/v1/announcement/:modalId/dismiss`;
export const GET_ORDER_CREDIT_HISTORY = `${PLUTUS_V1}/credits/usage`;
export const GET_ORDER_CREDIT_HISTORY_EXPORT = `${PLUTUS_V1}/credits/usage/export`;
export const GET_ORDER_CREDIT_SUMMARY = `${PLUTUS_V1}/credits/summary`;
export const GET_BULK_ORDER_SUMMARY = `${BASE_URL}/api/v1/bulk-orders/summary/:projectId`;
export const GET_BULK_ORDER_LIST = `${BASE_URL}/api/v1/bulkorder-list/:projectId`;
export const CREATE_BULK_PROJECT = `${BASE_URL}/api/v1/bulkorder/`;
export const BULK_ORDERS_CSV_UPLOAD = `${BASE_URL}/api/v1/bulk-orders/:orderId`;
export const GET_BULK_ORDER_DETAILS = `${BASE_URL}/api/v1/bulkorder/:orderId`;
export const GET_BULK_ORDERS_LIST = `${BASE_URL}/api/v1/bulkorder/list/?p=:page&psz=:pageSize`;
export const CONFIRM_BULK_ORDER = `${BASE_URL}/api/v1/bulk-orders/create/:orderId`;
export const GET_ORDER_DETAILS = `${BASE_URL}/api/order/product/:orderHashed/`;
export const GET_ORDER_DETAILS_V2 = `${BASE_URL}/api/emporio/v2/order/hash/:orderHashed/`;
export const GET_NEARMAP = `${BASE_URL}/api/emporio/v2/orders/:orderId/images`;
export const CANCEL_PLAN = `${PLUTUS_V1}/subscription/workspace/change/cancel`;
export const SAVE_ORDER = `${BASE_URL}/api/order/saveOrder/?is_parcel_verified=false`;
export const SAVE_ORDER_V2 = `${BASE_URL}/api/emporio/v2/order`;
export const GET_ORDER_QUEUE = `${EMPORIO_V1}/queue`;
export const UPDATE_ORDER_DETAILS = `${BASE_URL}/api/order/updateOrderDetails/`;
export const UPDATE_ORDER_STATUS = `${BASE_URL}/api/order/updateStatus/`;
export const GET_FEATURE_LIST = `${TEMPLATING_V1}/feature?maintainer=user,siterecon,organization&status=closed,active&sendUserDetails=true`;
export const GET_ALL_USERS = `${BASE_URL}/api/v1/organization/users/:organizationId?psz=50`;
export const GET_ALL_TAGS = `${BASE_URL}/api/emporio/v1/order/tag`;
export const GET_REVIEW_TAGS = `${BASE_URL}/api/v1/review-tag/:organizationId/:orderId`;

export const FORGOT_PASSWORD_URL = `${BASE_URL}/api/user/forgot_password/`;
export const UPDATE_PASSWORD_URL = `${BASE_URL}/api/user/update/`;

// Views
export const CLONE_VIEW = `${TEMPLATING_V1}/view/:viewId/clone`;
export const CLONE_DYNAMIC_VIEW = `${TEMPLATING_V1}/view/:viewId/clone/dynamic`;
export const CLONE_ESTIMATION_VIEW = `${TEMPLATING_V1}/view/:viewId/clone/estimation`;
export const ORDER_VIEW = `${TEMPLATING_V2}/order/:orderId/view`;
export const ORDER_VIEW_DETAILS = `${TEMPLATING_V1}/order/:orderId/view/:viewId`;
export const ORDER_VIEW_ATTRIBUTE_FILTERS = `${TEMPLATING_V1}/view/:viewId/attribute/filter`;
export const SERVICE_ITEM_ASSIGNMENT = `${TEMPLATING_V1}/view/:viewId/component/service-item`;

export const LOCK_VIEW = `${TEMPLATING_V2}/view/:viewId/si-assignment/lock`;
export const UNLOCK_VIEW = `${TEMPLATING_V2}/view/:viewId/si-assignment/unlock`;

export const LOCK_VIEW_PRICING_TEAM = `${TEMPLATING_V2}/view/:viewId/si-assignment/lock/admin`
export const UNLOCK_VIEW_PRICING_TEAM = `${TEMPLATING_V2}/view/:viewId/si-assignment/unlock/admin`

export const NOTES_LIST = `${BASE_URL}/api/v2/order/note-list/:orderId/:viewId/:isBaseView`

//LAYERS
export const VIEW_LAYERS = `${TEMPLATING_V1}/view/:viewId/layer`;
export const VIEW_LAYERS_COMPONENTS = `${BASE_URL}/api/templating/v2/view/:viewId/layer/:layerId/component?offset=:offset&limit=:limit`;
export const VIEW_LAYERS_COMPONENT_WITHOUT_PAGINATION = `${BASE_URL}/api/templating/v2/view/:viewId/layer/:layerId/component`;
export const DOWNLOAD_LAYER_GEOJSON = `${TEMPLATING_V1}/view/:viewId/layer/:layerId/geojson`;
export const DOWNLOAD_LAYER_COMPONENT_GEOJSON = `${TEMPLATING_V1}/view/:viewId/layer/:layerId/component/:componentId/geojson`;
export const VIEW_LAYER_DETAILS = `${TEMPLATING_V1}/view/:viewId/layer/:layerId`;

//Features':offset':'0',':limit':2
export const FEATURES = `${TEMPLATING_V1}/feature`;
export const PARTICULAR_FEATURE = `${TEMPLATING_V1}/feature/:featureId`;

//Attributes
export const COPY_LAYER_VIEW_CLONE = `${TEMPLATING_V1}/view/:viewId/layer/:layerId/clone`;
export const REPLACE_LAYER_VIEW = `${TEMPLATING_V1}/view/:viewId/layer/:layerId/clone?replace=:replace`;

export const GENERATE_SHARE_MAP_ID = `${TEMPLATING_V1}/order/:orderId/share`;
export const GENERATE_ORDER_HASH = `${TEMPLATING_V1}/order/share/:hashId`;

export const UPDATE_COMPONENT = `${TEMPLATING_V1}/view/:viewId/layer/:layerId/component/:componentId`;
export const EXPORT_EXCEL = `${BASE_URL}/api/carto/exportMeasurement/:viewId/:hash`;
export const CREDIT_HISTORY_EXPORT = `${BASE_URL}/api/v1/order/credit-history/:org_id/export`;

export const MOVE_COMPONENT = `${TEMPLATING_V1}/view/:viewId/layer/:layerId/component/move?showTopologyWarning=true`;
export const COPY_COMPONENT = `${TEMPLATING_V1}/view/:viewId/layer/:layerId/component/copy?showTopologyWarning=true`;

export const VALIDATE_COMPONENT = `${BASE_URL}/api/karta/v1/validate`;

export const BULK_UPDATE_COMPONENT = `${TEMPLATING_V1}/view/:viewId/layer/:layerId/edit`;

export const SHARE_ORDER = `${BASE_URL}/api/order/shareProject/:orderHashed/`;

export const REPUBLISH_ORDER = `${EMPORIO_V1}/order/:orderId/republish`;

export const GLOBAL_ASSET = `${TEMPLATING_V1}/asset/`;
export const GLOBAL_ASSET_UPLOAD = `${TEMPLATING_V1}/asset/:type/:name`;

export const CONFIG_API = `${BASE_URL}/api/backend/v1/config`;
export const CONFIG_WITH_ORDER_ID_API = `${BASE_URL}/api/backend/v1/config/order/:orderId`;
export const LAYERS_GEO_JSON_UPLOAD = `${TEMPLATING_V1}/view/:viewId/layer/geojson`;
export const LAYERS_GEO_JSON_UPLOAD_FORCE = `${TEMPLATING_V1}/view/:viewId/layer/geojson?replace=true`;

export const DISMISS_RATTING = `${EMPORIO_V1}/order/:orderId/rating/dismiss`;

export const REPAIR_ORDER_IMAGE = `${BASE_URL}/api/v1/bulk-orders/:orderId/nearmap`;
export const REPAIR_ORDER_PARCEL = `${BASE_URL}/api/v1/bulk-orders/:orderId/parcel`;
export const REPUBLISH_TO_TABULA = `${BASE_URL}/api/v1/bulk-orders/:orderId/republish`;

export const GET_SUBSCRIPTION_DATA = `${PHYLAX_V1}/workspace/subscriptions`;
export const SUBSCRIPTION_REMINDER = `${PHYLAX_V1}/workspace/subscriptions/reminder`;
export const GET_INVOICES = `${PLUTUS_V1}/invoice/subscription/workspace`;
export const GET_SUBSCRIPTION_PLANS_DATA = `${PLUTUS_V1}/plan/workspace`;
export const GET_CURRENT_PLAN_DATA = `${PLUTUS_V1}/subscription/workspace`;
export const CREATE_DRAFT_SUBSCRIPTION = `${PLUTUS_V1}/subscription/workspace/draft`;
export const PURCHASE_DRAFT_SUBSCRIPTION = `${PLUTUS_V1}/subscription/workspace/purchase`;
export const TRIAL_RECHARGE = `${PLUTUS_V1}/takeoffs/commitment/purchase`;
export const CHANGE_SUBSCRIPTION_PLAN = `${PLUTUS_V1}/subscription/workspace/change`;
export const CONFIRM_SUBSCRIPTION_PAYMENT = `${PLUTUS_V1}/subscription/workspace/purchase/confirm`;
export const CONFIRM_COMMITMENT_PAYMENT = `${PLUTUS_V1}/takeoffs/commitment/purchase/confirm`;
export const REQUEST_SUBSCRIPTION_PLAN = `${PHYLAX_V1}/workspace/subscriptions/subscribe`;

export const STORE_FEEDBACK = `${BASE_URL}/api/order/userFeedbacks/`;

// Pricing
export const PRICING = `${PLUTUS_V1}/takeoffs/pricing/setup/workspace`;

export const GET_CREDITS_DATA = `${PLUTUS_V1}/takeoffs/commitment`;
export const GET_COMMITMENT_CREDITS_AMOUNT_OPTIONS = `${PLUTUS_V1}/takeoffs/commitment/setup/workspace`;
export const CALCULATE_COMMITMENT_CREDITS_AMOUNT_OPTION = `${PLUTUS_V1}/takeoffs/commitment/setup/calculate`;
export const GET_TRIAL_CREDITS_AMOUNT_OPTIONS = `${PLUTUS_V1}/takeoffs/commitment/trial`;
export const CALCULATE_TRIAL_CREDITS_AMOUNT_OPTION = `${PLUTUS_V1}/takeoffs/commitment/trial/calculate`;
export const PURCHASE_CREDITS_COMMITMENT_PLAN = `${PLUTUS_V1}/takeoffs/commitment/purchase`;
export const ANNUAL_COMMITMENT_PLAN = `${PLUTUS_V1}/takeoffs/commitment/subscription/purchase`;
export const REQUEST_CREDITS = `${PLUTUS_V1}/credits/request`;
export const REVIEW_ESTIMATION = `${EMPORIO_V2}/orders/:orderId/review-estimation`;

//  ToS Confirmation
export const TOS_CONFIRMED = `${BASE_URL}/api/v1/user/agreement`;

// Subscription 
export const GET_SUBSCRIPTION = `${MINT_V2}/subscription`;
export const GET_PAY_G_PREVIEW = `${PLUTUS_V2}/payg/preview`;
export const PROCESS_PAY_G_INVOICE = `${PLUTUS_V2}/payg/invoice`;
export const GET_PAY_G_INVOICE = `${PLUTUS_V2}/payg/invoice/:id`;
export const REQUEST_ADMIN_TO_UPGRADE_SUBSCRIPTION = `${PHYLAX_V1}/workspace/subscription/update`;
export const GET_SUBSCRIPTION_INVOICE = `${MINT_V1}/subscription/invoice/:id`;
export const SUBSCRIPTION_REQUEST_UPDATE = `${MINT_V1}/subscription/request-update`;

// Workspaces & Team Management
export const GET_WORKSPACES = `${EMPORIO_V2}/user/workspace`;
export const DISMISS_TRIAL = `${EMPORIO_V2}/user/seen-trial-popup`;
export const GET_WORKSPACE_SUMMARY = `${EMPORIO_V2}/workspace/summary`;
export const TAKEOFFS_BREAKUP_ENDPOINT = `${PLUTUS_V2}/takeoffs/pricing/order/:orderId/breakup`;

// Nearmap Obliques
export const GET_NEARMAP_SURVEYS = `${PLUTUS_V2}/coverage/:coordinates`;
export const GET_NEARMAP_PREVIEW_DETAILS = `${PLUTUS_V2}/nearmap/access`;
export const PURCHASE_DRAFT_NEARMAP_LIMIT = `${PLUTUS_V2}/credits/consume/imagery`;
export const CHECK_PARCEL_EXCEEDS_LIMIT = `${EMPORIO_V2}/orders/:orderId/parcel`;

// Property Reports
export const GENERATE_SNAPSHOT = `${EMPORIO_V2}/snapshot`;
export const FETCH_MEASUREMENTS = `${TEMPLATING_V1}/view/:viewId/filter/measurement`;
export const GET_ASSET_LIST = `${EMPORIO_V2}/asset-dropbox`;
export const DELETE_ASSET = `${EMPORIO_V2}/asset-dropbox/:assetId`;
export const ADD_ASSET_TO_PROPERTY_REPORT = `${EMPORIO_V2}/property-report/:reportId/page/custom-cover`;
export const POST_PROPERTY_REPORT = `${EMPORIO_V2}/property-report`;
export const UPDATE_PROPERTY_REPORT = `${EMPORIO_V2}/property-report/:reportId`;
export const DELETE_PROPERTY_REPORT_PAGE = `${EMPORIO_V2}/property-report/:reportId/page/:pageId`;
export const AUTOGEN_PROPERTY_REPORT_PAGES = `${EMPORIO_V2}/property-report/:reportId/auto-generate`;
export const EXPORT_PROPERTY_REPORT = `${EMPORIO_V2}/property-report/:reportId/export`;
export const CLONE_PROPERTY_REPORT = `${EMPORIO_V2}/property-report/:reportId/clone`;
export const ARRANGE_PROPERTY_REPORT = `${EMPORIO_V2}/property-report/:reportId/arrange`;
export const ADD_SNAPSHOT_PAGE_PROPERTY_REPORT = `${EMPORIO_V2}/property-report/:reportId/page/snapshot`;
export const UPDATE_SNAPSHOT_PAGE_PROPERTY_REPORT = `${EMPORIO_V2}/property-report/:reportId/page/:pageId/snapshot`;
export const MAKE_COVER_PAGE_PROPERTY_REPORT = `${EMPORIO_V2}/property-report/:reportId/page/:pageId/make-cover`;
export const REMOVE_COVER_PAGE_PROPERTY_REPORT = `${EMPORIO_V2}/property-report/:reportId/page/:pageId/remove-cover`;

// Workspace Map Dashboard
export const GET_PROPERTY_LIST = `${EMPORIO_V2}/order/location`;
export const GET_ALL_COORDINATES = `${EMPORIO_V2}/order/all-coordinates`;
export const GET_DASHBOARD_ORDER_LIST = `${EMPORIO_V2}/order/dashboard-list`;


// Bing Maps API
export const BING_AUTOSUGGEST_API = 'https://dev.virtualearth.net/REST/v1/Autosuggest'
export const BING_GEOCODE_API = 'https://dev.virtualearth.net/REST/v1/Locations'

export const NOTIFY_CTA = `${EMPORIO_V2}/notify-cta`

export const UPDATE_ORDER_DETAILS_V2 = `${EMPORIO_V2}/orders/:orderId`;

// Billing Information APIs
export const GET_BILLING_INFO = `${PLUTUS_V1}/billing/address`;
export const SAVE_BILLING_INFO = `${PLUTUS_V1}/billing/address`;
export const CHECK_BILLING_INFO_VALIDITY = `${PLUTUS_V1}/billing/address/status`;
