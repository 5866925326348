import React from 'react';
import { useEffect, useState } from 'react';
import { Popover } from 'antd';

import PathTool from '../../assets/tools/PathTool';
import SettingsIcon from '../../assets/common/SettingsIcon';

import Box from '@siterecon/sr-styles/dist/elements/Box';
import { Typography } from '@siterecon/sr-styles/dist/elements';
import { toolbarMenuItemSplit } from './ToolbarSplitToolItem';
import { toolbarMenuItemSimple } from './ToolbarNormalToolItem';
import { TOOLBAR_SETTINGS } from '../constant';
import { ToolKey, ToolList } from '../types';

import classNames from 'classnames';
import './style.less';

export interface ToolbarSettingsProps {
  handlePinning: (key: string) => void;
  pinned: string[];
  disableTool: boolean;
  SubscriptionChip: any;
  isEssential: boolean;
}

export const ToolbarSettings = ({
  handlePinning,
  pinned,
  disableTool,
  SubscriptionChip,
  isEssential,
}: ToolbarSettingsProps) => {
  const [tools, setTools] = useState(TOOLBAR_SETTINGS);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!tools.map((ele) => ele.key).includes(ToolKey.PATH)) {
      setTools([
        ...tools.slice(0, 4),
        {
          label: ToolList.PATH,
          icon: <PathTool />,
          shortcut: '',
          pinned: true,
          perm: false,
          key: ToolKey.PATH,
        },
        ...tools.slice(4),
      ]);
    }
  }, []);

  const [menu, setMenu] = useState(
    tools.map((ele) => {
      const updated = { ...ele };
      updated.pinned = pinned.includes(updated.key);
      return updated;
    })
  );

  useEffect(() => {
    setMenu(
      tools.map((ele) => {
        const updated = { ...ele };
        updated.pinned = pinned.includes(updated.key);
        return updated;
      })
    );
  }, [tools]);

  useEffect(() => {
    setMenu(
      tools.map((ele) => {
        const updated = { ...ele };
        updated.pinned = pinned.includes(updated.key);
        return updated;
      })
    );
  }, [pinned]);

  const Dropdown = (
    <Box className='w-100 px-2 pt-4 pb-2'>
      <Box className='header mb-2 ml-2'>
        <Typography
          fontSize={14}
          fontWeight={400}
          lineHeight={1.5}
          color='neutral-400'
        >
          Toolbar Menu
        </Typography>
      </Box>
      <Box>
        {menu.map((ele) => {
          if (ele?.isSplitTool) {
            return toolbarMenuItemSplit(
              ele,
              handlePinning,
              SubscriptionChip,
              isEssential
            );
          }
          return toolbarMenuItemSimple(ele, handlePinning, isEssential);
        })}
      </Box>
    </Box>
  );

  const handleVisibility = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Box
      className='settings-container'
      style={{ opacity: disableTool ? 0.4 : 1 }}
    >
      <Popover
        content={disableTool ? <></> : Dropdown}
        placement='topRight'
        trigger='click'
        visible={open}
        overlayClassName='settings-popover'
        onVisibleChange={handleVisibility}
      >
        <Box
          className={`settings-icon ${classNames('', {
            disabled: disableTool,
            'settings-icon--active': open,
          })}`}
        >
          <SettingsIcon />
        </Box>
      </Popover>
    </Box>
  );
};
