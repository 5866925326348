import React, { Component } from 'react';
import { Card, Col, Image, Row } from 'antd';
import { ReactComponent as SiterconLogo } from '../Partner/assets/Siterecon Logo Complete.svg';
import './style.less';
import { connect } from 'react-redux';
import Text from '../../elements/text/Text';

class Partner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { configurations } = this.props;
    return (
      <div className={'partner-container'}>
        <Card className={'partner-card py-1'}>
          <Row align={'middle'} justify={'center'}>
            <Col span={3}>
              <Row align={'middle'} justify={'center'}>
                <Image
                  preview={false}
                  src={configurations?.orgIcon}
                  style={{ width: '28px' }}
                />
              </Row>
            </Col>
            <Col span={12}>
              <Text type='p62' color='dark-gray'>
                {configurations?.channelPartnerName}
              </Text>
              <Text type='p33' color='dark-gray'>
                {' '}
                powered by
              </Text>
            </Col>
            <Col span={8}>
              <Row align={'middle'} justify={'center'}>
                <SiterconLogo />
              </Row>
            </Col>
            {/* <Col span={6}>
              <Text type='p26' color='dark-gray' className='pl-1'>
                <b>SiteRecon</b>
              </Text>
            </Col> */}
          </Row>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    configurations: state.configurations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Partner);
