import { Box } from '@common/elements';
import { BoxProps } from '@common/elements/Box';
import classNames from 'classnames';
import { Spin } from 'antd';
import { SpinSize } from 'antd/lib/spin';

interface LoaderProps extends BoxProps {
  /** (Optional) Height you want for Loader container */
  height?: number;

  size?: SpinSize;
}

const Loader = ({
  height,
  className,
  style,
  size = 'default',
  ...rest
}: LoaderProps) => {
  return (
    <Box
      flex
      align='middle'
      justify='center'
      className={classNames('px-4 py-4', className)}
      style={{ height: height, ...style }}
      {...rest}
    >
      <Spin size={size} />
    </Box>
  );
};

export default Loader;
