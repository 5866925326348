import { MenuItemProps, Popover, Tooltip } from 'antd';
import React, { ReactNode, useState, useEffect, useMemo } from 'react';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import './style.less';
import classNames from 'classnames';
import Down from '../../assets/icons/DownArrow';
import Info from '../../assets/icons/Info';
import { FeatureType } from '../../../../../feature-attributes/types';
import { SubTool, ToolKey, ToolList } from '../types';
import { getWarningMsg } from '../utils';
import LayerData from './LayerData';
import NoLayerText from './NoLayerText';
import { DRAW_TOOLS } from '../constant';
import { SubscriptionPlanIcons } from '../../../../../../modules/subscription/components/Chips/constant';

export type MapSubToolItemData = Omit<MapToolItemData, 'sub'>;

export interface MapToolItemData {
  title: ToolList;
  icon: ReactNode;
  keyVal: ToolKey;
  viableLayers: FeatureType[];
  sub?: MapSubToolItemData[];
}

export interface MapToolProps
  extends Omit<MenuItemProps, 'icon' | 'title'>,
  MapToolItemData {
  expand?: boolean;
  selected?: string;
  clickAction?: (key: string) => void;
  changeLayer?: boolean;
  changeSubLayer?: boolean;
  layers?: any[];
  changeLayerClick?: (layer: any, key: string) => void;
  addLayerHandler?: () => void;
  disableTool?: boolean;
  allLayers?: any[];
  chosenLayer?: any;
  SubscriptionChip?: any;
  isEssential?: boolean;
}

export const MapTool = ({
  title,
  icon,
  keyVal,
  expand,
  sub,
  selected,
  clickAction,
  changeLayer,
  changeSubLayer,
  layers,
  changeLayerClick,
  addLayerHandler,
  disableTool,
  viableLayers,
  allLayers,
  chosenLayer,
  SubscriptionChip,
  isEssential,
}: MapToolProps) => {
  const [iconSelected, setIconSelected] = useState(icon);
  const [titleSelected, setTitleSelected] = useState(title);
  const [popup, setPopup] = useState<boolean>(Boolean(changeLayer));
  const [open, setOpen] = useState<boolean>(Boolean(changeSubLayer));
  const [selectedSubTool, setSelectedSubTool] =
    useState<MapSubToolItemData | null>();
  const [showSubPopover, setShowSubPopover] = useState<boolean>(
    Boolean(changeSubLayer)
  );
  const [layersList, setLayersList] = useState(layers);

  useEffect(() => {
    setPopup(Boolean(changeLayer));
  }, [changeLayer]);

  useEffect(() => {
    setOpen(Boolean(changeSubLayer));

    if (!changeSubLayer) {
      setShowSubPopover(Boolean(changeSubLayer));
    }
  }, [changeSubLayer]);

  useEffect(() => {
    if (!sub) {
      setLayersList(layers);
    }
  }, [layers, sub]);

  useEffect(() => {
    if (sub && selected) {
      if (!sub.map((item: SubTool) => item.keyVal).includes(selected[0])) {
        setIconSelected(icon);
        setTitleSelected(title);
      }
    }
  }, [selected]);

  const getSubViableLayers = () => {
    if (!layersList) {
      return;
    }
    const newList = [];
    layersList.map((element) => {
      if (viableLayers.includes(element.featureType)) {
        newList.push(element.featureType);
      }
    });
    return [...new Set(newList)];
  };

  const sortedLayers = useMemo(() => {
    if (!layersList) return [];
    return layersList.sort((a, b) => Number(b.isEditable) - a.isEditable);
  }, [layersList]);

  const wrongLayerContent = (
    <Box className='wrong-layer-container'>
      {!DRAW_TOOLS.includes(keyVal) &&
        !chosenLayer?.isEditable &&
        viableLayers.length !== 4 && (
          <Box className='header'>
            <Box className='header-inner'>
              <Box style={{ width: '16px', height: '16px' }}>
                <Info />
              </Box>
              {sub ? (
                <Typography fontSize={12} fontWeight={400} lineHeight={'18px'}>
                  {title} tool can be used on{' '}
                  {getWarningMsg(getSubViableLayers())}
                </Typography>
              ) : (
                <Typography fontSize={12} fontWeight={400} lineHeight={'18px'}>
                  {title} tool can be used on {getWarningMsg(viableLayers)}
                </Typography>
              )}
            </Box>
          </Box>
        )}

      <Box className='select-layer-section w-100'>
        {DRAW_TOOLS.includes(keyVal) && chosenLayer?.isEditable && (
          <Box className='select-layer-header'>
            <Typography
              fontWeight={400}
              fontSize={14}
              lineHeight={'16px'}
              color='neutral-400'
            >
              Draw {title} in
            </Typography>
          </Box>
        )}

        <Box>
          {chosenLayer?.isEditable && layersList ? (
            <>
              {layersList.filter((element) =>
                viableLayers?.includes(element.featureType)
              ) ? (
                <>
                  {!DRAW_TOOLS.includes(keyVal) && (
                    <Box className='select-layer-header'>
                      <Typography
                        fontWeight={500}
                        fontSize={14}
                        lineHeight={'21px'}
                        color='#666'
                      >
                        Select Layer
                      </Typography>
                    </Box>
                  )}
                  <Box className='radio-set w-100'>
                    {layersList &&
                      layersList.filter((element) =>
                        viableLayers?.includes(element.featureType)
                      ).length ? (
                      layersList
                        .filter((element) =>
                          viableLayers?.includes(element.featureType)
                        )
                        .map((layer) => {
                          return (
                            <LayerData
                              layer={layer}
                              chosenLayer={chosenLayer}
                              onChange={() => onChange(layer.layerId, keyVal)}
                              keyVal={keyVal}
                            />
                          );
                        })
                    ) : (
                      <NoLayerText />
                    )}
                  </Box>
                </>
              ) : (
                <NoLayerText />
              )}
            </>
          ) : (
            <>
              {sortedLayers ? (
                <>
                  <Box className='select-layer-header'>
                    <Typography
                      fontWeight={400}
                      fontSize={14}
                      lineHeight={'16px'}
                      color='neutral-400'
                    >
                      {DRAW_TOOLS.includes(keyVal)
                        ? `Draw ${title} in`
                        : 'Select Layer'}
                    </Typography>
                  </Box>

                  <Box className='radio-set w-100'>
                    {sortedLayers.length ? (
                      sortedLayers.map((layer) => {
                        return (
                          <LayerData
                            layer={layer}
                            chosenLayer={chosenLayer}
                            onChange={() => onChange(layer.layerId, keyVal)}
                            keyVal={keyVal}
                          />
                        );
                      })
                    ) : (
                      <NoLayerText />
                    )}
                  </Box>
                </>
              ) : (
                <NoLayerText />
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );

  const handleSubDropdownClose = (newOpen: boolean) => {
    setOpen(newOpen);
    setSelectedSubTool(null);
  };

  const handleSubLayerDropdownClose = (newOpen: boolean) => {
    setShowSubPopover(newOpen);
    setSelectedSubTool(null);
  };

  const subToolClickHandler = (ele: SubTool) => {
    const filteredLayers = layers?.filter((element) =>
      ele.viableLayers?.includes(element.featureType)
    );

    setSelectedSubTool(ele);
    setLayersList(filteredLayers);
    setShowSubPopover(true);
  };

  const subDropdown = (
    <Box className='sub-tool-dropdown'>
      {sub &&
        selected &&
        sub.map((ele: any) => {
          return (
            <Box>
              <Box
                className={`sub-tool ${classNames('', {
                  selected: selected[0] == ele.keyVal,
                })}`}
                onClick={() => subToolClickHandler(ele)}
              >
                <span
                  style={{
                    display: 'flex',
                    filter: selected[0] == ele.keyVal ? 'brightness(100)' : '',
                  }}
                >
                  {ele.icon}
                </span>
                <Typography fontSize={12} fontWeight={500} lineHeight={'16px'}>
                  {ele.title}
                </Typography>
                {isEssential && (
                  <span
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      right: '10px',
                      alignItems: 'center',
                      filter:
                        selected[0] == ele.keyVal ? 'brightness(100)' : '',
                    }}
                  >
                    <SubscriptionChip iconProp={SubscriptionPlanIcons.PROFESSIONAL} />
                  </span>
                )}
              </Box>
            </Box>
          );
        })}
      <Popover
        visible={showSubPopover}
        onVisibleChange={handleSubLayerDropdownClose}
        trigger={'click'}
        content={wrongLayerContent}
        overlayClassName='sub-layer-popover'
        placement='left'
      />
    </Box>
  );

  const onChange = (id: number, key: string) => {
    let foundLayer = layers && layers.find((item) => item.layerId == id);

    if (sub) {
      if (selectedSubTool) {
        setIconSelected(selectedSubTool.icon);
        setTitleSelected(selectedSubTool.title);
        changeLayerClick?.(foundLayer, selectedSubTool.keyVal);
      }

      setOpen(false);
      setShowSubPopover(false);
      setSelectedSubTool(null);
      return;
    }

    setPopup(false);
    changeLayerClick?.(foundLayer, key);
  };

  const innerContent = (
    <Box
      className={classNames('tool', {
        selected:
          sub && selected
            ? sub
              .map((item) => {
                return item.keyVal;
              })
              .includes(selected[0])
            : selected == keyVal,
        disabled: disableTool,
        popover: popup,
      })}
      style={{ background: `${sub && open ? '#E9E9E9' : ''}` }}
    >
      <span
        style={{
          display: 'flex',
          filter:
            selected == keyVal ||
              (sub &&
                sub?.length > 0 &&
                selected &&
                sub
                  .map((item) => {
                    return item.keyVal;
                  })
                  .includes(selected[0]))
              ? 'brightness(100)'
              : '',
        }}
      >
        {iconSelected}
      </span>
      {expand && (
        <Typography fontSize={12} fontWeight={500} lineHeight={'16px'}>
          {titleSelected}
        </Typography>
      )}
      <span
        style={{
          display: 'flex',
          filter:
            selected == keyVal ||
              (sub &&
                selected &&
                sub
                  .map((item) => {
                    return item.keyVal;
                  })
                  .includes(selected[0]))
              ? 'brightness(100)'
              : '',
        }}
      >
        {sub ? <Down /> : <></>}
      </span>

      {isEssential &&
        keyVal === ToolKey.PATH &&
        <SubscriptionChip iconProp={SubscriptionPlanIcons.PROFESSIONAL} />
      }

    </Box>
  );

  return (
    <div
      className='map-tool-container'
      style={{
        opacity: disableTool ? 0.4 : 1,
      }}
    >
      {!disableTool ? (
        popup ? (
          <Popover
            trigger={'click'}
            placement='bottomLeft'
            visible={popup}
            content={wrongLayerContent}
            overlayClassName='wrong-layer-popover'
            onVisibleChange={() => setPopup(false)}
          >
            <Tooltip title={expand ? '' : titleSelected}>
              {innerContent}
            </Tooltip>
          </Popover>
        ) : sub ? (
          <Popover
            visible={open}
            onVisibleChange={handleSubDropdownClose}
            trigger={'click'}
            content={sub ? subDropdown : <></>}
            overlayClassName='sub-tools-popover'
            placement='bottomLeft'
          >
            <Tooltip title={expand ? '' : titleSelected}>
              {innerContent}
            </Tooltip>
          </Popover>
        ) : (
          <Tooltip title={expand ? '' : titleSelected}>{innerContent}</Tooltip>
        )
      ) : (
        <Tooltip title={expand ? '' : titleSelected}>{innerContent}</Tooltip>
      )}
    </div>
  );
};
