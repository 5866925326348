import { ACTIONS } from './action_types';

export const setDefault = () => ({
  type: ACTIONS.SET_DEFAULT,
});

export const setAll = (config) => ({
  type: ACTIONS.SET_CONFIGURATIONS,
  payload: config,
});
