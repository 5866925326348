import {
    ADD_ALL_FILTERS,
    ADD_BULK_ORDER_ID,
    ADD_BULK_VIEW_SUMMARY,
    ADD_MORE_SLICED_ORDERS,
    ADD_ORDERS,
    ADD_SLICED_ORDER,
    BULK_EDIT_DESCRIPTION,
    CLEAR_BULK_SUMMERY_WITH_ORDER,
    CLEAR_ORDER_ARRAY,
    DELETE_ORDER_WITH_ID,
    EDIT_BULK_DESCRIPTION_IN_BULK_PAGE,
    RESET_DASHBOARD_STATE,
    SET_FILTER_DATA,
    TOGGLE_DASHBOARD_LOADING,
    TOGGLE_REQUEST_PENDING_SCREEN,
    UPDATE_A_ORDER_WITH_ID,
    UPDATE_BULK_SUMMERY,
    UPDATE_BULK_SUMMERY_ORDER_COUNT,
    UPDATE_COUNT_BULK_ORDER_DUPLICATE,
    UPDATE_ORDER_PAGE,
    UPDATE_ORDER_SEARCH,
    UPDATE_TAG_BY_ID,
} from './actions.types';

// me
export const addOrdersForCreateByMe = (payload) => ({
  type: ADD_ORDERS,
  payload: {
    tab: payload.tab,
    data: payload.data,
    next: payload?.next ?? false,
    prev: payload?.prev ?? false,
    count: payload?.count,
    currentPage: payload?.currentPage ?? 1,
    totalPage: payload?.totalPage ?? 1,
    totalResult: payload?.totalResult ?? 1,
  },
});

export const toggleRequestPendingScreen = (payload) => ({
  type: TOGGLE_REQUEST_PENDING_SCREEN,
  payload: {
    tab: payload.tab,
    data: payload.data,
  },
});

export const clearOrderArray = (payload) => ({
  type: CLEAR_ORDER_ARRAY,
  payload: {
    tab: payload?.tab,
  },
});

export const addFilterData = (payload) => ({
  type: ADD_ALL_FILTERS,
  payload: payload,
});

export const setFilterData = (payload) => ({
  type: SET_FILTER_DATA,
  payload: payload,
});

export const updateHistory = (payload) => ({
  type: UPDATE_ORDER_SEARCH,
  payload: {
    tab: payload?.tab,
    data: payload?.search,
  },
});

export const updateOrderPage = (payload) => ({
  type: UPDATE_ORDER_PAGE,
  payload: payload,
});

export const addBulkViewSummery = (payload) => ({
  type: ADD_BULK_VIEW_SUMMARY,
  payload: payload,
});

export const toggleDashboardLoading = (payload) => ({
  type: TOGGLE_DASHBOARD_LOADING,
  payload,
});

export const addSlicedOrder = (payload) => ({
  type: ADD_SLICED_ORDER,
  payload,
});

export const addMoreSlicedOrder = (payload) => ({
  type: ADD_MORE_SLICED_ORDERS,
  payload,
});
export const updateOrderWithId = (payload) => ({
  type: UPDATE_A_ORDER_WITH_ID,
  payload,
});

export const deleteOrderbyId = (payload) => ({
  type: DELETE_ORDER_WITH_ID,
  payload,
});

export const updateBulkSummeryOrderCount = (payload) => ({
  type: UPDATE_BULK_SUMMERY_ORDER_COUNT,
  payload,
});

export const updateDashboardTags = (payload) => ({
  type: UPDATE_TAG_BY_ID,
  payload,
});

export const bulkDuplicateCard = (paylaod) => ({
  type: UPDATE_COUNT_BULK_ORDER_DUPLICATE,
  paylaod,
});

export const updateBulkDescription = (payload) => ({
  type: BULK_EDIT_DESCRIPTION,
  payload,
});

export const updateBulSummary = (payload) => ({
  type: UPDATE_BULK_SUMMERY,
  payload,
});

export const addBulkOrderId = (payload) => ({
  type: ADD_BULK_ORDER_ID,
  payload,
});

export const resetDashboardState = (payload) => ({
  type: RESET_DASHBOARD_STATE,
});

export const clearBulkSummeryWithOrder = (payload) => ({
  type: CLEAR_BULK_SUMMERY_WITH_ORDER,
});

export const editBulkDescriptionFromBulkCard = (payload) => ({
  type: EDIT_BULK_DESCRIPTION_IN_BULK_PAGE,
  payload,
});
