import { Button, Dropdown, Menu, Row, Space } from 'antd';
import React, { ReactElement } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import RoleGuard from '../../../modules/auth/guards/RoleGuard';
import { BetaFeature, UserRole } from '../../../modules/common/types';
import { Link } from 'react-router-dom';
import Text from '../../elements/text/Text';
import { ReactComponent as FileIcon } from '@/assets/nvabar/fileIcon.svg';
import { ReactComponent as GroupIcon } from '@/assets/nvabar/groupIcon.svg';
import { ReactComponent as DownIcon } from '@/assets/nvabar/down.svg';
import { ReactComponent as Parking } from '@/assets/nvabar/parking.svg';
import './style.less';
import { trackEvents } from '@/helpers/utilities';
import { useSelector } from 'react-redux';
import { isResponsive } from '../../pages/myProperties/utils/properties.utils';
import { useBetaRelease } from '../../../modules/common/guards/BetaRelease';

interface CreatePropertyDDProps {
  clearOrderArray: () => void;
  setIsBulkUploadModalOpen: () => void;
}

const propertyMenu = (clearOrderArray, setIsBulkUploadModalOpen): ReactElement => {
  return (
    <Menu className='nav__property__dropdown__menu'>
      <Menu.Item>
        <Link
          to='/createOrder'
          className='navbar-item-a'
          onClick={(e) => {
            clearOrderArray();
            trackEvents('dashboard_new-order', {});
          }}
        >
          <Space>
            <FileIcon />
            <Text type={'p34'} color={'dark-grey'}>
              Single Property
            </Text>
          </Space>
        </Link>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          trackEvents('dashboard_new-bulk-order', {});
          setIsBulkUploadModalOpen();
        }}
      >
        <Space>
          <GroupIcon />
          <Text type={'p34'} color={'dark-grey'}>
            Bulk Project
          </Text>
        </Space>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          trackEvents('dashboard_new-bolt-order', {});
        }}
      >
        <Link to='/bolt' className='navbar-item-a'>
          <Space>
            <Parking style={{ position: 'relative', top: '3px' }} />
            <Text type={'p34'} color={'dark-grey'}>
              Instant Turf
            </Text>
          </Space>
        </Link>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          trackEvents('dashboard_new-bolt-order', {});
        }}
      >
        <Link to='/bolt?feature=Parking Lots' className='navbar-item-a'>
          <Space>
            <Parking style={{ position: 'relative', top: '3px' }} />
            <Text type={'p34'} color={'dark-grey'}>
              Instant Parking Lot
            </Text>
          </Space>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

const newPropertyButton = (
  viewWidth: any,
  hasDropdown: boolean,
  clearOrderArray: () => void,
) => {
  if (!hasDropdown) {
    return (
      <Link
        to='/createOrder'
        className='navbar-item-a'
        onClick={(e) => {
          clearOrderArray();
          trackEvents('dashboard_new-order', {});
        }}
      >
        <Button
          type='primary'
          className={isResponsive(viewWidth) ? '' : 'new-property-btn'}
          style={{ backgroundImage: 'none', paddingLeft: '12px', paddingRight: '12px' }}
        >
          <Row
            justify='center'
            align='middle'
            wrap={false}
            className='new-property-wrapper'
          >
            <PlusCircleOutlined
              style={{
                fontSize: '16px',
                paddingRight: '8px',
              }}
            />
            <Text type='p0' color='dark-gray'>
              New Property
            </Text>
          </Row>
        </Button>
      </Link>
    )
  }
  return (
    <Button
      type='primary'
      className={isResponsive(viewWidth) ? '' : 'new-property-btn'}
      style={{ width: '178px', backgroundImage: 'none' }}
    >
      <Row
        justify='center'
        align='middle'
        wrap={false}
        className='new-property-wrapper'
      >
        <PlusCircleOutlined
          style={{
            fontSize: '16px',
            paddingLeft: '5px',
          }}
        />
        <div className='new-property-style'>
          <Space>
            <Text type='p0' color='dark-gray'>
              New Property
            </Text>
            <DownIcon className={'mt-1'} />
          </Space>
        </div>
      </Row>
    </Button>
  )
}

const CreatePropertyDD = (
  { clearOrderArray, setIsBulkUploadModalOpen }: CreatePropertyDDProps
) => {
  const { viewWidth } = useSelector((state) => state.user);
  const isEstimationsWorkspace = useBetaRelease(BetaFeature.Estimations);
  const ddContent = (
    <RoleGuard except={UserRole.Guest}>
      <Dropdown
        overlayClassName='nav__property__dropdown'
        overlay={propertyMenu(clearOrderArray, setIsBulkUploadModalOpen)}
        placement='bottomCenter'
        trigger={isResponsive(viewWidth) ? 'click' : 'hover'}
      >
        {newPropertyButton(viewWidth, true, clearOrderArray)}
      </Dropdown>
    </RoleGuard>
  );

  if (isEstimationsWorkspace) {
    if (isResponsive(viewWidth)) {
      return (
        <Row
          className='responsive__createProperty'
          align='middle'
          justify='center'
        >
          {newPropertyButton(viewWidth, false, clearOrderArray)}
        </Row>
      );
    }
    return newPropertyButton(viewWidth, false, clearOrderArray);
  }

  return isResponsive(viewWidth) ? (
    <Row
      className='responsive__createProperty'
      align='middle'
      justify='center'
    >
      {ddContent}
    </Row>
  ): ddContent;
}

export default CreatePropertyDD;
