// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Subtract5IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Subtract5Icon(props: Subtract5IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 6 6"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M2.698 0A2.698 2.698 0 000 2.698v2.833h5.396V2.698A2.698 2.698 0 002.698 0zm0 3.975c.652 0 1.18-.542 1.18-1.21 0-.668-.528-1.21-1.18-1.21-.652 0-1.18.542-1.18 1.21 0 .668.528 1.21 1.18 1.21z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Subtract5Icon;
/* prettier-ignore-end */
