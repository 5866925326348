import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { ToolQuickTip } from './tips';

export interface QuickTipsProps {
  activeTool: string;
}

const QuickTips = ({ activeTool }: QuickTipsProps) => {
  const [displayedTip, setDisplayedTip] = useState('');
  const [firstItem, setFirstItem] = useState(true);

  useEffect(() => {
    setDisplayedTip(ToolQuickTip[activeTool[0]][firstItem ? 0 : 1]);
  }, [firstItem, activeTool]);

  useEffect(() => {
    setDisplayedTip(ToolQuickTip[activeTool[0]][0]);
  }, [activeTool]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (ToolQuickTip[activeTool[0]].length > 1) {
        setFirstItem((prevState) => !prevState);
      }
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [activeTool]);

  return (
    <Box className='quick-tips-section'>
      <div className='quick-tips-header'>
        <Typography fontSize={10} fontWeight={500} lineHeight={'16px'}>
          Quick Tips:
        </Typography>
      </div>

      <div className='quick-tips-data'>
        <Tooltip title={displayedTip} placement='bottom'>
          <Typography
            fontSize={10}
            fontWeight={400}
            lineHeight={'16px'}
            className='overflow'
          >
            {displayedTip}
          </Typography>
        </Tooltip>
      </div>
    </Box>
  );
};

export default QuickTips;
