import { LayerPanelData, SingleComponent } from '../api/types';
import { NumericId } from '../../common/types';
import {
  feature,
  FeatureCollection,
  featureCollection,
  geometry,
} from '@turf/turf';
import { GeoJSONFeature, GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import MapContext from '@/components/pages/project/MapContext';
import { useContext, useEffect, useMemo } from 'react';
import OlMap from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import { convertJSONStyleToOlStyle } from '@/helpers/mapUtils/layerUtils';
import { removeAllMapLayers } from '@/helpers/mapUtils/mapInit';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { transform } from 'ol/proj';
import { Polygon } from 'geojson';
import { Coordinate } from 'ol/coordinate';
import { CheckedServiceItem } from '../../../components/pages/project/projectComponents/EstimationLayerPanel/types';
import BaseLayer from 'ol/layer/Base';
import { updatePathStyleFunction } from '@/helpers/mapGlobals/styles';
import { es } from '@faker-js/faker';

/**
 * TODO: Currently, when any change happens in `checkedItems`, we are removing all the layers and re-rendering it. Optimize it by only performing required changes
 *
 * @param data
 * @param checkedItems
 */
export const useServiceItemLayers = (
  data: LayerPanelData | null,
  checkedItems: CheckedServiceItem[]
) => {
  const mapRef: OlMap = useContext(MapContext);

  const checkedComponentIds = useMemo(() => {
    const componentIds = new Set<NumericId>();

    checkedItems.forEach((item) => {
      item.checkedComponents?.forEach((componentId) => {
        componentIds.add(componentId);
      });
    });

    return componentIds;
  }, [checkedItems]);

  useEffect(() => {
    if (!data) return;

    /** Remove any static or estimation view layer from map before rendering new ones */
    removeAllLayers(mapRef);

    const layers: Record<NumericId, SingleComponent[]> = {};

    for (const serviceItem of data) {
      for (const component of serviceItem.components) {
        if (!checkedComponentIds.has(component.componentId)) {
          /** The component is not checked, so we don't want to render that */
          continue;
        }

        if (!layers.hasOwnProperty(component.layerId)) {
          layers[component.layerId] = [];
        }

        const alreadyAdded = layers[component.layerId].some(
          (_component) => _component.componentId === component.componentId
        );

        if (!alreadyAdded) {
          layers[component.layerId].push(component);
        }
      }
    }

    const layerCollections: GeoJSONFeatureCollection[] = [];

    for (const [layerId, components] of Object.entries(layers)) {
      const features = components.map((component) => {
        const geoJsonFeature: GeoJSONFeature = JSON.parse(component.geoJson);

        if ((geoJsonFeature.geometry as Polygon).coordinates) {
          geoJsonFeature.geometry.coordinates = transformCoordinates(
            geoJsonFeature.geometry.coordinates
          );
        }

        return feature(
          geoJsonFeature.geometry,
          {
            ...geoJsonFeature.properties,
            layerName: component.name,
            ...component,
            id: component.componentId,
          },
          {
            id: component.componentId,
          }
        );
      });

      layerCollections.push(
        featureCollection(features, {
          id: layerId,
        })
      );
    }

    for (const layerCollection of layerCollections) {
      const feature = layerCollection.features[0];

      const featureType = feature.properties?.featureType ?? 'polygon';

      const source = new VectorSource({
        features: new GeoJSON().readFeatures(layerCollection),
      });

      const layer = new VectorLayer({
        className: 'EstimationViewLayer',
        source: source,
        style: convertJSONStyleToOlStyle(
          feature.geometry.type,
          feature.properties?.style
        ),
        zIndex: getLayerZIndex(featureType),
      });

      if (featureType === 'path') {
        layer.setStyle(
          updatePathStyleFunction(
            source.getFeatures(),
            feature.properties?.style
          )
        );
      }

      layer.set('id', layerCollection.id);
      mapRef.addLayer(layer);
    }
  }, [data, checkedItems]);
};

const transformCoordinates = (
  coordinates: Coordinate | Coordinate[] | Coordinate[][]
) => {
  if (typeof coordinates[0] === 'number') {
    return transform(coordinates as Coordinate, 'EPSG:4326', 'EPSG:3857');
  }

  return coordinates.map((coordinateCollection) =>
    transformCoordinates(coordinateCollection)
  );
};

const removeAllLayers = (mapRef: OlMap) => {
  const estimationLayers: BaseLayer[] = [];

  mapRef.getLayers().forEach((layer) => {
    if (layer.getClassName().includes('EstimationViewLayer')) {
      estimationLayers.push(layer);
    }
  });

  for (const estimationLayer of estimationLayers) {
    mapRef.removeLayer(estimationLayer);
  }
};

export const getLayerZIndex = (featureType: string) => {
  switch (featureType) {
    case 'line':
      return 80;

    case 'point':
      return 100;

    case 'path':
      return 90;

    default:
      return 70;
  }
};
