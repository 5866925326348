import { Col, Row } from 'antd';
import Compass from '../../../../assets/compass-png.png';
import React from 'react';
import Text from '../../../elements/text/Text';

const RotateMapTooltip = () => {
  return (
    <Row align='middle' className='pb-2'>
      <Col span={6}>
        <Row justify='space-around' align='middle'>
          <img src={Compass} style={{
            outline: '1px solid #DEDEDE',
            height: 'calc(100vh * (36 / 820))',
            aspectRatio: 1,
          }}/>
        </Row>
      </Col>
      <Col span={18}>
        <Row className='pb-1'>
          <Text type='p19' color='dark-gray' strong>
            Compass
          </Text>
        </Row>
        <Row>
          <Text type='p23' color='dark-gray'>
            <b>Alt/Option + Shift + Drag</b> to rotate the map view. Click on
            the compass to realign north.
          </Text>
        </Row>
      </Col>
    </Row>
  );
};

export default RotateMapTooltip;
