// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector145IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector145Icon(props: Vector145IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 33 4"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M26.803.086L0 3.358h32.474c-1.066 0-2.666-.248-3.247-1.636-.769-1.834-1.309-1.834-2.424-1.636z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector145Icon;
/* prettier-ignore-end */
