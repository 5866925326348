import { Select } from 'antd';
import React from 'react';
import { IUserEmailsAndNames } from '../common/types';

const { Option } = Select;

export const emailOptionContent = (
  userEmailsAndNames: IUserEmailsAndNames[],
  emails: Array<string>
) => {
  return userEmailsAndNames
    .filter((user: IUserEmailsAndNames) => !emails.includes(user.email))
    .map((user: IUserEmailsAndNames, index: number) => (
      <>
        {user.is_user_deactivated ? (
          <>
            <Option key={index} value={user.email} label={user.username}>
              {user.email}
              <span style={{ fontSize: '10px', fontStyle: 'italic' }}>
                (Deactivated)
              </span>
            </Option>
          </>
        ) : (
          <>
            <Option key={index} value={user.email} label={user.username}>
              {user.email}
            </Option>
          </>
        )}
      </>
    ));
};
