import { IObject } from '@/modules/common/types';
import { PaywallModalProps } from '@/modules/subscription/components/PaywallModal';
import { SubscriptionPlan } from '@/components/subscription/helpers/enum';
import {
  PayAsYouGoModalBoltOrder,
  PayAsYouGoModalOrder,
  RepairSubscriptionInvoice,
} from '@/components/subscription/helpers/types';

export const SubscriptionPlanNum = {
  essential: 10,
  professional: 100,
  advanced: 1000,
  enterprise: 2000,
};

export enum BillingStatus {
  Paid = 'paid',
  Unpaid = 'not-paid',
  Failed = 'failed',
}

export enum SubscriptionFeature {
  CreateSnapshot = 'snapshot-create',
  ExportKml = 'property_export-download_kml',
  ExportExcel = 'property_export-download_measurement',
  ExportAspire = 'property_export-aspire',
  ToolPath = 'map_tools-path_tool',
  ToolLassoSlice = 'map_tools-lasso_slice',
  ToolLineSplit = 'map_tools-line_split',
  ToolPolygonSplit = 'map_tools-polygon_split',
  SingleLayerMove = 'geometry-single_select_move',
  SingleLayerDuplicate = 'geometry-single_select_duplicate',
  SingleLayerDelete = 'geometry-single_select_delete',
  MultiLayerMove = 'geometry-multi_select_move',
  MultiLayerDuplicate = 'geometry-multi_select_duplicate',
  MultiLayerDelete = 'geometry-multi_select_delete',
  MultiLayerMerge = 'geometry-multi_select_merge',
  GuestInvites = 'team_mgmt-guest-add',
  MapViews = 'view-add',

  CustomFeature = 'feature-create',
  CustomLayer = 'layer-add',
  CreateWorkspace = 'create-workspace',

  CustomAttribute = 'custom-attribute',
}

export interface SubscriptionModalData extends PaywallModalProps {}

export interface SubscriptionFeatureData {
  featureName: SubscriptionFeature;
  subscriptionPlan: SubscriptionPlan;

  /** Is this feature enabled for logged in user */
  isEnabled?: boolean;

  /** List of other attributes which are specific to a feature */
  attribute?: IObject;
}

export interface SubscriptionSpecialFeatureData
  extends Omit<SubscriptionFeatureData, 'subscriptionPlan'> {}

export type ShowPaywallFunction = (
  /** {@link SubscriptionFeature} for which modal is opened  */
  feature: SubscriptionFeature | string,

  data?: Omit<SubscriptionModalData, 'visible' | 'feature'>
) => void;

export type ShowPayAsYouGoFunction = (
  /** Reference {@link PayAsYouGoModalOrder} for which modal is opened  */
  order: PayAsYouGoModalOrder | PayAsYouGoModalBoltOrder
) => void;

export type ShowRepairSubscriptionFunction = (
  /** Reference {@link RepairSubscriptionInvoice} for which repair subscription flow is happening */
  invoice: RepairSubscriptionInvoice
) => void;


