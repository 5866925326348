import { getAxiosInstance, getCancelToken } from "@/helpers/utilities/api-utils";

let cancelTokenSource = null;

const axiosInstance = getAxiosInstance();

export const fetchFeedList = (search) => {
  let url = `/api/agora/v1/feed/order?search=${search}`;

  try {
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }
  } catch (e) {}
  cancelTokenSource = getCancelToken().source();
  
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url, { cancelToken: cancelTokenSource.token })
      .then((result) => {
        if (result.status === 200) {
          if (result?.data?.errorCode === 'SR-BIZ-001') resolve([]);
          else resolve(result?.data?.data);
        }
      })
      .catch((err) => {
        reject(err);
        console.error(err);
      });
  });
};

export const fetchCards = (id, filter, view, status, tag, assignee) => {
  let url = `/api/agora/v1/note?orderid=${id}&filter=${filter}`;

  if (view !== 'None') {
    url += `&view=${view}`;
  }
  if (status !== 'None') {
    url += `&status=${status}`;
  }
  if (tag !== 'None') {
    url += `&tag=${tag}`;
  }
  if (assignee !== 'None') {
    url += `&assignee=${assignee}`;
  }

  return new Promise((resolve, reject) => {
    axiosInstance.get(`${url}`)
      .then((result) => {
        if (result.status === 200) {
          resolve(result);
        }
      })
      .catch((err) => {
        reject(err);
        console.error(err);
      });
  });
};

export const fetchViews = (id) => {
  //let url = `/api/carto/getVersionDataAll/${id}`;
  let url = `/api/agora/v1/order/${id}/view`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((result) => {
        if (result.status === 200) {
          resolve(result);
        }
      })
      .catch((err) => {
        reject(err);
        console.error(err);
      });
  });
};

export const getFeedCount = (id) => {
  let url = `/api/agora/v1/feed/count?status=unread&type=note,comment`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((result) => {
        if (result.status === 200) {
          resolve(result);
        }
      })
      .catch((err) => {
        reject(err);
        console.error(err);
      });
  });
};

export { axiosInstance };
