import { NotifyError } from '@/helpers/notification-utils';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { Button, Col, Input, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { GLOBAL_ASSET_UPLOAD } from '../../../../../helpers/constants/APIEndpoints';
import { replaceParams } from '../../../../../helpers/utilities/linkUtils';
import { showNotification } from '../../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../storybook/NotificationToast/types';
import './style.less';

const IconInput = (props) => {
  const [fileName, setName] = useState(null);
  const axiosInstance = getAxiosInstance();

  const handleSave = () => {
    const { iconFileList } = props;
    if (!fileName) {
      NotifyError('Please provide a name');
      return true;
    }
    let formData = new FormData();
    // Update the formData object
    formData.append('file', iconFileList[0]?.originFileObj);
    axiosInstance
      .post(
        replaceParams(GLOBAL_ASSET_UPLOAD, {
          ':type': props?.type,
          ':name': fileName,
        }),
        formData
      )
      .then((res) => {
        let obj = {
          ...res?.data,
          name: fileName,
        };
        props?.updateGlobalList(obj);
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Icon saved');
        props?.backIconHandler();
      })
      .catch((error) => {
        NotifyError(`${fileName} file upload failed.`);
      });
  };

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => {
      document.getElementById('icon-preview-block').innerHTML = reader.result;
    };
    reader.readAsText(props.iconFileList[0]?.originFileObj);
  }, []);

  return (
    <>
      <Row justify={'center'}>
        <Col>
          <Tooltip title={'upload Name'} placement={'topLeft'}>
            <div id='icon-preview-block'></div>
          </Tooltip>
        </Col>
      </Row>
      <Row className={'text-input'}>
        <Col span={24}>
          <Input
            placeholder={
              props?.type === 'symbol' ? 'symbol name' : 'arrow name'
            }
            onChange={(e) => setName(e.target.value)}
            className={'icon-input'}
            style={{
              border: 0,
              width: '62%',
            }}
          />
          <Button type='primary' size={'small'} onClick={handleSave}>
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default IconInput;
