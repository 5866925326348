import Text from '@/components/elements/text/Text';
import { Button, Col, Popover, Row, Select, Tag } from 'antd';
import { emailOptionContent } from './EmailOptionContent';
import { useEffect } from 'react';
import { filterByEmail } from '@/components/notes-feedback/helpers/EmailFilter';

interface SelectComponentProps {
  showSuggestion: boolean;
  setShowSuggetion: (value: boolean) => void;
  setShowShareWarning?: (value: boolean) => void;
  userEmails: any;
  onEmailChange: (value: Array<string>) => void;
  onEmailDeselect: (value: string) => void;
  onRemove: () => void;
  emails: Array<string>;
  userEmailsAndNames: any;
  showShareWarning?: boolean;
  invalidMails: string;
  warningEmail: string;
  isChipRed: boolean;
  setIsChipRed: (value: boolean) => void;
  alreadyInvitedError: string;
  invalidMailsArray: string[];
  alreadyInvitedMailsArray: string[];
  setIsError: (value: boolean) => void;
  type?: string;
}

const SelectComponent = ({
  showSuggestion,
  setShowSuggetion,
  onEmailChange,
  onEmailDeselect,
  onRemove,
  emails,
  userEmailsAndNames,
  showShareWarning,
  warningEmail,
  setShowShareWarning,
  isChipRed,
  setIsChipRed,
  invalidMailsArray,
  alreadyInvitedMailsArray,
  setIsError,
  type,
}: SelectComponentProps) => {
  useEffect(() => {
    // TODO - Try to find a solution using *useRef()* instead of plain JS
    const input: HTMLInputElement | null = document.querySelector(
      'div.share-email-select input.ant-select-selection-search-input'
    );

    if (input) {
      input.addEventListener('paste', (e) => e.preventDefault());
    }
  }, []);

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    if (!isChipRed) {
      return (
        <Tag
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{
            marginRight: 3,
            color: '#333333',
            backgroundColor: `${
              invalidMailsArray.includes(label) ||
              alreadyInvitedMailsArray.includes(label)
                ? '#FFD1D1'
                : 'transparent'
            }`,
            borderRadius: '4px',
            border: `${
              invalidMailsArray.includes(label) ||
              alreadyInvitedMailsArray.includes(label)
                ? '1px solid #FFD1D1'
                : '1px solid #d9d9d9'
            }`,
          }}
        >
          {label}
        </Tag>
      );
    } else {
      return (
        <Tag
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{
            marginRight: 3,
            color: '#333333',
            backgroundColor: `${
              invalidMailsArray.includes(label) ||
              label === warningEmail ||
              alreadyInvitedMailsArray.includes(label)
                ? '#FFD1D1'
                : 'transparent'
            }`,
            border: `${
              invalidMailsArray.includes(label) ||
              label === warningEmail ||
              alreadyInvitedMailsArray.includes(label)
                ? '1px solid #FFD1D1'
                : '1px solid #d9d9d9'
            }`,
            borderRadius: '4px',
          }}
        >
          {label}
        </Tag>
      );
    }
  };

  return (
    <>
      <Select
        maxTagCount={5}
        mode='tags'
        open={showSuggestion}
        disabled={showShareWarning}
        placeholder='Enter email(s), comma separated'
        style={{
          borderRadius: 4,
          width: '100%',
        }}
        onInputKeyDown={(e: any) => {
          if (e.key !== 'Enter') {
            if (e.target.value?.length <= 1 && e.key === 'Backspace') {
              setShowSuggetion(false);
              return;
            }
            setShowSuggetion(true);
          } else {
            setShowSuggetion(false);
          }
        }}
        onClick={() => setShowSuggetion(false)}
        onBlur={() => setShowSuggetion(false)}
        optionLabelProp='label'
        tagRender={tagRender}
        onChange={onEmailChange}
        onDeselect={(value) => {
          onEmailDeselect(value);
        }}
        value={emails}
        className='share-email-select'
        dropdownClassName='share-email-select-dd'
        dropdownStyle={{
          border: 'none !important',
        }}
        tokenSeparators={[',', ' ', '\n', '\t', '\r']}
      >
        {type && type === 'notes'
          ? filterByEmail(userEmailsAndNames, emails)
          : emailOptionContent(userEmailsAndNames, emails)}
      </Select>

      <div>
        <Popover
          placement='bottomLeft'
          visible={showShareWarning}
          overlayClassName='share-warning-popover'
          content={
            <Col
              style={{
                width: '350px',
                padding: '8px',
              }}
            >
              <Row>
                <Text type='p19'>Share outside of organization?</Text>
              </Row>
              <Row className='mt-2'>
                <Text type='p75' color='dark-gray'>
                  You are sharing this property with{' '}
                  <span style={{ fontWeight: 700 }}>{warningEmail}</span> who is
                  not in the organization.
                </Text>
              </Row>
              <Row style={{ justifyContent: 'end' }} className='mt-2'>
                <Col>
                  <Button
                    type='text'
                    style={{ color: '#666666' }}
                    onClick={onRemove}
                  >
                    Remove
                  </Button>
                </Col>
                <Col>
                  <Button
                    type='text'
                    style={{ color: '#0094FF' }}
                    onClick={() => {
                      setShowShareWarning && setShowShareWarning(false);
                      setIsChipRed(false);
                      setIsError(false);
                    }}
                  >
                    Share Anyway
                  </Button>
                </Col>
              </Row>
            </Col>
          }
        ></Popover>
      </div>
    </>
  );
};

export default SelectComponent;
