import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Modal, Progress, Row, Select, Upload } from 'antd';
import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import { NotifyError } from '@/helpers/notification-utils';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { replaceParams } from '@/helpers/utilities/linkUtils';
import { renderFeatureTypeIcon } from '@project/projectComponents/LayerUtility';
import { Typography } from '@/modules/common/elements';
import Text from '@elements/text/Text';
import { ReactComponent as DeleteUploadJson } from '@/assets/DeleteUploadJson.svg';
import { ReactComponent as FileIconUploadJson } from '@/assets/FileIconUploadJson.svg';
import { ReactComponent as ExclaimationSign } from '@/assets/ExclaimationSign.svg';
import { ReactComponent as DoneGeoJson } from '@/assets/DoneGeoJson.svg';
import { ReactComponent as UploadGeoJSONIcon } from '@/assets/uploadGeoJSON.svg';
import './style.less';
import SRButton from '@elements/Button';
import { trackEvents } from '@/helpers/utilities';
import { useOrderStatus } from '@/modules/project/guards/OrderStatus';
// import { OrderStatus } from '@/modules/common/types';

const { Dragger } = Upload;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 24 },
};

interface Error {
  errorCode: string;
  errorDesc: string;
}

const REPLACE_ERROR = 'SR-BIZ-ERROR-8';

const UploadGeoJSON = ({ visible, onCancel, selectedView, ...props }) => {
  const { data: featureListInfo } = useSelector(
    (state: any) => state.order.featureListInfo
  );
  const [filesData, setFileData] = useState<any>();
  const [feature, setFeature] = useState<any>(false);
  const [wholeFeature, setWholeFeature] = useState<any>(false);
  const [showUploadSuccesMessage, setShowUploadSuccesMessage] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [replaceLoading, setReplaceLoading] = useState<boolean>(false);
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [localFileUpload, setLocalFileUpload] = useState<boolean>(false);
  const [replaceLayerModalOpen, setReplaceLayerModalOpen] =
    useState<boolean>(false);

  const [geoJSONError, setGeoJSONError] = useState<Error>();

  const selectRef = useRef<any>(null);
  const orderStatus = useOrderStatus();

  //   @ts-ignore
  const uploadGeoJSON = useCallback(async (file) => setFileData(file));
  const [form] = Form.useForm();

  const overrideXHRRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
    setLocalFileUpload(true);
  };

  //Force API
  const replaceFileUpload = () => {
    setReplaceLoading(true);
    const payload = new FormData();
    payload.append('file', filesData);
    payload.append('featureId', feature && feature.split(':')[0]);

    trackEvents('geojson__replace-layer__submit', {
      payload: payload,
      viewId: selectedView?.viewId,
      viewName: selectedView.name,
      isBaseView: selectedView.isBaseView,
      orderId: selectedView.orderId,
      orderStatus: orderStatus,
      fileExtension: filesData.name.split(':').splice(-1),
      featureName: wholeFeature.name,
      featureType: wholeFeature.category,
    });

    getAxiosInstance()
      .post(
        replaceParams(API_ENDPOINTS.LAYERS_GEO_JSON_UPLOAD_FORCE, {
          ':viewId': selectedView?.viewId,
        }),
        payload,
        {
          onUploadProgress: (data) => {
            const value = ((data.loaded / data.total) * 100).toFixed(0);
            setProgressPercent(Number(value));
          },
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((result) => {
        trackEvents('uploadgeojson_replaced', {
          result: result,
          viewId: selectedView?.viewId,
          viewName: selectedView.name,
          isBaseView: selectedView.isBaseView,
          orderId: selectedView.orderId,
          orderStatus: orderStatus,
          fileExtension: filesData.name.split(':').splice(-1),
          featureName: wholeFeature.name,
          featureType: wholeFeature.category,
        });
        setShowUploadSuccesMessage(true);
        setProgressPercent(0);
        setReplaceLayerModalOpen(false);
        setLoading(true);
        setReplaceLoading(false);
        setTimeout(() => {
          props.loadData(selectedView?.viewId);
          setLoading(false);
          setShowUploadSuccesMessage(false);
          closeModalHander();
        }, 3000);
      })
      .catch((error) => {
        setReplaceLoading(false);

        trackEvents('uploadgeojson_replaced-failed', {
          errorMessage: error,
          viewId: selectedView?.viewId,
          viewName: selectedView.name,
          isBaseView: selectedView.isBaseView,
          orderId: selectedView.orderId,
          orderStatus: orderStatus,
          fileExtension: filesData.name.split(':').splice(-1),
          featureName: wholeFeature.name,
          featureType: wholeFeature.category,
        });
        if (error.response && error.response.status === 500) {
          NotifyError('Something Went Wrong');
        } else {
          if (error.errorCode.includes(REPLACE_ERROR)) {
            setReplaceLayerModalOpen(true);
          } else {
            setGeoJSONError(error);
            setReplaceLayerModalOpen(false);
          }
        }

        setProgressPercent(0);

        setLoading(false);
      });
  };

  //Normal Api
  const onFinish = (value) => {
    if (!(value?.feature && value?.file)) {
      NotifyError('Please fill form!');
      return;
    }
    setGeoJSONError(undefined);
    setLoading(true);
    const payload = new FormData();
    payload.append('file', filesData);
    payload.append('featureId', value?.feature.split(':')[0]);

    trackEvents('upload_geojson_clicked', {
      payload: payload,
      viewId: selectedView?.viewId,
      viewName: selectedView.name,
      isBaseView: selectedView.isBaseView,
      orderId: selectedView.orderId,
      orderStatus: orderStatus,
      fileExtension: filesData.name.split(':').splice(-1),
      featureName: wholeFeature.name,
      featureType: wholeFeature.category,
    });
    getAxiosInstance()
      .post(
        replaceParams(API_ENDPOINTS.LAYERS_GEO_JSON_UPLOAD, {
          ':viewId': selectedView?.viewId,
        }),
        payload,
        {
          onUploadProgress: (data) => {
            const value = ((data.loaded / data.total) * 100).toFixed(0);
            setProgressPercent(Number(value));
          },
          headers: {
            Accept: 'application/json',
          },
        }
      )
      .then((result) => {
        trackEvents('upload_geojson_successful', {
          result: result,
          viewId: selectedView?.viewId,
          viewName: selectedView.name,
          isBaseView: selectedView.isBaseView,
          orderId: selectedView.orderId,
          orderStatus: orderStatus,
        });
        setProgressPercent(0);
        setReplaceLayerModalOpen(false);
        setShowUploadSuccesMessage(true);
        setTimeout(() => {
          closeModalHander();

          setLoading(false);
          props.loadData(selectedView?.viewId);
          setShowUploadSuccesMessage(false);
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 500) {
          return;
        } else {
          if (error.errorCode.includes(REPLACE_ERROR)) {
            trackEvents('upload_geojson_failed__replace', {
              errorMessage: error,
              viewId: selectedView?.viewId,
              viewName: selectedView.name,
              isBaseView: selectedView.isBaseView,
              orderId: selectedView.orderId,
              orderStatus: orderStatus,
              fileExtension: filesData.name.split(':').splice(-1),
              featureName: wholeFeature.name,
              featureType: wholeFeature.category,
            });

            setReplaceLayerModalOpen(true);
          } else {
            trackEvents('upload_geojson_failed', {
              errorMessage: error,
              viewId: selectedView?.viewId,
              viewName: selectedView.name,
              isBaseView: selectedView.isBaseView,
              orderId: selectedView.orderId,
              orderStatus: orderStatus,
              fileExtension: filesData.name.split(':').splice(-1),
              featureName: wholeFeature.name,
              featureType: wholeFeature.category,
            });

            setGeoJSONError(error);
            setReplaceLayerModalOpen(false);
          }
        }
        setProgressPercent(0);
      });
  };

  const onReset = () => {
    form.resetFields();
    onCancel();
  };

  const featureSelectHandler = (value) => {
    setFeature(value);
    const full = featureListInfo.find(
      (ele) => (ele.featureId = value.split(':')[0])
    );
    setWholeFeature(full);

    trackEvents('upload-geojson__clicked-feature', {
      featureName: full.name,
      featureId: full.featureId,
      viewId: selectedView?.viewId,
      viewName: selectedView.name,
      isBaseView: selectedView.isBaseView,
      orderId: selectedView.orderId,
      orderStatus: orderStatus,
    });

    if (selectRef) {
      selectRef.current.blur();
    }
  };

  const closeModalHander = () => {
    onCancel();
    onReset();
    setLocalFileUpload(false);
    setGeoJSONError(undefined);
  };

  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        closable={false}
        centered
        width={!showUploadSuccesMessage ? '400px' : '384px'}
        onCancel={closeModalHander}
      >
        <Row>
          {!showUploadSuccesMessage && (
            <Row align='middle' justify={'center'} className='mb-6 w-100'>
              {loading ? (
                <Text type='p47'>Uploading GeoJSON File</Text>
              ) : (
                <Text type='p47'>Upload GeoJSON File</Text>
              )}
            </Row>
          )}

          {loading ? (
            <Row
              align={'middle'}
              wrap={false}
              className='w-100 py-2'
              style={
                geoJSONError
                  ? { backgroundColor: 'rgba(255, 0, 0, 0.05)' }
                  : { backgroundColor: 'white' }
              }
            >
              {showUploadSuccesMessage ? (
                <>
                  <Row
                    className='w-100'
                    style={{ display: 'flex', flexDirection: 'column' }}
                    justify={'center'}
                    align={'middle'}
                  >
                    <DoneGeoJson />
                    <span className='file-upload-success mt-3'>
                      File uploaded successfully
                    </span>
                  </Row>
                </>
              ) : (
                <>
                  <Row className='px-2'>
                    <FileIconUploadJson />
                  </Row>

                  {geoJSONError &&
                  !geoJSONError.errorCode.includes(REPLACE_ERROR) ? (
                    <Row wrap={false} className='w-100 px-2'>
                      <Row
                        className='w-100'
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <span className='file-name-geojson'>
                          {filesData.name}
                        </span>
                        <span className='upload-retry-error'>
                          {geoJSONError
                            ? geoJSONError.errorDesc
                            : 'Uploading failed!'}
                        </span>
                      </Row>
                      <Row>
                        <span
                          className='retry-button'
                          onClick={() => setLoading(false)}
                        >
                          Retry
                        </span>
                      </Row>
                    </Row>
                  ) : (
                    <Row wrap={true} className='w-100 px-4'>
                      <span className='file-name-geojson '>
                        {filesData.name}
                      </span>
                      <Progress
                        percent={progressPercent}
                        strokeColor={'#FFD02B'}
                        status={'active'}
                        strokeWidth={4}
                        style={{ fontSize: '11px' }}
                      />
                    </Row>
                  )}
                </>
              )}
            </Row>
          ) : (
            <Row className='w-100' justify={'center'} align={'middle'}>
              <Form
                {...layout}
                onFinish={onFinish}
                form={form}
                className='w-100'
              >
                <Row className='mb-1'>
                  <Typography variant='body.sm' color='#000000'>
                    Feature
                  </Typography>
                </Row>

                {/* Dropdown of Features */}
                <Form.Item name={'feature'} className='w-100 select-feature'>
                  <Select
                    ref={selectRef}
                    placeholder={'Select Feature'}
                    showSearch={true}
                    onChange={(value) => featureSelectHandler(value)}
                    className='w-100'
                  >
                    {featureListInfo?.map((feature, i) => {
                      return (
                        <Select.Option
                          key={i}
                          value={`${feature.featureId}:${feature.name}`}
                          title={feature?.name}
                        >
                          <div className={'feature-select w-100'}>
                            <Row className=' feature-single-select w-100'>
                              <Row className='ml-2' align={'middle'}>
                                {renderFeatureTypeIcon(feature?.type)}
                                <Typography variant='body.sm' className='ml-2'>
                                  {feature.name}
                                </Typography>
                              </Row>
                            </Row>
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Row className='mb-1'>
                  <Typography variant='body.sm' color='neutral.300'>
                    File
                  </Typography>
                </Row>

                <Form.Item
                  name={'file'}
                  className='geoJsonUpload mb-2'
                  style={{ backgroundColor: geoJSONError ? '#FF00000D' : '' }}
                >
                  {localFileUpload ? (
                    <Row className='w-100 py-3 px-2' justify={'space-between'}>
                      <Col span={18}>
                        <Row align={'middle'}>
                          <Col span={4}>
                            <FileIconUploadJson />
                          </Col>
                          <Col>
                            <Row>
                              <Typography variant='body.sm'>
                                {filesData.name}
                              </Typography>
                            </Row>
                            <Row>
                              {geoJSONError &&
                                !geoJSONError.errorCode.includes(
                                  REPLACE_ERROR
                                ) && (
                                  <Row wrap={false} className='w-100'>
                                    <Row
                                      className='w-100'
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <span className='upload-retry-error'>
                                        {geoJSONError
                                          ? geoJSONError.errorDesc
                                          : 'Uploading failed!'}
                                      </span>
                                    </Row>
                                  </Row>
                                )}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <Row justify='end'>
                          <DeleteUploadJson
                            className='mt-1'
                            onClick={() => {
                              setGeoJSONError(undefined);
                              setLocalFileUpload(false);
                              setFileData(null);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Dragger
                      className='droppable w-100'
                      beforeUpload={uploadGeoJSON}
                      accept={'.geojson,.json,.txt'}
                      //   @ts-ignore
                      customRequest={overrideXHRRequest}
                    >
                      <Col span={24}>
                        <Row justify='center'>
                          <UploadGeoJSONIcon className='mb-8' />
                        </Row>

                        <Row justify='center' className='mb-2'>
                          <Typography
                            fontSize={10}
                            lineHeight={1.5}
                            fontWeight={500}
                            color='neutral.600'
                          >
                            Click to browse or drag and drop your files.
                          </Typography>
                        </Row>
                        <Row justify='center'>
                          <Typography
                            fontSize={10}
                            lineHeight={1.5}
                            fontWeight={500}
                            color='neutral.600'
                          >
                            Supported file - geoJSON
                          </Typography>
                        </Row>
                      </Col>
                    </Dragger>
                  )}
                </Form.Item>

                <Form.Item noStyle={true}>
                  <Row className='w-100'>
                    <Col span={12}>
                      <SRButton disabled={loading} onClick={closeModalHander}>
                        <Text type={'h22'} color='dark-gray'>
                          Cancel
                        </Text>
                      </SRButton>
                    </Col>
                    <Col span={12}>
                      <Row justify='end'>
                        <SRButton
                          type='primary'
                          htmlType='submit'
                          className='edit-btn px-13 py-5'
                          loading={loading}
                          disabled={!(feature && localFileUpload)}
                        >
                          <Text type={'h22'} color='dark-gray' className='ml-2'>
                            Upload
                          </Text>
                        </SRButton>
                      </Row>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Row>
          )}
        </Row>
      </Modal>
      <Modal
        visible={replaceLayerModalOpen}
        footer={null}
        closable={false}
        centered
        width={'420px'}
        onCancel={onCancel}
      >
        <Row className='w-100' wrap={true}>
          <Row className='w-100' justify={'center'} align={'middle'}>
            <ExclaimationSign />
          </Row>
          <Row
            className='w-100 text-layerexists-modal mt-6'
            align={'middle'}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <span className='text-layerexists-modal-title'>Layer Exists</span>
            <span className='text-layerexists-modal-description mt-2'>
              "{feature && feature.split(':').splice(1)}" layer already
              exists in this view. Do you want to replace it?
            </span>
          </Row>

          <Row className='w-100 mt-5' justify={'end'} align={'middle'}>
            <Col span={12}>
              <Row justify='center'>
                <SRButton
                  disabled={loading}
                  onClick={() => {
                    setReplaceLayerModalOpen(false);
                    setLoading(false);
                  }}
                >
                  <Text type={'h22'} color='dark-gray' className='ml-2'>
                    Cancel
                  </Text>
                </SRButton>
              </Row>
            </Col>
            <Col span={12}>
              <Row justify='center'>
                <SRButton
                  type='primary'
                  htmlType='submit'
                  className='edit-btn px-11 py-5'
                  onClick={replaceFileUpload}
                  loading={replaceLoading}
                >
                  <Text type={'h22'} color='dark-gray' className='ml-2'>
                    Replace
                  </Text>
                </SRButton>
              </Row>
            </Col>
          </Row>
        </Row>
      </Modal>
    </>
  );
};

export default UploadGeoJSON;
