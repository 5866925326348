import { useEffect } from 'react';
import { Box, Typography } from '@common/elements';
import { Button, Modal } from 'antd';
import './style.less';
import { ReactComponent as Upgrade } from '../../../../assets/Upgrade.svg';
import { ReactComponent as Warning } from '../../../../assets/icons/Warning.svg';
import { ReactComponent as Tick } from '../../../../assets/svgs/alert/tick.svg';

interface UpgradeConfirmationProps {
  modalVisibility: boolean;
  onModalClose?: (confirmed?: boolean) => void;
  onModalOpen?: () => void;
  closable?: boolean;
  title: string;
  content: string;
  id?: string;
  activate?: string;
}

const UpgradeConfirmation = ({
  modalVisibility,
  onModalClose,
  onModalOpen,
  closable,
  title,
  content,
  id,
  activate,
}: UpgradeConfirmationProps) => {
  const handleCancel = (confirmed?: boolean) => {
    if (!confirmed) {
      confirmed = false;
    }
    if (onModalClose) {
      onModalClose(confirmed);
    }
  };

  useEffect(() => {
    if (onModalOpen) onModalOpen();
  }, []);

  return (
    <Modal
      visible={modalVisibility}
      onCancel={() => {
        handleCancel();
      }}
      maskClosable={false}
      className='confirmation-modal'
      width='512px'
      centered
      footer={null}
      closable={closable}
    >
      <Box id={id} className='confirmation-modal-main'>
        <Box className='confirmation-modal-icon'>
          {activate ? (
            activate == 'activate' ? (
              <Tick />
            ) : (
              <Warning />
            )
          ) : (
            <Upgrade />
          )}
        </Box>
        <Box className='confirmation-modal-title'>
          <Typography fontWeight={500} fontSize={'20px'} lineHeight={'24px'}>
            {title}
          </Typography>
        </Box>

        <Box className='confirmation-modal-content'>
          <Typography fontWeight={400} fontSize={'14px'} lineHeight={'20px'}>
            {content}
          </Typography>
        </Box>

        <Box className='confirmation-modal-footer'>
          <Button className='confirmation-modal-footer-button'>
            <Typography
              fontWeight={500}
              lineHeight={'20px'}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Typography>
          </Button>
          <Button
            className='confirmation-modal-footer-button'
            type='primary'
            onClick={() => {
              handleCancel(true);
            }}
          >
            <Typography
              style={{ color: '#333333' }}
              fontWeight={600}
              lineHeight={'20px'}
            >
              Confirm
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpgradeConfirmation;
