import React from 'react';

import './style.less';

const RoundedBox = ({ color, children, props }) => {
  return (
    <div className={`box ${color} text-capatalize`} {...props}>
      {children}
    </div>
  );
};

export default RoundedBox;
