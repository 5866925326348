import React, { useEffect } from 'react';
import { Dropdown, Menu, Tooltip } from 'antd';

import Text from '@elements/text/Text.jsx';
import { ReactComponent as DownArrowIcon } from '../../../assets/down-arrow.svg';
import { ReactComponent as TickMarkIcon } from '../../../assets/tick-mark.svg';

interface IMenuItem {
  description?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  label: string;
  value?: string;
}

interface IDropdownComponentProps {
  name?: string;
  disabled?: boolean;
  iconStyles?: React.CSSProperties;
  labelStyles?: React.CSSProperties;
  options: Array<IMenuItem>;
  selectedOption: IMenuItem;
  setSelectedOption: (option: IMenuItem) => void;
  showDivider?: boolean;
  componentClass?: string;
  classFromParent?: string;
  tooltip?: string | null;
}

const DropdownComponent = ({
  disabled,
  tooltip,
  iconStyles,
  labelStyles,
  options,
  selectedOption,
  setSelectedOption,
  showDivider = false,
  componentClass,
  classFromParent,
  name,
}: IDropdownComponentProps) => {
  useEffect(() => {
    /** Whenever dropdown is made disabled, need to change access from "Can Edit" to "Can View" */
    if (name === 'property-invite') {
      if (disabled) {
        setSelectedOption(options[0]);
      }
    }
  }, [disabled]);

  const dropdownMenu = (
    <Menu>
      {options.map((useMenu, idx) => (
        <>
          <Menu.Item
            key={idx}
            onClick={() => {
              setSelectedOption(useMenu);
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              // alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {useMenu.label === selectedOption.label ? (
                <TickMarkIcon style={{ marginRight: 8 }} />
              ) : (
                <div
                  style={{
                    display: 'inline-block',
                    height: 9,
                    marginRight: 8,
                    width: 12,
                  }}
                />
              )}
              {useMenu.icon && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  {useMenu.icon}
                </div>
              )}
              <span
                style={{
                  fontSize: 12,
                  fontWeight:
                    useMenu.label === selectedOption.label ? 500 : 400,
                  lineHeight: '18px',
                }}
              >
                {useMenu.label}
              </span>
            </div>
            {useMenu.description && (
              <div
                className='ml-5'
                style={{
                  lineHeight: '12px',
                  maxWidth: 172,
                  whiteSpace: 'normal',
                }}
              >
                <Text
                  color='gray-shade'
                  style={{
                    whiteSpace: 'break-spaces',
                  }}
                  type='p61'
                >
                  {useMenu.description}
                </Text>
              </div>
            )}
          </Menu.Item>
          {showDivider && idx !== options.length - 1 && <Menu.Divider />}
        </>
      ))}
    </Menu>
  );

  const Content = (
    <Dropdown
      disabled={disabled}
      className={`${classFromParent}`}
      overlay={dropdownMenu}
      trigger={['click']}
      overlayClassName={`${componentClass}`}
    >
      <div
        style={
          disabled
            ? {
                alignItems: 'center',
                cursor: 'not-allowed ',
                color: '#999999 !important',
                display: 'flex',
                justifyContent: 'center',
              }
            : {
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
              }
        }
      >
        {selectedOption.icon && (
          <div
            style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}
          >
            {selectedOption.icon}
          </div>
        )}
        <span
          style={{
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '18px',
            marginRight: 4,
            ...labelStyles,
          }}
        >
          {selectedOption.label}
        </span>
        <DownArrowIcon
          style={
            disabled
              ? {
                  cursor: 'not-allowed ',
                  ...iconStyles,
                }
              : { cursor: 'pointer', ...iconStyles }
          }
        />
      </div>
    </Dropdown>
  );

  if (tooltip) {
    return <Tooltip title={tooltip}>{Content}</Tooltip>;
  }

  return <>{Content}</>;
};

export default DropdownComponent;
