import { createContext, useContext } from 'react';

export type Params = {
  [key in string]: string;
};

interface IRouterPlusContext {
  params: Params;
  setParams: (params: Params) => void;
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
}

const RouterPlusContext = createContext<IRouterPlusContext>({
  params: {},
  setParams: () => {},
  searchParams: new URLSearchParams(),
  setSearchParams: () => {},
});

export const useRouterPlusContext = () => useContext(RouterPlusContext);

export default RouterPlusContext;
