import { FeatureType } from '../../../../feature-attributes/types';

export interface SubTool {
  icon: any;
  keyVal: string;
  title: string;
  viableLayers: FeatureType[];
}

export enum ToolList {
  SELECT = 'Select',
  LINE = 'Line',
  POINT = 'Point',
  POLYGON = 'Polygon',
  CUT = 'Cut',
  EDIT = 'Edit',
  SPLIT = 'Split',
  LINE_SPLIT = 'Line Split',
  LASSO_SPLIT = 'Lasso Split',
  NEW_NOTE = 'New Note',
  MEASURE = 'Measure',
  DRAG = 'Drag',
  PATH = 'Path',
}

export enum ToolKey {
  SELECT = 'select',
  LINE = 'line',
  POINT = 'point',
  POLYGON = 'polygon',
  CUT = 'cut',
  EDIT = 'modify',
  SPLIT = 'split',
  LINE_SPLIT = 'slice',
  LASSO_SPLIT = 'doughnut',
  NEW_NOTE = 'new_note',
  MEASURE = 'measuretool',
  DRAG = 'drag',
  PATH = 'path',
  SETTINGS = 'settings',
  MENU = 'menu',
}
