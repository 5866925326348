import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import {
  setPayAsYouGoModal,
  setRepairSubscriptionModal,
  setSubscriptionModal,
  setSubscriptionWidget,
} from '@/store/subscription/actions';
import {
  ShowPayAsYouGoFunction,
  ShowPaywallFunction,
  ShowRepairSubscriptionFunction,
  SubscriptionFeature,
  SubscriptionFeatureData,
} from '@/modules/subscription/types';
import { IStore } from '@/store/types';
import { SubscriptionStore } from '@/store/subscription';
import { isEnabled } from '@/modules/subscription/helpers';
import { UseSubscriptionFeatureResponse } from '@/modules/subscription/hooks/useSubscriptionFeature';
import { trackEvents } from '@/helpers/utilities';
import useAuth from '@/modules/auth/hooks';
import {
  SubscriptionPlan,
  SubscriptionState,
} from '@/components/subscription/helpers/enum';
import { ISubscription } from '@/components/subscription/helpers/types';
import { Nullable } from '@/modules/common/types';
import { getSubscriptionState } from '../../../components/subscription/helpers';

export type HidePaywallFunction = () => void;

export type HidePayAsYouGoFunction = () => void;

export type ShowSubscriptionWidgetFunction = () => void;

export type HideSubscriptionWidgetFunction = () => void;

export type HideRepairSubscriptionFunction = () => void;

export interface UseSubscriptionResponse {
  /** A List of subscription features merged with "specialFeatures"  */
  features: SubscriptionFeatureData[];

  /** Plan of the user (organization) who is currently logged in.  */
  currentPlan: SubscriptionPlan | null;

  /** Show Paywall Modal */
  showPaywall: ShowPaywallFunction;

  /** Hide Paywall Modal */
  hidePaywall: HidePaywallFunction;

  /** Show Pay-as-you-go Modal */
  showPayAsYouGo: ShowPayAsYouGoFunction;

  /** Hide Pay-as-you-go Modal */
  hidePayAsYouGo: HidePayAsYouGoFunction;

  /** Show Subscription widget popup */
  showSubscriptionWidget: ShowSubscriptionWidgetFunction;

  /** Hide Subscription widget popup */
  hideSubscriptionWidget: HideSubscriptionWidgetFunction;

  /** Show repair subscription flow modal */
  showRepairSubscription: ShowRepairSubscriptionFunction;

  /** Hide repair subscription flow modal */
  hideRepairSubscription: HideRepairSubscriptionFunction;

  /** Data related to current subscription */
  subscription: Nullable<ISubscription>;

  /** Subscription state for current subscription */
  subscriptionState: Nullable<SubscriptionState>;

  /** Get data returned from API related to given feature */
  getFeatureData: (
    feature: SubscriptionFeature
  ) => UseSubscriptionFeatureResponse;
}

const useSubscription = (): UseSubscriptionResponse => {
  const dispatch = useDispatch();
  const { email, role } = useAuth();

  const { currentPlan, features, subscription } = useSelector<
    IStore,
    SubscriptionStore
  >((state) => state.subscription);

  const subscriptionState = useMemo(
    () => (subscription ? getSubscriptionState(subscription) : null),
    [subscription]
  );

  const getFeatureData = useCallback(
    (feature: SubscriptionFeature) => {
      const featureData = features.find(
        (_feature) => _feature.featureName === feature
      );

      if (!featureData) {
        return { data: null, enabled: false };
      }

      return {
        data: featureData,
        enabled: currentPlan ? isEnabled(featureData, currentPlan) : false,
      };
    },
    [features, currentPlan]
  );

  const showPaywall = useCallback<ShowPaywallFunction>(
    (feature, data) => {
      dispatch(setSubscriptionModal({ visible: true, feature, ...data }));

      /** Fire a Segment Event for the given feature */
      trackEvents('subscription__paywall_triggered', {
        email,
        access_type: role,
        feature_name: feature,
      });
    },
    [dispatch]
  );

  const hidePaywall = useCallback<HidePaywallFunction>(() => {
    dispatch(
      setSubscriptionModal({ visible: false, title: null, content: null })
    );
  }, [dispatch]);

  const showPayAsYouGo = useCallback<ShowPayAsYouGoFunction>(
    (order) => {
      dispatch(setPayAsYouGoModal({ visible: true, order }));
    },
    [dispatch]
  );

  const hidePayAsYouGo = useCallback<HidePayAsYouGoFunction>(() => {
    dispatch(setPayAsYouGoModal({ visible: false, order: null }));
  }, [dispatch]);

  const showSubscriptionWidget = useCallback<ShowSubscriptionWidgetFunction>(
    () => dispatch(setSubscriptionWidget({ visible: true })),
    [dispatch]
  );

  const hideSubscriptionWidget = useCallback<HideSubscriptionWidgetFunction>(
    () => dispatch(setSubscriptionWidget({ visible: false })),
    [dispatch]
  );

  const showRepairSubscription = useCallback<ShowRepairSubscriptionFunction>(
    (invoice) =>
      dispatch(
        setRepairSubscriptionModal({
          visible: true,
          invoice,
        })
      ),
    [dispatch]
  );

  const hideRepairSubscription = useCallback<HideRepairSubscriptionFunction>(
    () =>
      dispatch(
        setRepairSubscriptionModal({
          visible: false,
          invoice: null,
        })
      ),
    [dispatch]
  );

  return {
    showPaywall,
    hidePaywall,
    showPayAsYouGo,
    hidePayAsYouGo,
    showSubscriptionWidget,
    hideSubscriptionWidget,
    showRepairSubscription,
    hideRepairSubscription,
    getFeatureData,
    currentPlan,
    features,
    subscription,
    subscriptionState,
  };
};

export default useSubscription;
