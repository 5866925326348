import { Environment } from '@common/types';
import { config as StripeConfig, IStripeConfig, } from '@common/config/services/stripe';

interface IConfigurations {
  env: Environment;

  isSubscriptionsEnabled: boolean;

  /** Are Order Queues Enabled by default for all organizations ? */
  isOrderQueueEnabled: boolean;

  isShareNoteEnabled: boolean;

  stripe: IStripeConfig;
}

const getEnvironment = (): Environment => {
  const REACT_APP_ENV = process.env.REACT_APP_ENV || '';

  if (REACT_APP_ENV === 'app') {
    return 'PROD';
  }

  if (['dev', 'qa', 'uat'].includes(REACT_APP_ENV)) {
    return REACT_APP_ENV.toUpperCase() as Environment;
  }

  return 'DEV';
};

export const config: IConfigurations = {
  env: getEnvironment(),
  isSubscriptionsEnabled: true,
  isOrderQueueEnabled: true,
  isShareNoteEnabled: true,
  stripe: StripeConfig,
};
