import { Button, Col, Modal, Row, Tooltip } from 'antd';
import { memo, useEffect, useMemo, useState } from 'react';

import CopyTextComponent from './CopyTextComponent';
import DropdownComponent from './DropdownComponent';
import Users from './Users';
import useAuth from '@/modules/auth/hooks';
import Text from '@/components/elements/text/Text';
import { ReactComponent as GlobeIcon } from '@myProperties/components/properties/assets/globe.svg';
import { ReactComponent as LockIcon } from '@myProperties/components/properties/assets/lock.svg';
import StackedFilesIcon from '../../../assets/stacked-files.png';
import { ReactComponent as UsersSharedIcon } from '@myProperties/components/properties/assets/users-shared.svg';

import { useDispatch, useSelector } from 'react-redux';
import {
    deleteUserFromProperty,
    getDefaultAccess,
    getInvitedUsers,
    postInviteAccess,
    postInviteOnlyAccess,
    postPublicAccess,
    postWorkspaceAccess,
    singleMemberAccessChange,
} from './api';
import { IUser, IUserEmailsAndNames } from './common/types';
import './style.less';
// @ts-ignore
import useEmailValidation from '@/hooks/useEmailValidation';
import { getEmailAndNameFromUsers, getEmailsFromUsers } from './common/helpers';
import SelectComponent from './components/SelectComponent';
import { useRole } from '@/modules/auth/guards/RoleGuard';
import { catchError } from '@/helpers/utilities/api-utils';
import { AccessRight, UserRoleId } from '@/modules/common/types';
import useAlert from '@common/components/Alert/useAlert';
import { config } from '@/modules/common/config';
import { trackEvents } from '@/helpers/utilities';

const Strings = {
  permissionsDropdown: {
    options: [
      {
        icon: <UsersSharedIcon />,
        label: 'Everyone in the workspace can access',
        value: 'workspace',
      },
      {
        icon: <LockIcon />,
        label: 'Only people invited to the property can access',
        value: 'invite',
      },
      {
        icon: <GlobeIcon />,
        label: 'Anyone with the link can access (view only)',
        value: 'public',
      },
    ],
    selectedValue: {
      icon: <UsersSharedIcon />,
      label: 'Everyone in the workspace can access',
      value: 'workspace',
    },
  },
  /**Todo : When multiple views can be shared*/
  viewsDropdown: {
    options: [
      { label: 'All views' },
      { label: 'Draft 01' },
      { label: 'Draft 02' },
      { label: 'Draft 03' },
    ],
    selectedValue: { label: 'All views', value: 'all_views' },
  },
  roleDropdown: {
    options: [
      { label: 'View', value: 'view' },
      { label: 'Edit', value: 'edit' },
    ],
    selectedValue: { label: 'View', value: 'view' },
  },
  searchDropdown: {
    options: [
      { label: 'Can View', value: 'view' },
      { label: 'Can Edit', value: 'edit' },
    ],
    selectedValue: { label: 'Can View', value: 'view' },
  },
  sharedWithDropdown: {
    options: [
      { label: 'View', value: 'view' },
      { label: 'Edit', value: 'edit' },
    ],
    selectedValue: { label: 'View', value: 'view' },
  },
};

interface ISharePropertyModalProps {
  isModalVisible: boolean;
  setModalVisible: (visibility: boolean) => void;
  title: string;
  orderHash: string;
  orderId: number;
}

interface IMenuItem {
  label: string;
  icon?: JSX.Element;
  disabled?: boolean;
  value?: string;
}

const EMAIL_REGEX = /^([a-zA-Z0-9_\.-]+)@([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})$/;

const SharePropertyModal = ({
  isModalVisible,
  setModalVisible,
  title,
  orderHash,
  orderId,
}: ISharePropertyModalProps) => {
  const MAX_ALLOWED_INVITE = 2;
  const dispatch = useDispatch();
  const { isGuest, isAdmin } = useRole();
  const { organizationId, userId: loggedUserId, email: userEmail } = useAuth();
  const alert = useAlert();

  const organisationUsers = useSelector(
    (state: any) => state.teamManagement.organisationUserData
  );
  const bulkOrderTitle = useSelector(
    (state: any) => state.bulkOrder?.selectedOrderList?.data?.address
  );
  const bulkOrderHash = useSelector(
    (state: any) => state.bulkOrder?.selectedOrderList?.data?.orderHash
  );

  const [viewsDropdownSelectedOption, setViewsDropdownSelectedOption] =
    useState<IMenuItem>(Strings.viewsDropdown.selectedValue);
  const [
    permissionsDropdownSelectedOption,
    setPermissionsDropdownSelectedOption,
  ] = useState<IMenuItem>({
    icon: <UsersSharedIcon />,
    label: 'Everyone in the workspace can access',
    value: 'workspace',
  } as IMenuItem);
  const [searchDropdownSelectedOption, setSearchDropdownSelectedOption] =
    useState<IMenuItem>(Strings.searchDropdown.selectedValue);
  const [
    sharedWithDropdownSelectedOption,
    setSharedWithDropdownSelectedOption,
  ] = useState<IMenuItem>(Strings.sharedWithDropdown.selectedValue);
  const [showShareWarning, setShowShareWarning] = useState(false);
  const [warningEmail, setWarningEmail] = useState('');
  const [inviteeList, setInviteeList] = useState([]);
  const [inviteeMails, setInviteeMails] = useState<any>([]);
  const [defaultAccessData, setdefaultAccessData] = useState({});
  const [showSuggestion, setShowSuggetion] = useState(false);
  const [propertyOwner, setPropertyOwner] = useState<IUser | null>(null);
  const [isChipRed, setIsChipRed] = useState(true);

  const [userEmailsAndNames, setUserEmailsAndNames] = useState<
    Array<IUserEmailsAndNames>
  >([]);
  const [userEmails, setUserEmails] = useState<any>([]);
  const [isError, setIsError] = useState(false);
  const [alreadyInvitedError, setAlreadyInvitedError] = useState('');
  const [alreadyInvitedMailsArray, setAlreadyInvitedMailsArray] = useState<
    string[]
  >([]);
  const [inviteLimitExceedError, setinviteLimitExceedError] = useState('');
  const [maxAllowedError, setMaxAllowedError] = useState('');
  const [isAccessDropdownDisabled, setIsAccessDropdownDisabled] =
    useState<boolean>(false);
  const [accessDropdownTooltip, setAccessDropdownTooltip] = useState<
    string | null
  >(null);

  const [emails, setEmails] = useState<string[]>([]);

  const [canChangeGlobalPermissions, setCanChangeGlobalPermissions] =
    useState(false);
  const [canInviteUsersWithEdit, setCanInviteUsersWithEdit] =
    useState<boolean>(false);
  const shareUrl = useMemo(
    () => `${window.location.origin}/project/${orderHash || bulkOrderHash}`,
    [orderHash, bulkOrderHash, window.location.origin]
  );

  const [workspaceAccessData, setWorkspaceAccessData] = useState<any>({
    order_rights: 'view',
    view_id: 0,
    shared_type: 'order',
    sharer_id: loggedUserId,
    access_type: 'view',
    is_workspace_shared: true,
  });
  const [inviteAccessData, setInviteAccessData] = useState<any>({
    order_rights: 'edit',
    view_id: 0,
    shared_type: 'order',
    sharer_id: loggedUserId,
    owner_id: 0,
    owner_workspace_id: 0,
    access_type: 'edit',
    invitee_id: emails,
  });

  const [pulicAccessData, setPublicAccessData] = useState<any>({
    shared_type: 'order',
    sharer_id: loggedUserId,
    owner_id: 0,
    org_id: organizationId,
    view_id: 0,
  });

  const { isEmailError, invalidMails, invalidMailsArray } =
    useEmailValidation(emails);
  useEffect(() => {
    setUserEmailsAndNames(getEmailAndNameFromUsers(organisationUsers));
    setUserEmails(getEmailsFromUsers(organisationUsers));
  }, [organisationUsers]);

  useEffect(() => {
    setMaxAllowedError('');
  }, [emails]);

  useEffect(() => {
    const filteredEmails = emails.filter((email) => email.match(EMAIL_REGEX));

    const hasGuestUsers = organisationUsers
      .filter((orgMember: any) => orgMember.role_id === UserRoleId.Guest)
      .filter((orgMember: any) => filteredEmails.includes(orgMember.em));

    const hasOutsideOrgUsers = filteredEmails.filter(
      (val) => !organisationUsers.find((user: any) => user.em === val)
    );

    if (
      (Array.isArray(hasGuestUsers) && hasGuestUsers.length !== 0) ||
      (Array.isArray(hasOutsideOrgUsers) && hasOutsideOrgUsers.length !== 0)
    ) {
      setIsAccessDropdownDisabled(true);
      if (canInviteUsersWithEdit) {
        setAccessDropdownTooltip(
          'You have invited a Guest user. So, access right is restricted to View only. You can change right of other users after they are added'
        );
      }
      return;
    }

    const hasViewerUsers = organisationUsers
      .filter((orgMember: any) => orgMember.role_id === UserRoleId.Viewer)
      .filter((orgMember: any) => filteredEmails.includes(orgMember.em));

    if (Array.isArray(hasViewerUsers) && hasViewerUsers.length !== 0) {
      setIsAccessDropdownDisabled(true);
      if (canInviteUsersWithEdit) {
        setAccessDropdownTooltip(
          'You have invited a Viewer user. So, access right is restricted to View only. You can change right of other users after they are added'
        );
      }
      return;
    }

    setIsAccessDropdownDisabled(false);
    setAccessDropdownTooltip(null);
  }, [emails]);

  const getInvitedUsersData = async () => {
    try {
      let { data } = await getInvitedUsers(orderId);

      /**Property owner */
      let firstUser = data.find((x: IUser) => x !== undefined);
      setPropertyOwner(firstUser);

      /** Sort the data for list Consistency */
      let sortedData = data.sort((a: IUser, b: IUser) => {
        if (a.user_name && b.user_name) {
          return a.user_name > b.user_name ? 1 : -1;
        }

        if (a.user_name && !b.user_name) return -1;
        if (!a.user_name && b.user_name) return 1;

        if (a.email && b.email) {
          return a.email > b.email ? 1 : -1;
        }

        return 1;
      });
      setInviteeList(sortedData);
      /**Get Invite list emails */
      const getEmailsFromData = (users: IUser[]) => {
        return data
          ?.filter((user: IUser) => Boolean(!user.is_deleted))
          .map((user: IUser) => user.email);
      };
      setInviteeMails(getEmailsFromData(data));

      /**Check if edit access is allowed */
      const LoggedUserInList: IUser = data.find(
        (user: IUser) =>
          user.user_id === loggedUserId || user.email === userEmail
      );

      /** For Admin and Property creator, allow changing Property Base access "invited-only"|"workspace"|"public" */
      if (isAdmin || firstUser?.owner_email === userEmail) {
        setCanInviteUsersWithEdit(true);
        setCanChangeGlobalPermissions(true);
        return;
      }

      if (LoggedUserInList?.access_rights === AccessRight.Edit) {
        setCanInviteUsersWithEdit(true);
        setCanChangeGlobalPermissions(false);
        return;
      }

      setCanInviteUsersWithEdit(false);
      setCanChangeGlobalPermissions(false);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const getDefaultAccessData = async () => {
    try {
      let data = await getDefaultAccess(orderId);
      if (data.access === 'workspace') {
        setPermissionsDropdownSelectedOption(
          Strings.permissionsDropdown.options[0]
        );
      }
      if (data.access === 'invite') {
        setPermissionsDropdownSelectedOption(
          Strings.permissionsDropdown.options[1]
        );
      }
      if (data.access === 'public') {
        setPermissionsDropdownSelectedOption(
          Strings.permissionsDropdown.options[2]
        );
      }
      setdefaultAccessData(data);
    } catch (error) {
      console.error('Error', error);
    }
  };

  useEffect(() => {
    getInvitedUsersData();
    getDefaultAccessData();
  }, []);

  const onEmailChange = (val: Array<string>) => {
    if (val.length === 0) {
      setShowShareWarning(false);
      setIsChipRed(true);
      setAlreadyInvitedError('');
      setAlreadyInvitedMailsArray([]);
      setIsError(false);
    } else if (
      userEmails.includes(val[val.length - 1]) &&
      inviteeMails.includes(val[val.length - 1])
    ) {
      setEmails(val);
      setAlreadyInvitedError(`${val[val.length - 1]} is already invited.`);
      setAlreadyInvitedMailsArray([
        ...alreadyInvitedMailsArray,
        val[val.length - 1],
      ]);
      setIsError(true);
    } else if (val[val.length - 1] === propertyOwner?.owner_email) {
      setEmails(val);
      setAlreadyInvitedError(`${val[val.length - 1]} is already invited.`);
      setAlreadyInvitedMailsArray([
        ...alreadyInvitedMailsArray,
        val[val.length - 1],
      ]);
      setIsError(true);
    } else if (
      userEmails.includes(val[val.length - 1]) &&
      val.length <= MAX_ALLOWED_INVITE
    ) {
      setEmails(val);
      setinviteLimitExceedError('');
      if (isError) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    } else {
      if (val.length <= MAX_ALLOWED_INVITE) {
        const invalidMailInputs = val.filter(
          (value: any) => !value.match(EMAIL_REGEX)
        );
        if (
          invalidMailInputs.length > 0 &&
          invalidMailInputs.includes(val[val.length - 1])
        ) {
          setEmails(val);
          setIsError(true);
          setIsChipRed(true);
          setinviteLimitExceedError('');
        } else {
          setWarningEmail(val[val.length - 1]);
          setShowShareWarning(true);
          setEmails(val);
          setIsError(true);
          setIsChipRed(true);
          setinviteLimitExceedError('');
        }
      }
      if (val.length > MAX_ALLOWED_INVITE) {
        setinviteLimitExceedError(
          'Cannot share with more than 2 members in a single attempt.'
        );
      }
    }
  };

  const onEmailDeselect = (val: string) => {
    setEmails(emails.filter((email: string) => email !== val));
    const isAlreadyInvitedMail = emails.some((mail) =>
      alreadyInvitedMailsArray.includes(mail)
    );
    if (alreadyInvitedMailsArray.includes(val)) {
      setAlreadyInvitedError('');
      setIsError(false);
    }
    if (!invalidMails && !alreadyInvitedError && !inviteLimitExceedError) {
      setIsError(false);
    }

    if (val === warningEmail) {
      setShowShareWarning(false);
    }
    if (
      isAlreadyInvitedMail &&
      alreadyInvitedMailsArray.includes(val) &&
      alreadyInvitedMailsArray.length <= 1
    ) {
      setAlreadyInvitedMailsArray([]);
    }
    setShowShareWarning(false);
    setIsError(false);
    setWarningEmail('');
  };

  const onGlobalPermissionChange = (val: any) => {
    setPermissionsDropdownSelectedOption(val);

    const track = () => {
      trackEvents('property-sharing__access-changed', {
        orderId: orderId,
        previousAccess: permissionsDropdownSelectedOption.value || 'workspace',
        access: val.value,
      });
    };

    if (val.value === 'workspace') {
      postWorkspaceAccess(
        {
          ...workspaceAccessData,
          access_type: sharedWithDropdownSelectedOption.value,
        },
        orderId
      ).then(track);
    }
    if (val.value === 'invite') {
      postInviteOnlyAccess(orderId).then(track);
    }
    if (val.value === 'public') {
      postPublicAccess(pulicAccessData, orderId).then(track);
      setSharedWithDropdownSelectedOption({
        label: 'view',
        value: 'view',
        disabled: true,
      });
    }
  };

  const onRoleAccessChange = (val: any) => {
    setSharedWithDropdownSelectedOption(val);
    if (permissionsDropdownSelectedOption.value === 'workspace') {
      postWorkspaceAccess(
        { ...workspaceAccessData, access_type: val.value },
        orderId
      );
    }
    if (permissionsDropdownSelectedOption.value === 'invite') {
      postInviteAccess(
        { ...inviteAccessData, access_type: val.value },
        orderId
      );
    }
  };

  const onRemove = () => {
    const emailAfterRemoval = emails.filter(
      (email: string) => email !== warningEmail
    );

    setEmails(emailAfterRemoval);
    setShowShareWarning(false);
    setIsError(false);
    setIsChipRed(true);
  };

  const onSubmit = () => {
    if (emails.length !== 0) {
      const MAX_ALLOWED_WORKSPACE_USERS = 5;
      const MAX_ALLOWED_GUEST_USERS = 2;

      const activeUsers: IUser[] = inviteeList.filter(
        (user: IUser) => !user.is_deleted && user.email !== null
      );

      const workspaceEmailCount = emails.filter((email) =>
        userEmails.includes(email)
      ).length;
      const guestEmailCount = emails.length - workspaceEmailCount;

      const invitedWorkspaceEmailCount = activeUsers.filter((user) =>
        userEmails.includes(user.email)
      ).length;
      const invitedGuestEmailCount =
        activeUsers.length - invitedWorkspaceEmailCount;

      if (
        workspaceEmailCount + invitedWorkspaceEmailCount >
        MAX_ALLOWED_WORKSPACE_USERS
      ) {
        setMaxAllowedError(
          `You can invite max. ${MAX_ALLOWED_WORKSPACE_USERS} workspace members to a property.`
        );
        return;
      }

      /** TODO: Remove the following code block entirely after subscriptions are started permenently  */
      if (!config.isSubscriptionsEnabled) {
        if (
          guestEmailCount + invitedGuestEmailCount >
          MAX_ALLOWED_GUEST_USERS
        ) {
          setMaxAllowedError(
            `You can invite max. ${MAX_ALLOWED_GUEST_USERS} guests.`
          );
          return;
        }
      }

      const trackingData = {
        orderId: orderId,
        invitees: emails,
        right: searchDropdownSelectedOption.value,
      };

      postInviteAccess(
        {
          ...inviteAccessData,
          access_type: searchDropdownSelectedOption.value,
          order_rights: searchDropdownSelectedOption.value,
          invitee_id: emails,
        },
        orderId
      )
        .then(() => {
          trackEvents(
            'property-sharing__members_invitation-failed',
            trackingData
          );
          alert.success('Shared Successfully!', 1500);
          getInvitedUsersData();
          setEmails([]);
        })
        .catch(() => {
          trackEvents(
            'property-sharing__members_invitation-sent',
            trackingData
          );
        });
    } else {
      setAlreadyInvitedError('Empty email field.');
    }
  };

  const onSingleRoleChange = (option: any, email: string) => {
    singleMemberAccessChange(
      {
        order_rights: option.value,
        view_id: 0,
        shared_type: 'order',
        sharer_id: loggedUserId,
        owner_id: 0,
        owner_workspace_id: 0,
        access_type: option.value,
        invitee_id: [email],
      },
      orderId
    )
      // @ts-ignore
      .then((res, rej) => {
        trackEvents('property-sharing__members_access-changed', {
          orderId: orderId,
          email: email,
          right: option.value,
        });
        getInvitedUsersData();
      });
  };

  const onSingleUserDelete = (option: any, email: string) => {
    deleteUserFromProperty(
      {
        parameter: {
          email: email,
        },
      },
      orderId
    ) // @ts-ignore
      .then((res, rej) => {
        trackEvents('property-sharing__members_access-removed', {
          orderId: orderId,
          email: email,
        });
        getInvitedUsersData();
      })
      .catch(catchError);
  };

  /**Hack to close share warning popup before closing modal */
  const onCloseModal = () => {
    setShowShareWarning(false);
    setIsChipRed(true);
    setTimeout(() => {
      setModalVisible(false);
    }, 10);
  };

  return (
    <Modal
      footer={null}
      onCancel={() => {
        onCloseModal();
        trackEvents('property-sharing__closed', {
          orderId: orderId,
        });
      }}
      onOk={() => {
        onCloseModal();
      }}
      centered
      title={
        <>
          Add People to{' '}
          <span style={{ color: '#666666' }}>{title || bulkOrderTitle}</span>
        </>
      }
      visible={isModalVisible}
      width={720}
      className='share-property-modal'
    >
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              alt='...'
              height={13}
              src={StackedFilesIcon}
              width={13}
              style={{ marginRight: 10 }}
            />
            <DropdownComponent
              disabled
              iconStyles={{ display: 'none' }}
              labelStyles={{ color: '#000000', cursor: 'auto', marginRight: 0 }}
              options={Strings.viewsDropdown.options}
              selectedOption={viewsDropdownSelectedOption}
              setSelectedOption={(option) => {
                setViewsDropdownSelectedOption(option);
              }}
            />
          </div>
        </Col>
        <Col span={24} style={{ marginBottom: 20 }}>
          <Row gutter={8}>
            <Col span={20} style={{ position: 'relative' }}>
              <SelectComponent
                showSuggestion={showSuggestion}
                setShowSuggetion={(value) => setShowSuggetion(value)}
                setShowShareWarning={(value) => setShowShareWarning(value)}
                userEmails={userEmails}
                onEmailChange={(value) => onEmailChange(value)}
                onEmailDeselect={(value) => onEmailDeselect(value)}
                onRemove={() => onRemove()}
                emails={emails}
                userEmailsAndNames={userEmailsAndNames}
                showShareWarning={showShareWarning}
                invalidMails={invalidMails}
                warningEmail={warningEmail}
                isChipRed={isChipRed}
                setIsChipRed={(value: boolean) => setIsChipRed(value)}
                alreadyInvitedError={alreadyInvitedError}
                invalidMailsArray={invalidMailsArray as string[]}
                alreadyInvitedMailsArray={alreadyInvitedMailsArray}
                setIsError={(value) => setIsError(value)}
              />
              <div
                style={{
                  position: 'relative',
                  top: -26,
                  right: -435,
                  width: 150,
                  opacity:
                    canInviteUsersWithEdit && !isAccessDropdownDisabled
                      ? 1
                      : 0.6,
                }}
              >
                <DropdownComponent
                  name='property-invite'
                  disabled={!canInviteUsersWithEdit || isAccessDropdownDisabled}
                  tooltip={accessDropdownTooltip}
                  componentClass='share-search-dropdown'
                  options={Strings.searchDropdown.options}
                  selectedOption={searchDropdownSelectedOption}
                  setSelectedOption={(option) => {
                    setSearchDropdownSelectedOption(option);
                  }}
                  classFromParent='share-search-select'
                />
              </div>
            </Col>
            <Col span={4}>
              <Tooltip
                visible={isGuest}
                title='Guests are not allowed to share a property'
              >
                <Button
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: 36,
                    justifyContent: 'center',
                    width: 110,
                    marginLeft: -4,
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                  type='primary'
                  onClick={onSubmit}
                  disabled={
                    isError ||
                    emails.length === 0 ||
                    isEmailError ||
                    alreadyInvitedMailsArray.length > 0 ||
                    isGuest
                  }
                >
                  Send Invite
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Row style={{ position: 'relative' }} className='error-show-container'>
          {emails.length !== 0 && (
            <>
              <Text
                type='p23'
                color='error-red3'
                style={{ position: 'absolute', top: '42px', left: '-670px' }}
              >
                {invalidMails} {alreadyInvitedError}
                {inviteLimitExceedError}
              </Text>
            </>
          )}
          {emails.length === 0 && (
            <>
              <Text
                type='p23'
                color='error-red3'
                style={{ position: 'absolute', top: '42px', left: '-670px' }}
              >
                {alreadyInvitedError}
              </Text>
            </>
          )}
          {!invalidMails && !alreadyInvitedError && !inviteLimitExceedError && (
            <>
              <Text
                type='p23'
                color='error-red3'
                style={{ position: 'absolute', top: '42px', left: '-670px' }}
              >
                {maxAllowedError}
              </Text>
            </>
          )}
        </Row>
        <Col span={24} style={{ marginBottom: 12 }}>
          <p
            style={{
              color: '#333333',
              fontSize: 12,
              fontWeight: 500,
              lineHeight: '18px',
              marginBottom: 0,
            }}
          >
            Shared with
          </p>
        </Col>
        <Col span={24} style={{ marginBottom: 16 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginRight: '3.1rem',
            }}
          >
            <div style={{ opacity: canChangeGlobalPermissions ? 1 : 0.6 }}>
              <DropdownComponent
                disabled={!canChangeGlobalPermissions}
                labelStyles={{ color: '#333333' }}
                options={Strings.permissionsDropdown.options}
                selectedOption={permissionsDropdownSelectedOption}
                setSelectedOption={(option) => onGlobalPermissionChange(option)}
                componentClass='global-permission-dd'
              />
            </div>

            <DropdownComponent
              disabled={true}
              iconStyles={{ opacity: '0.3', cursor: 'not-allowed' }}
              labelStyles={{ color: '#999999' }}
              options={Strings.roleDropdown.options}
              selectedOption={sharedWithDropdownSelectedOption}
              setSelectedOption={(option) => onRoleAccessChange(option)}
              classFromParent='view-edit-global-dd'
            />
          </div>
        </Col>
        <Col span={24} style={{ marginBottom: '1.5rem' }}>
          <Users
            data={inviteeList}
            userEmails={userEmails}
            orderId={orderId}
            loggedUserId={loggedUserId}
            onSingleRoleChange={(option, email) =>
              onSingleRoleChange(option, email)
            }
            onSingleUserDelete={(option, email) =>
              onSingleUserDelete(option, email)
            }
            propertyOwner={propertyOwner as IUser}
            loggedUserEmail={userEmail}
          />
        </Col>
        <Col span={24}>
          <CopyTextComponent
            text={shareUrl}
            buttonText='Copy Link'
            onClick={() =>
              trackEvents('property-sharing__link-copied', {
                orderId: orderId,
                link: shareUrl,
              })
            }
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(SharePropertyModal);
