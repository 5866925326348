import React, {useMemo, useState} from 'react';
import { Col, Input, Row } from 'antd';
import CounterButton from './CounterButton.tsx';
import './style.less';

const Counter = (props) => {
  const [input, setInput] = useState(props.value);

  const isIncrementEnabled = useMemo(() => {
    if (typeof props.max === 'undefined') {
      /** We don't have max value. So, Increment would never be `disabled` */
      return true;
    }

    const nextValue = input + props.step;
    return nextValue <= props.max;
  }, [input, props.step, props.max]);

  const isDecrementEnabled = useMemo(() => {
    if (typeof props.min === 'undefined') {
      /** We don't have min value. So, Decrement would never be `disabled` */
      return true;
    }

    const prevValue = input - props.step;
    return prevValue >= props.min;
  }, [input, props.step, props.min]);

  const incrementHandler = async () => {
    if (!isIncrementEnabled) return;

    await setInput(input + props.step);
    await props.handleChange(input + props.step, props.name);
  };

  const decrementHandler = async () => {
    if (!isDecrementEnabled) return;

    await setInput(input - props.step);
    await props.handleChange(input - props.step, props.name);
  };
  const formatter = () => {
    if (props.isPercent) {
      const { max, min } = props;
      return `${(input / max) * 100} %`;
    } else {
      return input;
    }
  };
  return (
    <Row justify='center' align='middle' className='counter-container'>
      <Col span={12}>
        <Input
          style={{
            border: 0,
            backgroundColor: 'transparent',
            color:'#000000d9',
            padding:0
          }}
          name='opacity'
          className='pl-3 py-0'
          defaultValue={formatter()}
          value={formatter()}
          disabled
        />
      </Col>
      <Col span={12} justify='center'>
        <Row justify='end' align='middle'>
          <CounterButton 
            text='-' 
            style={{ marginRight: '2px', borderRadius: '2px 0px 0px 2px' }} 
            onClick={decrementHandler}
            disabled={!isDecrementEnabled}
          />
          <CounterButton 
            text='+' 
            style={{ marginRight: '3px', borderRadius: '0px 2px 2px 0px' }} 
            onClick={incrementHandler}
            disabled={!isIncrementEnabled}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default Counter;
