import React from 'react';
import { Dropdown, Menu, Row } from 'antd';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { AccessRight, ViewType } from '@/modules/common/types';
import LayerGuard from '../../../../modules/project/guards/LayerGuard';
import SRUserTypeAccessGuard from '@/modules/project/guards/SRUserTypeAccessGuard';
import { ReactComponent as KebabMenu } from '../../../../assets/action-center/kebab-menu.svg';
import './style.less';

interface ActionCenterKebabMenuProps {
  menuList: MenuItemProps[];
}

interface MenuItemProps {
  key: number;
  disabled?: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
}

const ActionCenterKebabMenu = ({ menuList }: ActionCenterKebabMenuProps) => {
  const dropdownMenu = (
    <Menu className='action-center-menu'>
      {menuList.map((item, index) => (
        <Menu.Item key={index} onClick={item.onClick} disabled={item.disabled}>
          <LayerGuard view={ViewType.STATIC} disabled tooltip>
            <SRUserTypeAccessGuard right={AccessRight.Edit} tooltip>
              <Row align='middle' className='h-100'>
                {item.icon}
                <Typography className='ml-3'>{item.label}</Typography>
              </Row>
            </SRUserTypeAccessGuard>
          </LayerGuard>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Box
      style={{ height: 24, width: 24 }}
      justify={'space-around'}
      align='middle'
    >
      <Dropdown
        overlay={dropdownMenu}
        trigger={['click']}
        className='sr-icon-container'
        placement='bottomLeft'
        arrow
      >
        <KebabMenu />
      </Dropdown>
    </Box>
  );
};

export default ActionCenterKebabMenu;
