import React from 'react';
import { SVGProps, useState } from 'react';
import { Button, Row, Tooltip } from 'antd';
import Text from '@/components/elements/text/Text';

interface HighlightViewProps {
  title?: string;
  image: SVGProps<any>;
  onClick?: () => void;
  className?: string;
  disabled: boolean;
}

const HighlightView = ({
  title,
  image,
  onClick,
  className,
  disabled,
}: HighlightViewProps) => {

  return (
    <Tooltip
      placement='right'
      title={disabled ? 'You wont be able to create an estimation view while pricing is in progress.' : ''}
      overlayClassName='thumbnail-tooltip'
    >
      <Button
        className={className}
        onClick={onclick}
      >
        <Row justify='center' className='mb-2 mt-2'>
          {image}
        </Row>
        <Row justify='center'>
          <Text type='p36' color='gray-shade'>
            {title}
          </Text>
        </Row>
      </Button>
    </Tooltip>
  );
};

export default HighlightView;
