import * as turf from '@turf/turf';
import { transformExtent } from 'ol/proj';
import LayerGroup from 'ol/layer/Group';
import XYZ from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';
import { trackEvents } from '@/helpers/utilities';
import { uuidv4 } from '../helpers/utilities/api-utils';
import {
  getNearmapObliqueType,
  tileLoadFunction,
} from '../helpers/mapUtils/nearmap-oblique';
import { getNearmapSurveys } from './api/nearmap/api';
import { isModifying } from '../modules/project/helpers';
import RenderEvent from 'ol/render/Event';
import { NEARMAP_PUBLIC_KEY } from '@/helpers/constants';

const createNearmapLayerGroup = async (
  imageryDate,
  geoJson,
  orderId,
  sessionId,
  apiKey
) => {
  const layers = [];
  const geometryType = geoJson['type'].toLowerCase();

  if (geometryType === 'polygon') {
    // Take a buffer because we want to show more images towards the corner
    const polygon = turf.buffer(turf.polygon(geoJson['coordinates']), 5, {
      units: 'meters',
    });

    const bbox = turf.bbox(polygon);

    const surveyId = await fetchNearmapSurveyId(bbox, imageryDate, orderId);

    if (surveyId) {
      const extent = transformExtent(bbox, 'EPSG:4326', 'EPSG:3857');

      layers.push(
        nearmapLayer(
          extent,
          nearmapSource(surveyId, 21, orderId, sessionId, apiKey)
        )
      );
    }
  } else if (geometryType === 'multipolygon') {
    let surveyId = null;

    for (const i in geoJson['coordinates']) {
      const coords = geoJson['coordinates'][i];
      let polygon = turf.buffer(turf.polygon(coords), 20, {
        units: 'meters',
      });

      const bbox = turf.bbox(polygon);

      if (!surveyId) {
        surveyId = await fetchNearmapSurveyId(bbox, imageryDate, orderId);
      }

      if (surveyId) {
        const extent = transformExtent(bbox, 'EPSG:4326', 'EPSG:3857');
        layers.push(
          nearmapLayer(
            extent,
            nearmapSource(surveyId, 21, orderId, sessionId, apiKey)
          )
        );
      }
    }
  }

  return new LayerGroup({
    layers: layers,
    name: 'nearmap',
  });
};

const getNearmapLayers = (
  source,
  geoJson,
  fullMap = false,
  isModifying = false
) => {
  if (fullMap || isModifying) {
    return [nearmapLayer(undefined, source)];
  }

  const geometryType = geoJson['type'].toLowerCase();

  if (geometryType === 'polygon') {
    /** Create the polygon from the geoJson coordinates */
    const polygon = turf.polygon(geoJson['coordinates']);

    const cleanedPolygon = turf.cleanCoords(polygon);

    let unKinkPolygon;

    const kinks = turf.kinks(cleanedPolygon);

    /** Unkink the polygon before buffering */
    if (kinks.features.length !== 0) {
      unKinkPolygon = turf.unkinkPolygon(cleanedPolygon);
    }

    /** Apply the buffer to the unkinked polygon because we want to show more images towards the corner */
    const bufferedPolygon = turf.buffer(
      kinks.features.length === 0 ? cleanedPolygon : unKinkPolygon,
      10,
      {
        units: 'meters',
      }
    );

    const bbox = turf.bbox(bufferedPolygon);

    const extent = transformExtent(bbox, 'EPSG:4326', 'EPSG:3857');

    return [nearmapLayer(extent, source)];
  }

  if (geometryType === 'multipolygon') {
    const layers = [];

    geoJson['coordinates'] = sanitizeMultipolygonCoordinates(
      geoJson['coordinates']
    );

    for (const i in geoJson['coordinates']) {
      const coords = geoJson['coordinates'][i];

      let unKinkPolygon;

      /** Create and optionally unkink the polygon */
      let polygon = turf.polygon(sanitizeMultipolygonCoordinateGroup(coords));

      const cleanedPolygon = turf.cleanCoords(polygon);

      const kinks = turf.kinks(cleanedPolygon);

      /** Unkink the polygon before buffering */
      if (kinks.features.length !== 0) {
        unKinkPolygon = turf.unkinkPolygon(cleanedPolygon);
      }

      /** Buffer the polygon (either unkinked or original) */
      let bufferedPolygon = turf.buffer(
        kinks.features.length === 0 ? cleanedPolygon : unKinkPolygon,
        20,
        {
          units: 'meters',
        }
      );

      const bbox = turf.bbox(bufferedPolygon);
      const extent = transformExtent(bbox, 'EPSG:4326', 'EPSG:3857');

      layers.push(nearmapLayer(extent, source));
    }

    return layers;
  }

  return [];
};

const createNearmapLayerGroupWithSurveyId = async (
  surveyId,
  geoJson,
  orderId,
  maxZoom,
  sessionId,
  apiKey,
  viewType,
  activeTool,
  tileSource = 'siterecon',
  fullMap = false,
  mapRef = null
) => {
  const source = nearmapSource(
    surveyId,
    maxZoom,
    orderId,
    sessionId,
    apiKey,
    viewType,
    activeTool,
    tileSource,
    mapRef
  );

  return new LayerGroup({
    layers: surveyId
      ? getNearmapLayers(source, geoJson, fullMap, isModifying(activeTool))
      : [],
    name: 'nearmap',
  });
};

const emptyLayerGroup = () => {
  return new LayerGroup({
    layers: [],
    name: 'nearmap',
  });
};

let coordinates = {};

const nearmapSource = (
  surveyId,
  maxZoom,
  orderId,
  sessionId,
  apiKey,
  viewType,
  activeTool,
  tileSource,
  mapRef = null
) => {
  const obliqueType = getNearmapObliqueType(viewType);
  coordinates = {};

  const isProduction = ['app', 'prod'].includes(process.env.REACT_APP_ENV);

  const wmts_url = `https://map.siterecon.ai/api/wmts/v1/tile/${surveyId}/${obliqueType}/{z}/{x}/{y}?order_id=${orderId}&session_id=${sessionId}&apikey=${apiKey}`;

  const nearmap_url = `https://api.nearmap.com/tiles/v3/surveys/${surveyId}/${obliqueType}/{z}/{x}/{y}.img?tertiary=satellite&apikey=${NEARMAP_PUBLIC_KEY}`;

  const properties = {
    url: wmts_url,
    crossOrigin: 'Anonymous',
    minZoom: 12,
    maxZoom: maxZoom,
    transition: 0,
  };

  if (viewType) {
    /** When `viewType` is provided, we may need to load and rotate image according to `obliqueType` */
    properties.tileLoadFunction = (imageTile, src) => {
      tileLoadFunction(imageTile, src, obliqueType);
    };
  }

  const source = new XYZ(properties);

  /** Infinite tile load issue fix */

  // let onRenderCompletedFired = false;

  // const onTileLoadEnd = (event) => {
  //   if (onRenderCompletedFired) {
  //     return;
  //   }

  //   const tileCoord = JSON.stringify(event.tile.tileCoord);

  //   if (coordinates.hasOwnProperty(tileCoord)) {
  //     /** We already rendered all the tiles, now we are in infinite loop. Need to break that. */
  //     onRenderCompletedFired = true;
  //     mapRef.dispatchEvent(new RenderEvent('rendercomplete', mapRef, null));
  //     // source.setTileLoadFunction(() => {
  //     //   return;
  //     // });
  //     source.un('tileloadend', onTileLoadEnd);
  //   } else {
  //     coordinates[JSON.stringify(event.tile.tileCoord)] = 1;
  //   }
  // };

  // if (mapRef) {
  //   source.on(['tileloadend'], onTileLoadEnd);
  // }

  return source;
};

const nearmapLayer = (extent, source) => {
  return new TileLayer({
    zIndex: -0.5,
    extent: extent,
    source: source,
    minZoom: 1,
    maxZoom: 24,
  });
};

const fetchNearmapSurveyId = async (bboxOrGeoJson, imageryDate, orderId) => {
  const response = await getNearmapSurveys(bboxOrGeoJson, orderId);

  if ('surveys' in response.data) {
    for (let i in response.data['surveys']) {
      const survey = response.data['surveys'][i];
      if (
        survey['captureDate'].replaceAll('-', '') ===
        imageryDate.replaceAll('-', '')
      ) {
        return survey['id'];
      }
    }
  }

  return null;
};

export const fetchNearmapSurveysByParcel = async (parcel, orderId) => {
  const response = await getNearmapSurveys(parcel, orderId);
  return response.data;
};

export const fetchNearmapSurveysByPoint = async (center, orderId) => {
  const polygon = turf.buffer(turf.point(center), 10, {
    units: 'meters',
  });

  const response = await getNearmapSurveys(turf.bbox(polygon), orderId);
  return response.data;
};

export const renderNearmap = async (
  orderId,
  imageryDate,
  tileData,
  geoJson,
  parcelArea
) => {
  const sessionId = uuidv4();

  const token = localStorage.getItem('sr_token')
    ? JSON.parse(localStorage.getItem('sr_token')).token
    : null;

  if (
    geoJson === null ||
    imageryDate === undefined ||
    imageryDate === null ||
    imageryDate === '' ||
    orderId === null
  ) {
    return emptyLayerGroup();
  }

  try {
    return createNearmapLayerGroup(
      imageryDate,
      geoJson,
      orderId,
      sessionId,
      token
    );
  } catch (e) {
    // If anything goes wrong. Do not render nearmap
    console.error(e);
    return emptyLayerGroup();
  }
};

export const renderNearmapWithSurveyId = async (
  surveyId,
  geoJson,
  orderId,
  propertyName,
  zoom,
  wkspId,
  /** Resolution on which we want to render the nearmap, {@link ResolutionType} */
  maxZoom,
  /** {@link ViewOption} that we want to show on the map */
  viewType,
  activeTool,
  tileSource = 'siterecon',

  /** @type boolean - If `true`, nearmap layer would be rendered for the full screen */
  fullMap = false,

  mapRef = null
) => {
  const sessionId = uuidv4();
  const token = localStorage.getItem('mapkey') ?? null;

  let area = 0;
  let type = '';
  let countOfPolygons = 0;
  const areaInAcres = area * 0.000247105;

  if (!fullMap) {
    if (geoJson === null || surveyId === null) {
      trackEvents('render-nearmap__failed', {
        reason: geoJson === null ? 'geoJson is null' : 'surveyId is null',
        orderId: orderId,
        parcelArea: areaInAcres,
        parcelType: type,
        countOfPolygons: countOfPolygons,
        propertyName: propertyName,
        zoom: zoom,
        workspace: wkspId,
      });
      return emptyLayerGroup();
    }
  }

  try {
    return createNearmapLayerGroupWithSurveyId(
      surveyId,
      geoJson,
      orderId,
      maxZoom,
      sessionId,
      token,
      viewType,
      activeTool,
      tileSource,
      fullMap,
      mapRef
    );
  } catch (e) {
    // If anything goes wrong. Do not render nearmap
    console.error(e);
    return emptyLayerGroup();
  }
};

export const sanitizeMultipolygonCoordinateGroup = (coordinateGroup) => {
  if (Array.isArray(coordinateGroup[0]?.[0]?.[0])) {
    /** If coordinates are nested invalidly, we need to sanitize them  */
    return coordinateGroup[0];
  }

  if (coordinateGroup.length !== 1 && coordinateGroup[0]?.length === 2) {
    return [coordinateGroup];
  }

  return coordinateGroup;
};

export const sanitizeMultipolygonCoordinates = (coordinates) => {
  return (
    coordinates?.map((coordinateGroup) =>
      sanitizeMultipolygonCoordinateGroup(coordinateGroup)
    ) || []
  );
};

export const sanitizeIfMultipolygonGeoJson = (geoJson) => {
  if (geoJson?.type !== 'MultiPolygon') {
    return geoJson;
  }

  const sanitized = sanitizeMultipolygonCoordinates(geoJson.coordinates);

  return {
    ...geoJson,
    coordinates: sanitized,
  };
};
