import useInactivityTracker from './useInactivityTracker';
import { saveIfAnyUnSyncedModifications } from '../helpers';

interface UseAutoSyncWhileInactiveProps {
  isResetModificationsModalVisible: boolean;
}

const useAutoSyncWhileInactive = ({
  isResetModificationsModalVisible,
}: UseAutoSyncWhileInactiveProps) => {
  const handleAutoSaveWhileInactive = () => {
    if (isResetModificationsModalVisible) {
      /** When reset modification modal is visible, we never want to auto sync the changes */
      return;
    }

    /** As this will be the callback for `useInactivityTracker`, local state values would be outdated here. We need to access the updated values from redux directly */
    saveIfAnyUnSyncedModifications();
  };

  useInactivityTracker(handleAutoSaveWhileInactive, 7000);
};

export default useAutoSyncWhileInactive;
