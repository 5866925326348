import React from "react";

const UndoRedoSVG = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5751 11.25C13.4618 11.25 13.3627 11.212 13.2777 11.1361C13.1927 11.0595 13.1502 10.9589 13.1502 10.8342V8.33911C13.1502 7.53515 12.8599 6.84901 12.2792 6.28069C11.6985 5.71238 10.9975 5.42822 10.176 5.42822H1.33839L4.54628 8.56782C4.63126 8.63713 4.67375 8.72723 4.67375 8.83812C4.67375 8.94901 4.62418 9.04604 4.52504 9.12921C4.44006 9.22624 4.34092 9.27475 4.22762 9.27475C4.11431 9.27475 4.01517 9.22624 3.9302 9.12921L0.191199 5.49059C0.120385 5.40743 0.0708143 5.32758 0.0424886 5.25107C0.0141628 5.17511 0 5.09554 0 5.01238C0 4.92921 0.0141628 4.84937 0.0424886 4.77285C0.0708143 4.69689 0.120385 4.61733 0.191199 4.53416L3.95144 0.874753C4.02226 0.791584 4.11431 0.75 4.22762 0.75C4.34092 0.75 4.44006 0.798515 4.52504 0.895545C4.62418 0.978713 4.67375 1.07574 4.67375 1.18663C4.67375 1.29752 4.62418 1.39455 4.52504 1.47772L1.33839 4.59653H10.176C11.2382 4.59653 12.1413 4.96026 12.8851 5.6877C13.6284 6.4157 14 7.2995 14 8.33911V10.8342C14 10.9589 13.9612 11.0595 13.8836 11.1361C13.8054 11.212 13.7026 11.25 13.5751 11.25Z"
      fill="#1C1B1F"
    />
  </svg>
);

export default UndoRedoSVG;
