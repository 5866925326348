import { ThunkAction } from 'redux-thunk';
import {
  setSubscription,
  setSubscriptionData,
} from '@/store/subscription/actions';
import { getData } from '@/modules/subscription/api';
import { getSubscription as getSubscriptionApi } from '../../components/api/subscription';

type GetSubscriptionDataThunk = (
  /** User Email is required to update "segment analytics" */
  email: string
) => ThunkAction<any, any, any, any>;

export const getSubscriptionData: GetSubscriptionDataThunk =
  (email) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await getData();
        resolve(dispatch(setSubscriptionData(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

export const getSubscription =
  (): ThunkAction<any, any, any, any> => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await getSubscriptionApi();
        resolve(dispatch(setSubscription(data)));
      } catch (error) {
        reject(error);
      }
    });
  };
