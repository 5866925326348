import React, { useEffect } from 'react';
import { ObliqueType } from '../../../../containers/nearmap-oblique-selection-dd/types';
import CompassNorth from '@/assets/compass-png.png';
import CompassNorthDisabled from '@/assets/compass-disabled-state.svg';
import RotateMapTooltip from '../RotateMapTooltip';
import { Tooltip } from '@siterecon/sr-component/dist/sr-component-library';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../store/types';
import { OrderNearmap } from '../../../../../store/order/state';
import classNames from 'classnames';
import { trackEvents } from '../../../../../helpers/utilities';
import { MapControlsEvt } from '../../../../../segment';

interface CompassMapActionProps {
  darkMode?: boolean;
}

const CompassMapAction = ({ darkMode }: CompassMapActionProps) => {

  useEffect(() => {
    trackEvents(MapControlsEvt.MapCompassVisible);
  },[]);

  const selectedNearmap = useSelector<IStore, OrderNearmap | null>(
    (state) => state.order.selectedNearmap
  );

  if (selectedNearmap && selectedNearmap.type !== ObliqueType.VERT) {
    return (
      <Tooltip
        placement='left'
        title='Map rotation blocked when viewing side views of nearmap'
      >
        <img
          src={CompassNorthDisabled}
          className='rotate-compass disabled'
          alt='reset to north'
          style={
            {
              height: 'calc(100vh * (36 / 820))',
              aspectRatio: 1,
            }
          }
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      placement='left'
      color={'white'}
      trigger={'hover'}
      overlayStyle={{
        width: 'calc(100vw * (300 / 1440))',
        height: 'calc(100vh * (100 / 820))',
        maxWidth: 'calc(100vw * (500 / 1440)) !important',
      }}
      overlayInnerStyle={{ borderRadius: 10, marginLeft: '-78px' }}
      title={<RotateMapTooltip />}
    >
      <img
        src={CompassNorth}
        className={classNames('rotate-compass', { 'dark-mode': darkMode })}
        alt='reset to north'
        style={
          {
            height: 'calc(100vh * (36 / 820))',
            aspectRatio: 1,
          }
        }
      />
    </Tooltip>
  );
};

export default CompassMapAction;
