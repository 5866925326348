import React from 'react';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';

const NoLayerText = () => {
  return (
    <Box className='no-layers-cover'>
      <Box className='no-layers'>
        <Typography
          fontWeight={400}
          fontSize={12}
          lineHeight={'18px'}
          style={{
            textAlign: 'center',
            fontStyle: 'italic',
            color: '#666666',
          }}
        >
          No layers have been added
        </Typography>
      </Box>
    </Box>
  );
};

export default NoLayerText;
