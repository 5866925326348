import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCallback } from 'react';
import { IObject } from '@/modules/common/types';
import { useRouterPlusContext } from '@/modules/router-plus/context';
import useRouterPlusParams from '@/modules/router-plus/hooks/use-router-plus-params';
import useRouterPlusSearchParams from '@/modules/router-plus/hooks/use-router-plus-search-params';

type RouteMode = 'path' | 'search';

interface Options {
  keepQueryParams?: boolean;
  removeQueryParams?: string | string[];
  behaviour?: RouteBehaviour;
  mode?: RouteMode; // Defaults to "path"
}
export type RedirectFunction = (params: IObject, options?: Options) => void;

type RouteBehaviour = 'push' | 'replace';

interface UseRouterPlusResponse {
  /** Soft redirect to same page with different path params */
  redirect: RedirectFunction;
}

export const replacePathParams = (link, params) => {
  return link.replace(/:\w+\??/g, (segment) => {
    // Remove the first ":" (colon)
    let param = segment.substring(1);
    if (param.indexOf('?') !== -1) {
      // If "?" exists in the end, remove it
      param = param.slice(0, -1);
    }

    return params[param] || param;
  });
};

const useRouterPlusRedirect = (): UseRouterPlusResponse => {
  const history = useHistory();
  const pathParams = useRouterPlusParams();
  const searchParams = useRouterPlusSearchParams();
  const { path } = useRouteMatch();
  const { setParams, setSearchParams } = useRouterPlusContext();

  const redirect: RedirectFunction = useCallback(
    (newParam, options) => {
      if (!path) return;

      let newPath = '';

      const newParameters = { ...pathParams, ...newParam };
      if (options?.mode === 'search') {
        const keepQueryParams = options?.keepQueryParams || false;

        const emptySearchParams = new URLSearchParams();
        const currentSearchParams = searchParams;

        /** If current URL already have some params, we want to use the latest params from URL  */
        if (keepQueryParams) {
          const urlSearchParams = new URLSearchParams(window.location.search);

          for (const [key, value] of Array.from(urlSearchParams.entries())) {
            currentSearchParams.set(key, value);
          }
        }

        const searchParamsReference = keepQueryParams
          ? currentSearchParams
          : emptySearchParams;

        for (const key in newParam) {
          searchParamsReference.set(key, newParam[key]);
        }

        if (options?.removeQueryParams) {
          const removeQueryParams =
            typeof options.removeQueryParams === 'string'
              ? [options.removeQueryParams]
              : options.removeQueryParams;

          removeQueryParams.forEach((key) => {
            searchParamsReference.delete(key);
          });
        }

        newPath = window.location.pathname.concat(
          Array.from(searchParamsReference).length > 0 ? '?' : '',
          searchParamsReference.toString(),
          window.location.hash
        );

        setSearchParams(new URLSearchParams(searchParamsReference.toString()));
        setParams(newParameters);
      } else {
        newPath = options?.keepQueryParams
          ? replacePathParams(path, newParameters).concat(
              window.location.search,
              window.location.hash
            )
          : replacePathParams(path, newParameters);
        setParams(newParameters);
      }

      if (options?.behaviour === 'push') {
        history.push(newPath);
        return;
      }

      window.history.replaceState(null, '', newPath);
    },
    [path, pathParams, searchParams, setParams, setSearchParams]
  );

  return { redirect };
};

export default useRouterPlusRedirect;
