import { useDispatch } from 'react-redux';
import { AlertContent, AlertVariant } from '@common/components/Alert/index';
import { closeAlert, showAlert } from '@/store/ui/actions';
import { useRef } from 'react';

type ShowFunction = (
  content: string | AlertContent,
  variant: AlertVariant,
  timeout?: number
) => void;

type ShowVariantFunction = (
  content: string | AlertContent,
  timeout?: number
) => void;

type CloseFunction = () => void;

interface UseAlertResponse {
  close: CloseFunction;
  success: ShowVariantFunction;
  info: ShowVariantFunction;
  error: ShowVariantFunction;
}

/** Show an alert message  */
const useAlert = (): UseAlertResponse => {
  const dispatch = useDispatch();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const show: ShowFunction = (content, variant, timeout) => {
    const payload = (): AlertContent => {
      if (typeof content === 'string') {
        return { title: content };
      }
      return { title: content.title, message: content.message };
    };

    dispatch(showAlert({ ...payload(), variant }));

    if (typeof timeout !== 'undefined') {
      /** If timeout is provided, We need to close the success modal after given time */
      timeoutRef.current = setTimeout(() => {
        dispatch(closeAlert());
      }, timeout);
    }
  };

  const success: ShowVariantFunction = (content, timeout) => {
    show(content, 'success', timeout);
  };

  const error: ShowVariantFunction = (content, timeout) => {
    show(content, 'error', timeout);
  };

  const close: CloseFunction = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    dispatch(closeAlert());
  };

  return { success, error, info: success, close };
};

export default useAlert;
