import React from 'react';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';

interface ModifySyncStatesProps {
  icon: string;
  title: string;
  color?: string;
}

const ModifySyncStates = ({
  icon,
  title,
  color = '#333333',
}: ModifySyncStatesProps) => {
  return (
    <Box className='d-flex flex-row'>
      <Box
        style={{ width: '20px', height: '18px' }}
        className='d-flex items-center justify-center'
      >
        <img src={icon} style={{ maxHeight: '18px' }} />
      </Box>
      <Typography
        fontSize={10}
        lineHeight={'18px'}
        fontWeight={600}
        style={{ color, textDecoration: 'underline' }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default ModifySyncStates;
