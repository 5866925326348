import React, { useEffect, useState } from 'react';
import './style.less';
import { Button, Card, Col, Input, Popover, Row, Space, Tooltip } from 'antd';
import Text from '../../../../elements/text/Text';
import { SearchOutlined } from '@/components/elements/icons/antd-icons';
import { ReactComponent as BackArrow } from '../../../../../assets/icons/backArrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { globalAssetsData } from '../../../../../store/ui/actions';
import IconUpload from './IconUpload';
import IconInput from './IconInput';
import { ReactComponent as DeleteLayer } from '../../../../../assets/layer/DeleteLayer.svg';
import OrderStatusGuard from '@/modules/project/guards/OrderStatus';
import { AccessRight, OrderStatus } from '@/modules/common/types';
import SRUserTypeAccessGuard from '@/modules/project/guards/SRUserTypeAccessGuard';
import classNames from 'classnames';

const IconType = (props) => {
  const dispatch = useDispatch();
  const globalAssetData = useSelector((state) => state.ui.globalAsset);
  const [selectedIcon, setSelectedIcon] = useState(props?.value);
  const [iconData, setIconData] = useState([]);
  const [isAddIcon, addIcon] = useState(false);
  const [iconFileList, setFileList] = useState([]);
  const [isError, setError] = useState(true);

  useEffect(() => {
    setIconData(props.data);
  }, [props.data]);

  useEffect(() => {
    setSelectedIcon(props.value);
  }, [props.value]);

  const updateGlobalList = (data) => {
    let globalAssets = {
      ...globalAssetData,
    };
    let reqData = {
      id: +data?.id,
      svg: data?.value,
      name: data?.name,
    };
    if (props?.type === 'symbol') {
      globalAssets.pointSymbol.push(reqData);
    }
    if (props?.type === 'arrow_head') {
      globalAssets.arrowHead.push(reqData);
    }
    dispatch(globalAssetsData(globalAssets));
  };
  const searchHandler = (e) => {
    const val = e.target.value;
    const len = val.length;
    if (val === '') {
      setIconData(props.data);
    } else {
      const newFeature = props.data.filter((el) => {
        return el.name.toLowerCase().search(val.toLowerCase()) !== -1;
      });
      setIconData(newFeature);
    }
  };
  const addIconHandler = () => {
    setIconData(props.data);
    addIcon(true);
  };
  const backIconHandler = () => {
    addIcon(false);
    // setName(null);
    setFileList([]);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  function uploadIcon(e) {
    let filtered = e.fileList.filter((file) => !!file.status);
    filtered = filtered.slice(-1);
    if (e.file) {
      setFileList(filtered);
    } else {
      setFileList(filtered);
    }
  }

  const onDeleteIcon = () => {
    setFileList([]);
  };
  return (
    <div className='icons-list'>
      <Button
        className='selected-icon'
        icon={
          <div
            className='inner'
            dangerouslySetInnerHTML={{ __html: selectedIcon }}
          />
        }
      />

      <Popover
        placement='bottomLeft'
        overlayClassName='arrow-list-popup'
        overlayInnerStyle={{
          scrollbarGutter: 'stable',
        }}
        trigger='click'
        content={
          <Card
            bodyStyle={{ padding: 0, paddingBottom: 0, paddingTop: 8 }}
            bordered={false}
          >
            {!isAddIcon ? (
              <div style={{ padding: 8, paddingTop: 0 }}>
                <Text type='p23'>Icon</Text>
                <Input
                  style={{ marginBottom: 4 }}
                  onChange={searchHandler}
                  prefix={
                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  }
                />
                <div style={{ paddingTop: 5, paddingBottom: 2 }}>
                  <Space size={2} align='baseline' wrap>
                    {iconData?.map((item, index) => (
                      <Tooltip
                        title={item.name}
                        placement={'bottomLeft'}
                        key={item.name}
                      >
                        <Button
                          size={'small'}
                          icon={
                            <div
                              className='icon-item-inner'
                              dangerouslySetInnerHTML={{ __html: item.svg }}
                            />
                          }
                          key={item.id}
                          ghost={true}
                          className={classNames('icon-item', { 'active': item.name === props.value })}
                          onClick={() => {
                            props.handleChange(item.svg, props.name);
                            setSelectedIcon(item.svg);
                          }}
                        />
                      </Tooltip>
                    ))}
                    {/* Intentionally disabling icon upload */}
                    {false && (
                    <SRUserTypeAccessGuard right={AccessRight.Edit}>
                      <OrderStatusGuard
                        status={[
                          OrderStatus.AwaitingApproval,
                          OrderStatus.Approved,
                          OrderStatus.SelfMeasured,
                          OrderStatus.BoltApproved,
                          OrderStatus.BoltPreview,
                          OrderStatus.BoltInVerification,
                          OrderStatus.ResolvingFeedback,
                        ]}
                      >
                        <Button
                          type='link'
                          onClick={addIconHandler}
                          size={'small'}
                          style={{
                            color: '#3779b6',
                            padding: 0,
                            fontSize: 12,
                          }}
                        >
                          + Add Icon
                        </Button>
                      </OrderStatusGuard>
                    </SRUserTypeAccessGuard>
                    )}
                  </Space>
                </div>
              </div>
            ) : (
              <>
                <Row className={'back-arrow-container'}>
                  <Col span={12} className={'px-1'}>
                    <BackArrow
                      onClick={backIconHandler}
                      style={{ position: 'fixed' }}
                      cursor='pointer'
                    />
                  </Col>
                  {iconFileList.length ? (
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <DeleteLayer cursor='pointer' onClick={onDeleteIcon} />
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>

                {iconFileList.length ? (
                  <IconInput
                    backIconHandler={backIconHandler}
                    type={props?.type}
                    iconFileList={iconFileList}
                    updateGlobalList={updateGlobalList}
                  />
                ) : (
                  <IconUpload
                    dummyRequest={dummyRequest}
                    uploadIcon={uploadIcon}
                    iconFileList={iconFileList}
                  />
                )}
              </>
            )}
          </Card>
        }
      >
        <Button
          type='link'
          style={{
            color: '#3779b6',
            position: 'absolute',
            top: '22px',
            left: '20px',
          }}
          className='ml-2'
        >
          <Text type='p56' color='link' underlined>
            Edit symbol
          </Text>
        </Button>
      </Popover>
    </div>
  );
};

export default IconType;
