import { hexToRgbA } from '@/helpers/mapUtils/layerUtils';
import { _get } from '@/helpers/utilities/lodashUtils';
import { MultiPoint, Point } from 'ol/geom';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { LINE_TYPE_FEATURE, POINT_TYPE_FEATURE, POLYGON_TYPE_FEATURE } from '../constants/mapConstants';
import { stringModify } from '../utilities';

export const updatePathStyleFunction = (featureArray, newStyle) => {
  const arrowIconStyle = stringModify(newStyle?.arrowType, {
    height: newStyle?.arrowSize,
    width: newStyle?.arrowSize,
    fill: newStyle?.arrowColor,
    'fill-opacity': newStyle?.opacity,
  });
  const arrowIcon = 'data:image/svg+xml;utf8,' + escape(arrowIconStyle);
  let styles = [
    // linestring
    new Style({
      stroke: new Stroke({
        color: newStyle?.color,
        width: newStyle?.stroke,
      }),
      fill: new Fill({
        color: hexToRgbA(_get(newStyle, 'fillColor'), _get(newStyle, 'opacity')),
      }),
    }),
  ];

  featureArray.forEach((feature) => {
    const geometry = feature?.getGeometry();
    const coordinates = geometry.getCoordinates();
    let featureStyle = [];
    coordinates.forEach(function(cord, index) {
      if (index !== coordinates.length - 1) {
        const dx = coordinates && coordinates[index + 1][0] - coordinates[index][0];
        const dy = coordinates && coordinates[index + 1][1] - coordinates[index][1];
        const rotation = Math.atan2(dy, dx);
        featureStyle.push(new Style({
          geometry: new Point(coordinates[index]),
          image: new Icon({
            src: arrowIcon,
            rotateWithView: true,
            rotation: -rotation + 1.5708,
            crossOrigin: 'Anonymous',
          }),
        }));
      } else {
        const dx = coordinates[index - 1] && coordinates[index][0] - coordinates[index - 1][0];
        const dy = coordinates[index - 1] && coordinates[index][1] - coordinates[index - 1][1];
        const rotation = Math.atan2(dy, dx);
        featureStyle.push(
          new Style({
            geometry: new Point(coordinates[index]),
            image: new Icon({
              src: arrowIcon,
              rotateWithView: true,
              rotation: -rotation + 1.5708,
              crossOrigin: 'Anonymous',
            }),
          }),
        );
      }
    });
    feature.setStyle([...styles, ...featureStyle]);
  });
  return styles;
};

export const drawPathStyleFunction = (featureArray, newStyle) => {
  let arrowIconStyle = stringModify(newStyle?.arrowType, {
    height: newStyle?.arrowSize,
    width: newStyle?.arrowSize,
    fill: newStyle?.arrowColor,
    'fill-opacity': newStyle?.opacity,
  });
  let pointStrokeColor = '#FFD02B';
  const styles = [
    // linestring
    new Style({
      stroke: new Stroke({
        color: newStyle?.color,
        width: newStyle?.stroke,
      }),
    }),
  ];

  featureArray.forEach((feature) => {
    const geometry = feature?.getGeometry();
    const coordinates = feature.getGeometry().getCoordinates();
    let count = 0;
    geometry?.forEachSegment(function(start, end) {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
      count++;
      styles.push(
        new Style({
          geometry: new Point(start),
          image: new Icon({
            src: 'data:image/svg+xml;utf8,' + escape(arrowIconStyle),
            rotateWithView: true,
            rotation: -rotation + 1.5708,
            crossOrigin: 'Anonymous',
          }),
        }),
      );
      styles.push(
        new Style({
          image: new CircleStyle({
            radius: 3,
            fill: new Fill({
              color: pointStrokeColor,
            }),
          }),
          geometry: new Point(start),
        }),
      );
      if (count === coordinates.length - 1) {
        styles.push(
          new Style({
            geometry: new Point(end),
            image: new Icon({
              src: 'data:image/svg+xml;utf8,' + escape(arrowIconStyle),
              // src: image.src,
              rotateWithView: true,
              rotation: -rotation + 1.5708,
              crossOrigin: 'Anonymous',
            }),
          }),
        );

        styles.push(
          new Style({
            image: new CircleStyle({
              radius: 3,
              fill: new Fill({
                color: pointStrokeColor,
              }),
            }),
            geometry: new Point(end),
          }),
        );
      }

    });
  });
  return styles;
};


export const DRAW_STYLE_MODE = (type, layerStyle, newStyle) => {
  let prevStrokeColor;
  let pointStrokeColor = '#FFD02B';
  let prevStrokeWidth;
  let prevFillColor;
  let circleRadius = 4;
  let arrowIconStyle;
  if (layerStyle && layerStyle[0]) {
    if (type === POINT_TYPE_FEATURE) {
      arrowIconStyle = stringModify(newStyle?.symbolType, {
        height: newStyle?.symbolSize,
        width: newStyle?.symbolSize,
        fill: newStyle?.symbolColor,
        'fill-opacity': newStyle?.opacity,
      });
    } else {
      prevStrokeColor = layerStyle[0]?.getStroke()?.getColor();
      prevStrokeWidth = layerStyle[0]?.getStroke()?.getWidth();
      prevFillColor = layerStyle[0]?.getFill()?.getColor();
    }
  }
  let coordinates = [];
  let styles = [
    new Style({
      stroke: new Stroke({
        color: prevStrokeColor,
        // lineDash: [10],
        width: prevStrokeWidth,
      }),
      fill: new Fill({
        // color: 'rgba(51, 51, 51, 0.5)',
        color: prevFillColor,
      }),
    }),
  ];
  if (type === POINT_TYPE_FEATURE) {
    styles.push(
      new Style({
        image: new Icon({
          src: 'data:image/svg+xml;utf8,' + escape(arrowIconStyle),
          rotateWithView: true,
          crossOrigin: 'Anonymous',
        }),
        geometry: function(feature) {
          const geometry = feature.getGeometry().getType();
          if (geometry === POLYGON_TYPE_FEATURE) {
            coordinates = feature.getGeometry().getCoordinates()[0];
          } else if (geometry === 'MultiPolygon') {
            let multiCoords = feature
              .getGeometry()
              .getCoordinates()
              .map((arr) => arr[0]);

            multiCoords.forEach((arr) => {
              arr.forEach((coord) => coordinates.push(coord));
            });
          } else if (geometry === LINE_TYPE_FEATURE) {
            coordinates = feature.getGeometry().getCoordinates();
          } else {
            coordinates = feature.getGeometry().getCoordinates();
            return new Point(coordinates);
          }
          return new MultiPoint(coordinates);
        },
      }),
    );
  } else {
    styles.push(
      new Style({
        image: new CircleStyle({
          radius: circleRadius,
          fill: new Fill({
            color: pointStrokeColor,
          }),
        }),
        geometry: function(feature) {
          const geometry = feature.getGeometry().getType();
          if (geometry === POLYGON_TYPE_FEATURE) {
            coordinates = feature.getGeometry().getCoordinates()[0];
          } else if (geometry === 'MultiPolygon') {
            let multiCoords = feature
              .getGeometry()
              .getCoordinates()
              .map((arr) => arr[0]);

            multiCoords.forEach((arr) => {
              arr.forEach((coord) => coordinates.push(coord));
            });
          } else if (geometry === LINE_TYPE_FEATURE) {
            coordinates = feature.getGeometry().getCoordinates();
          } else {
            coordinates = feature.getGeometry().getCoordinates();
            return new Point(coordinates);
          }
          return new MultiPoint(coordinates);
        },
      }),
    );
  }
  return styles;
};


export const EDIT_STYLE_MODE = (type, layerStyle, pathStyle) => {
  let prevStrokeColor;
  let pointStrokeColor = '#FFD02B';
  let prevStrokeWidth;
  let prevFillColor;
  let circleRadius = 4;
  if (layerStyle && layerStyle[0]) {
    if (type === POINT_TYPE_FEATURE) {
      pointStrokeColor = getPointStrokeColor(layerStyle[0].getImage());
    } else {
      prevStrokeColor = layerStyle[0]?.getStroke()?.getColor();
      prevStrokeWidth = layerStyle[0]?.getStroke()?.getWidth();
      prevFillColor = layerStyle[0]?.getFill()?.getColor();
    }
  }
  let coordinates = [];
  let styles = [
    new Style({
      stroke: new Stroke({
        color: prevStrokeColor,
        // lineDash: [10],
        width: prevStrokeWidth,
      }),
      fill: new Fill({
        // color: 'rgba(51, 51, 51, 0.5)',
        color: prevFillColor,
      }),
    }),
  ];
  styles.push(
    new Style({
      image: new CircleStyle({
        radius: circleRadius,
        fill: new Fill({
          color: pointStrokeColor,
        }),
      }),
      geometry: function(feature) {
        const geometry = feature.getGeometry().getType();
        if (geometry === POLYGON_TYPE_FEATURE) {
          coordinates = feature.getGeometry().getCoordinates()[0];
        } else if (geometry === 'MultiPolygon') {
          let multiCoords = feature
            .getGeometry()
            .getCoordinates()
            .map((arr) => arr[0]);

          multiCoords.forEach((arr) => {
            arr.forEach((coord) => coordinates.push(coord));
          });
        } else if (geometry === LINE_TYPE_FEATURE) {
          coordinates = feature.getGeometry().getCoordinates();
        } else {
          coordinates = feature.getGeometry().getCoordinates();
          return new Point(coordinates);
        }
        return new MultiPoint(coordinates);
      },
    }),
  );
  return styles;
};

export const CUT_STYLE_MODE = (type, layerStyle) => {
  let prevStrokeColor;
  let pointStrokeColor = '#FFD02B';
  let prevStrokeWidth;
  let prevFillColor;
  if (layerStyle && layerStyle[0]) {
    if (type === POINT_TYPE_FEATURE) {
      pointStrokeColor = getPointStrokeColor(layerStyle[0].getImage());
    } else {
      prevStrokeColor = layerStyle[0]?.getStroke()?.getColor();
      prevStrokeWidth = layerStyle[0]?.getStroke()?.getWidth();
      prevFillColor = layerStyle[0]?.getFill()?.getColor();
    }
  }
  let styles = [
    new Style({
      stroke: new Stroke({
        color: prevStrokeColor,
        lineDash: [10],
        width: prevStrokeWidth,
      }),
      fill: new Fill({
        // color: 'rgba(51, 51, 51, 0.5)',
        color: prevFillColor,
      }),
    }),
    new Style({
      image: new CircleStyle({
        radius: 4,
        fill: new Fill({
          color: pointStrokeColor,
        }),
      }),
      geometry: function(feature) {
        const geometry = feature.getGeometry().getType();

        let coordinates = [];

        if (geometry === 'Polygon') {
          coordinates = feature.getGeometry().getCoordinates()[0];
        } else if (geometry === 'MultiPolygon') {
          let multiCoords = feature
            .getGeometry()
            .getCoordinates()
            .map((arr) => arr[0]);

          multiCoords.forEach((arr) => {
            arr.forEach((coord) => coordinates.push(coord));
          });
        }

        return new MultiPoint(coordinates);
      },
    }),

  ];
  return styles;
};


export const DRAWING_MODE_STYLE = [
  new Style({
    stroke: new Stroke({
      color: '#FF0000',
      lineDash: [10],
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(51, 51, 51, 0.5)',
    }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 4,
      fill: new Fill({
        color: '#FFD02B',
      }),
    }),
    geometry: function(feature) {
      const geometry = feature.getGeometry().getType();

      let coordinates = [];

      if (geometry === 'Polygon') {
        coordinates = feature.getGeometry().getCoordinates()[0];
        DRAWING_MODE_STYLE[0].stroke_.width_ = 1;
      } else if (geometry === 'MultiPolygon') {
        let multiCoords = feature
          .getGeometry()
          .getCoordinates()
          .map((arr) => arr[0]);

        multiCoords.forEach((arr) => {
          arr.forEach((coord) => coordinates.push(coord));
        });
        DRAWING_MODE_STYLE[0].stroke_.width_ = 2;
      } else if (geometry === 'LineString') {
        coordinates = feature.getGeometry().getCoordinates();
        DRAWING_MODE_STYLE[0].stroke_.width_ = 3;
      } else {
        coordinates = feature.getGeometry().getCoordinates();
        return new Point(coordinates);
      }

      return new MultiPoint(coordinates);
    },
  }),
];

export const CUTTING_MODE_STYLE = [
  new Style({
    stroke: new Stroke({
      color: '#FF0000',
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(51, 51, 51, 0.5)',
    }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: '#FFD02B',
      }),
    }),
    geometry: function(feature) {
      const geometry = feature.getGeometry().getType();

      let coordinates = [];

      if (geometry === 'Polygon') {
        coordinates = feature.getGeometry().getCoordinates()[0];
      } else if (geometry === 'MultiPolygon') {
        let multiCoords = feature
          .getGeometry()
          .getCoordinates()
          .map((arr) => arr[0]);

        multiCoords.forEach((arr) => {
          arr.forEach((coord) => coordinates.push(coord));
        });
      }

      return new MultiPoint(coordinates);
    },
  }),
];
export const EDITING_MODE_STYLE = [
  new Style({
    stroke: new Stroke({
      color: '#F3A40E',
      lineDash: [10],
      width: 2,
    }),
    // fill: new Fill({
    //   color: '#FFD02B',
    // }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 4,
      fill: new Fill({
        color: '#FFD02B',
      }),
    }),
    geometry: function(feature) {
      const geometry = feature.getGeometry().getType();

      let coordinates = [];

      if (geometry === 'Polygon') {
        coordinates = feature.getGeometry().getCoordinates()[0];
        EDITING_MODE_STYLE[0].stroke_.width_ = 2;
      } else if (geometry === 'MultiPolygon') {
        let multiCoords = feature
          .getGeometry()
          .getCoordinates()
          .map((arr) => arr[0]);

        multiCoords.forEach((arr) => {
          arr.forEach((coord) => coordinates.push(coord));
        });
        EDITING_MODE_STYLE[0].stroke_.width_ = 2;
      } else if (geometry === 'LineString') {
        coordinates = feature.getGeometry().getCoordinates();
        EDITING_MODE_STYLE[0].stroke_.width_ = 3;

      }

      return new MultiPoint(coordinates);
    },
  }),
];

export const RESTING_MODE_STYLE = [
  new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
      color: '#FF0000',
      width: 2,
    }),
  }),
];

export const PARCEL_BASE_STYLE = { color: '#ffd700', fillColor: '#ffd700', stroke: 2, opacity: 0 };

export const COMPLETE_MODE_STYLE = [
  new Style({
    stroke: new Stroke({
      color: '#ffd700',
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(255, 215, 0, 0)',
    }),
  }),
];

// export const MEASURE_STYLE = [
//   new Style({
//     fill: new Fill({
//       color: 'rgba(255, 255, 255, 0.2)',
//     }),
//     stroke: new Stroke({
//       color: '#FFFFFF',
//       width: 2,
//     }),
//   }),
// ];

export const MEASURE_STYLE = [
  new Style({
    stroke: new Stroke({
      color: '#FFD02B',
      lineDash: [10],
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(51, 51, 51, 0.5)',
    }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 4,
      fill: new Fill({
        color: '#ffffff',
      }),
    }),
    geometry: function(feature) {
      const geometry = feature.getGeometry().getType();

      let coordinates = [];

      if (geometry === 'Polygon') {
        coordinates = feature.getGeometry().getCoordinates()[0];
      } else if (geometry === 'MultiPolygon') {
        let multiCoords = feature
          .getGeometry()
          .getCoordinates()
          .map((arr) => arr[0]);

        multiCoords.forEach((arr) => {
          arr.forEach((coord) => coordinates.push(coord));
        });
      }

      return new MultiPoint(coordinates);
    },
  }),
];

const STYLE_INTERNAL_1 = new Style({
  stroke: new Stroke({
    color: '#FFD02B',
    lineDash: [10],
    width: 2,
  }),
});

const STYLE_INTERNAL_2 = new Style({
  image: new CircleStyle({
    radius: 5,
    // stroke: new Stroke({ color: 'rgba(255, 0, 0, 1)' }),
    fill: new Fill({ color: '#ffffff' }),
  }),
});

const STYLE_INTERNAL_3 = new Style({
  stroke: new Stroke({
    color: 'rgba(255, 102, 0, 0)',
    width: 3,
  }),
  fill: new Fill({
    color: 'rgba(51, 51, 51, 0.5)',
  }),
});

const STYLE_INTERNAL_4 = new Style({
  fill: new Fill({
    color: 'rgba(255,255,255,0.5)',
  }),
  stroke: new Stroke({
    color: '#3399CC',
    width: 2,
  }),
});

const STYLE_INTERNAL_5 = new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({ color: 'rgba(255,255,255,0.5)' }),
    fill: new Fill({ color: '#3399CC' }),
  }),
});

const STYLE_INTERNAL_6 = new Style({
  stroke: new Stroke({
    color: '#FFD02B',
    width: 2,
  }),
  image: new CircleStyle({
    radius: 3,
    fill: new Fill({ color: '#FFD02B' }),
  }),
});

const STYLE_INTERNAL_7 = new Style({
  stroke: new Stroke({
    color: '#FFD02B',
    lineDash: [10],
    width: 1,
  }),
  image: new CircleStyle({
    radius: 3,
    fill: new Fill({ color: '#FFD02B' }),
  }),
});

export const styleFunction = feature => {
  const geometry = feature.getGeometry();
  if (geometry.getType() === 'LineString') {
    return [STYLE_INTERNAL_1];
  }
  if (geometry.getType() === 'Point') {
    return [STYLE_INTERNAL_2];
  }
  if (geometry.getType() === 'Polygon') {
    return [STYLE_INTERNAL_3];
  }
  return false;
};


export const selectedFeatureStyle = () => {
  return STYLE_INTERNAL_4;
};

export const pointSelectedTempStyle = (style) =>{
  /** THis is only for fixing an urgent prod issue, need to remove it and handle selection properly.**/
  return STYLE_INTERNAL_5;
}
export const pointSelectedFeatureStyle =(style)=> {
  let arrowIconStyle = stringModify(style?.symbolType, {
    height: style?.symbolSize,
    width: style?.symbolSize,
    fill: '#3399CC',
    'fill-opacity': 1,
  });
  return new Style({
    image: new Icon({
      src: 'data:image/svg+xml;utf8,' + escape(arrowIconStyle),
      rotateWithView: true,
      crossOrigin: 'Anonymous',
    }),
    geometry: function(feature) {
      const coordinates = feature.getGeometry().getCoordinates();
      return new Point(coordinates);
    },
  });
}

export const hoveredFeatureStyle = new Style({
  fill: new Fill({
    color: 'rgba(255,255,255,0.7)',
  }),
  stroke: new Stroke({
    color: '#3399CC',
    width: 3,
  }),
});

export const pointHoveredFeatureStyle = function(style) {
  let arrowIconStyle = stringModify(style?.symbolType, {
    height: style?.symbolSize,
    width: style?.symbolSize,
    fill: '#3399CC',
    'fill-opacity': 1,
  });
  return new Style({
    image: new Icon({
      src: 'data:image/svg+xml;utf8,' + escape(arrowIconStyle),
      rotateWithView: true,
      crossOrigin: 'Anonymous',
    }),
    fill: new Fill({
      color: 'rgba(255,255,255,0.7)',
    }),
    geometry: function(feature) {
      const coordinates = feature.getGeometry().getCoordinates();
      return new Point(coordinates);
    },
  });
};

export const drawInteractionStyle = () => {
  return [STYLE_INTERNAL_6];
};

export const getPointStrokeColor = (circleStyle) => {
  let strColor = '#FFD02B';
  if (circleStyle) {
    strColor = circleStyle?.getStroke().getColor();
  }
  return strColor;
};

export const pathCustomStyle = (color = '#BA0F94', width) => {
  return <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g filter='url(#filter0_d_426_12460)'>
      <circle cx='11' cy='11' r='6.5' stroke={color} />
    </g>
    <path d='M9 3L11 4.5L9.5 7' stroke={color} strokeLinecap='round' />
    <path d='M19.3232 13.645L16.8755 14.1535L15.9919 11.3752' stroke={color} strokeLinecap='round' />
    <path d='M3.31079 14.8711L4.66388 12.7689L7.26478 14.0862' stroke={color} strokeLinecap='round' />
    <defs>
      <filter id='filter0_d_426_12460' x='0' y='0' width='22' height='22' filterUnits='userSpaceOnUse'
              colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                       result='hardAlpha' />
        <feOffset />
        <feGaussianBlur stdDeviation='2' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_426_12460' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_426_12460' result='shape' />
      </filter>
    </defs>
  </svg>;
};

export const layerIndicatorStyle = (type, style) => {

  switch (type) {
    case 'point':
      return <span className='dot' style={{
        backgroundColor: 'white',
        border: `2px solid ${style?.symbolColor}`,
      }}><span className='inner-dot' style={{ backgroundColor: style.symbolColor }} />
      </span>;
    case 'line':
      return <span className='dot' style={{ backgroundColor: 'white', border: `2px solid ${style?.color}` }} />;
    case 'polygon':
      return <span className='dot' style={{
        backgroundColor: style?.fillColor,
        border: `2px solid ${style?.color}`,
      }} />;
    case 'path':
      return <span className='path'>
                      {pathCustomStyle(style.color, style.stroke)}
                    </span>;
    default:
      break;

  }
};


export const pointHoverIconFeature = (newStyle) => {
  let arrowIconStyle = stringModify(newStyle?.symbolType, {
    height: newStyle?.symbolSize,
    width: newStyle?.symbolSize,
    fill: '#3399CC',
  });
  let coordinates = [];
  return new Style({
    image: new Icon({
      src: 'data:image/svg+xml;utf8,' + escape(arrowIconStyle),
      rotateWithView: true,
      crossOrigin: 'Anonymous',
    }),
    geometry: function (feature) {
      coordinates = feature.getGeometry().getCoordinates();
      return new Point(coordinates);
    },
  });
};


// Cut, Line Split, Lasso Split styles

export const CUT_SPLIT_STYLE_MODE = [
  new Style({
    stroke: new Stroke({
      color: '#FF0000',
      lineDash: [10],
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(51, 51, 51, 0.5)',
    }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 4,
      fill: new Fill({
        color: '#FFD02B',
      }),
    }),
    geometry: function(feature) {
      const geometry = feature.getGeometry().getType();

      let coordinates = [];

      if (geometry === 'Polygon') {
        coordinates = feature.getGeometry().getCoordinates()[0];
        DRAWING_MODE_STYLE[0].stroke_.width_ = 1;
      } else if (geometry === 'MultiPolygon') {
        let multiCoords = feature
          .getGeometry()
          .getCoordinates()
          .map((arr) => arr[0]);

        multiCoords.forEach((arr) => {
          arr.forEach((coord) => coordinates.push(coord));
        });
        DRAWING_MODE_STYLE[0].stroke_.width_ = 2;
      } else if (geometry === 'LineString') {
        coordinates = feature.getGeometry().getCoordinates();
        DRAWING_MODE_STYLE[0].stroke_.width_ = 3;
      } else {
        coordinates = feature.getGeometry().getCoordinates();
        return new Point(coordinates);
      }

      return new MultiPoint(coordinates);
    },
  }),
];

/// all cut tool pointer style
export const cutInteractionStyle = () => {
  return [STYLE_INTERNAL_7];
};

export const STYLE_INTERNAL_8 = new Style({
  fill: new Fill({
    color: 'rgba(255,255,255,0.7)',
  }),
  stroke: new Stroke({
    color: '#3399CC',
    width: 3,
  }),
  image: new CircleStyle({
    radius: 4,
    fill: new Fill({ color: 'rgba(255,255,255,0.7)' }),
    stroke: new Stroke({
      color: '#3399CC',
      width: 3,
    }),
  }),
});

export const STYLE_INTERNAL_9 = new Style({
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({ color: '#FFD02B' }),
  }),
});

export const STYLE_INTERNAL_10 = new Style({
  fill: new Fill({ color: 'rgba(255, 208, 43, 0.75)' }),
});

export const STYLE_INTERNAL_11 = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)',
  }),
  stroke: new Stroke({
    color: '#FF0000',
    width: 2,
  }),
});
