// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type AwaitingIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function AwaitingIcon(props: AwaitingIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 17 16"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M15.283.97a.87.87 0 00-.867.866v1.225l-.68-.68A7.95 7.95 0 008.056 0a8.04 8.04 0 00-5.694 2.363 8.087 8.087 0 00-2.347 6.19c.034.459.408.816.85.816h.051a.858.858 0 00.8-.918 6.374 6.374 0 011.853-4.863 6.322 6.322 0 018.96 0l.68.68h-1.223a.842.842 0 00-.85.85.87.87 0 00.867.867H15.3a.87.87 0 00.867-.867V1.82a.882.882 0 00-.884-.85z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M10.896 7.975l-2.023-.562V4.438a.87.87 0 00-.867-.867.87.87 0 00-.868.867V8.06c0 .39.256.73.63.833l2.652.748c.085.017.153.034.238.034a.888.888 0 00.833-.63.852.852 0 00-.595-1.07zm-4.863 6.172a6.046 6.046 0 01-1.173-.527.76.76 0 00-1.054.289.76.76 0 00.289 1.054c.459.272.952.493 1.462.646.085.034.17.034.238.034.323 0 .63-.204.731-.527a.783.783 0 00-.493-.97zM2.31 10.933c-.188-.39-.664-.527-1.038-.34s-.544.663-.34 1.037c.238.476.527.935.85 1.343.153.188.375.29.613.29.17 0 .34-.051.476-.17a.782.782 0 00.136-1.089 5.797 5.797 0 01-.697-1.07zm10.559 1.377c-.085.102-.188.204-.272.29a7.42 7.42 0 01-.68.595.782.782 0 00-.154 1.088.772.772 0 00.613.306c.17 0 .323-.051.459-.153.289-.221.56-.46.833-.731.119-.12.238-.238.34-.357a.76.76 0 00-.068-1.089.727.727 0 00-1.071.051zm-3.146 1.939c-.408.119-.85.187-1.275.204a.789.789 0 00-.731.816.778.778 0 00.765.731h.05a8.296 8.296 0 001.582-.255c.408-.12.663-.544.544-.952a.776.776 0 00-.935-.544z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default AwaitingIcon;
/* prettier-ignore-end */
