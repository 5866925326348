import { trackEvents } from '@/helpers/utilities';
import moment from 'moment';
import {
  authenticate,
  logout as removeTokens,
  userAuthenticate,
} from '@/helpers/auth';
import { useDispatch, useSelector } from 'react-redux';
import { resetDashboardState } from '@/store/dashboard/action';
import { resetAction } from '@/store/teamManagement/actions';
import { clearUserInfo } from '@/store/user/actions';
import { setDefault } from '@/store/configurations/actions';
import { useHistory } from 'react-router-dom';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { IStore } from '@/store/types';
import { User } from '@/store/user/state';

export interface UseLogoutResponse {
  logout: () => Promise<void>;
}

const useLogout = (): UseLogoutResponse => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector<IStore, User>((state) => state.user.info as User);

  const logout = async () => {
    userAuthenticate(false);
    authenticate({ token: null });
    sessionStorage.removeItem('$filter');
    sessionStorage.removeItem('$tabStatus');
    sessionStorage.removeItem('$currentPage');

    try {
      dispatch(resetDashboardState());
      dispatch(resetAction());
      removeTokens();
      dispatch(clearUserInfo());
      dispatch(setDefault());

      if (user && user?.id) {
        await getAxiosInstance().get(`/api/user/logout/${user.id}`);
        trackEvents('sign_out', {
          time: moment(user?.last_login).fromNow(),
        });
      }

      history.push('/');
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { logout };
};

export default useLogout;
