// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Group33IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Group33Icon(props: Group33IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 13 13"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={"M5.715 5.433L3.358 3.1m.589 8.4H1m11.199-7.292V1"}
        stroke={"currentColor"}
        strokeWidth={"1.289"}
        strokeLinecap={"round"}
      ></path>
    </svg>
  );
}

export default Group33Icon;
/* prettier-ignore-end */
