import { Button } from 'antd';
import React, { useState } from 'react';
import NewQueueImage from '../../assets/add-queue.png';
import RequestSentImage from '../../assets/request-sent.png';
import { Strings } from '../../utils/strings';
import { reqNewQueue } from '../../api';
import './style.less';
import RoleGuard from '@/modules/auth/guards/RoleGuard';
import { UserRole } from '@/modules/common/types';
import { trackEvents } from '../../../../../helpers/utilities';

type InitialAddQueueProps = {
  setIsRequestSent: (value: boolean) => void;
  isRequestSent: boolean;
};

type AddQueueProps = {
  isQueueRequested: boolean;
};

const InitialAddQueue = ({ setIsRequestSent }: InitialAddQueueProps) => {
  const [disableButton, setDisableButton] = useState(false);

  const onClick = async () => {
    setDisableButton(true);
    reqNewQueue().then((result: any) => {
      if (result.status === 202) {
        trackEvents('order-queue__add_new_queue')
        setIsRequestSent(true);
      }
    });
  };
  return (
    <>
      <img src={NewQueueImage} className='new-queue-image' alt='new queue ' />
      <p>{Strings.NeedQueues}</p>
      <Button
        type='text'
        className='add-queue-button'
        onClick={onClick}
        disabled={disableButton}
      >
        {Strings.AddQueButton}
      </Button>
    </>
  );
};
const RequestSentQueue = () => {
  return (
    <>
      <img
        src={RequestSentImage}
        className='request-sent-image'
        alt='request-sent '
      />
      <h3 className='request-sent-head'>{Strings.RequestSent}</h3>
      <p className='request-sent-para'>{Strings.RequestSentText}</p>
    </>
  );
};

const AddNewQue = ({ isQueueRequested }: AddQueueProps) => {
  const [isRequestSent, setIsRequestSent] = useState(false);

  return (
    <RoleGuard except={[UserRole.Viewer, UserRole.Guest]}>
      <div className='add-new-queue-container'>
        {isRequestSent || isQueueRequested ? (
          <RequestSentQueue />
        ) : (
          <InitialAddQueue
            isRequestSent={isRequestSent}
            setIsRequestSent={setIsRequestSent}
          />
        )}
      </div>
    </RoleGuard>
  );
};

export default AddNewQue;
