import { _debounce } from '@/helpers/utilities/lodashUtils';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { radiansToDegrees, trackEvents } from '../helpers/utilities';
import { rotateMapValue } from '../store/order/actions';

function Keyboard({ mapRef, shortCutRef }) {
  const currentView = useSelector((state) => state.order.currentViewData);
  const dispatch = useDispatch();
  const sendTrackEvent = (payload) => {
    trackEvents('rotate-map', {
      ...payload,
    });
    dispatch(rotateMapValue(payload));
  };
  const delayedQuery = useCallback(_debounce(sendTrackEvent, 3000), []);
  const rotateMap = (mapRef) => {
    let iconElement = document.getElementsByClassName('rotate-compass');

    mapRef.getView().on('change:rotation', (event) => {
      const rotation = event.target?.targetRotation_ ?? event.oldValue;

      if (event) {
        let reqData = {
          orderId: currentView?.orderId,
          viewId: currentView?.viewId,
          isBaseView: currentView?.isBaseView,
          isEditable: currentView?.isEditable,
          rotateDeg: radiansToDegrees(rotation),
          isRotate: true,
        };
        delayedQuery(reqData);
        Array.from(iconElement).forEach((element) => {
          element.style.transform = `rotate(${radiansToDegrees(rotation)}deg)`;
        });
      }
    });
  };

  // Map Rotation
  if (mapRef) {
    rotateMap(mapRef);
  }

  // map Zoom In with keyboard
  const mapZoomIn = () => {
    let zoomLevel = mapRef.getView().getZoom();
    mapRef.getView().setZoom(parseInt(zoomLevel) + 1);
  };

  // map Zoom Out with keyboard
  const mapZoomOut = () => {
    let zoomLevel = mapRef.getView().getZoom();
    mapRef.getView().setZoom(parseInt(zoomLevel) - 1);
  };
  // const mapNavigation = (direction) => {
  //     let newCenterInPx;
  //     let center = mapRef.getView().getCenter();
  //     let centerInPx = mapRef.getPixelFromCoordinate(center);
  //
  //     switch (direction) {
  //         case 'left':
  //             newCenterInPx = [centerInPx[0] - 100, centerInPx[1]];
  //             break;
  //         case 'right':
  //             newCenterInPx = [centerInPx[0] + 100, centerInPx[1]];
  //             break;
  //         case 'top':
  //             newCenterInPx = [centerInPx[0], centerInPx[1] - 100];
  //             break;
  //         case 'bottom':
  //             newCenterInPx = [centerInPx[0], centerInPx[1] + 100];
  //             break;
  //     }
  //     let newCenter = mapRef.getCoordinateFromPixel(newCenterInPx);
  //     mapRef.getView().setCenter(newCenter);
  //
  // }

  // mapping the function
  shortCutRef.zoomIn = mapZoomIn;
  shortCutRef.zoomOut = mapZoomOut;
  // shortCutRef.navigationMap = mapNavigation;
  return <div></div>;
}

export default Keyboard;
