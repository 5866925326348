export const CONSTANTS = {
  BASE_BULK_PATH: '/api/v1/border',
  BASE_PATH: '/api/v1/order',
  BASE_ROUTE: '/api/v1/bulk-orders/summary',
  BASE_ROUTE_FILTER: '/api/emporio/v1/order',
  BASE_ROUTE_SUMMERY: '/api/v1/bulk-orders/summary',
  BULK_ORDER_FILTER: '/api/emporio/v1/bulkorder',
  BULK_ROUTE: '/api/emporio/v1/bulkorder',
  ORDER_ROUTE: '/api/emporio/v1/order',
  ORDER_ROUTE_V2: '/api/emporio/v2/order',
  SHRED_ROUTE: '/api/emporio/v1/order',
  BULK_ORDER_ROUTE: '/api/v1/bulk-orders',
};
