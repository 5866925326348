// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type SubtractStrokeIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function SubtractStrokeIcon(props: SubtractStrokeIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 31 42"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M0 2.997C0 1.555.995 0 2.66 0h1.402c.563 0 1.02.447 1.02.999s-.457.999-1.02.999H2.66c-.09 0-.226.042-.366.212a1.246 1.246 0 00-.256.787v1.529c0 .551-.456.998-1.02.998A1.01 1.01 0 010 4.526v-1.53zM8.297.999c0-.552.456-.999 1.02-.999h2.802c.563 0 1.02.447 1.02.999s-.457.999-1.02.999H9.316a1.01 1.01 0 01-1.02-1zm8.056 0c0-.552.457-.999 1.02-.999h1.401a2.67 2.67 0 012.066.97l.543.66a.985.985 0 01-.156 1.404 1.034 1.034 0 01-1.433-.152l-.543-.66a.617.617 0 00-.477-.224h-1.4a1.01 1.01 0 01-1.02-1zM1.02 9.259c.563 0 1.02.448 1.02 1v3.057c0 .552-.457.999-1.02.999A1.01 1.01 0 010 13.316v-3.058c0-.551.457-.998 1.02-.998zm0 8.791c.563 0 1.02.447 1.02 1v3.057c0 .551-.457.999-1.02.999a1.01 1.01 0 01-1.02-1V19.05c0-.552.457-.999 1.02-.999zm0 8.79c.563 0 1.02.448 1.02 1v3.057c0 .552-.457 1-1.02 1a1.01 1.01 0 01-1.02-1V27.84c0-.552.457-1 1.02-1zm0 8.791c.563 0 1.02.447 1.02 1v1.528c0 .34.113.614.255.787.14.17.275.212.366.212h1.464c.564 0 1.02.447 1.02.999s-.456.999-1.02.999H2.661C.995 41.156 0 39.6 0 38.159V36.63c0-.552.457-.999 1.02-.999zm28.551.938c.563 0 1.02.448 1.02 1v.59c0 1.441-.995 2.997-2.66 2.997h-1.466a1.01 1.01 0 01-1.02-1c0-.55.457-.998 1.02-.998h1.465c.09 0 .226-.042.366-.212.142-.173.256-.448.256-.787v-.59c0-.552.456-1 1.02-1zM8.6 40.157c0-.552.457-1 1.02-1h2.93c.563 0 1.02.448 1.02 1s-.457.999-1.02.999H9.62a1.01 1.01 0 01-1.02-1zm8.423 0c0-.552.457-1 1.02-1h2.93c.563 0 1.02.448 1.02 1s-.457.999-1.02.999h-2.93a1.01 1.01 0 01-1.02-1z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default SubtractStrokeIcon;
/* prettier-ignore-end */
