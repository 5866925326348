import { Button, Modal, Row, Select, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Text from './../../../../../../../elements/text/Text';
import './style.less';
import { useDispatch, useSelector } from 'react-redux';
import PropertyApi from '../../../../../api/properties';
import {
  addAllTags,
  emptyTag,
  updateOrderTags,
} from '../../../../../../../../store/order/actions';
import { convertTagArrayToObject } from '../../../../../utils/enum';
import { updateTagOrderList } from '../../../../../../../../store/bulkOrder/actions';
import { updateDashboardTags } from '../../../../../../../../store/dashboard/action';
import { trackEvents } from '../../../../../../../../helpers/utilities';
import useActiveTab from '@/components/pages/myProperties/hooks/useActiveTab';
import { showNotification } from '../../../../../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../../../../storybook/NotificationToast/types';

const { Option } = Select;

const AddTag = ({ visible = false, setVisible, isRouteAvail = true }) => {
  const activeTab = useActiveTab();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    allAvailableTags,
    orderIdForTag,
    tags,
    suggestedTags,
    bulkOrderTagModal,
  } = useSelector((state) => state.order);

  // const state = useSelector((state) => state);

  const { selectedOrderList } = useSelector((state) => state.bulkOrder);
  const { orderData } = useSelector((state) => state.order);

  const [searchValue, setSearchValue] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const dismissModal = useCallback(() => {
    dispatch(emptyTag());
    if (!isRouteAvail) {
      setVisible(false);
      return;
    }

    history.goBack();
  }, [dispatch, history, isRouteAvail, setVisible]);

  const handleSearch = useCallback((e) => {
    setSearchValue((prevState) => {
      if (e) return e;
      else if (prevState.length === 1) return e;
      return prevState;
    });
    // if(e)
    // setSearchValue(e);
    // else if(e.t)
  }, []);

  const handleSelect = useCallback((e) => {
    setSearchValue('');
  }, []);

  const handleTag = useCallback((e) => {
    setSelectedValues(e);
  }, []);

  const handelSuggestedTagClick = useCallback((tagValue) => {
    setSelectedValues((prevState) => {
      if (!prevState.includes(tagValue)) return [...prevState, tagValue];

      // if already added to list then pass origin list
      return prevState;
    });
  }, []);

  const handleSuccessPromise = useCallback(() => {
    showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Tag updated successfully');
    if (!isRouteAvail) {
      setVisible(false);
    } else {
      history.goBack();
    }

    if (selectedOrderList?.data) {
      return dispatch(
        updateTagOrderList({
          orderID: selectedOrderList?.data?.id,
          tagArray: convertTagArrayToObject(selectedValues),
        })
      );
    } else if (orderData) {
      dispatch(updateOrderTags(convertTagArrayToObject(selectedValues)));
    }
    dispatch(
      updateDashboardTags({
        tab: activeTab,
        data: {
          orderId: orderIdForTag,
          tags: convertTagArrayToObject(selectedValues),
        },
      })
    );
  }, [
    activeTab,
    dispatch,
    history,
    isRouteAvail,
    orderData,
    orderIdForTag,
    selectedOrderList?.data,
    selectedValues,
    setVisible,
  ]);

  const handleBulkAddTagBeforeOrderPlace = useCallback(() => {
    PropertyApi.addTagApiBeforeOrderPlaced(selectedValues, orderIdForTag)
      .then(() => {
        setBtnLoading(false);
        handleSuccessPromise();
      })
      .catch((err) => {
        console.error(err);
        setBtnLoading(false);
        new Error(err);
      });
  }, [handleSuccessPromise, orderIdForTag, selectedValues]);

  const submitTag = useCallback(() => {
    if (!orderIdForTag) {
      if (!isRouteAvail) {
        setVisible(false);

        return;
      }

      history.goBack();
    }

    setBtnLoading(true);

    if (bulkOrderTagModal) {
      return handleBulkAddTagBeforeOrderPlace();
    }

    PropertyApi.addTagApi(selectedValues, orderIdForTag)
      .then(() => {
        setBtnLoading(false);
        let tempTag = [...selectedValues, ...allAvailableTags];
        dispatch(addAllTags(tempTag));
        trackEvents('tag_updated', {
          orderID: orderIdForTag,
          updated_tag_list: selectedValues,
        });
        handleSuccessPromise();
      })
      .catch((err) => {
        console.error(err);
        setBtnLoading(false);
        new Error(err);
      });
  }, [
    orderIdForTag,
    bulkOrderTagModal,
    selectedValues,
    isRouteAvail,
    history,
    setVisible,
    handleBulkAddTagBeforeOrderPlace,
    handleSuccessPromise,
  ]);

  const handleClick = useCallback(
    (e) => {
      if (e.target.className === 'ant-select-item-option-content') return;
      if (!searchValue) return;

      setSelectedValues((prevState) => {
        if (!prevState.includes(searchValue)) {
          const demoSearch = [...prevState, searchValue];
          setSearchValue('');
          return demoSearch;
        }

        setSearchValue('');
        return prevState;
      });
    },
    [searchValue]
  );

  useEffect(() => {
    if (isRouteAvail) return;

    setSelectedValues(tags);
  }, [isRouteAvail, tags]);

  useEffect(() => {
    setSelectedValues([...tags]);
  }, [tags, visible]);

  return (
    <Modal
      visible={true}
      onCancel={dismissModal}
      closable={dismissModal}
      width={450}
      className='addTagModal'
      footer={null}
    >
      <div className='addTagModal' style={{ width: '450px' }}>
        <Row style={{ flexDirection: 'column' }} className='mb-3'>
          <Text type='h41' color='dark-gray'>
            Select Tag
          </Text>

          <Select
            className='addTagModal__select mt-5'
            mode='multiple'
            style={{ width: '90%' }}
            placeholder='Add New Tag'
            onSearch={handleSearch}
            searchValue={searchValue}
            onChange={handleTag}
            value={selectedValues}
            onSelect={handleSelect}
            open={searchValue !== ''}
            defaultActiveFirstOption={false}
            showSearch={true}
            onClick={handleClick}
            dropdownClassName='tag-dropdowns'
            // onDeselect={handleDD}
          >
            {searchValue && !allAvailableTags.includes(searchValue) && (
              <Option value={searchValue}>
                <Text type={'p58'} color='light-gray'>
                  {' '}
                  Add New Tag
                </Text>{' '}
                "{searchValue}"
              </Option>
            )}
            {allAvailableTags.map((value, i) => (
              <Option key={i} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </Row>

        <Row style={{ flexDirection: 'column' }}>
          <Text type='p29' color='dark-gray' className='mb-2'>
            Suggested Tags:
          </Text>

          <Row className='mb-3'>
            {suggestedTags.map((value, i) => (
              <Tag
                key={i}
                className='tags mb-2'
                style={{ cursor: 'pointer' }}
                onClick={() => handelSuggestedTagClick(value)}
              >
                {value}
              </Tag>
            ))}
          </Row>
        </Row>
        <Row align='middle' justify='center' className='addButton'>
          <Button
            className={!(tags.length || selectedValues.length) && 'disabled'}
            type='primary'
            onClick={submitTag}
            loading={btnLoading}
            disabled={!(tags.length || selectedValues.length)}
          >
            Done
          </Button>
        </Row>
      </div>
    </Modal>
  );
};

export default AddTag;
