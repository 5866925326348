import React, { useEffect, useState } from 'react';
// @ts-ignore
import classNames from 'classnames';

import './style.less';
import '@/styles/base/utils.less';
import { Input } from 'antd';

type SmallSearchBarProps = {
  containerStyle?: React.CSSProperties;
  type?: string;
  prefix: any;
  className?: string;
  placeholder?: string;
};

const SmallSearchBar = ({
  containerStyle,
  type,
  prefix,
  placeholder,
  ...otherProps
}: SmallSearchBarProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!isFocused) {
      setSearchTerm('');
    }
  }, [isFocused]);

  const onChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <div
        className={classNames(
          'form-input-group p-relative',
          otherProps.className
        )}
        style={{ ...containerStyle }}
      >
        <label className='small-autocomplete-label'>{placeholder}</label>
        <Input
          id='inputID'
          {...otherProps}
          type='text'
          autoComplete='false'
          prefix={prefix}
          value={searchTerm}
          onChange={onChange}
          // placeholder={placeholder}
          className={`form-input ${isFocused ? 'blue' : ''} 
          `}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
      </div>
    </>
  );
};

SmallSearchBar.defaultProps = {
  containerStyle: {},
};

export default React.memo(SmallSearchBar);
