import { isObjEmpty } from '../utils';

export const getTypeValue = (o) => {
  const t = o.datatype;
  if (t === 'decimal') {
    return { d: +o[t]?.default, v: +o[t]?.decimalPlaces };
  } else if (t === 'multiselect' || t === 'singleselect') {
    return { d: o[t]?.default, v: o[t]?.values };
  } else if (t === 'text') {
    return { d: o[t]?.default, v: o[t]?.maxCharAllowed };
  }
};

export const ellipsis = (str, n) => {
  return str && str.length > n ? str.substr(0, n - 1) + '...' : str;
};

export const getServiceItemAttributeObj = (allAttributes) => {
  const attributesList = ['Service Item', 'Takeoff Measurement Type'];
  const attribute = allAttributes?.find(
    (_attribute) =>
      attributesList.includes(_attribute.name) ||
      attributesList.includes(_attribute?.localName)
  );

  return attribute;
};

export const getAttributesLabel = (
  attributeObj = {},
  isPlural,
  isLowerCase
) => {
  if (isObjEmpty(attributeObj)) {
    return;
  }

  let attributeLabel = attributeObj?.localName
    ? attributeObj.localName
    : attributeObj.name;
  if (isPlural) {
    attributeLabel = getPluralLabel(attributeLabel);
  }
  if (isLowerCase) {
    attributeLabel = attributeLabel.toLowerCase();
  }
  return attributeLabel;
};

export const getPluralLabel = (str) => {
  if (str.charAt(str.length - 1) === 'y') {
    return str.substring(0, str.length - 1).concat('ies');
  } else {
    return str.substring(0, str.length).concat('s');
  }
};
