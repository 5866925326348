import {
  setAllAttributes,
  setAllServiceItems,
  setAllwithDeletedAttributes,
} from './actions';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { ATTRIBUTE } from '@/components/api/feature_endpoints';
import { getServiceItems, getServicesList } from '../../modules/property/api';

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const callThunk = (
  method,
  url,
  params = null,
  data = null,
  headers = null,
  id = null
) => {
  if (!headers)
    headers = {
      Authorization: 'Token ' + getToken(),
    };

  if (params) {
    url += '?';
    params.forEach((par) => {
      url += `${par.key}=${par.value}&`;
    });
  }

  const options = {
    method,
    url,
    headers,
    data,
  };
  return requestData(options);
};

const requestData = (options) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        dispatch(props.totaltributes(res.data));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllAttributes = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get(ATTRIBUTE, {
        params: {
          maintainer: 'user,organization,siterecon',
        },
      })
      .then(({ data: response }) => {
        dispatch(setAllAttributes(response.data));
        resolve(response);
      })
      .catch((err) => {
        console.error(err);
        reject();
      });
  });
};

export const getAllAttributesWithDeleted = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get(ATTRIBUTE, {
        params: {
          maintainer: 'user,organization,siterecon',
          status: 'active,closed',
        },
      })
      .then(({ data: response }) => {
        dispatch(setAllwithDeletedAttributes(response.data));
        resolve(response);
      })
      .catch((err) => {
        console.error(err);
        reject();
      });
  });
};

export const getAllServiceItems = (orderId, organizationId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getServiceItems(Number(orderId), organizationId)
      .then((response) => {
        dispatch(setAllServiceItems(response));
        resolve(response);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const getAllServices = (organizationId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getServicesList(organizationId)
      .then((response) => {
        dispatch(setAllServiceItems(response));
        resolve(response);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
