import React from 'react';

const NewNote = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      d='M19 13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H5L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V13Z'
      stroke='#333333'
      stroke-width='1.2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M7.45003 8.45003H9.54997V10.55C9.54997 10.805 9.74501 11 10 11C10.255 11 10.45 10.805 10.45 10.55V8.45003H12.55C12.805 8.45003 13 8.25499 13 8C13 7.74501 12.805 7.54997 12.55 7.54997H10.45V5.45003C10.45 5.19504 10.255 5 10 5C9.74501 5 9.54997 5.19503 9.54997 5.45003V7.54997H7.45003C7.19504 7.54997 7 7.74501 7 8C7 8.25499 7.20999 8.45003 7.45003 8.45003Z'
      fill='#333333'
      stroke='#333333'
      stroke-width='0.5'
    />
  </svg>
);

export default NewNote;
