import { SingleComponent } from './types';

export const transformComponentProperties = (data: SingleComponent[]) => {
  return data.map((component) => {
    const resultComponent = { ...component };

    if (component.area) {
      resultComponent['Area'] = component.area;
    }

    if (component.length) {
      resultComponent['Length'] = component.length;
    }

    if (component.perimeter) {
      resultComponent['Perimeter'] = component.perimeter;
    }

    if (component['surface area']) {
      resultComponent['Surface Area'] = component['surface area'];
    }

    if (component['ring area']) {
      resultComponent['Ring Area'] = component['ring area'];
    }

    return resultComponent;
  });
};
