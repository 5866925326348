// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector161IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector161Icon(props: Vector161IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 32 22"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M1 21.165c.535-.222 2.788-1.286 3.279-1.51a25.594 25.594 0 003.413-1.851c1.053-1.027 2.23-2.968 2.57-4.08-.037-.435 3.252-7.168 3.252-7.168-1.598-.431-7.83 2.126-7.83 2.126l.373 2.808c1.568.552 1.413.597 2.086.693 3.832.751 7.12.026 9.654-.895 1.941-.705 3.177-1.737 4.483-3.292 1.435-1.708 1.2-3.614 2.94-5.021C26.736 1.748 28.489 1 30.425 1"
        }
        stroke={"currentColor"}
        strokeWidth={"1.628"}
        strokeLinecap={"round"}
      ></path>
    </svg>
  );
}

export default Vector161Icon;
/* prettier-ignore-end */
