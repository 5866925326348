import Text from '@/components/elements/text/Text';
import { Row } from 'antd';
import { SVGProps } from 'react';

interface AboutViewProps {
  viewTitle: string;
  viewDesc: string;
  image: SVGProps<any>;
}

const AboutView = ({
  viewTitle = '',
  viewDesc = '',
  image,
}: AboutViewProps) => {
  return (
    <Row className='about-view'>
      <Text type='p11' color='dark-gray' className='mb-1'>
        About {viewTitle}
      </Text>
      <Text type='p7' color='gray-shade' className='mb-2'>
        {viewDesc}
      </Text>
      <Row className='display-img'>{image}</Row>
    </Row>
  );
};

export default AboutView;
