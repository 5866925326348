import { Button, Col, Image, Modal, Row, Space, Spin } from 'antd';
import React from 'react';
import './style.less';
import Text from '@elements/text/Text';

interface IActionModalProps {
  isVisible: boolean;
  setModalVisible: (visibility: boolean) => void;
  title: string;
  icon: string;
  content: React.ReactNode;
  onOK: (data: any) => void;
  onCancel?: () => void;
  extraData?: any;
  loading?: boolean;
}

const ActionModal = ({
  isVisible,
  title,
  icon,
  content,
  setModalVisible,
  onOK,
  onCancel,
  extraData,
  loading = false,
}: IActionModalProps) => {
  return (
    <Modal
      className={'action-modal'}
      visible={isVisible}
      onCancel={() => {
        setModalVisible(false);
      }}
      footer={null}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <Row className={'action-modal-container text-center'}>
          <Col span={24}>
            <Image src={icon} className={'modal-icon'} preview={false} />
          </Col>
          <Col span={24}>
            <Text type={'p78'} color={'dark-gray'} className={'my-4'}>
              {title}
            </Text>
          </Col>
          <Col span={24}>
            <Text type={'p85'} color={'gray-shade'} className={'my-4'}>
              {content}
            </Text>
          </Col>
        </Row>
        <Row className={'action-modal-footer'} justify={'space-around'}>
          <Space size={40}>
            <Button
              type={'text'}
              className={'modal-btn btn-cancel'}
              onClick={() => {
                setModalVisible(false);
                if (onCancel) {
                  onCancel();
                }
              }}
            >
              Cancel
            </Button>
            <Button
              type={'primary'}
              className={'modal-btn btn-ok'}
              onClick={() => {
                onOK(extraData);
              }}
            >
              OK
            </Button>
          </Space>
        </Row>
      </Spin>
    </Modal>
  );
};
export default ActionModal;
