import { AutoComplete, Input } from 'antd';
import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import { ReactComponent as LeftIcon } from '../../assets/left.svg';
import { ReactComponent as CloseIcon } from '../../assets/close-grey.svg';
import { ReactComponent as SeachIcon } from '../../assets/search.svg';

import './style.less';
import '@/styles/base/utils.less';
import { Strings } from '../../utils/strings';
import { padWithEmptySpaces } from '../../utils/helpers';

type SearchValueType = {
  address: string;
  orderId: string | number;
};

type LargeSearchBarProps = {
  onClose: () => void;
  values: SearchValueType[];
  setSearchedAddress: (data: string) => void;
  resetSearchedAddress: () => void;
  showSearchBar: boolean;
  searchTerm: string;
  setSearchTerm: (value: any) => void;
};

const LargeSearchBar = ({
  onClose,
  values,
  setSearchedAddress,
  resetSearchedAddress,
  searchTerm,
  setSearchTerm,
}: LargeSearchBarProps) => {
  const [options, setOptions] = useState([]);
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const keys = ['address', 'orderId'];

  const setOptionsFunc = () => {
    const filteredObj: any = values.filter((order: any) => {
      if (searchTerm === searchTerm.toUpperCase()) {
        return keys.some((key) =>
          order[key].toString().toUpperCase().includes(searchTerm)
        );
      }
      if (searchTerm === searchTerm.toLowerCase()) {
        return keys.some((key) =>
          order[key].toString().toLowerCase().includes(searchTerm)
        );
      }
      if (
        searchTerm === searchTerm[0].toUpperCase() &&
        searchTerm === searchTerm.toLowerCase()
      ) {
        return keys.some((key) =>
          order[key].toString().toLowerCase().includes(searchTerm)
        );
      } else {
        return keys.some((key) => order[key].toString().includes(searchTerm));
      }
    });

    const valueOptions = filteredObj
      // .filter(
      //   (order: any, index: number, orderObj: any) =>
      //     index === orderObj.findIndex((t: any) => t.address === order.address)
      // )
      .splice(0, 5)
      .map((order: any) => {
        return {
          label: (
            <div className='label-container'>
              <p className='address'>{order.address}</p>
              <p className='order-id'>
                <span style={{ color: '#666666', fontSize: '12px' }}>
                  Order ID{' '}
                </span>
                {padWithEmptySpaces(1)}
                {order.orderId}
              </p>
            </div>
          ),
          value: order.orderId,
        };
      });
    setOptions(valueOptions);
  };

  useEffect(() => {
    const handleEsc = (event: { key: string }) => {
      if (event.key === 'Escape') {
        resetSearchedAddress();
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    setOptionsFunc();
  }, [searchTerm]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (searchTerm.length !== 0) {
      setIsInputEmpty(false);
    } else {
      setIsInputEmpty(true);
      resetSearchedAddress();
    }
  }, [searchTerm]);

  const onChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSearchTerm(e.target.value);
  };

  const onSelect = (data: any) => {
    setSearchedAddress(data);
    setIsInputEmpty(false);
  };

  const onKeydown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      setSearchedAddress(searchTerm);
      setIsInputEmpty(false);
    }
    if (e.key === 'Escape') {
      resetSearchedAddress();
      onClose();
    }
  };

  const handleClose = () => {
    resetSearchedAddress();
    onClose();
  };

  return (
    <>
      <label className='autocomplete-label'>
        {isInputEmpty && Strings.SearchPlaceholder}
      </label>
      <AutoComplete
        dropdownClassName='certain-category-search-dropdown'
        dropdownMatchSelectWidth={500}
        className='antd-autocomplete'
        style={{
          width: '97%',
        }}
        onSelect={onSelect}
        options={options}
      >
        <Input
          ref={inputRef as unknown as LegacyRef<Input>}
          id='form-input'
          className='form-input'
          prefix={
            <span onClick={handleClose}>
              <LeftIcon />
            </span>
          }
          suffix={
            isInputEmpty ? (
              <span>
                <SeachIcon />
              </span>
            ) : (
              <span onClick={handleClose}>
                <CloseIcon />
              </span>
            )
          }
          value={searchTerm}
          onChange={onChange}
          onKeyDown={onKeydown}
        />
      </AutoComplete>
    </>
  );
};

export default LargeSearchBar;
