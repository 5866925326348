import React from 'react';

const LineSplit = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      d='M16.8972 5.18158C16.928 5.12575 16.9475 5.06496 16.9545 5.00266C16.9615 4.94037 16.9559 4.8778 16.938 4.81852C16.92 4.75924 16.8902 4.70442 16.8501 4.65718C16.8099 4.60994 16.7604 4.57121 16.7042 4.5432C16.5907 4.48663 16.4571 4.47716 16.3328 4.51688C16.2086 4.5566 16.1037 4.64226 16.0414 4.755L12.3241 11.5045L8.89154 11.3516C8.90784 10.637 8.64813 9.95235 8.16386 9.43334C7.67959 8.91433 7.00608 8.59879 6.2767 8.54921C5.54733 8.49962 4.8153 8.71962 4.22558 9.16562C3.63586 9.61162 3.23146 10.2511 3.09249 10.9574C2.95351 11.6637 3.09008 12.3853 3.47516 12.9794C3.86023 13.5734 4.46573 13.9965 5.17172 14.1649C5.87772 14.3333 6.63273 14.2347 7.28722 13.8886C7.94171 13.5425 8.44795 12.9742 8.70568 12.2962L11.8109 12.434L10.3142 15.1501C9.59891 15.0174 8.84865 15.1534 8.21195 15.531C7.57526 15.9086 7.09857 16.5003 6.87631 17.189C6.65404 17.8777 6.7024 18.6131 7.01181 19.2495C7.32122 19.8859 7.86911 20.377 8.54698 20.6255C9.22484 20.874 9.98325 20.8617 10.672 20.5912C11.3607 20.3206 11.9296 19.8115 12.2659 19.1646C12.6021 18.5177 12.6813 17.7803 12.4878 17.0983C12.2942 16.4164 11.842 15.8397 11.2207 15.4824L16.8972 5.18158ZM7.94837 18.9142C7.70301 18.4795 7.64866 17.9612 7.79728 17.4732C7.9459 16.9853 8.28531 16.5677 8.74086 16.3124C9.1964 16.0571 9.73075 15.9849 10.2264 16.1117C10.722 16.2386 11.1383 16.5541 11.3836 16.9888C11.629 17.4235 11.6833 17.9419 11.5347 18.4298C11.3861 18.9178 11.0467 19.3353 10.5911 19.5907C10.1356 19.846 9.60124 19.9182 9.10562 19.7913C8.61001 19.6645 8.19373 19.349 7.94837 18.9142ZM5.0403 9.75586C5.49584 9.50054 6.0302 9.42836 6.52581 9.55522C7.02142 9.68207 7.4377 9.99756 7.68306 10.4323C7.92843 10.867 7.98278 11.3854 7.83416 11.8733C7.68554 12.3612 7.34612 12.7788 6.89058 13.0341C6.43504 13.2895 5.90068 13.3616 5.40507 13.2348C4.90945 13.1079 4.49318 12.7924 4.24781 12.3577C4.00245 11.923 3.9481 11.4046 4.09672 10.9167C4.24534 10.4288 4.58476 10.0112 5.0403 9.75586Z'
      fill='#333333'
      stroke='#333333'
      stroke-width='0.25'
    />
    <path
      d='M13.9231 12.565L14.4339 11.6357L20.6302 11.9093C20.7545 11.92 20.868 11.9774 20.947 12.0695C21.026 12.1615 21.0644 12.2812 21.0542 12.4035C21.0439 12.5258 20.9859 12.6412 20.8921 12.7257C20.7983 12.8102 20.6761 12.8572 20.5509 12.857L13.9231 12.565Z'
      fill='#333333'
      stroke='#333333'
      stroke-width='0.25'
    />
  </svg>
);

export default LineSplit;
