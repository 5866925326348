import { Button, Input, Row, Select } from 'antd';
import Text from '@/components/elements/text/Text';
import { ViewType } from '@/modules/common/types';
import { OrderView, View, ViewCategory } from '../../types';
import { useEffect, useRef } from 'react';
import React from 'react';

interface NewViewSettingsProps {
  isDataLoading: boolean;
  orderViews: Array<OrderView>;
  currentView: OrderView;
  selectedView: View;
  setVisibility: (visible: boolean) => void;
  handleCreateNewView: (selectedView: ViewCategory) => void;
  selectedViewForClone?: number;
  setSelectedViewForClone: (e: any) => void;
  newCopiedViewName: string;
  setNewCopiedViewName: (e: string) => void;
}

const NewViewSettings = ({
  isDataLoading,
  orderViews,
  currentView,
  selectedView,
  setVisibility,
  handleCreateNewView,
  selectedViewForClone,
  setSelectedViewForClone,
  newCopiedViewName,
  setNewCopiedViewName,
}: NewViewSettingsProps) => {
  const isEstView: boolean = selectedView.title === ViewCategory.ESTIMATION;
  const staticOrderViews = orderViews.filter(
    (view: OrderView) => view.viewType === ViewType.STATIC
  );

  const viewTitleInputRef = useRef<Input>(null);

  useEffect(() => {
    if (viewTitleInputRef.current) {
      viewTitleInputRef.current.focus();
    }
  }, []);

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleCreateNewView(selectedView.title);
    }
  };

  return (
    <Row>
      <Row className='w-100'>
        <Text type='p11' color='black'>
          {`New ${selectedView.title} Settings`}
        </Text>
      </Row>
      <Row className={'mt-4'}>
        <Text type='p0' color='gray-shade' className={'m-0 pb-2'}>
          View name
        </Text>
        <Input
          ref={viewTitleInputRef}
          placeholder='Enter view name'
          onChange={(e) => setNewCopiedViewName(e.target.value)}
          value={newCopiedViewName}
          className='w-100 input-view-name'
          maxLength={64}
          onKeyDown={handleEnterPress}
        />
      </Row>
      <Row className={'my-5'}>
        <Text type='p36' color='gray-shade' className={'m-0 pb-2'}>
          {`${isEstView ? 'Linked View' : 'Copy data from'}`}
        </Text>
        <Select
          className={'select-view-name'}
          onChange={(e) => {
            setSelectedViewForClone(e);
          }}
          defaultValue={
            currentView.viewType === ViewType.STATIC
              ? currentView.viewId
              : staticOrderViews[0].viewId
          }
          value={selectedViewForClone}
        >
          {staticOrderViews.map((key: OrderView, i) => {
            return (
              <Select.Option key={key.viewId} value={key.viewId}>
                <p className={'m-0'}>{key.name}</p>
              </Select.Option>
            );
          })}
        </Select>
      </Row>
      <Row align={'middle'} justify={'end'} className='action-btns w-100'>
        <Button
          key='back'
          className={'mr-3'}
          onClick={() => setVisibility(false)}
        >
          <Text type='p19' color='gray-shade'>
            Cancel
          </Text>
        </Button>
        <Button
          key='submit'
          type='primary'
          loading={isDataLoading}
          onClick={() => handleCreateNewView(selectedView.title)}
        >
          <Text type='p19' color='dark-gray'>
            Create
          </Text>
        </Button>
      </Row>
    </Row>
  );
};

export default NewViewSettings;