import { NotificationBarType } from './types';

/** Priorities in which a notification bar should be displayed over the others */
export const priorities = [
  NotificationBarType.GuestUser,
  NotificationBarType.SubscriptionTerminated,
  NotificationBarType.PaymentFailed,
  NotificationBarType.JobStatusUpdate,
  NotificationBarType.VerifyEmail,
];
