import React from 'react';

const EditTool = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='24'
    viewBox='0 0 20 24'
    fill='none'
    {...props}
  >
    <path
      d='M17.375 20.5H18V19.8303V18.6697V18H17.375H16.125H15.5V18.6697V19.8303V20.5H16.125H17.375Z'
      stroke='#333333'
    />
    <path
      d='M2.87549 20.5H3.50049V19.8485V18.6515V18H2.87549H1.62549H1.00049V18.6515V19.8485V20.5H1.62549H2.87549Z'
      stroke='#333333'
    />
    <path
      d='M2.875 6H3.5V5.32011V4.17989V3.5H2.875H1.625H1V4.17989V5.32011V6H1.625H2.875Z'
      stroke='#333333'
    />
    <path
      d='M16.3031 12.2637L4.53894 17.0607L9.25989 5.48047L11.5442 10.165L16.3031 12.2637Z'
      stroke='#333333'
    />
    <line
      x1='2.25'
      y1='18'
      x2='2.25'
      y2='6'
      stroke='#333333'
      stroke-width='0.5'
    />
    <line
      x1='3.5'
      y1='19.25'
      x2='15.5'
      y2='19.25'
      stroke='#333333'
      stroke-width='0.5'
    />
  </svg>
);

export default EditTool;
