import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Divider, Modal, Row } from 'antd';
import EstimationViewImage from '@/assets/view/estimation-view.png';
import EstimationViewDisabledImage from '@/assets/view/estimation-view-disabled.png';
import TakeoffDraftViewImage from '@/assets/view/take-off-draft-view.png';
import { ReactComponent as CloseModal } from '@/assets/cross-modal.svg';
import Text from '@/components/elements/text/Text';
import AboutView from './AboutView';
import HighlightView from './HighlightView';
import NewViewSettings from './NewViewSettings';
import { OrderView, View, ViewCategory } from '../../types';
import { ViewType } from '@/modules/common/types';
import './style.less';
import {
  getServiceItemAttributeObj,
  getAttributesLabel,
} from '../../../../../feature-attributes/utils';
import React from 'react';

interface CreateNewViewProps {
  isDataLoading: boolean;
  orderViews: any;
  currentView: any;
  visiblity: boolean;
  setVisibility: (visible: boolean) => void;
  handleCreateNewView: () => void;
  selectedViewForClone?: number;
  setSelectedViewForClone: (e: any) => void;
  newCopiedViewName: string;
  setNewCopiedViewName: (e: string) => void;
  isEstimationDisabled: boolean;
}

const CreateNewView = ({
  isDataLoading,
  orderViews,
  currentView,
  visiblity,
  setVisibility,
  handleCreateNewView,
  selectedViewForClone,
  setSelectedViewForClone,
  newCopiedViewName,
  setNewCopiedViewName,
  isEstimationDisabled = false,
}: CreateNewViewProps) => {
  const [selectedView, setSelectedView] = useState<View | null>(null);
  const [showViewDetails, setShowViewDetails] = useState<boolean>(false);

  const staticOrderViews = orderViews.filter(
    (view: OrderView) => view.viewType === ViewType.STATIC
  );

  const dynamicOrderViews = orderViews.filter((view: OrderView) =>
    [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(view.viewType)
  );

  const allAttributes =
    useSelector((state: any) => state.feature.withDeletedAttributes) || [];
  const attributeObj = getServiceItemAttributeObj(allAttributes);
  const attributeLabel = getAttributesLabel(attributeObj, true, true);

  const VIEW_DATA = [
    {
      title: ViewCategory.TAKEOFF_DRAFT,
      desc: 'Use Takeoff draft view to create a new version of any existing takeoffs data and make changes to it without interfering with the existing view data.',
      image: (
        <img
          src={TakeoffDraftViewImage}
          alt={'Take off draft view'}
          width={'160px'}
          height={'120px'}
          style={{ borderRadius: '10px' }}
        />
      ),
    },
    {
      title: ViewCategory.ESTIMATION,
      desc: 'Get all the service items that you have assigned to the layers on the map for estimating the service cost of the property. This is a non-editable linked view.',
      image: (
        <img
          src={isEstimationDisabled ? EstimationViewDisabledImage : EstimationViewImage}
          alt={'Estimation view'}
          width={'160px'}
          height={'120px'}
          style={{ borderRadius: '10px' }}
        />
      ),
      isEstimationDisabled,
    },
  ];

  useEffect(() => {
    if (selectedView) {
      if (selectedView.title === ViewCategory.ESTIMATION) {
        // Extract numbers from existing estimation views
        const estimationViewNumbers: number[] = dynamicOrderViews
          .map(view => {
            const match = view.name.match(/Estimation View\s*(\d+)/i);
            return match ? parseInt(match[1], 10) : null;
          })
          .filter((number): number is number => number !== null);

        // Find the highest number and increment it by one
        const highestEstimationNumber = estimationViewNumbers.length > 0 ? Math.max(...estimationViewNumbers) : 0;
        const newEstimationViewNumber = highestEstimationNumber + 1;

        setNewCopiedViewName(`Estimation View ${newEstimationViewNumber}`);
      } else {
        // Extract numbers from existing draft views
        const draftViewNumbers: number[] = staticOrderViews
          .map(view => {
            const match = view.name.match(/Draft\s*(\d+)/i);
            return match ? parseInt(match[1], 10) : null;
          })
          .filter((number): number is number => number !== null);

        // Find the highest number and increment it by one
        const highestDraftNumber = draftViewNumbers.length > 0 ? Math.max(...draftViewNumbers) : 0;
        const newDraftViewNumber = highestDraftNumber + 1;

        setNewCopiedViewName(`Draft ${newDraftViewNumber}`);
      }
    }
  }, [selectedView, visiblity]);

  useEffect(() => {
    setShowViewDetails(false);
    setSelectedView(null);
  }, [visiblity]);

  return (
    <>
      <Modal
        visible={visiblity}
        onCancel={() => setVisibility(false)}
        title='Create a New View'
        className='create-new-view-modal'
        footer={null}
        closeIcon={<CloseModal />}
        centered
      >
        <Row className='mb-3'>
          <Text type='p19' color='gray-shade'>
            Select any template to start
          </Text>
        </Row>
        <Row justify='start' className='mb-5 w-100 new-view-section1'>
          {VIEW_DATA.map((view, index) => {
            const isEstimationView = view.title === ViewCategory.ESTIMATION
            return (
              <Col
                key={view.title}
                span={6}
                onClick={() => {
                  // if (isEstimationView && view.isEstimationDisabled === false) {
                  setSelectedView(view);
                  setShowViewDetails(true);
                  // }
                }}
              >
                <HighlightView
                  disabled={isEstimationDisabled}
                  key={index}
                  image={view.image}
                  title={view.title}
                  className={
                    selectedView && selectedView.title === view.title
                      ? 'view-wrapper-btn-active'
                      : 'view-wrapper-btn'
                  }
                />
              </Col>
            );
          })}
        </Row>
        {selectedView && showViewDetails && (
          <Row align='top' className='new-view-section2'>
            <Col span={12} className='p-3'>
              <AboutView
                viewTitle={selectedView.title}
                viewDesc={selectedView.desc.replace(
                  'service items',
                  attributeLabel
                )}
                image={selectedView.image}
              />
            </Col>
            <Divider type='vertical' />
            <Col span={11} className='p-3'>
              <NewViewSettings
                isDataLoading={isDataLoading}
                orderViews={orderViews}
                currentView={currentView}
                selectedView={selectedView}
                setVisibility={setVisibility}
                handleCreateNewView={handleCreateNewView}
                selectedViewForClone={selectedViewForClone}
                setSelectedViewForClone={setSelectedViewForClone}
                newCopiedViewName={newCopiedViewName}
                setNewCopiedViewName={setNewCopiedViewName}
              />
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default CreateNewView;
