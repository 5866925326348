import { Row, Tooltip } from 'antd';
import { ReactComponent as UploadGeoJsonIcon } from '@/assets/uploadGeoJsonNavIcon.svg';
import React from 'react';
import { NotifyError } from '@/helpers/notification-utils';
import { trackEvents } from '@/helpers/utilities';

interface UploadGeoJsonProps {
  onClick: () => void;
  currentViewData: any;
}

const UploadGeoJson = ({ onClick, currentViewData }: UploadGeoJsonProps) => {
  return (
    <>
      <Tooltip title={'Upload geoJSON'}>
        <div
          onClick={() => {
            trackEvents('navbar_uploadgeojson_clicked', {
              viewName: currentViewData.name,
              isBaseView: currentViewData.isBaseView,
              orderId: currentViewData.orderId,
              viewId: currentViewData.viewId,
            });
            !currentViewData?.isEditable
              ? NotifyError(
                  'All changes in the "' +
                    currentViewData.name +
                    '" is restricted, Please create a new view'
                )
              : onClick();
          }}
          style={{ cursor: 'pointer', display: 'flex' }}
          className='tool--navbar'
        >
          <Row>
            <UploadGeoJsonIcon />
          </Row>
        </div>
      </Tooltip>
    </>
  );
};

export default UploadGeoJson;
