import { ReactComponent as PolygonIcon } from '@/assets/PolygonIcon.svg';
import { ReactComponent as LineIcon } from '@/assets/LineIcon.svg';
import { ReactComponent as PointIcon } from '@/assets/PointIcon.svg';
import React from 'react';

export const renderFeatureTypeIcon = (type) => {
  switch (type) {
    case 'polygon':
      return <PolygonIcon />;
    case 'line':
      return <LineIcon />;
    case 'path':
      return <LineIcon />;
    case 'point':
      return <PointIcon />;
  }
};
