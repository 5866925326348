// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon100IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon100Icon(props: Icon100IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M10.725 7.995c0 .253-.104.485-.311.696l-3.992 3.902a.924.924 0 01-.264.18.859.859 0 01-.316.058.831.831 0 01-.733-.417.793.793 0 01-.116-.422c0-.236.09-.443.269-.622l3.696-3.591v.427L5.262 4.625a.849.849 0 01-.27-.617c0-.155.04-.295.117-.422a.81.81 0 01.305-.3.803.803 0 01.428-.116c.228 0 .421.079.58.237l3.992 3.897a.964.964 0 01.311.69z"
        }
        fill={"#666"}
      ></path>
    </svg>
  );
}

export default Icon100Icon;
/* prettier-ignore-end */
