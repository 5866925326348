import { Row, RowProps } from 'antd';
import { ComponentProps, CSSProperties, forwardRef, useMemo } from 'react';

export interface UiBoxProps extends RowProps, ComponentProps<'div'> {
  flex?: boolean;

  /** Flex direction, only works when flex prop is true */
  direction?: CSSProperties['flexDirection'];
}

export type BoxProps = Omit<UiBoxProps, 'ref'>;

/**
 * Component replacement for HTML "div"
 * This is just a basic version of Box currently, which supports limited props.
 *
 * TODO - add functionality to support spacing and other props directly. (ex. my={{xs: 5, lg: 10}})
 **/
const Box = forwardRef<HTMLDivElement, UiBoxProps>(
  ({ flex, direction, style, children, ...rest }, ref) => {
    /** For flex, we have simply use ant "Row" for now */
    const Component = useMemo(() => (flex ? Row : 'div'), [flex]);

    return (
      <Component
        style={{ flexDirection: direction, ...style }}
        {...rest}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);

export default Box;
