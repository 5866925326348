import { useOrderStatusContext } from '@/modules/project/guards/OrderStatus/context';
import { useSelector } from 'react-redux';
import { IStore } from '@/store/types';
import { OrderStatus } from '@/modules/common/types';

/** Returns Order Status Value from Context, if context value is not available. Fetches the value from Redux Store  */
const useOrderStatus = () => {
  const context = useOrderStatusContext();

  const reduxOrderStatus = useSelector<IStore, OrderStatus | null>(
    (state) => state.order.orderStatus
  );

  return typeof context.status === 'undefined'
    ? reduxOrderStatus
    : context.status;
};

export default useOrderStatus;
