import { ID, ISOTimestamp, Nullable } from '../../../modules/common/types';
import {
  SubscriptionAddOnType,
  SubscriptionChangeType,
  SubscriptionIntervalType,
  SubscriptionPaymentStatus,
  SubscriptionPaymentType,
  SubscriptionPlan,
  SubscriptionUpdateType,
} from './enum';
import { GetSubscriptionApiResponse } from './api-schema';
import { OrderType } from '../../types';

export interface SubscriptionAddOn {
  type: SubscriptionAddOnType;
  quantity: number;
}

export interface BasePackage {
  platformAccessType: SubscriptionPlan;
  intervalType: SubscriptionIntervalType;
  unitPrice?: number;
  includedCredits?: number;
  includedSeats?: number;
}

export interface PreviousSubscription {
  platformAccessType: SubscriptionPlan;
  intervalType: SubscriptionIntervalType;
  endedOn: ISOTimestamp;
  creditAddOns: number;
  seatAddOns: number;
}

export interface ScheduledUpdates {
  updateType: SubscriptionUpdateType;
  basePackage: BasePackage;
  addOns: SubscriptionAddOn[];
  effectiveDate: Nullable<string>;
  changeType: SubscriptionChangeType;
}

export interface BillTotal {
  unitPrice: number;
  discount: Nullable<number>;
  finalCost: number;
}

export interface ISubscription extends GetSubscriptionApiResponse {}

export interface IUpcomingBill {
  basePackage: BasePackage;
  addOns: SubscriptionAddOn[];
  total: BillTotal;
  billingDate: string;
}

export enum PaymentFailureReason {
  PAYMENT_DECLINED = 'payment-declined',
  INVALID_BILLING_ADDRESS = 'invalid-billing-address',
}

export interface ILastPaymentStatus {
  invoiceId: Nullable<number>;
  status: Nullable<SubscriptionPaymentStatus>;
  type: Nullable<SubscriptionPaymentType>;
  stripeInvoiceId: Nullable<string>;
  paymentLink: Nullable<string>;
  daysRemaining: Nullable<number>;
  totalAmount: Nullable<number>;
  paymentFailureReason: Nullable<PaymentFailureReason>;
}

export interface PayAsYouGoModalOrder {
  id: ID;
  type: OrderType;
}

export interface InstantMeasurementPayload {
  address: string;
  features: string[];
  featureIds: string[];
  latitude: number;
  longitude: number;
  customerName: string;
  customerId: string;
  description: string;
  postalCode: string;
}

export interface PayAsYouGoModalBoltOrder {
  type: OrderType.INSTANT;
  payload: InstantMeasurementPayload;
}

export interface PayAsYouGoModalProps {
  visible: boolean;
  order: PayAsYouGoModalOrder | PayAsYouGoModalBoltOrder | null;
}

export type PayAsYouGoModalData = PayAsYouGoModalProps;

export interface SubscriptionWidgetData {
  visible: boolean;
}

export interface RepairSubscriptionInvoice {
  id: string;
  url: string;
}

export interface RepairSubscriptionData {
  visible: boolean;
  invoice: Nullable<RepairSubscriptionInvoice>;
}

export type GetSubscriptionStatePayload = Pick<
  ISubscription,
  'basePackage' | 'lastPaymentStatus' | 'previousSubscription' | 'status'
>;
