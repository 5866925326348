import { Button, Col, Row, Space } from 'antd';
import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormInput from '@/components/elements/form-input/FormInput';
import Text from '@/components/elements/text/Text';
import { updateEmailAndSendOtp, verifyEmailOtp } from '@/store/user/thunks';
import { ExclamationCircleOutlined } from '@/components/elements/icons/antd-icons';
import OtpInput from 'react-otp-input';
import { ReactComponent as EditMail } from '../../../../assets/images/EditMail.svg';
import { API_STATUS } from '../../auth-page/types';
import '../style.less';
import useAuth from '@/modules/auth/hooks';

interface EmailVerifyFormPops {
  showEmailVerifyModal: boolean;
  handleToggleEmailVerifyModal: (status: any) => void;
}

const OTP_DIGITS = 6;
const BLANK = '';
const DEFAULT_SECONDS = 30;

const reducer = (state: number, action: any) => {
  switch (action.type) {
    case 'Decrement':
      if (state <= 0) {
        return state;
      } else {
        return state - 1;
      }
    case 'Reset':
      return DEFAULT_SECONDS;
    default:
      return state;
  }
};

const EmailVerifyForm = ({
  showEmailVerifyModal,
  handleToggleEmailVerifyModal,
}: EmailVerifyFormPops) => {
  const dispatch = useDispatch();

  const { loading, user } = useAuth();
  const userEmail = user.email;
  const { otpGenStatus, otpGenError, otpVerifyStatus, otpVerifyError } =
    useSelector((state: any) => state.user);

  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [showOtpField, setShowOtpField] = useState(false);

  useEffect(() => {
    if (userEmail) {
      setEmail(userEmail);
      setError(null);
    }
  }, [userEmail]);

  useEffect(() => {
    if (API_STATUS.SUCCESS === otpGenStatus) {
      setShowOtpField(true);
    } else if (API_STATUS.FAILURE === otpGenStatus) {
      setError(otpGenError || 'Something went wrong.');
    }
  }, [otpGenStatus, otpGenError]);

  useEffect(() => {
    if (otpVerifyStatus === API_STATUS.SUCCESS) {
      handleToggleEmailVerifyModal(API_STATUS.SUCCESS);
    } else if (otpVerifyStatus === API_STATUS.FAILURE) {
      setError(otpVerifyError || 'Something went wrong.');
    }
  }, [otpVerifyStatus, otpVerifyError]);

  useEffect(() => {
    if (showEmailVerifyModal === false) {
      reset();
    }
  }, [showEmailVerifyModal]);

  const sendMailOtp = () => {
    dispatch(updateEmailAndSendOtp({ otpInfo: { email } }));
    reset();
  };

  const [otp, setOtp] = useState<string>(BLANK);
  const [timer, setTimer] = useReducer(reducer, DEFAULT_SECONDS);

  const otpInputHandler = (val: string) => {
    setOtp(val);
  };

  const reset = () => {
    setTimer({ type: 'Reset' });
    setOtp(BLANK);
    setError(null);
  };

  const verificationButtonHandler = () => {
    reset();
    dispatch(verifyEmailOtp({ otpInfo: { email }, otp }));
  };

  const [intervalId, setIntervalId] = useState<any>(0);

  useEffect(() => {
    if (timer === DEFAULT_SECONDS) {
      clearInterval(intervalId);
      const newIntervalId = setInterval(() => {
        setTimer({ type: 'Decrement' });
      }, 1000);
      setIntervalId(newIntervalId);
    }
  }, [timer]);

  return (
    <Space
      direction='vertical'
      align='center'
      size='small'
      className='user-job-title'
    >
      <Row justify='center' className='mt-3'>
        <Space direction='vertical' align='center' size='small'>
          <Row justify='center'>
            <Text
              type='p24'
              color='dark-gray'
              className='text-center email-title'
            >
              Account Verification
            </Text>
          </Row>
          {!showOtpField && (
            <Row justify='center'>
              <Text className='email-verify-text text-center'>
                <Text>{'    '}</Text>Please confirm your Email ID. Account
                verification is required to be a part of your organisation’s
                workspace.
              </Text>
              <Col span={24}>
                <FormInput
                  className='w-100'
                  type='email'
                  name='email'
                  containerStyle={{}}
                  handleChange={(e: any) => setEmail(e.target.value)}
                  value={email}
                  label='Email'
                  placeholder='Enter Email'
                />
                {email.length > 0 && error && (
                  <Text className='w-100 error-text'>
                    <ExclamationCircleOutlined /> {'  '} {error}
                  </Text>
                )}
              </Col>
              <Col span={24}>
                <Row justify='center' className='mt-5 mb-7'>
                  <Button
                    type='primary'
                    className='w-100 ws-btn'
                    loading={loading}
                    onClick={sendMailOtp}
                    disabled={email.length === 0}
                  >
                    Send OTP
                  </Button>
                </Row>
              </Col>
            </Row>
          )}
          {showOtpField && (
            <Row justify='center' align='middle'>
              <Text className='email-verify-text' color='gray-shade'>
                Check your inbox or spam folder for the account verification
                code.
              </Text>
              <Text className='email-text w-100 mb-2 text-center'>
                {email}
                {
                  <span className='edit-icon'>
                    <EditMail
                      onClick={() => {
                        setShowOtpField(false);
                      }}
                    />
                  </span>
                }
              </Text>
              <Row
                justify='center'
                align='middle'
                className='email-otp w-100'
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    verificationButtonHandler();
                  }
                }}
              >
                <form
                  onSubmit={verificationButtonHandler}
                  className='position-relative'
                >
                  <>
                    {/* @ts-ignore */}
                    <OtpInput
                      numInputs={OTP_DIGITS}
                      onChange={(val: string) => otpInputHandler(val)}
                      value={otp}
                      shouldAutoFocus={true}
                      isInputNum={true}
                      inputStyle='otp-input-style'
                      focusStyle='otp-focus-style'
                    />
                    {error && (
                      <Text className='w-100 error-text error-otp'>
                        {error}
                      </Text>
                    )}
                    <Button className='d-none' />
                  </>
                </form>
              </Row>
              <Col span={24} className='text-center mt-2'>
                {timer > 0 ? (
                  <Text type='p10' className='mb-0 text-center'>
                    Resend OTP in {timer}s
                  </Text>
                ) : (
                  <Text type='p10' className='text-center otp-not-sent'>
                    Didn’t receive OTP?
                    <Button type='text' onClick={sendMailOtp}>
                      Resend OTP
                    </Button>
                  </Text>
                )}
              </Col>
              <Col span={24} className='mt-2 mb-2'>
                <Row justify='center' className='w-100'>
                  <Button
                    type='primary'
                    className='mt-3 h-40 w-100'
                    loading={loading}
                    disabled={otp.length !== OTP_DIGITS}
                    onClick={verificationButtonHandler}
                  >
                    Verify Account
                  </Button>
                </Row>
              </Col>
              {timer === 0 && (
                <Col span={24} className='mt-2 poppins'>
                  <Row justify='center'>
                    <Text className='otp-not-recieved'>
                      Not receiving OTP? Drop an email at{' '}
                      <u>support@siterecon.ai</u> from the same email ID and we
                      will assist you.
                    </Text>
                  </Row>
                </Col>
              )}
            </Row>
          )}
        </Space>
      </Row>
    </Space>
  );
};
export default EmailVerifyForm;
