import React from 'react';
import { Input, Slider } from 'antd';
import './style.less';

const StylePickerOptions = (props) => {
  return (
    <div
      id={props.layerData.layerId + '-style-picker'}
      style={{ background: 'white' }}
      className='layer-style'
    >
      <div>
        <div className='layer-header'>Layer styling</div>
        <div className='layer-inner-1'>
          <div>Stroke Width</div>
          <Slider
            defaultValue={props.style.stroke}
            key={props.style.stroke}
            min={1}
            max={10}
            onChange={(e) =>
              props.updateStyle(props.layerType, props.layerData.layerId, {
                ...props.style,
                stroke: e,
              })
            }
          />
        </div>
      </div>
      {props.layerType === 'polygon' && (
        <div className='layer-inner-1'>
          <div>Opacity</div>
          <Slider
            defaultValue={props.style.opacity}
            key={props.style.opacity}
            min={0}
            max={1}
            step={0.1}
            onChange={(e) =>
              props.updateStyle(props.layerType, props.layerData.layerId, {
                ...props.style,
                opacity: e,
              })
            }
          />
        </div>
      )}
      {props.layerType === 'parcel' && (
        <div className='layer-inner-1'>
          <div>Opacity</div>
          <Slider
            defaultValue={props.style.opacity}
            min={0}
            max={1}
            step={0.1}
            onChange={(e) =>
              props.updateStyle(props.layerType, props.layerData.layerId, {
                ...props.style,
                opacity: e,
              })
            }
          />
        </div>
      )}

      <div className='layer-inner-1'>
        <div>Fill Color</div>
        <Input
          className='parcel-layer-inner-color'
          name='fillColor'
          value={props.style.fillColor}
          type='color'
          onChange={(e) =>
            props.updateStyle(props.layerType, props.layerData.layerId, {
              ...props.style,
              color: e.target.value,
              fillColor: e.target.value,
            })
          }
          activeBorderColor={'#999'}
          // style={{ border: 0 }}
        />
      </div>
    </div>
  );
};

export default StylePickerOptions;
