import { getFeatureValue } from '@/helpers/mapUtils/layerUtils';
import { trackEvents } from '@/helpers/utilities';

export const DrawSegment = (reqData, layer, geometryType) => {
  if (reqData.length) {
    reqData.forEach((item) => {
      let payload = {
        Layername: layer?.name,
        Feature_Type: JSON.parse(item.geoJson)?.geometry?.type,
        Area: getFeatureValue(JSON.parse(item.geoJson)),
      };
      switch (geometryType) {
        case 'polygon':
          trackEvents('map-tools__draw_polygon', {
            ...payload,
            PolygonArea: `${payload?.Area} Sq.Ft`,
          });
          break;
        case 'line':
          trackEvents('map-tools__draw_line', {
            ...payload,
            LineLength: `${payload?.Area} Ft`,
          });
          break;
        case 'path':
          trackEvents('map-tools__draw_path', {
            ...payload,
            PathLength: `${payload?.Area} Ft`,
          });
          break;
        case 'point':
          trackEvents('map-tools__draw_point', {
            ...payload,
            peripheralArea: `${payload?.Area} Sq.Ft`,
          });
          break;
        default:
          break;
      }
    });
  }
};

export const DragSegment = (start, end) => {
  trackEvents('map-tools__drag', {
    InitialMapCentreLat: start[1],
    InitialMapCentreLong: start[0],
    FinalMapCentreLat: end[1],
    FinalMapCentreLong: end[0],
  });
};
