import { useHistory, useLocation } from 'react-router-dom';
import { lazy } from 'react';
import {
  decodeRedirectURI,
  getWorkspaceIdFromUrl,
  isWorkspaceIdValid,
  lazyRetry,
} from '../../../helpers/utils';
import useAuth from '../../../modules/auth/hooks';
import { useSelector } from 'react-redux';

import Fallback from '../../elements/fallback/Fallback';
import { IStore } from '../../../store/types';
import { useWorkspace } from '../../../modules/workspace/hooks/useWorkspace';

// @ts-ignore
const Auth = lazy(() => lazyRetry(() => import('./Auth')));

const AuthComponentSwitch = () => {
  const { authenticated } = useAuth();
  const { search } = useLocation();
  const history = useHistory();
  const { change } = useWorkspace();

  const orgData = useSelector<IStore, any>(
    (state) => state.teamManagement.organisationData
  );

  const getUrlWithRedirectionParam = (url: string, redirect: string | null) => {
    if (!redirect || redirect === '/') {
      return url;
    }

    return `${url}?redirect=${redirect}`;
  };

  if (authenticated) {
    /** User is logged in now */  
  // const redirect = getRedirectionUrl(search);

  const searchParams = new URLSearchParams(search);

  const redirect = searchParams.get('redirect');

    const urlWorkspaceId = redirect ? getWorkspaceIdFromUrl(redirect) : null;
    const isValidWorkspaceId = Boolean(
      urlWorkspaceId && isWorkspaceIdValid(orgData, urlWorkspaceId)
    );

    if (orgData.length === 0) {
      return <Fallback />;
    }

    if (orgData.length === 1) {
      /** User have only one organization, so no need to show workspace selection page */
      if (redirect) {
        window.location.href = decodeRedirectURI(redirect, false);
      } else {
        history.push('/');
      }

      return null;
    }

    if (urlWorkspaceId) {
      if (isValidWorkspaceId) {
        /** Redirection path contains valid `workspaceId`. So we'll directly switch the workspace to that id and redirect user to intended path */
        change(Number(urlWorkspaceId), decodeRedirectURI(redirect!));
        return null;
      }

      /** Redirection path contains invalid `workspaceId`. So we'll redirect user to intended path without `workspaceId` in URL */
      window.location.href = decodeRedirectURI(redirect!, false);
      return null;
    }

    /** User have multiple organizations. So need to choose which org to log into */
    history.push(getUrlWithRedirectionParam('/workspace', redirect));
    return null;
  }

  return <Auth />;
};

export default AuthComponentSwitch;