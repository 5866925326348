// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon108IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon108Icon(props: Icon108IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M8.62 18.375c-.309 0-.573-.11-.792-.33a1.087 1.087 0 01-.328-.795v-6c0-.31.11-.574.33-.794.22-.22.486-.331.795-.331H9v-1.5c0-.83.293-1.537.879-2.122a2.896 2.896 0 012.125-.878c.83 0 1.538.293 2.121.878S15 7.795 15 8.625v1.5h.375c.31 0 .574.11.794.33.22.22.331.486.331.795v6c0 .31-.11.574-.33.794-.221.22-.486.331-.796.331H8.621zm.005-1.125h6.75v-6h-6.75v6zm1.5-7.125h3.75v-1.5c0-.52-.182-.964-.547-1.328A1.808 1.808 0 0012 6.75c-.52 0-.963.182-1.328.547a1.808 1.808 0 00-.547 1.328v1.5z"
        }
        fill={"#AAA"}
      ></path>
    </svg>
  );
}

export default Icon108Icon;
/* prettier-ignore-end */
