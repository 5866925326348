import React from "react";
import { Box, Typography } from "@siterecon/sr-styles/dist/elements";
import { Button } from '@siterecon/sr-component/dist/sr-component-library.js';

export interface CustomActionModalProps {
  title: string;
  description: string;
  icon: string;
  secondaryButtonLabel: string;
  primaryButtonLabel: string;
  onSecondaryButtonClick: () => void;
  onPrimaryButtonClick: () => void;
}

const CustomActionModal = ({
  title,
  description,
  icon,
  secondaryButtonLabel,
  primaryButtonLabel,
  onSecondaryButtonClick,
  onPrimaryButtonClick,
}: CustomActionModalProps) => {
  return (
    <Box className="flex-column" style={{ gap: "32px" }}>
      <Box flex wrap={false}>
        <Box className="mr-4">
          <img src={icon} style={{ maxWidth: 96 }} />
        </Box>
        <Box className="flex-column">
          <Typography
            fontSize={18}
            lineHeight={1.5}
            fontWeight={600}
            className="mb-1"
          >
            {title}
          </Typography>
          <Typography
            fontSize={14}
            lineHeight={1.5}
            fontWeight={400}
            color="neutral.600"
          >
            {description}
          </Typography>
        </Box>
      </Box>
      <Box flex justify="end">
        <Button
          btnType={'secondary'}
          className="py-4 mr-4"
          onClick={onSecondaryButtonClick}
        >
          {secondaryButtonLabel}
        </Button>
        <Button className="py-4" onClick={onPrimaryButtonClick}>
          {primaryButtonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default CustomActionModal;
