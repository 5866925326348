import { useCallback, useEffect, useMemo } from 'react';
import usePrevious from '@/hooks/usePrevious';
import useRouterPlusParams from '@/modules/router-plus/hooks/use-router-plus-params';
import useRouterPlusSearchParams from '@/modules/router-plus/hooks/use-router-plus-search-params';
import useRouterPlusRedirect from '@/modules/router-plus/hooks/use-router-plus-redirect';

type OnChangeFunction = (newValue: string, prevValue: string) => void;
export type RedirectFunction = (
  newValue: string,
  keepQueryParams?: boolean
) => void;

type RouteBehaviour = 'push' | 'replace';

type RouteMode = 'path' | 'search';

interface UseRouterPlusParams {
  /**
   * If input parameter is single string, onChange callback will be fired.
   * Otherwise, "on[Param]Change" callback will be fired.
   **/
  onChange: OnChangeFunction;

  behaviour?: RouteBehaviour;
  mode?: RouteMode; // Defaults to "path"
}

interface UseRouterPlusResponse {
  /** Soft redirect to same page with different path params */
  redirect: RedirectFunction;
}

export const replacePathParams = (link, params) => {
  return link.replace(/:\w+\??/g, function (segment) {
    // Remove the first ":" (colon)
    let param = segment.substring(1);
    if (param.indexOf('?') !== -1) {
      // If "?" exists in the end, remove it
      param = param.slice(0, -1);
    }

    return params[param] || param;
  });
};

const useRouterPlus = (
  param: string,
  options?: UseRouterPlusParams,
  deps: any[] = []
): UseRouterPlusResponse => {
  const pathParams = useRouterPlusParams();
  const searchParams = useRouterPlusSearchParams();
  const { redirect: baseRedirect } = useRouterPlusRedirect();

  const value = useMemo(() => {
    if (options?.mode === 'search') {
      return searchParams.get(param) || null;
    }
    return pathParams[param] || null;
  }, [pathParams, searchParams, options?.mode]);

  const previousValue = usePrevious(value);

  useEffect(() => {
    if (options && options.onChange) {
      options.onChange(value, previousValue);
    }
  }, [value, ...deps]);

  const redirect: RedirectFunction = useCallback(
    (newValue: string, keepQueryParams: boolean = true) => {
      return baseRedirect(
        { [param]: newValue },
        { keepQueryParams, mode: options?.mode, behaviour: options?.behaviour }
      );
    },
    [param, baseRedirect]
  );

  return { redirect };
};

export default useRouterPlus;
