export enum QueueOrderStatus {
  MEASUREMENT_ONGOING = 'Measurement Ongoing',
  AWAITING_APPROVAL = 'Awaiting Approval',
  RESOLVING_FEEDBACK = 'Resolving Feedback',
  APPROVED = 'Approved',
}

// export enum QueueCardType {
//   ONGOING,
//   SCHEDULED,
//   COMPLETED,
// }

export interface IQueueOrder {
  orderId: number | string;
  address: string;
  city: string;
  state: string;
  /** ISO 8601 Date Format with Timestamp */
  estimatedStartTime: string;
  /** ISO 8601 Date Format with Timestamp */
  estimatedDeliveryTime: string;
  status: QueueOrderStatus;
  orderHashed: string;
  createdBy: string;
  /** ISO 8601 Date Format with Timestamp */
  createdAt: string;
}

export interface IQueue {
  id: string;
  queueName: string;
  orders: IQueueOrder[];
  searchOrders?: IQueueOrder[];
}

export interface IQueueResponse {
  isQueueRequested: boolean;
  data: IQueue[];
}

export interface AddressObject {
  address: string;
  orderId: string | number;
}

export interface Organization {
  all_users: Array<string>;
  avatar: string;
  created_at: string | Date;
  id: number;
  is_default: null | boolean;
  role: string;
  title: string;
  total_credits: string | number;
}

export interface OrganizationUser {
  em: string;
  fn: string;
  id: number;
  last_login: string | Date;
  profile_pic: string;
  role_id: 1;
}

export interface User {
  address: string | null;
  contact_number: string;
  created_at: string | Date;
  date_joined: string | Date;
  email: string;
  email_verified: string | null;
  first_name: string;
  id: number;
  is_activated: boolean | null;
  is_active: boolean | null;
  is_deleted: boolean | null;
  is_existing_user: number;
  is_staff: boolean | null;
  is_superuser: boolean | null;
  job_title_id: number;
  last_login: string | Date;
  last_name: string | null;
  organisation: string;
  organization_id: number;
  profile_image: string | null;
  reset_key: string;
  session_token: string;
  seen_trial_popup: boolean;
  show_tools_video: number;
  sr_user_type_id: string | number | null;
  totalcredits: number;
  updated_at: string | Date;
  user_role_id: number;
}
