import { ANNOUNCEMENTS_ACTIONS } from './action_types';

const INITIAL_STATE = {
  shouldAnnouncementModalOpen: false,
  shouldKeyboardShortCutModalOpen: false,
  selectedAnnouncementData: {},
};

const data = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ANNOUNCEMENTS_ACTIONS.ALL_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        selectedAnnouncementData: payload.selectedAnnouncementData,
      };
    case ANNOUNCEMENTS_ACTIONS.ALL_ANNOUNCEMENTS_FAILURE:
      return {
        ...state,
      };
    case ANNOUNCEMENTS_ACTIONS.OPEN_KEYBOARD_SHORTCUT_MODAL:
      return {
        ...state,
        shouldKeyboardShortCutModalOpen: payload,
      };
    default:
      return state;
  }
};

export default data;