import { UI_ACTIONS } from './action_types';
import { INITIAL_STATE } from '@/store/ui/state';
import pick from 'lodash/pick';

const reducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case UI_ACTIONS.TOGGLE_SECONDARY_PANEL_VISIBILITY:
      return {
        ...state,
        isSecondaryPanelVisible: payload,
      };
    case UI_ACTIONS.SET_SECONDARY_PANEL_WIDTH:
      return {
        ...state,
        secondaryPanelWidth: payload,
      };
    case UI_ACTIONS.TOGGLE_FORGOT_PASSWORD_MODAL_VISIBILITY:
      return {
        ...state,
        isForgotPasswordModalVisible: payload,
      };
    case UI_ACTIONS.TOGGLE_CHECKOUT_LAYOUT_BULK:
      return {
        ...state,
        isBulkCheckoutVisible: payload,
      };
    case UI_ACTIONS.TOGGLE_PAYMENT_FAILED_TOASTER:
      return {
        ...state,
        isFailedPaymentToasterVisible: payload,
      };
    case UI_ACTIONS.TOGGLE_CHECK_PAYMENT_STATUS:
      return {
        ...state,
        isPaymentStatusChecked: payload,
      };
    case UI_ACTIONS.VIEW_PROPERTY_DETAIL_BULK:
      return {
        ...state,
        showEditPropertyViewBulk: payload,
      };
    case UI_ACTIONS.GLOBAL_ASSETS:
      return {
        ...state,
        globalAsset: payload,
      };
    case UI_ACTIONS.TOGGLE_ORDERS_LIST_PANEL_VISIBILITY:
      return {
        ...state,
        isOrdersListPanelVisible: payload,
      };
    case UI_ACTIONS.TOGGLE_LAYER_LEFT_PANEL:
      return {
        ...state,
        isClickLeftPanel: payload,
      };
    case UI_ACTIONS.VISIBLE_LAYER_PANEL:
      return {
        ...state,
        isLayerPanel: payload,
      };
    case UI_ACTIONS.TOGGLE_ACTION_CENTER_CARD:
      return {
        ...state,
        isActionCenterCard: payload,
      };
    case UI_ACTIONS.OPEN_SHARE_PROPERTY_MODAL:
      return {
        ...state,
        isSharePropertyModalVisible: payload,
      };
    case UI_ACTIONS.CLOSE_SHARE_PROPERTY_MODAL:
      return {
        ...state,
        isSharePropertyModalVisible: payload,
      };
    case UI_ACTIONS.HIDE_NAVBAR_IF_UNAUTHORISED:
      return {
        ...state,
        isNavbarHidden: true,
      };
    case UI_ACTIONS.SHOW_VERTICES:
      return {
        ...state,
        showVertices: payload,
      };
    case UI_ACTIONS.SHOW_ALERT:
      return {
        ...state,
        alert: {
          visible: true,
          variant: payload.variant,
          content: pick(payload, ['title', 'message'])
        }
      };
    case UI_ACTIONS.CLOSE_ALERT:
      return {
        ...state,
        alert: {
          visible: false,
          content: null,
          variant: 'success'
        }
      };
    case UI_ACTIONS.SHOW_NOTIFICATION_BAR:
      return {
        ...state,
        notificationBar: {
          visible: true,
          type: payload.type,
          data: payload.data
        }
      }
    case UI_ACTIONS.HIDE_NOTIFICATION_BAR:
      return {
        ...state,
        notificationBar: {
          visible: false,
          type: null,
          data: null
        }
      }
    case UI_ACTIONS.TOGGLE_NEARMAP_DEBUG:
      return {
        ...state,
        isNearmapDebugEnabled: payload
      }
    case UI_ACTIONS.SHOW_HIDE_BASEMAP:
      return {
        ...state,
        isBasemapEnabled: payload
      }
    case UI_ACTIONS.SHOW_HIDE_PARCEL:
      return {
        ...state,
        isParcelEnabled: payload
      }
    case UI_ACTIONS.SHOW_HIDE_NEARMAP:
      return {
        ...state,
        isNearmapEnabled: payload
      }
    case UI_ACTIONS.SWTCH_BASEMAP_TYPE:
      return {
        ...state,
        selectedBasemap: payload
      }
    case UI_ACTIONS.SET_PROPERTY_HAS_NEARMAP:
      return {
        ...state,
        hasNearmap: payload
      }
    case UI_ACTIONS.SET_CUSTOMER_INFO_DIALOG_VISIBILITY:
      return {
        ...state,
        isCustomerInfoDialogVisible: payload
      }
    case UI_ACTIONS.SHOW_FULL_SCREEN_LOADER:
      return {
        ...state,
        fullScreenLoaderVisibility: {
          isVisible: payload.isVisible,
          message: payload.message,
        },
      }
    default:
      return state;
  }
};

export default reducer;
