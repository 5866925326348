import {
  GET_PAY_G_PREVIEW,
  PROCESS_PAY_G_INVOICE,
  GET_PAY_G_INVOICE,
  GET_SUBSCRIPTION_INVOICE,
} from '@/helpers/constants/APIEndpoints';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { replaceParams } from '@/helpers/utilities/linkUtils';
import {
  GetPayGPreviewApiRequestBody,
  GetPayGPreviewApiResponse,
  GetSubscriptionApiResponse,
  ProcessPayGInvoiceApiRequestBody,
  PayGInvoiceApiResponse,
  GetBoltPayGPreviewApiRequestBody,
  GetSubscriptionInvoiceApiResponse,
  ProcessBoltPayGInvoiceApiRequestBody,
  BoltPayGInvoiceApiResponse,
} from '../subscription/helpers/api-schema';
import { AxiosResponse } from 'axios';
import {
  GET_SUBSCRIPTION,
  REQUEST_ADMIN_TO_UPGRADE_SUBSCRIPTION,
  REQUEST_SUBSCRIPTION_PLAN,
  SUBSCRIPTION_REQUEST_UPDATE,
} from '../../helpers/constants/APIEndpoints';
import {
  RequestSubscriptionPlanApiRequest,
  UpdateSubscriptionRequestApiRequest,
} from '../../modules/subscription/api/types';
import { SubscriptionPlan } from '../subscription/helpers/enum';
import { Source } from '../../modules/subscription/storybook/types';

const axios = getAxiosInstance();

export const getSubscription = (): Promise<
  AxiosResponse<GetSubscriptionApiResponse>
> => {
  return axios.get<GetSubscriptionApiResponse>(GET_SUBSCRIPTION);
};

export const getPayGPreview = (
  data: GetPayGPreviewApiRequestBody | GetBoltPayGPreviewApiRequestBody
): Promise<AxiosResponse<GetPayGPreviewApiResponse>> => {
  return axios.post<GetPayGPreviewApiResponse>(GET_PAY_G_PREVIEW, data);
};

export const processPayGInvoice = (
  data: ProcessPayGInvoiceApiRequestBody | ProcessBoltPayGInvoiceApiRequestBody
): Promise<
  AxiosResponse<PayGInvoiceApiResponse | BoltPayGInvoiceApiResponse>
> => {
  return axios.post(PROCESS_PAY_G_INVOICE, data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export const getPayGInvoice = (
  id: string
): Promise<
  AxiosResponse<PayGInvoiceApiResponse | BoltPayGInvoiceApiResponse>
> => {
  return axios.get(replaceParams(GET_PAY_G_INVOICE, { ':id': id }));
};

export const getSubscriptionInvoice = (
  id: string
): Promise<AxiosResponse<GetSubscriptionInvoiceApiResponse>> => {
  return axios.get(replaceParams(GET_SUBSCRIPTION_INVOICE, { ':id': id }));
};

export const requestAdminToUpgradeSubscription = (credits: number) => {
  return axios.post(REQUEST_ADMIN_TO_UPGRADE_SUBSCRIPTION, { credits });
};

export const requestSubscriptionPlan = (
  plan: SubscriptionPlan,
  description: string
) => {
  const data: RequestSubscriptionPlanApiRequest = {
    planType: plan,
    description,
  };

  return axios.post(REQUEST_SUBSCRIPTION_PLAN, data);
};

export const updateSubscriptionRequest = (ctaContext: string) => {
  const data: UpdateSubscriptionRequestApiRequest = {
    ctaContext: ctaContext,
    source: Source.web,
  };

  return axios.post(SUBSCRIPTION_REQUEST_UPDATE, data);
};
