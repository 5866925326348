import { useCallback } from 'react';
import { ViewType } from "../../common/types";
import { OrderView } from "../../../components/pages/project/projectComponents/types";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { IStore } from '../../../store/types';
import { baseView, currentView, editableView } from '../../../store/order/actions';
import useRouterPlus from '../../router-plus/hooks/use-router-plus';
import { replaceParams } from '../../../helpers/utilities/linkUtils';
import { updateViewsByOrderId } from '../../../store/order/thunks';
import { showNotification } from '../../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../components/storybook/NotificationToast/types';
import { axiosInstance } from '../../../components/pages/feed/api';
import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import useRouterPlusRedirect from '../../router-plus/hooks/use-router-plus-redirect';
import { trackEvents } from '@/helpers/utilities';
import { CreateOrderEvt } from '../../../segment';
import { SWITCH_TO_STATIC_VIEW_SOURCE } from '../../../events/constants';

const useViewSelectionOrCreationUtil = () => {
  const dispatch = useDispatch();
  const currentViewData = useSelector<IStore, OrderView>((state) => state.order.currentViewData);
  const viewsList = useSelector<IStore, OrderView[]>((state) => state.order.orderViews);

  const { redirect } = useRouterPlus(
    'viewId',
    {
      onChange: (viewId, prevViewId) => {
        // Handle view change and update URL here
        const view = viewsList.find((view) => view.viewId === Number(viewId));
        if (view) {
          console.log(">>> running changeView 0");
          // changeView(view);
        }
      },
    },
    [viewsList?.length, currentViewData?.viewId]
  );

  const { redirect: redirectPlus } = useRouterPlusRedirect();

  const getLatestEstimationView = (view) => {
    if (!view) return null;

    const linkedEstimationViews = viewsList.filter(
      (v) => v.viewType === ViewType.ESTIMATION && v.linkedView === view.viewId
    );
    return linkedEstimationViews.length > 0
      ? linkedEstimationViews.reduce((prev, curr) =>
        prev.viewId > curr.viewId ? prev : curr
      )
      : null;
  };

  const getLinkedStaticView = (view: OrderView) => {
    if (!view) return null;

    return viewsList.find(
      (v) => v.viewId === view.linkedView
    );
  };

  /** CHANGE VIEW */
  const changeView = (view: OrderView): void => {
    trackEvents(CreateOrderEvt.ViewPanelViewChange)
    dispatch(currentView({
      viewId: view.viewId,
      isBaseView: view.isBaseView,
      name: view.name,
      orderId: view.orderId,
      isDefault: view.isDefault,
      isEditable: view.isEditable,
      viewType: view.viewType,
      linkedView: view.linkedView,
      isSIAssignmentLocked: view.isSIAssignmentLocked,
      isPricingLocked: view.isPricingLocked,
    }));
    dispatch(baseView(view.isBaseView));
    dispatch(editableView(view.isEditable));

    redirect(String(view.viewId), true);

    redirectPlus(
      { viewId: String(view.viewId) },
      {
        keepQueryParams: true,
        removeQueryParams: ['n', 'vId', 'fb', 'oid', 'sr'],
        mode: 'search',
      }
    );
  };

  const handleCreateNewView = (viewCategory: ViewType) => {

    const dynamicOrderViews = viewsList.filter((view: OrderView) =>
      [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(view.viewType)
    );

    const estimationViewNumbers: number[] = dynamicOrderViews
      .map(view => {
        const match = view.name.match(/Estimation View\s*(\d+)/i);
        return match ? parseInt(match[1], 10) : null;
      })
      .filter((number): number is number => number !== null);

    const highestNumber = estimationViewNumbers.length > 0 ? Math.max(...estimationViewNumbers) : 0;
    const newViewNumber = highestNumber + 1;

    const reqData = { name: `Estimation View ${newViewNumber}` };

    const url: string = viewCategory === ViewType.ESTIMATION
      ? API_ENDPOINTS.CLONE_ESTIMATION_VIEW
      : API_ENDPOINTS.CLONE_VIEW;

    const viewToBeCloned = currentViewData?.viewType === ViewType.STATIC ? currentViewData : getLinkedStaticView(currentViewData);

    axiosInstance
      .post(
        replaceParams(url, { ':viewId': viewToBeCloned?.viewId }),
        reqData,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      )
      .then(async (res) => {
        // createNewView(res.data, isSelectedBaseView);
        const newView = res.data;
        await dispatch(updateViewsByOrderId(newView.orderId));

        showNotification(
          NOTIFICATIONS_TYPES.SUCCESS,
          'New view created successfully'
        );

        if (newView.viewId) {
          redirect(String(newView.viewId));
          console.log(">>> running changeView 1");
          changeView(newView);
        }
      })
      .catch((error) => {
        if (error.errorCode === 'SR-BIZ-ERROR-22') {
          showNotification(NOTIFICATIONS_TYPES.ERROR, error.errorDesc);
        }
      });
  };

  const handleEstimationViewClick = useCallback(() => {
    if (!currentViewData) return;

    if (currentViewData.viewType === ViewType.STATIC) {
      // Case 1: You're on a static view
      const latestLinkedEstimationView = getLatestEstimationView(currentViewData);

      if (latestLinkedEstimationView) {
        console.log(">>> change to ", latestLinkedEstimationView)
        console.log(">>> running changeView 2")
        changeView(latestLinkedEstimationView);
      } else {
        // Case 3: You're on a static view with no linked estimation view
        console.log(">>> no linked estimation view found, create a new one")
        handleCreateNewView(ViewType.ESTIMATION);
      }
    } else if (currentViewData.viewType === ViewType.DYNAMIC) {
      // Case 2: You're on a dynamic view
      const linkedStaticView = getLinkedStaticView(currentViewData);

      if (linkedStaticView) {
        const linkedEstimationView = getLatestEstimationView(linkedStaticView);
        if (linkedEstimationView) {
          console.log(">>> change to ", linkedEstimationView)
          console.log(">>> running changeView 3")
          changeView(linkedEstimationView);
        } else {
          // Case 4: You're on a dynamic view whose static view is not linked to any estimation views
          // Call the necessary function(s) to create a new estimation view
          handleCreateNewView(ViewType.ESTIMATION);
        }
      }
    }
  }, [currentViewData, getLatestEstimationView, getLinkedStaticView, handleCreateNewView, changeView]);

  const handleStaticViewClick = useCallback(() => {
    if (!currentViewData) return;

    if ([ViewType.ESTIMATION, ViewType.DYNAMIC].includes(currentViewData.viewType)) {
      // Case 5: When you're on estimation tab and go to layer panel tab
      const linkedStaticView = getLinkedStaticView(currentViewData);

      if (linkedStaticView) {
        // Switch to the linked static view
        // Call the necessary function(s) to switch to the linked static view
        changeView(linkedStaticView);
      } else {
        // No linked static view found, create a new one
        handleCreateNewView(ViewType.STATIC);
      }
    }
  }, [currentViewData, getLinkedStaticView, handleCreateNewView, changeView]);

  return { handleEstimationViewClick, handleStaticViewClick, getLinkedStaticView };
};

export default useViewSelectionOrCreationUtil;