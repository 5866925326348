import React, { useState } from 'react';
import { Modal } from 'antd';
import CreateNewWorkspace from './CreateNewWorkspace';
import { CloseOutlined } from '@elements/icons/antd-icons';
import EmailVerifyForm from '@/components/containers/auth-form/signup/EmailVerifyForm';
import { API_STATUS } from '@/components/containers/auth-page/types';
import { useWorkspace } from '@/modules/workspace/hooks/useWorkspace';
import ProfileDropdown, { ProfileDropdownProps } from './index';

/** Profile Dropdown component with other modals and data */
const ProfileDropdownWrapper = (
  props: Pick<ProfileDropdownProps, 'isMapPage'>
) => {
  const { change } = useWorkspace();

  const [
    isCreateNewWorkspaceModalVisible,
    setIsCreateNewWorkspaceModalVisible,
  ] = useState<boolean>(false);

  const [isEmailVerifyModalVisible, setIsEmailVerifyModalVisible] =
    useState<boolean>(false);

  const handleToggleCreateWorkspaceModal = () => {
    setIsCreateNewWorkspaceModalVisible(!isCreateNewWorkspaceModalVisible);
  };

  const handleToggleEmailVerifyModal = (status?: keyof typeof API_STATUS) => {
    setIsEmailVerifyModalVisible(!isEmailVerifyModalVisible);

    if (status === API_STATUS.SUCCESS) {
      setIsCreateNewWorkspaceModalVisible(true);
    }
  };

  const handleCreateNewWorkspaceClose = (workspace: any) => {
    change({ title: workspace.title });
    setIsCreateNewWorkspaceModalVisible(false);
  };

  const handleEmailVerifyModalClose = () => handleToggleEmailVerifyModal();

  return (
    <>
      <ProfileDropdown
        onToggleEmailVerifyModal={handleToggleEmailVerifyModal}
        onAddWorkspace={handleToggleCreateWorkspaceModal}
        {...props}
      />

      <CreateNewWorkspace
        showModal={isCreateNewWorkspaceModalVisible}
        source={'ADD_WS_BTN'}
        // @ts-ignore
        handleClose={handleCreateNewWorkspaceClose}
      />

      <Modal
        visible={isEmailVerifyModalVisible}
        onCancel={handleEmailVerifyModalClose}
        footer={null}
        closable={true}
        closeIcon={<CloseOutlined />}
        width='450px'
        centered
        className='email-verify-wrapper'
      >
        <EmailVerifyForm
          handleToggleEmailVerifyModal={handleToggleEmailVerifyModal}
          showEmailVerifyModal={isEmailVerifyModalVisible}
        />
      </Modal>
    </>
  );
};

export default ProfileDropdownWrapper;
