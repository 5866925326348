// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Subtract6IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Subtract6Icon(props: Subtract6IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 9 7"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M0 6.907h6.87c1.09 0 1.975-.884 1.975-1.975A4.938 4.938 0 004.165 0C3.788 2.365 2.625 5.125 0 6.907z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Subtract6Icon;
/* prettier-ignore-end */
