export const PARCEL_TOOLS = {
  draw: 'draw',
  modify: 'modify',
  cut: 'cut',
};

export const bulkOrderParcelStatus = {
  draft: 'draft',
  confirmed: 'confirmed',
  skipped: 'skipped',
};

export const IMAGERY_DATA = {
  nearmap: 'NEARMAP',
  satellite: 'SATELLITE',
};
