export const ToolQuickTip = {
  select: [
    'Press and hold ctrl button to select multiple shapes in the same layer',
    'Hover on any shape to view its dimensions',
  ],
  polygon: [
    'Click on the map to start drawing a polygon.',
    'To finish the polygon, click on the first point to stop drawing.',
  ],
  point: ['Click anywhere on the map to drop a point.'],
  line: [
    'Click on map to start drawing a line.',
    'Double click on last point to finish drawing',
  ],
  modify: [
    'Drag any vertex to move its location.',
    'Click any where on the line segment to create a new vertex.',
  ],
  path: [
    'Click on map to start drawing a line.',
    'Double click on last point to finish the path.',
  ],
  cut: ['Draw an area to remove from the selected layer.'],
  slice: ['Draw a line over shapes to split them into sub parts.'],
  doughnut: [
    'Draw an area to completely split overlapping areas in the selected layer and create multiple smaller areas.',
  ],
  measuretool: ['Start drawing on the map to measure distance or an area.'],
  drag: [
    'Click on any shape in the selected layer to activate it. Then drag to move it anywhere on the map.',
  ],
  new_note: ['Click anywhere on the map to drop a note.'],
  draw: [''],
  split: [''],
  edit: ['']
};

// for remaining tips
// https://siterecon.notion.site/quick-tips-a60d7b59c92f4149aada67ed71aafdfc
