import { Button, Checkbox, Modal, Row, Select, Switch, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Text from '../../../../../../../elements/text/Text';
import './style.less';
import { DownOutlined, InfoCircleOutlined, } from '@/components/elements/icons/antd-icons';
import AttachFileIcon from '../../../assets/Vector (2).svg';
import ListPeople from './components/ListPeople';
import ShareAnywayModal from './components/ShareAnywayModal';
import { isMobileScreen } from '../../../../../utils/properties.utils.js';
import { useSelector } from 'react-redux';

const { Option } = Select;

const AddPeople = () => {
  const history = useHistory();
  const dismissModal = useCallback(() => history.goBack(), [history]);
  const {
    listOfPeopleOfOrganizations,
    orderSharedWith,
    orderStatusForPeople,
    orderViews,
  } = useSelector((state) => state.order);
  const [inviteEmails, setInviteEmails] = useState();
  const [showShareAnywayModal, setShowShareAnywayModal] = useState(false);
  const { viewWidth, info } = useSelector((state) => state.user);

  const [selectedViews, setSelectedViews] = useState([]);

  const onHideShareAnywayModal = useCallback(
    () => setShowShareAnywayModal((prevState) => !prevState),
    []
  );

  const handleMultiSelectChange = useCallback((e) => {
    setInviteEmails(e.map((val) => JSON.parse(val)));
  }, []);

  const handelViewSelect = useCallback((view) => {
    setSelectedViews((prevState) => {
      if (prevState.includes(view)) {
        return prevState.filter((value) => value?.name !== view?.name);
      }

      return [...prevState, view];
    });
  }, []);

  return (
    <Modal
      visible={true}
      //   onOk={onArchiveConfirm}
      onCancel={dismissModal}
      closable={dismissModal}
      centered
      className='addPeopleModal'
      width={680}
      footer={[
        <Row
          align='middle'
          style={{ cursor: 'pointer' }}
          className='px-2 py-1'
          justify={isMobileScreen(viewWidth) ? 'end' : 'start'}
        >
          <img className='mr-1' src={AttachFileIcon} alt='AttachFileIcon' />
          <Text type='p29' color='dark-blue'>
            Copy link
          </Text>
        </Row>,
      ]}
    >
      <Row style={{ flexDirection: 'column' }}>
        <Text type='h3' color='dark-gray'>
          Add People
        </Text>

        <Row wrap={false} align='middle' className='mt-4 mb-4'>
          <Text type='p29' color='dark-gray' className='mr-2'>
            Share with All Organization Users
          </Text>
          <InfoCircleOutlined className='mr-2' />
          <Switch size='small' />
        </Row>

        <Text type='p29' color='light-gray' className='mb-4'>
          Share the Property data on email
        </Text>

        <Select
          className='mb-4 addPeopleModal__select'
          placeholder='Select Property View'
          style={{ width: 180 }}
          optionLabelProp='label'
          value={
            selectedViews.length > 1
              ? `${selectedViews[0]?.name}, +${selectedViews.length - 1}`
              : selectedViews.map((val) => val?.name)
          }
        >
          {orderViews &&
            orderViews.map((value, i) => (
              <Option key={i} value={value?.name}>
                <Tooltip title={value?.name} placement='left'>
                  <Checkbox onChange={() => handelViewSelect(value)}>
                    {value?.name}
                  </Checkbox>
                </Tooltip>
              </Option>
            ))}
        </Select>

        <Row
          wrap={false}
          className='w-100 mb-4'
          justify='space-between'
          align='middle'
        >
          <Select
            mode='multiple'
            showArrow
            className='mr-2 addPeopleModal__select view'
            placeholder='Invite Someone'
            style={{ flex: 1 }}
            optionLabelProp='label'
            suffixIcon={
              <Row wrap={false} align='middle' justify='space-around'>
                View <DownOutlined />
              </Row>
            }
            onChange={handleMultiSelectChange}
          >
            {listOfPeopleOfOrganizations.map((value, i) => (
              <Option
                key={i}
                value={JSON.stringify(value)}
                label={value?.name}
              >{`${value?.name} ${
                value?.id === info?.id ? '(you)' : ''
              }`}</Option>
            ))}
          </Select>
          {!isMobileScreen(viewWidth) && (
            <Button
              type='primary'
              className={
                inviteEmails.length > 0
                  ? 'save-button '
                  : 'save-button disabled'
              }
              onClick={onHideShareAnywayModal}
            >
              Share
            </Button>
          )}
        </Row>
        {isMobileScreen(viewWidth) && (
          <Row className='mb-3'>
            <Button
              type='primary'
              className={
                inviteEmails.length > 0
                  ? 'save-button '
                  : 'save-button disabled'
              }
              onClick={onHideShareAnywayModal}
            >
              Share
            </Button>
          </Row>
        )}

        <Text type='p19' color='dark-gray' className='mb-4'>
          Shared with
        </Text>

        <Row style={{ flexDirection: 'column', width: '100%' }}>
          {inviteEmails.map((people, i) => (
            <ListPeople
              key={i}
              showDropDown={people?.id !== info?.id}
              name={people?.name}
              me={people?.id === info?.id}
              role={people?.role}
              email={people?.email}
              guest={people?.guest}
            />
          ))}

          {/* <ListPeople showDropDown />
          <ListPeople showDropDown guest /> */}
        </Row>
      </Row>
      <ShareAnywayModal
        showModal={showShareAnywayModal}
        hideModal={onHideShareAnywayModal}
      />
    </Modal>
  );
};

export default AddPeople;
