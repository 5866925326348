// ##### Full Name Logo #####
import { Col, Image, Row } from 'antd';
import { useSelector } from 'react-redux';
import { ReactComponent as CompanyLogoChristmas } from '../../../assets/logo-christmas.svg';
import { ReactComponent as CompanyLogo } from '../../../assets/Siterecon Logo Complete.svg';
import { ReactComponent as PipeIconLong } from '../../../assets/nvabar/pipe_icon_46px.svg';
import { DEFAULT_LOGO, DEFAULT_LOGO_CHRISTMAS, } from '../../../helpers/constants/BrandLogoTypes';

const USE_DEFAULT_LOGO_CHRISTMAS = false;

const Styles = {
  ChristmasAuth: {
    height: 72,
    width: 132,
  },
  ChristmasHeader: {
    height: '100%',
    width: 112,
    marginTop: -1,
  },
};

const BrandLogo = (props) => {
  const { brandLogoClasses, type, showPartnerLogo = true } = props;
  const configurations = useSelector((state) => state.configurations);

  return (
    <Row align={'middle'}>
      <Col span={configurations?.orgLogo ? 11 : 24} className='p-0'>
        {type === DEFAULT_LOGO && (
          <CompanyLogo
            className={brandLogoClasses}
            style={{
              position: 'relative',
            }}
          />
        )}
        {type === DEFAULT_LOGO_CHRISTMAS && (
          <CompanyLogoChristmas
            className={brandLogoClasses}
            style={
              brandLogoClasses ? Styles.ChristmasHeader : Styles.ChristmasAuth
            }
          />
        )}
      </Col>
      {showPartnerLogo && configurations?.isChannelPartner ? (
        <>
          <Col
            span={1}
            className='p-0'
            style={{ position: 'relative', left: '50px' }}
          >
            <PipeIconLong
              style={{ position: 'relative', right: '6.5px', top: '2px' }}
            />
          </Col>
          <Col
            span={12}
            className='p-0'
            style={{ position: 'relative', left: '50px' }}
          >
            <Image
              preview={false}
              className={brandLogoClasses}
              src={configurations?.orgLogo}
              style={{ width: '90px' }}
            />
          </Col>
        </>
      ) : null}
    </Row>
  );
};

BrandLogo.defaultProps = {
  brandLogoClasses: '',
  type: USE_DEFAULT_LOGO_CHRISTMAS ? DEFAULT_LOGO_CHRISTMAS : DEFAULT_LOGO,
};

export default BrandLogo;
