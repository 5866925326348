import UserInfo from '@/components/elements/user-info';
import { Select } from 'antd';
import { UserObject } from '../types/Notes';

const { Option } = Select;

export const filterByEmail = (
  userEmailsAndNames: UserObject[],
  emails: Array<string>
) => {
  return userEmailsAndNames
    .filter(
      (user: UserObject) =>
        user.emailAddress && !emails.includes(user.emailAddress)
    )
    .map((user: UserObject, index: number) => (
      <>
        {user.deactivatedUser ? (
          <>
            {user.emailAddress && (
              <Option
                key={index}
                value={user.emailAddress}
                label={user.userName}
              >
                <UserInfo user={user} />
                <span style={{ fontSize: '10px', fontStyle: 'italic' }}>
                  (Deactivated)
                </span>
              </Option>
            )}
          </>
        ) : (
          <>
            {user.emailAddress && (
              <Option
                key={index}
                value={user.emailAddress}
                label={user.userName}
              >
                <UserInfo user={user} />
              </Option>
            )}
          </>
        )}
      </>
    ));
};
