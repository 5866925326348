import { IUserData } from './types';
import { UserRoleId } from '@/modules/common/types';

export const getEmailAndNameFromUsers = (users: IUserData[]) => {
  return users
    ?.filter((user: IUserData) => user.role_id !== 5)
    .map((user: IUserData) => ({
      email: user.em,
      username: user.fn,
      is_user_deactivated: user.is_user_deactivated,
    }));
};

export const getEmailsFromUsers = (users: IUserData[]) => {
  return users
    ?.filter((user: IUserData) => user.role_id !== UserRoleId.Guest)
    .map((user: IUserData) => user.em);
};
