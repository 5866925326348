// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon88IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon88Icon(props: Icon88IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 8 8"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M1.92 8c-.328 0-.578-.083-.748-.249-.17-.163-.255-.41-.255-.738V4.167c0-.327.085-.572.255-.735.17-.163.42-.245.749-.245h4.052c.33 0 .579.082.75.245.17.163.255.408.255.735v2.846c0 .329-.086.575-.256.738-.17.166-.42.249-.749.249H1.921zm.122-.723h3.81c.09 0 .159-.025.208-.076.049-.048.073-.12.073-.217V4.2c0-.097-.024-.169-.073-.217a.284.284 0 00-.208-.072h-3.81a.293.293 0 00-.208.072c-.049.048-.073.12-.073.217v2.785c0 .097.024.169.073.217a.285.285 0 00.208.076zM1.71 3.524V2.248c0-.495.104-.909.311-1.24.211-.335.487-.587.827-.755.34-.169.707-.253 1.1-.253.393 0 .76.084 1.1.253.34.168.614.42.822.755.21.331.316.745.316 1.24v1.276h-.81V2.176c0-.313-.066-.577-.198-.791a1.29 1.29 0 00-.52-.494 1.518 1.518 0 00-.71-.168 1.52 1.52 0 00-.71.168 1.29 1.29 0 00-.52.494c-.13.214-.194.478-.194.79v1.35H1.71z"
        }
        fill={"#AAA"}
      ></path>
    </svg>
  );
}

export default Icon88Icon;
/* prettier-ignore-end */
