import { Store } from '@/store';
import {
  setPayAsYouGoModal,
  setSubscriptionModal,
} from '@/store/subscription/actions';
import {
  ShowPayAsYouGoFunction,
  ShowPaywallFunction,
  SubscriptionFeatureData,
} from '@/modules/subscription/types';
import { SubscriptionDataApiResponse } from '@/modules/subscription/api';
import { SubscriptionPlan } from '@/components/subscription/helpers/enum';

/** List of all the plans in Ascending Level */
export const PLAN_LEVELS = [
  SubscriptionPlan.ESSENTIAL,
  SubscriptionPlan.PROFESSIONAL,
  SubscriptionPlan.TEAMS,
  SubscriptionPlan.ENTERPRISE,
];

/**
 * A function which shows tha pay-wall modal.
 * Created for the purpose to use this outside of react components/hooks, as "useSubscription" will not be accessible there.
 *
 * Don't use this where you can access showPaywall directly with "useSubscription" hook.
 **/
export const showPaywall: ShowPaywallFunction = (feature, data) => {
  Store.dispatch(setSubscriptionModal({ visible: true, feature, ...data }));
};

/**
 * A function which shows tha pay-as-you-go modal.
 * Created for the purpose to use this outside of react components/hooks, as "useSubscription" will not be accessible there.
 *
 * Don't use this where you can access showPayAsYouGo directly with "useSubscription" hook.
 **/
export const showPayAsYouGo: ShowPayAsYouGoFunction = (order) => {
  Store.dispatch(setPayAsYouGoModal({ visible: true, order }));
};

/**
 * Api helper function which transforms API response and merged "features" and "specialFeatures" to easily determine if features are enabled or not for logged-in user (organization)
 */
export const transformFeatures = ({
  subscriptionFeatures,
  specialFeatures,
}: SubscriptionDataApiResponse) => {
  return subscriptionFeatures.map((feature) => {
    const extended = specialFeatures.find(
      (specialFeature) => specialFeature.featureName === feature.featureName
    );

    if (!extended) return feature;
    return { ...feature, ...extended };
  });
};

/**
 * A helper function to determine if given feature with SubscriptionFeatureData is enabled or not ?
 */
export const isEnabled = (
  feature: SubscriptionFeatureData,
  currentPlan: SubscriptionPlan
) => {
  if (typeof feature.isEnabled !== 'undefined') {
    return feature.isEnabled;
  }
  return (
    PLAN_LEVELS.indexOf(feature.subscriptionPlan) <=
    PLAN_LEVELS.indexOf(currentPlan)
  );
};
