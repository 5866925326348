import axios from 'axios';
import { getToken } from '@/helpers/auth';
import { BASE_URL } from '@/helpers/constants';
import { Targets } from './Targets';
import { trackEvents } from '@/helpers/utilities';
import { _get } from '@/helpers/utilities/lodashUtils';
import { NotifyError } from '../notification-utils';
import { HttpStatus, SiteReconError } from '@/modules/common/types';
import { showPaywall } from '@/modules/subscription/helpers';
import { replaceParams } from './linkUtils';

const API_THRESHOLD = 17000;
let trackDetailsObj = {};

//Track slow apis
export const cancelTokenSource = axios.CancelToken.source();

const trackSlowApis = (duration, response, trackDetailsObj) => {
  if (duration > API_THRESHOLD) {
    try {
      trackEvents(
        'api_response__time',
        {
          duration,
          url: response.config.url,
          organization: trackDetailsObj.title,
          email: trackDetailsObj.email,
        },
        Targets.SEGMENT
      );
    } catch (error) {
      console.error(error);
    }
  }
};

const getEventName = (status = 0) => {
  let eventName = 'api_error';
  if (status >= 400 && status < 500) {
    eventName = 'api_failures_status_errors';
  } else if (status >= 500 && status < 1000) {
    eventName = 'api_failures_server_errors';
  }
  return eventName;
};

const trackApiFailures = (response, trackDetailsObj) => {
  if (response.status >= 400 && response.status < 1000) {
    const eventName = getEventName(response.status);
    try {
      trackEvents(
        eventName,
        {
          status: response.status,
          url: response.config.url,
          organization: trackDetailsObj.title,
          email: trackDetailsObj.email,
        },
        Targets.SEGMENT
      );
    } catch (error) {
      console.error(error);
    }
  }
};

const calculateDuration = (startTime, endTime) => {
  return endTime - startTime;
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  onUploadProgress: (progressEvent) => {
    console.log(progressEvent);
  },
  cancelToken: cancelTokenSource.token,
});

axiosInstance.defaults.headers['Accept'] = '*/*';

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      const token = getToken();
      config.metadata = { startTime: new Date() };
      config.cancelToken = cancelTokenSource.token; //it will update cancelToken on every call

      if (getShareMapId()) {
        config.headers['shareid'] = getShareMapId();
      } else if (_get(config, 'Content-Type')) {
        config.headers['Content-Type'] = _get(config, 'Content-Type');
        config.headers['Authorization'] = 'Token ' + token || null;
      } else {
        config.headers['Authorization'] = 'Token ' + token || null;
      }

      if (config.headers['Error-Handling']) {
        delete config.headers['Authorization'];
        delete config.headers['Error-Handling'];
        delete config.headers['Accept'];
      }

      if (!token || token.length === 0) {
        delete config.headers['Authorization'];
      }
      return config;
    } catch (error) {
      console.error(error);
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    try {
      response.config.metadata.endTime = new Date();
      const duration = calculateDuration(
        response.config.metadata.startTime,
        response.config.metadata.endTime
      );
      response.duration = duration;
      trackSlowApis(duration, response, trackDetailsObj);
      trackApiFailures(response, trackDetailsObj);
    } catch (error) {}
    return response;
  },
  function (error) {
    try {
      /**  If API error is related to subscription, we need to show Pay-Wall with custom message  */
      if (error?.response) {
        if (
          (error.response.status === HttpStatus.BadRequest &&
            error.response.data?.errorCode ===
              SiteReconError.SubscriptionRequired) ||
          /** TODO: Remove the following condition once, Guest Limit error is updated from the backend */
          error.response.data?.errorCode === 'SR-BIZ-ERR-005'
        ) {
          if (error?.response.config.url !== '/api/fastrack/v1/order') {
            showPaywall('api_paywall', {
              title: error.response.data?.errorDesc,
            });
          }
        }
      }

      error.config.metadata.endTime = new Date();
      const duration = calculateDuration(
        error.config.metadata.startTime,
        error.config.metadata.endTime
      );
      error.duration = duration;
      trackSlowApis(duration, error.response, trackDetailsObj);
      trackApiFailures(error.response, trackDetailsObj);
    } catch (error) {}
    const errors = handleError(error);
    return Promise.reject(errors);
  }
);

export const getAxiosInstance = () => {
  return axiosInstance;
};

export const setTrackDetails = (trackObject) => {
  trackDetailsObj = { ...trackObject };
};

const handleError = (error) => {
  let errors = error;
  switch (true) {
    case _get(error, 'response.status') === 400:
      // if (_get(error, 'response.data.errorDesc')) {
      //   NotifyError(_get(error, 'response.data.errorDesc'));
      // }
      errors = _get(error, 'response.data');
      break;
    case _get(error, 'response.status') === 401:
      // NotifyError(_get(error, 'response.data.errorDesc'));
      errors = _get(error, 'response.data');
      break;
    case _get(error, 'response.status') === false:
      NotifyError('Network Error. Please check your internet.');
      errors = { message: 'Network Error. Please check your internet.' };
      break;
    case _get(error, 'response.status') === 500:
      NotifyError('Something went wrong, try again later');
      break;
    default:
      errors = _get(error, 'response.data');
  }
  return errors;
};

export const getShareMapId = () => {
  let url = window.location.pathname;
  const orderHash = url.split('share_id/')[1];
  if (orderHash) {
    const shareMapId = orderHash.substring(0, orderHash.indexOf('/'));
    if (shareMapId.length === 0) {
      return orderHash;
    }
    if (shareMapId) {
      return shareMapId;
    }
  }
};

export const fetchPostRequest = async (url = '', data = {}) => {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + getToken(),
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
};

export const handleCancelRequest = () => {
  cancelTokenSource.cancel('Request was canceled');
};

export const getCancelToken = () => {
  return axios.CancelToken;
};

/** Returns error from Axios Error if exits or returns default Error */
export const getError = (error = {}) => {
  return capitalize(
    error.response?.data?.errorDesc ||
      error.response?.data?.message ||
      error.message ||
      error.msg ||
      'Something went wrong!'
  );
};

export const displayError = (error) => {
  NotifyError(getError(error));
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const catchError = (error) => {
  console.error(error);
};

export const onExportExcel = (organizationId, apiEndpoint) => {
  getAxiosInstance()
    .get(
      replaceParams(apiEndpoint, {
        ':org_id': organizationId,
      }),
      {
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        responseType: 'arraybuffer',
      }
    )
    .then((result) => {
      const blob = new Blob([result.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const URL = window.URL || window.webkitURL;
      const downloadUrl = URL.createObjectURL(blob);
      document.location = downloadUrl;
    })
    .catch((error) => {
      console.error('error===>', error);
    });
};
