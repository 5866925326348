import { Button, Popover, Space, Tooltip } from 'antd';
import FeatureSubscriptionGuard from '../../../../../modules/subscription/guards/base/FeatureSubscriptionGuard';
import { findLayerStatus, getViewInfo, trackEvents } from '@/helpers/utilities';
import ExportIconSvg from '@/assets/images/ExportIcon.svg';
import React, { useEffect, useState } from 'react';
import { SubscriptionChip } from '@/modules/subscription/components/Chips';
import { SubscriptionFeature } from '@/modules/subscription/types';
import './style.less';
import BetaReleaseGuard from '@/modules/common/guards/BetaRelease';
import { BetaFeature } from '@/modules/common/types';
import ExportAspire from './ExportAspire';
import { ViewType } from '../../../../../modules/common/types';
import { useWidgetBarTabContext } from '../../../../../modules/property/hooks/useWidgetBar';
import useViewSelectionOrCreationUtil from '../../../../../modules/property/hooks/useViewSelectionOrCreationUtil';
import { WidgetBarTabs } from '../../../../types';

interface ExportToolProps {
  available: boolean;
  editing: boolean;
  reduxView: any;
  currentViewData: any;
  layerList: any;
  selectDeselectFeature: any;
  onExportExcel: any;
  kmlFromNav: any;
  isDownloaded: boolean;
}

const Divider = () => {
  return (
    <div
      style={{
        borderTop: '0.1px solid rgb(153 153 153 / 30%)',
      }}
    />
  );
};

const ExportTool = ({
  available,
  editing,
  reduxView,
  currentViewData,
  layerList,
  selectDeselectFeature,
  onExportExcel,
  kmlFromNav,
  isDownloaded,
}: ExportToolProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showExportOptions, setShowExportOptions] = useState<boolean>(false);
  const [showExportAspireModal, setShowExportAspireModal] =
    useState<boolean>(false);

  // const { handleWidgetBarTabChange } = useWidgetBarTabContext();
  // const { handleStaticViewClick } = useViewSelectionOrCreationUtil();

  useEffect(() => {
    setLoading(!isDownloaded);
  }, [isDownloaded]);

  if (!available) {
    return (
      <div style={{ cursor: 'pointer', display: 'flex', height: '16px' }}>
        <Tooltip
          title={
            editing
              ? 'Editing tools are currently active'
              : 'This option is available after the property is approved'
          }
        >
          <div
            style={{ cursor: 'pointer', display: 'flex' }}
            className='tool--navbar'
          >
            <img alt='Export' src={ExportIconSvg} />
          </div>
        </Tooltip>
      </div>
    );
  }

  const switchToLinkedViewHandler = () => {
    // handleStaticViewClick();
    // handleWidgetBarTabChange(WidgetBarTabs.LAYERS);
  };

  const exportExcelHandler = () => {
    if (currentViewData.viewType !== ViewType.STATIC) {
      switchToLinkedViewHandler();
    } else {
      setLoading(true);
      onExportExcel(getViewInfo(currentViewData, reduxView).viewId);
      setTimeout(() => setShowExportOptions(false), 1000);
    }
  };

  const exportKmlHandler = () => {
    if (currentViewData.viewType !== ViewType.STATIC) {
      switchToLinkedViewHandler();
    } else {
      trackEvents('navbar__kml-export', {
        orderId: currentViewData?.orderId,
        viewId: currentViewData?.viewId,
        isBaseView: currentViewData.isBaseView,
        layerCount: layerList.length,
        layerList: findLayerStatus(selectDeselectFeature),
      });
      kmlFromNav(true);
      setTimeout(() => setShowExportOptions(false), 1000);
    }
  };

  return (
    <>
      <Tooltip title='Export'>
        <Popover
          trigger='click'
          placement='bottom'
          overlayClassName='tool--export-popover'
          visible={showExportOptions}
          onVisibleChange={(visible) => setShowExportOptions(visible)}
          content={
            <div>
              {reduxView.length !== 0 && (
                <div>
                  <FeatureSubscriptionGuard
                    feature={SubscriptionFeature.ExportExcel}
                    modal={{
                      title: 'Want to export the data of this property?',
                    }}
                  >
                    <div className='export-menu-item'>
                      <Button
                        type='link'
                        block
                        onClick={exportExcelHandler}
                        loading={loading}
                      >
                        <Space>
                          Export Excel (xlsx)
                          <SubscriptionChip
                            feature={SubscriptionFeature.ExportExcel}
                            height={12}
                          />
                        </Space>
                      </Button>
                    </div>
                  </FeatureSubscriptionGuard>

                  <Divider />

                  <BetaReleaseGuard feature={BetaFeature.ExportKml}>
                    <FeatureSubscriptionGuard
                      feature={SubscriptionFeature.ExportKml}
                      modal={{
                        title: 'Want to export the data of this property?',
                      }}
                    >
                      <div className='export-menu-item'>
                        <Button type='link' block onClick={exportKmlHandler}>
                          Export KML Files
                          <SubscriptionChip
                            feature={SubscriptionFeature.ExportKml}
                            height={12}
                          />
                        </Button>
                      </div>
                    </FeatureSubscriptionGuard>
                  </BetaReleaseGuard>

                  {/* <Divider />
                  <div className='export-menu-item'>
                    <Button
                      type='link'
                      block
                      onClick={() => {
                        setShowExportAspireModal(true);
                        setShowExportOptions(false);
                      }}
                    >
                      Export to 3rd Party Apps
                      <SubscriptionChip
                        feature={SubscriptionFeature.ExportAspire}
                        height={12}
                      />
                    </Button>
                  </div> */}
                </div>
              )}
            </div>
          }
        >
          <div
            style={{ cursor: 'pointer', display: 'flex' }}
            className='tool--navbar'
          >
            <img
              alt='Export'
              src={ExportIconSvg}
              onClick={() => setShowExportOptions(true)}
            />
          </div>
        </Popover>
      </Tooltip>
      {showExportAspireModal && (
        <ExportAspire
          showExportAspireModal={showExportAspireModal}
          setShowExportAspireModal={setShowExportAspireModal}
        />
      )}
    </>
  );
};

export default ExportTool;
