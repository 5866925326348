import React, { useContext, useEffect } from 'react';
import { Box } from '@/modules/common/elements';
import { MapControlButton } from '@siterecon/sr-component/dist/sr-component-library';
import Zoom from './Zoom';
import CompassMapAction from '../../projectComponents/MapActions/CompassMapAction';
import MapContext from '@/components/pages/project/MapContext';
import { setSelectedNearmap } from '@/store/order/actions';
import {
  showHideBasemap,
  showHideNearmap,
  switchBasemap,
} from '@/store/ui/actions';
import {
  rotateMapBy90,
  toggleBaseLayerVisibility,
  toggleBasemap,
  toggleNearmap,
} from '@/helpers/mapUtils/mapInit';
import { ObliqueType } from '../../../../containers/nearmap-oblique-selection-dd/types';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../../store/types';
import { OrderNearmap } from '../../../../../store/order/state';
import './style.less';
import { trackEvents } from '../../../../../helpers/utilities';
import { MapControlsEvt } from '../../../../../segment';
import { useElementPositioningContext } from '../../../../../Contexts/ElementPositioningContext';
import { ElementName } from '../../../../../modules/property/types/element-position-visibility.types';

export enum MapViewType {
  SATELLITE = 'satellite',
  STREET = 'street',
}

interface MapControlsProps {
  onResetToParcel?: () => void;
  isAddressConfirmation?: boolean;
  hasParcel?: boolean;
  mapRef?: any;
}

const MapControls = ({
  onResetToParcel,
  isAddressConfirmation = false,
  hasParcel = true,
  mapRef,
}: MapControlsProps) => {
  const dispatch = useDispatch();
  const contextMapRef = useContext(MapContext);
  const olMapRef = mapRef ?? contextMapRef;

  const selectedNearmap = useSelector<IStore, OrderNearmap | null>(
    (state) => state.order.selectedNearmap
  );
  const hasNearmap = useSelector<IStore, Boolean>(
    (state) => state.ui.hasNearmap
  );
  const isBasemapEnabled = useSelector<IStore, Boolean>(
    (state) => state.ui.isBasemapEnabled
  );
  const isNearmapEnabled = useSelector<IStore, Boolean>(
    (state) => state.ui.isNearmapEnabled
  );
  const selectedBasemap = useSelector<IStore, Boolean>(
    (state) => state.ui.selectedBasemap
  );

  const { elementStates } = useElementPositioningContext();
  const isMapControlsVisible = elementStates[ElementName.MapControlsPosition].visible;

  useEffect(() => {
    trackEvents(MapControlsEvt.MapControllerVisible, {
      isAddressConfirmationPage: isAddressConfirmation,
    });
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll('.ol-scale-step-text');
    elements.forEach((element) => {
      element.style.color = isBasemapEnabled ? '#fff' : '#666';
    });
  }, [isBasemapEnabled]);

  const getRotatedObliqueType = (type: ObliqueType): ObliqueType => {
    switch (type) {
      case ObliqueType.NORTH:
        return ObliqueType.WEST;

      case ObliqueType.WEST:
        return ObliqueType.SOUTH;

      case ObliqueType.SOUTH:
        return ObliqueType.EAST;

      case ObliqueType.EAST:
        return ObliqueType.NORTH;
    }

    return ObliqueType.VERT;
  };

  const handleRotate = () => {
    trackEvents(MapControlsEvt.Rotate90Clicked, {
      angle: selectedNearmap?.type ?? ObliqueType.VERT,
    });
    if (!selectedNearmap || selectedNearmap.type === ObliqueType.VERT) {
      rotateMapBy90(olMapRef);
      return;
    }

    /** When selected nearmap is of oblique type, we'll just change the oblique type on rotation and it would take care of the rotation also */
    dispatch(
      setSelectedNearmap({
        ...selectedNearmap,
        type: getRotatedObliqueType(selectedNearmap.type),
      })
    );
  };

  const handleResetToParcel = () => {
    trackEvents(MapControlsEvt.ResetToParcelClicked);
    if (onResetToParcel) {
      onResetToParcel();
    }
  };

  const handleSatelliteClick = () => {
    trackEvents(MapControlsEvt.SatelliteSelected);
    dispatch(switchBasemap(MapViewType.SATELLITE));
    toggleBaseLayerVisibility(MapViewType.SATELLITE, olMapRef);
    toggleBasemap(olMapRef, true, MapViewType.SATELLITE);
  };

  const handleStreetClick = () => {
    trackEvents(MapControlsEvt.StreetSelected);
    dispatch(switchBasemap(MapViewType.STREET));
    toggleBaseLayerVisibility(MapViewType.STREET, olMapRef);
    toggleBasemap(olMapRef, true, MapViewType.STREET);
  };

  const handleBaseMapToggle = (checked: boolean) => {
    trackEvents(MapControlsEvt.BaseMapToggleClicked);
    dispatch(showHideBasemap(checked));
    toggleBasemap(olMapRef, checked, selectedBasemap);
  };

  const handleNearMapToggle = (checked: boolean) => {
    trackEvents(MapControlsEvt.NearmapToggleClicked);
    dispatch(showHideNearmap(checked));
    toggleNearmap(olMapRef, checked);
  };
  return (
    <Box>
      {isMapControlsVisible && (<Box
        flex
        direction='column'
        align='middle'
        style={{ gap: 16, right: '24px' }}
        className='map-controls'
        id='map-controls'
      >
        <CompassMapAction darkMode={!isBasemapEnabled} />

        <Zoom
          mapRef={mapRef}
          darkMode={!isBasemapEnabled}
        />

        <MapControlButton
          onRotate90={handleRotate}
          onResetToParcel={handleResetToParcel}
          onBaseMapToggle={handleBaseMapToggle}
          onNearmapToggle={handleNearMapToggle}
          onSatelliteClick={handleSatelliteClick}
          onStreetClick={handleStreetClick}
          isNearmapAvailable={hasNearmap}
          isNearmapChecked={isNearmapEnabled}
          isBaseMapChecked={isBasemapEnabled}
          isAddressConfirmationPage={isAddressConfirmation}
          selectedBaseMap={selectedBasemap}
          isResetToParcelDisabled={!hasParcel}
        />
      </Box>)}
    </Box>
  );
};

export default MapControls;
