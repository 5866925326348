import { Row } from 'antd';

const OrLine = (props) => {
    return (<Row align='middle' className='w-100' justify='center' {...props} >
        <hr className='or-line or-line-left' style={{ marginRight: "0.8rem" }} />
        <span>OR</span>
        <hr className='or-line or-line-right' style={{ marginLeft: "0.8rem" }} />
    </Row>)
}

export default OrLine;