import { SingleComponent } from '../api/types';
import { clickLayerHighlight } from '@/helpers/mapUtils/featuresUtils';
import { findLayerById } from '@/helpers/utilities/index';
import MapContext from '@/components/pages/project/MapContext';
import { useEstimationViewContext } from '../context/EstimationView/context';
import { useSelector } from 'react-redux';
import { IStore } from '../../../store/types';
import OlMap from 'ol/Map';
import { useContext } from 'react';
import {
  highlightedComponents,
  setHighlightedComponents,
} from './useOnFeatureClick';

/** Use this hook for highlight/un-highlight functionality from LayerPanel */
export const useComponentHighlight = () => {
  const mapRef: OlMap = useContext(MapContext);
  const { actionCenterData } = useEstimationViewContext();

  const layerList = useSelector<IStore>((state) => state.order.layerList);

  const featureList = useSelector<IStore>(
    (state) => state.order.featureListInfo?.data ?? []
  );

  const highlightComponent = (
    component: SingleComponent,
    highlight: boolean = true
  ) => {
    const layerId = component.layerId;
    if (!layerId) return;

    const sourceLayerOfComponent = findLayerById(layerId, mapRef);

    if (!sourceLayerOfComponent) return;

    clickLayerHighlight(
      sourceLayerOfComponent,
      component.componentId,
      highlight,
      layerList,
      featureList
    );
  };

  const highlight = (components: SingleComponent[]) => {
    if (actionCenterData) {
      /** If we have existing selected layers for action center, need to unselect it */

      for (const component of actionCenterData.components) {
        highlightComponent(component, false);
      }
    }

    /** Additional check to un-highlighted components if they are stored in `highlightedComponents` */
    for (const component of highlightedComponents) {
      highlightComponent(component, false);
    }

    for (const component of components) {
      highlightComponent(component);
    }

    setHighlightedComponents(components);
  };

  const unHighlight = (components: SingleComponent[]) => {
    const componentIds = components.map((component) => component.componentId);

    for (const component of components) {
      highlightComponent(component, false);
    }

    const newHighlightedComponents = highlightedComponents.filter(
      (_component) => !componentIds.includes(_component.componentId)
    );
    setHighlightedComponents(newHighlightedComponents);
  };

  return { highlight, unHighlight };
};
