import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import { replaceParams } from '@/helpers/utilities/linkUtils';
import useQuery from '@/hooks/useQuery';
import { fetchPostRequest, getAxiosInstance, getCancelToken, } from '@/helpers/utilities/api-utils';

const axiosInstance = getAxiosInstance();
const CancelToken = getCancelToken();
let cancel;

export const useBulkProjectSummary = (projectId) => {
  const urlBulkProjectSummary = replaceParams(
    API_ENDPOINTS.GET_BULK_ORDER_SUMMARY,
    {
      ':projectId': projectId,
    }
  );
  return useQuery(urlBulkProjectSummary);
};

export const useBulkProjectOrderList = (projectId) => {
  const urlBulkPOrderList = replaceParams(API_ENDPOINTS.GET_BULK_ORDER_LIST, {
    ':projectId': projectId,
  });
  return useQuery(urlBulkPOrderList);
};

export const uploadBulkOrderCSV = async (
  orderId,
  bulkOrderCSVFromData,
  progressEventHandler
) => {
  try {
    const uploadBulkOrderCSVURL = replaceParams(
      API_ENDPOINTS.BULK_ORDERS_CSV_UPLOAD,
      {
        ':orderId': orderId,
      }
    );
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(uploadBulkOrderCSVURL, bulkOrderCSVFromData, {
          headers: {
            'Content-Type':
              'multipart/form-data; boundary=<calculated when request is sent>',
          },
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
          onUploadProgress: (progressEvent) => {
            progressEventHandler(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          },
        })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const cancelAxiosToken = () => {
  if (cancel) cancel();
  cancel = null;
};

export const useBulkOrderList = (page = 1, size = 10) => {
  return useQuery(
    replaceParams(API_ENDPOINTS.GET_BULK_ORDERS_LIST, {
      ':page': page,
      ':pageSize': size,
    })
  );
};

export const createBulkName = async (name, description) => {
  const payload = { na: name, description: description };
  try {
    //the reason to use javascript native fetch is because of axios not giving response for 409 status code requests.
    const response = await fetchPostRequest(
      API_ENDPOINTS.CREATE_BULK_PROJECT,
      payload
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const confirmBulkOrder = async (orderId) => {
  const urlConfirmBulkOrder = replaceParams(API_ENDPOINTS.CONFIRM_BULK_ORDER, {
    ':orderId': orderId,
  });
  try {
    const response = await axiosInstance.post(urlConfirmBulkOrder);
    return response;
  } catch (error) {
    return error;
  }
};

export const getBulkOrderDetails = async (orderId) => {
  const urlToGetBulkOrderDetails = replaceParams(
    API_ENDPOINTS.GET_BULK_ORDER_DETAILS,
    {
      ':orderId': orderId,
    }
  );
  try {
    const response = await axiosInstance.get(urlToGetBulkOrderDetails);
    return response.data;
  } catch (error) {
    return error;
  }
};
