import { MAP_ACTIONS } from './action_types';

export const drawInteractionAddPointToTrackHistory = (addedCoordinate) => ({
  type: MAP_ACTIONS.DRAW_INTERACTION_ADD_POINT_TO_TRACK,
  payload: addedCoordinate,
});

export const drawInteractionLastPointRedo = (addedCoordinate) => ({
  type: MAP_ACTIONS.DRAW_INTERACTION_LAST_POINT_REDO,
  payload: addedCoordinate,
});

export const drawInteractionLastPointUndo = (removedCoordinate) => ({
  type: MAP_ACTIONS.DRAW_INTERACTION_LAST_POINT_UNDO,
  payload: removedCoordinate,
});

export const drawInteractionClearPointLevelHistory = () => ({
  type: MAP_ACTIONS.DRAW_INTERACTION_CLEAR_POINT_HISTORY,
  payload: null,
});

export const modifySyncState = (payload) => ({
  type: MAP_ACTIONS.MODIFY_SYNC_STATE,
  payload: payload,
});

export const setModifyDraftEvents = (payload) => ({
  type: MAP_ACTIONS.SET_MODIFY_DRAFT_EVENTS,
  payload: payload,
});

export const setLastSyncedOn = (payload) => ({
  type: MAP_ACTIONS.SET_LAST_SYNCED_ON,
  payload: payload,
});
