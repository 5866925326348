import moment from 'moment';
import { CONSTANTS } from '../api/contants';
import { getAxiosInstance as axiosInstance } from '@/helpers/utilities/api-utils';

/**
 *
 * @param {Array} orderArray
 * @param {Number} page
 * @param {Number} pageLength
 * @returns {Number}
 */
export const getSlicedOrders = (orderArray, page, pageLength) => {
  const startOrderIndex = (+page - 1) * +pageLength;
  const endOrderIndex = +pageLength * +page - 1;

  return orderArray.slice(startOrderIndex, endOrderIndex + 1);
};

/**
 *
 * @param {Array} status
 * @param {Object} created
 * @param {Array} bulk
 * @returns
 */
export const bulkFilters = (status, created, bulk, search) => {
  return {
    status: status.reduce((prevState, val) => [...prevState, val.tag], []),
    created: created
      ? {
          start: created.tag.split('<->')[0].trim(),
          end: created.tag.split('<->')[1].trim(),
        }
      : null,
    bulk: bulk.reduce((prevState, val) => [...prevState, val.tag], []),
    search,
  };
};

export const getFilterResults = (bulkOrderArray, filters) => {
  const { statusArray, bulkArray, createdDate, search } = filters;
  const {
    status,
    created,
    search: bulk,
  } = bulkFilters(statusArray, createdDate, bulkArray, search);

  return bulkOrderArray.filter((val) => {
    if (status?.length > 0 && created && bulk?.length > 0)
      return (
        status.includes(val.status) &&
        bulk.toLowerCase() === val.na.toLowerCase() &&
        moment.utc(val.cr_at).valueOf() > moment(created.start).valueOf() &&
        moment.utc(val.cr_at).valueOf() < moment(created.end).valueOf()
      );

    if (status?.length > 0 && created)
      return (
        status.includes(val.status) &&
        moment.utc(val.cr_at).valueOf() > moment(created.start).valueOf() &&
        moment.utc(val.cr_at).valueOf() < moment(created.end).valueOf()
      );

    if (status?.length > 0 && bulk?.length > 0)
      return (
        status.includes(val.status) &&
        bulk.toLowerCase() === val.na.toLowerCase()
      );

    if (created && bulk?.length > 0)
      return (
        bulk.toLowerCase() === val.na.toLowerCase() &&
        moment.utc(val.cr_at).valueOf() > moment(created.start).valueOf() &&
        moment.utc(val.cr_at).valueOf() < moment(created.end).valueOf()
      );

    if (status?.length > 0) return status.includes(val.status);
    if (created)
      return (
        moment.utc(val.cr_at).valueOf() > moment(created.start).valueOf() &&
        moment.utc(val.cr_at).valueOf() < moment(created.end).valueOf()
      );
    if (bulk?.length > 0) return bulk.toLowerCase() === val.na.toLowerCase();
    // return false;
  });
};

export const getListOfTags = async () => {
  return await axiosInstance().get(`${CONSTANTS.BASE_ROUTE_FILTER}/tag`);
};

export const removeKeysFromObject = (originalObject, removeKeys = []) => {
  let newObj = {};
  for (let key in originalObject) {
    if (!removeKeys.includes(key)) {
      newObj[key] = originalObject[key];
    }
  }
  return newObj;
};

export const getKeysWithNonUndefinedValue = (obj) => {
  return Object.keys(obj).filter((key) => obj[key] !== undefined);
}
