import { Row, Tooltip } from 'antd';
import TabulaIcon from '@/components/containers/navbar/assets/letter-t.png';
import React from 'react';

interface UploadToTabulaProps {
  onClick: () => void;
}

const UploadToTabula = ({ onClick }: UploadToTabulaProps) => {
  return (
    <Row
      style={{
        cursor: 'pointer',
      }}
    >
      <Tooltip title={'Send to Tabula'}>
        <div
          onClick={onClick}
          style={{ cursor: 'pointer', display: 'flex' }}
          className='tool--navbar'
        >
          <Row>
            <img
              style={{ width: '16px', height: '16px' }}
              src={TabulaIcon}
              alt='Tabula'
            />
          </Row>
        </div>
      </Tooltip>
    </Row>
  );
};

export default UploadToTabula;
