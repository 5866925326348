import { Button, Checkbox, Collapse, Input, Space, Spin, Tag } from 'antd';

import Text from '../../elements/text/Text';

import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';
import downIcon from '../../../assets/down.svg';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _filter, _get } from '../../../helpers/utilities/lodashUtils';
import { setAttributeFilterPanelVisible } from '../../../store/attributeFeature/actions';
import './style.less';
import {
    AREA_FILTER_ID,
    createDecimalAttribute,
    LENGTH_FILTER_ID,
} from '@/components/feature-attributes/filter-panel/helpers';
import useAuth from '@/modules/auth/hooks';

const FilterHeading = ({ head, sub, index, openedAttribute }) => {
  return (
    <div className='flex f-column'>
      <div className='flex justify-between'>
        <Text color='dark-black' type='p12' className='filter-heading'>
          {head}
        </Text>
        <img
          src={downIcon}
          height={17}
          width={17}
          className={
            openedAttribute?.includes(index)
              ? 'down-icon rotate90'
              : 'down-icon'
          }
          alt='down-icon'
        />
      </div>
      <Text color='light-gray' className='extra-detail-text italic'>
        {sub}
      </Text>
    </div>
  );
};

export const AttributesFilter = ({
  features,
  applyAttributeFilters,
  layersData,
  appliedFilters,
}) => {
  const [mainFilter, setMainFilter] = useState(null);
  const [searchedFilter, setSearchedFilter] = useState(null);
  const [attributeToLayer, setAttributeToLayer] = useState({});
  const [appliedAttributeList, setAppliedAttributeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openedAttribute, setOpenAttribute] = useState(null);
  const dispatch = useDispatch();
  const { viewId } = useSelector((state) => state.order.currentViewData);
  const featureListInfo = useSelector(
    (state) => state.order.featureListInfo.data
  );

  const { organizationId } = useAuth();

  const defaultAttributes = useMemo(
    () => [
      createDecimalAttribute(AREA_FILTER_ID, 'Area', organizationId),
      createDecimalAttribute(LENGTH_FILTER_ID, 'Length', organizationId),
    ],
    [organizationId]
  );

  const withDeletedAttributes = useSelector(
    (state) => state.feature.withDeletedAttributes
  );

  const allAttributesList = useMemo(
    () => [...defaultAttributes, ...withDeletedAttributes],
    [defaultAttributes, withDeletedAttributes]
  );

  useEffect(() => {
    let appliedAttributeTagList = [];
    let convertedFiltersAttributeToLayer = {};
    let attributeListDict = {};
    allAttributesList.forEach((att) => {
      attributeListDict[att.attributeId] = att;
    });

    const getNumFilterText = (attributeName, value, type) => {
      let text = `${attributeName} (${type}) : ${value}`;

      if (attributeName === 'Area') text += ' sq ft';

      if (attributeName === 'Length') text += ' ft';

      return text;
    };

    appliedFilters.forEach(function (appliedFilter) {
      if (appliedFilter) {
        convertedFiltersAttributeToLayer[appliedFilter.attributeId] =
          appliedFilter;
        let attName = _get(
          attributeListDict[appliedFilter.attributeId],
          'name'
        );
        if (appliedFilter.numFilter) {
          if (appliedFilter.numFilter.minVal) {
            appliedAttributeTagList.push({
              ...appliedFilter,
              text: getNumFilterText(
                attName,
                appliedFilter.numFilter.minVal,
                'min'
              ),
              closeTagData: {
                type: 'minVal',
                attId: appliedFilter.attributeId,
                filterType: 'numFilter',
              },
            });
          }
          if (appliedFilter.numFilter.maxVal) {
            appliedAttributeTagList.push({
              ...appliedFilter,
              text: getNumFilterText(
                attName,
                appliedFilter.numFilter.maxVal,
                'max'
              ),
              closeTagData: {
                type: 'maxVal',
                attId: appliedFilter.attributeId,
                filterType: 'numFilter',
              },
            });
          }
        } else if (appliedFilter.textFilter) {
          appliedFilter.textFilter.forEach(function (value) {
            appliedAttributeTagList.push({
              ...appliedFilter,
              text: `${attName} : ${value}`,
              closeTagData: {
                attId: appliedFilter.attributeId,
                filterType: 'textFilter',
                value: _filter(
                  appliedFilter.textFilter,
                  (text) => text !== value
                ),
              },
            });
          });
        }
      }
    });
    setAttributeToLayer(convertedFiltersAttributeToLayer);
    setAppliedAttributeList(appliedAttributeTagList);
  }, [appliedFilters, allAttributesList]);

  const fetchFilter = async () => {
    setLoading(true);
    let obj = {};
    let attributeListDict = {};
    allAttributesList.forEach((att) => {
      attributeListDict[att.attributeId] = att;
    });
    layersData?.forEach((layer) => {
      layer.attributeIds?.forEach((layerAtt) => {
        obj[layerAtt] = obj[layerAtt]
          ? {
              ...obj[layerAtt],
              layerNames: [...obj[layerAtt].layerNames, layer.name],
            }
          : { ...attributeListDict[layerAtt], layerNames: [layer.name] };
      });
    });

    const filters = [...defaultAttributes, ...Object.values(obj)];

    setSearchedFilter(filters);
    setMainFilter(filters);
    setLoading(false);
  };
  useEffect(() => {
    fetchFilter();
  }, [allAttributesList, layersData]);
  const findSubHead = (names) => {
    if (names.length > 3) {
      let name = names.slice(0, 3).join(', ');
      name = name + '...';
      return name;
    } else {
      names = names.join(', ');
      return names;
    }
  };

  const searchAttribute = (e) => {
    const name = e.target.value.toLowerCase();
    const newFilters = searchedFilter.filter(
      (a) => a.name.substring(0, name.length).toLowerCase() === name
    );
    setMainFilter(newFilters);
  };

  const setNumValuesForFilters = (attId, type, data, applyFilter = false) => {
    let dataToSet = {};
    if (
      !+data &&
      ((type === 'minVal' &&
        !_get(attributeToLayer[attId], 'numFilter.maxVal')) ||
        (type === 'maxVal' &&
          !_get(attributeToLayer[attId], 'numFilter.minVal')))
    ) {
      dataToSet = {
        ...attributeToLayer,
        [attId]: undefined,
      };
    } else
      dataToSet = {
        ...attributeToLayer,
        [attId]: {
          numFilter: {
            ..._get(attributeToLayer[attId], 'numFilter', {}),
            [type]: +data || undefined,
          },
          attributeId: attId,
        },
      };
    setAttributeToLayer(dataToSet);
    if (applyFilter) applyFilters(dataToSet);
  };
  const setTextValuesForFilters = (attId, data, applyFilter = false) => {
    setAttributeToLayer({
      ...attributeToLayer,
      [attId]: data?.length
        ? { textFilter: data, attributeId: attId }
        : undefined,
    });
    if (applyFilter)
      applyFilters({
        ...attributeToLayer,
        [attId]: data?.length
          ? { textFilter: data, attributeId: attId }
          : undefined,
      });
  };
  const applyFilters = (filters = null) => {
    applyAttributeFilters(
      _filter(
        Object.values(filters ? filters : attributeToLayer),
        (val) => !!val
      )
    );
  };
  return (
    <>
      {loading ? (
        <div className='flex align-center justify-center panel-spin'>
          <Spin size='large' />
        </div>
      ) : (
        <div>
          <div className='att-filter-panel-heading flex justify-between align-center p-4'>
            <Text type='h41' color='dark-gray'>
              Attributes Filter
            </Text>
            <CrossIcon
              width={15}
              cursor='pointer'
              onClick={() => dispatch(setAttributeFilterPanelVisible(false))}
            />
          </div>
          <div className='att-filter-panel flex f-column w-100p px-4'>
            <div className='search-box'>
              <Input onChange={searchAttribute} placeholder='Search' />
            </div>
            <div className='pt-3'>
              {appliedAttributeList.map((filter) => (
                <Tag
                  style={{ borderRadius: 10, marginTop: 5 }}
                  closable
                  onClose={() => {
                    let { attId, type } = filter.closeTagData;
                    if (filter.closeTagData.filterType === 'numFilter')
                      setNumValuesForFilters(attId, type, null, true);
                    else if (filter.closeTagData.filterType === 'textFilter')
                      setTextValuesForFilters(
                        attId,
                        filter.closeTagData.value,
                        true
                      );
                  }}
                >
                  {filter.text}
                </Tag>
              ))}
            </div>
            <Collapse
              className='collapse-panel '
              onChange={(e) => {
                setOpenAttribute(e);
              }}
            >
              {mainFilter?.map((fea, index) => {
                const type = fea.datatype;

                let filterContent = (
                  <div className='flex height gap-8 align-center'>
                    <Input
                      value={
                        _get(
                          attributeToLayer[fea.attributeId],
                          'numFilter.minVal'
                        ) || null
                      }
                      placeholder='Min'
                      onChange={(e) =>
                        setNumValuesForFilters(
                          fea.attributeId,
                          'minVal',
                          e.target.value
                        )
                      }
                    />
                    <Text type='p6' color='gray-shade'>
                      to
                    </Text>
                    <Input
                      value={
                        _get(
                          attributeToLayer[fea.attributeId],
                          'numFilter.maxVal'
                        ) || null
                      }
                      placeholder='Max'
                      onChange={(e) =>
                        setNumValuesForFilters(
                          fea.attributeId,
                          'maxVal',
                          e.target.value
                        )
                      }
                    />
                  </div>
                );

                if (type === 'multiselect' || type === 'singleselect') {
                  filterContent = fea[type]?.values?.map((el) => (
                    <Checkbox className='mbo-12 mle-0 checkbox' value={el}>
                      <Text type='p24' color='dark-gray'>
                        {el}
                      </Text>
                    </Checkbox>
                  ));
                  filterContent = (
                    <div className='flex f-column'>
                      <Checkbox.Group
                        value={_get(
                          attributeToLayer[fea.attributeId],
                          'textFilter',
                          []
                        )}
                        onChange={(values) =>
                          setTextValuesForFilters(fea.attributeId, values)
                        }
                      >
                        {filterContent}
                      </Checkbox.Group>
                    </div>
                  );
                } else if (type === 'text')
                  filterContent = (
                    <>
                      <Input
                        value={
                          _get(
                            attributeToLayer[fea.attributeId],
                            'textFilter',
                            []
                          ).join(',') || null
                        }
                        placeholder='Search Forests keywords'
                        onChange={(e) =>
                          setTextValuesForFilters(
                            fea.attributeId,
                            e.target.value ? e.target.value.split(',') : null
                          )
                        }
                      />
                      <span
                        style={{
                          fontSize: '9px',
                          fontWeight: 400,
                          color: '#999999',
                        }}
                      >
                        You can filter for multiple keywords by using comma
                      </span>
                    </>
                  );
                return (
                  <Collapse.Panel
                    showArrow={false}
                    className='collpase-header'
                    header={
                      <FilterHeading
                        head={fea.name}
                        sub={findSubHead(fea.layerNames)}
                        index={index + ''}
                        openedAttribute={openedAttribute}
                      />
                    }
                  >
                    {filterContent}
                  </Collapse.Panel>
                );
              })}
            </Collapse>
            <div className='apply-wrapper'>
              <Space>
                <Button
                  className={!appliedFilters.length && 'disabled'}
                  type='default'
                  onClick={() => applyFilters({})}
                  disabled={!appliedFilters.length}
                >
                  Reset
                </Button>
                <Button type='primary' onClick={() => applyFilters()}>
                  Apply
                </Button>
              </Space>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
