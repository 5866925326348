import { Component } from 'react';
import ShortCutsContext from '../../ShortCutsContext';
import {
  cutKey,
  deleteComponent,
  drawMode,
  duplicateComponent,
  duplicateLayer,
  exitEditMode,
  layerBottomMove,
  layerLeftMove,
  layerRightMove,
  layerTopMove,
  mergeComponent,
  modifyEditKey,
  redoKey,
  saveKey,
  shiftBottomKey,
  shiftLeftKey,
  shiftRightKey,
  shiftTopKey,
  sliceKey,
  undoKey,
  zoomInKey,
  zoomOutKey,
} from '../constants/ShortCutData';
import { WithMapToolGuard } from '@/modules/subscription/guards/project';
import { SubscriptionFeature } from '@/modules/subscription/types';
import { MapTool } from '@/modules/project/types';
import withSubscription from '@/modules/project/guards/SubscriptionGuard/withSubscription';
import { SubscriptionPlan } from '@/components/subscription/helpers/enum';

class ShortCutKeys extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyBoard);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyBoard);
  }

  toggleKeyBoardEvents = (option) => {
    if (option) {
      document.addEventListener('keydown', this.onKeyBoard);
    } else {
      document.removeEventListener('keydown', this.onKeyBoard);
    }
  };
  getUserOs = () => {
    return window.navigator.platform.match(/^Mac/) ? 'Mac' : 'Windows';
  };

  onKeyBoard = (event) => {
    if (
      event.target.nodeName === 'INPUT' ||
      event.target.nodeName === 'TEXTAREA' ||
      event.target.nodeName === 'DIV'
    ) {
      return true;
    }

    if (this.props.currentPlan == SubscriptionPlan.ESSENTIAL) {
      return true;
    }

    // event.preventDefault();
    let keys = event.key;
    if (event.altKey) {
      keys = `Alt+${event.key}`;
    }
    if (event.ctrlKey) {
      keys = `ctrl+${event.key}`;
    }
    if (event.shiftKey) {
      keys = `shift+${event.key}`;
    }
    if (event.metaKey && this.getUserOs() === 'Mac') {
      keys = `metaKey+${event.key}`;
    }
    if (event.metaKey && this.getUserOs() === 'Windows') {
      keys = `ctrl+${event.key}`;
    }

    switch (keys) {
      //map operation
      case zoomInKey.keyName:
        if (this.zoomIn) this.zoomIn();
        break;
      case zoomOutKey.keyName:
        if (this.zoomOut) this.zoomOut();
        break;
      case layerRightMove.keyName:
        if (this.layerNavigationSlow) this.layerNavigationSlow('right');
        break;
      case layerLeftMove.keyName:
        if (this.layerNavigationSlow) this.layerNavigationSlow('left');
        break;
      case layerTopMove.keyName:
        if (this.layerNavigationSlow) this.layerNavigationSlow('top');
        break;
      case layerBottomMove.keyName:
        if (this.layerNavigationSlow) this.layerNavigationSlow('bottom');
        break;

      case shiftRightKey.keyName:
        if (this.layerNavigationFast) this.layerNavigationFast('right');
        break;
      case shiftLeftKey.keyName:
        if (this.layerNavigationFast) this.layerNavigationFast('left');
        break;
      case shiftTopKey.keyName:
        if (this.layerNavigationFast) this.layerNavigationFast('top');
        break;
      case shiftBottomKey.keyName:
        if (this.layerNavigationFast) this.layerNavigationFast('bottom');
        break;

      //  operation of function
      case modifyEditKey.keyName:
        if (this.modify) this.modify();
        break;
      case cutKey.keyName:
        if (this.cut) this.cut();
        break;

      case sliceKey.keyName:
        if (this.props.allowed(MapTool.LineSplit)) {
          if (this.slice) this.slice();
        }
        break;
      case saveKey.keyName:
        event.preventDefault();
        if (this.save) this.save();
        break;
      case saveKey.windowsKey:
        event.preventDefault();
        if (this.save) this.save();
        break;

      case exitEditMode.keyName:
        if (this.exitEditMode) {
          this.exitEditMode();
        }
        break;
      case drawMode.keyName:
        if (this.drawMode) {
          this.drawMode();
        }
        break;
      case duplicateLayer.keyName:
        event.preventDefault();
        if (this.props.allowed(SubscriptionFeature.SingleLayerDuplicate)) {
          if (this.duplicateLayer) {
            this.duplicateLayer();
          }
        }
        break;
      case duplicateLayer.windowsKey:
        event.preventDefault();
        if (this.props.allowed(SubscriptionFeature.SingleLayerDuplicate)) {
          if (this.duplicateLayer) {
            this.duplicateLayer();
          }
        }
        break;
      case duplicateComponent.windowsKey:
      case duplicateComponent.keyName:
        event.preventDefault();

        if (this.props.allowed(SubscriptionFeature.MultiLayerDuplicate)) {
          if (this.duplicateComponent) {
            this.duplicateComponent();
          }
        }
        break;
      case mergeComponent.windowsKey:
      case mergeComponent.keyName:
        event.preventDefault();

        if (this.props.allowed(SubscriptionFeature.MultiLayerMerge)) {
          if (this.mergeComponent) {
            this.mergeComponent();
          }
        }
        break;
      case undoKey.keyName:
        if (this.undo) this.undo();
        break;
      case redoKey.keyName:
        event.preventDefault();
        if (this.redo) this.redo();
        break;
      case undoKey.windowsKey:
        if (this.undo) this.undo();
        break;
      case redoKey.windowsKey:
        event.preventDefault();
        if (this.redo) this.redo();
        break;
      case deleteComponent.keyName:
        event.preventDefault();

        if (this.props.allowed(SubscriptionFeature.MultiLayerDelete)) {
          if (this.deleteComponent) this.deleteComponent();
        }
        break;
      case deleteComponent.windowsKey:
        event.preventDefault();

        if (this.props.allowed(SubscriptionFeature.MultiLayerDelete)) {
          if (this.deleteComponent) this.deleteComponent();
        }
        break;
      default:
        break;
    }
    return true;
  };

  render() {
    return (
      <ShortCutsContext.Provider value={this}>
        {this.props.children}
      </ShortCutsContext.Provider>
    );
  }
}

export default withSubscription(WithMapToolGuard(ShortCutKeys));
