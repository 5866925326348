import React from 'react';
import { amountFormat } from '../../../../helpers/utilities/currencyUtils';
import { Col, Row } from 'antd';
import { Typography } from '@siterecon/sr-styles/dist/elements';
import { Tooltip } from '@siterecon/sr-component/dist/sr-component-library';
import './style.less';

interface AreaPerimeterInfoProps {
  area: number;
  areaUnit: string | null;
  perimeter: number | null;
}

const AreaPerimeterInfo = ({
  area,
  areaUnit = 'sq ft',
  perimeter,
}: AreaPerimeterInfoProps) => {
  if (!area) {
    return <></>;
  }
  const isPerimeterValid =
    perimeter !== null &&
    perimeter !== undefined &&
    !Number.isNaN(perimeter) &&
    perimeter !== 0;

  return (
    <Row align='middle' className='d-flex area-perimeter-info'>
      {area && (
        <Tooltip title='Area' placement='top'>
          <Typography
            fontSize={12}
            lineHeight='16px'
            fontWeight={500}
            style={{ color: '#333333' }}
          >
            {amountFormat(area.toFixed(2))} {areaUnit}
          </Typography>
        </Tooltip>
      )}

      {isPerimeterValid && <Col className='divider' />}

      {isPerimeterValid && (
        <Tooltip
          title='Perimeter is the sum of lengths of all edges (inner and outer) of all polygons'
          placement='top'
        >
          <Typography
            fontSize={12}
            lineHeight='16px'
            fontWeight={500}
            style={{ color: '#333333' }}
          >
            {amountFormat(perimeter.toFixed(2))} ft
          </Typography>
        </Tooltip>
      )}
    </Row>
  );
};

export default AreaPerimeterInfo;
