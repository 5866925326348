// @ts-nocheck
import { ComponentType, FC } from 'react';
import { IObject } from '@/modules/common/types';
import { MapToolAllowedFunction } from '@/modules/subscription/guards/project/MapTool/useMapToolGuard';
import { useMapToolGuard } from '@/modules/subscription/guards/project';

interface WithMapToolGuardProps {
  allowed: MapToolAllowedFunction;
}

const WithMapToolGuard = <P extends IObject = any>(
  Component: ComponentType<P>
): FC<P & WithMapToolGuardProps> => {
  const WrappedComponent: FC<P & WithMapToolGuardProps> = (props) => {
    const { allowed } = useMapToolGuard();

    return <Component {...props} allowed={allowed} />;
  };

  return WrappedComponent;
};

export default WithMapToolGuard;
