import { ORGANISATION_ACTIONS, USER_ACTIONS } from './action_types';

export const userLoginRequest = () => ({
  type: USER_ACTIONS.USER_LOGIN_REQUEST,
});

export const userLoginSuccess = (data) => ({
  type: USER_ACTIONS.USER_LOGIN_SUCCESS,
  payload: data,
});

export const userLoginFailure = (data) => ({
  type: USER_ACTIONS.USER_LOGIN_FAILURE,
  payload: data,
});

export const getUserDetail = (data) => ({
  type: USER_ACTIONS.GET_USER_DETAILS,
  payload: data,
});

export const updateActiveState = (data) => ({
  type: USER_ACTIONS.UPDATE_ACTIVE_STATE,
  payload: data,
});

export const userUpdateRequest = () => ({
  type: USER_ACTIONS.USER_UPDATE_REQUEST,
});

export const userUpdateSuccess = () => ({
  type: USER_ACTIONS.USER_UPDATE_SUCCESS,
});

export const userUpdateFailure = (data) => ({
  type: USER_ACTIONS.USER_UPDATE_FAILURE,
});

export const userSignUpRequest = () => ({
  type: USER_ACTIONS.USER_SIGNUP_REQUEST,
});

export const userSignUpSuccess = (data) => ({
  type: USER_ACTIONS.USER_SIGNUP_SUCCESS,
  payload: data,
});

export const userSignUpFailure = () => ({
  type: USER_ACTIONS.USER_SIGNUP_FAILURE,
});

export const changePasswordRequest = () => ({
  type: USER_ACTIONS.CHANGE_PASSWORD_REQUEST,
});

export const changePasswordSuccess = () => ({
  type: USER_ACTIONS.CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailure = () => ({
  type: USER_ACTIONS.CHANGE_PASSWORD_FAILURE,
});

export const updateUserCredits = (data) => ({
  type: USER_ACTIONS.UPDATE_USER_CREDITS,
  payload: data,
});

export const setMailStatus = (data) => ({
  type: USER_ACTIONS.IS_MAIL_SENT,
  payload: data,
});

export const setToBeRemembered = (data) => ({
  type: USER_ACTIONS.SET_TO_BE_REMEMBERED,
  payload: data,
});

export const clearUserInfo = (data) => ({
  type: USER_ACTIONS.CLEAR_USER_INFO,
  payload: data,
});
export const getJobTitleRequest = () => ({
  type: USER_ACTIONS.JOB_TITLE_REQUEST,
});
export const getJobTitlesuccess = (data) => ({
  type: USER_ACTIONS.JOB_TITLE_SUCCESS,
  payload: data,
});
export const getJobTitleFailure = () => ({
  type: USER_ACTIONS.JOB_TITLE_FAILURE,
});
export const updateJobTitleRequest = () => ({
  type: USER_ACTIONS.UPDATE_JOB_TITLE_REQUEST,
});
export const updateJobTitlesuccess = (data) => ({
  type: USER_ACTIONS.UPDATE_JOB_TITLE_SUCCESS,
  payload: data,
});
export const updateJobTitleFailure = () => ({
  type: USER_ACTIONS.UPDATE_JOB_TITLE_FAILURE,
});

export const setUserJobTitle = (data) => ({
  type: USER_ACTIONS.SET_USER_JOB_TITLE,
  payload: data,
});
export const getOrganisationSuccess = (data) => ({
  type: ORGANISATION_ACTIONS.GET_ORGANISATION_SUCCESS,
  payload: data,
});
export const getOrganisationFailure = () => ({
  type: ORGANISATION_ACTIONS.GET_ORGANISATION_FAILURE,
});

export const skipOrganisationCreation = () => ({
  type: ORGANISATION_ACTIONS.SKIP_ORG_CREATION,
});

export const getAvatarImagesRequest = () => ({
  type: USER_ACTIONS.GET_AVATAR_IMAGES_REQUEST,
});
export const getAvatarImagesSuccess = (data) => ({
  type: USER_ACTIONS.GET_AVATAR_IMAGES_SUCCESS,
  payload: data,
});

export const getAvatarImagesFailure = () => ({
  type: USER_ACTIONS.GET_AVATAR_IMAGES_FAILURE,
});
export const loginByLinkRequest = () => ({
  type: USER_ACTIONS.LOGIN_BY_LINK_REQUEST,
});
export const loginByLinkSuccess = (data) => ({
  type: USER_ACTIONS.LOGIN_BY_LINK_SUCCESS,
  payload: data,
});
export const otpVerificationRequest = () => ({
  type: USER_ACTIONS.OTP_VERIFICATION_REQUEST,
});
export const otpVerificationSuccess = () => ({
  type: USER_ACTIONS.OTP_VERIFICATION_SUCCESS,
});
export const otpVerificationFailure = (data) => ({
  type: USER_ACTIONS.OTP_VERIFICATION_FAILURE,
  payload: data,
});

export const otpGenerationRequest = () => ({
  type: USER_ACTIONS.OTP_GENERATION_REQUEST,
});
export const otpGenerationSuccess = () => ({
  type: USER_ACTIONS.OTP_GENERATION_SUCCESS,
});
export const otpGenerationFailure = (data) => ({
  type: USER_ACTIONS.OTP_GENERATION_FAILURE,
  payload: data,
});

export const forgotPasswordRequest = () => ({
  type: USER_ACTIONS.FORGOT_PASSWORD_REQUEST,
});
export const forgotPasswordSuccess = () => ({
  type: USER_ACTIONS.FORGOT_PASSWORD_SUCCESS,
});
export const forgotPasswordFailure = () => ({
  type: USER_ACTIONS.FORGOT_PASSWORD_FAILURE,
});
export const userTitleSetRequest = (data) => ({
  type: USER_ACTIONS.USER_TITLE_SET_REQUEST,
  payload: data,
});
export const userTitleSetSuccess = () => ({
  type: USER_ACTIONS.USER_TITLE_SET_SUCCESS,
});
export const userTitleSetFailure = () => ({
  type: USER_ACTIONS.USER_TITLE_SET_FAILURE,
});
export const loginByLinkFailure = () => ({
  type: USER_ACTIONS.LOGIN_BY_LINK_FAILURE,
});
export const isPaaswordTrueSuccess = () => ({
  type: USER_ACTIONS.IS_PASSWORD_TRUE_SUCCESS,
});
export const isPaaswordTrueFailure = () => ({
  type: USER_ACTIONS.IS_PASSWORD_TRUE_FAILURE,
});
export const nameUpdateRequest = () => ({
  type: USER_ACTIONS.USER_NAME_CHANGE_REQUEST,
});
export const nameUpdateSuccess = (data) => ({
  type: USER_ACTIONS.USER_NAME_CHANGE_SUCCESS,
  payload: data,
});
export const nameUpdateFailure = () => ({
  type: USER_ACTIONS.USER_NAME_CHANGE_FAILURE,
});

export const toogleMeasureTool = (data) => ({
  type: USER_ACTIONS.TOGGLE_MEASURE,
  payload: data,
});

export const setPrevNextHasVal = (add, prev, next) => ({
  type: USER_ACTIONS.PREV_NEXT,
  payload: [add, prev, next],
});

export const resetAddState = () => ({
  type: USER_ACTIONS.RESET_ADD,
});

export const setShareBtnStatus = (data) => ({
  type: USER_ACTIONS.SET_BTN_STATUS,
  payload: data,
});

export const onShareClick = (data) => ({
  type: USER_ACTIONS.ON_SHARE_CLICK,
  payload: data,
});

export const updateSlideState = (data) => ({
  type: USER_ACTIONS.UPDATE_SLIDE_STATE,
  payload: data,
});

export const setPopValue = (prev, next) => ({
  type: USER_ACTIONS.POP_VAL,
  payload: [prev, next],
});

export const setOnSnap = (val) => ({
  type: USER_ACTIONS.ON_SNAP,
  payload: val,
});

export const onSnapClick = (val) => ({
  type: USER_ACTIONS.ON_CLICK_SNAP,
  payload: val,
});

export const kmlFromNav = (val) => ({
  type: USER_ACTIONS.ON_KML_NAV,
  payload: val,
});

export const setOptInfo = (val) => ({
  type: USER_ACTIONS.SET_OTP_INFO,
  payload: val,
});

export const setActiveTool = (toolType) => ({
  type: USER_ACTIONS.SET_ACTIVE_TOOL,
  payload: toolType,
});

export const setMapClassnames = (className = '') => ({
  type: USER_ACTIONS.SET_MAP_CLASSNAME,
  payload: className,
});

export const kmlForLayerList = (val) => ({
  type: USER_ACTIONS.KML_FOR_LAYER_LIST,
  payload: val,
});

export const onShareMapId = (data) => ({
  type: USER_ACTIONS.ON_SHARE_ID,
  payload: data,
});

export const maskCredits = () => ({
  type: USER_ACTIONS.MASK_CREDITS,
});

export const unmaskCredits = () => ({
  type: USER_ACTIONS.UNMASK_CREDITS,
});

export const setViewWidth = (width) => ({
  type: USER_ACTIONS.SET_VIEW_WIDTH,
  payload: width,
});

export const showBlockPopup = (payload) => ({
  type: USER_ACTIONS.SHOW_BLOCKED_POPUP,
  payload: {
    status: payload?.status ?? false,
    message: payload?.message ?? '',
  },
});

export const toggleBulkLayout = () => ({
  type: USER_ACTIONS.SHOW_BULK_LAYOUT,
});

export const switchWorkspace = () => ({
  type: USER_ACTIONS.USER_SWITCH_WORKSPACE,
});

export const setTrialPopupAsSeen = () => ({
  type: USER_ACTIONS.DISMISS_TRIAL_POPUP,
});
