import React from 'react';
import { ReactComponent as MeasurementFailedIcon } from '../../bolt/assets/failed.svg';
import { BOLT_ROUTE_STATIC } from '../../bolt/scripts/constants';

import { Button } from 'antd';

const MapMeasurementFailed = () => {
  return (
    <div className='measurement-failed'>
      <div className='measurement-failed-icon'>
        <MeasurementFailedIcon />
      </div>
      <div className='text-content'>
        <h2>Oops!</h2>
        <h2 style={{ fontSize: '18px' }}>Measurement failed</h2>
        <p>
          We could not create measurements for this order due to unclear <br />
          Nearmap Imagery. <br />
          Try placing a regular order instead.
        </p>
      </div>
      <div className='buttons'>
        <Button
          type='primary'
          className='button flex-center'
          href={BOLT_ROUTE_STATIC}
        >
          Place Regular Order
        </Button>
      </div>
    </div>
  );
};

export default MapMeasurementFailed;
