import Text from '@/components/elements/text/Text';
import { Button, Col, Modal, Row } from 'antd';
import { ReactComponent as CloseModal } from '@/assets/cross-modal.svg';
import ExportAspireImage from '@/assets/images/ExportAspire.png';
import './style.less';
import React from 'react';

interface ExportAspireProps {
  showExportAspireModal: boolean;
  setShowExportAspireModal: (value: boolean) => void;
}

const ExportAspire = ({
  showExportAspireModal,
  setShowExportAspireModal,
}: ExportAspireProps) => {
  return (
    <Modal
      title=''
      visible={showExportAspireModal}
      closeIcon={<CloseModal />}
      onCancel={() => setShowExportAspireModal(false)}
      footer={null}
      className='export-aspire-modal'
      width='648px'
    >
      <Row justify='center' className='mb-4'>
        <Text type='p89' color='mid-dark'>
          Exporting to 3rd Party Apps made simple!
        </Text>
      </Row>
      <Row justify='center' className='mb-4'>
        <Text type='p19_2' color='gray-shade' align='center'>
          Use SiteRecon’s exclusive chrome extension to export your takeoff
          measurements directly to  3rd Party Apps.
        </Text>
      </Row>
      <img
        src={ExportAspireImage}
        alt={'Export Image'}
        width={'600px'}
        height={'340px'}
      />
      <Row gutter={16} className='mt-6'>
        <Col span={12}>
          <Button
            className='w-100'
            style={{ border: '1px solid #D1D5DB', height: '38px' }}
            onClick={() =>
              window.open(
                'https://complex-raptorex-5cf.notion.site/Integrating-Aspire-with-SiteRecon-141dd84c6984407b802739e2153aa431',
                '_blank'
              )
            }
          >
            <Text type='p19' color='gray-shade'>
              How does it work?
            </Text>
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type='primary'
            className='w-100'
            style={{ backgroundImage: 'none', height: '38px' }}
            onClick={() =>
              window.open(
                'https://chrome.google.com/webstore/detail/siterecon-aspire-integrat/nnjkdomnoiadkjodhenofnhmeaaljgke',
                '_blank'
              )
            }
          >
            <Text type='p19' color='dark-gray'>
              Get Chrome Extension
            </Text>
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ExportAspire;
