import { getCenter } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import { transform } from 'ol/proj';
import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { AttributeLayerSelectionPopup } from '../popup';

import MapContext from '../../../../pages/project/MapContext';

import { LineString, Point, Polygon } from 'ol/geom';
import DraggableWrapper from '../../../../../modules/common/components/DraggableWrapper';
import './style.less';

export const SinglePopup = (props) => {
  const singleData = useSelector((state) => {
    const data = state.feature.attributeMultiPopupData;

    if (!data || !Object.keys(data).length) return null;

    const actionCenterComponents = Object.values(data)[0] ?? [];
    return actionCenterComponents.length === 1
      ? actionCenterComponents[0]
      : null;
  });

  const popupRef = useRef(null);

  const mapContext = useContext(MapContext);

  useEffect(() => {
    if (popupRef.current) {
      handler();
    }
  }, [singleData?.componentId, popupRef, mapContext]);

  const handler = () => {
    const { current: element } = popupRef;

    if (!singleData) {
      element.style.display = 'none';
      element.style.visibility = 'hidden';
    } else {
      element.style.display = 'block';
      element.style.visibility = 'inherit';

      let { geoJson, geometry, eventPixel } = singleData;
      if (geoJson || geometry) {
        let geoJsonOfFeature, extent;
        let flag = true;
        let center = [0, 0];
        if (eventPixel) {
          center = eventPixel;
          flag = false;
        } else if (
          geoJson instanceof Polygon ||
          geoJson instanceof LineString ||
          geoJson instanceof Point
        ) {
          extent = geoJson.getExtent();
          center = getCenter(extent);
          flag = false;
        } else if (
          geometry instanceof Polygon ||
          geometry instanceof LineString ||
          geometry instanceof Point
        ) {
          extent = geometry.getExtent();
          center = getCenter(extent);
        } else if (geoJson) {
          geoJsonOfFeature = new GeoJSON().readFeature(geoJson);
          extent = geoJsonOfFeature.getGeometry().getExtent();
          center = getCenter(extent);
        } else if (geometry) {
          geoJsonOfFeature = new GeoJSON().readFeature(geometry);
          extent = geoJsonOfFeature.getGeometry().getExtent();
          center = getCenter(extent);
          flag = false;
        }

        if (flag) {
          center = transform(center, 'EPSG:4326', 'EPSG:3857');
        }
        if (singleData?.leftPanelSelect) {
          const view = mapContext?.getView();
          view?.animate({
            center: center,
            duration: 500,
          });
        }
      }
    }
  };

  const closeHandle = () => {
    props?.closeHandle();
  };

  return (
    <>
      <div id='single-layer-popup' ref={popupRef}>
          <AttributeLayerSelectionPopup
            popoverRef={props.popoverRef}
            popupData={singleData}
            componentName={singleData?.properties?.componentIndexing}
            key={singleData && props.popoverRef ? singleData.componentId : '1'}
            isBaseView={props.isBaseView}
            isEditableView={props.isEditableView}
            onClose={closeHandle}
            geometryType={props.geometryType}
            featureType={props.featureType}
            orderStatus={props.orderStatus}
            layersData={props.layersData}
            layerComponents={props.layerComponents}
            orderHash={props.orderHash}
            deleteComponents={props.deleteComponents}
            onDuplicateComponent={props.onDuplicateComponent}
            onUpdateLayerComponents={props.onUpdateLayerComponents}
            onServiceItemsChange={props.onServiceItemsChange}
          />
      </div>
    </>
  );
};
