import { trackEvents } from './utilities';
import { showNotification } from '../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../components/storybook/NotificationToast/types';

let selectedOrg: any;

export const setSelectedOrg = (selOrg: any) => {
  selectedOrg = { ...selOrg };
};

export const NotifyError = (error = '', otherParams = {}) => {
  if (error.length > 0) {
    showNotification(NOTIFICATIONS_TYPES.ERROR, error);
    try {
      trackEvents('error__toaster', {
        error: error.toLowerCase(),
        organization: selectedOrg.title,
        email: selectedOrg.email,
        ...otherParams
      });
    } catch (error) {}
  }
};
