// @ts-nocheck
import { ComponentType, forwardRef } from 'react';
import { IObject } from '@/modules/common/types';
import useAuth, { UseAuthResponse } from '@/modules/auth/hooks';

interface WithAuthProps extends UseAuthResponse {}

const WithAuth = <P extends IObject = any>(Component: ComponentType<P>) => {
  const WrappedComponent = forwardRef((props, ref) => {
    const auth = useAuth();

    return <Component {...props} ref={ref} {...auth} />;
  });

  return WrappedComponent;
};

export default WithAuth;
