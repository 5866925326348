import React, { useCallback, useState } from 'react';
import { Progress, Row, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Footer from './components/Footer';

import { FileUploadStatus } from '../bulk-project-upload/upload-file/UploadStatus';

import Text from '../../elements/text/Text';
import { TAB_CATEGORY } from '../../pages/myProperties/utils/enum';

import { clearOrderArray } from '../../../store/dashboard/action';

import './style.less';
import { CheckCircleOutlined, CloseCircleOutlined, } from '@/components/elements/icons/antd-icons';
import CSVUploadIcon from '../bulk-project-upload/upload-file/assets/csvBackground.svg';

const BulkProjectErrorList = ({
  errorBulkOrderDetails, // Error Data
  show = true, // Will Show The Modal
  setVisible, // Toggle Show
  setTypeOfModal, // Different Types Of Modal
  setCSVFileUpdating,
  closeUploadModal,
  bulkOrderId,
}) => {
  const [open, setOpen] = useState(show);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleClose = useCallback(() => {
    setOpen(false);
    setTypeOfModal(FileUploadStatus.uploadFile);
    closeUploadModal();

    dispatch(
      clearOrderArray({
        tab: TAB_CATEGORY.bulk,
      })
    );

    dispatch(
      clearOrderArray({
        tab: TAB_CATEGORY.bulkView,
      })
    );

    if (bulkOrderId)
      history.push({
        pathname: '/',
        search: `tab=${TAB_CATEGORY.bulk}`,
      });
  }, [setTypeOfModal, closeUploadModal, dispatch]);

  return (
    <Row
      style={{ flexDirection: 'column' }}
      className='upload__processing px-6'
    >
      <Row
        className='bulk-error-list-wrapper '
        style={{ flexDirection: 'column', overflow: 'hidden' }}
        wrap={false}
      >
        <Row
          className='w-100 progress__wrapper pt-5 mb-6 px-5 mt-3 '
          wrap={false}
          align={'middle'}
          style={{ borderColor: '#FF2424' }}
        >
          <Row
            className='background__csv__image mr-5'
            justify='center'
            align='middle'
          >
            <img
              src={CSVUploadIcon}
              alt='CSVUploadIcon'
              style={{ top: '0% !important' }}
            />
            <Text>CSV</Text>
          </Row>
          <Row
            className='w-100'
            style={{ flexDirection: 'column', position: 'relative' }}
          >
            <Row align='middle' justify='space-between' wrap={false}>
              <Text type='p76' color='dark-gray'>
                Project.csv
              </Text>
            </Row>
            <Progress
              percent={Math.ceil(
                (errorBulkOrderDetails?.error_count * 100) /
                  errorBulkOrderDetails?.total_count
              )}
              size='small'
              showInfo={false}
              strokeColor={'#FF2424'}
            />
            <Text type='p36' color={'error-red'}>
              There is error in some rows.
            </Text>
          </Row>
        </Row>

        <Row
          className='w-100'
          style={{ flexDirection: 'column', position: 'relative' }}
        >
          <Row
            align='middle'
            justify='space-between'
            wrap={false}
            className={'mb-5'}
          >
            <Text type={'p46'} color='black'>
              {errorBulkOrderDetails?.total_count} Order rows :
            </Text>
            <Space>
              <CheckCircleOutlined style={{ color: '#6CA141', fontSize: 18 }} />
              <Text type={'p51'} color='black'>
                {errorBulkOrderDetails?.accepted_count} success rows
              </Text>
            </Space>
            <Space>
              <CloseCircleOutlined style={{ color: '#DF3C3C', fontSize: 18 }} />
              <Text type={'p51'} color='black'>
                {errorBulkOrderDetails?.error_count} Error rows
              </Text>
            </Space>
          </Row>
        </Row>
        {errorBulkOrderDetails ? (
          <Footer
            errorBulkOrderDetails={errorBulkOrderDetails}
            setVisible={setVisible}
            setCSVFileUpdating={setCSVFileUpdating}
            setOpen={setOpen}
            setTypeOfModal={setTypeOfModal}
          />
        ) : null}
      </Row>
    </Row>
  );
};

export default BulkProjectErrorList;
