import { trackEvents } from '@/helpers/utilities';
import { Button, Result } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import CONFIG from '../app.config';
import WithAuth from '@/modules/auth/WithAuth';
import { Box } from '@common/elements';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.hasError &&
      this.props.orgData.length > 0 &&
      !this.state.eventId
    ) {
      // if (this.state.eventId !== prevState.eventId) {
      const selectedOrg = this.props.orgData?.find(
        (org) => org.id === this.props.user.organization_id
      );
      try {
        trackEvents('frontend_crash', {
          errorInfo: this.state.errorInfo,
          email: this.props.user.email,
          orgName: selectedOrg.title,
        });
      } catch (error) {}
      // }
    }
  }

  componentDidCatch(error, errorInfo) {
    if (
      CONFIG.prodDomain &&
      CONFIG.prodDomain.indexOf(window.location.hostname) > -1
    ) {
      if (!error.toString().includes('ChunkLoadError')) {
        // logErrorToSlackChannel(error, errorInfo);
        this.setState({ error, errorInfo, hasError: true });
      }
    }
  }

  redirectToHome = () => {
    window.location.replace('/');
  };

  refresh = () => {
    location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status='500'
          title='500'
          subTitle='Sorry, something went wrong.'
          extra={
            <Box>
              <Button
                style={{ marginRight: '10px', width: '120px' }}
                onClick={this.refresh}
              >
                Refresh
              </Button>
              <Button
                style={{ marginRight: '10px', width: '120px' }}
                type='primary'
                onClick={this.redirectToHome}
              >
                Back Home
              </Button>
            </Box>
          }
        />
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    orgData: state.teamManagement.organisationData,
  };
};

export default WithAuth(connect(mapStateToProps, null)(ErrorBoundary));
