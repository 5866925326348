import VectorLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import CircleStyle from 'ol/style/Circle';
import { RESTING_MODE_STYLE } from '../mapGlobals';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import { Icon } from 'ol/style';
import { _filter, _get } from '../utilities/lodashUtils';
import { getComponentArea } from './mapCalculations';
import { stringModify } from '../utilities';
import {
  LINE_TYPE_FEATURE,
  POLYGON_TYPE_FEATURE,
} from '../constants/mapConstants';
import { transform, transformExtent } from 'ol/proj';

import { MultiPoint, Point } from 'ol/geom';
import Feature from 'ol/Feature';
import moment from 'moment';
import Text from 'ol/style/Text';

export const newVectorLayer = function (source, style) {
  return new VectorLayer({
    source: source,
    style: style || RESTING_MODE_STYLE,
  });
};

export const newVectorSource = function (featureCollection = []) {
  return new VectorSource({
    features: new GeoJSON().readFeatures(
      { type: 'FeatureCollection', features: featureCollection },
      {
        featureProjection: 'EPSG:3857',
      }
    ),
    crossOrigin: 'Anonymous',
  });
};

export const addFeaturesToSource = function (source, features = []) {
  try {
    source.addFeatures(
      new GeoJSON().readFeatures(
        {
          type: 'FeatureCollection',
          features: _filter(features, (obj) => !obj.hiddenOfAttributeFilter),
        },
        {
          featureProjection: 'EPSG:3857',
        }
      )
    );
  } catch (e) {
    console.error(e);
  }
};

export const createRectangle = (bbox) => {
  const projected_corner_1 = transform([bbox[0], bbox[1]], 'EPSG:4326', 'EPSG:3857');
  const projected_corner_2 = transform([bbox[2], bbox[3]], 'EPSG:4326', 'EPSG:3857')
  const projected_bbox = [...projected_corner_1, ...projected_corner_2];
  const geojsonObject = {
    'type': 'FeatureCollection',
    'crs': {
      'type': 'name',
      'properties': {
        'name': 'EPSG:3857',
      },
    },
    'features': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': [
            [
              [projected_bbox[0], projected_bbox[1]],
              [projected_bbox[2], projected_bbox[1]],
              [projected_bbox[2], projected_bbox[3]],
              [projected_bbox[0], projected_bbox[3]],
              [projected_bbox[0], projected_bbox[1]]
            ],
          ],
        },
      }
    ],
  };

  const boxStyle = new Style({
    stroke: new Stroke({
      color: 'blue',
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.05)',
    }),
  })
  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geojsonObject),
  });
  return new VectorLayer({
    source: vectorSource,
    style: [boxStyle]
  });
}

export const createPointLayer = (lng, lat) => {
  let projected_point = transform([lng, lat], 'EPSG:4326', 'EPSG:3857');
  const iconStyle = new Style({
    image: new Circle({
      radius: 10,
      fill: new Fill({ color: 'red' }),
      stroke: new Stroke({ color: 'black', width: 2 })
    }),
  })
  const iconFeature = new Feature({
    geometry: new Point([projected_point[0], projected_point[1]]),
  });
  iconFeature.setStyle(iconStyle);
  const vectorSource = new VectorSource({
    features: [iconFeature],
  });
  return new VectorLayer({
    source: vectorSource,
  });
}

export const convertJSONStyleToOlStyle = function (geometryType, style = {}) {
  if (geometryType?.toLowerCase() === 'point') {
    let arrowIconStyle = stringModify(style?.symbolType, {
      height: style?.symbolSize,
      width: style?.symbolSize,
      fill: style?.symbolColor,
      'fill-opacity': style?.opacity,
    });
    let coordinates = [];
    return new Style({
      image: new Icon({
        src: 'data:image/svg+xml;utf8,' + escape(arrowIconStyle),
        rotateWithView: true,
        crossOrigin: 'Anonymous',
      }),
      geometry: function (feature) {
        coordinates = feature.getGeometry().getCoordinates();
        return new Point(coordinates);
      },
    });
  } else {
    return new Style({
      fill: new Fill({
        color: hexToRgbA(_get(style, 'fillColor'), _get(style, 'opacity')),
      }),
      stroke: new Stroke({
        color: _get(style, 'color'),
        width: _get(style, 'stroke') || 1,
      }),
    });
  }
};

export const firToMapExtent = function (mapRef, source) {
  mapRef.getView().fit(source.getExtent(), {
    size: mapRef.getSize(),
    padding: [50, 15, 55, 250],
  });
};

export const removeFeatureByComponentId = function (layer, componentId) {
  let source = layer.getSource();
  let feature = source.getFeatureById(componentId);
  if (feature) return source.removeFeature(feature);
  return null;
};

export const setStyleToLayer = function (layerRef, style) {
  return layerRef?.setStyle([style]);
};

export const hexToRgbA = function (hex, opacity) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      opacity +
      ')'
    );
  }
  return 'rgba(0,0,0,1)';
  // throw new Error('Bad Hex');
};

export const checkForDraftName = (viewList) => {
  let counter = 1;
  if (viewList.length) {
    viewList.forEach((val, index) => {
      if (
        val.name.substring(0, 5) === 'Draft' ||
        val.name.substring(0, 5) === 'draft'
      ) {
        counter = counter + 1;
      }
    });
  }

  let name = `Draft ${counter}`;
  return name;
};

export const getViewWithoutBase = (views) => {
  return _filter(views, function (view) {
    return !view.isBaseView;
  });
};

export const getFeatureValue = (component) => {
  switch (_get(component, 'geometry.type')) {
    case 'LineString':
      return getComponentArea('Line', component.properties);
    case 'Point':
      return getComponentArea('Point', component.properties);
    case 'Polygon':
      return getComponentArea('Polygon', component.properties);
    default:
      return 0;
  }
};

export const getOffSet = (mapRef) => {
  if (mapRef) {
    let zoomLevel = mapRef.getView().getZoom();
    // return 2*(20-zoomLevel)
    return 0.00006;
  }
};

export const getLayerDataWithType = (presentLayer = [], allFeatures = []) => {
  let layers = [];
  allFeatures.forEach((feature) => {
    presentLayer.forEach((layer) => {
      if (feature.featureId === layer.featureId) {
        layers.push({ ...layer, type: feature.type });
      }
    });
  });
  return layers;
};

export const getLayerUnits = (layer, allLayers = []) => {
  let unit = '';

  if (layer) {
    for (let i = 0; i < allLayers.length; i++) {
      if (layer.featureId === allLayers[i].featureId) {
        if (allLayers[i].type === 'point' || allLayers[i].type === 'polygon') {
          unit = 'sq ft';
        } else {
          unit = 'ft';
        }
      }
    }

    if (!unit && layer.featureType) {
      /** If the Unit is still not set, try to set the unit from "featureType" of current Layer */

      unit =
        layer.featureType === 'point' || layer.featureType === 'polygon'
          ? 'sq ft'
          : 'ft';
    }
  }
  return unit;
};

export const topologyWarningStringsArray = (
  topologyWarningObject = {
    overlap: {},
    kink: {},
    duplicate: {},
    nullGeometry: {},
  },
  componentIndexingMap = {},
  specificComponentId = null,
  type = 'Features'
) => {
  let topoWarningString = [];
  if (specificComponentId) {
    if (topologyWarningObject.kink[specificComponentId])
      topoWarningString.push('Self Intersecting');
    if (topologyWarningObject.duplicate[specificComponentId])
      topoWarningString.push('Overlapping Points');
    if (topologyWarningObject.nullGeometry[specificComponentId])
      topoWarningString.push('Null Geometry');
    if (topologyWarningObject.overlap[specificComponentId]) {
      let overlappingComponentListString = '';
      topologyWarningObject.overlap[specificComponentId].forEach(
        (overlappedId, index) => {
          overlappingComponentListString += `${
            topologyWarningObject.overlap[specificComponentId].length > 1 &&
            index
              ? topologyWarningObject.overlap[specificComponentId].length - 1 ==
                index
                ? ' & '
                : ', '
              : ''
          }${componentIndexingMap[overlappedId]}`;
        }
      );
      topoWarningString.push(
        `Overlapping ${type} ${overlappingComponentListString}`
      );
    }
  } else {
    if (Object.keys(topologyWarningObject.kink).length) {
      let overlappingComponentListString = '';
      Object.keys(topologyWarningObject.kink).forEach((componentId, index) => {
        overlappingComponentListString += `${
          Object.keys(topologyWarningObject.kink).length > 1 && index
            ? Object.keys(topologyWarningObject.kink).length - 1 == index
              ? ' & '
              : ', '
            : ''
        }${componentIndexingMap[componentId]}`;
      });
      topoWarningString.push(
        `Self Intersecting ${type} ${overlappingComponentListString}`
      );
    }
    if (Object.keys(topologyWarningObject.duplicate).length) {
      let overlappingComponentListString = '';
      Object.keys(topologyWarningObject.duplicate).forEach(
        (componentId, index) => {
          overlappingComponentListString += `${
            Object.keys(topologyWarningObject.duplicate).length > 1 && index
              ? Object.keys(topologyWarningObject.duplicate).length - 1 == index
                ? ' & '
                : ', '
              : ''
          }${componentIndexingMap[componentId]}`;
        }
      );
      topoWarningString.push(
        `Overlapping Points in ${overlappingComponentListString}`
      );
    }
    if (Object.keys(topologyWarningObject.nullGeometry).length) {
      let overlappingComponentListString = '';
      Object.keys(topologyWarningObject.nullGeometry).forEach(
        (componentId, index) => {
          overlappingComponentListString += `${
            Object.keys(topologyWarningObject.nullGeometry).length > 1 && index
              ? Object.keys(topologyWarningObject.nullGeometry).length - 1 ==
                index
                ? ' & '
                : ', '
              : ''
          }${componentIndexingMap[componentId]}`;
        }
      );
      topoWarningString.push(`Null Geometry ${overlappingComponentListString}`);
    }
    if (Object.keys(topologyWarningObject.overlap).length) {
      let overlappingComponentListString = '';
      Object.keys(topologyWarningObject.overlap).forEach(
        (componentId, index) => {
          overlappingComponentListString += `${
            Object.keys(topologyWarningObject.overlap).length > 1 && index
              ? Object.keys(topologyWarningObject.overlap).length - 1 == index
                ? ' & '
                : ', '
              : ''
          }${componentIndexingMap[componentId]}`;
        }
      );
      topoWarningString.push(
        `Overlapping ${type} ${overlappingComponentListString}`
      );
    }
  }
  return topoWarningString;
};

export const displayWaterMarks = (
  point,
  orderSummary,
  configurations,
  mapRef
) => {
  if (!configurations?.isChannelPartner) {
    return;
  }
  let waterMarkFeature = new Feature({
    geometry: new Point(point),
    date: moment(_get(orderSummary, 'updated_at')).format('MM-YY'),
    orderId: _get(orderSummary, 'orderId'),
  });

  let waterMarkStyle = new Style({
    image: new Icon({
      anchor: [0.6, 1.4],
      // anchorOrigin: 'top-left',
      crossOrigin: 'Anonymous',
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      scale: 0.2,
      src: configurations?.mapWatermark,
    }),
  });
  const labelStyle = new Style({
    text: new Text({
      fill: new Fill({
        color: '#FFFFFF',
      }),
    }),
  });

  let waterMarkSource = new VectorSource({
    features: [waterMarkFeature],
    crossOrigin: 'Anonymous',
  });
  const style = [waterMarkStyle, labelStyle];
  let waterMarkLayer = new VectorLayer({
    source: waterMarkSource,
    style: function (feature) {
      labelStyle
        .getText()
        .setText(
          `${feature.get('date')}     order Id: ${feature.get('orderId')}`
        );
      return style;
    },
  });
  mapRef.addLayer(waterMarkLayer);
};

export const showOrHideVertices = (
  checkedLayers = [],
  layerSource,
  resetLayerStyles
) => {
  checkedLayers.forEach((layer) => {
    if (layer.checked) {
      let layerStyle = [];
      let strokeColor;
      let strokeWidth;
      let fillColor;
      layerStyle = layerSource[layer.id]?.getStyle();
      if (layerStyle && layerStyle[0]) {
        strokeColor = layerStyle[0]?.getStroke()?.getColor();
        strokeWidth = layerStyle[0]?.getStroke()?.getWidth();
        fillColor = layerStyle[0]?.getFill()?.getColor();
      }
      layerSource[layer.id]?.setStyle(
        verticesStyle(strokeColor, strokeWidth, fillColor, 4)
      );
    } else {
      resetLayerStyles(layer?.id, true);
    }
  });
};

export const verticesStyle = (
  strokeColor,
  strokeWidth,
  fillColor,
  circleRadius
) => {
  let coordinates = [];
  let styles = [
    new Style({
      stroke: new Stroke({
        color: strokeColor,
        width: strokeWidth,
      }),
      fill: new Fill({
        color: fillColor,
      }),
    }),
  ];

  styles.push(
    new Style({
      image: new CircleStyle({
        radius: circleRadius,
        fill: new Fill({
          color: '#FFD02B', //vertices dot color
        }),
      }),
      geometry: function (feature) {
        const geometry = feature.getGeometry().getType();
        if (geometry === POLYGON_TYPE_FEATURE) {
          coordinates = feature.getGeometry().getCoordinates()[0];
        } else if (geometry === 'MultiPolygon') {
          let multiCoords = feature
            .getGeometry()
            .getCoordinates()
            .map((arr) => arr[0]);

          multiCoords.forEach((arr) => {
            arr.forEach((coord) => coordinates.push(coord));
          });
        } else if (geometry === LINE_TYPE_FEATURE) {
          coordinates = feature.getGeometry().getCoordinates();
        } else {
          coordinates = feature.getGeometry().getCoordinates();
          return new Point(coordinates);
        }
        return new MultiPoint(coordinates);
      },
    })
  );

  return styles;
};
