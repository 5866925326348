import { IQueue, IQueueOrder } from './utils/types';

export const getAddressFromQueues = (queues: IQueue[]) => {
  return queues
    .map((queue: IQueue) => {
      return queue?.orders?.map((order: IQueueOrder) => {
        return {
          address: order.address,
          orderId: order.orderId,
        };
      });
    })
    .flat();
};
