import { NotifyError } from '@/helpers/notification-utils';
import { Button, Modal, Select } from 'antd';
import { Component } from 'react';
import { connect } from 'react-redux';
import * as API_ENDPOINTS from '../../../helpers/constants/APIEndpoints';
import { replaceParams } from '../../../helpers/utilities/linkUtils';
import { _get } from '../../../helpers/utilities/lodashUtils';
import { copyLayerThunk } from '../../../store/order/thunks';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { showNotification } from '../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../storybook/NotificationToast/types';

const axiosInstance = getAxiosInstance();

class CopyLayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      viewsList: [],
      targetView: null,
      replace: false,
    };
  }

  componentDidMount() {
    this.loadViews();
  }

  loadViews = () => {
    const orderId = this.props.selectedView.orderId;
    this.setState({
      loading: true,
    });
    axiosInstance
      .get(
        replaceParams(API_ENDPOINTS.ORDER_VIEW, {
          ':orderId': orderId,
        })
      )
      .then((res) => {
        const data = res.data;
        this.setState(function () {
          return {
            loading: false,
            viewsList: data,
          };
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          loading: false,
        });
        NotifyError('Data Error', {'api': 'load-views', 'orderId': orderId});
      });
  };

  handleChange = (e) => {
    this.setState({
      targetView: {
        viewId: parseInt(e.split(':')[0]),
        name: e.split(':')[2],
        isBaseView: e.split(':')[1],
        orderId: e.split(':')[3],
        isDefault: e.split(':')[4],
        isEditable: e.split(':')[5],
        viewType: e.split(':')[6],
      },
    });
  };
  handleCopyView = () => {
    let that = this;
    const { targetView, viewsList, replace } = that.state;
    const { selectedLayer, selectedView } = that.props;
    if (!targetView) {
      NotifyError('Please select view');
      return false;
    }

    let reqData = {
      targetViewId: _get(targetView, 'viewId'),
    };

    axiosInstance
      .post(
        replaceParams(
          replace
            ? API_ENDPOINTS.REPLACE_LAYER_VIEW
            : API_ENDPOINTS.COPY_LAYER_VIEW_CLONE,
          {
            ':viewId': _get(selectedView, 'viewId'),
            ':layerId': _get(selectedLayer, 'id'),
            ':replace': replace,
          }
        ),
        reqData
      )
      .then((result) => {
        if (replace) {
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            'Replace layer successfully'
          );
        } else {
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            'Copy layer successfully'
          );
        }
        that.setState({ replace: false });
        that.props.handleModalOpen('isCopyLayer', false);
      })
      .catch(async (error) => {
        that.setState({ replace: true });
      });

    // that.props.copyLayerThunk(reqData).then((res)=>{
    //   that.setState({replace:false})
    // }).catch((error)=>{
    //   that.setState({replace:true})
    // })
  };
  render() {
    let that = this;
    const { viewsList, replace } = that.state;
    const { isOpen, selectedView, selectedLayer } = that.props;
    return (
      <div className='copy-layer'>
        <Modal
          visible={isOpen}
          className='modal-class'
          onOk={() => {}}
          closable={false}
          style={{ fontSize: '10px', textAlign: 'center' }}
          // onCancel={() => {that.props.handleModalOpen('isCopyLayer',false)}}
          footer={[
            <div className='copy-replace-btn-wrapper'>
              <Button
                key='back'
                onClick={() => {
                  that.props.handleModalOpen('isCopyLayer', false);
                }}
              >
                Cancel
              </Button>
              <Button
                key='submit'
                type='primary'
                onClick={() => {
                  that.handleCopyView();
                }}
              >
                {replace ? 'Replace' : 'Copy'}
              </Button>
            </div>,
          ]}
          width={382}
        >
          <div className='copy-layer-inner'>
            <p>Copy Layer "{_get(selectedLayer, 'name')}" to:</p>
            {viewsList.length > 0 && (
              <Select
                style={{ width: '74%' }}
                onChange={async (e) => {
                  that.handleChange(e);
                }}
                placeholder={'Please select view'}
              >
                {viewsList.map((key, i) => {
                  if (_get(key, 'isEditable')) {
                    return (
                      <Select.Option
                        key={i}
                        value={`${key.viewId}:${key.name}:${key.isBaseView}:${key.orderId}:${key.isEditable}`}
                        disabled={!_get(key, 'isEditable')}
                      >
                        <p>{key.name}</p>
                      </Select.Option>
                    );
                  }
                })}
              </Select>
            )}
            {replace && (
              <div style={{ paddingTop: '15px' }}>
                <span className='span-error'>
                  Same layer exists in the target view, do you want to replace
                  it with the layer from this view?
                </span>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    copyLayerThunk: (payload) => dispatch(copyLayerThunk(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyLayer);
