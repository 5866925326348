import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as MeasureTool } from '../../../../assets/measure.svg';
import { setActiveTool, setMapClassnames, } from '../../../../store/user/actions';
import ToolsItem from './tools-item/ToolsItem';
import { ToolsTypes } from './toolsTypes';
import { ViewType } from '../../../../modules/common/types';

const Tools = () => {
  const dispatch = useDispatch();

  const mapClassName = useSelector((state) => state.user.mapClassName);

  const isEstimationView = useSelector((state) => {
    const view = state.order.currentViewData;
    if (!view) return false;

    return ([ViewType.ESTIMATION, ViewType.DYNAMIC]).includes(
        view.viewType
    );
  });

  const removeMeasureTool = () => {
    dispatch(setActiveTool(ToolsTypes.SelectTool.action));
    dispatch(setMapClassnames('select'));
  }

  const handleKeyDown = useCallback((event) => {
    if (event.key === "Escape" || event.key === "Esc") {
      removeMeasureTool();
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const onClickMeasurement = () => {
    if (mapClassName === 'measuretool') {
      removeMeasureTool();
      return;
    }

    dispatch(setActiveTool(ToolsTypes.MeasureTool.action));
    dispatch(setMapClassnames('measuretool'));

    /** When user is in estimation view and measure tool is selected, we need to add a keyboard listener to remove that tool on keyboard `Esc` press */
    if (isEstimationView) {
      document.addEventListener('keydown', handleKeyDown);
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  return (
      <ToolsItem
        title={ToolsTypes.MeasureTool.title}
        action={ToolsTypes.MeasureTool.action}
        icon={<MeasureTool />}
        onClick={onClickMeasurement}
      />
  );
};

export default Tools;
