import { OrderStatus } from '@common/types';
import { MapTool } from './types';
import { ToolType } from '../../components/containers/navbar/tools/types';

export const isPostMeasurementOrderStatus = (status: OrderStatus) => {
  return [
    OrderStatus.Approved,
    OrderStatus.SelfMeasured,
    OrderStatus.BoltApproved,
  ].includes(status);
};

export const isModifying = (activeTool: MapTool | ToolType): boolean => {
  return [MapTool.Modify, MapTool.Cut, MapTool.Draw].includes(
    activeTool as MapTool
  );
};
