import { useRouterPlusContext } from '@/modules/router-plus/context';
import { useParams } from 'react-router-dom';

/** Provides a collection of updated params with "redirect" function of useRouterPlus in replace mode and other url params */
const useRouterPlusParams = () => {
  const params = useParams();
  const { params: routerPlusParams } = useRouterPlusContext();

  return { ...params, ...routerPlusParams };
};

export default useRouterPlusParams;
