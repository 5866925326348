import { Reducer } from 'react';
import { WorkspaceStore } from '@/store/workspace/index';
import { INITIAL_STATE } from '@/store/workspace/state';
import { WorkspaceAction } from '@/store/workspace/actions';
import { WorkspaceActionType } from '@/store/workspace/action.types';

const reducer: Reducer<WorkspaceStore, WorkspaceAction> = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case WorkspaceActionType.SET_WORKSPACES:
      return { ...state, list: payload };

    case WorkspaceActionType.SET_SUMMARY:
      return { ...state, summary: payload };

    default:
      return state;
  }
};

export default reducer;
