import { Component } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as COMMAND } from '../../../assets/keys/command.svg';
import { ReactComponent as Y } from '../../../assets/keys/y.svg';
import { ReactComponent as Z } from '../../../assets/keys/z.svg';
import { ReactComponent as Plus } from '../../../assets/keys/plus.svg';
import { ReactComponent as Minus } from '../../../assets/keys/minus.svg';
import { ReactComponent as C } from '../../../assets/keys/c.svg';
import { ReactComponent as S } from '../../../assets/keys/s.svg';
import { ReactComponent as ESC } from '../../../assets/keys/esc.svg';
import { ReactComponent as Polygon } from '../../../assets/keys/polygon.svg';
import { ReactComponent as CopyComponent } from '../../../assets/CopyComponent.svg';
import { ReactComponent as MergeItems } from '../../../assets/merge-items.svg'
import { ReactComponent as Modify } from '../../../assets/keys/modify.svg';
import { ReactComponent as Cut } from '../../../assets/keys/cut.svg';
import { ReactComponent as Slice } from '../../../assets/keys/slice.svg';
import { ReactComponent as Line } from '../../../assets/keys/line.svg';
import { ReactComponent as Path } from '../../../assets/keys/path.svg';
import { ReactComponent as Point } from '../../../assets/keys/point.svg';
import { ReactComponent as K } from '../../../assets/keys/k.svg';
import { ReactComponent as E } from '../../../assets/keys/e.svg';
import { ReactComponent as CTRL } from '../../../assets/keys/Ctrl.svg';
import { ReactComponent as SELECT } from '../../../assets/keys/Select.svg';
import { ReactComponent as ArrowUp } from '../../../assets/keys/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../assets/keys/arrowDown.svg';
import { ReactComponent as ArrowRight } from '../../../assets/keys/arrowRight.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/keys/arrowLeft.svg';
import { ReactComponent as SHIFT } from '../../../assets/keys/shift.svg';
import { ReactComponent as Del } from '../../../assets/keys/Del.svg';
import { ReactComponent as DeleteSvg } from '../../../assets/keys/delete.svg';
import { ReactComponent as MultiSelectSvg } from '../../../assets/keys/mutliSelect.svg';
import { ReactComponent as D } from '../../../assets/keys/d.svg';
import { WithBetaRelease } from '@/modules/common/guards/BetaRelease';


import { openKeyBoardShortCutModal } from '../../../store/announcements/thunks';
import { Button, Col, Drawer, List, Row, Space, Tabs } from 'antd';
import { BetaFeature } from '@/modules/common/types';

const { TabPane } = Tabs;
class KeyboardDetail extends Component{
  constructor(props) {
    super(props);
    this.state={
      selectedTab:'essentialTab'
    }
  }

  onChangeTab=(option)=>{
    this.setState({selectedTab:option})
  }
  render() {

    const essentialKeysList=[
      {
        title:'Undo',
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <COMMAND/>
            </Button>
          </Col>
          <Col span={2}/>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <Z/>
            </Button>
          </Col>
        </Row>
      },
      {
        title:'Redo',
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <COMMAND/>
            </Button>
          </Col>
          <Col span={2}/>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <Y/>
            </Button>
          </Col>
        </Row>
      },
      {
        title: 'Zoom in',
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <Plus/>
            </Button>
          </Col>
        </Row>
      },
      {
        title: 'Zoom out',
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <Minus/>
            </Button>
          </Col>
        </Row>
      },
      {
        title: 'Multiselect items',
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <CTRL/>
            </Button>
          </Col>
        </Row>
      },
      {
        title: 'Save',
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <COMMAND/>
            </Button>
          </Col>
          <Col span={2}/>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <S/>
            </Button>
          </Col>
        </Row>
      },
      {
        title: 'Exit tool (back to select)',
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <ESC/>
            </Button>
          </Col>

        </Row>
      },
      {
        title: 'Moving selected item (small steps)',
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={24}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <ArrowUp/>
            </Button>
          </Col>
          <Col span={12}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <ArrowLeft/>
            </Button>
          </Col>
          <Col span={12}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <ArrowRight/>
            </Button>
          </Col>
          <Col span={24}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <ArrowDown/>
            </Button>
          </Col>
        </Row>
      },
      {
        title: 'Moving selected item (big steps)',
        extraKey:<>
          <Row>
            <Col span={24}>
              <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
                <SHIFT/>
              </Button>
            </Col>
          </Row>
          <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={24}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <ArrowUp/>
            </Button>
          </Col>
          <Col span={12}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <ArrowLeft/>
            </Button>
          </Col>
          <Col span={12}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <ArrowRight/>
            </Button>
          </Col>
          <Col span={24}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <ArrowDown/>
            </Button>
          </Col>
        </Row>
      </>
      }
    ]

    const toolKeysList=[
      {
        title:'Polygon',
        leftIcon: <Polygon/>,
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <D/>
            </Button>
          </Col>
        </Row>
      },
      // {
      //   title:'Rectangle',
      //   leftIcon: <Rectangle/>,
      //   actions:[
      //     {
      //       title:'r',
      //       icon: <R/>
      //     }
      //   ]
      // },
      // {
      //   title:'Circle',
      //   leftIcon: <Circle/>,
      //   actions:[
      //     {
      //       title:'o',
      //       icon: <O/>
      //     }
      //   ]
      // },
      {
        title:'Line',
        leftIcon: <Line/>,
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <D/>
            </Button>
          </Col>
        </Row>
      },
      // {
      //   title:'Path',
      //   leftIcon: <Path/>,
      //   actions:[
      //     {
      //       title:'a',
      //       icon: <A/>
      //     }
      //   ]
      // },
      {
        title:'Point',
        leftIcon: <Point/>,
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <D/>
            </Button>
          </Col>
        </Row>
      },
      // {
      //   title:'Ring',
      //   leftIcon: <Ring/>,
      //   actions:[
      //     {
      //       title:'b',
      //       icon: <B/>
      //     }
      //   ]
      // },
      // {
      //   title:'Symbol',
      //   leftIcon: <Symbol/>,
      //   actions:[
      //     {
      //       title:'s',
      //       icon: <S/>
      //     }
      //   ]
      // },
      // {
      //   title:'Object',
      //   leftIcon: <Object/>,
      //   actions:[
      //     {
      //       title:'I',
      //       icon: <I/>
      //     }
      //   ]
      // },
      // {
      //   title:'Text',
      //   leftIcon: <Text/>,
      //   actions:[
      //     {
      //       title:'t',
      //       icon: <T/>
      //     }
      //   ]
      // },
      {
        title:'Modify',
        leftIcon: <Modify/>,
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <E/>
            </Button>
          </Col>
        </Row>
      },
      {
        title:'Cut',
        leftIcon: <Slice/>,
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <C/>
            </Button>
          </Col>
        </Row>
      }
      ,
      {
        title:'Slice',
        leftIcon: <Cut/>,
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:15}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <K/>
            </Button>
          </Col>
        </Row>
      },
      {
        title: 'Back to Select',
        leftIcon: <SELECT/>,
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:20}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <ESC/>
            </Button>
          </Col>
        </Row>
      },
      {
        title: 'Multiselect items',
        leftIcon:<MultiSelectSvg/>,
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:20}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <CTRL/>
            </Button>
          </Col>
        </Row>
      },
      {
        title: 'Delete Selected Items',
        leftIcon:<DeleteSvg/>,
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign:'center',paddingRight:20}}>
          <Col span={6}>
            <Button size={'small'} style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6}}>
              <Del/>
            </Button>
          </Col>
        </Row>
      },
      {
        title: 'Duplicate Selected Items',
        leftIcon: <CopyComponent/>,
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign: 'center', paddingRight:10}}>
          <Space>
            <Button size={'small'} style={{border: 1, borderStyle: 'solid', borderColor: '#E5E5E5', borderRadius: 6}}>
              <CTRL/>
            </Button>
            <Button size={'small'} style={{border: 1, borderStyle: 'solid', borderColor: '#E5E5E5', borderRadius: 6}}>
              I
            </Button>
          </Space>
        </Row>
      },
      {
        title: 'Merge Selected Items',
        leftIcon: <MergeItems/>,
        extraKey: <Row justify={'space-around'} align={'middle'} style={{textAlign: 'center', paddingRight:10}}>
          <Space>
            <Button size={'small'} style={{border: 1, borderStyle: 'solid', borderColor: '#E5E5E5', borderRadius: 6}}>
              <CTRL/>
            </Button>
            <Button size={'small'} style={{border: 1, borderStyle: 'solid', borderColor: '#E5E5E5', borderRadius: 6}}>
              M
            </Button>
          </Space>
        </Row>
      }
    ]

      toolKeysList.splice(2, 0, {
        title: 'Path',
        leftIcon: <Path />,
        extraKey: <Row justify={'space-around'} align={'middle'} style={{ textAlign: 'center', paddingRight: 15 }}>
          <Col span={6}>
            <Button size={'small'} style={{ border: 1, borderStyle: 'solid', borderColor: '#E5E5E5', borderRadius: 6 }}>
              <D />
            </Button>
          </Col>
        </Row>,
      });

    return(
      <Drawer
        title="Keyboard Shortcut"
        width={400}
        placement={'right'}
        visible={this.props.shouldKeyboardShortCutModalOpen}
        key={'right'}
        onClose={()=>{this.props.openKeyBoardShortCutModal(false)}}
        headerStyle={{border:0,paddingLeft:10 }}
        bodyStyle={{paddingLeft:5,paddingTop:0,paddingRight:2}}
      >
        <Tabs defaultActiveKey={this.state.selectedTab}  onChange={this.onChangeTab}>
          <TabPane tab="Essentials" key="essentialTab">
            <div>
            <List
              size="small"
              dataSource={essentialKeysList}
              renderItem={item => <List.Item
                style={{paddingRight:0, paddingLeft:5}}
                extra={item.extraKey}
                 // extra={
                 //   <Button.Group>
                 //     {item.actions.map((option)=><Button style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6, marginRight:6}}>
                 //       {option.icon}
                 //     </Button>)}
                 //   </Button.Group>
                 // }
              >
                {item.title}
              </List.Item>}
            />

            </div>
          </TabPane>
          <TabPane tab="Tools" key="toolTab">
            <List
              size="small"
              dataSource={toolKeysList}
              renderItem={item => <List.Item
                style={{paddingRight:0, paddingLeft:5}}
                extra={item.extraKey}

                // extra={
                //   <Button.Group>
                //     {item.actions.map((option)=><Button style={{border:1,borderStyle:'solid', borderColor:'#E5E5E5',borderRadius:6, marginRight:6}}>
                //       {option.icon}
                //     </Button>)}
                //   </Button.Group>
                // }
              >
                <List.Item.Meta avatar={item.leftIcon} title= {item.title}/>

              </List.Item>}
            />
          </TabPane>
        </Tabs>
      </Drawer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    shouldKeyboardShortCutModalOpen: state.announcements.shouldKeyboardShortCutModalOpen,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    openKeyBoardShortCutModal: (payload) =>
      dispatch(openKeyBoardShortCutModal(payload)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(KeyboardDetail);