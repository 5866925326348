import useAuth from '@/modules/auth/hooks';
import { UserRole, UserRoleId } from '@/modules/common/types';

interface UseRoleResponse {
  /** Role of Current logged-in user as {@link UserRole} */
  role: UserRole | null;

  /** RoleId of Current logged-in user as {@link UserRoleId} */
  roleId: UserRoleId;

  /** Is LoggedIn user UserRole.Guest ? */
  isGuest: boolean;

  /** Is LoggedIn user UserRole.Admin ? */
  isAdmin: boolean;

  /** Is LoggedIn user UserRole.Viewer ? */
  isViewer: boolean;

  /** Is LoggedIn user UserRole.Creator ? */
  isCreator: boolean;

  /** Is LoggedIn user UserRole.Editor ? */
  isEditor: boolean;
}

const useRole = (): UseRoleResponse => {
  const { role, roleId } = useAuth();

  return {
    role,
    roleId,
    isAdmin: role === UserRole.Admin,
    isCreator: role === UserRole.Creator,
    isViewer: role === UserRole.Viewer,
    isGuest: role === UserRole.Guest,
    isEditor: role === UserRole.Editor,
  };
};

export default useRole;
