import React, { useEffect } from 'react';
import { Dropdown, Menu, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import SnapNav from '@/assets/images/SnapNav.svg';
import { findLayerStatus, trackEvents } from '@/helpers/utilities';
import './style.less';
import { DefaultReportPayload } from '../../../../api/property-reports/schema';
import { OrderNearmap } from '../../../../../store/order/state';
import { createNewPropertyReport } from '../../../../api/property-reports/api';
import { showNotification } from '../../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../storybook/NotificationToast/types';
import { useDispatch } from 'react-redux';
import { switchBasemap, toggleFullScreenLoader } from '../../../../../store/ui/actions';
import { MapViewType } from '../../../../pages/project/components/MapControls';
import { NotifyError } from '@/helpers/notification-utils';
import { SnapPanelOption } from '@siterecon/sr-component/dist/sr-component-library';
import useRole from '@/modules/auth/guards/RoleGuard/useRole';
import { useSubscription } from '@/modules/subscription/hooks';

interface SnapshotFlowDDProps {
  available: boolean;
  editing: boolean;
  currentViewData: {
    orderId: string;
    viewId: string;
    isBaseView: boolean;
  };
  selectedNearmap: OrderNearmap;
  selectDeselectFeature: any;
  layerList: any;
  setOnSnap: any;
  snapshotV1Flow: boolean;
  snapshotV2Flow: boolean;
  toggleSnapshotV1Flow: (checked: boolean) => void;
  toggleSnapshotV2Flow: (visible: boolean) => void;
  setIsNotesPanelExpanded: (checked: boolean) => void;
  togglePropertyReport: (propertyReportId: number) => void;
}

const SnapshotFlowDD: React.FC<SnapshotFlowDDProps> = ({
  available,
  editing,
  currentViewData,
  selectedNearmap,
  layerList,
  selectDeselectFeature,
  snapshotV1Flow,
  snapshotV2Flow,
  toggleSnapshotV1Flow,
  toggleSnapshotV2Flow,
  setIsNotesPanelExpanded,
  togglePropertyReport,
}: SnapshotFlowDDProps) => {
  const dispatch = useDispatch();
  const { role } = useRole();
  const { currentPlan } = useSubscription();

  const sendSegmentEvent = (isSnapshotStudio: boolean): void => {
    let eventName = 'snapshot__trigger-legacy';
    if (isSnapshotStudio) {
      eventName = 'snapshot__trigger-studio';
    }

    trackEvents(eventName, {
      orderId: currentViewData?.orderId,
      viewId: currentViewData?.viewId,
      isBaseView: currentViewData?.isBaseView,
      layerList: findLayerStatus(selectDeselectFeature),
      layerCount: layerList?.length,
      triggerSource: 'navbar',
    });
  };

  const onSnapshotV1Click = (): void => {
    sendSegmentEvent(false);
    toggleSnapshotV1Flow(!snapshotV1Flow);
    toggleSnapshotV2Flow(false);
  };

  const onSnapshotV2Click = (): void => {
    sendSegmentEvent(true);
    toggleSnapshotV2Flow(!snapshotV2Flow);
    toggleSnapshotV1Flow(false);
  };

  const onCreateNewPropertyReport = () => {
    dispatch(toggleFullScreenLoader(true, 'Please wait. Initializing snapshot report...'));
    toggleSnapshotV2Flow(false);
    toggleSnapshotV1Flow(false);
    setIsNotesPanelExpanded(false);

    const defaultReportPayload: DefaultReportPayload = {
      viewId: parseInt(currentViewData?.viewId),
      nearmap: selectedNearmap && selectedNearmap.surveyId === "satellite" ? null : {
        angle: selectedNearmap.type,
        date: selectedNearmap.date,
        surveyId: selectedNearmap.surveyId,
      },
    };
    const segmentPayload = {
      orderId: parseInt(currentViewData?.orderId),
      viewId: parseInt(currentViewData?.viewId),
      hasNearmap: !!selectedNearmap,
      triggerSource: 'navbar',
      role: role,
      currentPlan: currentPlan,
    };
    // create new property report
    createNewPropertyReport(defaultReportPayload)
      .then((response) => {
        const { data } = response;
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Report created successfully');
        togglePropertyReport(data.propertyReportId);
        trackEvents('report-studio__open', {...segmentPayload, reportId: data.propertyReportId});
      })
      .catch((error) => {
        NotifyError('Error creating report');
      })
      .finally(() => dispatch(toggleFullScreenLoader(false, '')));
  };

  useEffect(() => {
    const shortcut = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'p') {
        e.preventDefault();
        dispatch(switchBasemap(MapViewType.SATELLITE));
        toggleSnapshotV2Flow(true);
      }
      if (e.ctrlKey && e.key === 'o') {
        e.preventDefault();
        dispatch(switchBasemap(MapViewType.SATELLITE));
        onCreateNewPropertyReport();
      }
      if (e.key === 'Escape' || e.key === 'Esc') {
        toggleSnapshotV2Flow(false);
        togglePropertyReport(-1);
      }
    };
    if (available) {
      document.addEventListener('keydown', shortcut);
    }
    return () => {
      if (available) {
        document.removeEventListener('keydown', shortcut);
      }
    };
  }, [selectedNearmap, currentViewData]);

  if (!available) {
    return (
      <div
        style={{
          cursor: 'pointer',
        }}
      >
        <Tooltip
          title={
            editing
              ? 'Editing tools are currently active'
              : 'This option is available after the property is approved'
          }
        >
          <div className='tool--navbar'>
            <img alt='Snapshot Tool' src={SnapNav} />
          </div>
        </Tooltip>
      </div>
    );
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={onSnapshotV1Click}>
        <SnapPanelOption title="Legacy Snapshot" />
      </Menu.Item>
      <Menu.Item onClick={onSnapshotV2Click}>
        <SnapPanelOption title="Snapshot Studio" isBeta />
      </Menu.Item>
      <Menu.Item onClick={onCreateNewPropertyReport}>
        <SnapPanelOption title="Report Studio" isBeta />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <div className='tool--snap-nav'>
          <img alt='Snapshot Tool' src={SnapNav} className='mr-2' />
          <DownOutlined />
        </div>
      </Dropdown>
    </>
  );
};

export default SnapshotFlowDD;
