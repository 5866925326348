import { memo, useEffect, useState } from 'react';
import './style.less';

const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;
const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;
const MICROSECONDS_PER_SECOND = 1000;

interface CountdownTimerProps {
  endsAt: Date | string;
  delayedText: string;
  textStyle: React.CSSProperties;
}

const CountdownTimer = ({
  endsAt,
  delayedText,
  textStyle,
}: CountdownTimerProps) => {
  const [durationInSeconds, setDurationInSeconds] = useState<number>(0);

  const calculateAndSetDuration = () => {
    const now = new Date();

    const endTime = typeof endsAt === 'string' ? new Date(endsAt) : endsAt;

    setDurationInSeconds(
      (endTime.getTime() - now.getTime()) / MICROSECONDS_PER_SECOND
    );
  };

  /** Set interval to update the timer every second */
  useEffect(() => {
    const interval = setInterval(
      calculateAndSetDuration,
      MICROSECONDS_PER_SECOND
    );

    return () => {
      /** Clear the interval when component is unmounted */
      clearInterval(interval);
    };
  }, []);

  useEffect(calculateAndSetDuration, [endsAt]);

  if (durationInSeconds < 0 || isNaN(durationInSeconds)) {
    return <span style={{ ...textStyle }}>{delayedText}</span>;
  }

  if (durationInSeconds < SECONDS_PER_MINUTE) {
    return <span>{Math.round(durationInSeconds)}s</span>;
  }

  if (durationInSeconds < SECONDS_PER_HOUR) {
    const minutes = Math.floor(durationInSeconds / SECONDS_PER_MINUTE);
    const seconds = Math.round(durationInSeconds % SECONDS_PER_MINUTE);

    return (
      <>
        <span>{minutes}m</span>
        &nbsp;
        <span>{seconds}s</span>
      </>
    );
  }

  if (durationInSeconds < SECONDS_PER_DAY) {
    const hours = Math.floor(durationInSeconds / SECONDS_PER_HOUR);
    const minutes = Math.round(
      (durationInSeconds % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE
    );

    return (
      <>
        <span>{hours}h</span>
        &nbsp;
        <span>{minutes}m</span>
      </>
    );
  }

  const days = Math.floor(durationInSeconds / SECONDS_PER_DAY);
  const hours = Math.round(
    (durationInSeconds % SECONDS_PER_DAY) / SECONDS_PER_HOUR
  );

  return (
    <>
      <span>{days}d</span>
      &nbsp;
      <span>{hours}h</span>
    </>
  );
};

export default memo(CountdownTimer);
