import React, { FC, useMemo } from 'react';
import { TooltipProps } from 'antd';
import { GuardProps } from '@/modules/common/types';
import BaseGuard from '@/modules/common/guards/BaseGuard';
import { WrapperProps } from '@/modules/common/components/Wrapper';
import useLayer from './useLayer';
import { ESIMATION_DISABLE_TOOLTIP } from '@/components/constants';
import { ViewType } from '@/modules/common/types';
import './style.less';

export interface LayerGuardProps
  extends GuardProps,
    Omit<WrapperProps, 'tooltip'> {
  /** Single or List of {@link ViewType} for which we want to display the content */
  // view?: ViewType | ViewType[];

  /**  Single or List of {@link ViewType} for which we *don't* want to display the content */
  // except?: ViewType | ViewType[];

  tooltip?: WrapperProps['tooltip'] | boolean;

  /**  If by[ass is true, automatically return true */
  bypass?: boolean;
}

const LayerGuard: FC<LayerGuardProps> = ({
  fallback,
  children,
  tooltip,
  or,
  bypass,
  ...rest
}) => {
  const layer = useLayer();

  const tooltipProps: TooltipProps | undefined = useMemo(() => {
    const buildProps = (props: TooltipProps) => {
      return {
        overlayClassName: 'view-guard-tooltip',
        ...props,
      };
    };

    if (typeof tooltip === 'undefined') return tooltip;

    if (typeof tooltip === 'boolean') {
      return tooltip
        ? buildProps({
            title: ESIMATION_DISABLE_TOOLTIP,
          })
        : undefined;
    }

    if (typeof tooltip === 'string') {
      return buildProps({ title: tooltip });
    }

    return buildProps(tooltip);
  }, [tooltip]);

  const accessCondition = useMemo(() => {
    if (bypass) return true;
    if (!layer) return false;

    return layer;
  }, [layer]);

  const canRender = useMemo(() => {
    if (typeof or !== 'undefined') {
      return accessCondition || or;
    }

    return accessCondition;
  }, [accessCondition, or]);

  return (
    <BaseGuard
      condition={canRender}
      fallback={fallback}
      tooltip={tooltipProps}
      {...rest}
    >
      {children}
    </BaseGuard>
  );
};

LayerGuard.displayName = 'LayerGuard';

export { useLayer };

export default LayerGuard;
