import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import { Box } from '@/modules/common/elements';
import useOrganization from '@/modules/auth/hooks/organization';
import Text from '@/components/elements/text/Text';

import { ReactComponent as CompanyLogo } from '@/assets/Logo.svg';
import { ReactComponent as AccessDeniedLock } from '@project/assets/access-denied-lock.svg';
import AccessWallMapImage from '@project/assets/map.png';
import './style.less';

interface AccessWallProps {
  title?: string;
  content?: string | ReactNode;
  propertyName?: string;
  orgName?: string;
}

const DEFAULT_TITLE = 'You are not authorised to view this page';

const DEFAULT_CONTENT =
  'Reach out to the property creator or workspace admin to provide you with the appropriate access rights.';

const AccessWall = ({
  title = DEFAULT_TITLE,
  content = DEFAULT_CONTENT,
  propertyName,
  orgName,
}: AccessWallProps) => {
  const organization = useOrganization();

  return (
    <Row className='access-wall-container'>
      <Col span={12} className='pt-9 pl-15'>
        <Row style={{ position: 'absolute' }}>
          <Link to='/'>
            <CompanyLogo />
          </Link>
        </Row>
        <Row align='middle' style={{ height: '100%' }}>
          <Row>
            <Row className='w-100 mb-8' align='middle'>
              <Col span={3}>
                <AccessDeniedLock height={56} />
              </Col>
              <Col span={21}>
                <Text type='p39' color='dark-gray' className='mt-5 mb-4'>
                  {title}
                </Text>
              </Col>
            </Row>
            {propertyName && orgName && (
              <Box className='property-org-card w-100 mb-8 p-2'>
                <Row className='mb-1'>
                  <Text type='p47' color='dark-gray'>
                    {propertyName}
                  </Text>
                </Row>
                <Row>
                  <Text type='p7' color='dark-gray'>
                    {orgName}
                  </Text>
                </Row>
              </Box>
            )}
            <Row>
              {typeof content === 'string' ? (
                <Text type='p20' color='gray-shade' className='leading-28'>
                  {content}
                </Text>
              ) : (
                content
              )}
            </Row>
          </Row>
        </Row>
      </Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <img
          src={AccessWallMapImage}
          alt='No Access'
          className='unauthorised-image'
        />
      </Col>
    </Row>
  );
};

export default AccessWall;
