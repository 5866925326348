// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector156IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector156Icon(props: Vector156IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 74 56"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M65.55 47.042L60.082 3.135c-.22-1.799-1.66-3.142-3.36-3.135l-11.98.037a3.289 3.289 0 00-2.368 1.046l-5.686 6.033-33.324.178C1.327 7.305-.24 9.228.03 11.386l5.075 40.58c.219 1.797 1.66 3.138 3.355 3.136l58.089-.116c6.468.133 7.086-1.77 7.086-1.77-7.425 1.637-8.086-6.163-8.086-6.174z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector156Icon;
/* prettier-ignore-end */
