import { getToken } from '@/helpers/auth';
import { getShareMapId } from '@/helpers/utilities/api-utils';
import axios from 'axios';

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const callApi = async (
  method,
  url,
  params = null,
  data = null,
  headers = null
) => {
  if (!headers){
    if (getShareMapId()) {
      headers = {
        'shareid': getShareMapId()
      };
    }else {
      headers = {
        Authorization: 'Token ' + getToken(),
      };
    }
  }

  if (params) {
    url += '?';
    params.forEach((par) => {
      url += `${par.key}=${par.value}&`;
    });
  }

  const options = {
    method,
    url,
    headers,
    data,
  };

  return requestData(options);
};

export const requestData = (options) => {
  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        resolve(res);
      })
      .catch((er) => {
        reject(er.response);
      });
  });
};
