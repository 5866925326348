import { useSelector } from 'react-redux';
import { IStore } from '@/store/types';
import { AccessRight } from '@/modules/common/types';

/** Returns Access Right of current logged-in user on MapPage. If Access is loading returns "null" */
const useAccess = () => {
  return useSelector<IStore, AccessRight | null>(
    (state) => state.order.userAccessRight as AccessRight
  );
};

export default useAccess;
