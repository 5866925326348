import React from 'react';

const LineTool = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <rect
      x='6'
      y='7.43848'
      width='1.2'
      height='16'
      rx='0.6'
      transform='rotate(-46 6 7.43848)'
      fill='#333333'
    />
  </svg>
);

export default LineTool;
