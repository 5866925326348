import React, { useState } from 'react';
import { Button, Input, Select } from 'antd';
import { useDispatch } from 'react-redux';

import Text from '../../../elements/text/Text.jsx';

import './style.less';
import '../../feature-attribute.less';

import { setCreationFormValue } from '../../../../store/attributeFeature/actions';
import { useBetaRelease } from '@/modules/common/guards/BetaRelease';
import { BetaFeature } from '@/modules/common/types';

const { TextArea } = Input;
const { Option } = Select;

export const CustomFeatureForm = (props) => {
  const [formValue, setFormValue] = useState({});
  const dispatch = useDispatch();
  const setHandler = (e) => {
    let key, value;

    if (e.target) {
      key = e.target.name;
      value = e.target.value;
    } else {
      key = e.split(' ')[0];
      value = e.split(' ')[1];
    }

    setFormValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const nextHandler = () => {
    props.pageHandler(1);
    props.setType('new');
    dispatch(setCreationFormValue(formValue));
  };

  const cancelHandler = () => {
    props.onCancel(1);
  };

  return (
    <div className='feature-form-container'>
      <Text className='form-heading' type='' color='dark-gray'>
        Create New Layer
      </Text>
      <div className='separation-line' />
      <div className='form-wrapper'>
        <div className='input-with-heading'>
          <Text>Layer Name</Text>
          <Input
            name='name'
            className='input-holder'
            type='text'
            placeholder='Layer Name'
            onChange={setHandler}
            value={formValue.name}
          />
        </div>

        <div className='input-with-heading'>
          <Text>Layer Type</Text>
          <Select
            placeholder='Layer Type'
            className='input-holder'
            onChange={setHandler}
            value={formValue.type}
          >
            <Option value='type line'>Line</Option>
            <Option value='type point'>Point</Option>
            <Option value='type polygon'>Polygon</Option>
            <Option value='type path'>Path</Option>
          </Select>
        </div>

        <div className='input-with-heading'>
          <Text>Category</Text>
          <Select
            placeholder='Category'
            className='input-holder'
            onChange={setHandler}
            value={formValue.category}
          >
            <Option value='category Hardscape'>Hardscape</Option>
            <Option value='category Softscape'>Softscape</Option>
          </Select>
        </div>

        <div className='input-with-heading'>
          <Text>Description</Text>
          <TextArea
            name='longDesc'
            className='input-holder'
            placeholder='Description about the layer'
            rows={4}
            onChange={setHandler}
            value={formValue.description}
          />
        </div>
      </div>
      <div className='buttons-wrapper'>
        <Button type='text' onClick={cancelHandler}>
          Cancel
        </Button>
        <Button
          type='primary'
          onClick={nextHandler}
          disabled={
            !formValue.name ||
            !formValue.type ||
            !formValue.category ||
            !formValue.longDesc
          }
        >
          Next
        </Button>
      </div>
    </div>
  );
};
