// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon107IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon107Icon(props: Icon107IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 12 12"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M1.25 2c0-.69.56-1.25 1.25-1.25h7c.69 0 1.25.56 1.25 1.25v8c0 .69-.56 1.25-1.25 1.25h-7c-.69 0-1.25-.56-1.25-1.25V2zm8.25-.75h-7a.75.75 0 00-.75.75v8c0 .414.336.75.75.75h7a.75.75 0 00.75-.75V2a.75.75 0 00-.75-.75z"
        }
        fill={"#666"}
        stroke={"#666"}
        strokeWidth={".25"}
      ></path>

      <rect
        x={"3"}
        y={"2.75"}
        width={"6"}
        height={"2"}
        rx={".2"}
        fill={"#666"}
      ></rect>

      <rect
        x={"3"}
        y={"5.75"}
        width={"1.25"}
        height={"1.25"}
        rx={".25"}
        fill={"#666"}
      ></rect>

      <rect
        x={"3"}
        y={"8"}
        width={"1.25"}
        height={"1.25"}
        rx={".25"}
        fill={"#666"}
      ></rect>

      <rect
        x={"5.375"}
        y={"5.75"}
        width={"1.25"}
        height={"1.25"}
        rx={".25"}
        fill={"#666"}
      ></rect>

      <rect
        x={"5.375"}
        y={"8"}
        width={"1.25"}
        height={"1.25"}
        rx={".25"}
        fill={"#666"}
      ></rect>

      <rect
        x={"7.75"}
        y={"5.75"}
        width={"1.25"}
        height={"3.5"}
        rx={".25"}
        fill={"#666"}
      ></rect>
    </svg>
  );
}

export default Icon107Icon;
/* prettier-ignore-end */
