import { useEffect, useState } from 'react';
import { ReactComponent as PlatoAppLogoIcon } from '../../../assets/svgs/plato-app-logo.svg';
import { ReactComponent as CloseMobileApp } from '../../../assets/close-mobile-notfn.svg';

import './styles.less';

const OpenMobileApp = ({ handleClick, showMobileCard = false }) => {

  const [showMe, setShowMe] = useState(false);

  const handleClickClose = () => {
    setShowMe(false);
  };

  useEffect(() => {
    if (showMobileCard === true || showMobileCard === "true") {
      setShowMe(true);
    }
  }, [showMobileCard]);

  return (
    <>
      {showMobileCard && showMe ? (
        <div className='open-mobile-app'>
          <a className='open-mobile-link' onClick={handleClick}>
            <div>
              <PlatoAppLogoIcon />
            </div>
            <div>Open Mobile App</div>
          </a>
          <button className='close-mobile-app'>
            <CloseMobileApp onClick={handleClickClose}></CloseMobileApp>
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default OpenMobileApp;
