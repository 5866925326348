// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon101IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon101Icon(props: Icon101IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M8.005 10.725c-.253 0-.485-.104-.696-.311L3.407 6.422a.923.923 0 01-.18-.264.858.858 0 01-.057-.316.832.832 0 01.416-.733.793.793 0 01.422-.116c.236 0 .443.09.622.269l3.591 3.696h-.427l3.581-3.696a.849.849 0 01.617-.27c.155 0 .295.04.422.117a.811.811 0 01.3.305.803.803 0 01.117.428.79.79 0 01-.238.58l-3.897 3.992a.964.964 0 01-.69.311z"
        }
        fill={"#666"}
      ></path>
    </svg>
  );
}

export default Icon101Icon;
/* prettier-ignore-end */
