export const BULK_ORDER_ACTIONS = {
  OPEN_BULK_ORDER_DETAILS_MODAL: 'OPEN_BULK_ORDER_DETAILS_MODAL',
  CLOSE_BULK_ORDER_DETAILS_MODAL: 'CLOSE_BULK_ORDER_DETAILS_MODAL',
  BULK_ORDER_REDUX_CLEANUP: 'BULK_ORDER_REDUX_CLEANUP',
  OPEN_BULK_ORDER_LIST: 'OPEN_BULK_ORDER_LIST',
  CLOSE_BULK_ORDER_LIST: 'CLOSE_BULK_ORDER_LIST',
  VISIBLE_BULK_ORDER_DETAILS_MODAL: 'VISIBLE_BULK_ORDER_DETAILS_MODAL',
  LOAD_BULK_ORDER_DETAILS_STARTED: 'LOAD_BULK_ORDER_DETAILS_STARTED',
  LOAD_BULK_ORDER_DETAILS_SUCCESS: 'LOAD_BULK_ORDER_DETAILS_SUCCESS',
  LOAD_BULK_ORDER_DETAILS_FAILURE: 'LOAD_BULK_ORDER_DETAILS_FAILURE',
  REFETCH_BULK_ORDERS_LIST_DATA: 'REFETCH_BULK_ORDERS_LIST_DATA',
  RESET_REFETCH_BULK_ORDERS_LIST_DATA_STATE:
    'RESET_REFETCH_BULK_ORDERS_LIST_DATA_STATE',
  ORDER_LIST_BULK_ORDER: 'ORDER_LIST_BULK_ORDER',
  NEXT_SELECTED_ORDER: 'NEXT_SELECTED_ORDER',
  PREV_SELECTED_ORDER: 'PREV_SELECTED_ORDER',
  INDEXED_SELECTED_ORDER: 'INDEXED_SELECTED_ORDER',
  CONFIRM_PARCEL: 'CONFIRM_PARCEL',
  UPDATE_PARCEL: 'UPDATE_PARCEL',
  UPDATE_SELECTED_ORDER_INDEX: 'UPDATE_SELECTED_ORDER_INDEX',
  UPDATE_SUMMERY: 'UPDATE_SUMMERY',
  APPEND_ORDER_LIST: 'APPEND_ORDER_LIST',
  UPDATE_TAG_BY_ORDER_ID: 'UPDATE_TAG_BY_ORDER_ID',
  SKIP_BULK_ORDER: 'SKIP_BULK_ORDER',
  DELETE_BULK_ORDER: 'DELETE_BULK_ORDER',
  SKIP_ORDER_REASON: 'SKIP_ORDER_REASON',
  ADD_MORE_BULK_ORDER_DETAILS_MODAL_OPEN:
    'ADD_MORE_BULK_ORDER_DETAILS_MODAL_OPEN',
  ADD_MORE_BULK_ORDER_DETAILS_MODAL_CLOSE:
    'ADD_MORE_BULK_ORDER_DETAILS_MODAL_CLOSE',
};
