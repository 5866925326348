import React from 'react';
import { Modal } from 'antd';
import CustomActionModal from '../../../../CustomActionModal';

interface ResetUnsyncedEditsModalProps {
  visible: boolean;
  onClose: () => void;
  onReset: () => void;
}

const ResetUnsyncedEditsModal = ({
  visible,
  onClose,
  onReset,
}: ResetUnsyncedEditsModalProps) => {
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      closable={false}
      centered
      width={'544px'}
    >
      <CustomActionModal
        icon='https://fragmentor.siterecon.ai/subscription-assets/svg/warning_yellow.svg'
        title='Reset all unsaved changes'
        description='Are you sure you want to reset the changes that you have made. This action can not be undone.'
        secondaryButtonLabel='Cancel'
        primaryButtonLabel='Confirm'
        onSecondaryButtonClick={onClose}
        onPrimaryButtonClick={onReset}
      />
    </Modal>
  );
};

export default ResetUnsyncedEditsModal;
