import { SubscriptionFeature } from '@/modules/subscription/types';
import React, { FC, SVGProps } from 'react';
import BaseChip, {
  ChipProps,
} from '@/modules/subscription/components/Chips/BaseChip';
import { useSubscriptionFeature } from '@/modules/subscription/hooks';
import { SubscriptionPlan } from '@/components/subscription/helpers/enum';
import { SubscriptionPlanIcons } from './constant';

interface SubscriptionChipProps extends ChipProps {
  /** {@link SubscriptionFeature} for which you want to show the Chip */
  feature: SubscriptionFeature;
  // in case there is a custom icon to be used
  iconProp?: Function;
}

const getIcon = (plan: SubscriptionPlan): FC<SVGProps<any>> | string | null => {
  switch (plan) {
    case SubscriptionPlan.PROFESSIONAL:
      return SubscriptionPlanIcons.PROFESSIONAL;
    case SubscriptionPlan.TEAMS:
      return SubscriptionPlanIcons.TEAMS;
    case SubscriptionPlan.ENTERPRISE:
      return SubscriptionPlanIcons.ENTERPRISE;
    default:
      return null;
  }
};

/** A component which renders "PRO", "ADV", "ENT" chip as per given feature */
const SubscriptionChip: FC<SubscriptionChipProps & SVGProps<any>> = ({
  feature,
  iconProp,
  ...rest
}) => {
  if (iconProp) {
    return <BaseChip icon={iconProp} {...rest} />;
  }

  const { data, enabled } = useSubscriptionFeature(feature);

  if (!data || enabled) return null;

  const icon = getIcon(data.subscriptionPlan);

  return icon ? <BaseChip icon={icon} {...rest} /> : null;
};

export default SubscriptionChip;
