// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector422IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector422Icon(props: Vector422IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 15 8"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.562.113c3.43.589 6.086 3.337 7.024 6.816l-2.348.788c-.675-2.5-2.583-4.474-5.048-4.896-2.076-.356-4.07.47-5.434 2.03L0 2.938C1.897.769 4.673-.381 7.562.114z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector422Icon;
/* prettier-ignore-end */
