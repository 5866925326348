// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector413IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector413Icon(props: Vector413IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 87 62"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M82.418 0H3.695C1.655 0 0 1.441 0 3.219v54.675c0 1.778 1.654 3.22 3.695 3.22H82.42c2.04 0 3.695-1.442 3.695-3.22V3.22C86.114 1.44 84.459 0 82.418 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector413Icon;
/* prettier-ignore-end */
