// @ts-ignore
import { NotifyError } from '@/helpers/notification-utils';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { showNotification } from '../../../../../../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../../../../../storybook/NotificationToast/types';

const axiosInstance = getAxiosInstance();

export const postInviteAccess = async (data: any, orderId: any) => {
  const URL = `api/phylax/v1/order/${orderId}/invite-access`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(URL, data)
      .then((response) => {
        if (response.status === 200 || 204 || 201) {
          resolve(response.status);
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            'Invitation sent sucessfully'
          );
        } else {
          NotifyError(response.data.msg || 'Something went wrong');
        }
      })
      .catch((error) => {
        NotifyError('Something went wrong');
        reject(error);
      });
  });
};

export const singleMemberAccessChange = async (data: any, orderId: any) => {
  const URL = `api/phylax/v1/order/${orderId}/invite-access`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(URL, data)
      .then((response) => {
        if (response.status === 200 || 204 || 201) {
          resolve(response.status);
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            'Permission changed successfully'
          );
        } else {
          NotifyError(response.data.msg || 'Something went wrong');
        }
      })
      .catch((error) => {
        NotifyError('Something went wrong');
        reject(error);
      });
  });
};

export const postWorkspaceAccess = async (data: any, orderId: any) => {
  const URL = `api/phylax/v1/order/${orderId}/workspace-access`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(URL, data)
      .then((response) => {
        if (response.status === 200 || 204) {
          resolve(response.status);
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            'Permission changed successfully'
          );
          return;
        } else {
          NotifyError(response.data.msg || 'Something went wrong');
        }
        resolve(null);
      })
      .catch((error) => {
        NotifyError('Something went wrong');
        reject(error);
      });
  });
};

export const postPublicAccess = async (data: any, orderId: any) => {
  const URL = `api/phylax/v1/order/${orderId}/public-access`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(URL, data)
      .then((response) => {
        if (response.status === 200 || 204) {
          resolve(response.status);
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            'Permission changed successfully'
          );
          return;
        } else {
          NotifyError(
            response.data.msg || response.data.detail || 'Something went wrong'
          );
        }

        resolve(null);
      })
      .catch((error) => {
        NotifyError('Something went wrong');
        reject(error);
      });
  });
};

export const postInviteOnlyAccess = async (orderId: any) => {
  const URL = `api/phylax/v1/order/${orderId}/invite-only-access`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(URL)
      .then((response) => {
        if (response.status === 200 || 204) {
          resolve(response.status);
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            'Permission changed successfully'
          );
          return;
        } else {
          NotifyError(
            response.data.msg || response.data.detail || 'Something went wrong'
          );
        }

        resolve(null);
      })
      .catch((error) => {
        NotifyError('Something went wrong');
        reject(error);
      });
  });
};

export const getInvitedUsers = async (orderId: number) => {
  const URL = `/api/phylax/v1/order/invitees/${orderId}`;
  return axiosInstance
    .get(URL)
    .then((response) => response.data)
    .catch((error) => {
      NotifyError('Something went wrong');
      return Promise.reject(error?.response?.data || error);
    });
};

export const getDefaultAccess = async (orderId: number) => {
  const URL = `api/phylax/v1/order/${orderId}/default-access`;

  return axiosInstance
    .get(URL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      NotifyError('Something went wrong');
      return Promise.reject(error?.response?.data || error);
    });
};

export const deleteUserFromProperty = async (data: any, orderId: number) => {
  const URL = `api/phylax/v1/order/${orderId}/invite/delete`;
  return axiosInstance
    .delete(URL, {
      data: data,
    })
    .then((response) => {
      showNotification(
        NOTIFICATIONS_TYPES.SUCCESS,
        response?.data?.message || 'User Deleted'
      );
      return response.data;
    })
    .catch((error) => {
      NotifyError('Something went wrong');
      return Promise.reject(error?.response?.data || error);
    });
};
