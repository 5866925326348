import ReactDOM from 'react-dom';
import React from 'react';
import { NotificationContainer } from 'react-notifications';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import App from './App';
import { isAuthenticated } from './helpers/auth';
import {
  DEFAULT_APP_TITLE,
  ENV,
  IS_LOGGING_ENABLED,
  IS_USER_PILOT_ENABLED,
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_USER_PILOT
} from './helpers/constants';
import { Store } from './store';

import 'react-notifications/lib/notifications.css';
import { BrowserRouter } from 'react-router-dom';
import './App.less';
import ApplicationHead from './components/helmet';
import ErrorBoundary from './crashHandling/ErrorBoundary';
import { identifyEvent } from './helpers/utilities';
import { Userpilot } from 'userpilot';
import './styles/main.less';
import RouterPlusProvider from '@/modules/router-plus/context/provider';

import { createRoot } from 'react-dom/client';

// Microsoft Login
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

// Google Login
import { GoogleOAuthProvider } from '@react-oauth/google';
// process.env.NODE_ENV === 'production' &&

// init sentry 
Sentry.init({
  dsn: 'https://d85981346606ace4cfb004faa5dcd749@o4505283642654720.ingest.us.sentry.io/4507134744592384', // sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
    }),
  ],
  attachStacktrace: true,
  environment: ENV,
  allowUrls: ['[a-z]*.siterecon.ai', '[a-z]*.netlify.app/'],
  beforeSend: (event) => {
    // sending only the error message
    return event;
  },
  // release: 'siterecon@1.0.0',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});


if (!IS_LOGGING_ENABLED) {
  console.log = function () {
  };
}

Store.subscribe(() => {
});

if (IS_USER_PILOT_ENABLED) {
  Userpilot.initialize(REACT_APP_USER_PILOT);
}

window.analytics.ready(function () {
  identifySegment();
});

const identifySegment = () => {
  const { user } = JSON.parse(localStorage.getItem('sr_info')) || {};
  if (!user) return;
  identifyEvent(user?.email, { name: user?.first_name });
};
const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={Store}>
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <RouterPlusProvider>
              <ApplicationHead title={DEFAULT_APP_TITLE} />
              <App />
            </RouterPlusProvider>
          </BrowserRouter>
        </MsalProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  </Provider >
);
