import React, { useEffect, useRef, useState } from 'react';
import { Styler } from './styler';
import Text from '../../../elements/text/Text';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import EmTakeOffLayerChips from '../../../PlasmicComponents/EmTakeOffLayerChips';
import { ReactComponent as DownIcon } from '../../../../assets/downArrow.svg';
import './style.less';

export const LayerStyling = ({
  layerType,
  isVisible,
  setVisiblity,
  handleChange,
  name,
  defaultValues,
  layerRefs,
  resetLayerStyles,
  layerId,
  layerSource,
  resetStyle,
}) => {
  const [height, setHeight] = useState(0);
  const content = useRef(null);
  const visblityHandler = (e) => {
    e.target.id !== content.current?.id &&
      setVisiblity((prevState) => !prevState);
  };

  useEffect(() => {
    if (isVisible && content.current) setHeight(content.current.scrollHeight);
    else setHeight(0);
  }, [isVisible]);

  return (
    <div className='pointer parent-wrapper bg-white layer-styling'>
      <div
        onClick={visblityHandler}
        className='flex align-center justify-between gap-10 px-3 my-2'
      >
        <Box className='flex align-center gap-6' style={{ height: '20px' }}>
          <Typography fontSize={12} lineHeight={'20px'} fontWeight={500}>
            Layer Styling
          </Typography>
          <Box style={{ height: 16 }}>
            <EmTakeOffLayerChips
              featureType={layerType}
              styleObject={{
                strokeColor: defaultValues?.color,
                fillColor: defaultValues?.fillColor,
                symbolColor: defaultValues?.symbolColor,
                symbolType: defaultValues?.symbolType,
              }}
            />
          </Box>
        </Box>
        <Box
          style={{ width: 20, height: 20 }}
          className='flex-justify-align-center'
        >
          <DownIcon className={`${isVisible && 'toggle-arrow'}`} />
        </Box>
      </div>
      {isVisible && <div className='draw-line' />}
      <div
        id='styler-tool-div'
        ref={content}
        className={`style-tools-container px-2 ${
          isVisible ? 'mt-3 mb-2' : 'hidden'
        }`}
        style={{ maxHeight: `${height}px` }}
      >
        <Styler
          handleChange={handleChange}
          value={defaultValues}
          shape={layerType}
          layerRefs={layerRefs}
          resetLayerStyles={resetLayerStyles}
          layerId={layerId}
          layerSource={layerSource}
          resetStyleToDefault={resetStyle}
        />
      </div>
    </div>
  );
};
