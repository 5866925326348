// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon78IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon78Icon(props: Icon78IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M16.002 11.77a.94.94 0 01-.088.404c-.055.12-.143.24-.264.357l-4.423 4.336a.893.893 0 01-.657.27.9.9 0 01-.468-.13.944.944 0 01-.346-.333.913.913 0 01-.123-.469c0-.258.1-.488.299-.691l3.861-3.75L9.932 8.02a.93.93 0 01-.3-.686c0-.172.042-.328.124-.469a.945.945 0 01.346-.334.9.9 0 01.468-.129c.258 0 .477.088.657.264l4.423 4.336a1.04 1.04 0 01.352.768z"
        }
        fill={"#666"}
      ></path>
    </svg>
  );
}

export default Icon78Icon;
/* prettier-ignore-end */
