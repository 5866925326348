import Column from 'antd/lib/table/Column';
import React, { memo, useCallback, useState } from 'react';
import { Button, Modal, Row, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FileUploadStatus } from '../../../bulk-project-upload/upload-file/UploadStatus';

import { TAB_CATEGORY } from '../../../../pages/myProperties/utils/enum';
import Text from '../../../../elements/text/Text';

import { clearOrderArray } from '../../../../../store/dashboard/action';

import './style.less';
import { _get } from '../../../../../../src/helpers/utilities/lodashUtils';

const SuccessUploadModal = ({
  show = true, // Show By Default => true
  successBulkDetails, // Data
  // setVisible, // Hide The Modal
  setTypeOfModal, // Modal Type
  closeUploadModal,
  bulkOrderId,
}) => {
  const [open, setOpen] = useState(show);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleClose = useCallback(
    (e) => {
      e.preventDefault();
      // dispatch(reloadOrders());
      setOpen(false);
      setTypeOfModal(FileUploadStatus.uploadFile);
      closeUploadModal();

      dispatch(
        clearOrderArray({
          tab: TAB_CATEGORY.bulk,
        })
      );

      dispatch(
        clearOrderArray({
          tab: TAB_CATEGORY.bulkView,
        })
      );

      bulkOrderId &&
        history.push({
          pathname: '/',
          search: `?tab=${TAB_CATEGORY.bulkView}&bulkId=${bulkOrderId}`,
        });
    },
    [bulkOrderId, closeUploadModal, dispatch, history, setTypeOfModal]
  );

  return (
    <Modal
      visible={open}
      onCancel={handleClose}
      className='bulkSuccessModal'
      closable={true}
      centered
      width={1200}
      footer={[
        <Row justify='end' align='middle'>
          <Button
            type='primary'
            className='save-button'
            onClick={() => {
              setOpen(false);
              closeUploadModal();

              history.push({
                pathname: '/',
                search: `?tab=${TAB_CATEGORY.bulkView}&bulkId=${bulkOrderId}`,
              });

              dispatch(
                clearOrderArray({
                  tab: TAB_CATEGORY.bulk,
                })
              );
            }}
          >
            Confirm
          </Button>
        </Row>,
      ]}
    >
      <Row
        className='bulkSuccessModal__wrapper'
        style={{ overflow: 'hidden', flexDirection: 'column' }}
        wrap={false}
      >
        <Row style={{ flexDirection: 'column' }} className='mb-4'>
          <Row className='error-name-processing-complete'>
            <Text type='p45' color='dark-gray'>
              {successBulkDetails?.bulk_order_name} - Processing Complete
            </Text>
          </Row>
          <Text type='p29' color='dark-gray'>
            {successBulkDetails?.bulk_order_data.length} rows found
          </Text>
        </Row>
        <Row className='bulkSuccessModal__lists pb-3'>
          <Table
            dataSource={successBulkDetails?.bulk_order_data}
            pagination={false}
            sticky
            scroll={{ y: '60vh', x: 2000 }}
          >
            <Column
              title={
                <Text type='p13' color='black'>
                  Status
                </Text>
              }
              align='center'
              width={100}
              render={(date, record) => (
                <Text
                  type='p52'
                  className={
                    record.error ? 'color-of-error-red' : 'success__color'
                  }
                >
                  {record.error || 'Success'}
                </Text>
              )}
            />
            <Column
              title={
                <Text type='p13' color='black'>
                  Cust. Name
                </Text>
              }
              dataIndex='fn'
              key='date'
              align='center'
              width={200}
              render={(date, record) => (
                <Text type='p52' color='gray-shade'>
                  {_get(record, 'customer_name')
                    ? _get(record, 'customer_name')
                    : '--'}
                </Text>
              )}
            />
            <Column
              title={
                <Text type='p13' color='black'>
                  Address
                </Text>
              }
              dataIndex='fn'
              key='date'
              align='center'
              render={(date, record) => (
                <Text type='p52' color='gray-shade'>
                  {_get(record, 'address') ? _get(record, 'address') : '--'}
                </Text>
              )}
            />
            <Column
              title={
                <Text type='p13' color='black'>
                  City
                </Text>
              }
              dataIndex='fn'
              key='date'
              align='center'
              width={100}
              render={(date, record) => (
                <Text type='p52' color='gray-shade'>
                  {_get(record, 'city') ? _get(record, 'city') : '--'}
                </Text>
              )}
            />
            <Column
              title={
                <Text type='p13' color='black'>
                  State
                </Text>
              }
              dataIndex='fn'
              key='date'
              align='center'
              width={120}
              render={(date, record) => (
                <Text type='p52' color='gray-shade'>
                  {_get(record, 'state') ? _get(record, 'state') : '--'}
                </Text>
              )}
            />
            <Column
              title={
                <Text type='p13' color='black'>
                  Pin Code
                </Text>
              }
              dataIndex='fn'
              key='date'
              align='center'
              width={80}
              render={(date, record) => (
                <Text type='p52' color='gray-shade'>
                  {_get(record, 'pincode') ? _get(record, 'pincode') : '--'}
                </Text>
              )}
            />
            <Column
              title={
                <Text type='p13' color='black'>
                  Industry
                </Text>
              }
              dataIndex='fn'
              key='date'
              align='center'
              width={150}
              render={(date, record) => (
                <Text type='p52' color='gray-shade'>
                  {_get(record, 'industry') ? _get(record, 'industry') : '--'}
                </Text>
              )}
            />
            <Column
              title={
                <Text type='p13' color='black'>
                  Property Notes
                </Text>
              }
              dataIndex='fn'
              key='date'
              align='center'
              width={140}
              render={(date, record) => (
                <Text type='p52' color='gray-shade'>
                  {_get(record, 'property_notes', '--')
                    ? _get(record, 'property_notes', '--')
                    : '--'}
                </Text>
              )}
            />
            <Column
              title={
                <Text type='p13' color='black'>
                  Measurements
                </Text>
              }
              dataIndex='fn'
              key='date'
              align='center'
              render={(date, record) => (
                <Text type='p52' color='gray-shade'>
                  {_get(record, 'features') ? _get(record, 'features') : '--'}
                </Text>
              )}
            />
            <Column
              title={
                <Text type='p13' color='black'>
                  Cust. ID
                </Text>
              }
              dataIndex='fn'
              key='date'
              align='center'
              width={100}
              render={(date, record) => (
                <Text type='p52' color='gray-shade'>
                  {_get(record, 'customer_id')
                    ? _get(record, 'customer_id')
                    : '--'}
                </Text>
              )}
            />
          </Table>
        </Row>
      </Row>
    </Modal>
  );
};

export default memo(SuccessUploadModal);
