import { Row } from 'antd';
import './style.less';
import classNames from 'classnames';

const CounterButton = ({
  text,
  className,
  style,
  onClick,
  disabled = false,
}) => {
  return (
    <Row
      justify='center'
      align='middle'
      className={classNames('counter-btn', { disabled: disabled }, className)}
      style={style}
      onClick={onClick}
    >
      {text}
    </Row>
  );
};

export default CounterButton;
