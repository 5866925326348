interface IAvatarProps {
  name: string;
  email: string;
  is_deactivated?: boolean;
}

const Avatar = ({ name, email, is_deactivated = false }: IAvatarProps) => {

  return (
    <>
      {(name || email) && (
        <div
          style={{
            alignItems: 'center',
            backgroundColor: `${is_deactivated ? `#999999` : `#8B3D7B`}`,
            borderRadius: '50%',
            color: '#FFFFFF',
            display: 'flex',
            height: 32,
            justifyContent: 'center',
            marginRight: 10,
            width: 32,
          }}
        >
          {name?.charAt(0)?.toUpperCase() ||
            email?.charAt(0)?.toUpperCase() ||
            ''}
        </div>
      )}
    </>
  );
};

export default Avatar;
