import { SubscriptionGuardProps } from '@/modules/subscription/guards/base/BaseSubscriptionGuard';
import SubscriptionGuard from '@/modules/subscription/guards/base/BaseSubscriptionGuard';

import { useSubscriptionFeature } from '@/modules/subscription/hooks';
import React from 'react';
import { FC, useMemo } from 'react';

interface FeatureSubscriptionGuardProps
  extends Omit<SubscriptionGuardProps, 'condition'> {}

const FeatureSubscriptionGuard: FC<FeatureSubscriptionGuardProps> = ({
  feature,
  condition,
  children,
  or,
  ...rest
}) => {
  const { enabled } = useSubscriptionFeature(feature);

  const canRender = useMemo(() => {
    if (typeof or === 'undefined') return enabled;
    return enabled || or;
  }, [enabled, or]);

  return (
    <SubscriptionGuard feature={feature} condition={() => canRender} {...rest}>
      {children}
    </SubscriptionGuard>
  );
};

FeatureSubscriptionGuard.displayName = 'FeatureSubscriptionGuard';

export default FeatureSubscriptionGuard;
