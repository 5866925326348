import useNotificationBar from './useNotificationBar';
import { NotificationBarType } from './types';
import { useEffect } from 'react';
import { useRole } from '@/modules/auth/guards/RoleGuard';
import { NotificationBar } from '@siterecon/sr-component/dist/sr-component-library';

const TYPE = NotificationBarType.GuestUser;

const GuestUserNotificationBar = () => {
  const { isGuest } = useRole();
  const { isVisible, show } = useNotificationBar();

  useEffect(() => {
    if (isGuest) {
      show(TYPE);
    }
  }, [isGuest]);

  if (!isVisible(TYPE)) {
    return null;
  }

  return <NotificationBar className='justify-center' message='You are a guest in this workspace.' />;
};

export default GuestUserNotificationBar;
