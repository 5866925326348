import { useRouterPlusContext } from '@/modules/router-plus/context';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

/** Provides a collection of updated params with "redirect" function of useRouterPlus in replace mode and other url params */
const useRouterPlusSearchParams = () => {
  const { search } = useLocation();
  const { searchParams: routerPlusSearchParams } = useRouterPlusContext();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  // @ts-ignore
  for (const [key, value] of routerPlusSearchParams.entries()) {
    searchParams.set(key, value);
  }

  return searchParams;
};

export default useRouterPlusSearchParams;
