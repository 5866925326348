// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector412IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector412Icon(props: Vector412IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 75 100"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M70.698 0H4.229C1.894 0 0 2.114 0 4.722v89.755C0 97.085 1.894 99.2 4.23 99.2h66.468c2.336 0 4.23-2.114 4.23-4.722V4.722c0-2.608-1.894-4.722-4.23-4.722z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector412Icon;
/* prettier-ignore-end */
