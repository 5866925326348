import React from 'react';
import { Helmet } from 'react-helmet';

function ApplicationHead(props) {
  return (
    <Helmet>
      <title>{props?.title}</title>
    </Helmet>
  );
}

export default ApplicationHead;
