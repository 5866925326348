import {
  Layer,
  LayerPanelData,
  ServiceItem,
  SingleComponent,
} from '../../api/types';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { NumericId } from '@/modules/common/types';

export type ActionCenterData = {
  activeItem: ServiceItem | Layer;

  components: SingleComponent[];
};

export interface IEstimationViewContext {
  layers: Layer[];

  /** If data is `null`, means layers and components API is in progress */
  data: LayerPanelData | null;

  setData: Dispatch<SetStateAction<LayerPanelData | null>>;

  dataViewId: NumericId | null;

  setDataViewId: Dispatch<SetStateAction<NumericId | null>>;

  actionCenterData: ActionCenterData | null;

  setActionCenterData: Dispatch<SetStateAction<ActionCenterData | null>>;

  isServiceChipsVisible: boolean;

  setIsServiceChipsVisible: Dispatch<SetStateAction<boolean>>,

  isServiceItemsListFetching: boolean;

  isLayerDataFetching: boolean;
}

export const EstimationViewContext = createContext<IEstimationViewContext>({
  layers: [],

  data: null,

  setData: () => {},

  dataViewId: null,

  setDataViewId: () => {},

  actionCenterData: null,

  setActionCenterData: () => {},

  isServiceChipsVisible: true, 

  setIsServiceChipsVisible: () => {},

  isServiceItemsListFetching: false,

  isLayerDataFetching: false,
});

export const useEstimationViewContext = () => useContext(EstimationViewContext);
