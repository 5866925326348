import React from 'react';
import './style.less';
import { pathCustomStyle } from '../../../../helpers/mapGlobals/styles';
import { FeatureType } from '../../types';
import { stringModify } from '../../../../helpers/utilities';

export const StyleCircle = (props) => {
  return props.layerType === FeatureType.POLYGON ? (
    <div
      className='circle-wrapper'
      style={{
        border: '2px solid ' + props?.strokeColor,
        background: props.fillColor,
        height: props.height ?? 'calc(100vh * (14 / 820))',
        aspectRatio: 1,
      }}
    />
  ) : props.layerType === FeatureType.POINT ? (
    props.icon ? (
      <div
        style={{ display: 'flex' }}
        dangerouslySetInnerHTML={{
          __html: stringModify(props.icon, {
            fill: props.symbolColor,
            opacity: '1',
          }),
        }}
      />
    ) : (
      <div
        className='circle-wrapper'
        style={{
            border: '2px solid ' + props?.symbolColor,
            height: props.height ?? 'calc(100vh * (14 / 820))',
            aspectRatio: 1,
        }}
      >
        <div
          className='point'
          style={{
              background: props?.symbolColor || '#ccc',
              height: props.height ?? 'calc(100vh * (4 / 820))',
              aspectRatio: 1,
          }}
        />
      </div>
    )
  ) : props.layerType === FeatureType.PATH ? (
    pathCustomStyle(props?.strokeColor, props?.stroke)
  ) : (
    <div
      className='circle-wrapper'
      style={{
          border: '2px solid ' + props?.strokeColor,
          height: props.height ?? 'calc(100vh * (14 / 820))',
          aspectRatio: 1,
      }}
    />
  );
};
