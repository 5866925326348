import { ID, NumericId, UserRole, UserRoleId } from '@/modules/common/types';
import { useSelector } from 'react-redux';
import { roleById } from '@/modules/auth/helpers';
import { useMemo } from 'react';
import useLogout, { UseLogoutResponse } from '@/modules/auth/hooks/logout';
import { User } from '@/store/user/state';
import { IStore } from '@/store/types';

export interface UseAuthResponse extends UseLogoutResponse {
  /** If true, authentication api is in progress */
  loading: boolean;

  /** Is User authenticated or not ? */
  authenticated: boolean;

  /** Role for Authenticated User. If authentication is in progress. Returns null */
  role: UserRole | null;

  /** RoleId for Authenticated User*/
  roleId: UserRoleId;

  /** Complete information of a User, If loading is in progress, returns empty object */
  user: User;

  /** Id of LoggedIn user */
  userId: ID;

  /** Id of Organization which user have currently selected  */
  organizationId: NumericId | null;

  /** Email of LoggedIn user */
  email: string;

  /** Token which is used for Authentication */
  token: string | null;

  /** Is user workspace switching in progress? */
  switching: boolean;
}

const useAuth = (): UseAuthResponse => {
  const { logout } = useLogout();

  const loading = useSelector<IStore, boolean>((state) => state.user.isLoading);

  const switching = useSelector<IStore, boolean>((state) => state.user.isSwitchingWorkspace);

  const token = useSelector<IStore, string>((state) => state.user.token);

  const authenticated = useSelector<IStore, boolean>(
    (state) => state.user.isAuthenticated
  );

  const user = useSelector<IStore, User>((state) => state.user.info as User);

  const {
    user_role_id: roleId,
    id: userId,
    organization_id: organizationId,
    email,
  } = user;

  const role = useMemo(
    () => (typeof roleId !== 'undefined' ? roleById(roleId) : null),
    [roleId]
  );

  return {
    authenticated,
    role,
    roleId,
    userId,
    loading,
    logout,
    user,
    organizationId,
    email,
    token,
    switching
  };
};

export default useAuth;
