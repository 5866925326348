import { TAB_CATEGORY } from '../../components/pages/myProperties/utils/enum';
import {
  ADD_ALL_FILTERS,
  ADD_BULK_ORDER_ID,
  ADD_BULK_VIEW_SUMMARY,
  ADD_MORE_SLICED_ORDERS,
  ADD_ORDERS,
  ADD_SLICED_ORDER,
  BULK_EDIT_DESCRIPTION,
  CLEAR_BULK_SUMMERY_WITH_ORDER,
  CLEAR_ORDER_ARRAY,
  DELETE_ORDER_WITH_ID,
  EDIT_BULK_DESCRIPTION_IN_BULK_PAGE,
  RESET_DASHBOARD_STATE,
  SET_FILTER_DATA,
  TOGGLE_DASHBOARD_LOADING,
  TOGGLE_REQUEST_PENDING_SCREEN,
  UPDATE_A_ORDER_WITH_ID,
  UPDATE_BULK_SUMMERY,
  UPDATE_BULK_SUMMERY_ORDER_COUNT,
  UPDATE_COUNT_BULK_ORDER_DUPLICATE,
  UPDATE_ORDER_PAGE,
  UPDATE_ORDER_SEARCH,
  UPDATE_TAG_BY_ID,
} from './actions.types';
import { getSlicedOrders } from './../../components/pages/myProperties/utils/dashboard.utils';
import { FilterType } from '../../components/pages/myProperties/utils/types';

const initialState = {
  me: {
    orders: [],
    requestPending: false,
    next: false,
    prev: false,
    totalPage: 0,
    totalResult: 0,
    emptyState: false,
    currentPage: 1,
    search: '',
    slicedOrder: [],
  },
  bulk: {
    orders: [],
    requestPending: false,
    next: false,
    prev: false,
    totalPage: 0,
    totalResult: 0,
    emptyState: false,
    currentPage: 1,
    search: '',
    slicedOrder: [],
  },
  all: {
    orders: [],
    requestPending: false,
    next: false,
    prev: false,
    totalPage: 0,
    totalResult: 0,
    emptyState: false,
    currentPage: 1,
    search: '',
    slicedOrder: [],
  },
  bulkView: {
    orders: [],
    next: false,
    prev: false,
    totalPage: 0,
    totalResult: 0,
    emptyState: false,
    currentPage: 1,
    search: '',
    slicedOrder: [],
    summery: {
      totalArea: 0,
      estimatedCredits: 0,
      estimatedDeliveryTime: null,
      bulkProjectName: '',
      bulkProjectId: 0,
      bulkSummeryStatus: '',
      description: '',
      status: '',
      totalOrders: 0,
      completedOrders: 0,
      errorCount: 0,
      currentOrderCount: 0,
      skippedOrders: 0,
      awatingReviewCount: 0,
      openFeedbackCount: 0,
    },
    stats: {
      Approve: 0,
      'Awaiting Approval': 0,
      'Resolving Feedback': 0,
      'In Progress': 0,
      Draft: 0,
    },
  },
  tabCount: {
    'created_by_me': 0,
    'bulk_projects': 0,
    all: 0,
  },
  filterData: {
    [FilterType.CUSTOMER_ID]: [],
    [FilterType.ADDRESS]: [],
    [FilterType.PROPERTY_NAME]: [],
    [FilterType.CITY]: [],
    [FilterType.CUSTOMER_NAME]: [],
    [FilterType.STATE]: [],
    [FilterType.PROPERTY_TAG]: [],
    [FilterType.ORDER_ID]: [],
    [FilterType.CREATED_BY]: [],
    suggestedTags: [],
    bulkName: [],
  },
  loading: false,
  bulkOrderId: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_ORDERS:
      return {
        ...state,
        [payload.tab]: {
          ...state[payload.tab],
          orders: payload?.data,
          next: payload.next,
          prev: payload.prev,
          currentPage: payload.currentPage,
          totalPage: payload.totalPage,
          totalResult: payload.totalResult,
          summery: {
            ...state[payload.tab]['summery'],
            ...payload.summery,
          },
        },
        tabCount: {
          ...state.tabCount,
          ...payload?.count,
        },
      };

    case TOGGLE_REQUEST_PENDING_SCREEN:
      return {
        ...state,
        [payload.tab]: {
          ...state[payload.tab],
          requestPending: payload.data,
        },
      };
    case CLEAR_ORDER_ARRAY:
      return {
        ...state,
        [payload.tab]: {
          ...state[payload.tab],
          orders: [],
          slicedOrder: [],
          totalPage: 0,
          totalResult: 0,
          emptyState: false,
          currentPage: 1,
          // summery: undefined,
          bulkTabCount: undefined,
        },
      };
    case ADD_ALL_FILTERS:
      return {
        ...state,
        filterData: payload,
      };
    case SET_FILTER_DATA:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          [payload.key]: payload.values,
        },
      };
    case UPDATE_ORDER_SEARCH:
      return {
        ...state,
        [payload.tab]: {
          ...state[payload.tab],
          search: payload.data,
        },
      };
    case UPDATE_ORDER_PAGE: {
      const bulkTabCount =
        payload?.bulkTabCount ?? state.tabCount['bulk_projects'];
      return {
        ...state,
        [payload.tab]: {
          ...state[payload.tab],
          ...payload,
        },
        tabCount: {
          ...state.tabCount,
          'bulk_projects': bulkTabCount,
          ...(payload.tabCount || {}),
        },
      };
    }
    case ADD_BULK_VIEW_SUMMARY:
      return {
        ...state,
        [payload.tab]: {
          ...state[payload.tab],
          summery: {
            totalArea: payload.totalArea,
            estimatedCredits: payload.estimatedCredits,
            estimatedDeliveryTime: payload.estimatedDeliveryTime,
            bulkProjectName: payload.bulkProjectName,
            bulkProjectId: payload.bulkProjectId,
            bulkSummeryStatus: payload.bulkSummeryStatus,
            status: payload.status,
            totalOrders: payload.totalOrders,
            completedOrders: payload.completedOrders,
            errorCount: payload.errorCount,
            currentOrderCount: payload.currentOrderCount,
            description: payload.description,
            skippedOrders: payload.skippedOrders,
            awatingReviewCount: payload.awatingReviewCount,
            openFeedbackCount: payload.openFeedbackCount,
          },
          stats: payload.stats,
        },
      };

    case TOGGLE_DASHBOARD_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ADD_SLICED_ORDER:
      return {
        ...state,
        [payload.tab]: {
          ...state[payload.tab],
          slicedOrder: payload.data,
        },
      };
    case ADD_MORE_SLICED_ORDERS:
      const uniqueOrders = [
        ...new Set(state[payload.tab].slicedOrder.concat(payload.data)),
      ];
      if (uniqueOrders.length > 0)
        return {
          ...state,
          [payload.tab]: {
            ...state[payload.tab],
            slicedOrder: uniqueOrders,
          },
        };
      else return state;
    case UPDATE_A_ORDER_WITH_ID:
      return {
        ...state,
        [payload.tab]: {
          ...state[payload.tab],
          orders: state[payload.tab].orders.map((order) => {
            if (order && order?.orderId === payload.data.orderId) {
              return {
                ...order,
                ...payload.data,
              };
            } else return order;
          }),
          slicedOrder: state[payload.tab].slicedOrder.map((order) => {
            if (order.orderId === payload.data.orderId) {
              return {
                ...order,
                ...payload.data,
              };
            } else return order;
          }),
        },
        filterData: {
          ...state.filterData,
          [payload?.filter?.key]: state.filterData?.[payload?.filter?.key]
            ? [
                ...state.filterData?.[payload?.filter?.key],
                payload.filter.value,
              ]
            : undefined,
        },
      };
    case DELETE_ORDER_WITH_ID: {
      const orders = state[payload.tab].orders.filter(
        (order) => order.orderId !== payload.data.orderId
      );
      const newSlicedArray = getSlicedOrders(
        orders,
        payload.data.page,
        payload.data.pageLength
      );

      return {
        ...state,
        [payload.tab]: {
          ...state[payload.tab],
          orders: orders,
          slicedOrder: newSlicedArray,
        },
      };
    }
    case UPDATE_BULK_SUMMERY_ORDER_COUNT: {
      const prevTotalOrder = +state[TAB_CATEGORY.bulkView].summery.totalOrders;
      const prevCompletedOrders =
        +state[TAB_CATEGORY.bulkView].summery.completedOrders;
      const isBlockCountUpdate = payload?.blockCountUpdate ?? false;

      return {
        ...state,
        [TAB_CATEGORY.bulkView]: {
          ...state[TAB_CATEGORY.bulkView],
          summery: {
            ...state[TAB_CATEGORY.bulkView].summery,
            totalOrders: !isBlockCountUpdate
              ? prevTotalOrder - 1
              : prevTotalOrder,
            completedOrders: !isBlockCountUpdate
              ? prevTotalOrder === prevCompletedOrders
                ? prevCompletedOrders - 1
                : prevCompletedOrders
              : prevCompletedOrders,
          },
          stats: {
            ...state[TAB_CATEGORY.bulkView].stats,
            ...payload?.stats,
          },
        },
      };
    }
    case UPDATE_TAG_BY_ID:
      return {
        ...state,
        [payload.tab]: {
          ...state[payload.tab],
          orders: state[payload.tab].orders.map((order) => {
            if (order.orderId === payload?.data?.orderId) {
              order.tags = payload?.data?.tags;
            }

            return order;
          }),
          slicedOrder: state[payload.tab].slicedOrder.map((order) => {
            if (order.orderId === payload?.data?.orderId) {
              order.tags = payload?.data?.tags;
            }

            return order;
          }),
        },
        filterData: {
          ...state.filterData,
          tags: state.filterData.tags.concat(
            payload?.data?.tags.map((tag) => tag.tagName)
          ),
        },
      };
    case UPDATE_COUNT_BULK_ORDER_DUPLICATE: {
      const prevTotalOrder = +state[TAB_CATEGORY.bulkView].summery.totalOrders;
      const draftStatusCount = +state[TAB_CATEGORY.bulkView].stats.Draft;

      return {
        ...state,
        [TAB_CATEGORY.bulkView]: {
          ...state[TAB_CATEGORY.bulkView],
          summery: {
            ...state[TAB_CATEGORY.bulkView].summery,
            totalOrders: prevTotalOrder + 1,
          },
          stats: {
            ...state[TAB_CATEGORY.bulkView].stats,
            Draft: draftStatusCount + 1,
          },
        },
      };
    }
    case BULK_EDIT_DESCRIPTION: {
      return {
        ...state,
        [TAB_CATEGORY.bulkView]: {
          ...state[TAB_CATEGORY.bulkView],
          summery: {
            ...state[TAB_CATEGORY.bulkView].summery,
            description: payload.description,
            bulkProjectName: payload.name,
          },
        },
        filterData: {
          ...state.filterData,
          bulkName: state.filterData.bulkName.concat([
            { recent: false, value: payload.name },
          ]),
        },
      };
    }
    case UPDATE_BULK_SUMMERY: {
      return {
        ...state,
        [TAB_CATEGORY.bulkView]: {
          ...state[TAB_CATEGORY.bulkView],
          summery: {
            ...state[TAB_CATEGORY.bulkView].summery,
            ...payload.data,
          },
        },
      };
    }
    case ADD_BULK_ORDER_ID: {
      return {
        ...state,
        bulkOrderId: payload,
      };
    }
    case RESET_DASHBOARD_STATE: {
      return {
        me: {
          orders: [],
          requestPending: false,
          next: false,
          prev: false,
          totalPage: 0,
          totalResult: 0,
          emptyState: false,
          currentPage: 1,
          search: '',
          slicedOrder: [],
        },
        bulk: {
          orders: [],
          requestPending: false,
          next: false,
          prev: false,
          totalPage: 0,
          totalResult: 0,
          emptyState: false,
          currentPage: 1,
          search: '',
          slicedOrder: [],
        },
        all: {
          orders: [],
          requestPending: false,
          next: false,
          prev: false,
          totalPage: 0,
          totalResult: 0,
          emptyState: false,
          currentPage: 1,
          search: '',
          slicedOrder: [],
        },
        bulkView: {
          orders: [],
          next: false,
          prev: false,
          totalPage: 0,
          totalResult: 0,
          emptyState: false,
          currentPage: 1,
          search: '',
          slicedOrder: [],
          summery: {
            totalArea: 0,
            estimatedCredits: 0,
            estimatedDeliveryTime: null,
            bulkProjectName: '',
            bulkProjectId: 0,
            bulkSummeryStatus: '',
            description: '',
            status: '',
            totalOrders: 0,
            completedOrders: 0,
            errorCount: 0,
            currentOrderCount: 0,
          },
          stats: {
            Approve: 0,
            'Awaiting Approval': 0,
            'Resolving Feedback': 0,
            'In Progress': 0,
            Draft: 0,
          },
        },
        tabCount: {
          'created_by_me': 0,
          'bulk_projects': 0,
          all: 0,
        },
        filterData: {
          address: [],
          propertyName: [],
          city: [],
          bulkName: [],
          customerName: [],
          suggestedTags: [],
          state: [],
          tags: [],
          orderId: [],
          createdBy: [],
          customerIds: [],
        },
        loading: false,
        bulkOrderId: '',
      };
    }
    case CLEAR_BULK_SUMMERY_WITH_ORDER: {
      return {
        ...state,
        bulkView: {
          orders: [],
          next: false,
          prev: false,
          totalPage: 0,
          totalResult: 0,
          emptyState: false,
          currentPage: 1,
          search: '',
          slicedOrder: [],
          summery: {
            totalArea: 0,
            estimatedCredits: 0,
            estimatedDeliveryTime: null,
            bulkProjectName: '',
            bulkProjectId: 0,
            bulkSummeryStatus: '',
            description: '',
            status: '',
            totalOrders: 0,
            completedOrders: 0,
            errorCount: 0,
            currentOrderCount: 0,
          },
          stats: {
            Approve: 0,
            'Awaiting Approval': 0,
            'Resolving Feedback': 0,
            'In Progress': 0,
            Draft: 0,
          },
        },
      };
    }
    case EDIT_BULK_DESCRIPTION_IN_BULK_PAGE: {
      const { bulkId, name, description } = payload;

      return {
        ...state,
        [TAB_CATEGORY.bulk]: {
          ...state[TAB_CATEGORY.bulk],

          orders: state[TAB_CATEGORY.bulk].orders.map((t) => {
            if (t.id === bulkId) {
              return {
                ...t,
                na: name,
                description,
              };
            }

            return t;
          }),

          slicedOrder: state[TAB_CATEGORY.bulk].slicedOrder.map((t) => {
            if (t.id === bulkId) {
              return {
                ...t,
                na: name,
                description,
              };
            }

            return t;
          }),
        },
      };
    }
    default:
      return state;
  }
};
