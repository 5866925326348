import React, { useState } from 'react';
import { Modal } from 'antd';
import CoRequestReceivedPopup from '../../../../PlasmicComponents/CoRequestReceivedPopup';
import './style.less';

interface RequestReceivedModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const RequestReceivedModal = ({
  visible,
  setVisible,
}: RequestReceivedModalProps) => {
  const [isRequestReceivedPopupVisible, setRequestReceivedPopupVisible] =
    useState<boolean>(visible);

  return (
    <Modal
      visible={isRequestReceivedPopupVisible}
      closable={false}
      footer={false}
      centered
      className='request-received-popup-modal p-0'
      onCancel={() => {
        setVisible(false);
        setRequestReceivedPopupVisible(false);
      }}
    >
      <CoRequestReceivedPopup
        onClose={() => {
          setVisible(false);
          setRequestReceivedPopupVisible(false);
        }}
      />
    </Modal>
  );
};

export default RequestReceivedModal;
