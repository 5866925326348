export const USER_ACTIONS = {
  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  USER_SIGNUP_REQUEST: 'USER_SIGNUP_REQUEST',
  USER_SIGNUP_SUCCESS: 'USER_SIGNUP_SUCCESS',
  USER_SIGNUP_FAILURE: 'USER_SIGNUP_FAILURE',
  USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',
  USER_LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  CLEAR_USER_INFO: 'CLEAR_USER_INFO',
  SET_TO_BE_REMEMBERED: 'SET_TO_BE_REMEMBERED',
  UPDATE_USER_CREDITS: 'UPDATE_USER_CREDITS',
  UPDATE_ACTIVE_STATE: 'UPDATE_ACTIVE_STATE',
  IS_MAIL_SENT: 'IS_MAIL_SENT',
  SET_USER_JOB_TITLE: 'SET_USER_JOB_TITLE',
  GET_JOB_TITLE: 'GET_JOB_TITLE',
  JOB_TITLE_REQUEST: 'JOB_TITLE_REQUEST',
  JOB_TITLE_SUCCESS: 'JOB_TITLE_SUCCESS',
  JOB_TITLE_FAILURE: 'JOB_TITLE_FAILURE',
  UPDATE_JOB_TITLE_REQUEST: 'UPDATE_JOB_TITLE_REQUEST',
  UPDATE_JOB_TITLE_SUCCESS: 'UPDATE_JOB_TITLE_SUCCESS',
  UPDATE_JOB_TITLE_FAILURE: 'UPDATE_JOB_TITLE_FAILURE',
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  GET_AVATAR_IMAGES_REQUEST: 'GET_AVATAR_IMAGES_REQUEST',
  GET_AVATAR_IMAGES_SUCCESS: 'GET_AVATAR_IMAGES_SUCCESS',
  GET_AVATAR_IMAGES_FAILURE: 'GET_AVATAR_IMAGES_FAILURE',
  LOGIN_BY_LINK_REQUEST: 'LOGIN_BY_LINK_REQUEST',
  LOGIN_BY_LINK_SUCCESS: 'LOGIN_BY_LINK_SUCCESS',
  LOGIN_BY_LINK_FAILURE: 'LOGIN_BY_LINK_FAILURE',
  IS_PASSWORD_TRUE_SUCCESS: 'IS_PASSWORD_TRUE_SUCCESS',
  IS_PASSWORD_TRUE_FAILURE: 'IS_PASSWORD_TRUE_FAILURE',
  USER_NAME_CHANGE_REQUEST: 'USER_NAME_CHANGE_REQUEST',
  USER_NAME_CHANGE_SUCCESS: 'USER_NAME_CHANGE_SUCCESS',
  USER_NAME_CHANGE_FAILURE: 'USER_NAME_CHANGE_FAILURE',
  OTP_VERIFICATION_REQUEST: 'OTP_VERIFICATION_REQUEST',
  OTP_VERIFICATION_SUCCESS: 'OTP_VERIFICATION_SUCCESS',
  OTP_VERIFICATION_FAILURE: 'OTP_VERIFICATION_FAILURE',
  OTP_GENERATION_REQUEST: 'OTP_GENERATION_REQUEST',
  OTP_GENERATION_SUCCESS: 'OTP_GENERATION_SUCCESS',
  OTP_GENERATION_FAILURE: 'OTP_GENERATION_FAILURE',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  USER_TITLE_SET_REQUEST: 'USER_TITLE_SET_REQUEST',
  USER_TITLE_SET_SUCCESS: 'USER_TITLE_SET_SUCCESS',
  USER_TITLE_SET_FAILURE: 'USER_TITLE_SET_FAILURE',
  TOGGLE_MEASURE: 'TOGGLE_MEASURE',
  PREV_NEXT: 'PREV_NEXT',
  RESET_ADD: 'RESET_ADD',
  SET_BTN_STATUS: 'SET_BTN_STATUS',
  ON_SHARE_CLICK: 'ON_SHARE_CLICK',
  UPDATE_SLIDE_STATE: 'UPDATE_SLIDE_STATE',
  POP_VAL: 'POP_VAL',
  ON_SNAP: 'ON_SNAP',
  ON_CLICK_SNAP: 'ON_CLICK_SNAP',
  ON_KML_NAV: 'ON_KML_NAV',
  SET_OTP_INFO: 'SET_OTP_INFO',
  SET_ACTIVE_TOOL: 'SET_ACTIVE_TOOL',
  SET_MAP_CLASSNAME: 'SET_MAP_CLASSNAME',
  KML_FOR_LAYER_LIST: 'KML_FOR_LAYER_LIST',
  ON_SHARE_ID: 'ON_SHARE_ID',
  MASK_CREDITS: 'MASK_CREDITS',
  UNMASK_CREDITS: 'UNMASK_CREDITS',
  SET_VIEW_WIDTH: 'SET_VIEW_WIDTH',
  SHOW_BLOCKED_POPUP: 'SHOW_BLOCKED_POPUP',
  SHOW_BULK_LAYOUT: 'SHOW_BULK_LAYOUT',
  USER_SWITCH_WORKSPACE: 'USER_SWITCH_WORKSPACE',
  DISMISS_TRIAL_POPUP: 'DISMISS_TRIAL_POPUP',
};

export const ORGANISATION_ACTIONS = {
  JOIN_ORGANISATION_REQUEST: 'JOIN_ORGANISATION_REQUEST',
  JOIN_ORGANISATION_SUCCESS: 'JOIN_ORGANISATION_SUCCESS',
  JOIN_ORGANISATION_FAILURE: 'JOIN_ORGANISATION_FAILURE',
  UPDATE_ORGANISATION_REQUEST: 'UPDATE_ORGANISATION_REQUEST',
  UPDATE_ORGANISATION_SUCCESS: 'UPDATE_ORGANISATION_SUCCESS',
  UPDATE_ORGANISATION_FAILURE: 'UPDATE_ORGANISATION_FAILURE',
  GET_ORGANISATION_REQUEST: 'GET_ORGANISATION_REQUEST',
  GET_ORGANISATION_SUCCESS: 'GET_ORGANISATION_SUCCESS',
  GET_ORGANISATION_FAILURE: 'GET_ORGANISATION_FAILURE',
  CREATE_ORGANISATION_REQUEST: 'CREATE_ORGANISATION_REQUEST',
  CREATE_ORGANISATION_SUCCESS: 'CREATE_ORGANISATION_SUCCESS',
  CREATE_ORGANISATION_FAILURE: 'CREATE_ORGANISATION_FAILURE',
  ORGANISATION_WORKSPACE: 'ORGANISATION_WORKSPACE',
  NEW_ORGANISATION: 'NEW_ORGANISATION',
  ORGANISATION_TITLE: 'ORGANISATION_TITLE',
  SKIP_ORG_CREATION: 'SKIP_ORG_CREATION',
};
