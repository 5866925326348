// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon34IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon34Icon(props: Icon34IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 20 20"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M13.756 9.77c0 .101-.02.195-.059.28a.846.846 0 01-.175.247l-4.565 4.465a.643.643 0 01-.48.199.666.666 0 01-.346-.094.662.662 0 01-.34-.586c0-.187.07-.353.211-.498l4.113-4.013-4.113-4.014a.687.687 0 01-.211-.492.68.68 0 01.088-.346.676.676 0 01.252-.24.666.666 0 01.346-.094c.191 0 .351.064.48.193l4.564 4.465c.079.078.137.16.176.246a.66.66 0 01.059.282z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon34Icon;
/* prettier-ignore-end */
