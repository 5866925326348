import React, { useContext, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Rnd, RndDragCallback, RndResizeCallback } from 'react-rnd';
import MapContext from '../../../components/pages/project/MapContext';

interface DraggableWrapperProps {
    children: React.ReactNode;
    initialPosition?: { x: number, y: number };
    initialSize?: { width: number, height: number };
    dragHandlerClassName?: string;
    bounds?: string;
    onDragStart?: () => void;
    onDragStop?: () => void;
}

const DraggableWrapper: React.FC<DraggableWrapperProps> = ({
    children,
    initialPosition = { x: 0, y: 0 },
    initialSize = { width: 200, height: 0 },
    dragHandlerClassName = 'drag-handle',
    bounds = 'window',
    onDragStart,
    onDragStop,
}) => {
    const [position, setPosition] = useState(initialPosition);
    const [size, setSize] = useState(initialSize);
    const olMap = useContext(MapContext);
    const overlayDivRef = useRef<HTMLDivElement | null>(null);
    const originalHoverListeners = useRef<any[]>([]);

    const createOverlayDiv = () => {
        const div = document.createElement('div');
        div.style.position = 'fixed';
        div.style.top = '50px';
        div.style.left = '56px';
        div.style.height = 'calc(100% - 50px)';
        div.style.width = 'calc(100% - 56px)';
        div.style.zIndex = '10'; // Ensure it's below the Rnd component
        div.style.backgroundColor = 'transparent';
        div.className = 'actioncenter-overlay';
        overlayDivRef.current = div;
        document.body.appendChild(div);
    };

    const removeOverlayDiv = () => {
        if (overlayDivRef.current) {
            document.body.removeChild(overlayDivRef.current);
            overlayDivRef.current = null;
        }
    };

    const removeHoverListeners = () => {
        if (olMap) {
            const hoverListeners = olMap.getListeners('pointermove') || [];
            originalHoverListeners.current = hoverListeners.slice(); // Save a copy of the current hover listeners
            hoverListeners.forEach(listener => olMap.un('pointermove', listener));
        }
    };

    const reattachHoverListeners = () => {
        if (olMap) {
            originalHoverListeners.current.forEach(listener => olMap.on('pointermove', listener));
        }
    };

    const handleDragStop: RndDragCallback = (e, d) => {
        onDragStop && onDragStop();
        setPosition({ x: d.x, y: d.y });
        removeOverlayDiv();
        reattachHoverListeners();
    };

    const handleDragStart = () => {
        onDragStart && onDragStart();
        createOverlayDiv();
        removeHoverListeners();
    };

    const handleResize: RndResizeCallback = (e, direction, ref, delta, position) => {
        setSize({
            width: ref.offsetWidth,
            height: ref.offsetHeight,
        });
        setPosition({
            x: position.x,
            y: position.y,
        });
    };

    const resizeHandleStyles = {
        top: { display: 'none' },
        bottom: { display: 'none' },
        bottomLeft: { display: 'none' },
        bottomRight: { display: 'none' },
        topLeft: { display: 'none' },
        topRight: { display: 'none' },
    };

    return createPortal(
        <Rnd
            minWidth={initialSize.width}
            minHeight={initialSize.height}
            default={{
                x: position.x,
                y: position.y,
                width: size.width,
                height: 'auto',
            }}
            dragHandleClassName={dragHandlerClassName}
            onDragStart={handleDragStart}
            onDragStop={handleDragStop}
            onResize={handleResize}
            bounds={".actioncenter-overlay"}
            position={{ x: position.x, y: position.y }}
            resizeHandleStyles={resizeHandleStyles}
        >
            {children}
        </Rnd>,
        document.getElementById('draggable-portal-root')!
    );
};

export default DraggableWrapper;