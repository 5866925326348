export const FEATURE_CREATION = {
  SET_FEATURE_CREATION_FORM_VALUES: 'SET_FEATURE_CREATION_FORM_VALUES',
};

export const SET_ATTRIBUTE_FEATURE_POPUP_DATA =
  'SET_ATTRIBUTE_FEATURE_POPUP_DATA';

export const SET_VISIBLE_ATTRIBUTE_MODAL = 'SET_VISIBLE_ATTRIBUTE_MODAL';
export const SET_ATTRIBUTE_FEATURE_SINGLE_POPUP_DATA =
  'SET_ATTRIBUTE_FEATURE_SINGLE_POPUP_DATA';
export const SET_ATTRIBUTE_FEATURE_MULTI_POPUP_DATA =
  'SET_ATTRIBUTE_FEATURE_MULTI_POPUP_DATA';
export const SET_ALL_ATTRIBUTES = 'SET_ALL_ATTRIBUTES';
export const SET_ALL_DELETED_ATTRIBUTES = 'SET_ALL_DELETED_ATTRIBUTES';
export const SET_ALL_SERVICE_ITEMS = 'SET_ALL_SERVICE_ITEMS';
export const SET_ALL_SERVICES = 'SET_ALL_SERVICES';
export const SET_NEW_ATTRIBUTES = 'SET_NEW_ATTRIBUTES';
export const SET_VISIBLE_ATTRIBUTE_PANEL = 'SET_VISIBLE_ATTRIBUTE_PANEL';
export const SET_LINK_NEW_ATTRIBUTE_MODAL = 'SET_LINK_NEW_ATTRIBUTE_MODAL';
export const NEW_ATTRIBUTES_AVAILABLE_TO_LINK =
  'NEW_ATTRIBUTES_AVAILABLE_TO_LINK';
export const SET_FEATURE_TO_VISBLE = 'SET_FEATURE_TO_VISBLE';
export const SET_CURRENT_LAYER = 'SET_CURRENT_LAYER';
