import { HeaderWidgetType } from '@/modules/subscription/storybook/types';
import { useMemo } from 'react';
import { SubscriptionState } from '../../helpers/enum';
import { useSubscription } from '@/modules/subscription/hooks';
import { ILastPaymentStatus } from '../../helpers/types';
import useAuth from '@/modules/auth/hooks';
import { Nullable } from '@/modules/common/types';

interface UseSubscriptionWidgetHookResponse {
  type: HeaderWidgetType | null;

  lastPaymentStatus?: Nullable<ILastPaymentStatus>;
}

const useSubscriptionHeaderWidget = (): UseSubscriptionWidgetHookResponse => {
  const { subscription, subscriptionState } = useSubscription();
  const { user } = useAuth();

  const type: HeaderWidgetType | null = useMemo(() => {
    if (!subscription || !subscriptionState || !user) return null;

    if (subscriptionState === SubscriptionState.PAYMENT_FAILED) {
      return HeaderWidgetType.PAYMENT_FAILED;
    }

    if (
      subscription.workspaceAvailableCredits <= 25 &&
      [
        SubscriptionState.TEAMS_ONGOING,
        SubscriptionState.PROFESSIONAL_ONGOING,
      ].includes(subscriptionState)
    ) {
      return HeaderWidgetType.LOW_CREDITS;
    }

    if (subscriptionState === SubscriptionState.TRIAL_ONGOING) {
      return HeaderWidgetType.TRIAL_COUNTDOWN;
    }

    if (subscriptionState === SubscriptionState.TRIAL_EXPIRED) {
      return HeaderWidgetType.TRIAL_OVER;
    }

    if (
      [
        SubscriptionState.PLAN_CANCELLED,
        SubscriptionState.MIGRATED_WORKSPACE,
      ].includes(subscriptionState)
    ) {
      return HeaderWidgetType.BUY_PLAN;
    }

    return null;
  }, [subscription, subscriptionState]);

  return { type, lastPaymentStatus: subscription?.lastPaymentStatus };
};

export default useSubscriptionHeaderWidget;
