// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Subtract12IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Subtract12Icon(props: Subtract12IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 15 11"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M0 10.963h10.903a3.135 3.135 0 003.135-3.135A7.839 7.839 0 006.611 0C6.012 3.754 4.166 8.135 0 10.963z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Subtract12Icon;
/* prettier-ignore-end */
