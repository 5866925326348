import axios, { AxiosResponse } from 'axios';
import { Asset, DefaultReportPayload, PropertyReport, SnapshotPage } from './schema';
import { GetAssetListResponse } from './types';
import { replaceParams } from '@/helpers/utilities/linkUtils';
import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import { NumericId } from '../../../modules/common/types';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';

const axiosInstance = getAxiosInstance();

export const createSnapshotV2 = async (payload) => {
  return getAxiosInstance().post<any>(
      API_ENDPOINTS.GENERATE_SNAPSHOT,
      payload
  );
};

export const fetchMeasurements = async (viewId: number, payload: any) => {
  return getAxiosInstance().post<any>(
      replaceParams(API_ENDPOINTS.FETCH_MEASUREMENTS, {':viewId': viewId}), payload
  );
};

export const createNewPropertyReport = async (
  payload: DefaultReportPayload
) => {
  return getAxiosInstance().post<PropertyReport>(
      API_ENDPOINTS.POST_PROPERTY_REPORT,
      payload
  );
};

export const updateExistingReport = async (payload: PropertyReport) => {
  const updateReportEndpoint = replaceParams(API_ENDPOINTS.UPDATE_PROPERTY_REPORT, {
    ':reportId': `${payload?.propertyReportId}`
  });
  return getAxiosInstance().put(updateReportEndpoint, payload);
};

export const onDeleteSnapshotClick = async (
  pageId: number,
  defaultReport: PropertyReport,
) => {
  const deleteSnapshotEndpoint = replaceParams(API_ENDPOINTS.DELETE_PROPERTY_REPORT_PAGE, {
    ':reportId': defaultReport?.propertyReportId,
    ':pageId': pageId,
  });
  return getAxiosInstance().delete(deleteSnapshotEndpoint);
};

export const makeCoverForReport = async (
    reportId: number,
    pageId: number,
) => {
  const makeCoverEndpoint = replaceParams(API_ENDPOINTS.MAKE_COVER_PAGE_PROPERTY_REPORT, {
    ':reportId': reportId,
    ':pageId': pageId,
  });
  return getAxiosInstance().post(makeCoverEndpoint);
};

export const removeCoverForReport = async (
    reportId: number,
    pageId: number,
) => {
  const removeCoverEndpoint = replaceParams(API_ENDPOINTS.REMOVE_COVER_PAGE_PROPERTY_REPORT, {
    ':reportId': reportId,
    ':pageId': pageId,
  });
  return getAxiosInstance().post(removeCoverEndpoint);
};

export const onAutoGen = async (
  defaultReport: PropertyReport,
) => {
  const autoGenEndpoint = replaceParams(API_ENDPOINTS.AUTOGEN_PROPERTY_REPORT_PAGES, {
    ':reportId': defaultReport?.propertyReportId
  });
  return getAxiosInstance().post(autoGenEndpoint);
};

export const onCreateReport = async (defaultReport: PropertyReport) => {
  const createReportEndpoint = replaceParams(API_ENDPOINTS.EXPORT_PROPERTY_REPORT, {
    ':reportId': defaultReport?.propertyReportId
  });
  return getAxiosInstance().post(createReportEndpoint);
};

export const cloneReport = async (report_id: number) => {
  const createReportEndpoint = replaceParams(API_ENDPOINTS.CLONE_PROPERTY_REPORT, {
    ':reportId': report_id
  });
  return getAxiosInstance().post(createReportEndpoint);
};

export const rearrangeReport = async (report_id: number, page_seq: number[]) => {
  const endpoint = replaceParams(API_ENDPOINTS.ARRANGE_PROPERTY_REPORT, {
    ':reportId': report_id
  });
  return getAxiosInstance().post(endpoint, {
    "pageSequence": page_seq
  });
};

export const addSnapshotPage = async (report_id: number, pageToAdd: SnapshotPage) => {
  const addSnapshotEndpoint = replaceParams(API_ENDPOINTS.ADD_SNAPSHOT_PAGE_PROPERTY_REPORT, {
    ':reportId': report_id
  });
  return getAxiosInstance().post(addSnapshotEndpoint, pageToAdd);
};


export const udpateSnapshotPage = async (reportId: number, pageId: number, pageToAdd: SnapshotPage) => {
  const addSnapshotEndpoint = replaceParams(API_ENDPOINTS.UPDATE_SNAPSHOT_PAGE_PROPERTY_REPORT, {
    ':reportId': reportId,
    ':pageId': pageId
  });
  return getAxiosInstance().put(addSnapshotEndpoint, pageToAdd);
};

export const getAssetList = async (): Promise<
  AxiosResponse<GetAssetListResponse>
> => {
  return await axiosInstance.get<GetAssetListResponse>(
    replaceParams(API_ENDPOINTS.GET_ASSET_LIST, {
      headers: {
        Accept: 'application/json',
      },
    })
  );
};

export const deleteAsset = async (assetId: NumericId) => {
  return axiosInstance.delete(
    replaceParams(API_ENDPOINTS.DELETE_ASSET, {
      ':assetId': assetId,
    }),
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
};

export const renameAsset = async (assetId: NumericId, assetName: string) => {
  return axiosInstance.put(
    API_ENDPOINTS.GET_ASSET_LIST,
    {
      assetId: assetId,
      assetName: assetName,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
};

export const uploadAsset = async (
  assetName: string,
  fileName: string
): Promise<AxiosResponse<Asset>> => {
  return axiosInstance.post(
    API_ENDPOINTS.GET_ASSET_LIST,
    {
      assetName,
      fileName,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
};

export const uploadAssetToBucket = async (url: string, image: any) => {
  return axios.create().put(url, image, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });
};

export const setUploadSuccess = async (assetId: NumericId) => {
  return axiosInstance.put(
    API_ENDPOINTS.GET_ASSET_LIST,
    {
      assetId: assetId,
      isUploaded: true,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
};

export const addAssetToPropertyReport = async (
  reportId: number,
  assetId: number
): Promise<AxiosResponse<PropertyReport>> => {
  return axiosInstance.post(
    replaceParams(API_ENDPOINTS.ADD_ASSET_TO_PROPERTY_REPORT, {
      ':reportId': reportId,
    }),
    { assetId },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
};
