export enum FilterKey {
  CUSTOMER_ID = 'customer-id',
  CUSTOMER_NAME = 'customer-name',
  PROPERTY_NAME = 'property-name',
  CITY = 'city',
  STATE = 'state',
  ORDER_ID = 'order-id',
  PROPERTY_TAG = 'property-tag',
  CREATED_BY = 'created-by',
  ADDRESS = 'address',
}

export enum FilterType {
  CUSTOMER_ID = 'customerId',
  CUSTOMER_NAME = 'customerName',
  PROPERTY_NAME = 'propertyName',
  CITY = 'city',
  STATE = 'state',
  ORDER_ID = 'orderId',
  PROPERTY_TAG = 'tags',
  CREATED_BY = 'createdBy',
  ADDRESS = 'address',
}

export enum TabType {
  ALL = 'all', // will return all unique values in db
  ME = 'me', // will return for properties created by me
  BULK = 'bulk', // will return for properties under a specific bulk project specified by the BULK_PROJECT_ID
}

export interface FilterResponse {
  filterparam: FilterKey;
  filtervalues: string[];
  createdby: string | null;
}

export interface CreatedBy {
  isDeleted: boolean;
  userId: number;
  userFirstName: string;
}

export interface CreateByFilterResponse {
  filterparam: FilterKey;
  filtervalues: string[];
  createdby: CreatedBy[] | null;
}

export enum GroupName {
  CUSTOMER_ID = 'CustomerId',
  CUSTOMER_NAME = 'customerName',
  CITY = 'City',
  STATE = 'State',
  ORDER_ID = 'Order',
  PROPERTY_TAG = 'Tag',
  CREATED_BY = 'CreatedBy',
  MIN_AREA = 'minArea',
  MAX_AREA = 'maxArea',
  CREATE_DATE = 'Created',
}

export enum PropertyDashboardPreviewType {
  LIST_VIEW,
  MAP_VIEW,
}

export enum FilterVersion {
  LEGACY = 'legacy',
  V2 = 'v2',
}
