import { useQuery } from '@tanstack/react-query';
import { getServiceItems } from '../api';
import useAuth from '../../auth/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../store/types';
import { setAllServiceItems } from '@/store/attributeFeature/actions';
import { ID } from '../../common/types';
import { useEffect } from 'react';

export const useServiceItems = () => {
  const { organizationId } = useAuth();
  const dispatch = useDispatch();

  const reduxServiceItems = useSelector<IStore, any>(
    (state) => state.feature.serviceItemsList
  );

  const orderId = useSelector<IStore, ID>(
    (state: any) => state.order.orderData?.orderNo
  );

  const fetchServiceItems = async () => {
    const serviceItems = await getServiceItems(
      Number(orderId),
      organizationId!
    );
    dispatch(setAllServiceItems(serviceItems));
    return serviceItems;
  };

  const { data, isFetching } = useQuery(
    ['serviceItems', orderId, organizationId],
    fetchServiceItems,
    {
      enabled: !!orderId && !!organizationId && !reduxServiceItems?.length,
      initialData: reduxServiceItems,
      onSuccess: (data) => {
        if (!reduxServiceItems?.length) {
          dispatch(setAllServiceItems(data));
        }
      },
    }
  );

  return { serviceItems: data, isFetching };
};
