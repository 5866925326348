// @ts-nocheck
import { ComponentType, FC } from 'react';
import { IObject } from '@/modules/common/types';
import { useSubscription } from '@/modules/subscription/hooks';
import { UseSubscriptionResponse } from '../../../subscription/hooks/useSubscription';

/**
 * Adds subscription: {@link UseSubscriptionResponse} to the Wrapped component
 */
const withSubscription = <P extends IObject = any>(
  Component: ComponentType<P>
): FC<P & UseSubscriptionResponse> => {
  const WrappedComponent: FC<P & UseSubscriptionResponse> = (props) => {
    const {
      subscription,
      showPaywall,
      hidePaywall,
      getFeatureData,
      currentPlan,
      features,
    } = useSubscription();

    return (
      <Component
        {...props}
        subscription={subscription}
        hidePaywall={hidePaywall}
        getFeatureData={getFeatureData}
        features={features}
        showPaywall={showPaywall}
        currentPlan={currentPlan}
      />
    ) as unknown as JSX.Element;
  };

  return WrappedComponent;
};

export default withSubscription;
