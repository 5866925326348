import { ORGANISATION_ACTIONS, USER_ACTIONS } from './action_types';

import {
  API_STATUS,
  AUTH_STATE,
} from '@/components/containers/auth-page/types';
import { INITIAL_STATE, User } from '@/store/user/state';

const data = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case USER_ACTIONS.SET_TO_BE_REMEMBERED:
      return {
        ...state,
        toBeRemembered: payload,
      };
    case USER_ACTIONS.USER_LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case USER_ACTIONS.IS_MAIL_SENT:
      return {
        ...state,
        isMailSent: payload,
      };

    case USER_ACTIONS.GET_USER_DETAILS:
      const email = payload.data?.email;

      if (email) {
        let index = email.indexOf('@');
        payload.data.domain = email.substring(index, email.length);
      }
      if (!payload.data.organization_id) {
        if (state.reqId) {
          return {
            ...state,
            info: payload.data,
            userActiveState: AUTH_STATE.INVITED_USER,
            isAuthenticated: false,
            isLoading: false,
          };
        } else if (payload.data.adminData?.length !== 0) {
          return {
            ...state,
            info: payload.data,
            userActiveState: AUTH_STATE.SELECT_ADMIN,
            isAuthenticated: false,
            isLoading: false,
          };
        } else {
          return {
            ...state,
            info: payload.data,
            userActiveState: AUTH_STATE.NEW_ORG,
            isAuthenticated: false,
            isLoading: false,
          };
        }
      } else {
        let userActiveState = state.userActiveState;
        if (
          userActiveState === 'sign-up' &&
          payload.data.email_verified === '0'
        ) {
          userActiveState = AUTH_STATE.NEW_ORG;
        }
        // removed as it was causing only certain users to login with third party login
        // if (!payload.data.job_title_id) {
        userActiveState = AUTH_STATE.ORG_TITLE;
        // }

        return {
          ...state,
          info: payload.data,
          userActiveState,
          isAuthenticated: userActiveState !== AUTH_STATE.NEW_ORG,
          isLoading: false,
        };
      }

    case USER_ACTIONS.UPDATE_ACTIVE_STATE:
      return {
        ...state,
        userActiveState: payload,
        isMailSent: false,
      };

    case USER_ACTIONS.USER_LOGIN_SUCCESS:
      return {
        ...state,
        newUser: payload.new_user,
        reqId: payload.reqId,
        organisation: payload.organisation,
        invitedBy: payload.invited_by,
      };

    case USER_ACTIONS.USER_LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      };

    case USER_ACTIONS.USER_SIGNUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        signup: null,
      };

    case USER_ACTIONS.USER_SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        signup: { ...payload },
      };

    case USER_ACTIONS.USER_SIGNUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        signup: null,
      };

    case USER_ACTIONS.USER_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        userUpdate: API_STATUS.LOADING,
      };
    case USER_ACTIONS.USER_UPDATE_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          ...payload,
        },
        isLoading: false,
        userUpdate: API_STATUS.SUCCESS,
      };
    case USER_ACTIONS.USER_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        userUpdate: API_STATUS.FAILURE,
      };

    case USER_ACTIONS.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case USER_ACTIONS.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isPasswordTrue: false,
      };

    case USER_ACTIONS.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isPasswordTrue: false,
      };

    case USER_ACTIONS.UPDATE_USER_CREDITS:
      return {
        ...state,
        info: {
          ...state.info,
          ...payload,
        },
      };
    case USER_ACTIONS.CLEAR_USER_INFO:
      return {
        ...state,
        isAuthenticated: false,
        isMailSent: false,
        isPasswordTrue: false,
        userActiveState: 'login',
      };
    case USER_ACTIONS.SET_USER_JOB_TITLE:
      return {
        ...state,
        userActiveState: AUTH_STATE.LOGGED_IN,
      };
    case ORGANISATION_ACTIONS.SKIP_ORG_CREATION:
      return {
        ...state,
        userActiveState: AUTH_STATE.ORG_TITLE,
      };
    case USER_ACTIONS.JOB_TITLE_REQUEST:
      return {
        ...state,
        isPasswordTrue: false,
      };
    case USER_ACTIONS.JOB_TITLE_SUCCESS:
      return {
        ...state,
        jobtitle: payload,
        isPasswordTrue: false,
      };
    case USER_ACTIONS.JOB_TITLE_FAILURE:
      return {
        ...state,
        isPasswordTrue: false,
      };
    case USER_ACTIONS.UPDATE_JOB_TITLE_REQUEST:
      return {
        ...state,
      };
    case USER_ACTIONS.UPDATE_JOB_TITLE_SUCCESS:
      return {
        ...state,
      };
    case USER_ACTIONS.UPDATE_JOB_TITLE_FAILURE:
      return {
        ...state,
      };
    case USER_ACTIONS.GET_AVATAR_IMAGES_REQUEST:
      return {
        ...state,
      };
    case USER_ACTIONS.GET_AVATAR_IMAGES_SUCCESS:
      return {
        ...state,
        avatarImages: payload,
      };
    case USER_ACTIONS.GET_AVATAR_IMAGES_FAILURE:
      return {
        ...state,
      };
    case USER_ACTIONS.LOGIN_BY_LINK_REQUEST:
      return {
        ...state,
      };
    case USER_ACTIONS.LOGIN_BY_LINK_SUCCESS:
      return {
        ...state,
        userActiveState: !payload.data.job_title_id
          ? AUTH_STATE.ORG_TITLE
          : null,
        isAuthenticated: true,
        isLoading: false,
      };
    case USER_ACTIONS.LOGIN_BY_LINK_FAILURE:
      return {
        ...state,
      };
    case USER_ACTIONS.IS_PASSWORD_TRUE_SUCCESS:
      return {
        ...state,
        isPasswordTrue: true,
      };
    case USER_ACTIONS.IS_PASSWORD_TRUE_FAILURE:
      return {
        ...state,
        isPasswordTrue: false,
      };
    case USER_ACTIONS.OTP_VERIFICATION_REQUEST:
      return {
        ...state,
        otpVerifyError: null,
        otpVerifyStatus: API_STATUS.LOADING,
      };
    case USER_ACTIONS.OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otpVerifyError: null,
        otpVerifyStatus: API_STATUS.SUCCESS,
      };
    case USER_ACTIONS.OTP_VERIFICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        otpVerifyError: payload,
        otpVerifyStatus: API_STATUS.FAILURE,
      };

    case USER_ACTIONS.OTP_GENERATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        otpGenError: null,
        otpGenStatus: API_STATUS.LOADING,
      };
    case USER_ACTIONS.OTP_GENERATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otpGenError: null,
        otpGenStatus: API_STATUS.SUCCESS,
      };
    case USER_ACTIONS.OTP_GENERATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        otpGenError: payload,
        otpGenStatus: API_STATUS.FAILURE,
      };
    case USER_ACTIONS.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case USER_ACTIONS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case USER_ACTIONS.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case USER_ACTIONS.USER_TITLE_SET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case USER_ACTIONS.USER_TITLE_SET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case USER_ACTIONS.USER_TITLE_SET_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case USER_ACTIONS.USER_NAME_CHANGE_REQUEST:
      return {
        ...state,
      };
    case USER_ACTIONS.USER_NAME_CHANGE_SUCCESS:
      return {
        ...state,
      };
    case USER_ACTIONS.USER_NAME_CHANGE_FAILURE:
      return {
        ...state,
      };
    case USER_ACTIONS.UPDATE_SLIDE_STATE:
      return {
        ...state,
        slideState: payload,
      };
    case USER_ACTIONS.TOGGLE_MEASURE:
      return {
        ...state,
        measureTool: payload,
      };
    case USER_ACTIONS.PREV_NEXT:
      return {
        ...state,
        address: payload[0],
        prevHash: payload[1],
        nextHash: payload[2],
      };
    case USER_ACTIONS.RESET_ADD:
      return {
        ...state,
        address: '',
        prevHash: '',
        nextHash: '',
        nextAdd: '',
        prevAdd: '',
        nextCust: '',
        prevCust: '',
        nextStatus: '',
        prevStatus: '',
        measureTool: 0,
        shareBtn: false,
      };
    case USER_ACTIONS.SET_BTN_STATUS:
      return {
        ...state,
        shareBtn: payload,
      };
    case USER_ACTIONS.ON_SHARE_CLICK:
      return {
        ...state,
        shareClick: payload,
      };
    case USER_ACTIONS.POP_VAL:
      return {
        ...state,
        prevAdd:
          Object.keys(payload[0]).length !== 0 ? payload[0].prevAddress : '',
        prevStatus:
          Object.keys(payload[0]).length !== 0 ? payload[0].status : '',
        prevCust:
          Object.keys(payload[0]).length !== 0 ? payload[0].customerName : '',
        nextAdd:
          Object.keys(payload[1]).length !== 0 ? payload[1].nextAddress : '',
        nextStatus:
          Object.keys(payload[1]).length !== 0 ? payload[1].status : '',
        nextCust:
          Object.keys(payload[1]).length !== 0 ? payload[1].customerName : '',
      };

    case USER_ACTIONS.ON_SNAP:
      return {
        ...state,
        onSnap: payload,
      };
    case USER_ACTIONS.ON_KML_NAV:
      return {
        ...state,
        onKmlNav: payload,
      };
    case USER_ACTIONS.SET_OTP_INFO:
      return {
        ...state,
        otpInfo: payload,
      };
    case USER_ACTIONS.SET_ACTIVE_TOOL:
      return {
        ...state,
        activeTool: payload,
      };
    case USER_ACTIONS.SET_MAP_CLASSNAME:
      return {
        ...state,
        mapClassName: payload,
      };
    case USER_ACTIONS.KML_FOR_LAYER_LIST:
      return {
        ...state,
        KMLDownloadLayer: payload,
      };

    case USER_ACTIONS.ON_SHARE_ID:
      return {
        ...state,
        shareMapId: payload,
      };

    case USER_ACTIONS.MASK_CREDITS:
      return {
        ...state,
        maskCredits: true,
      };
    case USER_ACTIONS.UNMASK_CREDITS:
      return {
        ...state,
        maskCredits: false,
      };

    case USER_ACTIONS.SET_VIEW_WIDTH:
      return {
        ...state,
        viewWidth: payload,
      };
    case USER_ACTIONS.SHOW_BLOCKED_POPUP:
      return {
        ...state,
        showMobileBlockedPopup: payload.status,
        showMobileBlockedPopupMessage: payload.message,
      };

    case USER_ACTIONS.SHOW_BULK_LAYOUT:
      return {
        ...state,
        showBulkLayout: !state.showBulkLayout,
      };

    case USER_ACTIONS.USER_SWITCH_WORKSPACE:
      return {
        ...state,
        isSwitchingWorkspace: true,
      };

    case USER_ACTIONS.DISMISS_TRIAL_POPUP:
      return {
        ...state,
        info: { ...state.info, seen_trial_popup: true },
      };
    case ORGANISATION_ACTIONS.CREATE_ORGANISATION_SUCCESS:
      //Create organisation is done from only two places
      // 1. Add btn in workspaces section
      // 2. During signup
      return {
        ...state,
        isAuthenticated: true,
        userActiveState: 'org-title',
      };

    case ORGANISATION_ACTIONS.JOIN_ORGANISATION_SUCCESS:
      return {
        ...state,
        userActiveState: !payload?.data?.job_title_id
          ? AUTH_STATE.ORG_TITLE
          : null,
        isAuthenticated: true,
      };
    default:
      return state;
  }
};

export default data;
