// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon48IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon48Icon(props: Icon48IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g clipPath={"url(#1P8FNQlbdrora)"} fill={"#666"}>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M2.5 3.875a.75.75 0 01.75-.75h3.492a.75.75 0 01.733.591l.76 3.516 12.513-.04a.75.75 0 01.736.904l-1.592 7.559a.75.75 0 01-.734.595h-9.9a.75.75 0 01-.733-.591L6.137 4.625H3.25a.75.75 0 01-.75-.75zm6.06 4.856l1.303 6.019h8.687l1.275-6.055L8.56 8.73z"
          }
        ></path>

        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M14.333 9.483a.5.5 0 01.5.5v1.284h1.284a.5.5 0 110 1h-1.284v1.291a.5.5 0 11-1 0v-1.291h-1.291a.5.5 0 110-1h1.291V9.983a.5.5 0 01.5-.5z"
          }
        ></path>

        <path
          d={
            "M11.25 19.375a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm9 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
          }
        ></path>
      </g>

      <defs>
        <clipPath id={"1P8FNQlbdrora"}>
          <path fill={"#fff"} d={"M0 0h24v24H0z"}></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon48Icon;
/* prettier-ignore-end */
