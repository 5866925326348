import useQuery from '@/components/pages/myProperties/hooks/useQuery';
import { TAB_CATEGORY } from '@/components/pages/myProperties/utils/enum';
import { useRole } from '@/modules/auth/guards/RoleGuard';

/** Active tab in properties dashboard page **/
const useActiveTab = () => {
  const query = useQuery();
  const { isGuest } = useRole();

  return isGuest ? TAB_CATEGORY.all : query.get('tab') ?? TAB_CATEGORY.me;
};

export default useActiveTab;
