import { Tooltip, Typography } from 'antd';
import { ReactElement } from 'react';

import { ReactComponent as LinkOutlinedIcon } from '../../../assets/link-outlined.svg';

const { Paragraph } = Typography;

interface ICopyTextComponentProps {
  text: string;
  buttonText: string;
  icon?: ReactElement;

  onClick?: () => void;
}

const CopyTextComponent = ({
  text = '',
  buttonText,
  icon,
  onClick,
}: ICopyTextComponentProps) => {
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: '#FBFBFB',
        borderRadius: 4,
        display: 'flex',
        height: '40px',
        padding: '11.5px 10px',
        position: 'relative',
      }}
      onClick={onClick}
    >
      <Paragraph
        copyable={{
          icon: [
            <Tooltip placement='top' title={buttonText}>
              <span
                style={{
                  color: '#274DD3',
                  display: 'flex',
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: '21px',
                  position: 'absolute',
                  right: 20,
                  top: 10,
                }}
              >
                {icon || <LinkOutlinedIcon />}
                {buttonText}
              </span>
            </Tooltip>,
            <Tooltip placement='top' title='Copied!'>
              <span
                style={{
                  color: '#274DD3',
                  display: 'flex',
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: '21px',
                  position: 'absolute',
                  right: 20,
                  top: 10,
                }}
              >
                {icon || <LinkOutlinedIcon />}
                {buttonText}
              </span>
            </Tooltip>,
          ],
          tooltips: false,
        }}
        ellipsis
        style={{
          alignItems: 'center',
          color: '#666666',
          display: 'flex',
          fontSize: 12,
          fontWeight: 400,
          justifyContent: 'space-between',
          lineHeight: '18px',
          marginBottom: 0,
          marginRight: 12,
          marginTop: 3,
          width: 260,
        }}
      >
        {text}
      </Paragraph>
    </div>
  );
};

export default CopyTextComponent;
