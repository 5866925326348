import { Avatar } from 'antd';
import { Box, Typography } from '@/modules/common/elements';
import { UserObject } from '@/components/notes-feedback/types/Notes';

interface UserInfoProps {
  user: UserObject;
}

const UserInfo = ({ user }: UserInfoProps) => {
  const { userName, emailAddress } = user;

  return (
    <Box flex align='middle' wrap={false}>
      <Avatar
        style={{ backgroundColor: '#665AEE', color: '#EAEAEA' }}
        size={42}
        className='mr-3'
      >
        {userName.substring(0, 1).toUpperCase()}
      </Avatar>
      <Box>
        <Typography
          fontWeight={400}
          fontSize={14}
          lineHeight={1.5}
          color='#333333'
        >
          {userName}
        </Typography>
        <br />
        <Typography
          fontWeight={400}
          fontSize={12}
          lineHeight={1.5}
          color='#666666'
        >
          {emailAddress}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserInfo;
