import React, { useState } from 'react';
import './style.less';
import '@/styles/base/utils.less';

import { ReactComponent as DownIcon } from '../../assets/downicon-blue.svg';
import { ReactComponent as OngoingIcon } from '../../assets/ongoing.svg';
import { ReactComponent as ScheduledIcon } from '../../assets/scheduled.svg';
import { ReactComponent as CompletedIcon } from '../../assets/completed.svg';
import { ReactComponent as UpIcon } from '../../assets/upicon-blue.svg';
import { ReactComponent as Clock } from '../../assets/clock.svg';
import { ReactComponent as SadBoxIcon } from '../../assets/sadbox.svg';

import { IQueue, QueueOrderStatus } from '../../utils/types';

import SingleQueueCard from '../QueueCard';
import { padWithEmptySpaces } from '../../utils/helpers';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeOrderQueueModal } from '@/store/orderQueue/actions';

import CountdownTimer from '../CountdownTimer';
import { addPluralSuffix } from '@project/utils/helpers';

type SingleQueueProps = {
  queue: IQueue;
  users: any;
};

const SingleQueue = ({ queue, users }: SingleQueueProps) => {
  const [showCompletedOrders, setShowCompletedOrders] = useState(false);
  const { orders, queueName } = queue;

  const history = useHistory();
  const dispatch = useDispatch();

  const onGoingOrders = orders.filter(
    (order) => order.status === QueueOrderStatus.MEASUREMENT_ONGOING
  );

  /** onGoingOrders are already sorted based on estimatedDeliveryTime */
  let onGoingDeliveries = onGoingOrders.map((order) =>
    new Date(order.estimatedDeliveryTime).getTime()
  );
  let largestEstimatedTime = onGoingDeliveries.length
    ? new Date(Math.max(...onGoingDeliveries)).toISOString()
    : '';

  const completedOrders = orders.filter((order) =>
    [
      QueueOrderStatus.AWAITING_APPROVAL,
      QueueOrderStatus.RESOLVING_FEEDBACK,
      QueueOrderStatus.APPROVED,
    ].includes(order.status)
  );

  const createdBy = (userId: string): string =>
    users
      .filter((u: { id: string }) => u.id === userId)
      .map((u: { fn: string }) => u.fn)[0]
      ?.split(' ')[0];

  return (
    <div className='single-queue-container'>
      <div className='queue-top-head'>
        <h3>
          {queueName}
          {padWithEmptySpaces(2)}
          <span className='text-10 fw-400 leading-15 text-midgray'>
            ({onGoingOrders.length}{' '}
            {addPluralSuffix(onGoingOrders.length, 'Order')})
          </span>
        </h3>
        <p className='d-flex align-center bg-chablis rounded-4 text-10 fw-400 leading-15 text-midgray'>
          <Clock /> {padWithEmptySpaces(1)}
          <CountdownTimer
            endsAt={largestEstimatedTime}
            delayedText='-NA-'
            textStyle={{ color: '#666666' }}
          />
        </p>
      </div>
      <div className='queue-bottom-head'>
        <div className='text-container'>
          <p>
            {completedOrders.length} Completed{' '}
            {addPluralSuffix(completedOrders.length, 'Order')}{' '}
            <span>In last 24 hours</span>
          </p>
        </div>
        <div
          className='view-button'
          onClick={() => setShowCompletedOrders(!showCompletedOrders)}
        >
          {showCompletedOrders ? (
            <div className='text-container'>Hide {<UpIcon />}</div>
          ) : (
            <div className='text-container'>View {<DownIcon />}</div>
          )}
        </div>
      </div>
      <div className='queue-main-container'>
        {showCompletedOrders ? (
          <>
            {orders &&
              orders.map((order, index) => {
                return (
                  <div key={index}>
                    {[
                      QueueOrderStatus.APPROVED,
                      QueueOrderStatus.RESOLVING_FEEDBACK,
                      QueueOrderStatus.AWAITING_APPROVAL,
                    ].includes(order.status) && (
                      <SingleQueueCard
                        key={order.orderId}
                        orderId={order.orderId}
                        orderHashed={order.orderHashed}
                        address={order.address}
                        city={order.city}
                        state={order.state}
                        orderTime={order.createdAt}
                        estimatedStartTime={order.estimatedStartTime}
                        estimatedDeliveryTime={order.estimatedDeliveryTime}
                        author={createdBy(order.createdBy)}
                        containerStyle={{ backgroundColor: '#FFF' }}
                        icon={<CompletedIcon />}
                        showOrderTime={false}
                      />
                    )}
                  </div>
                );
              })}
          </>
        ) : (
          <>
            {onGoingOrders.length !== 0 ? (
              <>
                {orders &&
                  orders.map((order, index) => {
                    return (
                      <div key={index}>
                        {order.status ===
                          QueueOrderStatus.MEASUREMENT_ONGOING &&
                          new Date() > new Date(order.estimatedStartTime) && (
                            <SingleQueueCard
                              key={order.orderId}
                              orderId={order.orderId}
                              orderHashed={order.orderHashed}
                              address={order.address}
                              city={order.city}
                              state={order.state}
                              orderTime={order.createdAt}
                              estimatedStartTime={order.estimatedStartTime}
                              estimatedDeliveryTime={
                                order.estimatedDeliveryTime
                              }
                              author={createdBy(order.createdBy)}
                              containerStyle={{ backgroundColor: '#F4FCFF' }}
                              icon={<OngoingIcon />}
                              showOrderTime={true}
                            />
                          )}
                      </div>
                    );
                  })}
                {orders &&
                  orders.map((order, index) => {
                    return (
                      <div key={index}>
                        {order.status ===
                          QueueOrderStatus.MEASUREMENT_ONGOING &&
                          new Date() < new Date(order.estimatedStartTime) && (
                            <SingleQueueCard
                              key={order.orderId}
                              orderId={order.orderId}
                              orderHashed={order.orderHashed}
                              address={order.address}
                              city={order.city}
                              state={order.state}
                              orderTime={order.createdAt}
                              estimatedStartTime={order.estimatedStartTime}
                              estimatedDeliveryTime={
                                order.estimatedDeliveryTime
                              }
                              author={createdBy(order.createdBy)}
                              containerStyle={{ backgroundColor: '#FFF' }}
                              icon={<ScheduledIcon />}
                              showOrderTime={true}
                            />
                          )}
                      </div>
                    );
                  })}
              </>
            ) : (
              <>
                <div
                  onClick={() => {
                    history.push('/createOrder');
                    dispatch(closeOrderQueueModal());
                  }}
                >
                  <div className='no-ongoing-orders rounded-4 bg-white d-flex align-center justify-around cursor-pointer'>
                    <div className='left-container'>
                      <SadBoxIcon />
                    </div>
                    <div className='right-container'>
                      <p className='fw-400 text-10 text-midgray leading-14 mb-0'>
                        Uh-oh! No live orders in this queue.{' '}
                      </p>
                      <div
                        style={{
                          border: 'none',
                          color: '#0099FF',
                          fontSize: '14px',
                          fontWeight: '500',
                          lineHeight: '21px',
                          padding: '0px',
                          marginTop: '6px',
                        }}
                      >
                        Place an Order
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SingleQueue;
