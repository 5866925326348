import { useEffect, useState } from 'react';

let triggerAppOpen = function () {
  window.location.href = `https://siterecon.page.link/web-to-app-redirect`;
};

const useDeviceDetect = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [deviceType, setDeviceType] = useState('');

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    let hasTouchScreen = false;
    if ('maxTouchPoints' in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
      if (mQ && mQ.media === '(pointer:coarse)') {
        hasTouchScreen = !!mQ.matches;
      } else if ('orientation' in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    if (hasTouchScreen) {
      setDeviceType('Mobile');
    } else {
      setDeviceType('Desktop');
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return { deviceType, width, handleClick: triggerAppOpen };
};

export default useDeviceDetect;
