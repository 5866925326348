import { Modal, Row } from 'antd';
import React from 'react';
import Text from '../../../../../../../../elements/text/Text';

const ShareAnywayModal = ({ showModal, hideModal }) => {
  return (
    <Modal
      visible={showModal}
      // onOk={onArchiveConfirm}
      onCancel={hideModal}
      closable={false}
      className='shareOutSideModal'
      width={350}
      footer={null}
      centered
    >
      <Row style={{ flexDirection: 'column' }}>
        <Text type='h5' color='dark-gray' className='mb-2'>
          Share outside of organization?
        </Text>

        <Text type='p34' color='dark-gray' className='mb-3'>
          You are sharing to 'vin.thomas@gmail.com' who is not in the
          organization that this item belongs to.
        </Text>

        <Row justify='end' align='middle'>
          <Text
            type='p23'
            color='gray-shade'
            className='mr-3 text__button'
            onClick={hideModal}
          >
            Cancel
          </Text>
          <Text type='p23' color='dark-gray' className='text__button'>
            Share
          </Text>
        </Row>
      </Row>
    </Modal>
  );
};

export default ShareAnywayModal;
