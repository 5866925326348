import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import './style.less';

interface MapNavbarCenterProps {
  leftArrowComponent: any;
  logo: any;
  property: any;
  rightArrowComponent: any;
  showPropertyName: boolean;
}

const MapNavbarCenter = ({
  leftArrowComponent,
  logo,
  property,
  rightArrowComponent,
  showPropertyName,
}: MapNavbarCenterProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {}, [property]);

  // const mouseEnterHandler = () => {
  //   if (showPropertyName) {
  //     setEditMode(true);
  //   }
  // };

  // const mouseLeaveHandler = () => {
  //   if (showPropertyName) {
  //     setEditMode(false);
  //   }
  // };

  return (
    <Row className='map-nav-center' align='middle' justify='center'>
      {/* {!editMode && (
        <Col className='nav-center-left-arrow'>{leftArrowComponent}</Col>
      )} */}
      <Col className='nav-center-property-info'>
        {/* <Col className='mr-3'>{logo}</Col> */}
        <Col>{property}</Col>
        {/* onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler} */}
      </Col>
      {/* {!editMode && (
        <Col className='nav-center-right-arrow'>{rightArrowComponent}</Col>
      )} */}
    </Row>
  );
};

export default MapNavbarCenter;
