import Box from '@siterecon/sr-styles/dist/elements/Box';
import Pinned from '../../assets/common/pinned';
import Unpinned from '../../assets/common/unpinned';
import LineSplit from '../../assets/tools/LineSplit';
import LassoSplit from '../../assets/tools/LassoSplit';
import React from 'react';
import { Typography } from '@siterecon/sr-styles/dist/elements';
import { SubscriptionPlanIcons } from '../../../../../../modules/subscription/components/Chips/constant';

export const toolbarMenuItemSplit = (
  ele: any,
  handlePinning: any,
  SubscriptionChip: any,
  isEssential: boolean
) => {
  return (
    <Box
      onClick={() => {
        if (!ele.perm) handlePinning(ele.key);
      }}
      key={'split'}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          className='tool'
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'space-between',
            height: '36px',
          }}
        >
          <Box className='image'>{ele.icon}</Box>
          <Typography
            fontSize={12}
            fontWeight={500}
            lineHeight={'18px'}
            style={{ alignSelf: 'center' }}
          >
            {ele.label}
          </Typography>
          <Box className='pin'>{ele.pinned ? <Pinned /> : <Unpinned />}</Box>
        </div>
        <div
          className='tool'
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '36px',
            padding: '0px 40px',
          }}
        >
          <Box className='image'>
            <LineSplit />
          </Box>
          <Typography
            fontSize={12}
            fontWeight={500}
            lineHeight={'18px'}
            style={{ alignSelf: 'center' }}
          >
            Lasso Split
          </Typography>

          {isEssential && (
            <SubscriptionChip
              style={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                right: '25px',
              }}
              iconProp={SubscriptionPlanIcons.PROFESSIONAL}
            />
          )}
        </div>
        <div
          className='tool'
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '36px',
            padding: '0px 40px',
          }}
        >
          <Box className='image'>
            <LassoSplit />
          </Box>
          <Typography
            fontSize={12}
            fontWeight={500}
            lineHeight={'18px'}
            style={{ alignSelf: 'center' }}
          >
            Line Split
          </Typography>
          {isEssential && (
            <SubscriptionChip
              style={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                right: '25px',
              }}
              iconProp={SubscriptionPlanIcons.PROFESSIONAL}
            />
          )}
        </div>
      </div>
    </Box>
  );
};
