import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AttributePopup } from './attribute-popup';
import { SinglePopup } from './layer-selection/single-layer/single-popup';
import { MultiLayerPopup } from './layer-selection/multi-layer/multi-popup';

import {
  setAttributePopupData,
  setAttributeSinglePopupData,
} from '../../store/attributeFeature/actions';
import MapContext from '../pages/project/MapContext';
import { _get } from '../../helpers/utilities/lodashUtils';
import { useOrderStatus } from '@/modules/project/guards/OrderStatus';
import { getAllServiceItems } from '@/store/attributeFeature/thunks';
import './style.less';
import useOrganization from '../../modules/auth/hooks/organization';
import { useElementPositioningContext } from '../../Contexts/ElementPositioningContext';
import { ElementName } from '../../modules/property/types/element-position-visibility.types';
import DraggableWrapper from '../../modules/common/components/DraggableWrapper';
import { CreateOrderEvt } from '../../segment';
import { ACTION_CENTER_TYPE, isThisDeviceIpad } from '../../events/constants';
import { trackEvents } from '../../helpers/utilities';

export const AttributeFeature = (props) => {
  const dispatch = useDispatch();
  const map = useContext(MapContext);
  const {
    attributePopupData: layerData,
    attributeMultiPopupData: multiData,
    attributeSinglePopupData: singleData,
    currentLayer,
  } = useSelector((state) => state.feature);

  const view = useSelector((state) => state.order.currentViewData);
  const isBaseView = props.isBaseView;
  const isEditableView = props.isEditableView;
  const isServiceItemAssignmentLocked = props.isServiceItemAssignmentLocked;
  const orderStatus = useOrderStatus();

  const org = useOrganization();

  const orgId = org?.id;

  const { elementStates } = useElementPositioningContext();
  const actionCenterPosition = elementStates[ElementName.ActionCenterPosition];

  useEffect(
    () => addMapClick(),
    [map, singleData, multiData, layerData, currentLayer]
  );

  useEffect(() => {
    listener();
  }, [view?.viewId]);

  useEffect(() => {
    dispatch(getAllServiceItems(view.orderId, orgId));
  }, []);

  const listener = (event) => {
    if (
      !(
        _get(event, 'originalEvent.ctrlKey') ||
        _get(event, 'originalEvent.metaKey')
      )
    ) {
      singlePopupClose(event);
      featurePopupClose(event);
    }
  };

  const addMapClick = () => {
    map?.on('click', listener);
    return () => {
      map?.un('click', listener);
    };
  };

  const singlePopupClose = (e) => {
    if (singleData) {
      dispatch(setAttributeSinglePopupData(null));
    }
  };

  const multiPopupClose = (e) => {
    if (multiData) {
      const { multiSelectFeature, unSetMultiSelectFeature } = props;
      multiSelectFeature &&
        multiSelectFeature.forEach((item) => {
          if (item.getGeometry().getType() === 'Point') {
            item.setStyle(undefined);
          } else {
            item.setStyle(undefined);
          }
        });
      unSetMultiSelectFeature && unSetMultiSelectFeature();
      props.clearAllMultiSelectedData();
    }
  };

  const featurePopupClose = (e) => {
    layerData && dispatch(setAttributePopupData(null));
  };

  const attributeCardStyle = {
    zIndex: 1200,
    // position: 'relative',
    display: 'block',
    width: actionCenterPosition.width,
    right: actionCenterPosition.position.right,
  };
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const x = windowWidth - 382;
    const y = 100;
    setInitialPosition({ x, y });
  }, []);

  return (
    <div className='action-center-box'>
      {layerData && (
        <div
          id='attribute-popup'
          style={
            {
              // ...attributeCardStyle,
            }
          }
        >
          <DraggableWrapper
            dragHandlerClassName='drag-handle'
            bounds='window'
            initialPosition={{ x: window.innerWidth - (282 + 8), y: 0 }}
            initialSize={{ height: 200, width: 282 }}
            onDragStart={() => {
              trackEvents(CreateOrderEvt.ActionCenterDragStart, {
                type: ACTION_CENTER_TYPE.NONESTIMATION,
              });
            }}
            onDragStop={() => {
              trackEvents(CreateOrderEvt.ActionCenterDragStop, {
                type: ACTION_CENTER_TYPE.NONESTIMATION,
              });
            }}
          >
            <AttributePopup
              isServiceItemAssignmentLocked={isServiceItemAssignmentLocked}
              layerComponents={props.layerComponents}
              selectedLayer={props.selectedLayer}
              geometryType={props.geometryType}
              isBaseView={isBaseView}
              isEditableView={isEditableView}
              viewType={props.viewType}
              orderStatus={orderStatus}
              layersData={props.layersData}
              toggleAddNewLayer={props.toggleAddNewLayer}
              toggleAddAfterLayer={props.toggleAddAfterLayer}
              featureType={props.featureType}
              layerRefs={props.layerRefs}
              resetLayerStyles={props.resetLayerStyles}
              layerSource={props.layerSource}
              orderHash={props.orderHash}
              onServiceItemsChange={props.onServiceItemsChange}
            />
          </DraggableWrapper>
        </div>
      )}

      {multiData &&
        Object.keys(multiData).length === 1 &&
        multiData[Object.keys(multiData)[0]].length === 1 && (<div>
          <DraggableWrapper
            dragHandlerClassName='drag-handle'
            bounds='window'
            initialPosition={{ x: window.innerWidth - (282 + 8), y: 0 }}
            initialSize={{ height: 200, width: 282 }}
            onDragStart={() => {
              trackEvents(CreateOrderEvt.ActionCenterDragStart, {
                type: ACTION_CENTER_TYPE.NONESTIMATION,
              });
            }}
            onDragStop={() => {
              trackEvents(CreateOrderEvt.ActionCenterDragStop, {
                type: ACTION_CENTER_TYPE.NONESTIMATION,
              });
            }}
          >
            <SinglePopup
              closeHandle={multiPopupClose}
              popoverRef={props.popoverRef}
              geometryType={props.geometryType}
              onDuplicateComponent={props.onDuplicateComponent}
              selectedLayer={props.selectedLayer}
              onUpdateLayerComponents={props.onUpdateLayerComponents}
              deleteComponents={props.deleteComponents}
              isBaseView={isBaseView}
              isEditableView={isEditableView}
              viewType={props.viewType}
              orderStatus={orderStatus}
              layersData={props.layersData}
              featureType={props.featureType}
              layerComponents={props.layerComponents}
              orderHash={props.orderHash}
              onServiceItemsChange={props.onServiceItemsChange}
            />
          </DraggableWrapper>
        </div>)
      }

      {multiData &&
        Object.keys(multiData).length === 1 &&
        multiData[Object.keys(multiData)[0]].length > 1 && (
          <DraggableWrapper
            dragHandlerClassName='drag-handle'
            bounds='window'
            initialPosition={{ x: window.innerWidth - (282 + 8), y: 0 }}
            initialSize={{ height: 200, width: 282 }}
            onDragStart={() => {
              trackEvents(CreateOrderEvt.ActionCenterDragStart, {
                type: ACTION_CENTER_TYPE.NONESTIMATION,
              });
            }}
            onDragStop={() => {
              trackEvents(CreateOrderEvt.ActionCenterDragStop, {
                type: ACTION_CENTER_TYPE.NONESTIMATION,
              });
            }}
          >
            <div style={{ ...attributeCardStyle }}>
              <MultiLayerPopup
                closeHandle={multiPopupClose}
                deleteComponents={props.deleteComponents}
                loadLayerComponents={props.loadLayerComponents}
                onUpdateLayerComponents={props.onUpdateLayerComponents}
                geometryType={props.geometryType}
                onDuplicateComponent={props.onDuplicateComponent}
                isBaseView={isBaseView}
                isEditableView={isEditableView}
                viewType={props.viewType}
                orderStatus={orderStatus}
                layersData={props.layersData}
                featureType={props.featureType}
                onMergeComponents={props.onMergeComponents}
                layerComponents={props.layerComponents}
                selectedLayer={props.selectedLayer}
                orderHash={props.orderHash}
                onServiceItemsChange={props.onServiceItemsChange}
              />
            </div>
          </DraggableWrapper>
        )}
    </div>
  );
};
