export enum ElementName {
  NotesReportsSnapsTabsPosition = 'notesReportsSnapsTabsPosition',
  MapControlsPosition = 'mapControlsPosition',
  NearmapDropdownPosition = 'nearmapDropdownPosition',
  ActionCenterPosition = 'actionCenterPosition',
  MapToolBarPosition = 'mapToolBarPosition',
  OrderDetailsPanelPosition = 'orderDetailsPanelPosition',
  NotesReportsSnapsPanelPosition = 'notesReportsSnapsPanelPosition',
}

export interface ElementState {
  visible: boolean;
  position: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  width?: number;
  height?: number;
}

export interface State {
  [key: string]: ElementState;
}
