import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { IS_LOGGING_ENABLED } from './helpers/constants';

// * THIRD PARTY MIDDLEWARE
import thunk from 'redux-thunk';
import logger from 'redux-logger';

// * REDUCERS FROM REDUX DUCKS
import userReducer from './store/user/reducer';
import orderReducer from './store/order/reducer';
import uiReducer from './store/ui/reducer';
import organisationReducer from './store/organisation/reducer';
import teamManagementReducer from './store/teamManagement/reducer';
import announcementsReducer from './store/announcements/reducer';
import bulkOrderReducer from './store/bulkOrder/reducer';
import betaReleaseReducer from './store/betaRelease/reducer';
import notesReducer from './store/notes/reducer';
import featureReducer from './store/attributeFeature/reducer';
import dashboardReducer from './store/dashboard/reducer'
import configurationsReducer from './store/configurations/reducer';
import mapReducer from './store/map/reducer';
import orderQueueReducer from './store/orderQueue/reducer'
import { reducer as subscriptionReducer } from './store/subscription'
import { reducer as workspaceReducer } from './store/workspace'

// * PUTTING ALL THE MIDDLEWARE AT ONE PLACE
let middlewares = [];
if (process.env.NODE_ENV === 'development' && IS_LOGGING_ENABLED) {
  middlewares = [thunk,
    // logger
  ];
} else {
  middlewares = [thunk];
}

// * ROOT REDUCER
const rootReducer = combineReducers({
  user: userReducer,
  order: orderReducer,
  ui: uiReducer,
  organisation: organisationReducer,
  teamManagement: teamManagementReducer,
  announcements: announcementsReducer,
  bulkOrder: bulkOrderReducer,
  orderQueue: orderQueueReducer,
  betaRelease: betaReleaseReducer,
  notes: notesReducer,
  feature: featureReducer,
  dashboard: dashboardReducer,
  configurations: configurationsReducer,
  map: mapReducer,
  subscription: subscriptionReducer,
  workspace: workspaceReducer,
});

const withTrace = composeWithDevTools({ trace: true, traceLimit: 25 })

export const Store = createStore(
  rootReducer,
  withTrace(applyMiddleware(...middlewares))
);
