import React, { Suspense, useEffect } from 'react';
import { useSubscription } from '@/modules/subscription/hooks';
import { SubscriptionState } from '../helpers/enum';
import useAuth from '../../../modules/auth/hooks';
import { Route, Switch } from 'react-router-dom';
import { lazy } from 'react';
import { PATH_WITH_NO_WORKSPACE_ID } from '../../../modules/common/constants/urls';

const SubscriptionWidgetPopup = lazy(() => import('./SubscriptionWidgetPopup'));
const PaymentFailedModal = lazy(() => import('./PaymentFailedModal'));
const RepairSubscriptionModal = lazy(() => import('./RepairSubscriptionModal'));
const PayAsYouGoModal = lazy(() => import('./PayAsYouGoModal'));
const PaywallModal = lazy(
  () => import('@/modules/subscription/components/PaywallModal')
);

const GlobalSubscriptionComponents = () => {
  const { subscriptionState, showSubscriptionWidget } = useSubscription();
  const { user } = useAuth();

  useEffect(() => {
    /** We need to check if User is on Trial Plan, and we have to show the TrialInProgress Subscription Widget on initial load or not? */
    if (!subscriptionState) return;

    if (
      subscriptionState === SubscriptionState.TRIAL_ONGOING &&
      !user.seen_trial_popup
    ) {
      setTimeout(() => showSubscriptionWidget(), 3000);
    }
  }, [subscriptionState]);

  return (
    <Suspense fallback={null}>
      <PaywallModal />
      <Switch>
        <Route exact path={PATH_WITH_NO_WORKSPACE_ID} />
        <Route>
          <PayAsYouGoModal />
          <SubscriptionWidgetPopup />
          <PaymentFailedModal />
          <RepairSubscriptionModal />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default GlobalSubscriptionComponents;
