import { Checkbox, Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Text from '../../../../elements/text/Text';

import './style.less';
import Counter from '../../../../elements/counter/Counter';
import IconType from '../icon-type/IconType';
import '../../../../feature-attributes/feature-attribute.less';
import {
    LINE_GEOMETRY,
    PATH_GEOMETRY,
    POINT_GEOMETRY,
    POLYGON_GEOMETRY,
} from '../../../../../helpers/constants/mapConstants';
import { useDispatch, useSelector } from 'react-redux';
import { _debounce, _isEmpty } from '@/helpers/utilities/lodashUtils';
import { showHideVertices } from '@/store/ui/actions';
import { showOrHideVertices } from '@/helpers/mapUtils/layerUtils';

export const Styler = ({
  handleChange,
  value,
  shape,
  resetLayerStyles,
  layerId,
  layerSource,
  resetStyleToDefault,
  isCustomFeature = false,
}) => {
  const shapeLower = shape?.toLowerCase();
  const strokeList = [PATH_GEOMETRY, POLYGON_GEOMETRY, LINE_GEOMETRY];

  const showArrowTypeColorSizeOpacity = shape && shapeLower === PATH_GEOMETRY;
  const showStrokeColorAndSize = shape && strokeList.includes(shapeLower);
  const showFillColorAndOpacity = shape && shapeLower === POLYGON_GEOMETRY;
  const showSymbolAndSymbolSize = shape && shapeLower === POINT_GEOMETRY;
  const showSymbolOpacityAndSymbolColor =
    shape && shapeLower === POINT_GEOMETRY;
  const globalAssetData = useSelector((state) => state.ui.globalAsset);
  const showVertices = useSelector((state) => state.ui.showVertices);
  const colorContainerStyle = {
    width: '100%',
    height: '30px',
  };
  const dispatch = useDispatch();
  const [showLayerVertices, setLayerVertices] = useState({});
  const [isShowVertices, setShowVertices] = useState(false);

  const renderOpacity = () => {
    if (typeof value?.opacity === 'undefined' || value.opacity === null) {
      return 10;
    }

    return value.opacity <= 1 ? value.opacity * 10 : value.opacity;
  };

  useEffect(() => {
    debounceLoadData(showVertices);
    showVertices.forEach((item) => {
      if (item.id === layerId) {
        setShowVertices(item.checked);
      }
    });
  }, [JSON.stringify(showVertices)]);

  const debounceLoadData = _debounce((checkedLayers) => {
    showOrHideVertices(checkedLayers, layerSource, resetLayerStyles);
  }, 500);

  useEffect(() => {
    let tempArr = [];
    let flagLayerFound = false;
    if (showVertices.length) {
      showVertices.forEach((item) => {
        if (item.id === showLayerVertices.id) {
          flagLayerFound = true;
          tempArr.push(showLayerVertices);
        } else {
          tempArr.push(item);
        }
      });
      if (!flagLayerFound && !_isEmpty(showLayerVertices)) {
        tempArr.push(showLayerVertices);
      }
    } else if (!_isEmpty(showLayerVertices)) {
      tempArr.push(showLayerVertices);
    }
    dispatch(showHideVertices(tempArr));
  }, [showLayerVertices]);

  return (
    <div>
      <Row gutter={16}>
        {showArrowTypeColorSizeOpacity && (
          <Col span={12} className='mb-3'>
            <Text type='b01' color='dark-gray'>
              Arrow Type
            </Text>
            <br />
            <IconType
              handleChange={handleChange}
              value={value?.arrowType}
              name={'arrowType'}
              type={'arrow_head'}
              key={'path'}
              data={globalAssetData?.arrowHead}
            />
          </Col>
        )}

        {showArrowTypeColorSizeOpacity && (
          <Col span={12} className={'w-110 h-24 mb-3'}>
            <Text type='b01' color='dark-gray'>
              Arrow Size
            </Text>
            <br />
            <Counter
              step={2}
              max={50}
              min={0}
              isPercent={false}
              handleChange={handleChange}
              name={'arrowSize'}
              value={value?.arrowSize}
            />
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {showArrowTypeColorSizeOpacity ? (
          <Col span={12} className='color-container mb-3'>
            <Text type='b01' color='dark-gray'>
              Arrow Color
            </Text>
            <br />
            <Input
              name='arrowColor'
              value={value?.arrowColor}
              type='color'
              onChange={(e) => handleChange(e.target.value, 'arrowColor')}
              style={colorContainerStyle}
            />
          </Col>
        ) : (
          <></>
        )}

        {showArrowTypeColorSizeOpacity ? (
          <Col span={12} className={'mb-3'}>
            <Text type='b01' color='dark-gray'>
              Arrow Opacity
            </Text>
            <br />
            <Counter
              step={1}
              max={10}
              min={0}
              isPercent={true}
              handleChange={handleChange}
              name={'opacity'}
              value={renderOpacity()}
            />
          </Col>
        ) : (
          <></>
        )}
      </Row>

      <Row gutter={16}>
        {showStrokeColorAndSize ? (
          <Col span={12} className={'color-container mb-3'}>
            <Text type='b01' color='dark-gray'>
              Stroke Color
            </Text>
            <br />
            <Input
              value={value?.color}
              name='color'
              type='color'
              onChange={(e) => handleChange(e.target.value, 'color')}
              style={colorContainerStyle}
            />
          </Col>
        ) : (
          <></>
        )}
        {showStrokeColorAndSize ? (
          <Col span={12} className={'mb-3'}>
            <Text type='b01' color='dark-gray'>
              Stroke Size
            </Text>
            <br />
            <Counter
              key={value?.stroke.toString()}
              step={1}
              max={10}
              min={1}
              isPercent={false}
              handleChange={handleChange}
              name={'stroke'}
              value={value?.stroke}
            />
          </Col>
        ) : (
          <></>
        )}
      </Row>

      <Row justify='center' align='middle' gutter={16}>
        {showFillColorAndOpacity ? (
          <Col span={12} className={'color-container mb-3'}>
            <Text type='b01' color='dark-gray'>
              Fill Color
            </Text>
            <br />
            <Input
              name='fillColor'
              value={value?.fillColor}
              type='color'
              onChange={(e) => handleChange(e.target.value, 'fillColor')}
              style={colorContainerStyle}
            />
          </Col>
        ) : (
          <></>
        )}

        {showFillColorAndOpacity ? (
          <Col span={12} className={'mb-3'}>
            <Text type='b01' color='dark-gray'>
              Opacity
            </Text>
            <br />
            <Counter
              key={value?.opacity.toString()}
              step={1}
              max={10}
              min={0}
              isPercent={true}
              handleChange={handleChange}
              name={'opacity'}
              value={renderOpacity()}
            />
          </Col>
        ) : (
          <></>
        )}
      </Row>

      <Row gutter={16}>
        {showSymbolAndSymbolSize && (
          <Col span={12} className={'mb-3'}>
            <Text type='b01' color='dark-gray'>
              Symbol
            </Text>
            <br />
            <IconType
              handleChange={handleChange}
              value={value?.symbolType}
              name={'symbolType'}
              type={'symbol'}
              key={'point'}
              data={globalAssetData?.pointSymbol}
            />
          </Col>
        )}

        {showSymbolAndSymbolSize && (
          <Col span={12} className={'mb-3'}>
            <Text type='b01' color='dark-gray'>
              Symbol Size
            </Text>
            <br />
            <Counter
              step={2}
              max={50}
              min={0}
              isPercent={false}
              handleChange={handleChange}
              name={'symbolSize'}
              value={value?.symbolSize}
            />
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {showSymbolOpacityAndSymbolColor ? (
          <Col span={12} className='color-container mb-3'>
            <Text type='b01' color='dark-gray'>
              Symbol Color
            </Text>
            <br />
            <Input
              name='symbolColor'
              value={value?.symbolColor}
              type='color'
              onChange={(e) => handleChange(e.target.value, 'symbolColor')}
              style={colorContainerStyle}
            />
          </Col>
        ) : (
          <></>
        )}

        {showSymbolOpacityAndSymbolColor ? (
          <Col span={12} className={'mb-3'}>
            <Text type='b01' color='dark-gray'>
              Symbol Opacity
            </Text>
            <br />
            <Counter
              step={1}
              max={10}
              min={0}
              isPercent={true}
              handleChange={handleChange}
              name={'opacity'}
              value={renderOpacity()}
            />
          </Col>
        ) : (
          <></>
        )}
      </Row>

      {!isCustomFeature && (
        <Row
          align={'middle'}
          justify={'space-around'}
          className={'my-2 px-1'}
          gutter={16}
        >
          <Col span={14}>
            <Row align={'middle'} justify={'start'}>
              <Checkbox
                style={{ color: '#333333', fontSize: '12px' }}
                checked={!!isShowVertices}
                onChange={(e) => {
                  setLayerVertices({ id: layerId, checked: e.target.checked });
                }}
                disabled={shape === 'point' || shape === 'path'}
              />
              <Text type='b01' className={'ml-2'}>
                Show vertices{' '}
              </Text>
            </Row>
          </Col>
          <Col span={10}>
            <Row align={'middle'} justify={'end'}>
              <Text
                type='p28'
                color={'link'}
                onClick={resetStyleToDefault}
                underlined
                style={{ letterSpacing: -0.1 }}
              >
                Reset To Default
              </Text>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};
