import { useEffect, useState } from 'react';

const useEmailValidation = (values: Array<string>) => {
  const [invalidMails, setInvalidMails] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const [invalidMailsArray, setInvalideMailsArray] = useState<string[]>();

  const emailRegex = /^([a-zA-Z0-9_\.-]+)@([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})$/;

  useEffect(() => {
    const invalidInputs = values.filter(
      (value: any) => !value.match(emailRegex)
    );
    setInvalideMailsArray(invalidInputs);
    if (invalidInputs.length === 0) {
      setInvalidMails('');
      setIsEmailError(false);
    } else if (invalidInputs.length === 1) {
      setInvalidMails(invalidInputs.join('') + ' is not a valid email.');
      setIsEmailError(true);
    } else {
      setInvalidMails(
        invalidInputs.slice(0, -1).join(', ') +
          ' and ' +
          invalidInputs.slice(-1) +
          ' are not valid emails.'
      );
      setIsEmailError(true);
    }
  }, [values]);

  return {
    invalidMails,
    isEmailError,
    invalidMailsArray,
  };
};

export default useEmailValidation;
