/** Pads given number or string with Leading zeroes to given max length. Ex. Input -> 3, Output -> 03 */
export const padWithZeros = (
  number: number | string,
  maxLength: 2
): string => {
  const input =
    typeof number === 'number' ? String(Math.round(number)) : number;
  return input.padStart(maxLength, '0');
};

// Pad with emptyspaces
export const padWithEmptySpaces = (maxLength: number): string => {
  let str = '\xa0';
  const pad = maxLength;
  return str.repeat(pad);
};
