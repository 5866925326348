export const TypeOfCurrencySign = {
  USD: '$',
  INR: '₹',
};
export const TypeOfCurrency = {
  USD: 'USD',
  INR: 'INR',
};

export const currencyAmountIn = (amount, type) => {
  return `${TypeOfCurrencySign[type]}${amount}`;
};

export const amountFormat = (amount) => {
  if (amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return 0;
  }
};
