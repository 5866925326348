import React from 'react';

import DragTool from '../assets/tools/DragTool';
import LineTool from '../assets/tools/LineTool';
import PointTool from '../assets/tools/PointTool';
import PolygonTool from '../assets/tools/PolygonTool';
import SelectTool from '../assets/tools/SelectTool';
import CutTool from '../assets/tools/CutTool';
import LineSplit from '../assets/tools/LineSplit';
import EditTool from '../assets/tools/EditTool';
import MeasureTool from '../assets/tools/Measure';
import LassoSplit from '../assets/tools/LassoSplit';
import NewNote from '../assets/tools/NewNote';
import { FeatureType } from '../../../../feature-attributes/types';
import { ToolKey, ToolList } from './types';

export const TOOLS_DATA = [
  {
    title: ToolList.SELECT,
    icon: <SelectTool />,
    keyVal: ToolKey.SELECT,
    viableLayers: [
      FeatureType.POINT,
      FeatureType.LINE,
      FeatureType.POLYGON,
      FeatureType.PATH,
    ],
  },
  {
    title: ToolList.POLYGON,
    icon: <PolygonTool />,
    keyVal: ToolKey.POLYGON,
    viableLayers: [FeatureType.POLYGON],
  },
  {
    title: ToolList.LINE,
    icon: <LineTool />,
    keyVal: ToolKey.LINE,
    viableLayers: [FeatureType.LINE],
  },
  {
    title: ToolList.POINT,
    icon: <PointTool />,
    keyVal: ToolKey.POINT,
    viableLayers: [FeatureType.POINT],
  },
  {
    title: ToolList.CUT,
    icon: <CutTool />,
    keyVal: ToolKey.CUT,
    viableLayers: [FeatureType.POLYGON],
  },
  {
    title: ToolList.EDIT,
    icon: <EditTool />,
    keyVal: ToolKey.EDIT,
    viableLayers: [FeatureType.LINE, FeatureType.POLYGON, FeatureType.PATH],
  },
  {
    title: ToolList.SPLIT,
    icon: <LineSplit />,
    keyVal: ToolKey.SPLIT,
    viableLayers: [FeatureType.LINE, FeatureType.POLYGON, FeatureType.PATH],
    sub: [
      {
        title: ToolList.LINE_SPLIT,
        icon: <LineSplit />,
        keyVal: ToolKey.LINE_SPLIT,
        viableLayers: [FeatureType.LINE, FeatureType.POLYGON, FeatureType.PATH],
      },
      {
        title: ToolList.LASSO_SPLIT,
        icon: <LassoSplit />,
        keyVal: ToolKey.LASSO_SPLIT,
        viableLayers: [FeatureType.POLYGON],
      },
    ],
  },
  {
    title: ToolList.NEW_NOTE,
    icon: <NewNote />,
    keyVal: ToolKey.NEW_NOTE,
    viableLayers: [
      FeatureType.POINT,
      FeatureType.LINE,
      FeatureType.POLYGON,
      FeatureType.PATH,
    ],
  },
  {
    icon: <MeasureTool />,
    title: ToolList.MEASURE,
    keyVal: ToolKey.MEASURE,
    viableLayers: [
      FeatureType.POINT,
      FeatureType.LINE,
      FeatureType.POLYGON,
      FeatureType.PATH,
    ],
  },
  {
    icon: <DragTool />,
    title: ToolList.DRAG,
    keyVal: ToolKey.DRAG,
    viableLayers: [
      FeatureType.POINT,
      FeatureType.LINE,
      FeatureType.POLYGON,
      FeatureType.PATH,
    ],
  },
];

export const TOOLBAR_SETTINGS = [
  {
    label: ToolList.SELECT,
    icon: <SelectTool />,
    shortcut: '',
    pinned: true,
    perm: true,
    key: ToolKey.SELECT,
  },
  {
    label: ToolList.POLYGON,
    icon: <PolygonTool />,
    shortcut: 'D',
    pinned: true,
    perm: true,
    key: ToolKey.POLYGON,
  },
  {
    label: ToolList.LINE,
    icon: <LineTool />,
    shortcut: 'D',
    pinned: true,
    perm: true,
    key: ToolKey.LINE,
  },
  {
    label: ToolList.POINT,
    icon: <PointTool />,
    shortcut: 'D',
    pinned: true,
    perm: true,
    key: ToolKey.POINT,
  },
  {
    label: ToolList.CUT,
    icon: <CutTool />,
    shortcut: 'C',
    pinned: true,
    perm: false,
    key: ToolKey.CUT,
  },
  {
    label: ToolList.EDIT,
    icon: <EditTool />,
    shortcut: 'E',
    pinned: true,
    perm: false,
    key: ToolKey.EDIT,
  },
  {
    label: ToolList.SPLIT,
    isSplitTool: true,
    icon: <LineSplit />,
    shortcut: 'K',
    pinned: false,
    perm: false,
    key: ToolKey.SPLIT,
  },
  {
    label: ToolList.NEW_NOTE,
    icon: <NewNote />,
    shortcut: '',
    pinned: true,
    perm: false,
    key: ToolKey.NEW_NOTE,
  },
  {
    label: ToolList.MEASURE,
    icon: <MeasureTool />,
    shortcut: '',
    pinned: true,
    perm: false,
    key: ToolKey.MEASURE,
  },
  {
    label: ToolList.DRAG,
    icon: <DragTool />,
    shortcut: '',
    pinned: false,
    perm: false,
    key: ToolKey.DRAG,
  },
];

export const NO_LAYER_POPUP_TOOLS = [
  ToolKey.SELECT,
  ToolKey.MEASURE,
  ToolKey.NEW_NOTE,
];

export const DRAW_TOOLS = [
  ToolKey.POLYGON,
  ToolKey.LINE,
  ToolKey.POINT,
  ToolKey.PATH,
];

export const DEFAULT_PINNED_TOOLS = [
  ToolKey.SELECT,
  ToolKey.POLYGON,
  ToolKey.LINE,
  ToolKey.POINT,
  ToolKey.PATH,
  ToolKey.CUT,
  ToolKey.EDIT,
];
