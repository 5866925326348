import { forwardRef } from 'react';
import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import './style.less';

interface ButtonProps extends AntButtonProps {}

const Button = forwardRef<HTMLElement, ButtonProps>(
  ({ className, href, ...rest }, ref) => {
    return (
      <>
        <AntButton
          className={`sr-button ${className || ''}`}
          {...rest}
          ref={ref}
          href={href}
        />
      </>
    );
  }
);

export default Button;
