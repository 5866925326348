import { capitalizeFirstLetter } from '../../../../../helpers/utilities';

export const getWarningMsg = (featureArr: string[]) => {
  const capitalisedArray = featureArr.map((item) =>
    capitalizeFirstLetter(item)
  );
  if (capitalisedArray.length === 1) {
    return capitalisedArray[0] + ' layer';
  } else {
    const newArr = capitalisedArray.slice(0, capitalisedArray.length - 1);
    return (
      newArr.join(', ') +
      ' and ' +
      capitalisedArray[capitalisedArray.length - 1] +
      ' layers'
    );
  }
};
