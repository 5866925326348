// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector421IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector421Icon(props: Vector421IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 5 10"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M2.474 0l1.755 1.912c-.88 1.007-1.498 2.32-1.704 3.818a7.735 7.735 0 00.193 3.08l-2.347.792a10.76 10.76 0 01-.269-4.287C.39 3.23 1.248 1.402 2.474 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector421Icon;
/* prettier-ignore-end */
