// @ts-nocheck
import { ComponentType, FC } from 'react';
import { IObject, UserRole, UserRoleId } from '@/modules/common/types';
import { useRole } from './';

interface WithRoleProps {
  role: UserRole;
  roleId: UserRoleId;
}

/**
 * Adds role: {@link UserRole} and roleId: {@link UserRoleId}, to the Wrapped component
 */
const WithRole = <P extends IObject = any>(
  Component: ComponentType<P>
): FC<P & WithRoleProps> => {
  const WrappedComponent: FC<P & WithRoleProps> = (props) => {
    const { role, roleId } = useRole();

    return (
      <Component {...props} role={role} roleId={roleId} />
    ) as unknown as JSX.Element;
  };

  return WrappedComponent;
};

export default WithRole;
