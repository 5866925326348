import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'antd';
import { axiosInstance } from '@/components/pages/feed/api';
import TextIconButton from '@/components/elements/text-icon-button';
import EditableText from '@/components/elements/editable-text';
import { ReactComponent as EditIcon } from '@/assets/icons/edit-icon.svg';
import { ReactComponent as DoneIcon } from '@/assets/icons/done-icon.svg';
import { ReactComponent as Pin } from '@/assets/icons/location-pin.svg';
import { updateOrderWithId } from '@/store/dashboard/action';
import useActiveTab from '@myProperties/hooks/useActiveTab';
import { useDispatch } from 'react-redux';
import { trackEvents } from '@/helpers/utilities';
import ApplicationHead from '@/components/helmet';
import Text from '@/components/elements/text/Text';
import { Box } from '@/modules/common/elements';
import './style.less';
import { updatePropertyName } from '../../../../../../../../../store/order/actions';

interface PropertyNameProps {
  viewportWidth?: string;
  orderId: number;
  value: string;
  isMapPage?: boolean;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  className?: string;
  address: string;
  singleClickHandler?: (e: any) => void;
}

interface OrderInfo {
  orderNo: number;
  propertyName: string;
}

const PropertyName = ({
  viewportWidth,
  orderId,
  value,
  isMapPage = false,
  style,
  inputStyle,
  className,
  address,
  singleClickHandler,
}: PropertyNameProps) => {
  const activeTab = useActiveTab();
  const dispatch = useDispatch();
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [showEditButton, setShowEditButton] = useState<boolean>(false);
  const [showDoneButton, setShowDoneButton] = useState<boolean>(false);
  const [isEmptyString, setIsEmptyString] = useState(false);
  const [editTextVaue, setEditValue] = useState('');
  const [editedValue, setEditedValue] = useState('');

  const doneButtonRef = useRef(null);
  let timeoutID = useRef<any>(null);

  useEffect(() => {}, [value]);

  const handleClickTypes = (e: any) => {
    getClickHandler(
      (e) => {
        isMapPage ? setEnableEdit(true) : '';
        if (singleClickHandler && !enableEdit) {
          singleClickHandler(e);
        }
      },
      (e) => {}
    )(e);
  };

  const getClickHandler = (
    onclick: (event: any) => void,
    ondblclick: (event: any) => void,
    delay?: number
  ) => {
    delay = delay || 250;
    return (event: any) => {
      if (!timeoutID.current) {
        timeoutID.current = window.setTimeout(() => {
          onclick(event);
          timeoutID.current = null;
        }, delay);
      } else {
        timeoutID.current = clearTimeout(timeoutID.current);
        ondblclick(event);
      }
    };
  };

  const updatePropName = async (orderData: OrderInfo) => {
    const tempData = new FormData();
    tempData.append('orderNo', orderData.orderNo.toString());
    tempData.append('propertyName', orderData.propertyName);

    const response = await axiosInstance.post(
      '/api/order/updateOrderDetails/',
      tempData
    );

    dispatch(
      updateOrderWithId({
        tab: activeTab,
        data: {
          orderId: orderId,
          prospect: {
            propertyName: orderData.propertyName,
          },
        },
        filter: {
          key: 'propertyName',
          value: orderData.propertyName,
        },
      })
    );

    dispatch(updatePropertyName(orderData.propertyName));

    return response;
  };

  const stopEventBubbling = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const enterKeydownHandler = (text: string) => {
    if (!text.replace(/\s/g, '').length) {
      setIsEmptyString(true);
    } else {
      setEditedValue(text);
      setIsEmptyString(false);
      updatePropName({
        orderNo: orderId,
        propertyName: text,
      });
      trackEvents('property-name__edit', {
        initialPropertyName: value,
        finalPropertyName: text,
        source: isMapPage ? 'property-map' : 'dashboard',
      });
      setEnableEdit(false);
      setShowDoneButton(false);
    }
  };

  const doneHandler = (e: any) => {
    stopEventBubbling(e);
    setIsEmptyString(false);
    setEditedValue(editTextVaue);
    updatePropName({
      orderNo: orderId,
      // @ts-ignore
      propertyName: editTextVaue,
    });
    trackEvents('property-name__edit', {
      initialPropertyName: value,
      // @ts-ignore
      finalPropertyName: editTextVaue,
      source: isMapPage ? 'property-map' : 'dashboard',
    });
    setEnableEdit(false);
    setShowDoneButton(false);
  };

  const editHandler = (e: any) => {
    stopEventBubbling(e);
    setEnableEdit(true);
    setShowEditButton(false);
    if (!isMapPage) {
      setShowDoneButton(true);
    }
  };

  const mouseEnterHandler = () => {
    if (enableEdit || isMapPage) {
      return;
    }
    setShowEditButton(true);
  };

  const blurHandler = (e: any) => {
    if (
      e.relatedTarget &&
      (e.relatedTarget.id === 'done-icon-btn' ||
        e.relatedTarget.id === 'done-text-btn')
    ) {
      return;
    }
    if (isMapPage) {
      doneHandler(e);
      return;
    }
    setShowEditButton(false);
    setShowDoneButton(false);
    setEnableEdit(false);
    setIsEmptyString(false);
  };

  const keydownHandler = (e: any) => {
    // escape key
    if (e.keyCode === 27) {
      blurHandler(e);
    }
  };

  const mapPageButtonStyle = { width: '24px', borderRadius: '20px' };

  return (
    <Row
      style={{ flexFlow: 'nowrap' }}
      className={
        isMapPage ? (enableEdit ? 'editMode' : 'propertyName-row') : ''
      }
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={() => setShowEditButton(false)}
      onClick={(e) => {
        stopEventBubbling(e);
        handleClickTypes(e);
      }}
    >
      {editedValue.length > 0 && (
        <ApplicationHead title={isMapPage && editedValue} />
      )}
      <Box flex style={{ flexDirection: isMapPage ? '' : 'column' }}>
        <EditableText
          isEditModeOn={enableEdit}
          value={value}
          handleDoubleClick={(e) => editHandler(e)}
          handleEnter={enterKeydownHandler}
          handleBlur={blurHandler}
          handleEmptyString={(valid) => setIsEmptyString(valid)}
          handleKeydown={keydownHandler}
          style={style}
          inputStyle={inputStyle}
          showTooltip={!isMapPage}
          setInput={setEditValue}
          isMapPage={isMapPage}
        />
        {!isMapPage && !enableEdit && value !== address && (
          <>
            <Box className='d-flex items-center'>
              <Pin style={{ marginRight: '4px' }} />
              <Text type='p33' style={{ color: '#666666' }}>
                {address}
              </Text>
            </Box>
          </>
        )}
      </Box>

      {isMapPage && showEditButton && (
        <TextIconButton
          icon={<EditIcon />}
          onClick={(e: any) => editHandler(e)}
          style={mapPageButtonStyle}
          className={'ml-4'}
        />
      )}

      {isMapPage && showDoneButton && (
        <TextIconButton
          id='done-icon-btn'
          ref={doneButtonRef}
          icon={<DoneIcon />}
          onClick={(e: any) => doneHandler(e)}
          disable={isEmptyString}
          style={mapPageButtonStyle}
          className={'ml-4'}
        />
      )}

      {/* {isMapPage && !showDoneButton && !showEditButton && (
        <TextIconButton
          style={mapPageButtonStyle}
          className={'opacity-0 ml-4'}
        />
      )} */}

      {!isMapPage && showEditButton && !showDoneButton && (
        <TextIconButton
          icon={<EditIcon />}
          text='Edit'
          type='p32'
          onClick={(e: any) => editHandler(e)}
          className={`${showEditButton ? 'opacity-1' : 'opacity-0'} ml-4`}
        />
      )}

      {!isMapPage && showDoneButton && !showEditButton && (
        <TextIconButton
          id='done-text-btn'
          icon={<DoneIcon />}
          text='Done'
          type='p32'
          disable={isEmptyString}
          onClick={(e: any) => doneHandler(e)}
          className={`${showDoneButton ? 'opacity-1' : 'opacity-0'} ml-4`}
        />
      )}
    </Row>
  );
};

export default PropertyName;
