import { Button } from 'antd';
import Text from '@elements/text/Text';
import './style.less';
import { forwardRef, Ref } from 'react';
import classNames from 'classnames';

type TextIconButtonVariant = 'default' | 'primary:alt';

interface TextIconButtonProps {
  icon?: any;
  text?: string;
  type?: string;
  onClick?: (e?: any) => void;
  disable?: boolean;
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  props?: any;
  variant?: TextIconButtonVariant;
}

const TextIconButton = forwardRef(
  (
    {
      icon,
      text,
      type,
      onClick,
      disable = false,
      id,
      className,
      style,
      variant = 'default',
    }: TextIconButtonProps,
    ref?: Ref<any>
  ) => {
    return (
      <Button
        id={id}
        className={classNames('edit-btn px-2', className, {
          'variant--primary-alt': variant === 'primary:alt',
        })}
        style={style}
        onClick={(e) => onClick && onClick(e)}
        disabled={disable}
        ref={ref as any}
      >
        <>
          {icon && icon}
          {text && (
            <Text
              type={type ? type : 'p32'}
              color={`${disable ? 'gray-shade' : 'dark-gray'}`}
              className='ml-2'
            >
              {text}
            </Text>
          )}
        </>
      </Button>
    );
  }
);

export default TextIconButton;
