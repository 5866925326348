import { Select, Snap, Translate } from 'ol/interaction';
import { altKeyOnly, singleClick } from 'ol/events/condition';
import { getComponentArea } from './mapCalculations';
import { transformCRS3857ToEPSG4326 } from './mapInit';
import {
  hoveredFeatureStyle,
  pointHoveredFeatureStyle,
  pointHoverIconFeature,
  pointSelectedFeatureStyle,
  pointSelectedTempStyle,
  selectedFeatureStyle,
  STYLE_INTERNAL_8,
  updatePathStyleFunction,
} from '../mapGlobals/styles';
import { GeoJSON } from 'ol/format';
import * as turf from '@turf/turf';
import { removeCutInteraction } from './tools/cutTool';
import { removeDrawInteraction } from './tools/drawTool';
import { removeModifyInteraction } from './tools/modifyTool';
import { removeSnipInteraction } from './tools/snipTool';
import { removeMeasureInteraction } from './tools/measurementTool';
import { unByKey } from 'ol/Observable';
import { _get } from '../utilities/lodashUtils';
import { getFeatureValue } from './layerUtils';
import { getCenter } from 'ol/extent';
import { trackEvents } from '../utilities';
import { Store } from '../../store';
import { setEstimationAssignedItemBarComponent } from '../../store/order/actions';
import AreaIconHover from '../../assets/action-center/AreaIconHover.svg';
import PerimeterIconHover from '../../assets/action-center/PerimeterIconHover.svg';
import { DragSegment } from '@/helpers/mapUtils/tools/toolSegment';

let featureStyleOnHover;
let selected = null;
let clicked = [];
let hoverOverlayPopoverInteraction = true;
let selectInteraction;
let translateInteraction;
let hoverListener = null;
let clickListener = null;
let startLongLat = null;
let endLongLat = null;
const NO_PARCEL_ADDRESS_PIN = 'NO_PARCEL_ADDRESS_PIN';

// Reset interactions on the map
export const resetInteraction = (mapRef, interactionName) => {
  if (mapRef) {
    resetSnap(mapRef);
    mapRef.getInteractions().forEach((interaction) => {
      if (interaction) {
        mapRef.getInteractions().pop();
      }
    });
    removeStaticTooltips();
  }
};

const removeStaticTooltips = () => {
  document
    .querySelectorAll('div.ol-tooltip.ol-tooltip-static')
    .forEach((item) => item.remove());
};

// Translate feature with copy
export const translateFeatureWithCpy = (
  mapRef,
  layerObj,
  layerName,
  callback,
  setDragMouseCursor,
  setSelectedFeature,
  segmentPayload,
  hasToolStart
) => {
  const vectorSource = layerObj.getSource();
  const highlightStyle = STYLE_INTERNAL_8;

  // Remove existing interactions
  if (selectInteraction) {
    mapRef.removeInteraction(selectInteraction);
  }
  if (translateInteraction) {
    mapRef.removeInteraction(translateInteraction);
  }

  // Add selected interaction
  selectInteraction = new Select({
    source: vectorSource,
    style: highlightStyle,
    condition: singleClick,
    filter: (feature) => feature.getProperties().name === layerName,
  });
  selectInteraction.on('select', (e) => {
    if (altKeyOnly(e.mapBrowserEvent)) {
      setDragMouseCursor('drag duplicate');
    } else {
      setDragMouseCursor('drag');
    }
    if (e.selected.length > 0) {
      e.selected.forEach((feature) => {
        setSelectedFeature(feature.getProperties());
      });
    } else {
      setSelectedFeature(null);
    }
  });
  mapRef.addInteraction(selectInteraction);

  // Add translate interaction
  translateInteraction = new Translate({
    condition: altKeyOnly,
    features: selectInteraction.getFeatures(),
  });
  mapRef.addInteraction(translateInteraction);

  // Handle translate start
  handleTranslateStart(
    mapRef,
    translateInteraction,
    vectorSource,
    layerName,
    callback,
    setDragMouseCursor,
    hasToolStart
  );

  // Handle translate end
  handleTranslateEnd(
    mapRef,
    translateInteraction,
    vectorSource,
    layerName,
    callback,
    segmentPayload,
    hasToolStart
  );
};

// Handle translate start event
const handleTranslateStart = (
  mapRef,
  translate,
  source,
  layerName,
  callback,
  setDragMouseCursor,
  hasToolStart
) => {
  translate.on('translatestart', (event) => {
    hasToolStart(true);
    event.features.forEach((feature) => {
      const properties = feature.getProperties();
      startLongLat = transformCRS3857ToEPSG4326(
        getCenter(feature.getGeometry().getExtent())
      );
      if (properties?.name === layerName) {
        if (altKeyOnly(event.mapBrowserEvent)) {
          const cloneFeature = feature.clone();
          source.addFeature(cloneFeature);
        }
      } else {
        alert('Please Select layer feature');
      }
    });
  });
};

// Handle translate end event
const handleTranslateEnd = (
  mapRef,
  translate,
  source,
  layerName,
  callback,
  segmentPayload,
  hasToolStart
) => {
  translate.on('translateend', (event) => {
    event.features.forEach((feature) => {
      const properties = feature.getProperties();
      if (properties?.name === layerName) {
        endLongLat = transformCRS3857ToEPSG4326(
          getCenter(feature.getGeometry().getExtent())
        );

        const geojson_geom = new GeoJSON();
        const drawGeometry = feature.getGeometry().clone();
        const drawCoordinates = geojson_geom.writeGeometry(
          drawGeometry.transform('EPSG:3857', 'EPSG:4326')
        );
        const drawnPolygon = JSON.parse(drawCoordinates);

        if (event?.mapBrowserEvent?.originalEvent?.altKey) {
          const tempId = `temp_${Math.random()}`;
          const nextId = source.getFeatures().length;
          const reqFeature = turf.feature(drawnPolygon, {
            temp_id: tempId,
            componentIndexing: displayComponentName(nextId + 1),
          });

          feature.setProperties({
            temp_id: tempId,
            componentIndexing: displayComponentName(nextId + 1),
          });

          const reqData = {
            geoJson: reqFeature,
          };

          if (callback) {
            trackEvents('map-tools__select', {
              toolType: 'drag-duplicate',
              layerName: properties.name,
              geometryType: reqFeature.type,
              area: getFeatureValue({ ...reqFeature, properties }),
              count: event.features.length,
              isMultiSelected: false,
              ...segmentPayload,
            });

            callback('drawInteraction', reqData, tempId);
            hasToolStart(false);
          }
        } else {
          const reqFeature = turf.feature(drawnPolygon, {
            componentIndexing: _get(properties, 'componentIndexing'),
          });

          feature.setProperties({
            componentIndexing: _get(properties, 'componentIndexing'),
          });

          const reqData = {
            geoJson: reqFeature,
          };

          if (callback) {
            DragSegment(startLongLat, endLongLat);
            callback(
              'modifyInteraction',
              reqData,
              properties.actualComponentId
            );
            hasToolStart(false);
          }
        }
      }
    });
  });
};

export const featureHoverIn = (layerSource, layerIndex, mapRef, layersData) => {
  const features = layerSource?.getSource().getFeatures();

  if (!features) return;

  for (const feature of features) {
    const selectedFeatureStyle = feature.getStyle();

    if (
      feature.values_.id === layerIndex &&
      (!selectedFeatureStyle ||
        !Array.isArray(selectedFeatureStyle) ||
        (typeof selectedFeatureStyle?.getFill === 'function' &&
          selectedFeatureStyle?.getFill()?.getColor() ===
            hoveredFeatureStyle.getFill().getColor()))
    ) {
      if (feature.getGeometry().getType() === 'Point') {
        layersData.forEach((layer) => {
          if (layer.layerId === +feature.getProperties()?.layerId) {
            layer?.style &&
              feature.setStyle(pointHoveredFeatureStyle(layer.style));
          }
        });
      } else {
        feature.setStyle(hoveredFeatureStyle);
      }
    } else {
      if (
        !Array.isArray(selectedFeatureStyle) &&
        typeof selectedFeatureStyle?.getFill === 'function' &&
        selectedFeatureStyle?.getFill()?.getColor() ===
          hoveredFeatureStyle.getFill().getColor()
      ) {
        feature.setStyle(false);
      } else if (Array.isArray(selectedFeatureStyle)) {
        let haveSelectedStyle = false;
        selectedFeatureStyle.forEach((style) => {
          if (
            style?.getFill()?.getColor() ===
            pointSelectedFeatureStyle.getFill().getColor()
          ) {
            haveSelectedStyle = true;
          }
        });
        if (!haveSelectedStyle) {
          feature.setStyle(false);
        }
      }
      layerSource.setZIndex(1);
    }
  }
};

export const featureHoverOut = (layerSource, popover, resetFeature = false) => {
  const features = layerSource?.getSource().getFeatures();

  if (!features) return;

  for (const feature of features) {
    const selectedFeatureStyle = feature.getStyle();

    if (
      (!Array.isArray(selectedFeatureStyle) &&
        selectedFeatureStyle &&
        selectedFeatureStyle?.getFill()?.getColor() ===
          hoveredFeatureStyle.getFill().getColor()) ||
      resetFeature
    ) {
      feature.setStyle(false);
    } else if (Array.isArray(selectedFeatureStyle)) {
      let haveSelectedStyle = false;
      selectedFeatureStyle.forEach((style) => {
        if (
          style?.getFill()?.getColor() ===
          pointSelectedFeatureStyle(style)?.getFill()?.getColor()
        ) {
          haveSelectedStyle = true;
        }
      });

      if (!haveSelectedStyle) {
        popover.style.display = 'none';
        selected.setStyle(false);
      }
    }
  }

  document.getElementById('map-popover').style.display = 'none';
};

export const resetSnap = (mapRef) => {
  let count = 0;
  mapRef?.getInteractions().forEach((interaction) => {
    if (interaction instanceof Snap) {
      count = count + 1;
    }
  });
  for (let i = 0; i < count; i++) {
    mapRef.getInteractions().pop();
  }
};

const setReduxEstimationAssignedItemBarComponent = (componentId) => {
  Store.dispatch(setEstimationAssignedItemBarComponent(componentId));
};

export const hoverLayer = (
  olMapRef,
  popoverRef,
  editingToolVal,
  hoverFeatureValue,
  hoverFeatureAction,
  noteActions,
  activeTool,
  hoverStyle,
  showPopover = true,
  listenerSource = 'takeoff'
) => {
  const popover = popoverRef.current;

  const handlePointerMove = (event) => {
    // Check if a feature is selected and has a style applied
    if (selected !== null && selected.getStyle()) {
      const selectedFeatureStyle = selected.getStyle();

      // If the selected feature has a hover style applied, reset it
      if (
        (!Array.isArray(selectedFeatureStyle) &&
          selectedFeatureStyle &&
          (typeof selectedFeatureStyle.getFill !== 'function' ||
            selectedFeatureStyle?.getFill()?.getColor() ===
              hoveredFeatureStyle.getFill().getColor())) ||
        (Array.isArray(selectedFeatureStyle) &&
          selectedFeatureStyle.some(
            (style) =>
              style?.getFill()?.getColor() ===
              hoveredFeatureStyle.getFill().getColor()
          ))
      ) {
        popover.style.display = 'none';
        hoverStyle(selected.getProperties(), selected, true);
        setReduxEstimationAssignedItemBarComponent(null);
      }

      selected = null;
    }

    const currentTool = () => {
      if (listenerSource === 'estimation') {
        return Store.getState().user.activeTool ?? 'select';
      }

      return activeTool;
    };

    // Check if the 'select' tool is active
    if (currentTool() === 'select') {
      olMapRef.forEachFeatureAtPixel(event.pixel, (feature) => {
        // Don't remove the address pin when there's no parcel for the property
        if (feature.getProperties().name === NO_PARCEL_ADDRESS_PIN) {
          return;
        }

        try {
          popover.style.left = `${event.pixel[0] + 10}px`;
          popover.style.top = `${event.pixel[1] + 10}px`;

          selected = feature;
          if (Array.isArray(feature.getStyle())) {
            let iconPresent = false;
            feature.getStyle().forEach((style) => {
              if (style.getImage()) iconPresent = true;
            });
            if (!iconPresent) return;
          } else if (feature.getStyle()) return;
          let properties = feature.getProperties();
          if (!properties?.layerId) {
            return;
          }

          const componentId = properties.componentId ?? properties['ID'];

          // Set the componentId for the estimation assigned item bar
          if (componentId) {
            setReduxEstimationAssignedItemBarComponent(componentId);
          }

          // Check if the feature has area or length properties
          if (
            properties.Area ||
            properties['Surface Area'] ||
            properties['Ring Area'] ||
            properties.Length
          ) {
            if (
              Object.keys(hoverFeatureValue).length === 0 &&
              properties['name']
            ) {
              hoverFeatureAction(properties);
            }
          } else if (
            feature.getGeometry().getType() === 'Point' &&
            properties.Area !== undefined
          ) {
            hoverFeatureAction(properties);
          }

          const layerName = properties.name ?? properties.layerName;
          const componentName =
            properties.componentIndexing ?? properties.componentName;

          if (feature.getGeometry().getType() === 'Point') {
            updatePopoverContent(
              componentName,
              layerName,
              properties.Area || properties.area,
              'sq ft',
              AreaIconHover
            );
            if (showPopover) popover.style.display = 'block';
            hoverStyle(properties, feature);
            return true;
          } else if (properties.Area) {
            featureStyleOnHover = feature;
            updatePopoverContent(
              componentName,
              layerName,
              properties.Area.toFixed(2),
              'sq ft',
              AreaIconHover
            );
            if (showPopover) popover.style.display = 'block';
            feature.setStyle(hoveredFeatureStyle);
            return true;
          } else if (properties['Surface Area']) {
            featureStyleOnHover = feature;
            updatePopoverContent(
              componentName,
              layerName,
              (+properties['Surface Area']).toFixed(2),
              properties.unit,
              AreaIconHover
            );
            if (showPopover) popover.style.display = 'block';
            feature.setStyle(hoveredFeatureStyle);
            return true;
          } else if (properties['Ring Area'] !== undefined) {
            featureStyleOnHover = feature;
            updatePopoverContent(
              componentName,
              layerName,
              (+properties['Ring Area']).toFixed(2),
              properties.unit,
              AreaIconHover
            );
            if (showPopover) popover.style.display = 'block';
            hoverStyle(properties, feature);
            return true;
          } else if (properties.Length) {
            featureStyleOnHover = feature;
            updatePopoverContent(
              componentName,
              layerName,
              (+properties.Length).toFixed(2),
              properties.unit,
              PerimeterIconHover
            );
            if (showPopover) popover.style.display = 'block';
            hoverStyle(properties, feature);
            return true;
          } else {
            popover.style.display = 'none';
            return true;
          }
        } catch (e) {
          console.error('error', e);
        }
      });
    }

    // Hide the popover if the 'force-hide' class is present and a feature is selected
    if (
      document.getElementById('map-popover').classList.contains('force-hide') &&
      selected
    ) {
      const selectedFeatureStyle = selected.getStyle();
      if (
        (!Array.isArray(selectedFeatureStyle) &&
          selectedFeatureStyle &&
          selectedFeatureStyle?.getFill()?.getColor() ===
            hoveredFeatureStyle.getFill().getColor()) ||
        (Array.isArray(selectedFeatureStyle) &&
          selectedFeatureStyle.some(
            (style) =>
              style?.getFill()?.getColor() ===
              hoveredFeatureStyle.getFill().getColor()
          ))
      ) {
        selected.setStyle(false);
        popover.style.display = 'none';
      }
    }
  };

  // Update the popover content with the provided information
  const updatePopoverContent = (
    componentName,
    layerName,
    value,
    unit,
    iconSrc
  ) => {
    document.getElementById('featureId').innerHTML = componentName;
    document.getElementById('featureIcon').src = iconSrc;
    document.getElementById('unit').innerHTML = unit;
    document.getElementById('name').innerHTML = layerName;
    document.getElementById('hoverArea').innerHTML = value || '--';
  };

  hoverListener = olMapRef.on('pointermove', handlePointerMove);
  return hoverListener;
};

export const toggleHoverOverlayPopoverInteraction = (option) => {
  hoverOverlayPopoverInteraction = !!option;
};

/**
 * Click a ol/Feature and a callback action is called
 * @param olMapRef
 * @param clickFeatureAction
 */
export const onClickLayer = (olMapRef, clickFeatureAction) => {
  const handleClick = (event) => {
    if (!hoverOverlayPopoverInteraction) {
      return;
    }

    let clickedFeature = null;
    olMapRef.forEachFeatureAtPixel(event.pixel, (feature) => {
      if (clickedFeature) {
        return;
      }

      clickedFeature = feature;
      try {
        clicked.push(feature);
        feature.set('clicked', true);
        const properties = feature.getProperties();

        if (
          properties.Area ||
          properties['Surface Area'] ||
          properties['Ring Area'] ||
          properties.Length
        ) {
          clickFeatureAction(properties, event, feature);
        } else {
          console.error('Something is wrong here ERR-45792', { properties });
        }
      } catch (e) {
        console.error('error', e);
      }
    });

    if (!clickedFeature) {
      clickFeatureAction({}, { ...event }, null);
    }
  };

  clickListener = olMapRef.on('click', handleClick);
  return clickListener;
};

/**
 *
 * @param layerSource
 * @param id
 * @param highlight
 * @param layerList
 * @param featureList
 */
export const clickLayerHighlight = (
  layerSource,
  id,
  highlight = true,
  layerList,
  featureList
) => {
  const source = layerSource.getSource();
  const feature =
    source.getFeatureById(id) ||
    source
      .getFeatures()
      .find(
        (f) =>
          f.getProperties().id === id || f.getProperties().componentId === id
      );

  if (!feature) return;

  const layer = layerList.find(
    (_layer) => Number(_layer.layerId) === Number(layerSource.values_.id)
  );
  const { style: layerStyle, type: layerFeatureType } = layer ?? {};

  const featureType = layerFeatureType ?? feature.getProperties().featureType;

  if (!featureType) return;

  switch (featureType) {
    case 'point':
      feature.setStyle(
        highlight
          ? layerStyle
            ? pointHoverIconFeature(layerStyle)
            : pointSelectedTempStyle()
          : null
      );

      break;

    case 'path':
      const styleToBeApplied = {
        ...layerStyle,
        color: hoveredFeatureStyle.getStroke().getColor(),
        fillColor: hoveredFeatureStyle.getFill().getColor(),
        arrowColor: hoveredFeatureStyle.getStroke().getColor(),
      };
      updatePathStyleFunction([feature], styleToBeApplied);
      break;

    default:
      feature.setStyle(highlight ? selectedFeatureStyle() : null);
      break;
  }

  feature.set('clicked', highlight);
  // layerSource.setZIndex(11);

  // Ensure `clicked` array is properly managed if needed
  if (highlight) {
    clicked.push(feature);
  } else {
    const index = clicked.indexOf(feature);
    if (index > -1) {
      clicked.splice(index, 1);
    }
  }
};

export const calculateAreaWithLayerId = (layerData) => {
  if (!layerData || layerData.length === 0) {
    return 0;
  }

  return layerData.reduce((finalArea, feature) => {
    const { geometry, properties } = feature;

    switch (geometry.type) {
      case 'LineString':
        return finalArea + getComponentArea('Line', properties);
      case 'Point':
        return finalArea + getComponentArea('Point', properties);
      case 'Polygon':
        return finalArea + getComponentArea('Polygon', properties);
      default:
        return finalArea;
    }
  }, 0);
};

export const displayComponentName = (pos) => {
  return `ID${pos}`;
};

export const removeSelectInteraction = (mapRef) => {
  if (selectInteraction) {
    const interactions = mapRef.getInteractions();
    interactions.forEach((interaction) => {
      if (
        interaction === selectInteraction ||
        interaction === translateInteraction
      ) {
        mapRef.removeInteraction(interaction);
      }
    });
  }
};

export const removeAllInteraction = (mapRef) => {
  const interactionRemovalFunctions = [
    removeMeasureInteraction,
    removeCutInteraction,
    removeDrawInteraction,
    removeModifyInteraction,
    removeSnipInteraction,
    removeSelectInteraction,
  ];

  interactionRemovalFunctions.forEach((removeInteraction) => {
    removeInteraction(mapRef);
  });
};

export const removeHoverListener = (mapRef, listener) => {
  if (hoverListener) {
    unByKey(hoverListener);
  }

  if (listener) {
    unByKey(listener);
  }

  hoverListener = null;
};

export const removeClickListener = () => {
  if (clickListener) {
    unByKey(clickListener);
  }
  clickListener = null;
};
