import { GetWorkspaceSummaryApiResponse } from './schema';
import { WorkspaceSummary } from '@common/models/workspace';

const MAN_HOURS_SAVED_MULTIPLIER = 35;

export class WorkspaceApiTransformer {
  /** Transforms {@link GetWorkspaceSummaryApiResponse} to {@link WorkspaceSummary} */
  public static readonly toWorkspaceSummary = (
    data: GetWorkspaceSummaryApiResponse
  ): WorkspaceSummary => {
    return {
      ...data,
      manHoursSaved: data.acresMapped * MAN_HOURS_SAVED_MULTIPLIER,
    };
  };
}
