import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RouterPlusContext, { Params } from '@/modules/router-plus/context/index';

interface RouterPlusProviderProps {
  children: ReactNode;
}

const RouterPlusProvider = ({ children }: RouterPlusProviderProps) => {
  const { search } = useLocation();

  const [params, setParams] = useState<Params>({});
  const [searchParams, setSearchParams] = useState<URLSearchParams>(
    new URLSearchParams()
  );

  const routeSearchParams = useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const filterAndSetSearchParams = useCallback(
    (newSearchParams: URLSearchParams) => {
      const routerSearchParamKeys: string[] = [];

      // @ts-ignore
      for (const [key, value] of routeSearchParams.entries()) {
        routerSearchParamKeys.push(key);
      }

      routerSearchParamKeys.forEach((key) => {
        newSearchParams.delete(key);
      });

      setSearchParams(newSearchParams);
    },
    [routeSearchParams]
  );

  return (
    <RouterPlusContext.Provider
      value={{
        params,
        setParams,
        searchParams,
        setSearchParams: filterAndSetSearchParams,
      }}
    >
      {children}
    </RouterPlusContext.Provider>
  );
};

export default RouterPlusProvider;
