import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Tooltip } from 'antd';
import RoundedBox from '../../../containers/rounded-box/index.jsx';
import Text from '../../../elements/text/Text.jsx';
import { Styler } from '../../attribute-popup/layer-styling/styler';
import { setCreationFormValue } from '../../../../store/attributeFeature/actions.js';
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';
import { StyleCircle } from '../style-circle';
import { ReactComponent as DeleteLayer } from '../../../../assets/layer/DeleteLayer.svg';
import question from '@/assets/Question-mark.svg';
import ActionModal from '@elements/ActionModal';
import useAuth from '@/modules/auth/hooks';
import { useRole } from '@/modules/auth/guards/RoleGuard';
import './style.less';

export const AddLayer = ({
  onSaveAndApply,
  ...props
}) => {
  const [showStyle, setShowStyle] = useState(false);
  const [style, setStyle] = useState({});
  const [isNameEditing, setIsNameEditing] = useState(false);
  const [isDescEditing, setIsDescEditing] = useState(false);
  const [featureId, setFeatureId] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const containerRef = useRef();
  const dispatch = useDispatch();
  const formValues = useSelector((state) => state.feature.featureFormValues);
  const { isAdmin } = useRole();
  const { userId } = useAuth();
  const stylerRef = useRef(null);
  
  useEffect(() => {
    const listener = (evt) => {
      if (isNameEditing && evt.target.id !== 'edit-input')
        setIsNameEditing(false);
      else if (isDescEditing && evt.target.id !== 'edit-input')
        setIsDescEditing(false);
      else if (
        showStyle &&
        !(stylerRef.current && stylerRef.current.contains(evt.target)) &&
        evt.target.localName !== 'input'
      ) {
        setShowStyle(false);
      }
    };

    containerRef.current?.addEventListener('click', listener);

    return () => {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener('click', listener);
    };
  }, [containerRef, isNameEditing, isDescEditing, showStyle, formValues]);

  useEffect(() => {
    if (formValues?.featureId !== featureId) {
      setFeatureId(formValues?.featureId);
    }
  }, [formValues, featureId]);

  useEffect(() => {
    setStyle({ ...props.customStyle, ...formValues?.style });
  }, [formValues]);

  const handleStyler = (value, name) => {
    const styleO = {
      ...props.customStyle,
      ...formValues.style,
      [name]: name === 'opacity' ? value / 10 : value,
    };
    setStyle(styleO);
    dispatch(
      setCreationFormValue({
        ...formValues,
        style: { ...styleO },
      })
    );
  };

  const newValueHandle = (e) => {
    dispatch(
      setCreationFormValue({
        ...formValues,
        [e.target.name]: e.target.value,
      })
    );
  };

  const deleteHandle = () => {
    props.onDeleteFeature(props.summary?.featureId);
    setVisibleModal(false);
  };

  return (
    <div ref={containerRef} className='summary-container flex f-column'>
      <Text type='h4' color='dark-gray'>
        {props?.heading}
      </Text>

      <div className='separation-line' />
      <div className='flex justify-between heading-wrapper'>
        {isNameEditing && props.summary?.maintainer !== 'siterecon' ? (
          <Input
            id='edit-input'
            className='h-30 feature-title'
            style={{
              maxWidth: 200,
            }}
            name='name'
            onChange={newValueHandle}
            defaultValue={props.summary?.name}
          />
        ) : (
          <Text
            className={'feature-title'}
            onClick={() => setIsNameEditing(true)}
            type='p47'
            color='dark-gray'
          >
            {props.summary?.name}
          </Text>
        )}
        <div className='flex align-center gap-3 p-relative'>
          <div
            className='flex align-center gap-5 pointer'
            onClick={() => setShowStyle((p) => !p)}
          >
            <StyleCircle
              fillColor={
                style?.fillColor
                  ? style?.fillColor
                  : props.customStyle.fillColor
              }
              strokeColor={
                style?.color ? style?.color : props.customStyle.color
              }
              name={props?.summary?.name}
              layerType={props?.summary?.type}
              stroke={style?.stroke ? style?.stroke : props.customStyle.stroke}
              symbolColor={
                style?.symbolColor
                  ? style?.symbolColor
                  : props.customStyle.symbolColor
              }
              icon={
                style?.symbolType
                  ? style?.symbolType
                  : props.customStyle.arrowType
              }
              key={props?.summary?.name}
            />
            {props.summary?.maintainer === 'siterecon' ? null : (
              <Text className='pointer'>Edit Style</Text>
            )}
          </div>
          <RoundedBox color='orange'>{props.summary?.category}</RoundedBox>
          {props.type !== 'new' && (
            <Tooltip title='Delete Layer'>
              <Button
                type={'link'}
                style={{ width: '20px' }}
                disabled={
                  (!isAdmin || props.summary?.maintainer === 'siterecon') &&
                  userId !== props.summary?.userId
                }
                // disabled={props.summary?.maintainer === 'siterecon'}
                icon={<DeleteLayer cursor='pointer' />}
                onClick={() => setVisibleModal(true)}
              />
            </Tooltip>
          )}
          {showStyle && (
            <div
              id='styler'
              ref={stylerRef}
              className='styler-tool-wrapper p-4 p-absolute top-28 right-20'
            >
              <CloseIcon
                className='pointer p-absolute top-7 right-20'
                width={10}
                onClick={() => setShowStyle(false)}
              />
              <Styler
                handleChange={handleStyler}
                value={style ? style : props.customStyle}
                shape={props.summary?.type}
                isCustomFeature={true}
              />
            </div>
          )}
        </div>
      </div>
      <div className='content-wrapper flex f-column'>
        <Text type='p55' color='dark-gray' className='text-capatalize'>
          {props.summary?.type}
        </Text>
        <div className='description'>
          {isDescEditing && props.summary?.maintainer !== 'siterecon' ? (
            <Input.TextArea
              id='edit-input'
              className='edit-desc'
              name='longDesc'
              autoSize
              onChange={newValueHandle}
              defaultValue={props.summary?.longDesc}
            />
          ) : (
            <Text
              onClick={() => setIsDescEditing(true)}
              type='p7'
              color='gray-shade'
              className='desc-text'
            >
              {props.summary?.longDesc}
            </Text>
          )}
        </div>
      </div>

      <div className='but-wrapper'>{props.children}</div>
      <ActionModal
        isVisible={visibleModal}
        setModalVisible={setVisibleModal}
        title={'Delete Layer?'}
        icon={question}
        content={
          <p>
            Are you sure you want to delete custom layer{' '}
            <strong>"{props.summary?.name}"</strong> from your Layers Library?
            Layers already added in properties will not be effected.
          </p>
        }
        onOK={deleteHandle}
      />
    </div>
  );
};
