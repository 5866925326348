import { AccessRight, GuardProps } from '@/modules/common/types';
import { useMemo } from 'react';
import useAccess from '@/modules/project/guards/AccessGuard/useAccess';
import BaseGuard from '@/modules/common/guards/BaseGuard';
import { WrapperProps } from '@/modules/common/components/Wrapper';

export interface AccessGuardProps extends GuardProps, Omit<WrapperProps, 'tooltip'> {
  /** Single or List of {@link AccessRight} for which we want to display the content */
  right?: AccessRight | AccessRight[];

  /**  Single or List of {@link AccessRight} for which we *don't* want to display the content */
  except?: AccessRight | AccessRight[];

  tooltip?: WrapperProps['tooltip'] | boolean;
}

const AccessGuard = ({
  right: allowedRights,
  except: excludedRights,
  fallback,
  children,
  tooltip,
  or,
  ...rest
}: AccessGuardProps) => {
  const right = useAccess();

  const tooltipProps = useMemo(() => {
    if (typeof tooltip === 'boolean') {
      return tooltip ? "You don't have permission" : undefined;
    }
    return tooltip;
  }, [tooltip]);

  const accessCondition = useMemo(() => {
    if (!right) return false;

    if (excludedRights) {
      if (typeof excludedRights === 'string') {
        return right !== excludedRights;
      }
      return !excludedRights.includes(right);
    }

    if (allowedRights) {
      return typeof allowedRights === 'string'
        ? right === allowedRights
        : allowedRights.includes(right);
    }

    return false;
  }, [right, allowedRights, excludedRights]);

  const canRender = useMemo(() => {
    if (typeof or !== 'undefined') {
      return accessCondition || or;
    }

    return accessCondition;
  }, [accessCondition, or]);

  return (
    <BaseGuard
      condition={canRender}
      fallback={fallback}
      tooltip={tooltipProps}
      {...rest}
    >
      {children}
    </BaseGuard>
  );
};

AccessGuard.displayName = 'AccessGuard';

export { useAccess };

export default AccessGuard;
