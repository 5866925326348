// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon35IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon35Icon(props: Icon35IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 20 20"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M10 12.98a.748.748 0 01-.533-.234l-4.46-4.564a.66.66 0 01-.152-.211.734.734 0 01-.046-.264.72.72 0 01.087-.352.676.676 0 01.247-.246.646.646 0 01.345-.093c.188 0 .352.07.492.21l4.307 4.413H9.72l4.3-4.412a.675.675 0 01.493-.211c.129 0 .244.03.345.093a.63.63 0 01.24.246.677.677 0 01.094.352.648.648 0 01-.199.475l-4.459 4.564a.713.713 0 01-.533.234z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon35Icon;
/* prettier-ignore-end */
