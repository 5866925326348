import { Button, Col, Divider, Form, Modal, Row, Select, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CopyTextComponent from '@/components/pages/myProperties/components/properties/components/modals/shareProperty/CopyTextComponent';
import Text from '@elements/text/Text';

import { ReactComponent as Information } from '@tm/assets/Information.svg';
import { ReactComponent as CopyCodeIcon } from '@tm/assets/copy-code.svg';

import useEmailValidation from '@/hooks/useEmailValidation';
import { getOrganisationThunk } from '@/store/organisation/thunks';
import { membersInviteThunk } from '@/store/teamManagement/thunk';
import { setOrganisation } from '@/store/user/thunks';
import { IUserData } from '@tm/common/types';
import { MembersInviteReqBody } from '../../common/types';
import './style.less';
import RoleGuard, { useRole } from '@/modules/auth/guards/RoleGuard';
import { SiteReconError, UserRole } from '@/modules/common/types';
import useAuth from '@/modules/auth/hooks';
import useAlert from '@common/components/Alert/useAlert';
import { trackEvents } from '@/helpers/utilities';
import { roleById } from '@/modules/auth/helpers';
import UpgradeConfirmation from '../../../../../modules/common/components/UpgradeConfirmationModal/index';
import { useSubscription } from '@/modules/subscription/hooks';
import { SubscriptionPlan } from '@/components/subscription/helpers/enum';
import React from 'react';

const { Option } = Select;
interface InviteMembersModalProps {
  isModalVisible: boolean;
  setModalVisible: (visibility: boolean) => void;
  title?: string;
}

const InviteMembersModal = ({
  isModalVisible,
  setModalVisible,
  title,
}: InviteMembersModalProps) => {
  const MAX_ALLOWED_INVITE = 50;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [invitingAsChanged, setInvitingAsChanged] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(0);
  const [emails, setEmails] = useState<Array<string>>([]);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [isError, setIsError] = useState(false);
  const [inviteAs, setInviteAs] = useState();
  const [userInOrgError, setUserInOrgError] = useState('');
  const [userDeactivatedError, setUserDeactivatedError] = useState('');

  const [formData, setFormData] = useState<MembersInviteReqBody>({
    emails: '',
    order_creation_role: 0,
    data_access_role: null,
  });

  const alert = useAlert();
  const { isAdmin } = useRole();
  const { organizationId } = useAuth();
  const { currentPlan, showRepairSubscription } = useSubscription();
  const organisationInvitationCode = useSelector(
    (state: any) => state?.organisation?.organisationData?.inv_code
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);

  const { isEmailError, invalidMails } = useEmailValidation(emails.reverse());

  const selectRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFormData({
      ...formData,
      data_access_role: inviteAs !== 'Guest' ? 1 : 2,
    });
  }, [inviteAs]);

  function emailChange(value: Array<string>) {
    setIsEmailChanged(value.length);

    if (value.length <= MAX_ALLOWED_INVITE) {
      userInOrgValidation(value);
      setEmails(value.reverse());
      setFormData({ ...formData, emails: value.toString() });
    }
  }

  const organisationData = useSelector(
    (state: any) => state.organisation.organisationData
  );
  const organisationUserData = useSelector(
    (state: any) => state.teamManagement.organisationUserData
  );

  useEffect(() => {
    if (organisationData && organisationData.na) {
      setOrganisation(organisationData.na);
    }
  }, [organisationData]);

  useEffect(() => {
    if (!organisationInvitationCode) {
      organizationId && dispatch(getOrganisationThunk(organizationId));
    }
  }, [organizationId]);

  const inviteAsMenuClickHandler = (key: any, val: any) => {
    setInviteAs(val.key);
    setInvitingAsChanged(true);
    setFormData({ ...formData, order_creation_role: key });
  };

  const accessShareChange = (key: any) => {
    setFormData({ ...formData, data_access_role: key });
  };

  const onSubmit = async () => {
    setSendingInvite(true);
    const trackingData = {
      members: formData.emails,
      role: formData.order_creation_role
        ? roleById(formData.order_creation_role)
        : null,
      dataAccessRole: formData.data_access_role,
    };

    dispatch(membersInviteThunk({ orgId: organizationId, formData }))
      // @ts-ignore
      .then((res, rej) => {
        form.resetFields();
        setSendingInvite(false);
        trackEvents(
          'team-management__invite-members_invitation-sent',
          trackingData
        );

        alert.success('Invite sent successfully', 2000);
        setModalVisible(false);
      })
      .catch((error) => {
        if (error?.errorCode === SiteReconError.PaymentRequired) {
          /** We need to show repair subscription flow */
          if (error.invoiceId && error.redirectionLink) {
            
            showRepairSubscription({
              id: error.invoiceId,
              url: error.redirectionLink,
            });
          }
        }

        form.resetFields();
        trackEvents(
          'team-management__invite-members_invitation-failed',
          trackingData
        );
        setSendingInvite(false);
      });
  };

  const userInOrgValidation = (value: Array<string>) => {
    const invalidInputsArray = value.map((val) =>
      organisationUserData.filter(
        (user: IUserData) => user.em === val && !user.is_user_deactivated
      )
    );

    const userDeactivatedArray = value.map((val) =>
      organisationUserData.filter(
        (user: IUserData) => user.em === val && user.is_user_deactivated
      )
    );
    const invalidInputs = invalidInputsArray
      .flat()
      .map((user: IUserData) => user.em);

    const userDeactivated = userDeactivatedArray
      .flat()
      .map((user: IUserData) => user.em);
    if (invalidInputs.length === 0 && userDeactivated.length === 0) {
      setUserInOrgError('');
      setUserDeactivatedError('');
      setIsError(false);
    } else if (invalidInputs.length === 1) {
      setUserInOrgError(invalidInputs.join('') + ' is already a member.');
      setIsError(true);
    } else if (userDeactivated.length === 1) {
      setUserDeactivatedError(
        userDeactivated.join('') +
          ' already exists in the workspace and can be reactivated from the members list.'
      );
      setIsError(true);
    } else if (invalidInputs.length > 1 || userDeactivated.length > 1) {
      if (invalidInputs.length > 1) {
        setUserInOrgError(
          invalidInputs.slice(0, -1).join(', ') +
            ' and ' +
            invalidInputs.slice(-1) +
            ' are already members.'
        );
      }
      if (userDeactivated.length > 1) {
        setUserDeactivatedError(
          userDeactivated.slice(0, -1).join(', ') +
            ' and ' +
            userDeactivated.slice(-1) +
            ' already exists in the workspace and can be reactivated from the members list.'
        );
      }
      setIsError(true);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      const invalidInputs = emails.filter(
        (value: any) =>
          !value.match(
            /^([a-zA-Z0-9_\.-]+)@([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})$/
          )
      );
      const userDeactivatedMailArray = emails.map((val) =>
        organisationUserData
          .filter(
            (user: IUserData) => user.em === val && user.is_user_deactivated
          )
          .map((user: IUserData) => user.em)
      );
      const alreadyInvitedMailArray = emails.map((val) =>
        organisationUserData
          .filter(
            (user: IUserData) => user.em === val && !user.is_user_deactivated
          )
          .map((user: IUserData) => user.em)
      );

      const errorArray = [
        ...invalidInputs,
        ...userDeactivatedMailArray,
        ...alreadyInvitedMailArray,
      ].flat();

      if (errorArray) {
        errorArray?.forEach((input) => {
          const errorItem = document.querySelector(
            `.ant-select-item[title="${input}"]`
          );
          if (errorItem) {
            (errorItem as HTMLElement).style.color = '#ff5151';
          }
        });
      }
    }
  }, [showDropdown]);

  const dropdownRender = (menu: any) => {
    return <>{menu}</>;
  };

  return (
    <div>
      <Modal
        title={title}
        visible={isModalVisible}
        footer={null}
        onOk={() => setModalVisible(false)}
        closable={true}
        width='642px'
        style={{ borderRadius: '4px' }}
        wrapClassName='invite-member-modal'
        onCancel={() => {
          setModalVisible(false);
          trackEvents('team-management__invite-members_closed');
        }}
      >
        <Form
          form={form}
          onFinish={() => {
            inviteAs === 'Creator' && ![SubscriptionPlan.ESSENTIAL, SubscriptionPlan.TRIAL, SubscriptionPlan.ENTERPRISE].includes(currentPlan)
              ? setConfirmationVisible(true)
              : onSubmit();
          }}
        >
          <Row>
            <Text type='h3' color='dark-gray'>
              Invite members
            </Text>
            <Divider />
            <Text type='p19_2' color='gray-shade'>
              Type or paste emails below. Multiple emails can be seperated by
              commas.
            </Text>
            <Row className='mt-4 mb-3'>
              <Col className='mr-2' span={isAdmin ? 16 : 24}>
                <Row className='mb-1'>
                  <Text type='p19' color='dark-gray'>
                    Enter Email(s)
                  </Text>
                </Row>
                <Row>
                  <Form.Item className='email-form-item' name='Email'>
                    <Select
                      mode='tags'
                      maxTagCount={isAdmin ? 1 : 2}
                      placeholder='Enter email, comma separated'
                      onChange={emailChange}
                      className={`invite-email ${
                        invalidMails || userInOrgError ? 'incorrect-email' : ''
                      }`}
                      open={showDropdown}
                      dropdownStyle={
                        emails.length === 0 ? { display: 'none' } : {}
                      }
                      dropdownClassName='invite-email-select'
                      ref={selectRef}
                      style={isAdmin ? { width: '387px' } : { width: '595px' }}
                      onBlur={() => setShowDropdown(false)}
                      dropdownRender={(menu) => dropdownRender(menu)}
                      suffixIcon={<Information />}
                      tokenSeparators={[',', ' ', '\n', '\t', '\r']}
                      value={emails}
                      onInputKeyDown={(e: any) => {
                        setShowDropdown(false);

                        if (e.key === 'Enter') {
                          const newTag = e.target.value;
                          setShowDropdown(false);
                          if (!newTag) {
                            e.preventDefault();
                            e.stopPropagation();
                          } else if (emails.includes(newTag)) {
                            e.preventDefault();
                            e.stopPropagation();
                            e.target.value = '';
                          }
                        }
                      }}
                    ></Select>
                    <div
                      className={`${
                        isAdmin
                          ? emails.length > 1
                            ? `em-enabled ${
                                (invalidMails ||
                                  userInOrgError ||
                                  userDeactivatedError) &&
                                'email-error'
                              }`
                            : 'em-disabled'
                          : emails.length > 2
                          ? `em-enabled ${
                              (invalidMails ||
                                userInOrgError ||
                                userDeactivatedError) &&
                              'email-error'
                            }`
                          : 'em-disabled'
                      } email-count`}
                      onClick={() => {
                        setShowDropdown(!showDropdown);
                        selectRef!.current!.focus();
                      }}
                    >
                      +{isAdmin ? emails.length - 1 : emails.length - 2}
                    </div>
                  </Form.Item>
                </Row>
              </Col>
              <Col span={isAdmin ? 7 : 24}>
                <Row
                  className='mb-1 pl-3'
                  style={isAdmin ? {} : { marginTop: '50px' }}
                >
                  <Text type='p19' color='dark-gray'>
                    Invite as
                  </Text>
                </Row>
                <Row>
                  <Form.Item
                    rules={[
                      {
                        type: 'number',
                      },
                    ]}
                  >
                    <Select
                      placeholder='Select'
                      onChange={(key, val) =>
                        inviteAsMenuClickHandler(key, val)
                      }
                      value={inviteAs}
                      className='invite-dropdown-height invite-dropdown '
                      dropdownStyle={{ borderRadius: '4px' }}
                      dropdownClassName='invite-as-dd'
                      style={
                        isAdmin
                          ? { width: '177px', marginLeft: '9px' }
                          : { width: '400px', marginLeft: '0px' }
                      }
                    >
                      {isAdmin && (
                        <>
                          <Option value={2} key='Creator' className='no-border'>
                            <Text
                              style={{
                                lineHeight: '10px',
                                display: 'block',
                                marginBottom: 8,
                              }}
                            >
                              Creator
                            </Text>
                            <Text type='p82' color='dark-gray'>
                              Can Order new properties using the allocated
                              credits.
                            </Text>
                          </Option>
                          <Option value={1} key='Admin' className='no-border'>
                            <Text
                              style={{
                                lineHeight: '10px',
                                display: 'block',
                                marginBottom: 8,
                              }}
                            >
                              Admin
                            </Text>
                            <Text type='p82' color='dark-gray'>
                              Can change Member roles and manage their Credits
                            </Text>
                          </Option>
                        </>
                      )}
                      <Option value={4} key='Viewer' className='no-border'>
                        <Text
                          style={{
                            lineHeight: '10px',
                            display: 'block',
                            marginBottom: 8,
                          }}
                        >
                          Viewer
                        </Text>
                        <Text type='p82' color='dark-gray'>
                          Can only view the properties created by others and
                          Invite as viewers.
                        </Text>
                      </Option>
                    </Select>
                  </Form.Item>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                position: 'relative',
                marginTop: isAdmin ? '-36px' : '-43px',
                top: isAdmin ? '0px' : '-110px',
                paddingBottom: '10px',
                width: '400px',
              }}
            >
              <Text
                type='p23'
                color='error-red3'
                className={isAdmin ? 'admin-error-div' : 'viewer-error-div '}
              >
                {emails.length !== 0 &&
                  `${invalidMails} ${userInOrgError} ${userDeactivatedError}`}
              </Text>
              <Text
                type='p23'
                color='error-red3'
                style={{ position: 'absolute', top: '42px', left: '-670px' }}
              >
                {emails.length === 0 &&
                  `${userInOrgError} ${userDeactivatedError}`}
              </Text>
            </Row>
            <Row className='mb-3'>
              <RoleGuard roles={UserRole.Admin}>
                <Col className='mr-4'>
                  <Row className='mb-1'>
                    <Text type='p19' color='dark-gray'>
                      Data Access
                    </Text>
                  </Row>
                  <Row>
                    <Form.Item
                      rules={[
                        {
                          type: 'number',
                        },
                      ]}
                    >
                      <Select
                        placeholder={
                          inviteAs !== 'Guest'
                            ? 'Can access all the properties '
                            : 'Can only access invited properties'
                        }
                        onChange={accessShareChange}
                        className='invite-dropdown-height data-access-dropdown '
                        dropdownStyle={{ borderRadius: '4px' }}
                        value={
                          inviteAs === 'Guest'
                            ? 2
                            : (formData.data_access_role as number)
                        }
                        dropdownClassName='invite-moodal-property-dd'
                        style={{ width: '387px' }}
                      >
                        {inviteAs !== 'Guest' && (
                          <Option value={1} className='no-border'>
                            Can access all the properties
                          </Option>
                        )}
                        <Option value={2} className='no-border'>
                          Can only access invited properties
                        </Option>
                      </Select>
                    </Form.Item>
                  </Row>
                </Col>
              </RoleGuard>

              <Col>
                <Button
                  disabled={
                    isEmailChanged === 0 ||
                    !invitingAsChanged ||
                    isError ||
                    isEmailError
                  }
                  loading={sendingInvite}
                  className='mt-6 send-invite'
                  type='primary'
                  htmlType='submit'
                  style={
                    isAdmin
                      ? {}
                      : { position: 'absolute', top: '-105px', right: '-195px' }
                  }
                >
                  Send Invite
                </Button>
              </Col>
            </Row>
            <Divider>or</Divider>

            <Col className='mt-2 w-100'>
              <Row>
                <Text type='h8' color='gray-shade'>
                  Invite via organisation’s code&nbsp;
                </Text>{' '}
                <Tooltip
                  trigger='click'
                  title='Share the given code with anyone and ask them to sign up using this. They will be a part of your workspace after successful sign up.'
                >
                  <Information />
                </Tooltip>
              </Row>
              <div className='mt-3 copy-code'>
                <CopyTextComponent
                  text={organisationInvitationCode || ''}
                  buttonText='Copy Code'
                  icon={
                    <CopyCodeIcon
                      style={{ marginRight: '3px', marginTop: '3px' }}
                    />
                  }
                  onClick={() =>
                    trackEvents(
                      'team-management__invite-members_organization-code-copied',
                      {
                        code: organisationInvitationCode,
                      }
                    )
                  }
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      <UpgradeConfirmation
        modalVisibility={isConfirmationVisible}
        title={
          isEmailChanged == 1
            ? 'Confirm to invite as a creator?'
            : 'Confirm to invite as a creators?'
        }
        content='Subscription amount will be charged for creators every month as per your current plan.'
        onModalClose={(confirmation) => {
          setConfirmationVisible(false);
          if (confirmation) {
            onSubmit();
          }
        }}
      />
    </div>
  );
};

export default InviteMembersModal;
