import { SUBSCRIPTION_TYPES } from '@/store/subscription/action.types';
import { SubscriptionModalData } from '@/modules/subscription/types';
import { SubscriptionDataApiResponse } from '@/modules/subscription/api';
import { GetSubscriptionApiResponse } from '@/components/subscription/helpers/api-schema';
import {
  PayAsYouGoModalData,
  RepairSubscriptionData,
  SubscriptionWidgetData,
} from '@/components/subscription/helpers/types';

export const setSubscriptionModal = (data: SubscriptionModalData) => ({
  type: SUBSCRIPTION_TYPES.SET_SUBSCRIPTION_MODAL,
  payload: data,
});

export const setPayAsYouGoModal = (data: PayAsYouGoModalData) => ({
  type: SUBSCRIPTION_TYPES.SET_PAY_AS_YOU_GO_MODAL,
  payload: data,
});

export const setSubscriptionWidget = (data: SubscriptionWidgetData) => ({
  type: SUBSCRIPTION_TYPES.SET_SUBSCRIPTION_WIDGET_DATA,
  payload: data,
});

export const setRepairSubscriptionModal = (data: RepairSubscriptionData) => ({
  type: SUBSCRIPTION_TYPES.SET_REPAIR_SUBSCRIPTION_MODAL_DATA,
  payload: data,
});

export const setSubscriptionData = (data: SubscriptionDataApiResponse) => ({
  type: SUBSCRIPTION_TYPES.SET_DATA,
  payload: data,
});

export const hideReminder = () => ({
  type: SUBSCRIPTION_TYPES.HIDE_REMINDER,
});

export const setSubscription = (data: GetSubscriptionApiResponse) => ({
  type: SUBSCRIPTION_TYPES.SET_SUBSCRIPTION,
  payload: data,
});
