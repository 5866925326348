import React, { FC } from 'react';
import { WidgetBarTabs } from '../../../components/types';
import {
  useWidgetBarTabContext,
} from '../hooks/useWidgetBar';

export const withWidgetBarContext = <P extends object>(
  Component: React.ComponentType<P>
): FC<P> => {
  const WrappedComponent: FC<P> = (props) => {
    return <ContextComponent Component={Component} {...(props as P)} />;
  };

  return WrappedComponent;
};

const ContextComponent: FC<{
  Component: React.ComponentType<any>;
  [key: string]: any;
}> = ({ Component, ...props }) => {
  const {
    activeTabs,
    disabledTabs,
    handleWidgetBarTabChange,
    handleCloseAllWidgetBarTabs,
    handleCloseWidgetBarTab,
    selectedTab,
  } = useWidgetBarTabContext();

  return (
    <Component
      {...props}
      activeWidgetBarTabs={activeTabs}
      selectedWidgetBarTab={selectedTab}
      handleWidgetBarTabChange={handleWidgetBarTabChange}
      disabledTabs={disabledTabs}
      handleCloseAllWidgetBarTabs={handleCloseAllWidgetBarTabs}
      handleCloseWidgetBarTab={handleCloseWidgetBarTab}
    />
  );
};
