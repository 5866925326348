import { ACTIONS } from './action_types';
import INITIAL_STATE from './state';

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_CONFIGURATIONS:
      return {
        ...state,
        ...payload,
      };
    case ACTIONS.SET_DEFAULT:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
