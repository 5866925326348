export interface ServiceItemColorData {
    id: number;
    name: string;
    color: string;
}

export interface ServiceItemColorChip {
    componentId: number;
    serviceItems: ServiceItemColorData[];
}

export enum ServiceItemColumnDataType {
    REGULAR = 'regular',
    LEGACY = 'legacy',
    ARCHIVED = 'archived',
}

export interface ServiceItemColumnData {
    id: number;
    name: string;
    index: number;
    serviceIndex: number;
    type: ServiceItemColumnDataType;
}