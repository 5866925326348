// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Group427320441IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Group427320441Icon(props: Group427320441IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 11 12"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M9 5.5H2a1 1 0 00-1 1V10a1 1 0 001 1h7a1 1 0 001-1V6.5a1 1 0 00-1-1zm-6 0v-2a2.5 2.5 0 115 0v2"
        }
        stroke={"currentColor"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      ></path>
    </svg>
  );
}

export default Group427320441Icon;
/* prettier-ignore-end */
