import React from 'react';
import Lottie from 'react-lottie';

import fallbackAnimationData from '../../../assets/animations/fallback.json';

import './style.less';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: fallbackAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Fallback = (props) => {
  const { comingFrom, height, isFallbackMain, width, isMobileDevice } = props;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if ('try_opening_app' in params && isMobileDevice) {
    if (params['try_opening_app'] === 'true') {
      window.location.href = 'https://siterecon.page.link/web-to-app-redirect';
    }
  }

  return (
    <div className={`${isFallbackMain ? 'fallback-main' : ''}`}>
      {height && width ? (
        <Lottie options={defaultOptions} height={height} width={width} />
      ) : (
        <>
          {comingFrom !== undefined && comingFrom === 'profile' ? (
            <Lottie options={defaultOptions} height={100} width={100} />
          ) : (
            <Lottie options={defaultOptions} height={200} width={200} />
          )}
        </>
      )}
    </div>
  );
};

Fallback.defaultProps = {
  isFallbackMain: true,
};

export default Fallback;
