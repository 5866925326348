import { GuardProps, IObject } from '@/modules/common/types';
import React, { ComponentProps, FC, MouseEvent, ReactNode, useMemo, } from 'react';
import { PaywallModalProps } from '@/modules/subscription/components/PaywallModal';
import { WrapperProps } from '@/modules/common/components/Wrapper';
import BaseGuard from '@/modules/common/guards/BaseGuard';
import { useSubscription, useSubscriptionFeature, } from '@/modules/subscription/hooks';
import { SubscriptionFeature, SubscriptionFeatureData, } from '@/modules/subscription/types';
import { config } from '@/modules/common/config';

export type ConditionFunction = (
  featureValue: SubscriptionFeatureData | null
) => boolean;

export interface SubscriptionGuardProps extends GuardProps, WrapperProps {
  /** A feature|tool we want to guard. {@link SubscriptionFeature}  */
  feature: SubscriptionFeature;

  /** (Optional) A Fallback component which we want to show if feature is not enabled | condition is not satisfied, For most use cases this will be a disabled icon */
  fallback?: ReactNode;

  /** (Optional) A list pf props we want to apply to the child if condition is not satisfied */
  fallbackProps?: ComponentProps<'div'> & IObject;

  /** (Optional) If we want to configure default PayWall Modal */
  modal?: boolean | Omit<PaywallModalProps, 'visible'>;

  /** (Optional) Name of method for which you want to show the Pay-Wall modal.
   * Ex. For form submit you may pass "onSubmit", for drop-downs this can be "onChange"
   **/
  method?: string;

  /**
   * (Optional) A conditional function which you want to pass to guard child component.
   * Expects a callback which must return **boolean**
   **/
  condition: ConditionFunction;
}

/** TODO: Add support for multiple elements as a children */
const SubscriptionGuard: FC<SubscriptionGuardProps> = ({
  children,
  modal,
  feature,
  fallback,
  condition,
  fallbackProps,
  method = 'onClick',
  ...rest
}) => {
  const { showPaywall } = useSubscription();
  const { data: featureData } = useSubscriptionFeature(feature);

  const canRender = useMemo(() => {
    return condition(featureData);
  }, [condition, feature, featureData]);

  const handleClick = (event: MouseEvent) => {
    if (event?.preventDefault) event.preventDefault();
    if (event?.stopPropagation) event.stopPropagation();

    if (modal === false) return;

    if (typeof modal === 'undefined' || typeof modal === 'boolean') {
      showPaywall(feature);
      return;
    }

    showPaywall(feature, modal);
  };

  const updatedChildren = useMemo(() => {
    /** If Fallback element is provided, we assume that restricted logic is provided under fallback,   */
    if (fallback || !React.isValidElement(children)) return children;

    return canRender
      ? children
      : React.cloneElement(children as JSX.Element, {
          [method]: handleClick,
          ...fallbackProps,
        });

    /** TODO: Handle the condition for multiple elements as a children */
  }, [children, fallback, condition]);

  const updatedFallback = useMemo(() => {
    if (!fallback || !React.isValidElement(fallback)) return fallback;

    return React.cloneElement(fallback as JSX.Element, {
      [method]: handleClick,
    });
  }, [fallback]);

  /** If the Subscriptions are disabled, we always want to render the child component */
  if (!config.isSubscriptionsEnabled) {
    if (React.isValidElement(children)) {
      return children as JSX.Element;
    }
    return <>{children}</>;
  }

  return (
    <BaseGuard
      condition={canRender}
      fallback={updatedFallback || updatedChildren}
      {...rest}
    >
      {updatedChildren}
    </BaseGuard>
  );
};

SubscriptionGuard.displayName = 'SubscriptionGuard';

export default SubscriptionGuard;
