// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Group11IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Group11Icon(props: Group11IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 67 48"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M8.747 2.67L1.006 42.303 0 47.49l51.605-.248c2.318-.011 4.374-2.063 5.115-5.11l9.153-37.415C66.44 2.405 65.198-.002 63.44 0L11.196.055C10.059.052 9.04 1.125 8.746 2.67z"
        }
        fill={"currentColor"}
        opacity={".5"}
      ></path>
    </svg>
  );
}

export default Group11Icon;
/* prettier-ignore-end */
