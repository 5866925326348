import { _isEmpty } from '@/helpers/utilities/lodashUtils';
import {
    createOrganisationThunk,
    getOrganisationSuggestionsThunk,
    getOrganisationViaCodeThunk,
    joinOrganisationThunk,
    joinOrgThunk,
} from '@/store/organisation/thunks';
import { skipOrganisationCreation } from '@/store/user/actions';
import { CloseOutlined } from '@/components/elements/icons/antd-icons';
import Text from '@elements/text/Text';
import { Button, Col, Input, Modal, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceSwitchThunk } from '@/store/user/thunks';
import { API_STATUS } from '../../auth-page/types';
import BrandLogo from '../../brand-logo/BrandLogo';
import AvatarWrapper from './AvatarWrapper';
import OrLine from './OrLine';
import useAuth from '@/modules/auth/hooks';
import { capitalizeFirstLetter } from '../../../../helpers/utilities';
import { commonDomainsList } from '../../../constants';

const CreateNewWorkspace = ({
  source = 'SIGNUP',
  showModal = true,
  type = 0,
  onOk = undefined,
  handleClose = () => {},
}) => {
  const dispatch = useDispatch();

  const { user } = useAuth();
  const { email, domain, first_name } = user;

  const reqId = useSelector((state) => state.user.reqId);
  const orgData = useSelector((state) => state.teamManagement.organisationData);
  const {
    orgCreateStatus,
    createWSError,
    organisationData,
    orgDataFetchStatus,
    organisationDataViaCode,
    organisationDataViaCodeFailure,
  } = useSelector((state) => state.organisation);

  const [workspace, setWorkspace] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [inviteCode, setInviteCode] = useState('');

  //0 = Create workspace, 1 = Join existing, 2 = Join via invite
  const [userChoice, setUserChoice] = useState(0);

  const domainName = domain.split('@').pop().split('.')[0];
  const isCommonDomain = commonDomainsList.includes(domainName.toLowerCase());

  const defaultOrgName =
    source === 'SIGNUP' && !isCommonDomain
      ? `${capitalizeFirstLetter(domainName)} Workspace`
      : `${first_name}\'s Workspace`;

  useEffect(() => {
    if (showModal) {
      setUserChoice(type);
      setOrganisation(defaultOrgName);
      setErrorText('');
      if (type === 1) {
        dispatch(getOrganisationSuggestionsThunk());
      }
    } else {
      setLoading(false);
    }
  }, [showModal, type, defaultOrgName]);

  useEffect(() => {
    if (userChoice === 0) {
      setErrorText('');
    } else if (userChoice === 1) {
      setWorkspace(null);
    } else if (userChoice === 2) {
      setInviteCode('');
    }
  }, [userChoice]);

  useEffect(() => {
    if (organisationDataViaCode) {
      setLoading(false);
      if (organisationDataViaCode.all_users.includes(email)) {
        setErrorText('INVALID_CODE');
      } else {
        setUserChoice(4);
      }

      setWorkspace(organisationDataViaCode);
    }
  }, [organisationDataViaCode]);

  useEffect(() => {
    if (organisationDataViaCodeFailure) {
      setErrorText('INVALID_CODE');
      setLoading(false);
      setErrorText('INCORRECT_CODE');
    }
  }, [organisationDataViaCodeFailure]);

  useEffect(() => {
    if (orgCreateStatus === API_STATUS.SUCCESS) {
      setLoading(false);
      handleClose({ title: organisation, mode: 'CREATED' });
    } else if (orgCreateStatus === API_STATUS.FAILURE) {
      setErrorText(createWSError || 'Something went wrong');
    }
  }, [orgCreateStatus]);

  useEffect(() => {
    if (orgCreateStatus === API_STATUS.SUCCESS) {
      setLoading(false);
      handleClose({ title: organisation, mode: 'CREATED' });
    } else if (orgCreateStatus === API_STATUS.FAILURE) {
      setErrorText(createWSError || 'Something went wrong');
    }
  }, [orgCreateStatus]);

  useEffect(() => {
    if (orgDataFetchStatus !== 'LOADING') {
      setLoading(false);
    }
  }, [orgDataFetchStatus]);

  useEffect(() => {
    if (userChoice === 1) {
      dispatch(getOrganisationSuggestionsThunk());
    }
  }, [userChoice]);

  useEffect(() => {
    if (
      userChoice === 0 &&
      organisationData &&
      Array.isArray(organisationData)
    ) {
      const workspaceData = organisationData.find(
        (org) => org.workspaceName === workspace
      );
      if (workspaceData) {
        dispatch(workspaceSwitchThunk({ workspaceData, reqId }));
      }
    }
  }, [userChoice, organisationData]);

  const createWorkspaceHandler = () => {
    if (_isEmpty(organisation)) {
      setErrorText('EMPTY_NAME');
      return;
    }
    dispatch(
      createOrganisationThunk({
        email,
        organisation,
        source,
        orgData,
      })
    );
  };

  const handleJoinWSClick = async (ws) => {
    await dispatch(
      joinOrgThunk({
        email,
        orgId: ws.workspaceId,
        orgName: ws.workspaceName,
        adminId: ws.adminId || 63,
        adminEmail: ws.createdByUserEmail,
      })
    );
    handleClose(ws);
  };

  const handleChangeWorkspace = (e) => {
    if (e.target.value.length > 50) {
      setErrorText('CHAR_LIMIT');
    } else if (e.target.value.length === 0) {
      setErrorText('EMPTY_NAME');
    } else {
      setErrorText('');
    }
    setOrganisation(e.target.value);
  };

  const handleSelectWorkspace = (workspace) => {
    setWorkspace(workspace);
  };

  const handleJoinViaCode = () => {
    dispatch(joinOrganisationThunk({ email, inviteCode }));
    handleClose(organisationDataViaCode);
  };
  const handleChangeInviteCode = (e) => {
    if (e.target.value.length === 0) {
      setErrorText('EMPTY_CODE');
    } else {
      setErrorText('');
    }
    setInviteCode(e.target.value);
  };

  const handleGetViaCode = () => {
    if (_isEmpty(inviteCode)) {
      setErrorText('EMPTY_CODE');
    } else {
      setLoading(true);
      dispatch(getOrganisationViaCodeThunk({ email, inviteCode }));
    }
  };

  const getErrorText = (errorKey) => {
    const ERROR_CODES = {
      EMPTY_NAME: 'Workspace name cannot be empty',
      CHAR_LIMIT: 'You have exceeded the 50 character limit!',
      INVALID_CODE: 'Already part of this workspace',
      EMPTY_CODE: 'Invite code cannot be empty',
      INCORRECT_CODE: 'Organisation Code is Incorrect',
    };

    return ERROR_CODES[errorKey] || errorText;
  };

  const getHeaderWorkspace = (userChoice, headerOrbutton = true) => {
    if (userChoice === 0) {
      return headerOrbutton ? 'Create Workspace' : 'Create New Workspace';
    } else if (userChoice === 1) {
      return headerOrbutton ? 'Existing Workspace(s)' : 'Join Existing';
    } else if (userChoice === 2 || userChoice === 4) {
      return 'Join Via Invite Code';
    }
  };

  const getMessage = (userChoice = 0, organizationData = []) => {
    if (userChoice === 0) {
      return 'Create a workspace to collobrate with your team, manage credits, and much more';
    } else if (userChoice === 1) {
      return !organizationData || loading
        ? ''
        : organizationData.length > 0
        ? `Select a workspace to join with domain name ${domain}`
        : "Uh-Oh! No existing workspaces found with your email's domain.";
    } else if (userChoice === 2) {
      return 'Enter the invite code to join workspace';
    } else if (userChoice == 4) {
      return 'Confirm to join workspace';
    }
  };

  const handleSkip = () => {
    dispatch(
      createOrganisationThunk({
        email,
        organisation: first_name + "'s Workspace",
        source,
        orgData,
      })
    );
    dispatch(skipOrganisationCreation());
  };

  return (
    <Modal
      visible={showModal}
      onOk={onOk}
      onCancel={handleClose}
      footer={null}
      closable={true}
      closeIcon={
        source === 'ADD_WS_BTN' ? (
          <CloseOutlined />
        ) : (
          <Button type='text' onClick={handleSkip}>
            Skip
          </Button>
        )
      }
      width='450px'
      className={source === 'ADD_WS_BTN' ? 'workspace-modal ' : 'signup-modal'}
      centered
    >
      <Row direction='vertical' align='center' size='small' className='w-100'>
        {source === 'SIGNUP' && (
          <Row>
            <Button type='text' onClick={handleSkip}>
              <Text></Text>
            </Button>
          </Row>
        )}
        <Row className='w-100' justify='center'>
          {source === 'SIGNUP' && <BrandLogo />}
        </Row>
        <Row justify='center'>
          <Text type='h41'>{getHeaderWorkspace(userChoice)}</Text>
        </Row>

        <Row justify='center w-100'>
          <Text className='create-workspace-msg text-center' color={'#333'}>
            {getMessage(userChoice, organisationData)}
          </Text>
        </Row>

        {userChoice === 0 && (
          <Row>
            <Row justify='start' className='w-100 org-input-wrapper'>
              <Input
                placeholder='Workspace Name'
                onChange={handleChangeWorkspace}
                value={organisation}
                className='workspace-name'
              />
              {errorText.length > 0 && (
                <Text type='p30' color={'error-red'} className='mt-1'>
                  {getErrorText(errorText)}
                </Text>
              )}
            </Row>
            <Row justify='center' className='w-100'>
              <Button
                type='primary'
                onClick={createWorkspaceHandler}
                disabled={organisation.length === 0 || !_isEmpty(errorText)}
                className='w-100 ws-btn btn-primary-color '
              >
                Create Workspace
              </Button>
            </Row>
          </Row>
        )}
        {userChoice === 1 && (
          <Row>
            {organisationData?.length > 0 ? (
              <>
                <Row className={'w-100'}>
                  <Text>Available Workspaces({organisationData.length})</Text>
                </Row>
                <Row className='join-workspace w-100'>
                  {organisationData.map((val, index) => {
                    return (
                      <Col
                        xs={24}
                        key={index}
                        onClick={() => handleSelectWorkspace(val)}
                      >
                        <Row
                          justify='start'
                          className={
                            workspace?.workspaceId === val.workspaceId
                              ? 'selected-ws workspace-wrapper'
                              : 'workspace-wrapper'
                          }
                        >
                          <Row justify='center'>
                            <AvatarWrapper data={val} />
                            <Col className='px-2'>
                              <Row justify='center'>
                                <Col span={24}>
                                  <Text type='p19' color='dark-gray'>
                                    {val.workspaceName}
                                  </Text>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <Text type='p24' color='gray-shade'>
                                    Admin: {val.createdByUsername}
                                  </Text>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
                <Row justify='center' className='w-100 mt-4'>
                  <Button
                    type='primary'
                    onClick={() => handleJoinWSClick(workspace)}
                    className='w-100 ws-btn btn-primary-color'
                    disabled={workspace === null}
                  >
                    {source === 'SIGNUP' ? 'Confirm' : 'Join Workspace'}
                  </Button>
                </Row>
              </>
            ) : loading ? (
              <Spin />
            ) : (
              <Row>
                <Row justify='center' className='w-100 mb-4'>
                  <Row>
                    <Button
                      type='primary'
                      onClick={() => setUserChoice(0)}
                      className='w-100 mt-4 ws-btn btn-primary-color'
                    >
                      Create New Workspace
                    </Button>
                  </Row>
                </Row>
                <Row justify='center' className='w-100'>
                  <Button
                    className='w-100 btn-primary-color'
                    type='text'
                    onClick={() => setUserChoice(2)}
                  >
                    <Text style={{ color: '#333' }}>
                      {getHeaderWorkspace(2)}
                    </Text>
                  </Button>
                </Row>
              </Row>
            )}
          </Row>
        )}
        {userChoice === 2 && (
          <Row justify={'start'} className='w-100'>
            <Row className='w-100 remove-border'>
              <Col xs={14}>
                <Input
                  style={{ lineHeight: '1.55rem' }}
                  placeholder='Enter invite code'
                  onChange={handleChangeInviteCode}
                  value={inviteCode}
                />
              </Col>
              <Col xs={10}>
                <Button
                  type='primary'
                  className='w-100 ws-btn btn-primary-color'
                  loading={loading}
                  onClick={handleGetViaCode}
                  disabled={inviteCode?.length === 0}
                >
                  Next
                </Button>
              </Col>
            </Row>
            {(errorText === 'EMPTY_CODE' ||
              errorText === 'INVALID_CODE' ||
              errorText === 'INCORRECT_CODE') && (
              <Text type='p30' color={'error-red'} className='mt-1'>
                {getErrorText(errorText)}
              </Text>
            )}
          </Row>
        )}

        {userChoice === 4 && (
          <Row className='w-100'>
            <Row
              justify='start'
              className='selected-ws pt-3 w-100'
              onClick={() => handleSelectWorkspace(workspace)}
            >
              <Row justify='center' align='middle'>
                <AvatarWrapper data={workspace} />
                <Col className='px-2'>
                  <Row justify='center' align='middle'>
                    <Col span={24}>
                      <Text type='p19' color='dark-gray'>
                        {workspace?.title}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>

            <Row className='mt-4 mb-4 w-100'>
              <Button
                className='w-100  btn-primary-color'
                type='primary'
                onClick={handleJoinViaCode}
              >
                Confirm
              </Button>
            </Row>
          </Row>
        )}
      </Row>
      {(userChoice === 0 ||
        userChoice === 2 ||
        (userChoice === 1 && organisationData?.length > 0)) && (
        <Row className='mt-6 mb-6'>
          <OrLine></OrLine>
        </Row>
      )}

      {!(
        userChoice === 1 &&
        (!organisationData || organisationData?.length === 0)
      ) && (
        <Row className='workspace-footer'>
          {[0, 1, 2].map((option, index) => {
            if (
              (userChoice < 4 && userChoice !== option) ||
              (userChoice === 4 && option !== 2)
            ) {
              return (
                <Col xs={12} xl={12} key={index} className='p-0'>
                  <Button
                    type='text'
                    onClick={() => setUserChoice(option)}
                    className=''
                  >
                    {getHeaderWorkspace(option, false)}
                  </Button>
                </Col>
              );
            } else {
              return <></>;
            }
          })}
        </Row>
      )}
    </Modal>
  );
};
export default CreateNewWorkspace;
