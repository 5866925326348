/** Returns which elements should be visible by default */

export const ELEMENT_DEFAULT_VISIBILITY = {
    navbarV: true,
    viewPanelV: false,
    layerPanelV: true,
    mapControlsV: true,
    nearmapDropdownV: true,
    actionCenterV: true,
    mapToolBarV: true,
    quickTipV: true,
    orderDetailsPanelV: false,
    notesReportsSnapsPanelV: true,
}