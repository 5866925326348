import { message } from 'antd';
import './style.css';
import Text from '../../elements/text/Text';

export const successBottomMessage = (text) => {
  message.success({
    duration: 2,
    content: (
      <Text type='p0' className={'px-1 success-msg '}>
        {text}
      </Text>
    ),
    className: 'success-msg-container',
    style: {
      marginTop: '90vh',
    },
  });
};
