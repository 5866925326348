// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector148IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector148Icon(props: Vector148IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 15 15"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M14.354 4.354a.5.5 0 000-.708L11.172.464a.5.5 0 10-.708.708L13.293 4l-2.829 2.828a.5.5 0 10.708.708l3.182-3.182zM.334 12a2.667 2.667 0 105.333 0 2.667 2.667 0 00-5.334 0zm5.416-.5H3v1h2.75v-1zm1.641 0H5.75v1h1.641v-1zM5.938 8.47l1.43-.02-.014-1-1.43.02.014 1zM14 3.5H8.5v1H14v-1zm-5.5 0H5.902v1H8.5v-1zm1.417 6.475a2.525 2.525 0 00-2.563-2.526l.015 1a1.525 1.525 0 011.548 1.526h1zM7.39 12.5a2.525 2.525 0 002.526-2.525h-1c0 .842-.683 1.525-1.526 1.525v1zM3.417 5.985A2.485 2.485 0 005.938 8.47l-.014-1a1.485 1.485 0 01-1.507-1.485h-1zm1 0c0-.82.665-1.485 1.485-1.485v-1a2.485 2.485 0 00-2.485 2.485h1z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector148Icon;
/* prettier-ignore-end */
