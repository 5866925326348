import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '@/store/types';
import { useMemo } from 'react';
import { User } from '@/store/user/state';
import { Box } from '@common/elements';
import useAuth from '@/modules/auth/hooks';
import { ProfileDropdownTransformer } from './profile-dropdown.transformer';
import { ProfileDropdown as BaseProfileDropdown } from '@siterecon/sr-component/dist/sr-component-library';
import { Workspace as StorybookWorkspace } from '@/modules/storybook/Subscription/types';
import { Workspace } from '@common/models/workspace';
import { SubscriptionType } from '../../../../modules/common/models/subscription';
import { useWorkspace } from '@/modules/workspace/hooks/useWorkspace';
import useProfileDropdownMenu from './useProfileDropdownMenu';
import { useSubscription } from '@/modules/subscription/hooks';
import { useBetaRelease } from '@/modules/common/guards/BetaRelease';
import { BetaFeature } from '@common/types';
import { SubscriptionState } from '../../../subscription/helpers/enum';
import { openUrl } from '../../../utils';
import {
  NO_ACTIVE_SUBSCRIPTION,
  PLAN_EXPIRED,
} from '../../../subscription/helpers/urls';
import { trackEvents } from '../../../../helpers/utilities';
import { ProfileDropdownEvt } from '../../../../segment';
import { sendWorkspaceTrackEvt } from './util';
import EoDbidConsumeModal from '../../../PlasmicComponents/EoDbidConsumeModal';
import useBidConsume from '../../../pages/project/projectComponents/hooks/useBidConsume';

export interface ProfileDropdownProps {
  /** Callback fired when user tries to Add Workspace without verified email */
  onToggleEmailVerifyModal: () => void;

  /** Callback fired when user tries to Add Workspace */
  onAddWorkspace: () => void;

  isMapPage?: boolean;
}

const ProfileDropdown = ({
  onAddWorkspace,
  onToggleEmailVerifyModal,
  isMapPage = false,
}: ProfileDropdownProps) => {

  const { change } = useWorkspace();
  const { organizationId, user } = useAuth();
  const { visible, setVisible, items, handleMenuItemClick } = useProfileDropdownMenu();
  const { showSubscriptionWidget, subscriptionState } = useSubscription();
  const bidConsume = useBidConsume();
  const isAddWorkspaceEnabled = useBetaRelease(BetaFeature.AddWorkspace);
  const isEstimationWorkspace = useBetaRelease(BetaFeature.Estimations);

  const workspaces = useSelector<IStore, Workspace[]>(
    (state) => state.workspace.list
  );

  const transformer = useMemo(() => {
    if (Object.keys(user).length === 0) return null;

    return new ProfileDropdownTransformer(user as User, workspaces);
  }, [user, workspaces]);

  const sortedWorkspaces = useMemo(() => {
    if (!transformer) return [];

    return transformer.workspaces().sort((a) => {
      return a.id === organizationId ? -1 : 1;
    });
  }, [transformer]);

  const handleAddWorkspace = () => {
    setVisible(false);

    if (user.email_verified !== '1') {
      onToggleEmailVerifyModal();
      return;
    }

    onAddWorkspace();
  };

  const handleWorkspaceChange = (newWorkspace: StorybookWorkspace) => {
    if (!newWorkspace) return;

    sendWorkspaceTrackEvt(newWorkspace, ProfileDropdownEvt.WorkspaceSwitch);

    change(newWorkspace.id);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    if (newVisible) {
      trackEvents(ProfileDropdownEvt.Visible);
    }

    setVisible(newVisible);
  };

  if (!transformer || !organizationId) {
    return null;
  }

  const handleCurrentWorkspaceWidgetClick = () => {

    const selectedWorkspace = transformer.getWorkspaceById(organizationId);
    sendWorkspaceTrackEvt(selectedWorkspace, ProfileDropdownEvt.WidgetClick);

    if (subscriptionState === SubscriptionState.PLAN_CANCELLED) {
      openUrl(PLAN_EXPIRED);
    } else if (
      [
        SubscriptionState.AUTO_DOWNGRADED,
        SubscriptionState.MIGRATED_WORKSPACE,
      ].includes(subscriptionState)
    ) {
      openUrl(NO_ACTIVE_SUBSCRIPTION);
    } else {
      showSubscriptionWidget();
    }
    setVisible(false);
  };

  const EstimationWidget = () => {
    return <div style={{ border: '0.5px solid #DDDDDD', borderRadius: 4 }}>
      <EoDbidConsumeModal bidConsume={bidConsume} />
    </div>
  }

  return (
    <Box className='ml-6'>
      <BaseProfileDropdown
        isEstimationWorkspace={isEstimationWorkspace}
        estimationWidget={<EstimationWidget />}
        userDetails={transformer.userDetails()}
        workspaces={sortedWorkspaces}
        selectedWorkspace={transformer.getWorkspaceById(organizationId, {
          onProfileDropdownWidgetClick: handleCurrentWorkspaceWidgetClick,
        })}
        onWorkspaceSelect={handleWorkspaceChange}
        visible={visible}
        onVisibleChange={handleVisibleChange}
        addButtonClickHandler={handleAddWorkspace}
        actionItems={items}
        onActionItemClick={handleMenuItemClick}
        subscriptionState={subscriptionState || undefined}
        isAddWorkspaceEnabled={isAddWorkspaceEnabled}
        chipSize={isMapPage ? 'small' : 'large'}
      />
    </Box>
  );
};

export default ProfileDropdown;
