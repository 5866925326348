import { SubscriptionFeature, SubscriptionFeatureData, } from '@/modules/subscription/types';
import { useSubscription } from '@/modules/subscription/hooks/index';

export type UseSubscriptionFeatureResponse = {
  data: SubscriptionFeatureData | null;
  enabled: boolean;
};

/** Returns data related to given {@link SubscriptionFeature}, if feature is not available returns null */
const useSubscriptionFeature = (
  feature: SubscriptionFeature
): UseSubscriptionFeatureResponse => {
  const { getFeatureData } = useSubscription();

  return getFeatureData(feature);
};

export default useSubscriptionFeature;
