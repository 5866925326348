import Icon from 'ol/style/Icon';
import OlLayerTile from 'ol/layer/Tile';
import OlSourceOSM from 'ol/source/OSM';
import OlView from 'ol/View';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { transform } from 'ol/proj';

export const ACTIVE_SECTION = 'activeSection';
export const ADDRESS = 'address';
export const ADDRESS_ERROR = 'addressError';
export const ADDRESS_ERROR_LOCATION_BUTTON = 'ADDRESS_ERROR_LOCATION_BUTTON';
export const ADDRESS_ERROR_LOCATION_INPUT_TAG =
  'ADDRESS_ERROR_LOCATION_INPUT_TAG';
export const ADDRESS_VALIDATION_ERROR_MESSAGE = 'This field is required.';
export const ADDRESS_WARNING = 'addressWarning';
export const ADDRESS_WARNING_MESSAGE =
  'Warning: Generated measurements for this address may be inaccurate.';
export const ANONYMOUS = 'Anonymous';
export const ARE_ORDER_DETAILS_BEING_SAVED = 'isOrderBeingSaved';
export const BACK_BUTTON_STYLE = 'backButtonStyle';
export const BACK_BUTTON_TITLE = 'backButtonTitle';
export const BOLT_ROUTE_DYNAMIC = (orderHash = '') => `/bolt/${orderHash}`;
export const BOLT_ROUTE_STATIC = '/bolt';
export const BOLT_VIEW_SITE_RECON_DATA = 'Bolt View (SiteRecon Data)';
export const BOLT_IN_VERIFICATION = 'BOLT_IN_VERIFICATION';
export const CENTER = 'center';
export const COMMON_ERROR_MESSAGE = 'Something went wrong.';
export const DISABLE_STATE = 'DISABLE_STATE';
export const EDIT_DETAILS = 'EDIT_DETAILS';
export const EPSG_3857 = 'EPSG:3857';
export const EPSG_4326 = 'EPSG:4326';
export const FAST_TRACK_VIEW = 'fastrackView';
export const FIRST_NAME = 'firstName';
export const CUSTOMER_NAME = 'customerName';
export const PROPERTY_NAME = 'propertyName';
export const GENERATING_ESTIMATES = 'GENERATING_ESTIMATES';
export const GET_VERIFIED = 'GET_VERIFIED';
export const GET_MEASUREMENT = 'GET_MEASUREMENT';
export const HIDE_SUGGESTIONS = 'hideSuggestions';
export const HOME_ROUTE = '/';
export const IMAGE_LOAD_END = 'imageloadend';
export const IS_ORDER_BEING_CREATED = 'isOrderBeingCreated';
export const LAST_NAME = 'lastName';
export const MAP_DEFAULT_LON_LAT = [-101.7129, 39.0902];
export const MAP_DEFAULT_PROJECTION = EPSG_3857;
export const MAP_DEFAULT_ZOOM = 5;
export const MEASURED_PROPERTY = 'MEASURED_PROPERTY';
export const MEASUREMENT_COMPLETE = 'MEASUREMENT_COMPLETE';
export const MEASUREMENT_DELAYED = 'MEASUREMENT_DELAYED';
export const ORDER_ID = 'orderId';
export const ORDER_FEATURES = 'orderFeatures';
export const PARCEL_JSON = 'parcelJSON';
export const PHONE_NUMBER = 'phoneNumber';
export const PHONE_NUMBER_OBJECT = 'phoneNumberObject';
export const POLYGON = 'polygon';
export const REGULAR_ORDER = 'REGULAR_ORDER';
export const RESET_STATE = 'resetState';
export const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';
export const TILE_DATA = 'tileData';
export const VERIFICATION_TIMER = 'VERIFICATION_TIMER';
export const TOTAL_AREA = 'totalArea';
export const TURF_COMPONENTS = 'turfComponents';
export const ZOOM = 'zoom';
export const CUSTOMER_ID = 'customerId';
export const DISMISS = 'dismiss';
export const NORMAL = 'normal';
export const QC = 'qc';

export const CUSTOM_CREATE_ORDER_ERRORS = {
  'SR-BIZ-ERR-005': {
    message: 'Parcel not found.',
    status: 400,
    location: ADDRESS_ERROR_LOCATION_INPUT_TAG,
  },
  'SR-BIZ-ERR-010': {
    message: 'Insufficient credit in your account.',
    status: 404,
    location: ADDRESS_ERROR_LOCATION_BUTTON,
  },
  'SR-BIZ-ERR-017': {
    message: 'Property parcel is greater than 5 acres.',
    status: 400,
    location: ADDRESS_ERROR_LOCATION_INPUT_TAG,
  },
  'SR-BIZ-ERR-018': {
    message: 'Nearmap Imagery is not available.',
    status: 400,
    location: ADDRESS_ERROR_LOCATION_INPUT_TAG,
  },
};
export const DEFAULT_LAYERS_Z_INDEX = {
  turfLayer: 0,
  neapMapLayer: 1,
  parcelLayer: 2,
};
export const FEATURE_STYLES = {
  Icon: new Style({
    image: new Icon({
      anchor: [0.5, 0.9],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      scale: 0.15,
      src: require('../../../../assets/icons/markerIconThree.svg').default,
    }),
  }),
  NewIcon: new Style({
    image: new Icon({
      anchor: [0.5, 0.9],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      src: require('../../../../assets/icons/markerIconThreeNew.svg').default,
    }),
  }),
  MultiPolygon: new Style({
    stroke: new Stroke({
      color: '#FFD02B',
      width: 2,
    }),
  }),
  Point: new Style({
    image: new CircleStyle({
      radius: 10,
      fill: null,
      stroke: new Stroke({
        color: 'magenta',
      }),
    }),
  }),
  Polygon: new Style({
    stroke: new Stroke({
      color: 'blue',
      lineDash: [4],
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
};
export const MAP_DEFAULT_CENTER = transform(
  MAP_DEFAULT_LON_LAT,
  EPSG_4326,
  MAP_DEFAULT_PROJECTION
);
export const MAP_DEFAULT_LAYERS = [
  {
    name: 'Street',
    value: 'street',
    layer: new OlLayerTile({
      source: new OlSourceOSM({
        url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        crossOrigin: 'Anonymous',
      }),
      visible: false,
    }),
  },
  {
    name: 'Satellite',
    value: 'raster',
    layer: new OlLayerTile({
      source: new OlSourceOSM({
        url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
        crossOrigin: 'Anonymous',
      }),
    }),
  },
];
export const MAP_DEFAULT_OPTIONS = {
  layers: [],
  view: new OlView({
    center: MAP_DEFAULT_CENTER,
    projection: MAP_DEFAULT_PROJECTION,
    zoom: MAP_DEFAULT_ZOOM,
  }),
};
