import { MEASURE_STYLE, RESTING_MODE_STYLE, styleFunction, } from '../../mapGlobals';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Draw } from 'ol/interaction';
import { Polygon } from 'ol/geom';
import { transform } from 'ol/proj';
import { MAP_DEFAULT_PROJECTION, MAP_DESIGNATION_PROJECTION, } from '../../constants/mapConstants';
import { _get } from '../../utilities/lodashUtils';
import { formatArea, formatLength } from '../index';
import Overlay from 'ol/Overlay';
import { unByKey } from 'ol/Observable';

let sourceMeasure;
let vectorMeasure;
let drawMeasurementInteraction;
let sketch;
let listener;
let countUn = 0;
let measureTooltip;
let measureTooltipElement;

export const onAreaMeasurement = (mapRef) => {
  if (mapRef) {
    if (drawMeasurementInteraction) {
      mapRef.removeInteraction(drawMeasurementInteraction);
    }
    if (vectorMeasure) {
      mapRef.removeLayer(vectorMeasure);
    }

    createMeasureTooltip(mapRef);
    sourceMeasure = new VectorSource({
      wrapX: false,
      crossOrigin: 'Anonymous',
    });
    vectorMeasure = new VectorLayer({
      name: 'measurement',
      source: sourceMeasure,
      style: RESTING_MODE_STYLE,
    });
    vectorMeasure.setStyle(MEASURE_STYLE);
    vectorMeasure.setZIndex(1000);

    drawMeasurementInteraction = new Draw({
      source: sourceMeasure,
      type: 'Polygon',
      finishCondition: (event) => {
        if (
          drawMeasurementInteraction.finishCoordinate_ ===
          drawMeasurementInteraction.sketchCoords_[0][0]
        ) {
          return true;
        }
      },
      style: styleFunction,
    });

    mapRef.addInteraction(drawMeasurementInteraction);

    onDrawStartMeasurement(mapRef, drawMeasurementInteraction);
    onDrawEndMeasurement(mapRef, drawMeasurementInteraction);

    mapRef.updateSize();
  }
};

const onDrawStartMeasurement = (mapRef, drawMeasurementInteraction) => {
  let measureDivElement = document.getElementById('measure-div');
  drawMeasurementInteraction.on('drawstart', function (event) {
    // mapRef.getLayers().getArray()[1].getSource().clear();
    measureDivElement.style.visibility = 'inherit';
    let mapCoordinate = transform(
      event.feature.getGeometry().getCoordinates()[0][0],
      MAP_DEFAULT_PROJECTION,
      MAP_DESIGNATION_PROJECTION
    );
    measureDivElement.innerHTML = `
    <div style='padding: 12px'>
    <div style='justify-content: left; align-items: left;display: flex; flex-direction: column;'>
    <div style='color: var(--Colors-Text-100, #333); font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 600; line-height: 30px;'>Measurement Tool</div>
    <p style='color: var(--Colors-Text-200, #666); font-family: Poppins; font-size: 12px; font-style: normal; font-weight: 400; line-height: normal;'>Draw on the map to measure</p>
          </div>
          <div style='display: flex;flex-direction: column;'>
          <p style='margin-bottom: 3px;
          color: var(--Colors-Text-200, #666); font-family: Poppins; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px;'>Distance</p>
            <span style='font-weight: 500;color: #333333;'>0 ft. (0 m)</span>
          </div>
          <div style='display: flex;flex-direction: column; padding-top: 10px;'>
          <p style='margin-bottom: 3px; color: var(--Colors-Text-200, #666); font-family: Poppins; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; /* 133.333% */'>Lat, Long</p>
            <span style='font-weight: 500;color: #333333;'>${mapCoordinate[1].toFixed(
              5
            )}, ${mapCoordinate[0].toFixed(5)}</span>
          </div>
          </div>
        `;
    sourceMeasure.clear();
    document
      .querySelectorAll('div.ol-tooltip.ol-tooltip-static')
      .forEach((item) => item.remove());

    sketch = event.feature;
    let tooltipCoord = event.coordinate;
    let coordinateArr = [],
      lastPoint = false,
      index = 0,
      tArr = [],
      outFix,
      outFeet,
      outMiles = 0,
      outSqFeet,
      outAcre;

    listener = sketch.getGeometry().on('change', function (event) {
      let geom = _get(event, 'target');
      let output, outputLength;
      coordinateArr.push(geom.getCoordinates());

      if (coordinateArr.length !== 1) {
        if (
          coordinateArr[index][0].length >
          coordinateArr[coordinateArr.length - 1][0].length
        ) {
          lastPoint = true;
        } else {
          index = index + 1;
        }
      }

      if (geom instanceof Polygon) {
        output = formatArea(geom);
        outputLength =
          formatLength(geom, lastPoint, tArr, countUn) !== undefined &&
          formatLength(geom, lastPoint, tArr, countUn);

        if (outputLength !== 0) {
          tArr.push(parseFloat(outputLength));
        }
        tooltipCoord = geom.getInteriorPoint().getCoordinates();
      }

      if (outputLength > 100) {
        if (outputLength !== undefined && outputLength !== false) {
          outFix = (outputLength / 1000).toFixed(2);
          outFeet = (outputLength * 3.28084).toFixed(2);
          outMiles = (outputLength * 0.000621371).toFixed(2);
        }
      } else {
        if (outputLength !== undefined && outputLength !== false) {
          outFix = outputLength.toFixed(2);
          outFeet = (outputLength * 3.28084).toFixed(2);
        }
      }
      if (output !== '0 m<sup>2</sup>') {
        let ar = output.split(' ');

        outSqFeet = (parseFloat(ar[0]) * 10.7639).toFixed(2);
        outAcre = (parseFloat(ar[0]) * 0.000247105).toFixed(2);

        if (parseFloat(ar[0]) > 10000) {
          output =
            Math.round((parseFloat(ar[0]) / 1000000) * 100) / 100 +
            ' ' +
            'km<sup>2</sup>';
        }
      }

      let mapToolTipCoordinate = transform(
        tooltipCoord,
        MAP_DEFAULT_PROJECTION,
        MAP_DESIGNATION_PROJECTION
      );
      if (output !== '0 m<sup>2</sup>') {
        measureDivElement.innerHTML = 
          `<div style='padding: 12px'>
          <div style='justify-content: left; align-items: left;display: flex; flex-direction: column;'>
            <div style='color: var(--Colors-Text-100, #333); font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 600; line-height: 30px;'>Measurement Tool<div>
            <p style='color: var(--Colors-Text-200, #666); font-family: Poppins; font-size: 12px; font-style: normal; font-weight: 400; line-height: normal;'>Draw on the map to measure</p>
          </div>
          <div style='display: flex;flex-direction: column;'>
            <p style='margin-bottom: 3px;
            color: var(--Colors-Text-200, #666); font-family: Poppins; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px;'>Distance</p>

            <span style='font-weight: 500;color: #333333;'>${
              outMiles > 1 ? outMiles : outFeet
            } ${outMiles > 1 ? `miles` : `ft.`} (${outFix} ${
          outputLength > 100 || tArr[tArr.length - 2] > 100 ? 'km' : 'm'
        })</span>
          </div>
          <div style='display: flex;flex-direction: column; padding-top: 10px;'>
            <p style='margin-bottom: 3px; color: var(--Colors-Text-200, #666); font-family: Poppins; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; /* 133.333% */'>Area</p>

            <span style='font-weight: 500;color: #333333;'>${
              outAcre > 1 ? outAcre : outSqFeet
            } ${outAcre > 1 ? `acres` : 'sq. ft.'} (${output})</span>
          </div>
          </div>
          `;
      } else {
        measureDivElement.innerHTML = `
        <div style='padding: 12px'>
        <div style='justify-content: left; align-items: left;display: flex; flex-direction: column;'>
        <div style='color: var(--Colors-Text-100, #333); font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 600; line-height: 30px;'>Measurement Tool</div>
        <p style='color: var(--Colors-Text-200, #666); font-family: Poppins; font-size: 12px; font-style: normal; font-weight: 400; line-height: normal;'>Draw on the map to measure</p>
          </div>
          <div style='display: flex;flex-direction: column;'>
          <p style='margin-bottom: 3px;
          color: var(--Colors-Text-200, #666); font-family: Poppins; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px;'>Distance</p>

            <span style='font-weight: 500;color: #333333;'>${
              outMiles > 1 ? outMiles : outFeet
            } ${outMiles > 1 ? `miles` : `ft.`} (${outFix} ${
          outputLength > 100 || tArr[tArr.length - 2] > 100 ? 'km' : 'm'
        })</span>
          </div>
          <div style='display: flex;flex-direction: column; padding-top: 10px;'>
          <p style='margin-bottom: 3px; color: var(--Colors-Text-200, #666); font-family: Poppins; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; /* 133.333% */'>Lat, Long</p>
            <span style='font-weight: 500;color: #333333;'>
              ${mapToolTipCoordinate[1].toFixed(
                5
              )}, ${mapToolTipCoordinate[0].toFixed(5)}
            </span>
          </div>
          </div>
          `;
      }
      // measureTooltipElement.innerHTML = output;
      // measureTooltip.setPosition(tooltipCoord);
    });
  });
};

const onDrawEndMeasurement = (mapRef, drawMeasurementInteraction) => {
  drawMeasurementInteraction.on('drawend', function (event) {
    countUn = 0;
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
    measureTooltip.setOffset([0, -7]);
    sketch = null;
    createMeasureTooltip(mapRef);
    unByKey(listener);
  });
  mapRef.addLayer(vectorMeasure);
};

const createMeasureTooltip = (mapRef) => {
  measureTooltipElement = document.createElement('div');
  measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: 'bottom-center',
  });
  mapRef.addOverlay(measureTooltip);
};

export const removeMeasureInteraction = (mapRef) => {
  if (drawMeasurementInteraction) {
    mapRef.getInteractions().forEach((interaction) => {
      if (interaction === drawMeasurementInteraction) {
        mapRef.removeInteraction(drawMeasurementInteraction);
        mapRef.removeLayer(vectorMeasure);
      }
    });
  }
};
