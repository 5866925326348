import { GuardProps, OrderStatus } from '@/modules/common/types';
import { WrapperProps } from '@/modules/common/components/Wrapper';
import BaseGuard from '@/modules/common/guards/BaseGuard';
import React, { FC, memo, ReactNode, useMemo } from 'react';
import useOrderStatus from '@/modules/project/guards/OrderStatus/useOrderStatus';

interface OrderStatusProps extends GuardProps {
  /** Single or List of {@link OrderStatus} for which we want to display the content */
  status?: OrderStatus | OrderStatus[];

  /**  Single or List of {@link OrderStatus} for which we *don't* want to display the content */
  except?: OrderStatus | OrderStatus[];

  /** (Optional) A Fall-back component which should be rendered if condition is not satisfied */
  fallback?: ReactNode;
}

const OrderStatusGuard: FC<OrderStatusProps & WrapperProps> = ({
  status: allowedStatus,
  except: excludedStatus,
  children,
  fallback,
  or,
  ...rest
}) => {
  const orderStatus = useOrderStatus();

  const orderStatusCondition = useMemo(() => {
    if (!orderStatus) return false;

    if (excludedStatus) {
      if (typeof excludedStatus === 'number') {
        return orderStatus !== excludedStatus;
      }
      return !excludedStatus.includes(orderStatus);
    }

    if (allowedStatus) {
      return typeof allowedStatus === 'number'
        ? orderStatus === allowedStatus
        : allowedStatus.includes(orderStatus);
    }

    return false;
  }, [orderStatus, allowedStatus, excludedStatus]);

  const canRender = useMemo(() => {
    if (typeof or !== 'undefined') {
      return orderStatusCondition || or;
    }

    return orderStatusCondition;
  }, [orderStatusCondition, or]);

  return (
    <BaseGuard condition={canRender} fallback={fallback} {...rest}>
      {children}
    </BaseGuard>
  );
};

OrderStatusGuard.displayName = 'OrderStatusGuard';

export { useOrderStatus };

export default memo(OrderStatusGuard);
