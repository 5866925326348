import { Button, Col, Row, Tooltip } from "antd";

import React from "react";
import "./style.less";

import UndoRedoSVG from "./UndoRedoSVG";

export interface UndoRedoProps {
  disableUndoButton: boolean;
  disableRedoButton: boolean;
  undoClick: () => void;
  redoClick: () => void;
}

const UndoRedo = ({
  disableUndoButton,
  disableRedoButton,
  undoClick,
  redoClick,
}: UndoRedoProps) => {
  return (
    <Row className="undo-redo-section" justify={"space-around"}>
      <Col>
        <Tooltip placement="bottom" title="Undo">
          <Button
            className={disableUndoButton ? "" : "icon-button "}
            type="text"
            disabled={disableUndoButton}
            onClick={undoClick}
            size={"small"}
            icon={<UndoRedoSVG />}
          />
        </Tooltip>
      </Col>
      <Col>
        <Tooltip placement="bottom" title="Redo">
          <Button
            className={
              disableRedoButton ? "flip-section" : "icon-button flip-section"
            }
            type="text"
            disabled={disableRedoButton}
            onClick={redoClick}
            size={"small"}
            icon={<UndoRedoSVG />}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

export default UndoRedo;
