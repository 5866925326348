import LineString from 'ol/geom/LineString';
import { getArea, getLength } from 'ol/sphere';
import { newColorFind, trackEvents } from '../utilities';
import { MapControlsEvt } from '../../segment';
/*
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
export const formatLength = (polygon, last, tArr, countUn) => {
  let lCoord = polygon.getLinearRing().getCoordinates();
  let lineArr = [],
    lineString,
    lineLength = 0,
    length;

  lCoord.map((val, index) => {
    if (index !== 0) {
      if (lCoord.length > 1) {
        if (lCoord[lCoord.length - 2][0] === lCoord[lCoord.length - 1][0]) {
          countUn = countUn + Math.random() * 5;
          length = isNaN(tArr[tArr.length - 1])
            ? countUn
            : tArr[tArr.length - 1];
        } else if (lCoord[0][0] !== lCoord[index][0] && last === false) {
          lineArr = [];
          lineArr.push(lCoord[index - 1]);
          lineArr.push(lCoord[index]);
          lineString = new LineString(lineArr);
          lineLength += getLength(lineString);
          length = lineLength;
        }
      }
    }
  });

  // length = lineLength

  // if (length > 100) {
  //   output = Math.round((length ) * 100) / 100;
  // } else {
  //   output = Math.round(length * 100) / 100;
  // }
  if (length !== undefined) {
    return length;
  }
};

/*
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
export const formatArea = (polygon) => {
  var area = getArea(polygon);
  var output;
  output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
  return output;
};

export const rotateMapReset = (mapRef) => {
  let iconElement = document.getElementsByClassName('rotate-compass');
  if (!iconElement.length) return;

  Array.from(iconElement).forEach((element) => {
    element.onclick = (event) => {

      if (event.target.classList.contains('disabled')) return;

      trackEvents(MapControlsEvt.MapCompassClicked);

      mapRef.getView().setRotation(0);
      element.style['transform'] = `rotate(${0}rad)`;
    };
  });
};

export const mapNavigation = (direction, mapRef) => {
  let newCenterInPx;
  let center = mapRef.getView().getCenter();
  let centerInPx = mapRef.getPixelFromCoordinate(center);

  switch (direction) {
    case 'left':
      newCenterInPx = [centerInPx[0] - 100, centerInPx[1]];
      break;
    case 'right':
      newCenterInPx = [centerInPx[0] + 100, centerInPx[1]];
      break;
    case 'top':
      newCenterInPx = [centerInPx[0], centerInPx[1] - 100];
      break;
    case 'bottom':
      newCenterInPx = [centerInPx[0], centerInPx[1] + 100];
      break;
  }
  let newCenter = mapRef.getCoordinateFromPixel(newCenterInPx);
  mapRef.getView().setCenter(newCenter);
};

export const customFeatureStyle = (
  colorCode = newColorFind(),
  featureStyle = {}
) => {
  return {
    stroke: 3,
    opacity: 0.8,
    color: colorCode,
    fillColor: colorCode,
    arrowType:
      '<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.45304 0.291308C6.41699 0.205345 6.35457 0.131627 6.27387 0.079692C6.19317 0.0277572 6.09789 -4.06801e-06 6.00037 4.47088e-10C5.90285 4.0689e-06 5.80757 0.0277733 5.72686 0.0797148C5.64615 0.131656 5.58373 0.205379 5.54767 0.291345L0.0348734 13.3653C-0.00184147 13.4524 -0.00978853 13.5479 0.0120878 13.6394C0.0339641 13.7308 0.0846292 13.8137 0.157359 13.8772C0.230088 13.9407 0.321444 13.9817 0.419302 13.9947C0.51716 14.0078 0.616893 13.9923 0.705266 13.9503L5.99984 11.4386L11.2935 13.9505C11.3819 13.9928 11.4819 14.0085 11.58 13.9956C11.6781 13.9826 11.7697 13.9416 11.8426 13.8779C11.9155 13.8143 11.9663 13.7311 11.9881 13.6394C12.0099 13.5478 12.0017 13.452 11.9646 13.3648L6.45304 0.291308Z" fill="#333333"/> </svg>',
    arrowSize: 16,
    arrowColor: colorCode,
    symbolType:
      '<svg width="15" height="15" viewBox="0 0 15 15" fill="none" fill-opacity="1" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.0899 11.0899 14 7.5 14C3.91015 14 1 11.0899 1 7.5C1 3.91015 3.91015 1 7.5 1C11.0899 1 14 3.91015 14 7.5ZM7.5 12C9.98528 12 12 9.98528 12 7.5C12 5.01472 9.98528 3 7.5 3C5.01472 3 3 5.01472 3 7.5C3 9.98528 5.01472 12 7.5 12Z" fill="#333333"/> <circle cx="7.5" cy="7.5" r="7.5" fill="#D9D9D9" opacity="0.01"/></svg>',
    symbolSize: 12,
    symbolColor: colorCode,
    ...featureStyle,
  };
};
