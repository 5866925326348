import Typography from "@siterecon/sr-styles/dist/elements/Typography"

const BetaTag = () => {
    return (
        <Typography
            className='pl-1'
            style={{
              color: '#1055EB',
              fontStyle: 'italic',
              textAlign: 'center',
            }}
            fontWeight={600}
            lineHeight={'12.8px'}
            fontSize={'9.6px'}
        >
          Beta
        </Typography>
    )
  }

export default BetaTag