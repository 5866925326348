import { ANNOUNCEMENTS_ACTIONS } from './action_types';
import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';

export const getCurrentAnnouncementThunk = (payload) => (dispatch) => {
  const currentUrl = window.location.pathname;

  getAxiosInstance().get(`${API_ENDPOINTS.GET_ANNOUNCEMENTS_LIST}`)
    .then(async (response) => {
      const resData = response.data.data;
      let announcementsData =
        resData &&
        resData.map((data) => ({
          modalId: data.modalId,
          url: data.url,
          isVisible: data.isVisible,
          createdDate: data.createdAt,
        }));
      let selectedAnnouncement =
        announcementsData &&
        announcementsData.filter((data) => data.url === currentUrl);
      const selectedAnnouncementData =
        selectedAnnouncement && selectedAnnouncement[0];
      dispatch({
        type: ANNOUNCEMENTS_ACTIONS.ALL_ANNOUNCEMENTS_SUCCESS,
        payload: {
          selectedAnnouncementData,
        },
      });

      return selectedAnnouncementData;
    })
    .catch((error) => {
      dispatch({
        type: ANNOUNCEMENTS_ACTIONS.ALL_ANNOUNCEMENTS_FAILURE,
        payload: error,
      });
    });
};

export const openKeyBoardShortCutModal = (payload) => (dispatch) => {
  dispatch({
    type: ANNOUNCEMENTS_ACTIONS.OPEN_KEYBOARD_SHORTCUT_MODAL,
    payload,
  });
};
