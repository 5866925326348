import {
  HeaderWidgetType,
  UsageHistoryItemData,
  UsageHistoryLabel,
} from '../../../modules/subscription/storybook/types';
import { WorkspaceSummary } from '../../../modules/common/models/workspace';
import {
  PlanChangeDescription,
  SubscriptionPaymentStatus,
  SubscriptionPlan,
  SubscriptionState,
  SubscriptionStatus,
} from './enum';
import { GetSubscriptionStatePayload } from './types';
import { HeaderWidgetEvt, SubscriptionModalEvt } from '../../../segment';

export const ALREADY_RECEIVED_ERROR = 'Subscription request has already been received';

/** Converts given decimal hours to HH:MM format */
const formatHours = (value: number) => {
  const timeInMinutes = Math.round(value * 60);

  const hours = Math.floor(timeInMinutes / 60);
  const remainingMinutes = timeInMinutes % 60;

  const formattedMinutes = String(remainingMinutes).padStart(2, '0');

  return `${hours.toLocaleString()}:${formattedMinutes}`;
};

/** Build Usage History from {@link WorkspaceSummary} */
export const getUsageHistory = (
  data: WorkspaceSummary
): UsageHistoryItemData[] => {
  return [
    {
      label: UsageHistoryLabel.PROPERTIES_CREATED,
      color: '#ea745e',
      value: data.propertiesCreated.toLocaleString(),
    },
    {
      label: UsageHistoryLabel.ACRES_MAPPED,
      color: '#5967f3',
      value: data.acresMapped.toLocaleString(),
    },
    {
      label: UsageHistoryLabel.MAN_HOURS_SAVED,
      color: '#60d697',
      value: formatHours(data.manHoursSaved),
    },
    {
      label: UsageHistoryLabel.SITE_REPORTS,
      color: '#1193fa',
      value: data.siteReports.toLocaleString(),
    },
    {
      label: UsageHistoryLabel.SITE_WALK,
      color: '#e275f5',
      value: formatHours(data.siteWalkHours),
    },
  ];
};

/** Returns {@link SubscriptionState} according to given subscription data */
export const getSubscriptionState = (
  subscription: GetSubscriptionStatePayload
): SubscriptionState => {
  const plan = subscription.basePackage.platformAccessType;
  const previousPlan =
    subscription.previousSubscription?.platformAccessType || null;

  if (subscription.status === SubscriptionStatus.ONGOING) {
    switch (plan) {
      case SubscriptionPlan.PROFESSIONAL:
        return SubscriptionState.PROFESSIONAL_ONGOING;

      case SubscriptionPlan.TEAMS:
        return SubscriptionState.TEAMS_ONGOING;

      case SubscriptionPlan.ENTERPRISE:
        return SubscriptionState.ENTERPRISE_ONGOING;

      case SubscriptionPlan.TRIAL:
        return SubscriptionState.TRIAL_ONGOING;

      case SubscriptionPlan.LEGACY_PRO:
        return SubscriptionState.LEGACY_PROFESSIONAL;

      case SubscriptionPlan.LEGACY_ADV:
        return SubscriptionState.LEGACY_ADVANCED;

      case SubscriptionPlan.ESSENTIAL:
        if (!previousPlan) {
          return SubscriptionState.MIGRATED_WORKSPACE;
        }

        if (previousPlan === SubscriptionPlan.TRIAL) {
          return SubscriptionState.TRIAL_EXPIRED;
        }

        if (
          subscription.lastPaymentStatus?.status !==
          SubscriptionPaymentStatus.SUCCESS
        ) {
          return SubscriptionState.AUTO_DOWNGRADED;
        }

        return SubscriptionState.PLAN_CANCELLED;
    }
  }

  if (
    subscription.status === SubscriptionStatus.PAST_DUE &&
    [
      SubscriptionPlan.TEAMS,
      SubscriptionPlan.PROFESSIONAL,
      SubscriptionPlan.LEGACY_PRO,
      SubscriptionPlan.LEGACY_ADV,
    ].includes(plan)
  ) {
    return SubscriptionState.PAYMENT_FAILED;
  }

  return SubscriptionState.PAYMENT_FAILED;
};

export const getHeaderWidgetClickSegmentEvent = (type: HeaderWidgetType) => {
  switch (type) {
    case HeaderWidgetType.LOW_CREDITS:
      return HeaderWidgetEvt.LowCredits;
    case HeaderWidgetType.PAYMENT_FAILED:
      return HeaderWidgetEvt.PaymentFailed;
    case HeaderWidgetType.BUY_PLAN:
      return HeaderWidgetEvt.BuyPlan;
    case HeaderWidgetType.TRIAL_OVER:
      return HeaderWidgetEvt.TrailOver;
    case HeaderWidgetType.TRIAL_COUNTDOWN:
      return HeaderWidgetEvt.TrailOngoing;
  }
};

export const getHeaderWidgetActionClickSegmentEvent = (
  type: HeaderWidgetType
) => {
  switch (type) {
    case HeaderWidgetType.LOW_CREDITS:
      return HeaderWidgetEvt.AddCredits;
    case HeaderWidgetType.PAYMENT_FAILED:
      return HeaderWidgetEvt.UpdatePayment;
    case HeaderWidgetType.TRIAL_OVER:
      return HeaderWidgetEvt.Upgrade;
  }
};

export const getSubscriptionWidgetCurrentPlanActionSegmentEvent = (
  description: string
) => {
  switch (description) {
    case PlanChangeDescription.EnterpriseRequestCallback:
    case PlanChangeDescription.TeamsRequestCallback:
    case PlanChangeDescription.RequestAdminCallbackTeamsToEnterprise:
      return SubscriptionModalEvt.RequestCallback;

    case PlanChangeDescription.RequestAdminTrialExpiredToProfessional:
    case PlanChangeDescription.RequestAdminTrialToTeams:
    case PlanChangeDescription.RequestAdminProfessionalToTeams:
      return SubscriptionModalEvt.UpgradePlan;
  }
};
