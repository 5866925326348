import React from 'react';
import './style.less';
import ResultNotFoundImg from '../../assets/resultnotfound.png';

const NoResultFound = () => {
  return (
    <div className='result-not-found'>
      <img
        src={ResultNotFoundImg}
        className='new-queue-image'
        alt='new queue '
      />
      <h3>No Results Found</h3>
      <p>
        We could not find your order in any queue. Kindly check it in “My
        Properties” Dashboard.
      </p>
    </div>
  );
};

export default NoResultFound;
