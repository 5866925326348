import React from 'react';

const LassoSplit = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clip-path='url(#clip0_3687_6465)'>
      <g clip-path='url(#clip1_3687_6465)'>
        <path
          d='M17.0787 4.2199C17.11 4.16339 17.1297 4.10186 17.1368 4.0388C17.1438 3.97575 17.1381 3.91242 17.12 3.85242C17.1019 3.79241 17.0716 3.73692 17.031 3.68911C16.9904 3.64129 16.9403 3.60209 16.8834 3.57374C16.7685 3.51648 16.6333 3.5069 16.5075 3.5471C16.3817 3.58731 16.2756 3.674 16.2126 3.78812L12.4499 10.6198L8.97556 10.4651C8.99205 9.74176 8.72918 9.04879 8.23901 8.52346C7.74884 7.99813 7.06712 7.67874 6.32886 7.62856C5.5906 7.57837 4.84965 7.80104 4.25274 8.25248C3.65584 8.70391 3.24652 9.35118 3.10584 10.0661C2.96517 10.781 3.10341 11.5114 3.49318 12.1127C3.88294 12.714 4.49582 13.1422 5.21042 13.3127C5.92501 13.4831 6.68922 13.3833 7.35168 13.033C8.01415 12.6827 8.52656 12.1075 8.78743 11.4212L11.9305 11.5607L10.4155 14.3099C9.69155 14.1756 8.93214 14.3132 8.28769 14.6954C7.64323 15.0776 7.16074 15.6766 6.93577 16.3736C6.71079 17.0707 6.75974 17.815 7.07292 18.4592C7.3861 19.1034 7.94067 19.6005 8.62679 19.852C9.31292 20.1035 10.0806 20.0911 10.7777 19.8172C11.4748 19.5434 12.0506 19.028 12.391 18.3733C12.7314 17.7185 12.8115 16.9721 12.6156 16.2818C12.4197 15.5916 11.962 15.0078 11.3331 14.6462L17.0796 4.21941L17.0787 4.2199ZM8.02089 18.1199C7.77254 17.6798 7.71753 17.1552 7.86796 16.6613C8.01839 16.1674 8.36194 15.7448 8.82303 15.4863C9.28413 15.2279 9.82499 15.1548 10.3266 15.2832C10.8283 15.4116 11.2496 15.731 11.498 16.171C11.7464 16.611 11.8014 17.1357 11.6509 17.6296C11.5005 18.1235 11.157 18.5461 10.6959 18.8045C10.2348 19.063 9.6939 19.136 9.19225 19.0076C8.69059 18.8792 8.26925 18.5599 8.02089 18.1199ZM5.07739 8.84992C5.53848 8.59148 6.07935 8.51842 6.581 8.64682C7.08265 8.77522 7.504 9.09456 7.75235 9.53458C8.00071 9.9746 8.05572 10.4993 7.90529 10.9932C7.75485 11.487 7.4113 11.9097 6.95021 12.1681C6.48912 12.4266 5.94825 12.4996 5.4466 12.3712C4.94495 12.2428 4.5236 11.9235 4.27525 11.4835C4.0269 11.0434 3.97188 10.5188 4.12232 10.0249C4.27275 9.531 4.6163 9.10835 5.07739 8.84992Z'
          fill='#333333'
        />
        <path
          d='M14.0682 11.694L14.5853 10.7533L20.857 11.0303C20.9829 11.0411 21.0978 11.0992 21.1777 11.1924C21.2577 11.2856 21.2965 11.4067 21.2862 11.5304C21.2758 11.6542 21.217 11.7711 21.1221 11.8566C21.0272 11.9421 20.9035 11.9897 20.7768 11.9895L14.0673 11.6944L14.0682 11.694Z'
          fill='#333333'
        />
        <path
          d='M18.1995 13.4223C18.7925 13.285 19.4099 13.285 20.0029 13.4223C20.0662 13.4369 20.1211 13.4757 20.1553 13.5301C20.1896 13.5845 20.2005 13.65 20.1856 13.7123C20.1707 13.7746 20.1313 13.8285 20.0759 13.8622C20.0206 13.8959 19.9539 13.9066 19.8905 13.892C19.3714 13.7718 18.831 13.7718 18.3118 13.892C18.2805 13.8992 18.248 13.9003 18.2161 13.8952C18.1843 13.8901 18.1539 13.8789 18.1265 13.8622C18.0991 13.8455 18.0753 13.8237 18.0565 13.798C18.0376 13.7723 18.0241 13.7432 18.0168 13.7123C18.0094 13.6815 18.0083 13.6495 18.0135 13.6182C18.0187 13.587 18.0301 13.557 18.047 13.5301C18.064 13.5032 18.0862 13.4798 18.1124 13.4613C18.1385 13.4428 18.1681 13.4295 18.1995 13.4223Z'
          fill='#333333'
        />
        <path
          d='M17.3747 13.9735C17.3917 14.0005 17.4031 14.0304 17.4084 14.0617C17.4136 14.093 17.4124 14.125 17.4051 14.1559C17.3977 14.1867 17.3842 14.2158 17.3653 14.2416C17.3465 14.2673 17.3227 14.2891 17.2952 14.3058C16.8423 14.5818 16.4605 14.9571 16.1797 15.4024C16.1632 15.4303 16.1412 15.4546 16.115 15.474C16.0888 15.4934 16.0589 15.5075 16.0271 15.5153C15.9953 15.5232 15.9622 15.5246 15.9298 15.5197C15.8974 15.5147 15.8663 15.5034 15.8384 15.4865C15.8105 15.4695 15.7863 15.4472 15.7674 15.4209C15.7484 15.3946 15.735 15.3648 15.7279 15.3333C15.7209 15.3018 15.7203 15.2693 15.7263 15.2376C15.7322 15.2059 15.7446 15.1757 15.7627 15.1487C16.0834 14.6398 16.5196 14.2108 17.0372 13.8954C17.0925 13.8618 17.1591 13.8511 17.2224 13.8658C17.2857 13.8804 17.3405 13.9192 17.3747 13.9735Z'
          fill='#333333'
        />
        <path
          d='M20.8274 13.9735C20.8616 13.9192 20.9164 13.8804 20.9797 13.8658C21.043 13.8511 21.1096 13.8618 21.1649 13.8954C21.6827 14.2108 22.1191 14.6398 22.4399 15.1487C22.4719 15.203 22.4812 15.2674 22.4657 15.3283C22.4501 15.3892 22.4111 15.4417 22.3568 15.4747C22.3025 15.5077 22.2373 15.5186 22.1749 15.5051C22.1126 15.4916 22.0581 15.4548 22.0229 15.4024C21.742 14.9571 21.36 14.5818 20.9069 14.3058C20.8794 14.2891 20.8556 14.2673 20.8368 14.2416C20.8179 14.2158 20.8044 14.1867 20.797 14.1559C20.7897 14.125 20.7885 14.093 20.7937 14.0617C20.799 14.0304 20.8104 14.0005 20.8274 13.9735Z'
          fill='#333333'
        />
        <path
          d='M15.5753 16.1119C15.6067 16.1191 15.6363 16.1323 15.6625 16.1508C15.6887 16.1692 15.7109 16.1926 15.7279 16.2195C15.7449 16.2464 15.7563 16.2764 15.7615 16.3076C15.7668 16.3389 15.7657 16.3708 15.7583 16.4017C15.6361 16.912 15.6361 17.4432 15.7583 17.9535C15.7732 18.0158 15.7623 18.0813 15.728 18.1357C15.6938 18.1901 15.6389 18.2289 15.5756 18.2436C15.5122 18.2582 15.4455 18.2475 15.3902 18.2138C15.3348 18.1801 15.2954 18.1262 15.2805 18.0639C15.1409 17.481 15.1409 16.8742 15.2805 16.2912C15.2954 16.229 15.3348 16.1752 15.3901 16.1416C15.4454 16.1079 15.512 16.0972 15.5753 16.1119Z'
          fill='#333333'
        />
        <path
          d='M22.627 16.1118C22.6902 16.0973 22.7567 16.108 22.8118 16.1417C22.867 16.1753 22.9064 16.229 22.9213 16.2911C23.0608 16.8741 23.0608 17.4809 22.9213 18.0638C22.9049 18.1245 22.8651 18.1764 22.8103 18.2087C22.7556 18.2409 22.6902 18.2509 22.6281 18.2366C22.5659 18.2222 22.5119 18.1846 22.4776 18.1317C22.4432 18.0788 22.4311 18.0149 22.444 17.9534C22.5662 17.4431 22.5662 16.9119 22.444 16.4016C22.4366 16.3707 22.4355 16.3388 22.4407 16.3075C22.446 16.2763 22.4574 16.2463 22.4744 16.2194C22.4914 16.1925 22.5136 16.1691 22.5398 16.1506C22.566 16.1322 22.5956 16.119 22.627 16.1118Z'
          fill='#333333'
        />
        <path
          d='M15.8417 18.8751C15.8691 18.8584 15.8996 18.8472 15.9314 18.842C15.9633 18.8369 15.9958 18.838 16.0272 18.8453C16.0586 18.8525 16.0882 18.8658 16.1144 18.8843C16.1406 18.9028 16.1628 18.9263 16.1797 18.9532C16.4605 19.3987 16.8423 19.7741 17.2953 20.0503C17.3237 20.0665 17.3485 20.0881 17.3682 20.1139C17.3879 20.1396 17.4022 20.169 17.4102 20.2003C17.4182 20.2316 17.4197 20.2641 17.4146 20.296C17.4096 20.3278 17.3981 20.3584 17.3808 20.3858C17.3636 20.4132 17.3409 20.4369 17.3141 20.4556C17.2874 20.4742 17.2571 20.4874 17.225 20.4944C17.193 20.5013 17.1599 20.5019 17.1276 20.496C17.0954 20.4901 17.0646 20.478 17.0372 20.4602C16.5195 20.1448 16.0831 19.7158 15.7622 19.2069C15.728 19.1525 15.7172 19.087 15.7321 19.0248C15.747 18.9626 15.7864 18.9088 15.8417 18.8751Z'
          fill='#333333'
        />
        <path
          d='M22.5029 19.226C22.5198 19.1985 22.5309 19.1679 22.5356 19.1361C22.5403 19.1043 22.5385 19.0719 22.5303 19.0408C22.522 19.0097 22.5075 18.9805 22.4877 18.955C22.4678 18.9294 22.443 18.9081 22.4146 18.8921C22.3862 18.8762 22.3548 18.866 22.3224 18.8621C22.2899 18.8582 22.257 18.8608 22.2255 18.8696C22.1941 18.8784 22.1648 18.8934 22.1393 18.9135C22.1138 18.9336 22.0926 18.9585 22.0771 18.9868L22.0761 18.9888L22.0692 18.9998C22.02 19.0769 21.9673 19.1517 21.9113 19.2241C21.7446 19.4383 21.5549 19.6342 21.3456 19.8086C20.8472 19.4266 20.1805 19.1064 19.3465 19.1064C18.6004 19.1064 18.1265 19.5404 18.1265 20.0709C18.1265 20.6013 18.6004 21.0354 19.3465 21.0354C20.1673 21.0354 20.8271 20.7605 21.325 20.4287L21.3809 20.4822C21.6324 20.7311 21.8508 21.0103 22.031 21.3131C22.0452 21.3372 22.0555 21.3565 22.0619 21.3681L22.0692 21.3816L22.0707 21.3845C22.085 21.413 22.1049 21.4385 22.1292 21.4595C22.1536 21.4805 22.1819 21.4965 22.2126 21.5067C22.2433 21.5168 22.2757 21.5209 22.308 21.5187C22.3403 21.5164 22.3718 21.508 22.4007 21.4937C22.4297 21.4794 22.4555 21.4597 22.4766 21.4356C22.4977 21.4115 22.5138 21.3835 22.5239 21.3533C22.534 21.3231 22.5379 21.2912 22.5354 21.2594C22.5329 21.2277 22.524 21.1968 22.5093 21.1684C22.5093 21.1684 22.4455 21.0551 22.5088 21.1675L22.5078 21.166L22.5049 21.1602C22.4897 21.131 22.4737 21.1022 22.4568 21.0739C22.2549 20.7338 22.0098 20.4204 21.7273 20.1413L21.717 20.1307C21.9765 19.9074 22.1708 19.6841 22.3008 19.5163C22.3714 19.4254 22.4373 19.3311 22.4985 19.2337L22.5014 19.2289C22.5019 19.2279 22.5024 19.227 22.5029 19.226ZM19.3465 19.5887C19.9843 19.5887 20.5121 19.8119 20.9306 20.1056C20.456 20.3986 19.9069 20.5537 19.3465 20.5531C18.7932 20.5531 18.617 20.2638 18.617 20.0709C18.617 19.878 18.7932 19.5887 19.3465 19.5887Z'
          fill='#333333'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_3687_6465'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
      <clipPath id='clip1_3687_6465'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default LassoSplit;
