import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import useAuth from '@/modules/auth/hooks';
import AccessWall from '@project/components/AccessWall';
import Navbar from '../../containers/navbar/Navbar';
import { useRole } from '@/modules/auth/guards/RoleGuard';
import Fallback from '@elements/fallback/Fallback';
import { UserRole } from '@/modules/common/types';
import { RouteComponentProps } from 'react-router';

interface PrivateRouteProps extends RouteProps {
  showNavbar?: boolean;

  /** List of {@link UserRole} for which we don't want to show the component */
  except?: UserRole[] | UserRole;
}

const PrivateRoute = ({
  showNavbar,
  except,
  component: Component,
  render,
  ...rest
}: PrivateRouteProps) => {
  const { authenticated, user } = useAuth();
  const { role } = useRole();
  const history = useHistory();

  const RenderComponent = (props: RouteComponentProps) => {
    if (!authenticated) {
      const currentPath = window.location.pathname + window.location.search;

      let loginUrl = '/auth';

      if (currentPath && currentPath !== '/') {
        if (currentPath.includes('redirect=')) {
          /** We already have redirection param present, So rather than passing currentPath we need to pass the value of `redirect` */
          loginUrl += window.location.search;
        } else {
          loginUrl += `?redirect=${encodeURI(window.location.href)}`;
        }
      }

      history.push(loginUrl);
      return null;
    }

    if ((authenticated && !user) || !role) {
      /** If User is authenticated but user value is not available, means user details API is in progress */
      return <Fallback />;
    }

    if (typeof except !== 'undefined') {
      const exceptRoles = typeof except === 'string' ? [except] : except;

      if (exceptRoles.includes(role)) {
        return <AccessWall />;
      }
    }

    if (Component) {
      return (
        <>
          {showNavbar && <Navbar />}
          <Component
            {...props}
            key={
              (props.location.pathname === '/createOrder' &&
                props.location.key) ||
              undefined
            }
          />
        </>
      );
    }

    if (render) {
      return (
        <>
          {showNavbar && <Navbar />}
          {render(props)}
        </>
      );
    }

    return null;
  };

  return <Route {...rest} render={RenderComponent} />;
};

PrivateRoute.defaultProps = {
  showNavbar: true,
};

export default PrivateRoute;
