// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Subtract8IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Subtract8Icon(props: Subtract8IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 11 8"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M0 7.894h7.852a2.257 2.257 0 002.257-2.258A5.644 5.644 0 004.761 0C4.33 2.703 3.001 5.858 0 7.894z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Subtract8Icon;
/* prettier-ignore-end */
