import React from 'react';
import { Row } from 'antd';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { NOTIFICATIONS_TYPES } from './types';

import { ReactComponent as Success } from '../assets/notification-toast/success.svg';
import { ReactComponent as Error } from '../assets/notification-toast/error.svg';
import { ReactComponent as Warning } from '../assets/notification-toast/warning.svg';

interface NotificationToastContainerProps {
  type: NOTIFICATIONS_TYPES;
  content: string;
}

const NotificationToastContainer = ({
  type,
  content,
}: NotificationToastContainerProps) => {
  const getToastIcon = () => {
    switch (type) {
      case NOTIFICATIONS_TYPES.SUCCESS:
        return <Success />;
      case NOTIFICATIONS_TYPES.ERROR:
        return <Error />;
      default:
        return <Warning />;
    }
  };

  return (
    <Box className={`toast-container ${type}-toast`}>
      <Row
        align='top'
        className='w-100 h-100 mx-4 py-2'
        style={{ flexWrap: 'nowrap' }}
      >
        <Row align='middle' className='mr-3'>
          {getToastIcon()}
        </Row>
        <Typography variant='body.sm' fontWeight={500} align='left' lineHeight='20px'>
          {content}
        </Typography>
      </Row>
    </Box>
  );
};

export default NotificationToastContainer;
