import {
  FEATURE_CREATION,
  NEW_ATTRIBUTES_AVAILABLE_TO_LINK,
  SET_ALL_ATTRIBUTES,
  SET_ALL_DELETED_ATTRIBUTES,
  SET_ALL_SERVICE_ITEMS,
  SET_ALL_SERVICES,
  SET_ATTRIBUTE_FEATURE_MULTI_POPUP_DATA,
  SET_ATTRIBUTE_FEATURE_POPUP_DATA,
  SET_ATTRIBUTE_FEATURE_SINGLE_POPUP_DATA,
  SET_CURRENT_LAYER,
  SET_FEATURE_TO_VISBLE,
  SET_LINK_NEW_ATTRIBUTE_MODAL,
  SET_NEW_ATTRIBUTES,
  SET_VISIBLE_ATTRIBUTE_MODAL,
  SET_VISIBLE_ATTRIBUTE_PANEL
} from "./action_types";

const initialState = {
  featureFormValues: null,
  attributePopupData: null,
  attributeSinglePopupData: null,
  attributeMultiPopupData: null,
  attributeModalVisible: false,
  attributes: null,
  newAttributes: null,
  updatedAttributes: null,
  featureToVisible: null,
  isAttributeFilterPanelVisible: false,
  currentLayer: null,
  serviceItemsList: [],
  servicesList: [],
};

const featureReducer = (state = initialState, action) => {
  switch (action.type) {
    case FEATURE_CREATION.SET_FEATURE_CREATION_FORM_VALUES:
      return {
        ...state,
        featureFormValues: action.payload,
      };
    case SET_ATTRIBUTE_FEATURE_POPUP_DATA:
      return {
        ...state,
        attributePopupData: action.payload,
        attributeSinglePopupData: null,
        attributeMultiPopupData: null,
      };
    case SET_VISIBLE_ATTRIBUTE_MODAL:
      return {
        ...state,
        attributeModalVisible: action.payload,
      };
    case SET_ATTRIBUTE_FEATURE_SINGLE_POPUP_DATA:
      return {
        ...state,
        attributeSinglePopupData: action.payload,
        attributePopupData: null,
        attributeMultiPopupData: null,
      };
    case SET_ATTRIBUTE_FEATURE_MULTI_POPUP_DATA:
      return {
        ...state,
        attributeMultiPopupData: action.payload,
        attributeSinglePopupData: null,
        attributePopupData: null,
      };
    case SET_ALL_ATTRIBUTES:
      return {
        ...state,
        attributes: action.payload,
      };
    case SET_ALL_DELETED_ATTRIBUTES:
      return {
        ...state,
        withDeletedAttributes: action.payload,
      };
    case SET_ALL_SERVICE_ITEMS:
      return {
        ...state,
        serviceItemsList: action.payload,
      };
    case SET_ALL_SERVICES:
      return {
        ...state,
        servicesList: action.payload,
      };
    case SET_NEW_ATTRIBUTES:
      return {
        ...state,
        newAttributes: action.payload,
      };
    case SET_VISIBLE_ATTRIBUTE_PANEL:
      return {
        ...state,
        isAttributeFilterPanelVisible: action.payload,
      };
    case SET_LINK_NEW_ATTRIBUTE_MODAL:
      return {
        ...state,
        isLinkNewAttributeModalVisible: action.payload,
      };
    case NEW_ATTRIBUTES_AVAILABLE_TO_LINK:
      return {
        ...state,
        isNewAttributeAvailableToLink: action.payload,
      };
    case SET_FEATURE_TO_VISBLE:
      return {
        ...state,
        featureToVisible: action.payload,
      };
    case SET_CURRENT_LAYER:
      return {
        ...state,
        currentLayer: action.payload,
      };
    default:
      return state;
  }
};

export default featureReducer;
