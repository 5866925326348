import { updateImagery } from '../bulkOrder/actions';
import { APPROVED_MAINTAINER } from '../../helpers/constants/orderData';
import * as API_ENDPOINTS from '../../helpers/constants/APIEndpoints';

import {
  createOrderFailure,
  createOrderRequest,
  createOrderSuccess,
  featureList,
  getNearMapInfo,
  setOrderViews,
  snapLoader,
  updateMapTools,
  updateOrderFailure,
  updateOrderRequest,
  updateOrderSuccess,
} from './actions';
import {
  getAxiosInstance,
  getCancelToken,
} from '@/helpers/utilities/api-utils';
import axios from 'axios';
import { getOrderViews } from '@/components/api/project';

const axiosInstance = getAxiosInstance();
const CancelToken = getCancelToken();
let cancel;

export const createOrderThunk = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(createOrderRequest());
    var bodyFormData = new FormData();
    bodyFormData.append('userId', payload.userId);
    bodyFormData.append('orgId', payload.orgId);
    bodyFormData.append('address', payload.address);
    bodyFormData.append('latitude', payload.lat);
    bodyFormData.append('longitude', payload.lng);
    bodyFormData.append('postalCode', payload.postalCode);
    bodyFormData.append('mapService', payload.mapService);

    axiosInstance
      .post(API_ENDPOINTS.SAVE_ORDER, bodyFormData)
      .then(async (response) => {
        if (response.status === 200) {
          const data = response.data;

          if (data.status === 200) {
            dispatch(createOrderSuccess(data));
            resolve();
          } else {
            if (data.error !== 'Nearmap access limit exceeded') {
              // NotifyError(data.error + ' Please move the blue pin to the center of the property');
              await dispatch(createOrderSuccess(data));
            }

            reject(data);
          }
        }
      })
      .catch((err) => {
        reject(err);
        dispatch(createOrderFailure(err));
      });
  });
};

export const updateOrderThunk = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(updateOrderRequest());
    dispatch(snapLoader(true));

    axiosInstance
      .post('/api/order/updateOrderDetails/', payload)
      .then((res) => {
        const result = JSON.parse(JSON.stringify(res.data));
        if (result.status === 200) {
          dispatch(updateOrderSuccess(result));
          dispatch(snapLoader(false));
          resolve(result);
        } else {
          dispatch(updateOrderFailure(result.error));
          dispatch(snapLoader(false));
          reject(result);
        }
      })
      .catch((err) => {
        dispatch(updateOrderFailure(err));
        dispatch(snapLoader(false));
        reject();
        console.error(err);
      });
  });
};

export const updateBulkOrderParcel = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const { bulkOrderId, orderId } = payload.query;
    let featureIdArray = [];
    let featureNameArray;
    let bulkOrderParcelData;
    const response = await getAxiosInstance().get(API_ENDPOINTS.FEATURES, {
      params: {
        maintainer: APPROVED_MAINTAINER,
        sendUserDetails: true,
      },
    });
    const allFeatureData = response.data.data;
    const payloadFeatures = payload?.bodyFormData.features;

    if (payloadFeatures) {
      featureNameArray =
        typeof payloadFeatures === 'string'
          ? JSON.parse(payloadFeatures)
          : payloadFeatures;

      featureNameArray.map((featureName) => {
        allFeatureData.map((feature) => {
          if (featureName === feature.name)
            featureIdArray.push(feature.featureId);
        });
      });
    }

    delete payload.bodyFormData.features;

    if (payloadFeatures) {
      bulkOrderParcelData = {
        ...payload.bodyFormData,
        features: featureNameArray,
        featureIds: featureIdArray,
      };
    } else {
      bulkOrderParcelData = {
        ...payload.bodyFormData,
      };
    }

    dispatch(updateOrderRequest());
    dispatch(snapLoader(true));

    axiosInstance
      .put(
        `/api/bulk/v1/border/${bulkOrderId}/corder/${orderId}`,
        bulkOrderParcelData
      )
      .then((res) => {
        const result = JSON.parse(JSON.stringify(res.data));
        dispatch(updateOrderSuccess(result));
        dispatch(snapLoader(false));
        dispatch(
          updateImagery({
            orderId: orderId,
            parcelData: {
              ...result,
            },
          })
        );
        resolve();
      })
      .catch((err) => {
        dispatch(updateOrderFailure(err));
        dispatch(snapLoader(false));
        reject();
        console.error(err);
      });
  });
};

export const updateNearMapImagery = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const { bulkOrderId, orderId } = payload.query;
    const body = payload?.bodyFormData;

    axiosInstance
      .put(
        `/api/bulk/v1/border/${bulkOrderId}/corder/${orderId}`,
        body,
        new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      )
      .then((res) => {
        resolve();
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else {
          dispatch(updateOrderFailure(err));
          dispatch(snapLoader(false));
          reject();
          console.error(err);
        }
      });
  });
};

export const cancelToken = () => () => {
  if (cancel) cancel();
  cancel = null;
};

export const getNearMapImgThunk = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(updateOrderRequest());
    var bodyFormData = new FormData();
    bodyFormData.append('latitude', payload.latitude);
    bodyFormData.append('longitude', payload.longitude);
    axiosInstance
      .post('/api/order/nearMapImageDate/', bodyFormData)
      .then((result) => {
        if (result.status === 200) {
          dispatch(getNearMapInfo(result.data));
          resolve(result.data);
        }
      })
      .catch((err) => {
        dispatch(updateOrderFailure(err));
        reject();
      });
  });
};

export const submitMapToolThunk = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(snapLoader(true));
    dispatch(updateOrderRequest());
    var bodyFormData = new FormData();
    bodyFormData.append('userJson', JSON.stringify(payload.finalParcelFeature));
    bodyFormData.append('orderId ', payload.orderId);
    bodyFormData.append('areaJson', JSON.stringify(payload.areaData));
    bodyFormData.append('id', payload.id);
    axiosInstance
      .post('/api/carto/updateUserInstance/', bodyFormData)
      .then((result) => {
        var result = JSON.parse(JSON.stringify(result.data));
        if (result.status === 200) {
          dispatch(updateMapTools());
          resolve(result.success);
        } else {
          dispatch(updateMapTools(result.error));
          reject(result.error);
        }
        dispatch(snapLoader(false));
      })
      .catch((err) => {
        dispatch(snapLoader(false));
        dispatch(updateMapTools(err));
        reject();
        console.error(err);
      });
  });
};

export const getFeatureList = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(
        '/api/templating/v1/feature?maintainer=user,siterecon,organization&status=closed,active&sendUserDetails=true'
      )
      .then((res) => {
        dispatch(featureList(res.data));
        resolve(true);
      })
      .catch((err) => {
        console.error(err);
        reject();
      });
  });
};

export const copyLayerThunk = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(snapLoader(true));
    var bodyFormData = new FormData();

    bodyFormData.append('toVersionId', payload.toVersionId);
    bodyFormData.append('fromVersionId', payload.fromVersionId);
    bodyFormData.append('featureName', payload.featureName);
    bodyFormData.append('orderId', payload.orderId);
    bodyFormData.append('replace', payload.replace);

    axiosInstance
      .post('/api/carto/cloneFeature/', bodyFormData)
      .then((res) => {
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, res.data.success);
        dispatch(snapLoader(false));
        resolve(res);
      })
      .catch((err) => {
        dispatch(snapLoader(false));
        console.error(err);
        reject(err);
      });
  });
};

export const updateViewsByOrderId = (orderId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return getOrderViews(orderId)
      .then(({ data }) => {
        dispatch(setOrderViews(data));
        resolve(data);
      })
      .catch((error) => reject(error));
  });
};