// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector144IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector144Icon(props: Vector144IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 72 45"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M12.449 36.879C-9.311 23.112 2.326 6.702 10.865.219c13.983-1.33 9.613 3.74 5.68 6.442 1.53 4.489 10.804 13.217 35.668 12.22 24.863-.998 22.016 13.153 14.42 17.998-8.995 5.736-32.423 13.766-54.184 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector144Icon;
/* prettier-ignore-end */
