// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector141IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector141Icon(props: Vector141IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 21 15"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M1 13.704c.337-.14 1.756-.81 2.066-.95a16.125 16.125 0 002.15-1.168c.663-.646 1.405-1.87 1.62-2.57C6.811 8.742 8.883 4.5 8.883 4.5 7.877 4.229 3.95 5.84 3.95 5.84l.235 1.769c.988.348.89.376 1.314.436 2.414.474 4.486.016 6.082-.563 1.223-.445 2.002-1.095 2.824-2.075.904-1.076.757-2.276 1.852-3.163.956-.773 2.06-1.244 3.28-1.244"
        }
        stroke={"currentColor"}
        strokeWidth={"1.172"}
        strokeLinecap={"round"}
      ></path>
    </svg>
  );
}

export default Vector141Icon;
/* prettier-ignore-end */
