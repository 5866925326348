// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon56IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon56Icon(props: Icon56IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g
        clipPath={"url(#dFFEPcv39Ur9a)"}
        stroke={"#CCC"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      >
        <path
          d={
            "M12 4.5L4.5 8.25 12 12l7.5-3.75L12 4.5zM4.5 15.75L12 19.5l7.5-3.75M4.5 12l7.5 3.75L19.5 12"
          }
        ></path>
      </g>

      <defs>
        <clipPath id={"dFFEPcv39Ur9a"}>
          <path
            fill={"#fff"}
            transform={"translate(3 3)"}
            d={"M0 0h18v18H0z"}
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon56Icon;
/* prettier-ignore-end */
