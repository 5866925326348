import { axiosInstance } from "../../../feed/api";
import { getToken } from '@/helpers/auth';
import { getSupabaseApiKey } from "../../utils/helpers";

export const deactivateEstimationSheet = async (
  estViewIdInput: number,
  propertyId: number,
): Promise<void> => {
  const { apiKey, supabaseProjectKey } = getSupabaseApiKey();

  try {
    await axiosInstance.post(
      `https://${supabaseProjectKey}.supabase.co/rest/v1/rpc/inactivate_estimation_sheet`,
      {
        est_view_id_input: estViewIdInput,
        property_id: propertyId,
        token: 'Token ' + getToken(),
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'apikey': apiKey,
          'Authorization': `Bearer ${apiKey}`,
        },
      }
    );
  } catch (error) {
    console.error('Error inactivating estimation sheet:', error);
  }
};
