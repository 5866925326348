/** Returns element visibility as boolean and position/dimensions as number */

import { useState, useEffect } from 'react';
import { OrderStatus } from '@/modules/common/types';
import {
  PADDING_16,
  actionCenterP,
  estimationBreakdownTabP,
  estimationViewTabsWidthP,
  layerPanelEstimationP,
  layerPanelStaticP,
  mapControlsP,
  mapToolBarEstimationP,
  mapToolBarP,
  nearmapDropdownP,
  notesReportsSnapsPanelP,
  orderDetailsPanelP,
  primaryPanelCollapsedLeftP,
  tertiaryPanelP,
  viewPanelP,
  widgetBarP,
} from '../helpers/position.constant';
import { ELEMENT_DEFAULT_VISIBILITY } from '../helpers/visibility.constant';
import { ElementName, State } from '../types/element-position-visibility.types';
import useViewType from '../../project/guards/ViewGuard/useViewType';
import { BetaFeature, ViewType } from '../../common/types';
import { cloneDeep } from 'lodash';
import { useOrderStatus } from '../../project/guards/OrderStatus';
import { useSelector } from 'react-redux';
import { useBetaRelease } from '@/modules/common/guards/BetaRelease';

const { actionCenterV, mapControlsV, mapToolBarV, nearmapDropdownV, orderDetailsPanelV, notesReportsSnapsPanelV } =
  ELEMENT_DEFAULT_VISIBILITY;

const initialState = {
  [ElementName.MapControlsPosition]: {
    visible: mapControlsV,
    ...mapControlsP,
  },
  [ElementName.NearmapDropdownPosition]: {
    visible: nearmapDropdownV,
    ...nearmapDropdownP,
  },
  [ElementName.ActionCenterPosition]: {
    visible: actionCenterV,
    ...actionCenterP,
  },
  [ElementName.MapToolBarPosition]: {
    visible: mapToolBarV,
    ...mapToolBarP,
  },
  [ElementName.OrderDetailsPanelPosition]: {
    visible: orderDetailsPanelV,
    ...orderDetailsPanelP,
  },
  [ElementName.NotesReportsSnapsPanelPosition]: {
    visible: notesReportsSnapsPanelV,
    ...notesReportsSnapsPanelP,
  },
};

const useElementPositioningAndVisibility = (
  normalLayerPanelOpen: boolean,
    orderDetailsPanelOpen: boolean,
  estimationViewServiceMapTabOpen: boolean,
  estimationViewEstimationTabOpen: boolean,
  estimationTabBreakdownTabOpen: boolean,
  estimationTabInvoiceTabOpen: boolean,
  notesReportsSnapsPanelOpen: boolean,
  isViewPinned: boolean,
  tertiaryPanelOpen: boolean,
  allPanelsVisibility: boolean
): State => {
  const [state, setState] = useState<State>(initialState);

  const currentViewType = useViewType();
  const orderStatus = useOrderStatus();

  const attributePanelVisible = useSelector((state) => state.order.attributePanelVisible);
  const isEstimationsEnabled = useBetaRelease(BetaFeature.Estimations);
  

  useEffect(() => {
    const calculatePositionsAndVisibility = () => {
      let updatedState = { ...state };

      Object.keys(updatedState).forEach((element) => {
        /** Set visibility to initial */
        if (initialState[element]) {
          updatedState[element].visible = initialState[element].visible;
        }
      });

      /** Handling static view */
      if (currentViewType === ViewType.STATIC) {
        /** Adjust positions based on the state of panels and tabs */
        updatedState[ElementName.NearmapDropdownPosition].position.left = widgetBarP.width + PADDING_16;
        updatedState[ElementName.MapToolBarPosition].position.left = primaryPanelCollapsedLeftP.mapToolBar;

        if (normalLayerPanelOpen) {          
          updatedState[ElementName.NearmapDropdownPosition].position.left = (orderStatus === OrderStatus.Approved ? widgetBarP.width : 0) + layerPanelStaticP.width + PADDING_16;
          // updatedState[ElementName.MapToolBarPosition].position.left = mapToolBarP.position.left;
          updatedState[ElementName.MapToolBarPosition].position.left = widgetBarP.width + layerPanelStaticP.width;

          if (attributePanelVisible) {
            updatedState[ElementName.NearmapDropdownPosition].position.left = widgetBarP.width + layerPanelStaticP.width + tertiaryPanelP.width + PADDING_16;
            updatedState[ElementName.MapToolBarPosition].position.left = widgetBarP.width + layerPanelStaticP.width + tertiaryPanelP.width;
          }
        }
        if (orderDetailsPanelOpen) {
          updatedState[ElementName.OrderDetailsPanelPosition].visible = true;
          updatedState[ElementName.MapToolBarPosition].position.left = 336;
          updatedState[ElementName.NearmapDropdownPosition].position.left = widgetBarP.width + orderDetailsPanelP.width + PADDING_16;
        }
        else {
          
        }
      } 
      
      if (currentViewType === ViewType.DYNAMIC || currentViewType === ViewType.ESTIMATION) {
        /** Handling dynamic/estimation view */
        if (estimationViewServiceMapTabOpen) {
          updatedState[ElementName.NearmapDropdownPosition].visible = true;
          updatedState[ElementName.NearmapDropdownPosition].position.bottom = nearmapDropdownP.position.bottom;
          updatedState[ElementName.NearmapDropdownPosition].position.left = widgetBarP.width+  estimationViewTabsWidthP.serviceMap + PADDING_16;
          updatedState[ElementName.MapToolBarPosition].position.left = widgetBarP.width;
        }

        if (estimationViewEstimationTabOpen || estimationTabBreakdownTabOpen) {
          updatedState[ElementName.NearmapDropdownPosition].visible = false;
          updatedState[ElementName.MapToolBarPosition].position.left = estimationBreakdownTabP.width;
        }

        if (estimationTabInvoiceTabOpen) {
          updatedState[ElementName.NearmapDropdownPosition].visible = false;
          updatedState[ElementName.ActionCenterPosition].visible = false;
          updatedState[ElementName.MapControlsPosition].visible = false;

          if(!isEstimationsEnabled) {
            updatedState[ElementName.MapControlsPosition].visible = false;
          }
        }

        if (estimationViewEstimationTabOpen || estimationTabBreakdownTabOpen || estimationTabInvoiceTabOpen)
         {
          updatedState[ElementName.MapToolBarPosition].visible = false;
          // updatedState[ElementName.MapControlsPosition].visible = false;
          updatedState[ElementName.NearmapDropdownPosition].visible = false;
        }

        if (estimationViewEstimationTabOpen && isViewPinned) {
          // updatedState[ElementName.ActionCenterPosition].visible = false;
        }

        // when none are open
        if (!estimationViewServiceMapTabOpen && !estimationViewEstimationTabOpen && !estimationTabBreakdownTabOpen && !estimationTabInvoiceTabOpen) {
          updatedState[ElementName.NearmapDropdownPosition].position.left = widgetBarP.width + PADDING_16;
          updatedState[ElementName.MapToolBarPosition].position.left = primaryPanelCollapsedLeftP.mapToolBar;
        }
      }
      
      /** HANDLE CASE OF NOTES / REPORTS / SNAPS PANEL */
      if(notesReportsSnapsPanelOpen) {
        updatedState[ElementName.NearmapDropdownPosition].position.left = widgetBarP.width + notesReportsSnapsPanelP.width + PADDING_16;
        updatedState[ElementName.MapToolBarPosition].position.left = widgetBarP.width + notesReportsSnapsPanelP.width;
      }

      if (isViewPinned) {
        updatedState[ElementName.NotesReportsSnapsPanelPosition].position.left = widgetBarP.width + viewPanelP.width;
        updatedState[ElementName.NearmapDropdownPosition].visible = false;
        if (updatedState[ElementName.MapToolBarPosition].position.left !== undefined) {
          updatedState[ElementName.MapToolBarPosition].position.left += viewPanelP.width;
        }
      } else {
        if (estimationViewEstimationTabOpen || estimationTabBreakdownTabOpen) {
          updatedState[ElementName.NearmapDropdownPosition].visible = false;
        } else {
          updatedState[ElementName.NearmapDropdownPosition].visible = true;
        }
        updatedState[ElementName.NotesReportsSnapsPanelPosition].position.left = widgetBarP.width;
      }

      if (!allPanelsVisibility) {
        updatedState[ElementName.MapControlsPosition].visible = false;
        updatedState[ElementName.NearmapDropdownPosition].visible = false;
        updatedState[ElementName.ActionCenterPosition].visible = false;
        updatedState[ElementName.MapToolBarPosition].visible = false;
      }

      /** To clone complete nested object instead of just top level */
      setState(cloneDeep(updatedState));
    };

    calculatePositionsAndVisibility();
  }, [
    normalLayerPanelOpen,
    estimationViewServiceMapTabOpen,
    estimationViewEstimationTabOpen,
    estimationTabBreakdownTabOpen,
    estimationTabInvoiceTabOpen,
    notesReportsSnapsPanelOpen,
    tertiaryPanelOpen,
    isViewPinned,
    attributePanelVisible
  ]);

  return state;
};

export default useElementPositioningAndVisibility;
