import { useSelector } from 'react-redux';
import { IStore } from '@/store/types';
import { ViewType } from '@/modules/common/types';

/** Returns current View Type. If view is loading returns "null" */
const useViewType = () => {
  return useSelector<IStore, ViewType | null>(
    (state) =>
      (state.order.currentViewData?.viewType as ViewType) || ViewType.STATIC
  );
};

export default useViewType;
