import Text from '@/components/elements/text/Text';
import { getName } from '../../../utils';
import { Col, Image } from 'antd';

import './style.less';

const AvatarWrapper = ({ data = {} }) => {
  return (
    <Col className='avatar-wrapper-create name-bg-color'>
      {data.avatar && data.avatar !== 'null' ? (
        <Image
          height={30}
          alt={data.title || data.workspaceName}
          preview={false}
          src={data.avatar}
        />
      ) : (
        <Text className='avatar-text'>
          {getName(data.title || data.workspaceName)}
        </Text>
      )}
    </Col>
  );
};

export default AvatarWrapper;
