import { Popover, PopoverProps } from 'antd';
import { FC, SVGProps } from 'react';
import Text from '@elements/text/Text';
import { config } from '@/modules/common/config';
import './style.less';
import React from 'react';
import { EXPLORE_SUBSCRIPTION_PLANS } from '../../../../components/subscription/helpers/urls';

export interface ChipProps
  extends Partial<Pick<PopoverProps, 'title' | 'content' | 'placement'>> {
  tooltip?: boolean;
}

interface BaseChipProps extends ChipProps {
  icon: FC<SVGProps<any>> | string;
}

const DEFAULT_TITLE = 'Premium Feature';

const DefaultContent = () => (
  <Text
    type='p30'
    color='gray-shade'
    onClick={(event: MouseEvent) => event.stopPropagation()}
  >
    This feature comes under the subscription plans.&nbsp;
    <Text
      type='p36'
      component='a'
      color='link'
      href={EXPLORE_SUBSCRIPTION_PLANS}
      target='_blank'
      className='text-underline'
    >
      Explore Plans
    </Text>
  </Text>
);

const BaseChip = ({
  title,
  content,
  placement = 'right',
  tooltip = true,
  icon: Icon,
  ...rest
}: BaseChipProps & SVGProps<any>) => {
  /** If the Subscriptions are disabled, we don't want to show any chip */
  if (!config.isSubscriptionsEnabled) {
    return null;
  }

  if (!tooltip) {
    return typeof Icon === 'string' ? <img src={Icon} /> : <Icon {...rest} />;
  }

  return (
    <Popover
      placement={placement}
      title={title || DEFAULT_TITLE}
      overlayStyle={{ zIndex: 9998 }}
      overlayClassName='badge--popover'
      content={content || <DefaultContent />}
    >
      {typeof Icon === 'string' ? (
        <img src={Icon} onClick={(event) => event.stopPropagation()} />
      ) : (
        <Icon {...rest} onClick={(event) => event.stopPropagation()}></Icon>
      )}
    </Popover>
  );
};

export default BaseChip;
