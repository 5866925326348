import React, { ReactNode, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { ModifySyncState } from '../../../../../../modules/common/types';
import ModifySyncStates from './ModifySyncStates';
import './style.less';

interface ModifyManualSyncTipProps {
  unSyncedChangedCount: number;
  lastSyncTime: string | null;
  onSave: () => void;
  onReset: () => void;
}

const ModifyManualSyncTip = ({
  unSyncedChangedCount,
  lastSyncTime,
  onSave,
  onReset,
}: ModifyManualSyncTipProps): ReactNode => {
  const modifySyncState = useSelector<ModifySyncState>(
    (state: any) => state.map.modifySyncState
  );

  return (
    <Box className='modify-manual-sync-tip'>
      {modifySyncState === ModifySyncState.SYNCED ? (
        <Box>
          <ModifySyncStates
            icon='https://fragmentor.siterecon.ai/common-assets/svg/check-circle-green.svg'
            title='Saved'
            color='#76CA66'
          />
        </Box>
      ) : (
        <Row>
          <Typography
            fontSize={10}
            lineHeight={1.5}
            fontWeight={500}
            style={{ color: '#FF000F' }}
            className='d-flex items-center'
          >
            {unSyncedChangedCount ?? 0} unsaved edits
          </Typography>
          <Typography
            fontSize={10}
            lineHeight={1.5}
            className='d-flex items-center'
          >
            &nbsp;on this view.{' '}
            {modifySyncState !== ModifySyncState.SYNCING && (
              <>Last saved {lastSyncTime}.</>
            )}
          </Typography>

          {modifySyncState === ModifySyncState.SYNCING ? (
            <ModifySyncStates
              icon='https://fragmentor.siterecon.ai/common-assets/gif/saving-loader-black.gif'
              title='Saving'
            />
          ) : (
            <Row className='modify-sync-btn p-0 ml-2' align='middle'>
              <Col className='items-center d-flex'>
                <img src='https://fragmentor.siterecon.ai/common-assets/svg/reset-orange.svg' />
                <Typography
                  fontSize={10}
                  lineHeight={'18px'}
                  fontWeight={600}
                  style={{ color: '#FFA600', textDecoration: 'underline' }}
                  onClick={onReset}
                >
                  Reset
                </Typography>
              </Col>
              <Col onClick={onSave} className='ml-4'>
                <ModifySyncStates
                  icon='https://fragmentor.siterecon.ai/common-assets/svg/refresh-black.svg'
                  title='Save Now'
                />
              </Col>
            </Row>
          )}
        </Row>
      )}
    </Box>
  );
};

export default ModifyManualSyncTip;
