import { GuardProps, UserRole } from '@/modules/common/types';
import useAuth from '@/modules/auth/hooks';
import { FC, useMemo } from 'react';
import BaseGuard from '@/modules/common/guards/BaseGuard';
import WithRole from '@/modules/auth/guards/RoleGuard/WithRole';
import useRole from '@/modules/auth/guards/RoleGuard/useRole';

interface RoleGuardProps extends GuardProps {
  /** Single or List of {@link UserRole} for which we want to display the content */
  roles?: UserRole | UserRole[];

  /** Single or List of {@link UserRole} for which we *don't* want to display the content */
  except?: UserRole | UserRole[];
}

const RoleGuard: FC<RoleGuardProps> = ({
  roles: allowedRoles,
  except: excludedRoles,
  fallback,
  children,
  or,
  ...rest
}) => {
  const { role, loading } = useAuth();

  const roleCondition = useMemo(() => {
    if (loading || !role) return false;

    if (excludedRoles) {
      if (typeof excludedRoles === 'string') {
        return role !== excludedRoles;
      }
      return !excludedRoles.includes(role);
    }

    if (allowedRoles) {
      return typeof allowedRoles === 'string'
        ? role === allowedRoles
        : allowedRoles.includes(role);
    }

    return false;
  }, [role, loading, allowedRoles, excludedRoles]);

  const canRender = useMemo(() => {
    if (typeof or !== 'undefined') {
      return roleCondition || or;
    }
    return roleCondition;
  }, [roleCondition, or]);

  return (
    <BaseGuard condition={canRender} fallback={fallback} {...rest}>
      {children}
    </BaseGuard>
  );
};

RoleGuard.displayName = 'RoleGuard';

export { useRole, WithRole };

export default RoleGuard;
