// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon31IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon31Icon(props: Icon31IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <ellipse
        cx={"12"}
        cy={"15.519"}
        rx={"1"}
        ry={".999"}
        transform={"rotate(-180 12 15.52)"}
        fill={"currentColor"}
      ></ellipse>

      <ellipse
        cx={"12"}
        cy={"12.001"}
        rx={"1"}
        ry={".999"}
        transform={"rotate(-180 12 12)"}
        fill={"currentColor"}
      ></ellipse>

      <ellipse
        cx={"12"}
        cy={"8.481"}
        rx={"1"}
        ry={".999"}
        transform={"rotate(-180 12 8.48)"}
        fill={"currentColor"}
      ></ellipse>
    </svg>
  );
}

export default Icon31Icon;
/* prettier-ignore-end */
