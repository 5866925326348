import { FC, useMemo } from 'react';
import { TooltipProps } from 'antd';
import { GuardProps, ViewType } from '@/modules/common/types';
import BaseGuard from '@/modules/common/guards/BaseGuard';
import { WrapperProps } from '@/modules/common/components/Wrapper';
import useViewType from './useViewType';
import withView from './withView';
import { ESIMATION_DISABLE_TOOLTIP } from '@/components/constants';
import './style.less';

export interface ViewGuardProps
  extends GuardProps,
    Omit<WrapperProps, 'tooltip'> {
  /** Single or List of {@link ViewType} for which we want to display the content */
  view?: ViewType | ViewType[];

  /**  Single or List of {@link ViewType} for which we *don't* want to display the content */
  except?: ViewType | ViewType[];

  tooltip?: WrapperProps['tooltip'] | boolean;
}

const ViewGuard: FC<ViewGuardProps> = ({
  view: allowedView,
  except: excludedView,
  fallback,
  children,
  tooltip,
  or,
  ...rest
}) => {
  const view = useViewType();

  const tooltipProps: TooltipProps | undefined = useMemo(() => {
    const buildProps = (props: TooltipProps) => {
      return {
        overlayClassName: 'view-guard-tooltip',
        ...props,
      };
    };

    if (typeof tooltip === 'undefined') return tooltip;

    if (typeof tooltip === 'boolean') {
      return tooltip
        ? buildProps({
            title: ESIMATION_DISABLE_TOOLTIP,
          })
        : undefined;
    }

    if (typeof tooltip === 'string') {
      return buildProps({ title: tooltip });
    }

    return buildProps(tooltip);
  }, [tooltip]);

  const accessCondition = useMemo(() => {
    if (!view) return false;

    if (excludedView) {
      if (typeof excludedView === 'string') {
        return view !== excludedView;
      }
      return !excludedView.includes(view);
    }

    if (allowedView) {
      return typeof allowedView === 'string'
        ? view === allowedView
        : allowedView.includes(view);
    }

    return false;
  }, [view, allowedView, excludedView]);

  const canRender = useMemo(() => {
    if (typeof or !== 'undefined') {
      return accessCondition || or;
    }

    return accessCondition;
  }, [accessCondition, or]);

  return (
    <BaseGuard
      condition={canRender}
      fallback={fallback}
      tooltip={tooltipProps}
      {...rest}
    >
      {children}
    </BaseGuard>
  );
};

ViewGuard.displayName = 'ViewGuard';

export { useViewType, withView };

export default ViewGuard;
