
import { FC } from 'react';
import useRouterPlus from '../../../../../modules/router-plus/hooks/use-router-plus';
import useRouterPlusRedirect from '../../../../../modules/router-plus/hooks/use-router-plus-redirect';

const useChangeUrlViewId = () => {
  const { redirect } = useRouterPlus('viewId');

  const { redirect: redirectPlus } = useRouterPlusRedirect();

  const changeViewId = (viewId: string) => {
    redirect(String(viewId), true);
    redirectPlus(
      { viewId: String(viewId) },
      {
        keepQueryParams: true,
        removeQueryParams: ['n', 'vId', 'fb', 'oid', 'sr'],
        mode: 'search',
      }
    );
  };

  return { changeViewId };
};

export const withChangeUrlViewId = (Component: any) => {
  const WrappedComponent: FC<any> = (props) => {
    const { changeViewId } = useChangeUrlViewId();

    return <Component changeViewId={changeViewId} {...props} />;
  };

  return WrappedComponent;
};

export default useChangeUrlViewId;
