import { CONFIG_WITH_ORDER_ID_API } from '@/helpers/constants/APIEndpoints';
import {
  addDefaultBaseLayers,
  createGeoJsonFromFeature,
  getLatLongClipboard,
  initMap,
} from '@/helpers/mapUtils/mapInit';
import {
  onAreaMeasurement,
  removeMeasureInteraction,
} from '@/helpers/mapUtils/tools/measurementTool';
import { NotifyError } from '@/helpers/notification-utils';
import {
  changeSearchURLParams,
  checkLayerPanelOrActionCenter,
  replaceParams,
} from '@/helpers/utilities/linkUtils';
import { Button, Row, Tooltip, Typography } from 'antd';
import 'ol/ol.css';
import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import '../../../App.less';
import * as API_ENDPOINTS from '../../../helpers/constants/APIEndpoints';
import '../project/style.less';
import {
  baseView,
  getOrderSuccess,
  rotateMapValue,
  setOrderFeatures,
  setOrderOrganisation,
  setOrderStatus,
  setParcel,
  setSharedOrder,
  setUserAccessRight,
  updateNearMapDates,
} from '@/store/order/actions';
import { globalAssetsData, toggleLayerLeftPanel } from '@/store/ui/actions';
import {
  setActiveTool,
  setMapClassnames,
  setShareBtnStatus,
  toogleMeasureTool,
} from '@/store/user/actions';
import Footer from '../../containers/footer/Footer';

import { rotateMapReset } from '@/helpers/mapUtils';
import {
  clickLayerHighlight,
  hoverLayer,
  onClickLayer,
  removeAllInteraction,
  removeClickListener,
  removeHoverListener,
} from '@/helpers/mapUtils/featuresUtils';
import { _get } from '@/helpers/utilities/lodashUtils';
import { setAttributeMultiPopupData } from '@/store/attributeFeature/actions';
import { KeyboardZoom } from 'ol/interaction';
import { transformExtent } from 'ol/proj';
import { ReactComponent as HighArrow } from '../../../assets/highlight-arrow.svg';
import { ReactComponent as HighMeasure } from '../../../assets/highMeasure.svg';
import { ReactComponent as LowArrow } from '../../../assets/lowArrow.svg';
import { ReactComponent as MeasureTool } from '../../../assets/measure.svg';
import { ReactComponent as SmallLogo } from '../../../assets/small-logo.svg';
import Keyboard from '../../../handler/Keyboard';
import {
  findLayerById,
  findLayerStyleFromComponent,
} from '../../../helpers/utilities';
import { ToolsTypes } from '../../containers/navbar/tools/toolsTypes';
import MapContext from '../project/MapContext';
import ProjectFeaturePanel from '../project/projectComponents/FeaturePanel';
import './style.less';

import {
  hoveredFeatureStyle,
  pointHoveredFeatureStyle,
  pointHoverIconFeature,
  updatePathStyleFunction,
} from '@/helpers/mapGlobals/styles';
import { ReactComponent as MapPinLogo } from '../../../assets/mapPin.svg';
import getConfigurations from '../../../store/configurations/thunk';
import BrandLogo from '../../containers/brand-logo/BrandLogo';
import Partner from '../../containers/Partner';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { AccessRight, OrderStatus } from '@/modules/common/types';
import OrderStatusGuard from '@/modules/project/guards/OrderStatus';
import OrderStatusContext from '@/modules/project/guards/OrderStatus/context';
import classNames from 'classnames';
import { ReactComponent as SitereconLogo } from '../../containers/Partner/assets/sitercon.svg';
import SRText from '../../elements/text/Text';
import {
  resetToParcel,
  toggleBasemap,
  toggleNearmap,
} from '../../../helpers/mapUtils/mapInit';
import AccessWall from '../project/components/AccessWall';
import { renderNearmapWithSurveyId } from '../../nearmap';
import { ViewOption } from '../../containers/nearmap-oblique-selection-dd/types';
import MapControls from '../project/components/MapControls';
import { setPropertyHasNearmap, showHideBasemap, showHideNearmap } from "../../../store/ui/actions";
import CoWidgetBarIntegration from '../project/projectComponents/CoWidgetBarIntegration';
import { WidgetBarTabs } from '../../types';

const { Text } = Typography;

const axiosInstance = getAxiosInstance();

class ShareOrderMap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orderStatus: null,
      orderSummary: {},
      orderBasicDetails: {},
      orderTileData: {},
      orderFeatureData: {},
      orderFeedbackData: {},
      orderViewsData: {},
      orderSupportData: {},
      isDataLoading: false,
      olMapRef: null,
      views: [],
      rasterVal: true,
      disabledBtn: false,
      disabledCheck: false,
      nearMapCheck: true,
      clickFeatureValue: null,
      hoverFeatureValue: {},
      showApprovedOrderDetails: false,
      openFPanel: window.matchMedia('(min-width: 768px)').matches
        ? true
        : false,
      isNearMap: true,
      shareApiErrorCode: null,
      fetching_imagery: false,
      multiSelectFeature: [],
      matches: window.matchMedia('(min-width: 768px)').matches,
      viewSelectionPanelVisible: false,
      layerAreaInfo: null,
    };
    this.popoverRef = createRef();
  }

  componentDidMount() {
    let mapRef = initMap();
    this.props.setMapClassnames('select'); // default selected tool
    this.props.setActiveTool('select');
    this.props.setSharedOrder(true);
    this.setState(
      {
        olMapRef: mapRef,
      },
      function () {
        addDefaultBaseLayers(mapRef);
        getLatLongClipboard(mapRef);
      }
    );
    checkLayerPanelOrActionCenter();
    changeSearchURLParams();
    this.loadOrderHash();
    let measureDivElement = document.getElementById('measure-div');
    measureDivElement.style.visibility = 'hidden';
    this.props.setMapClassnames();
    this.props.toogleMeasureTool(0);
    hoverLayer(
      mapRef,
      this.popoverRef,
      this.props.editingToolVal,
      this.state.hoverFeatureValue,
      this.hoverFeatureAction,
      this.state.noteActions,
      'select',
      this.hoverStyle
    );
    onClickLayer(mapRef, this.clickFeatureAction);

    mapRef
      .getInteractions()
      .getArray()
      .forEach((el) => {
        if (el instanceof KeyboardZoom) {
          mapRef.removeInteraction(el);
        }
      });
    this.resetRotateMapData(mapRef);
    this.loadGlobalAsset();
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia('(min-width: 768px)').addEventListener('change', handler);
  }

  componentWillUnmount() {
    this.props.setOrderOrganisation(null);
    removeAllInteraction(this.state.olMapRef);
  }

  componentDidUpdate(prevProps, prevState) {
    let that = this;
    let { olMapRef } = this.state;
    let measureDivElement = document.getElementById('measure-div');
    if (this.props.measureTool === 1) {
      onAreaMeasurement(olMapRef);
    } else if (this.props.measureTool === 0 && prevProps.measureTool === 1) {
      measureDivElement.style.visibility = 'hidden';
      removeMeasureInteraction(olMapRef);
      olMapRef.getLayers().forEach(function (layer) {
        if (layer && layer.getProperties().name === 'measurement') {
          olMapRef.removeLayer(layer);
        }
      });
    } else if (this.props.measureTool === 1 && prevProps.measureTool === 1) {
      removeAllInteraction(olMapRef);
    }

    if (this.props.activeTool === 'select') {
      if (this.props.activeTool !== prevProps.activeTool) {
        hoverLayer(
          olMapRef,
          that.popoverRef,
          that.props.editingToolVal,
          that.state.hoverFeatureValue,
          that.hoverFeatureAction,
          that.state.noteActions,
          that.props.activeTool,
          that.hoverStyle
        );
        onClickLayer(olMapRef, this.clickFeatureAction);
      }
    } else {
      removeHoverListener(olMapRef);
      removeClickListener();
    }
  }

  resetRotateMapData = (mapRef) => {
    this.props.rotateMapValue({ isRotate: false });
    rotateMapReset(mapRef);
  };

  toggleApprovedOrderDetails = (option) => {
    this.setState({
      showApprovedOrderDetails: !!option,
    });
  };

  showViewSelectionPanel = (val) => {
    this.setState({ viewSelectionPanelVisible: val });
  };

  updateLayerAreaInfo = (updatedLayerAreaInfo) => {
    this.setState({
      layerAreaInfo: updatedLayerAreaInfo,
    });
  };

  widgetBarTabChangeHandler = (tab) => {
    if (tab === WidgetBarTabs.VIEWS) {
      this.showViewSelectionPanel(true);
    }
  };
  loadGlobalAsset = () => {
    let that = this;
    axiosInstance
      .get(API_ENDPOINTS.GLOBAL_ASSET)
      .then((res) => {
        const data = _get(res, 'data');
        that.props.globalAssetsData(data);
      })
      .catch(() => { });
  };

  loadOrderHash = () => {
    let orderHash = this.props.match.params?.hash;

    axiosInstance
      .get(
        replaceParams(API_ENDPOINTS.GENERATE_ORDER_HASH, {
          ':hashId': orderHash,
        }),
        {
          headers: {
            Accept: 'application/json',
          },
        }
      )
      .then((result) => {
        let hashId = result.data[0].orderHash;
        let orderId = result.data[0].orderId;
        this.props.getConfigurations({
          url: replaceParams(CONFIG_WITH_ORDER_ID_API, { ':orderId': orderId }),
          header: {},
        });
        this.loadOrderDetails(hashId);
      })
      .catch((e) => {
        this.setState({
          shareApiErrorCode: e?.errorCode,
        });
      });
  };

  loadOrderDetails = (hashId) => {
    this.setState({
      isDataLoading: true,
    });
    axiosInstance
      .get(
        replaceParams(API_ENDPOINTS.SHARE_ORDER, {
          ':orderHashed': hashId,
        })
      )
      .then((res) => {
        const data = res.data;
        const [
          orderSummary,
          orderTileData,
          orderBasicDetails,
          orderFeatureData,
          orderFeedbackData,
          orderViewsData,
          orderSupportData,
        ] = data;
        this.setState({
          isDataLoading: false,
          orderStatus: orderSummary.status,
          orderSummary,
          orderBasicDetails,
          orderTileData,
          orderFeatureData,
          orderFeedbackData,
          orderViewsData,
          orderSupportData,
        });
        this.props.getOrderSuccess(orderTileData);
        this.props.setUserAccessRight(AccessRight.View);
        this.props.setOrderOrganisation(orderSummary.organizationId);
        this.props.setOrderStatus(orderSummary.status);
        this.props.setOrderFeatures(orderFeatureData);
        this.props.setParcel(orderTileData.parcelJson);
        if (orderSummary.status === 4) {
          this.props.setShareBtnStatus(true);
        }
        if (
          orderTileData.imagerydate &&
          orderTileData.imagerydate.length >= 8
        ) {
          this.setState({ imageryType: 'nearmap' });
          this.props.setPropertyHasNearmap(true);
          this.props.showHideNearmap(true);
        } else {
          this.props.setPropertyHasNearmap(false);
          this.props.showHideNearmap(false);
        }
        this.getTiff(
          orderBasicDetails,
          orderTileData.orderNo,
          orderTileData.imagerydate,
          orderTileData.survey_id,
          orderTileData.parcelJson,
          orderTileData.parcelArea,
          orderTileData.property_name
        );
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          isDataLoading: true,
        });
        NotifyError('Data Error', { 'api': 'load-shared-views', 'shareId': hashId });
      });
  };

  getTiff = (
    orderBasicDetails,
    orderId,
    imageryDate,
    surveyId,
    parcelJson,
    parcelArea,
    propertyName
  ) => {
    let that = this;
    const { olMapRef } = that.state;
    const { minLong, minLat, maxLong, maxLat, tileData } = orderBasicDetails;
    let extent = [minLong, minLat, maxLong, maxLat];
    let projectedExtent = transformExtent(extent, 'EPSG:4326', 'EPSG:3857');

    renderNearmapWithSurveyId(
      surveyId,
      JSON.parse(parcelJson),
      orderId,
      propertyName,
      undefined,
      undefined,
      19,
      ViewOption.TOP,
      undefined,
      'siterecon'
    ).then((layerGroup) => {
      olMapRef.addLayer(layerGroup);
      layerGroup.setZIndex(1);
      that.setState({
        fetching_imagery: true,
      });
      olMapRef.getView().fit(projectedExtent, {
        size: olMapRef.getSize(),
        padding: [50, 15, 55, 250],
        duration: 2000,
      });
    });
  };

  onNearMapCheck = (isChecked) => {
    toggleNearmap(this.state.olMapRef, isChecked);
    this.props.showHideNearmap(isChecked);
  };
  unSetMultiSelectFeature = () => {
    this.setState({
      multiSelectFeature: [],
    });
  };

  createFeatureGeoJsonWithLayerStyle = (feature, layerId) => {
    const { layerList } = this.props;
    let featureJson = createGeoJsonFromFeature(feature);
    featureJson.style = findLayerStyleFromComponent(layerId, layerList);
    featureJson.componentId = featureJson.properties.actualComponentId;
    return featureJson;
  };

  clickFeatureAction = (
    /* component */
    properties,
    /* MapBrowserEvent */
    e,
    /* ol/Feature */
    feature
  ) => {
    const { olMapRef } = this.state;
    const { layerList, featureList } = this.props;
    // When no feature is selected, do nothing
    if (!feature) {
      return;
    }
    this.props.toggleLayerLeftPanel(false);
    // Do we need to update the state and props that is holding
    // the information about selected components on map?
    let updateNotRequired = false;
    // Is this an attempt made by user to do cross layer selection?
    let crossLayerAction = false;
    // The component id that has been attempted to be selected
    const componentId = +properties.componentId.split('-')[1];
    // The layer this component belongs to
    let sourceLayer = findLayerById(properties.layerId, this.state.olMapRef);
    // This is the key to a map object = layer_name&&layer_id&&uom
    const key =
      properties.name + '&&' + properties.layerId + '&&' + properties.unit;
    // componentsAlreadySelected contains items that are already selected.
    let componentsAlreadySelected = this.props.attributesMulti || {};
    if (
      Object.keys(componentsAlreadySelected).length &&
      !componentsAlreadySelected[key]
    ) {
      crossLayerAction = true;
    }
    let selectedItemGeoJsonArray = componentsAlreadySelected?.[key]
      ? componentsAlreadySelected?.[key]
      : [];
    const isAlreadySelected = selectedItemGeoJsonArray.find(
      (component) => componentId === component.id
    );

    // GeoJson of the selected item on layer
    let featureJson = this.createFeatureGeoJsonWithLayerStyle(
      feature,
      properties.layerId
    );
    // dataToSet will collect all selected items on the layer into a single array
    // In case of single select, this array will be singleton
    let dataToSet = [];
    // If the selection is multi select
    if (_get(e, 'originalEvent.ctrlKey') || _get(e, 'originalEvent.metaKey')) {
      // cross layer selection in case of multi select is banned
      if (crossLayerAction) {
        updateNotRequired = true;
      }
      // if the component on that layer is not selected  already - bucket it as selected
      else if (!isAlreadySelected) {
        dataToSet = [
          ...selectedItemGeoJsonArray,
          {
            ...featureJson,
            eventPixel: olMapRef?.getCoordinateFromPixel(e.pixel),
          },
        ];
        sourceLayer &&
          clickLayerHighlight(
            sourceLayer,
            featureJson.componentId,
            true,
            layerList,
            featureList.data
          );
      }
      // if the component on that layer is already selected - unselect it
      else {
        for (let i = 0; selectedItemGeoJsonArray.length > i; i++) {
          if (selectedItemGeoJsonArray[i]['componentId'] !== componentId) {
            dataToSet.push(selectedItemGeoJsonArray[i]);
          } else {
            sourceLayer &&
              clickLayerHighlight(
                sourceLayer,
                featureJson.componentId,
                false,
                layerList,
                featureList.data
              );
          }
        }
      }
    }
    // If the selection is single select
    else {
      // cross layer selection in single select is allowed
      // If it is a cross layer selection attempt, everything is deselected in previous layer
      // single item is selected in current layer
      if (crossLayerAction) {
        let layerKeys = Object.keys(componentsAlreadySelected || {});
        for (let layerKey of layerKeys) {
          let layerId = layerKey.split('&&')[1];
          let sourceLayerOfComponent = findLayerById(
            layerId,
            this.state.olMapRef
          );
          for (let item of componentsAlreadySelected[layerKey]) {
            sourceLayerOfComponent &&
              clickLayerHighlight(
                sourceLayerOfComponent,
                item.id,
                false,
                layerList,
                featureList.data
              );
          }
        }
        dataToSet = [
          {
            ...featureJson,
            leftPanelSelect: false,
            eventPixel: olMapRef?.getCoordinateFromPixel(e.pixel),
          },
        ];
        sourceLayer &&
          clickLayerHighlight(
            sourceLayer,
            featureJson.id,
            true,
            layerList,
            featureList.data
          );
      }
      // Same layer and different component is attempted to be selected
      // previous item is deselected and current item is selected
      else if (!isAlreadySelected) {
        dataToSet = [
          {
            ...featureJson,
            leftPanelSelect: false,
            eventPixel: olMapRef?.getCoordinateFromPixel(e.pixel),
          },
        ];
        selectedItemGeoJsonArray.forEach(function (feature) {
          sourceLayer &&
            clickLayerHighlight(
              sourceLayer,
              feature.id,
              false,
              layerList,
              featureList.data
            );
        });
        sourceLayer &&
          clickLayerHighlight(
            sourceLayer,
            featureJson.id,
            true,
            layerList,
            featureList.data
          );
      }
      // Same layer and same component is attempted to be selected
      // It meams user is trying to unselect that item
      else {
        dataToSet = [];
        selectedItemGeoJsonArray.forEach(function (feature) {
          sourceLayer &&
            clickLayerHighlight(
              sourceLayer,
              feature.id,
              false,
              layerList,
              featureList.data
            );
        });
      }
    }
    // If there has been any change in the selected items on map -
    // props and state both are updated
    if (!updateNotRequired) {
      this.props.setMultiData({
        [key]: dataToSet,
      });
      this.setState({
        clickFeatureValue: properties,
      });
    }
    document.getElementById('map-popover').style.display = 'none';
  };

  hoverFeatureAction = (val) => {
    this.setState({
      hoverFeatureValue: val,
    });
  };

  hoverStyle = (properties, feature, removeHoverStyle = false) => {
    let that = this;
    const { layerList, featureList } = that.props;
    layerList?.forEach((layer) => {
      if (layer.layerId === +properties?.layerId) {
        const featureType = featureList.data
          .filter((item) => item.featureId === _get(layer, 'featureId'))
          .map((item) => item.type)[0];
        layer?.style && feature.setStyle(pointHoverIconFeature(layer?.style));
        if (featureType === 'path') {
          let styleToBeApplied = {
            ...layer?.style,
            color: hoveredFeatureStyle.getStroke().getColor(),
            fillColor: hoveredFeatureStyle.getFill().getColor(),
            arrowColor: hoveredFeatureStyle.getStroke().getColor(),
          };
          if (removeHoverStyle) styleToBeApplied = layer?.style;
          layer?.style && updatePathStyleFunction([feature], styleToBeApplied);
        } else if (featureType === 'point') {
          layer?.style &&
            feature.setStyle(
              removeHoverStyle ? false : pointHoveredFeatureStyle(layer?.style)
            );
        } else {
          layer?.style &&
            feature.setStyle(removeHoverStyle ? false : hoveredFeatureStyle);
        }
      }
    });
  };

  onBaseMapCheck = (show) => {
    this.props.showHideBasemap(show);
    const { olMapRef } = this.state;
    toggleBasemap(olMapRef, show, this.props.selectedBasemap);
  };

  render() {
    const {
      olMapRef,
      orderSummary,
      orderTileData,
      orderViewsData,
      orderStatus,
      showApprovedOrderDetails,
      shareApiErrorCode,
    } = this.state;

    if (shareApiErrorCode === 'SR-BIZ-ERROR-1') {
      return (
        <AccessWall
          title='You are not authorised to view this map.'
          content='Reach out to the property creator or workspace admin to provide you with the appropriate access rights.'
          propertyName={''}
          orgName={''}
        />
      );
    }

    return (
      <React.Fragment>
        <OrderStatusContext.Provider value={{ status: orderStatus }}>
          <MapContext.Provider value={olMapRef}>
            <div>
              <nav
                id='share-navbar'
                style={{
                  height: '50px',
                  padding: !this.state.matches ? '0px' : '',
                }}
              >
                {this.state.matches && (
                  <>
                    <div className='share-navbar-left'>
                      <Link
                        to='/'
                        onClick={() => this.setState({ current: '/' })}
                        className='navbar-logo-wrapper'
                      >
                        <BrandLogo brandLogoClasses='navbar-logo' />
                      </Link>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {/* <BackArrow style={{marginLeft: '8px', marginTop: '4px', cursor: 'pointer'}} onClick={() => {
                              this.props.history.push('/');
                            }}/> */}

                        <Tooltip title='Select Tool'>
                          {!this.props.measureTool ? (
                            <HighArrow className='secondary-icons' />
                          ) : (
                            <LowArrow
                              className='secondary-icons'
                              onClick={() => {
                                this.props.setMapClassnames();
                                this.props.toogleMeasureTool(0);
                                this.props.setMapClassnames('select'); // default selected tool
                                this.props.setActiveTool('select');
                              }}
                            />
                          )}
                        </Tooltip>

                        <Tooltip title='Measure Tool'>
                          {!this.props.measureTool ? (
                            <MeasureTool
                              className='secondary-icons'
                              style={{ marginLeft: '16px' }}
                              onClick={() => {
                                this.props.setActiveTool(
                                  ToolsTypes.MeasureTool.action
                                );
                                this.props.setMapClassnames('measuretool');
                                this.props.toogleMeasureTool(1);
                              }}
                            />
                          ) : (
                            <HighMeasure
                              className='secondary-icons'
                              onClick={() => {
                                this.props.setMapClassnames('select'); // default selected tool
                                this.props.setActiveTool('select');
                                this.props.toogleMeasureTool(0);
                              }}
                            />
                          )}
                        </Tooltip>
                      </div>
                    </div>
                    <div className='small-logo'>
                      {this.props?.configurations?.isChannelPartner ? (
                        <MapPinLogo />
                      ) : (
                        <SmallLogo />
                      )}
                      {this.state.orderTileData?.address && (
                        <p className='order-text'>
                          {this.state.orderTileData?.address}
                        </p>
                      )}
                    </div>
                    <div className='share-navbar-right'>
                      <Link
                        className='ant-btn ant-btn-default'
                        style={{ background: ' #ffffff' }}
                        to='/auth'
                      >
                        Login
                      </Link>
                      <span>&nbsp;</span>
                      <Link
                        className='ant-btn ant-btn-primary ant-btn-register'
                        to='/auth'
                      >
                        Register
                      </Link>
                    </div>
                  </>
                )}
                {!this.state.matches && (
                  <>
                    <Row
                      style={{
                        width: '100%',
                        height: '100%',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                      }}
                    >
                      {/* Icon */}
                      <Link
                        to='/'
                        onClick={() => this.setState({ current: '/' })}
                        className='navbar-logo-wrapper'
                        style={{ marginRight: '2px' }}
                      >
                        <SitereconLogo
                          style={{ height: '20px', width: '20px' }}
                        />
                      </Link>

                      {/* Property Name */}
                      <div style={{ width: '40%' }}>
                        {this.state.orderTileData?.address && (
                          <Tooltip title={this.state.orderTileData?.address}>
                            <SRText
                              style={{ display: 'flex' }}
                              className='text-ellipsis'
                            >
                              {this.state.orderTileData?.address}
                            </SRText>
                          </Tooltip>
                        )}
                      </div>

                      {/* Tools icons */}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title='Select Tool'>
                          {!this.props.measureTool ? (
                            <HighArrow className='secondary-icons' />
                          ) : (
                            <LowArrow
                              className='secondary-icons'
                              onClick={() => {
                                this.props.setMapClassnames();
                                this.props.toogleMeasureTool(0);
                                this.props.setMapClassnames('select'); // default selected tool
                                this.props.setActiveTool('select');
                              }}
                            />
                          )}
                        </Tooltip>

                        <Tooltip title='Measure Tool'>
                          {!this.props.measureTool ? (
                            <MeasureTool
                              className='secondary-icons'
                              style={{ marginLeft: '16px' }}
                              onClick={() => {
                                this.props.setActiveTool(
                                  ToolsTypes.MeasureTool.action
                                );
                                this.props.setMapClassnames('measuretool');
                                this.props.toogleMeasureTool(1);
                              }}
                            />
                          ) : (
                            <HighMeasure
                              className='secondary-icons'
                              onClick={() => {
                                this.props.setMapClassnames('select'); // default selected tool
                                this.props.setActiveTool('select');
                                this.props.toogleMeasureTool(0);
                              }}
                            />
                          )}
                        </Tooltip>
                      </div>

                      {/* Action Buttons */}
                      <div style={{ padding: '2px' }}>
                        <Button type='primary'>
                          <Link to='/auth'>
                            <Text>Register</Text>
                          </Link>
                        </Button>
                      </div>
                    </Row>
                  </>
                )}
              </nav>

              <div
                id='track_map'
                className={classNames('track_map', this.props.mapClassName, {
                  'track_map--with-topbar': this.props.isNotificationBarVisible,
                })}
                style={{
                  height: `calc(100vh - ${this.props.isNotificationBarVisible ? '100px' : '50px'
                    }`,
                }}
                onMouseLeave={() =>
                  (this.popoverRef.current.style.display = 'none')
                }
              >
                <div id='ol-map--project' />
                <div id='popup' className='ol-popup nitish'>
                  <a href='#' id='popup-closer' className='ol-popup-closer' />
                  <div id='popup-content'>
                    <Text id={'lat-long'} style={{ cursor: 'pointer' }} />
                  </div>
                </div>
                {this.props.configurations?.isChannelPartner && <Partner />}
              </div>
              <CoWidgetBarIntegration
                onChange={this.widgetBarTabChangeHandler}
                isSharedPageLink={true}
                className="co-widget-bar-integration"
              />

              <div>
                <Footer />

                <MapControls
                  isAddressConfirmation={false}
                  onResetToParcel={() =>
                    resetToParcel(
                      this.state.olMapRef,
                      this.state.orderBasicDetails,
                      false
                    )
                  }
                  hasParcel={orderTileData?.parcelJson !== null}
                />
              </div>
              <div className='share-container'>
                <OrderStatusGuard
                  status={[
                    OrderStatus.Approved,
                    OrderStatus.BoltApproved,
                    OrderStatus.SelfMeasured,
                    OrderStatus.AwaitingApproval,
                    OrderStatus.ResolvingFeedback,
                    OrderStatus.BoltPreview,
                    OrderStatus.BoltInVerification,
                  ]}
                >
                  <ProjectFeaturePanel
                    mapRef={this.state.olMapRef}
                    views={orderViewsData}
                    orderSummary={orderSummary}
                    orderTileData={orderTileData}
                    key={orderSummary.orderId}
                    loadOrderDetails={this.loadOrderDetails}
                    rasterVal={this.state.isBasemapEnabled}
                    onBaseMapCheck={this.onBaseMapCheck}
                    checkMap={this.state.isBasemapEnabled}
                    disabledCheck={this.state.disabledCheck}
                    onNearMapCheck={this.onNearMapCheck}
                    nearMapCheck={this.state.isNearmapEnabled}
                    expendedKeys={this.state.clickFeatureValue}
                    clickView={this.clickView}
                    openFPanel={this.state.openFPanel}
                    toggleFeaturePanel={(value) => {
                      this.setState({
                        openFPanel: value,
                      });
                    }}
                    fetching_imagery={this.state.fetching_imagery}
                    showApprovedOrderDetails={showApprovedOrderDetails}
                    toggleApprovedOrderDetails={this.toggleApprovedOrderDetails}
                    isNearMap={this.state.hasNearmap}
                    isLayerStyleAllowed={false} //is not allowed layer style
                    history={this.props.history}
                    isOperationAllowed={false}
                    clickFeatureAction={this.clickFeatureAction}
                    isVerificationTimerVisible={false}
                    multiSelectFeature={this.state.multiSelectFeature}
                    unSetMultiSelectFeature={this.unSetMultiSelectFeature}
                    setExtraCols={(n) =>
                      this.setState((prevState) => ({
                        ...prevState,
                        noOfExtraCols: n,
                      }))
                    }
                    isBulkView={false}
                    showViewSelectionPanel={this.showViewSelectionPanel}
                    viewSelectionPanelVisible={
                      this.state.viewSelectionPanelVisible
                    }
                    isShareLinkPage={true}
                    updateLayerAreaInfo={this.updateLayerAreaInfo}
                  />
                </OrderStatusGuard>
              </div>
              <div className='text' id='map-popover' ref={this.popoverRef}>
                {
                  <div className='map-hover-parent'>
                    <div className='map-hover-child__left'>
                      <div>
                        <p id='name' />
                      </div>
                      <span id='separator'>{'/'}</span>
                      <p id='featureId' />
                    </div>
                    <div className='map-hover-child__right'>
                      <img alt='...' id='featureIcon' />
                      <p id='hoverArea' />
                      <div>
                        <p id='unit'>sqft</p>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div id='measure-div'></div>
            </div >
            <Keyboard
              mapRef={this.state.olMapRef}
              shortCutRef={this.props.shortCutRef}
            />
          </MapContext.Provider >
        </OrderStatusContext.Provider >
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcel: state.order.parcel,
    measureTool: state.user.measureTool,
    nearMapDates: state.order.nearMapDates,
    activeTool: state.user.activeTool,
    mapClassName: state.user.mapClassName,
    editingToolVal: state.order.editingToolVal,
    address: state.user.address,
    layerList: state.order.layerList,
    attributesMulti: state.feature.attributeMultiPopupData,
    configurations: state.configurations,
    featureList: state.order.featureListInfo,
    isNotificationBarVisible: state.ui.notificationBar.visible,
    isBasemapEnabled: state.ui.isBasemapEnabled,
    isNearmapEnabled: state.ui.isNearmapEnabled,
    selectedBasemap: state.ui.selectedBasemap,
    hasNearmap: state.ui.hasNearmap,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setParcel: (payload) => dispatch(setParcel(payload)),
    setShareBtnStatus: (payload) => dispatch(setShareBtnStatus(payload)),
    updateNearMapDates: (payload) => dispatch(updateNearMapDates(payload)),
    toogleMeasureTool: (val) => dispatch(toogleMeasureTool(val)),
    getOrderSuccess: (payload) => dispatch(getOrderSuccess(payload)),
    setActiveTool: (payload) => dispatch(setActiveTool(payload)),
    setMapClassnames: (payload) => dispatch(setMapClassnames(payload)),
    setOrderStatus: (payload) => dispatch(setOrderStatus(payload)),
    setOrderOrganisation: (payload) => dispatch(setOrderOrganisation(payload)),
    setUserAccessRight: (payload) => dispatch(setUserAccessRight(payload)),
    setOrderFeatures: (payload) => dispatch(setOrderFeatures(payload)),
    baseView: (payload) => dispatch(baseView(payload)),
    setMultiData: (payload) => dispatch(setAttributeMultiPopupData(payload)),
    setSharedOrder: (payload) => dispatch(setSharedOrder(payload)),
    rotateMapValue: (payload) => dispatch(rotateMapValue(payload)),
    globalAssetsData: (payload) => dispatch(globalAssetsData(payload)),
    getConfigurations: (payload) => dispatch(getConfigurations(payload)),
    toggleLayerLeftPanel: (payload) => dispatch(toggleLayerLeftPanel(payload)),
    showHideBasemap: (payload) => dispatch(showHideBasemap(payload)),
    showHideNearmap: (payload) => dispatch(showHideNearmap(payload)),
    setPropertyHasNearmap: (payload) => dispatch(setPropertyHasNearmap(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShareOrderMap));
