// @ts-nocheck
import { ComponentType, FC } from 'react';
import { BetaFeature, IObject } from '@/modules/common/types';
import { useSelector } from 'react-redux';
import { IStore } from '@/store/types';
import { BetaReleaseStore } from '@/store/betaRelease/state';
import { getKey } from '@/modules/common/guards/BetaRelease/useBetaRelease';

type BetaFeatureStatusMap = {
  [key in BetaFeature]: boolean;
};

interface WithBetaReleaseProps {
  betaRelease: Partial<BetaFeatureStatusMap>;
}

const WithBetaRelease =
  <P extends IObject = any>(features: BetaFeature | BetaFeature[]) =>
  (Component: ComponentType<P>): FC<P & WithBetaReleaseProps> => {
    const WrappedComponent: FC<P & WithBetaReleaseProps> = (props) => {
      const betaReleaseFeatures: Partial<BetaFeatureStatusMap> = {};
      const betaReleaseStore = useSelector<IStore, BetaReleaseStore>(
        (state) => state.betaRelease
      );

      /** If only single feature is passed, convert it to array first */
      if (typeof features === 'string') {
        features = [features];
      }

      features.forEach((feature) => {
        betaReleaseFeatures[feature] = betaReleaseStore[getKey(feature)];
      });

      return <Component {...props} betaRelease={betaReleaseFeatures} />;
    };

    return WrappedComponent;
  };

export default WithBetaRelease;
