import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Button, Input, Modal, Row } from 'antd';
import Text from '../../elements/text/Text';
import UploadFile from './upload-file/UploadFile';

import './style.less';
import { createBulkName } from '@/components/api/bulkProject';
import useQuery from './../../pages/myProperties/hooks/useQuery';
import { BulkApi } from './../../pages/myProperties/api/bulk';
import { useDispatch } from 'react-redux';
import {
  editBulkDescriptionFromBulkCard,
  updateBulkDescription,
} from '../../../store/dashboard/action';
import { showNotification } from '../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../storybook/NotificationToast/types';

const { TextArea } = Input;

const BulkProjectUpload = ({
  visible,
  onOk,
  onCancel,
  oldBulkName,
  oldBulkDescription,
  isBulkDetailsPage,
  fromBulkCard,
  bulkIdFromCard,
}) => {
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [bulkProjectName, setBulkProjectName] = useState('');
  const [bulkProjectDescription, setBulkProjectDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [bulkOrderId, setBulkOrderId] = useState(null);
  const [error, setError] = useState({
    show: false,
    message: '',
  });
  const [errorText, setErrorText] = useState({
    name: false,
    description: false,
  });
  const bulkId = useQuery().get('bulkId');
  const [reloadOnAddNewBulk, setReloadOnAddNewBulk] = useState(false);
  const dispatch = useDispatch();

  const editBulkOrderNameDescription = useCallback(async () => {
    let BULK_ID = '';

    if (fromBulkCard) {
      BULK_ID = bulkIdFromCard;
    } else {
      BULK_ID = bulkId;
    }

    if (!BULK_ID) return;

    setLoading(true);
    setError({
      show: false,
      message: '',
    });
    try {
      const tempData = {
        description: bulkProjectDescription,
      };

      // Send Project Name In API Only If It Has Been Changed
      if (oldBulkName !== bulkProjectName) {
        tempData.na = bulkProjectName;
      }

      await BulkApi.editBulkNameAndDescriptionApi(BULK_ID, tempData);
      onOk();
      showNotification(
        NOTIFICATIONS_TYPES.SUCCESS,
        `${bulkProjectName}  is updated successfully`
      );
      // reloadBulk();
      setBulkProjectDescription('');
      setBulkProjectName('');
      setLoading(false);

      if (fromBulkCard) {
        dispatch(
          editBulkDescriptionFromBulkCard({
            name: bulkProjectName,
            description: bulkProjectDescription,
            bulkId: BULK_ID,
          })
        );
      } else {
        dispatch(
          updateBulkDescription({
            name: bulkProjectName,
            description: bulkProjectDescription,
          })
        );
        dispatch(
          editBulkDescriptionFromBulkCard({
            name: bulkProjectName,
            description: bulkProjectDescription,
            bulkId: +BULK_ID,
          })
        );
      }
    } catch (err) {
      setLoading(false);
      if (
        err &&
        err.response &&
        err.response.status === 400 &&
        err.response.data &&
        err.response.data.msg
      ) {
        setError({
          show: true,
          message: err.response.data.msg,
        });
        return;
      }
      setError({
        show: true,
        message: err.message || 'Something went wrong!',
      });
      console.error(err);
    }
  }, [
    bulkId,
    bulkIdFromCard,
    bulkProjectDescription,
    bulkProjectName,
    dispatch,
    fromBulkCard,
    oldBulkName,
    onOk,
  ]);

  const postBulkName = async () => {
    if (isBulkDetailsPage) return editBulkOrderNameDescription();

    setLoading(true);
    setError({
      show: false,
      message: '',
    });

    try {
      const bulkOrder = await createBulkName(
        bulkProjectName,
        bulkProjectDescription
      );
      if (bulkOrder.success) {
        onOk();
        setBulkOrderId(bulkOrder.id);
        setShowFileUpload(true);
        showNotification(
          NOTIFICATIONS_TYPES.SUCCESS,
          `${bulkProjectName}  is created successfully`
        );
        setReloadOnAddNewBulk(true);
        setBulkProjectDescription('');
        setBulkProjectName('');
      } else {
        setError({
          show: true,
          message: bulkOrder.msg || 'Something went wrong!',
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError({
        show: true,
        message: err.message || 'Something went wrong!',
      });
      console.error(err);
    }
  };

  const handleClose = useCallback(() => {
    onCancel();
    setBulkProjectDescription('');
    setBulkProjectName('');
    setError({
      show: false,
      message: '',
    });
    setErrorText({
      name: false,
      description: false,
    });
  }, [onCancel]);

  useLayoutEffect(() => {
    setBulkProjectName(oldBulkName);
    setBulkProjectDescription(oldBulkDescription);
  }, [oldBulkDescription, oldBulkName, visible]);

  return (
    <>
      {/* <Button
        icon={<UploadIcon className='bulk-project-upload-icon' />}
        className='bulk-upload-button'
        shape='round'
        size='large'
        onClick={onBulkProjectClick}
        style={{top : "40px"}}
      >
        Bulk Project
      </Button> */}
      {!isBulkDetailsPage && (
        <UploadFile
          visible={showFileUpload}
          setVisible={setShowFileUpload}
          onCancel={() => setShowFileUpload(false)}
          bulkOrderId={bulkOrderId}
          resetAllBulk={reloadOnAddNewBulk}
        />
      )}
      <Modal
        visible={visible}
        onOk={onOk}
        onCancel={handleClose}
        footer={null}
        closable={true}
        width='450px'
        className='step1__modal'
        centered
      >
        <Row style={{ flexDirection: 'column' }}>
          <Row
            align='middle'
            style={{ flexDirection: 'column' }}
            className='mb-6'
          >
            <Text type='p36' color={'light-gray'}>
              Step 1/5
            </Text>
            <Text type='h41'>Bulk Project</Text>
          </Row>
          <Text type='h9' className='mb-3'>
            Enter a short name for the Bulk Project
          </Text>
          <Input
            placeholder='15 Character Name'
            className='bulk-project-name-input'
            onChange={(e) => {
              if (e.target.value.length > 50) {
                setErrorText((prevState) => ({ ...prevState, name: true }));
              } else {
                setErrorText((prevState) => ({ ...prevState, name: false }));
              }
              setBulkProjectName(e.target.value);
              if (error && typeof error === 'object' && error.show) {
                setError({
                  show: false,
                  message: '',
                });
              }
            }}
            value={bulkProjectName}
          />
          {error.show && (
            <Text type='p30' color={'error-red'} className='mt-1'>
              {error?.message}
            </Text>
          )}
          {errorText.name && (
            <Text type='p35' color={'error-red'} className='mt-1'>
              {'You have exceeded the 50 character limit!'}
            </Text>
          )}
          <Text type='h9' className='mb-3 mt-5'>
            Description
          </Text>
          <Row style={{ position: 'relative' }}>
            <Text
              type='p35'
              color={'light-gray'}
              style={{
                position: 'absolute',
                bottom: '3px',
                right: '10px',
                zIndex: '100',
              }}
            >
              {`${bulkProjectDescription?.length ?? 0}/500`}
            </Text>
            <TextArea
              rows={4}
              placeholder='Enter a Short Description about the project'
              className='bulk-project-name-input pb-3'
              onChange={(e) => {
                if (e.target.value.length > 500)
                  setErrorText((prevState) => ({
                    ...prevState,
                    description: true,
                  }));
                else
                  setErrorText((prevState) => ({
                    ...prevState,
                    description: false,
                  }));
                setBulkProjectDescription(e.target.value);
              }}
              value={bulkProjectDescription}
            />
          </Row>
          {errorText.description && (
            <Text type='p35' color={'error-red'} className='mt-1'>
              {'You have exceeded the 500 character limit!'}
            </Text>
          )}
          <Row
            justify='end'
            align='middle'
            className='bulk-project-action-section w-100 mt-9'
          >
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type='primary'
              className='save-button'
              onClick={postBulkName}
              loading={loading}
              disabled={
                !bulkProjectName ||
                bulkProjectName?.length > 50 ||
                bulkProjectDescription?.length > 500 ||
                (oldBulkName === bulkProjectName &&
                  (!bulkProjectDescription ||
                    bulkProjectDescription === oldBulkDescription)) // Also Disable If Bulk Project Name Has Not Been Changed And There's No Description
              }
            >
              Save
            </Button>
          </Row>
        </Row>
      </Modal>
    </>
  );
};

export default BulkProjectUpload;
