import { useEffect, useState } from 'react';
import { NotificationBar } from '@siterecon/sr-component/dist/sr-component-library';
import ExclamationDark from '../../../../assets/icons/ExclamationDark';
import useNotificationBar from './useNotificationBar';
import { useSubscription } from '../../../../modules/subscription/hooks';
import { NotificationBarType } from './types';
import { SubscriptionState } from '../../../subscription/helpers/enum';
import { PaymentFailureReason } from '../../../subscription/helpers/types';
import { useHistory } from 'react-router-dom';

const TYPE = NotificationBarType.PaymentFailed;

const PaymentFailedNotificationBar = () => {
  const { isVisible, show, hide } = useNotificationBar();

  const { subscriptionState, showSubscriptionWidget, subscription } = useSubscription();
  const [message, setMessage] = useState<String>('Billing overdue as the payment has failed. Please update payment method for uninterrupted services. We will retry again tomorrow.');
  const [cta, setCTA] = useState<String>('Update Payment Method');
  const history = useHistory();

  const handleUpdatePaymentMethod = () => {
    if (
      subscription &&
      subscription.lastPaymentStatus &&
      subscription.lastPaymentStatus.paymentFailureReason &&
      subscription.lastPaymentStatus.paymentFailureReason === PaymentFailureReason.INVALID_BILLING_ADDRESS
    ) {
      history.push('/settings/billing-setup');
    } else {
      showSubscriptionWidget();
    }
  };

  const handleClose = () => hide(TYPE);

  useEffect(() => {
    if (!subscriptionState) return;

    if (
      subscription &&
      subscription.lastPaymentStatus &&
      subscription.lastPaymentStatus.paymentFailureReason &&
      subscription.lastPaymentStatus.paymentFailureReason === PaymentFailureReason.INVALID_BILLING_ADDRESS
    ) {
      setMessage('Billing overdue as the payment has failed. Please update billing address for uninterrupted services. We will retry again tomorrow.');
      setCTA('Update Billing Address');
    }
    if (subscriptionState === SubscriptionState.PAYMENT_FAILED) {
      show(TYPE);
    }
  }, [subscriptionState]);

  if (!isVisible(TYPE)) {
    return null;
  }

  return (
    <>
      <NotificationBar
        icon={<ExclamationDark />}
        message={message}
        action={{
          label: <> {cta} </>,
          onClick: handleUpdatePaymentMethod,
        }}
        onClose={handleClose}
      />
    </>
  );
};

export default PaymentFailedNotificationBar;
