import { transform } from 'ol/proj';

export const MAP_DEFAULT_PROJECTION = 'EPSG:3857';
export const MAP_DEFAULT_ZOOM = 1;
export const MAP_DEFAULT_CENTER = transform(
  [-95.7129, 37.0902],
  'EPSG:4326',
  MAP_DEFAULT_PROJECTION
);
export const MAP_GEOMETRY_TYPE_POLYGON = 'Polygon';
export const MAP_GEOMETRY_TYPE_MULTI_POLYGON = 'MultiPolygon';
export const MAP_GEOMETRY_TYPE_LINE = 'LineString';
export const MAP_GEOMETRY_TYPE_POINT = 'Point';
export const MAP_DESIGNATION_PROJECTION = 'EPSG:4326';
export const MAP_LAYER_STREET = 'street';
export const MAP_LAYER_RASTER = 'raster';

/*** Shape or feature Types ***/
export const POINT_TYPE_FEATURE = 'Point';
export const LINE_TYPE_FEATURE = 'LineString';
export const POLYGON_TYPE_FEATURE = 'Polygon';

export const POINT_GEOMETRY = 'point';
export const LINE_GEOMETRY = 'line';
export const POLYGON_GEOMETRY = 'polygon';
export const PATH_GEOMETRY = 'path';
