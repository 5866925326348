import { Reducer } from 'redux';
import { INITIAL_STATE } from '@/store/subscription/state';
import { SUBSCRIPTION_TYPES } from '@/store/subscription/action.types';
import { SubscriptionStore } from '@/store/subscription/index';
import { transformFeatures } from '@/modules/subscription/helpers';

const reducer: Reducer<SubscriptionStore> = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case SUBSCRIPTION_TYPES.SET_SUBSCRIPTION_MODAL:
      return {
        ...state,
        modal: payload,
      };
    case SUBSCRIPTION_TYPES.SET_PAY_AS_YOU_GO_MODAL:
      return {
        ...state,
        payAsYouGoModal: payload,
      };
    case SUBSCRIPTION_TYPES.SET_SUBSCRIPTION_WIDGET_DATA:
      return {
        ...state,
        subscriptionWidget: payload,
      };
    case SUBSCRIPTION_TYPES.SET_REPAIR_SUBSCRIPTION_MODAL_DATA:
      return {
        ...state,
        repairSubscription: payload,
      };
    case SUBSCRIPTION_TYPES.SET_DATA:
      return {
        ...state,
        isReminderVisible: payload.showReminder,
        currentPlan: payload.currentPlan,
        features: transformFeatures(payload),
        response: payload,
      };
    case SUBSCRIPTION_TYPES.HIDE_REMINDER:
      return {
        ...state,
        isReminderVisible: false,
      };
    case SUBSCRIPTION_TYPES.SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: payload,
      };
    default:
      return state;
  }
};

export default reducer;
