import { useEffect, useRef } from 'react';

const useInactivityTracker = (
  callback: () => void,
  duration: number = 5000
) => {
  const timeout = useRef<NodeJS.Timer | null>();
  const isInitialSetup = useRef(true);

  useEffect(() => {
    const resetInactivityTimer = () => {
      
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => callback(), duration);
    };

    const handleActivity = () => {
      if (!isInitialSetup.current) {
        resetInactivityTimer();
      } else {
        isInitialSetup.current = false;
      }
    };

    document.addEventListener('mousedown', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.addEventListener('mousemove', handleActivity);

    resetInactivityTimer(); // Initial setup

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      document.removeEventListener('mousedown', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('mousemove', handleActivity);
    };
  }, [callback, duration]);
};

export default useInactivityTracker;
