import React from 'react';

import Text from '../../../elements/text/Text';
import checkedCircle from '../../../../assets/bulkOrder/checkedCircle.svg';
import processingImage from '../../../../assets/bulkOrder/processingImage.svg';
import uploadingImage from '../../../../assets/uploadingImage.gif';

import './style.less';

export const FileUploadStatus = {
  uploadFile: 'upload File',
  uploadingFile: 'uploading File',
  processingFile: 'processing File',
  uploadingCompleted: 'uploading completed',
  uploadingFailed: 'uploading Failed',
  uploadingPartiallyFailed: 'uploading Partially Failed',
};

const UploadStatus = ({ loadingState }) => {
  const getLoadingIconAsPerState = () => {
    switch (loadingState) {
      case FileUploadStatus.uploadingFile:
        return { icon: uploadingImage, title: 'Uploading File' };
      case FileUploadStatus.processingFile:
        return { icon: processingImage, title: 'Processing Document' };
      case FileUploadStatus.uploadingCompleted:
        return { icon: checkedCircle, title: 'Uploading Completed' };
      default:
        return null;
    }
  };
  return (
    <>
      {getLoadingIconAsPerState() && (
        <div className='uploading'>
          <div className='uploading-image-text'>
            <img
              src={getLoadingIconAsPerState().icon}
              alt='uploading'
              className='upload-image-gif'
            ></img>
          </div>
          <div className='uploading-image-text'>
            <Text type='p3' color='dark-gray'>
              {getLoadingIconAsPerState().title}
            </Text>
          </div>
        </div>
      )}
    </>
  );
};

export default UploadStatus;
