import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../../../store/types';
import { ToolKey } from '../../types';

interface UseSyncOnLayerChangeParams {
  activeTool: any;
  chosenLayer: any;
  handleSaveUnSyncedModifications: () => void;
}

const useSyncOnLayerChange = ({
  activeTool,
  chosenLayer,
  handleSaveUnSyncedModifications,
}: UseSyncOnLayerChangeParams) => {
  const unSyncedModifiedChanges = useSelector<IStore, any[]>(
    (state) => state.map.modifyDraftEvents
  );

  useEffect(() => {
    /** Sync when chosen layer is changed */
    if (activeTool == ToolKey.EDIT && unSyncedModifiedChanges.length > 0) {
      handleSaveUnSyncedModifications();
    }
  }, [chosenLayer]);
};

export default useSyncOnLayerChange;
