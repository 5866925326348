import {
  CREATE_FEEDBACK,
  CREATE_NOTES,
  REMOVE_ATTACHMENTS,
  RESET_ATTACHMENTS,
  SET_ATTACHMENTS,
  SET_IS_FILTER,
  SET_IS_NOTES_PANEL_EXPANDED,
  SHOW_RESOLVE_NOTES,
  STORE_ORDER_NOTES,
  STORE_ORDER_NOTES_ORIGINAL,
} from './action_types';

const initialState = {
  notes: [],
  noteCreated: 0,
  feedback: [],
  orderNotes: [],
  orderNotesOriginal: [],
  showResolveCheck: false,
  isFiltered: false,
  attachments: [],
  isNotesPanelExpanded: null,
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NOTES:
      return {
        ...state,
        noteCreated: state.noteCreated + 1,
      };
    case CREATE_FEEDBACK:
      return {
        ...state,
      };
    case STORE_ORDER_NOTES:
      return {
        ...state,
        orderNotes: action.payload,
      };
    case STORE_ORDER_NOTES_ORIGINAL:
      return {
        ...state,
        orderNotesOriginal: action.payload,
      };
    case SHOW_RESOLVE_NOTES:
      return {
        ...state,
        showResolveCheck: action.payload,
      };
    case SET_IS_FILTER:
      return {
        ...state,
        isFiltered: action.payload,
      };
    case SET_IS_NOTES_PANEL_EXPANDED:
      return {
        ...state,
        isNotesPanelExpanded: action.payload,
      };
    case SET_ATTACHMENTS:
      if (state.attachments) {
        return {
          ...state,
          attachments: [...state.attachments, action.payload],
        };
      } else {
        return {
          ...state,
          attachments: [action.payload],
        };
      }

    case RESET_ATTACHMENTS:
      return {
        ...state,
        attachments: null,
      };
    case REMOVE_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload,
      };
    default:
      return state;
  }
};

export default notesReducer;
