// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Union3IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Union3Icon(props: Union3IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 34 12"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M3.605 6.425a1.19 1.19 0 00-.114 1.679c.431.49 1.182.533 1.677.097 4.076-3.597 9.411-5.793 15.244-5.823 4.18-.022 8.09 1.07 11.457 2.998a1.196 1.196 0 001.625-.445 1.18 1.18 0 00-.428-1.621A25.097 25.097 0 0020.425 0C13.99.034 8.1 2.458 3.605 6.425zm-1.58 4.588c.467-.466.47-1.219.009-1.68a1.189 1.189 0 00-1.681.008 1.189 1.189 0 00-.01 1.681 1.189 1.189 0 001.682-.009z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Union3Icon;
/* prettier-ignore-end */
