import { GuardProps, UserRole } from '@/modules/common/types';
import { useMemo } from 'react';
import BaseGuard from '@/modules/common/guards/BaseGuard';
import { WrapperProps } from '@/modules/common/components/Wrapper';
import useAuth from '@/modules/auth/hooks';
import { useSelector } from 'react-redux';
import { IStore } from '@/store/types';
import React from 'react';

interface MapRoleGuardProps extends GuardProps, WrapperProps {
  /** Single or List of {@link UserRole} for which we want to display the content */
  roles?: UserRole | UserRole[];

  /** Single or List of {@link UserRole} for which we *don't* want to display the content */
  except?: UserRole | UserRole[];
}

/** Role of logged-in user on MapPage. This will return same result as RoleGuard for all roles except **guest** */
const MapRoleGuard = ({
  roles: allowedRoles,
  except: excludedRoles,
  fallback,
  children,
  or,
  ...rest
}: MapRoleGuardProps) => {
  const { role, loading, organizationId, user } = useAuth();
  const { sr_user_type_id } = user;
  const orderOrganizationId = useSelector<IStore, number>(
    (state) => state.order.orderOrganisation
  );

  const userRole = useMemo(() => {
    if (!orderOrganizationId) return null;
    /** If logged-in user organizationId is not equals to order organizationId, the user is opening page as "guest" */

    return organizationId === orderOrganizationId ? role : UserRole.Guest;
  }, [role, organizationId, orderOrganizationId]);

  const roleCondition = useMemo(() => {
    if (loading || !userRole) return false;
    if (sr_user_type_id == 1) return true;

    if (excludedRoles) {
      if (typeof excludedRoles === 'string') {
        return userRole !== excludedRoles;
      }
      return !excludedRoles.includes(userRole);
    }

    if (allowedRoles) {
      return typeof allowedRoles === 'string'
        ? userRole === allowedRoles
        : allowedRoles.includes(userRole);
    }

    return false;
  }, [userRole, loading, allowedRoles, excludedRoles]);

  const canRender = useMemo(() => {
    if (typeof or !== 'undefined') {
      return roleCondition || or;
    }
    return roleCondition;
  }, [roleCondition, or]);

  return (
    <BaseGuard condition={canRender} fallback={fallback} {...rest}>
      {children}
    </BaseGuard>
  );
};

MapRoleGuard.displayName = 'MapRoleGuard';
export default MapRoleGuard;
