export enum NotificationBarType {
  SubscriptionTerminated = 'SubscriptionTerminated',
  PaymentFailed = 'PaymentFailed',
  JobStatusUpdate = 'JobStatusUpdate',
  GuestUser = 'GuestUser',
  VerifyEmail = 'VerifyEmail',
}

export enum NotificationBarVariant {
  DEFAULT = "default",
  SUCCESS = "success",
}