interface IChipProps {
  content: string;
  backgroundColor?: string;
}

const Chip = ({ content, backgroundColor = '#6CBB2F' }: IChipProps) => {
  return (
    <>
      {content && (
        <span
          style={{
            backgroundColor: backgroundColor,
            color: '#ffffff',
            borderRadius: 10,
            fontSize: 10,
            fontWeight: 400,
            lineHeight: '15px',
            marginLeft: 10,
            padding: '2px 8px',
          }}
        >
          {content}
        </span>
      )}
    </>
  );
};

export default Chip;
