// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type KeepIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function KeepIcon(props: KeepIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 7 12"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M5.833 5.25L7 6.417v1.166H4.083v3.5l-.583.584-.583-.584v-3.5H0V6.417L1.167 5.25V1.167H.583V0h5.834v1.167h-.584V5.25zm-4.17 1.167h3.675l-.671-.671v-4.58H2.333v4.58l-.67.67z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default KeepIcon;
/* prettier-ignore-end */
