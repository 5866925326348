import { MapTool } from '@/modules/project/types';
import { useSubscription } from '@/modules/subscription/hooks';
import { SubscriptionFeature } from '@/modules/subscription/types';
import { config } from '@/modules/common/config';

export type MapToolAllowedFunction = (
  tool: MapTool | SubscriptionFeature
) => boolean;

interface UseMapToolGuardResponse {
  allowed: MapToolAllowedFunction;
}

const ToolFeatureMap = {
  [MapTool.LineSplit]: SubscriptionFeature.ToolLineSplit,
  [MapTool.LassoSlice]: SubscriptionFeature.ToolLassoSlice,
  [MapTool.Path]: SubscriptionFeature.ToolPath,
};

const getFeature = (
  tool: MapTool | SubscriptionFeature
): SubscriptionFeature | null => {
  // @ts-ignore
  if (Object.values(SubscriptionFeature).includes(tool)) {
    return tool as SubscriptionFeature;
  }

  if (ToolFeatureMap.hasOwnProperty(tool)) {
    // @ts-ignore
    return ToolFeatureMap[tool] as SubscriptionFeature;
  }

  return null;
};

const useMapToolGuard = (): UseMapToolGuardResponse => {
  const { showPaywall, getFeatureData } = useSubscription();

  const allowed: MapToolAllowedFunction = (tool) => {
    /** If the Subscriptions are disabled, we want to allow the map tool */
    if (!config.isSubscriptionsEnabled) return true;

    const feature = getFeature(tool);

    if (feature) {
      const { enabled } = getFeatureData(feature);

      if (!enabled) {
        showPaywall(feature, {
          title: 'Want to use this tool?',
        });
        return false;
      }
    }
    return true;
  };

  return { allowed };
};

export default useMapToolGuard;
