import React from 'react';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationToastContainer from './NotificationToastContainer';
import { NOTIFICATIONS_TYPES } from './types';
import './style.less';

export const showNotification = (
  type: NOTIFICATIONS_TYPES,
  content: string | React.ReactElement,
  options: ToastOptions = {}
) => {
  const toastContent =
    typeof content === 'string' ? (
      <NotificationToastContainer type={type} content={content} />
    ) : (
      content
    );

  toast(toastContent, { autoClose: 1000, toastId: `${type}1`, ...options });
};

const NotificationContainer: React.FunctionComponent<{}> = () => (
  <ToastContainer
    position='top-right'
    hideProgressBar
    closeOnClick={false}
    closeButton={false}
    pauseOnHover
  />
);

export default NotificationContainer;
