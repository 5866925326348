import '../Queue/style.less';

import { ReactComponent as CompletedIcon } from '../../assets/completed.svg';
import { ReactComponent as OngoingIcon } from '../../assets/ongoing.svg';
import { ReactComponent as ScheduledIcon } from '../../assets/scheduled.svg';

import { IQueue, QueueOrderStatus } from '../../utils/types';
import SingleQueueCard from '../QueueCard';

type SearchQueueProps = {
  users: any;
  queue: IQueue;
};

const SearchQueue = ({ queue, users }: SearchQueueProps) => {
  const { searchOrders, queueName } = queue;

  const createdBy = (userId: string): string =>
    users
      .filter((u: { id: string }) => u.id === userId)
      .map((u: { fn: string }) => u.fn)[0]
      .split(' ')[0];

  return (
    <div className='single-queue-container'>
      <div className='queue-top-head'>
        <h3>{queueName}</h3>
        <p>{searchOrders?.length} Orders</p>
      </div>
      <div className='queue-main-container'>
        <>
          {searchOrders &&
            searchOrders.map((order) => {
              return (
                <div key={order.orderId}>
                  {[
                    QueueOrderStatus.APPROVED,
                    QueueOrderStatus.RESOLVING_FEEDBACK,
                    QueueOrderStatus.AWAITING_APPROVAL,
                  ].includes(order.status) && (
                      <SingleQueueCard
                        key={order.orderId}
                        orderId={order.orderId}
                        orderHashed={order.orderHashed}
                        address={order.address}
                        city={order.city}
                        state={order.state}
                        orderTime={order.createdAt}
                        estimatedStartTime={order.estimatedStartTime}
                        estimatedDeliveryTime={order.estimatedDeliveryTime}
                        author={createdBy(order.createdBy)}
                        containerStyle={{ backgroundColor: '#FFF' }}
                        icon={<CompletedIcon />}
                        showOrderTime={false}
                      />
                    )}
                  {order.status === QueueOrderStatus.MEASUREMENT_ONGOING &&
                    new Date() > new Date(order.estimatedStartTime) && (
                      <SingleQueueCard
                        key={order.orderId}
                        orderId={order.orderId}
                        orderHashed={order.orderHashed}
                        address={order.address}
                        city={order.city}
                        state={order.state}
                        orderTime={order.createdAt}
                        estimatedStartTime={order.estimatedStartTime}
                        estimatedDeliveryTime={order.estimatedDeliveryTime}
                        author={createdBy(order.createdBy)}
                        containerStyle={{ backgroundColor: '#F4FCFF' }}
                        icon={<OngoingIcon />}
                        showOrderTime={true}
                      />
                    )}
                  {order.status === QueueOrderStatus.MEASUREMENT_ONGOING &&
                    new Date() < new Date(order.estimatedStartTime) && (
                      <SingleQueueCard
                        key={order.orderId}
                        orderId={order.orderId}
                        orderHashed={order.orderHashed}
                        address={order.address}
                        city={order.city}
                        state={order.state}
                        orderTime={order.createdAt}
                        estimatedStartTime={order.estimatedStartTime}
                        estimatedDeliveryTime={order.estimatedDeliveryTime}
                        author={createdBy(order.createdBy)}
                        containerStyle={{ backgroundColor: '#FFF' }}
                        icon={<ScheduledIcon />}
                        showOrderTime={true}
                      />
                    )}
                </div>
              );
            })}
        </>
      </div>
    </div>
  );
};

export default SearchQueue;
