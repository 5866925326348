export const ADD_ORDERS = 'ADD_ORDERS';
export const TOGGLE_REQUEST_PENDING_SCREEN = 'TOGGLE_REQUEST_PENDING_SCREEN';
export const CLEAR_ORDER_ARRAY = 'CLEAR_ORDER_ARRAY';
export const ADD_ALL_FILTERS = 'ADD_ALL_FILTERS';
export const UPDATE_ORDER_SEARCH = 'UPDATE_ORDER_SEARCH';
export const UPDATE_ORDER_PAGE = 'UPDATE_ORDER_PAGE';
export const ADD_BULK_VIEW_SUMMARY = 'ADD_BULK_VIEW_SUMMARY';
export const TOGGLE_DASHBOARD_LOADING = 'TOGGLE_DASHBOARD_LOADING';

export const ADD_SLICED_ORDER = 'ADD_SLICED_ORDER';
export const ADD_MORE_SLICED_ORDERS = 'ADD_MORE_SLICED_ORDERS';
export const UPDATE_A_ORDER_WITH_ID = 'UPDATE_A_ORDER_WITH_ID';
export const DELETE_ORDER_WITH_ID = 'DELETE_ORDER_WITH_ID';
export const UPDATE_BULK_SUMMERY_ORDER_COUNT =
  'UPDATE_BULK_SUMMERY_ORDER_COUNT';
export const UPDATE_TAG_BY_ID = 'UPDATE_TAG_BY_ID';
export const UPDATE_COUNT_BULK_ORDER_DUPLICATE =
  'UPDATE_COUNT_BULK_ORDER_DUPLICATE';
export const BULK_EDIT_DESCRIPTION = 'BULK_EDIT_DESCRIPTION';
export const UPDATE_BULK_SUMMERY = 'UPDATE_BULK_SUMMERY';
export const ADD_BULK_ORDER_ID = 'ADD_BULK_ORDER_ID';
export const RESET_DASHBOARD_STATE = 'RESET_DASHBOARD_STATE';
export const CLEAR_BULK_SUMMERY_WITH_ORDER = 'CLEAR_BULK_SUMMERY_WITH_ORDER';
export const EDIT_BULK_DESCRIPTION_IN_BULK_PAGE =
  'EDIT_BULK_DESCRIPTION_IN_BULK_PAGE';

export const SET_FILTER_DATA = 'SET_FILTER_DATA';
