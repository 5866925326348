import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RoleGuard from '@/modules/auth/guards/RoleGuard';
import { UserRole } from '@/modules/common/types';
import SubscriptionTerminatedNotificationBar from './SubscriptionTerminatedNotificationBar';
import GuestUserNotificationBar from './GuestUserNotificationBar';
import VerifyEmailNotificationBar from './VerifyEmailNotificationBar';
import PaymentFailedNotificationBar from './PaymentFailedNotificationBar';

const NotificationBar = () => {
  return (
    <>
      <RoleGuard roles={UserRole.Admin}>
        <Route exact path='/'>
          <SubscriptionTerminatedNotificationBar />
        </Route>
      </RoleGuard>

      <RoleGuard roles={[UserRole.Creator, UserRole.Admin]}>
        <Route exact path='/'>
          <PaymentFailedNotificationBar />
        </Route>
      </RoleGuard>

      <RoleGuard except={UserRole.Guest}>
        <VerifyEmailNotificationBar />
      </RoleGuard>

      <RoleGuard roles={UserRole.Guest}>
        <GuestUserNotificationBar />
      </RoleGuard>
    </>
  );
};

export default NotificationBar;
