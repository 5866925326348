import { trackEvents } from '../../../../helpers/utilities';
import { ProfileDropdownEvt } from '../../../../segment';
import { Workspace as StorybookWorkspace } from '@/modules/storybook/Subscription/types';

export enum MenuItem {
  EditProfile = 'Edit Profile',
  OrderQueues = 'Order Queues',
  ManageTeam = 'Manage Team',
  ManageSubscription = 'Manage Subscription',
  Settings = 'Settings',
  SignOut = 'Sign out',
}

export const getProfileDropdownSegmentEvt = (item) => {
  switch (item as MenuItem) {
    case MenuItem.EditProfile:
      return ProfileDropdownEvt.EditProfile;
    case MenuItem.OrderQueues:
      return ProfileDropdownEvt.OrderQueues;
    case MenuItem.ManageTeam:
      return ProfileDropdownEvt.ManageTeam;
    case MenuItem.ManageSubscription:
      return ProfileDropdownEvt.ManageSubscription;
    case MenuItem.Settings:
      return ProfileDropdownEvt.Settings;
    case MenuItem.SignOut:
      return ProfileDropdownEvt.Signout;
  }
};

export const sendWorkspaceTrackEvt = (workspace: StorybookWorkspace, event: string) => {
  const { id, name, planType, totalCredits, userRole } = workspace;

  trackEvents(event, {
    workspaceId: id,
    workspaceName: name,
    currentPlan: planType,
    workspaceBalance: totalCredits,
    role: userRole,
  });
};
