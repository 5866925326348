export const Strings = {
  ModalTitle: 'Organization',
  ModaleSubtitle: 'Order Queues',
  SearchPlaceholder: 'Search by Address or Order ID',
  FooterText1: 'Need to prioritize an order?',
  FooterText2: 'Contact us at support@siterecon.ai',
  OrderID: 'Order ID',
  CreatedBy: 'Created By',
  OrderTime: 'Order time',
  NeedQueues: 'Need more queues? ',
  AddQueButton: 'Add a Queue',
  RequestSent: 'Request sent successfully',
  RequestSentText:
    'We have received your request to add more queues. We will get in touch with you shortly. ',
};

export const DummyStrings = {
  EstimatedTime: '2d 17h',
};
