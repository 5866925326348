import { ID, IObject } from '@common/types';
import _get from 'lodash/get';

export enum AttributeDatatype {
  Decimal = 'decimal',
  SingleSelect = 'singleselect',
  MultiSelect = 'multiselect',
  Text = 'text',
}

export type AttributeValue = {
  [key in AttributeDatatype]?: IObject;
};

export interface FilterAttribute extends AttributeValue {
  attributeId: ID;
  canBeOrdered: boolean;
  datatype: AttributeDatatype;
  maintainer: string;
  name: string;
  orgId?: ID;
  status: string;
  layerNames: string[];
}

export const AREA_FILTER_ID = 'area-filter';

export const LENGTH_FILTER_ID = 'length-filter';

/** A helper function used to generate dummy attribute for Area and Length */
export const createDecimalAttribute = (
  id: string,
  name: string,
  organizationId
): FilterAttribute => {
  return {
    attributeId: id,
    name: name,
    maintainer: 'siterecon',
    canBeOrdered: false,
    datatype: AttributeDatatype.Decimal,
    decimal: {
      decimalPlaces: 2,
    },
    status: 'active',
    layerNames: ['All Layers'],
  };
};

export const buildFilterRequestPayload = (filters: any) => {
  const payload: any = { filter: [] };

  for (const filter of filters) {
    if (filter.attributeId === AREA_FILTER_ID) {
      payload.area = filter.numFilter;
      continue;
    }

    if (filter.attributeId === LENGTH_FILTER_ID) {
      payload.length = filter.numFilter;
      continue;
    }

    if (_get(filter, 'textFilter') && filter.textFilter.length) {
      payload.filter.push(filter);
    } else if (
      _get(filter, 'numFilter') &&
      (_get(filter, 'numFilter.minVal') || _get(filter, 'numFilter.maxVal'))
    ) {
      payload.filter.push(filter);
    }
  }

  return payload;
};
