import { ReactComponent as LowArrow } from '../../../../assets/lowArrow.svg';
import { ReactComponent as MeasureTool } from '../../../../assets/measure.svg';
import { ReactComponent as CommentsTool } from '../../../../assets/comments.svg';

export const ToolsTypes = {
  SelectTool: { title: 'Select Tool', action:'select', icon: <LowArrow /> },
  MeasureTool: { title: 'Measure Tool',action:'measuretool', icon: <MeasureTool /> },
  AddComments: { title: 'New Notes', action:'new_note',icon: <CommentsTool /> ,
},
};
