import { TEAM_MANAGEMENT } from './action_types';

export const getUserListRequest = () => ({
  type: TEAM_MANAGEMENT.GET_USER_LIST_REQUEST,
});

export const getUserListsuccess = (data) => ({
  type: TEAM_MANAGEMENT.GET_USER_LIST_SUCCESS,
  payload: data,
});

export const getUserListFailure = () => ({
  type: TEAM_MANAGEMENT.GET_USER_LIST_FAILURE,
});

export const getOrgListRequest = () => ({
  type: TEAM_MANAGEMENT.GET_ORG_LIST_REQUEST,
});

export const getOrgListSuccess = (data) => ({
  type: TEAM_MANAGEMENT.GET_ORG_LIST_SUCCESS,
  payload: data,
});

export const getOrgListFailure = () => ({
  type: TEAM_MANAGEMENT.GET_ORG_LIST_FAILURE,
});

export const invitedUserListRequest = () => ({
  type: TEAM_MANAGEMENT.INVITED_USER_LIST_REQUEST,
});

export const invitedUserListsuccess = (data) => ({
  type: TEAM_MANAGEMENT.INVITED_USER_LIST_SUCCESS,
  payload: data,
});

export const invitedUserListFailure = () => ({
  type: TEAM_MANAGEMENT.INVITED_USER_LIST_FAILURE,
});

export const sendInvitationRequest = () => ({
  type: TEAM_MANAGEMENT.SEND_INVITATION_REQUEST,
});

export const sendInvitationsuccess = (data) => ({
  type: TEAM_MANAGEMENT.SEND_INVITATION_SUCCESS,
  payload: data,
});

export const sendInvitationFailure = () => ({
  type: TEAM_MANAGEMENT.SEND_INVITATION_FAILURE,
});

export const deleteInvitationRequest = () => ({
  type: TEAM_MANAGEMENT.DELETE_INVITATION_REQUEST,
});

export const deleteInvitationsuccess = (data) => ({
  type: TEAM_MANAGEMENT.DELETE_INVITATION_SUCCESS,
  payload: data,
});

export const deleteInvitationFailure = () => ({
  type: TEAM_MANAGEMENT.DELETE_INVITATION_FAILURE,
});

export const getUserRoles = (payload) => ({
  type: TEAM_MANAGEMENT.GET_USER_ROLES,
  payload: payload,
});

export const setIsMailSent = (payload) => ({
  type: TEAM_MANAGEMENT.SET_MAIL_SENT,
  payload: payload,
});

export const joinRequestSuccess = (payload) => ({
  type: TEAM_MANAGEMENT.SET_JOIN_REQUEST,
  payload: payload,
});

export const resetAction = (payload) => ({
  type: TEAM_MANAGEMENT.RESET_ACTION,
  payload: payload,
});

export const openInviteModal = () => ({
  type: TEAM_MANAGEMENT.OPEN_INVITE_MODAL,
});

export const closeInviteModal = () => ({
  type: TEAM_MANAGEMENT.CLOSE_INVITE_MODAL,
});
