import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { useCallback, useEffect, useState } from 'react';

interface UseQueryResponse<ResponseType = any> {
  data: ResponseType;
  loading: boolean;
  error: any;
  refetch: () => Promise<ResponseType>;
}

const useQuery = <ResponseType = any>(url: string): UseQueryResponse => {
  const [data, setData] = useState<ResponseType | null>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const runQuery = useCallback(async () => {
    setLoading(true);

    try {
      const response = await getAxiosInstance().get<ResponseType>(url);
      setData(response.data);
      setError(null);
      return response.data;
    } catch (error) {
      setData(null);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [url]);

  useEffect(() => {
    runQuery();
  }, [runQuery]);

  return {
    data,
    loading,
    error,
    refetch: runQuery,
  };
};

export default useQuery;
