import { GuardProps } from '@/modules/common/types';
import Wrapper, { extractProps, WrapperProps, } from '@/modules/common/components/Wrapper';
import React, { forwardRef, memo, ReactElement, ReactNode } from 'react';

interface BaseGuardProps extends GuardProps, WrapperProps {
  condition: boolean;
}

/** Returns name of react component */
const getDisplayName = (element: ReactElement<any, any>) => {
  return element.type.displayName || element.type.name;
};

/** Accepts a {@link ReactElement | @link ReactNode} as an input, removes all the guards around it and returns the base child */
const getBase = (children: ReactElement<any, any> | ReactNode) => {
  /** If provided children is not valid, or array instead of single element. Return that */
  if (!React.isValidElement(children)) return children;

  let base: ReactElement<any, any> = children;
  let name = getDisplayName(base);

  while (name?.includes('Guard') || name?.includes('Tooltip')) {
    if (base.props.children) {
      base = base.props.children;
      name = getDisplayName(base);
    }
  }

  return base;
};

/** Note : We are assuming that when a fallback component | fallbackProps is provided, disabled and tooltip prop will not be present */
const BaseGuard = forwardRef<HTMLElement | HTMLDivElement, BaseGuardProps>(
  ({ children, fallback, fallbackProps = {}, condition, ...rest }, ref) => {
    const [wrapperProps, otherProps] = extractProps(rest);

    /** If we don't have any additional props */
    if (!Object.keys(wrapperProps).length && !Object.keys(otherProps).length) {
      /** If we don't want to show wrapper and, we don't have any other props, simply return the child or fallback */
      if (condition) {
        if (React.isValidElement(children)) {
          return children as JSX.Element;
        }
        return <>{children}</>;
      }

      if (Object.keys(fallbackProps).length) {
        /** If fallback Props are provided, we need to return the Children with Fallback Props */
        return (
          <Wrapper {...fallbackProps} ref={ref}>
            {getBase(children) as JSX.Element}
          </Wrapper>
        );
      }

      return <>{fallback || null}</>;
    }

    /** We have Both Wrapper (disabled | tooltip) and other props */
    if (Object.keys(wrapperProps).length && Object.keys(otherProps).length) {
      if (condition) {
        /** If condition is matching, we only want to pass other props than disabled|tooltip */
        return (
          <Wrapper {...otherProps} ref={ref}>
            {children as JSX.Element}
          </Wrapper>
        );
      }

      return (
        <Wrapper {...rest} {...fallbackProps} ref={ref}>
          {(fallback || getBase(children)) as JSX.Element}
        </Wrapper>
      );
    }

    /** We have only other props and no Wrapper Props */
    if (Object.keys(otherProps).length) {
      if (condition) {
        return (
          <Wrapper {...otherProps} ref={ref}>
            {children as JSX.Element}
          </Wrapper>
        );
      }

      return fallback ? (
        <Wrapper {...otherProps} {...fallbackProps} ref={ref}>
          {fallback as JSX.Element}
        </Wrapper>
      ) : null;
    }

    /** We have Only Wrapper Props and no other Props */
    if (condition) {
      return <>{children}</>;
    }

    return (
      <Wrapper {...wrapperProps} {...fallbackProps} ref={ref}>
        {(fallback || getBase(children)) as JSX.Element}
      </Wrapper>
    );
  }
);

BaseGuard.displayName = 'BaseGuard';

export default memo(BaseGuard);
