import { ModifySyncState } from '../../modules/common/types';
import { MAP_ACTIONS } from './action_types';
import omit from 'lodash/omit';

const INITIAL_STATE = {
  drawingFeaturePointHistoryRedoStack: [], //To maintain points that can be redone
  drawingFeaturePointHistoryUndoStack: [], //To maintain points that can be undone
  modifySyncState: ModifySyncState.UNSYNCED,
  modifyDraftEvents: [],
  lastSyncedOn: new Date(),
};
let newUndoStack = null;
let newRedoStack = null;

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case MAP_ACTIONS.DRAW_INTERACTION_ADD_POINT_TO_TRACK:
      return {
        ...state,
        drawingFeaturePointHistoryUndoStack: [
          ...state.drawingFeaturePointHistoryUndoStack,
          payload,
        ],
        drawingFeaturePointHistoryRedoStack: [],
      };
    case MAP_ACTIONS.DRAW_INTERACTION_LAST_POINT_REDO:
      const lastPointToRedo =
        state.drawingFeaturePointHistoryRedoStack[
          state.drawingFeaturePointHistoryRedoStack.length - 1
        ];
      newUndoStack = [...state.drawingFeaturePointHistoryUndoStack];
      newUndoStack.push(lastPointToRedo);
      newRedoStack = [...state.drawingFeaturePointHistoryRedoStack];
      newRedoStack.pop();
      return {
        ...state,
        drawingFeaturePointHistoryRedoStack: newRedoStack,
        drawingFeaturePointHistoryUndoStack: newUndoStack,
      };
    case MAP_ACTIONS.DRAW_INTERACTION_LAST_POINT_UNDO:
      const lastPointToUndo =
        state.drawingFeaturePointHistoryUndoStack[
          state.drawingFeaturePointHistoryUndoStack.length - 1
        ];
      newUndoStack = [...state.drawingFeaturePointHistoryUndoStack];
      newUndoStack.pop();
      newRedoStack = [...state.drawingFeaturePointHistoryRedoStack];
      newRedoStack.push(lastPointToUndo);
      return {
        ...state,
        drawingFeaturePointHistoryRedoStack: newRedoStack,
        drawingFeaturePointHistoryUndoStack: newUndoStack,
      };
    case MAP_ACTIONS.DRAW_INTERACTION_CLEAR_POINT_HISTORY:
      return {
        ...state,
        ...omit(INITIAL_STATE, [
          'modifySyncState',
          'modifyDraftEvents',
          'lastSyncedOn',
        ]),
      };
    case MAP_ACTIONS.MODIFY_SYNC_STATE:
      return {
        ...state,
        modifySyncState: payload,
      };
    case MAP_ACTIONS.SET_MODIFY_DRAFT_EVENTS:
      return {
        ...state,
        modifyDraftEvents: payload,
      };
    case MAP_ACTIONS.SET_LAST_SYNCED_ON:
      return {
        ...state,
        lastSyncedOn: payload,
      };
    default:
      return state;
  }
};
