// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector143IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector143Icon(props: Vector143IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 63 39"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M10.893 32.269C-8.147 20.223 2.035 5.865 9.507.19c12.235-1.164 8.412 3.273 4.97 5.637 1.339 3.928 9.454 11.566 31.21 10.693 21.755-.873 19.264 11.509 12.617 15.748-7.87 5.019-28.37 12.046-47.411 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector143Icon;
/* prettier-ignore-end */
