export const TEAM_MANAGEMENT = {
  GET_USER_LIST_REQUEST: 'GET_USER_LIST_REQUEST',
  GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
  GET_USER_LIST_FAILURE: 'GET_USER_LIST_FAILURE',
  GET_ORG_LIST_REQUEST: 'GET_ORG_LIST_REQUEST',
  GET_ORG_LIST_SUCCESS: 'GET_ORG_LIST_SUCCESS',
  GET_ORG_LIST_FAILURE: 'GET_ORG_LIST_FAILURE',
  INVITED_USER_LIST_REQUEST: 'INVITED_USER_LIST_REQUEST',
  INVITED_USER_LIST_SUCCESS: 'INVITED_USER_LIST_SUCCESS',
  INVITED_USER_LIST_FAILURE: 'INVITED_USER_LIST_FAILURE',
  SEND_INVITATION_REQUEST: 'SEND_INVITATION_REQUEST',
  SEND_INVITATION_SUCCESS: 'SEND_INVITATION_SUCCESS',
  SEND_INVITATION_FAILURE: 'SEND_INVITATION_FAILURE',
  GET_USER_ROLES: 'GET_USER_ROLES',
  SET_MAIL_SENT: 'SET_MAIL_SENT',
  DELETE_INVITATION_REQUEST: 'DELETE_INVITATION_REQUEST',
  DELETE_INVITATION_SUCCESS: 'DELETE_INVITATION_SUCCESS',
  DELETE_INVITATION_FAILURE: 'DELETE_INVITATION_FAILURE',
  SET_JOIN_REQUEST: 'SET_JOIN_REQUEST',
  RESET_ACTION: 'RESET_ACTION',
  OPEN_INVITE_MODAL: 'OPEN_INVITE_MODAL',
  CLOSE_INVITE_MODAL: 'CLOSE_INVITE_MODAL',
};
