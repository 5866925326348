// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Layer12IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Layer12Icon(props: Layer12IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M17.856 9.582c.44 0 .83-.008 1.22.001.646.015 1.07.662.878 1.378-.58 2.165-1.176 4.323-1.765 6.484-.134.489-.428.736-.863.751-.51.018-1.02.024-1.529.024-3.449.002-6.897 0-10.346.002-.422 0-.886 0-1.155-.411-.154-.236-.18-.242-.28-.889A1.43 1.43 0 014 16.716c0-3.243-.003-6.487.002-9.73.001-.775.397-1.206 1.072-1.206 1.38 0 2.759.003 4.138-.002.467-.001.797.23.999.717.12.29.191.689.393.825.218.147.562.052.851.052 1.68.002 3.359 0 5.038.001.837 0 1.338.564 1.36 1.53.005.216.001.433.001.679zm-10.76 1.04c-.586 2.168-1.173 4.332-1.764 6.513H17.32l1.765-6.513H7.095h.001zm-2.16 4.37l.03.005c.406-1.484.813-2.968 1.217-4.454.208-.764.43-.96 1.118-.96h9.634v-.556c0-.545-.06-.614-.535-.614-1.96 0-3.919-.004-5.878.003-.34.002-.57-.155-.713-.503-.11-.27-.234-.532-.333-.805-.079-.216-.187-.298-.402-.295-1.289.013-2.579.006-3.868.007-.087 0-.173.013-.27.02v8.152z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Layer12Icon;
/* prettier-ignore-end */
