// @ts-ignore
import { NotifyError } from '@/helpers/notification-utils';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';

const axiosInstance = getAxiosInstance();

export interface PostOrderRatingData {
  userRating: number;
  totalRating: number;
  suggestedFeedbacks: string[];
  comment: string | null;
}

export const postOrderRating = async (
  data: PostOrderRatingData,
  orderId: any
) => {
  const URL = `api/emporio/v1/order/${orderId}/rating`;

  return new Promise((resolve, reject) => {
    axiosInstance
      .post(URL, data)
      .then((response) => {
        resolve(response.status);
      })
      .catch((error) => {
        NotifyError('Something went wrong');
        reject(error);
      });
  });
};

export const getOrderRating = async (orderId: any) => {
  const URL = `api/emporio/v1/order/${orderId}/rating`;

  return new Promise((resolve, reject) => {
    axiosInstance
      .get(URL)
      .then((response) => {
        resolve(response.data);
        reject();
      })
      .catch((error) => {
        reject(error);
      });
  });
};
