const toTitleCase = (str) => {
    if (str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
};

const polygonArea = (properties) => {
    let polygonArea = 0;

    if (properties['Surface Area']) {
        polygonArea = properties['Surface Area'];
    } else if (properties['Area']) {
        polygonArea = properties['Area'];
    } else if (properties.area) {
        polygonArea = properties.area;
    }
    return polygonArea;
};
export const getComponentArea = (type, properties) => {
    let area = 0;
    switch (toTitleCase(type)) {
        case 'Polygon':
            area = polygonArea(properties) ?? properties.measurement;
            break;
        case 'Point':
            area = properties['Ring Area'] ?? properties.measurement;
            break;
        case 'Line':
            area = properties['Length'] ?? properties.measurement;
            break;
        case 'Path':
            area = properties['Length'] ?? properties.measurement;
            break;
        case 'Linestring':
            area = properties['Length'] ?? properties.measurement;
            break;
    }
    return !isNaN(area) ? parseFloat(area) : 0;
};
