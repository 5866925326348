import React from "react";

const ExclamationDark = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8101 7.80602C13.8588 5.9312 16.5428 5.93119 17.5914 7.80602L25.2445 21.489C26.273 23.3278 24.9517 25.5999 22.8539 25.5999H7.54767C5.44983 25.5999 4.12854 23.3278 5.15703 21.489L12.8101 7.80602ZM16.5721 21.4623C16.5721 22.224 15.9581 22.8415 15.2007 22.8415C14.4432 22.8415 13.8292 22.224 13.8292 21.4623C13.8292 20.7005 14.4432 20.083 15.2007 20.083C15.9581 20.083 16.5721 20.7005 16.5721 21.4623ZM15.2007 10.4282C14.4432 10.4282 13.8292 11.0457 13.8292 11.8075V15.9453C13.8292 16.707 14.4432 17.3245 15.2007 17.3245C15.9581 17.3245 16.5721 16.707 16.5721 15.9453V11.8075C16.5721 11.0457 15.9581 10.4282 15.2007 10.4282Z"
      fill="#333333"
    />
  </svg>
);

export default ExclamationDark;
