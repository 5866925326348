import React, { useEffect } from 'react';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import './style.less';
import { setActiveTool, setMapClassnames, toogleMeasureTool } from '../../../../../store/user/actions';
import { ToolsTypes } from '../toolsTypes';

const ToolsItem = ({ title,action, icon, onClick }) => {
  const activeTool = useSelector((state) => state.user.activeTool);
  const dispatch = useDispatch();

  /*as we are depending on the 'measureTool' redux state this is why I am updating this redux state.
  Ideally we should depend only on the 'activeTool' redux state only.
  */
  useEffect(() => {
    if (activeTool === ToolsTypes.MeasureTool.action) {
      dispatch(toogleMeasureTool(1));
    } else {
      dispatch(toogleMeasureTool(0));
    }
    // eslint-disable-next-line
  }, [activeTool]);

  const toolItemHandler = () => {
    if (ToolsTypes.AddComments.action === activeTool) {
      if (activeTool === action) {
        dispatch(setActiveTool(ToolsTypes.SelectTool.action));
        dispatch(setMapClassnames(''));
      } else {
        onClick();
      }
    } else {
      onClick();
    }
  };

  return (
    <Tooltip title={title}>
      <div
        className={
          activeTool === action
            ? 'tools-item-icon-active tool--navbar'
            : 'tool--navbar'
        } 
        style={{
          backgroundColor:
          activeTool == action
              ? '#ffd02b'
              : '',
        }}
        onClick={toolItemHandler}
      >
        {icon}
      </div>
    </Tooltip>
  );
};

export default ToolsItem;
