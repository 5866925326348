/** *
 * App Default Config
 * */

const CONFIG = {
  prodDomain: ['uat.siterecon.ai', 'app.siterecon.ai', 'dev.siterecon.ai'],
  crashHandling: {
    slack: {
      sendOnProduction: true,
      sendOnDevelopment: true,
      webHookUrl:
        'https://hooks.slack.com/services/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',
    },
  },
};

export default CONFIG;
