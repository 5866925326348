import { NotifyError } from '@/helpers/notification-utils';
import { Button, Col, Row, Upload } from 'antd';
import Text from '../../../../elements/text/Text';

const IconUpload = (props) => {
  return (
    <Row style={{ paddingTop: 36 }} justify={'end'} align={'bottom'}>
      <Col span={24} className={'px-1'} style={{ textAlign: 'center' }}>
        <Upload
          className={'icon-upload'}
          accept={'image/svg+xml'}
          previewFile={(file) => {}}
          showUploadList={false}
          customRequest={props?.dummyRequest}
          beforeUpload={(file) => {
            const isSvgFile = file.type === 'image/svg+xml';
            const isLt3M = file.size / 1024 / 1024 < 3;

            if (!isLt3M) {
              NotifyError('Image must smaller than 3MB!');
            }

            if (!isSvgFile) {
              NotifyError('You can only upload svg file!');
            }
            return isSvgFile && isLt3M;
          }}
          onChange={(e) => props?.uploadIcon(e)}
          fileList={props?.iconFileList}
          style={{ margin: '10px 0' }}
        >
          <Button size={'small'} type={'primary'}>
            Upload Image
          </Button>
        </Upload>
      </Col>
      <Col
        span={24}
        style={{ textAlign: 'center', paddingTop: 8, paddingBottom: 18 }}
      >
        <Text type='p8' color='gray-shade' className='description'>
          Recommended size: 15 x 15 pixels
        </Text>
        <br />
        <Text type='p8' color='gray-shade' className='description'>
          File type: svg
        </Text>
        <br />
        <Text type='p8' color='gray-shade' className='description'>
          Maximum size per file: 3 MB
        </Text>
      </Col>
    </Row>
  );
};
export default IconUpload;
