import { TEAM_MANAGEMENT } from './action_types';

const initState = {
  organisationData: [],
  invitedUserData: [],
  isSent: false,
  userRoles: [],
  joinRequestData: [],
  isInviteModalVisible: false,
};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case TEAM_MANAGEMENT.GET_USER_LIST_REQUEST:
      return {
        ...state,
      };

    case TEAM_MANAGEMENT.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        organisationUserData: payload,
      };

    case TEAM_MANAGEMENT.GET_USER_LIST_FAILURE:
      return {
        ...state,
      };

    case TEAM_MANAGEMENT.GET_ORG_LIST_REQUEST:
      return {
        ...state,
      };

    case TEAM_MANAGEMENT.GET_ORG_LIST_SUCCESS:
      return {
        ...state,
        organisationData: payload,
      };

    case TEAM_MANAGEMENT.GET_ORG_LIST_FAILURE:
      return {
        ...state,
      };

    case TEAM_MANAGEMENT.INVITED_USER_LIST_REQUEST:
      return {
        ...state,
      };

    case TEAM_MANAGEMENT.INVITED_USER_LIST_SUCCESS:
      return {
        ...state,
        invitedUserData: payload,
      };

    case TEAM_MANAGEMENT.INVITED_USER_LIST_FAILURE:
      return {
        ...state,
      };

    case TEAM_MANAGEMENT.SEND_INVITATION_REQUEST:
      return {
        ...state,
      };

    case TEAM_MANAGEMENT.SEND_INVITATION_SUCCESS:
      return {
        ...state,
        isSent: true,
      };

    case TEAM_MANAGEMENT.SEND_INVITATION_FAILURE:
      return {
        ...state,
      };

    case TEAM_MANAGEMENT.GET_USER_ROLES:
      return {
        ...state,
        userRoles: payload,
      };

    case TEAM_MANAGEMENT.SET_MAIL_SENT:
      return {
        ...state,
        isSent: payload,
      };
    case TEAM_MANAGEMENT.SET_JOIN_REQUEST:
      return {
        ...state,
        joinRequestData: payload,
      };
    case TEAM_MANAGEMENT.OPEN_INVITE_MODAL:
      return {
        ...state,
        isInviteModalVisible: true,
      };
    case TEAM_MANAGEMENT.CLOSE_INVITE_MODAL:
      return {
        ...state,
        isInviteModalVisible: false,
      };
    case TEAM_MANAGEMENT.RESET_ACTION:
      return {
        ...initState,
      };

    default:
      return state;
  }
};
