// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon111IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon111Icon(props: Icon111IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 20 20"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.184 15.313a.897.897 0 01-.66-.276.906.906 0 01-.274-.662v-5c0-.258.092-.479.275-.662a.903.903 0 01.662-.275H7.5v-1.25c0-.692.244-1.282.732-1.77a2.414 2.414 0 011.771-.73c.692 0 1.282.243 1.768.73.486.488.729 1.078.729 1.77v1.25h.313c.257 0 .478.091.662.275a.903.903 0 01.275.662v5a.902.902 0 01-.275.662.903.903 0 01-.663.275H7.184zm.003-.938h5.625v-5H7.189v5zm1.25-5.938h3.126v-1.25c0-.434-.152-.802-.456-1.106A1.507 1.507 0 0010 5.625c-.434 0-.803.152-1.107.456a1.507 1.507 0 00-.456 1.106v1.25z"
        }
        fill={"#AAA"}
      ></path>
    </svg>
  );
}

export default Icon111Icon;
/* prettier-ignore-end */
