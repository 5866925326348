import { getToken, isUserAuthenticated } from '@/helpers/auth';
import {
  BarVisibility,
  DataAccessRole,
  ID,
  UserRoleId,
} from '@/modules/common/types';
import { ToolType } from '@/components/containers/navbar/tools/types';

export type EmailVerified = '0' | '1';

export type NumberedStatus = 0 | 1;

export interface User {
  acceptance_mode: any;
  address: string | null;
  reset_key?: string;
  contact_number: string | number | null;
  created_at: string; // ISO TimeString
  data_license: string | null;
  date_joined: string; // ISO TimeString
  email: string;
  email_verified: EmailVerified;
  first_name: string;
  has_user_accepted_agreement: string;
  id: ID;
  invited_by: any;
  is_activated: any;
  is_active: boolean;
  is_deleted: boolean | null;
  is_existing_user: boolean;
  is_staff: boolean;
  job_title_id: number;
  last_login: string; // ISO TimeString
  last_name: string | null;
  organisation: string | null;
  organization_id: number;
  privacy_term: any;
  profile_image: string | null;
  seen_trial_popup: boolean;
  show_tools_video: NumberedStatus;
  source: string | null;
  sr_user_type_id: ID | null;
  totalcredits: number;
  updated_at: string; // ISO TimeString
  user_role_id: UserRoleId;
}

export interface OrganizationUser {
  data_access_role: DataAccessRole;
  em: string;
  fn: string;
  id: ID;
  is_user_deactivated: boolean;
  last_login: string; // ISO DateString
  order_creation_role_id: UserRoleId;
  profile_pic: string | null;
  role_id: UserRoleId;
}

export interface UserStore {
  info: User | {};
  token: string;
  isAuthenticated: boolean;
  toBeRemembered: boolean;
  isLoading: boolean;
  userActiveState: any;
  userUpdate: 'SUCCESS' | 'LOADING' | 'FAILURE' | null;
  isMailSent: boolean;
  isOrganisation: boolean;
  jobtitle: any[];
  avatarImages: any;
  newUser: number;
  isByLink: boolean;
  isPasswordTrue: boolean;
  reqId: ID | null;
  organisation: any;
  invitedBy: any;
  measureTool: 0;
  address: string;
  prevHash: string;
  nextHash: string;
  shareBtn: boolean;
  shareClick: boolean;
  slideState: any;
  nextAdd: string;
  prevAdd: string;
  nextCust: string;
  prevCust: string;
  nextStatus: string;
  prevStatus: string;
  onSnap: boolean;
  onKmlNav: boolean;
  otpInfo: string;
  activeTool: ToolType;
  shareMapId: ID | null;
  maskCredits: boolean;
  viewWidth: number;
  showMobileBlockedPopup: boolean;
  showMobileBlockedPopupMessage: string;
  showBulkLayout: boolean;
  isSwitchingWorkspace: boolean;
}

export const INITIAL_STATE: UserStore = {
  info: {},
  token: getToken(),
  isAuthenticated: Boolean(isUserAuthenticated()),
  toBeRemembered: true,
  isLoading: false,
  userActiveState: null,
  isMailSent: false,
  isOrganisation: false,
  jobtitle: [],
  avatarImages: null,
  newUser: 0,
  isByLink: false,
  isPasswordTrue: false,
  reqId: null,
  organisation: null,
  invitedBy: null,
  measureTool: 0,
  address: '',
  prevHash: '',
  nextHash: '',
  shareBtn: false,
  shareClick: false,
  slideState: null,
  nextAdd: '',
  prevAdd: '',
  nextCust: '',
  prevCust: '',
  nextStatus: '',
  prevStatus: '',
  onSnap: false,
  onKmlNav: false,
  otpInfo: '',
  activeTool: ToolType.Select,
  shareMapId: null,
  maskCredits: false,
  viewWidth: window.innerWidth,
  showMobileBlockedPopup: false,
  showMobileBlockedPopupMessage: '',
  showBulkLayout: false,
  userUpdate: null,
  isSwitchingWorkspace: false,
};
