export const UI_ACTIONS = {
  SET_SECONDARY_PANEL_WIDTH: 'SET_SECONDARY_PANEL_WIDTH',
  TOGGLE_SECONDARY_PANEL_VISIBILITY: 'TOGGLE_SECONDARY_PANEL_VISIBILITY',
  TOGGLE_FORGOT_PASSWORD_MODAL_VISIBILITY: 'TOGGLE_FORGOT_PASSWORD_MODAL_VISIBILITY',
  TOGGLE_CHECKOUT_LAYOUT_BULK: 'TOGGLE_CHECKOUT_LAYOUT_BULK',
  TOGGLE_PAYMENT_FAILED_TOASTER: 'TOGGLE_PAYMENT_FAILED_TOASTER',
  TOGGLE_CHECK_PAYMENT_STATUS: 'TOGGLE_CHECK_PAYMENT_STATUS',
  VIEW_PROPERTY_DETAIL_BULK: 'VIEW_PROPERTY_DETAIL_BULK',
  GLOBAL_ASSETS: 'GLOBAL_ASSETS',
  TOGGLE_ORDERS_LIST_PANEL_VISIBILITY: 'TOGGLE_ORDERS_LIST_PANEL_VISIBILITY',
  TOGGLE_LAYER_LEFT_PANEL: 'TOGGLE_LAYER_LEFT_PANEL',
  VISIBLE_LAYER_PANEL: 'VISIBLE_LAYER_PANEL',
  TOGGLE_ACTION_CENTER_CARD: 'TOGGLE_ACTION_CENTER_CARD',
  OPEN_SHARE_PROPERTY_MODAL: 'OPEN_SHARE_PROPERTY_MODAL',
  CLOSE_SHARE_PROPERTY_MODAL: 'CLOSE_SHARE_PROPERTY_MODAL',
  HIDE_NAVBAR_IF_UNAUTHORISED: 'HIDE_NAVBAR_IF_UNAUTHORISED',
  SHOW_VERTICES: 'SHOW_VERTICES',
  SHOW_ALERT: 'SHOW_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT',
  SHOW_NOTIFICATION_BAR: 'SHOW_NOTIFICATION_BAR',
  HIDE_NOTIFICATION_BAR: 'HIDE_NOTIFICATION_BAR',
  TOGGLE_NEARMAP_DEBUG: 'TOGGLE_NEARMAP_DEBUG',
  SHOW_HIDE_BASEMAP: 'SHOW_HIDE_BASEMAP',
  SHOW_HIDE_PARCEL: 'SHOW_HIDE_PARCEL',
  SHOW_HIDE_NEARMAP: 'SHOW_HIDE_NEARMAP',
  SWTCH_BASEMAP_TYPE: 'SWTCH_BASEMAP_TYPE',
  SET_PROPERTY_HAS_NEARMAP: 'SET_PROPERTY_HAS_NEARMAP',
  SET_CUSTOMER_INFO_DIALOG_VISIBILITY: 'SET_CUSTOMER_INFO_DIALOG_VISIBILITY',
  SHOW_FULL_SCREEN_LOADER: 'SHOW_FULL_SCREEN_LOADER'
};
