import { useEffect } from 'react';
import { Attribution } from '@siterecon/sr-component/dist/sr-component-library';
import { Box } from '@siterecon/sr-styles/dist/elements';
import { trackEvents } from '../../../helpers/utilities';
import { MapControlsEvt } from '../../../segment';
import './style.less';

const Footer = ({ darkMode = false }) => {
  useEffect(() => {
    trackEvents(MapControlsEvt.AttributionVisible);
  }, []);

  return (
    <Box
      id='map-attribution'
      className='map-attribution'
      style={{ right: '96px' }}
    >
      <Attribution darkMode={darkMode} />
    </Box>
  );
};

export default Footer;
