const INITIAL_STATE = {
  maskCredits: false,
  mapWatermark: null,
  orgLogo: null,
  orgIcon: null,
  customPricingMessage: null,
  isChannelPartner: false,
  channelPartnerName: null,
  boltProductionCharges: null,
  boltQCCharges: null,
  boltQCTime: null,
  isBoltQCEnabled: false,
  boltOperationStartTime: null,
  boltOperationEndTime: null,
  boltPlaceOrderEstimatedTime: null,
  boltPlaceOrderEstimatedCost: null,
  nearmapMaxZoomInParcelReview: 19,
  defaultMapService: null
};

export default INITIAL_STATE;
