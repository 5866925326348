// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type _IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function _Icon(props: _IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 8 8"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M4.166 2.434l-.565.572c.223.02.415.065.577.134.16.07.297.161.409.275.21.21.35.442.42.695.07.252.07.505 0 .757a1.523 1.523 0 01-.413.691L3.356 6.792a1.556 1.556 0 01-.692.416c-.253.07-.506.07-.758 0a1.512 1.512 0 01-.692-.416c-.21-.21-.35-.442-.42-.695a1.405 1.405 0 010-.758c.07-.252.208-.483.416-.69l.636-.636a1.46 1.46 0 01-.09-.892L.687 4.184a2.323 2.323 0 00-.61 1.01 2.034 2.034 0 000 1.104c.104.369.309.705.614 1.01.31.31.65.515 1.019.617.369.101.737.1 1.104-.004.37-.101.705-.303 1.008-.605l1.294-1.293a2.3 2.3 0 00.606-1.007c.104-.369.105-.738.003-1.107a2.263 2.263 0 00-.613-1.01 1.521 1.521 0 00-.394-.287 1.94 1.94 0 00-.55-.178zm-.33 3.132l.565-.569a1.95 1.95 0 01-.577-.137 1.354 1.354 0 01-.409-.271 1.576 1.576 0 01-.424-.699 1.405 1.405 0 010-.757c.07-.253.21-.483.42-.691l1.231-1.23c.214-.21.445-.35.696-.42.253-.07.504-.07.754 0 .253.067.486.207.7.42.208.208.347.44.416.695.072.252.072.505 0 .757-.07.25-.208.48-.416.688l-.636.635a1.508 1.508 0 01.093.892l1.067-1.063c.302-.302.504-.638.606-1.007a2 2 0 000-1.107 2.276 2.276 0 00-.61-1.01A2.3 2.3 0 006.293.075 2.03 2.03 0 005.185.08a2.281 2.281 0 00-1.007.61L2.89 1.976c-.305.305-.51.642-.614 1.01a2.034 2.034 0 000 1.104c.102.369.308.707.618 1.014.106.11.235.203.386.283.154.079.339.138.554.178z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default _Icon;
/* prettier-ignore-end */
