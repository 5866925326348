import {
    FEATURE_CREATION,
    NEW_ATTRIBUTES_AVAILABLE_TO_LINK,
    SET_ALL_ATTRIBUTES,
    SET_ALL_DELETED_ATTRIBUTES,
    SET_ALL_SERVICES,
    SET_ALL_SERVICE_ITEMS,
    SET_ATTRIBUTE_FEATURE_MULTI_POPUP_DATA,
    SET_ATTRIBUTE_FEATURE_POPUP_DATA,
    SET_ATTRIBUTE_FEATURE_SINGLE_POPUP_DATA,
    SET_CURRENT_LAYER,
    SET_FEATURE_TO_VISBLE,
    SET_LINK_NEW_ATTRIBUTE_MODAL,
    SET_NEW_ATTRIBUTES,
    SET_VISIBLE_ATTRIBUTE_MODAL,
    SET_VISIBLE_ATTRIBUTE_PANEL
} from "./action_types";

export const setCreationFormValue = (payload) => ({
  type: FEATURE_CREATION.SET_FEATURE_CREATION_FORM_VALUES,
  payload,
});

export const setAttributePopupData = (payload) => ({
  type: SET_ATTRIBUTE_FEATURE_POPUP_DATA,
  payload,
});

export const setAttributeModalVisiblity = (payload) => ({
  type: SET_VISIBLE_ATTRIBUTE_MODAL,
  payload,
});

export const setAttributeSinglePopupData = (payload) => ({
  type: SET_ATTRIBUTE_FEATURE_SINGLE_POPUP_DATA,
  payload,
});

export const setAllAttributes = (payload) => ({
  type: SET_ALL_ATTRIBUTES,
  payload,
});

export const setAllwithDeletedAttributes = (payload) => ({
  type: SET_ALL_DELETED_ATTRIBUTES,
  payload,
});

export const setAllServiceItems = (payload) => ({
  type: SET_ALL_SERVICE_ITEMS,
  payload,
});

export const setAllServices = (payload) => ({
  type: SET_ALL_SERVICES,
  payload,
});

export const setNewAttributes = (payload) => ({
  type: SET_NEW_ATTRIBUTES,
  payload,
});

export const setAttributeMultiPopupData = (payload) => ({
  type: SET_ATTRIBUTE_FEATURE_MULTI_POPUP_DATA,
  payload,
});

export const setAttributeFilterPanelVisible = (payload) => ({
  type: SET_VISIBLE_ATTRIBUTE_PANEL,
  payload,
});

export const setLinkNewAttributeVisible = (payload) => ({
  type: SET_LINK_NEW_ATTRIBUTE_MODAL,
  payload,
});

export const setNewAttributeAvailableToLink = (payload) => ({
  type: NEW_ATTRIBUTES_AVAILABLE_TO_LINK,
  payload,
});

export const setFeatureToVisible = (payload) => ({
  type: SET_FEATURE_TO_VISBLE,
  payload,
});

export const setCurrentLayer = (payload) => ({
  type: SET_CURRENT_LAYER,
  payload,
});
