import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import './style.less';
import ExclamationRed from '../../assets/icons/ExclamationRed';

export interface TopologyErrorsProps {
  topologyWarning: any;
  chosenLayer: any;
  topologyWarningStringsArray: any;
  ignoreTopoChecks: any;
  layerComponents;
  displayComponentName;
}

const TopologyErrors = ({
  topologyWarningStringsArray,
  topologyWarning,
  chosenLayer,
  layerComponents,
  ignoreTopoChecks,
  displayComponentName,
}: TopologyErrorsProps) => {
  const [componentIndexMapping, setComponentIndexMapping] = React.useState({});

  useEffect(() => {
    let layerComponentsMapping = {};

    if (layerComponents[chosenLayer?.layerId]?.length) {
      layerComponents[chosenLayer?.layerId].forEach(function (
        component,
        index
      ) {
        layerComponentsMapping[component.componentId] = displayComponentName(
          index + 1
        );
      });
    }
    setComponentIndexMapping(layerComponentsMapping);
  }, [layerComponents, chosenLayer?.layerId]);

  return (
    <Box className='topo-errors'>
      <Tooltip
        placement='bottomLeft'
        title={
          <ul
            style={{
              paddingLeft: 20,
              marginBottom: 0,
              width: 250,
              paddingRight: 20,
            }}
          >
            {topologyWarningStringsArray(
              topologyWarning[chosenLayer?.layerId],
              componentIndexMapping,
              null,
              chosenLayer.featureType
            ).map((list) => (
              <li style={{ fontSize: 12 }}>{list}</li>
            ))}
            <Typography
              align={'right'}
              style={{
                color: 'white',
                marginBottom: 0,
                fontWeight: 600,
                fontSize: 13,
                cursor: 'pointer',
              }}
              onClick={() => {
                ignoreTopoChecks(chosenLayer?.layerId);
              }}
            >
              Ignore All
            </Typography>
          </ul>
        }
      >
        <Box className='topo-errors-inner'>
          <ExclamationRed />
          <Typography
            fontWeight={500}
            fontSize={10}
            lineHeight={'16px'}
            style={{
              color: '#DB0B0B',
              gap: '4px',
              display: 'flex',
              width: '100%',
            }}
          >
            {' '}
            <span>Error:</span>
            <span
              style={{
                maxWidth: '85%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {componentIndexMapping
                ? topologyWarningStringsArray(
                    topologyWarning[chosenLayer?.layerId],
                    componentIndexMapping,
                    null,
                    chosenLayer.featureType
                  )[0]
                : ''}
            </span>
            {parseInt(
              topologyWarningStringsArray(
                topologyWarning[chosenLayer?.layerId],
                componentIndexMapping,
                null,
                chosenLayer.featureType
              ).length
            ) > 1 && (
              <span>
                +
                {parseInt(
                  topologyWarningStringsArray(
                    topologyWarning[chosenLayer?.layerId],
                    componentIndexMapping,
                    null,
                    chosenLayer.featureType
                  ).length
                ) - 1}{' '}
                More
              </span>
            )}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default TopologyErrors;
