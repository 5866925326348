// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector159IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector159Icon(props: Vector159IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 100 62"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M17.29 51.22C-12.933 32.1 3.23 9.309 15.09.303 34.51-1.544 28.442 5.5 22.98 9.251c2.124 6.235 15.005 18.358 49.538 16.972 34.532-1.385 30.578 18.268 20.027 24.997-12.492 7.966-45.031 19.12-75.255 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector159Icon;
/* prettier-ignore-end */
