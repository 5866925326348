// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector414IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector414Icon(props: Vector414IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 26 14"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M23.91 4.662h-6.992V2.183C16.918.977 16.043 0 14.963 0h-4.548c-1.08 0-1.956.977-1.956 2.183v2.479H1.467C.657 4.662 0 5.395 0 6.3v7.532h25.378V6.3c0-.906-.657-1.638-1.467-1.638h-.001z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector414Icon;
/* prettier-ignore-end */
