import useAuth from '@/modules/auth/hooks/index';
import { useSelector } from 'react-redux';
import { IStore } from '@/store/types';
import { Organization } from '@/store/teamManagement/state';

/** Returns Details regarding the workspace which user is currently logged-into. */
const useOrganization = (): Organization | null => {
  const { organizationId } = useAuth();

  const organizationData = useSelector<IStore, Organization[]>(
    (state) => state.teamManagement.organisationData
  );

  if (!organizationId || !organizationData) return null;

  const organization = organizationData.find(({ id }) => id === organizationId);

  return organization || null;
};

export default useOrganization;
