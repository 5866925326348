import { NotifyError } from '@/helpers/notification-utils';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { ReactComponent as BulkOrderIcon } from '../../../assets/bulkOrder/bulkOrderIcon.svg';
import Text from '../../elements/text/Text';

import { Link } from 'react-router-dom';
import { TAB_CATEGORY } from '../../pages/myProperties/utils/enum';
import './style.less';

import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import { replaceParams } from '@/helpers/utilities/linkUtils';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import RoleGuard from '@/modules/auth/guards/RoleGuard';
import { UserRole } from '@/modules/common/types';

const axiosInstance = getAxiosInstance();

const BulkOrderNameChip = ({
  bulkOrderId,
  bulkOrderName,
  onClick,
  className,
}) => {
  const [fetchedBulkOrderName, setFetchedBulkOrderName] = useState('');
  const [isBulkPage, setIsBulkPage] = useState(true);

  const fetchBulkOrderDetails = () => {
    axiosInstance
      .get(
        replaceParams(API_ENDPOINTS.GET_BULK_ORDER_SUMMARY, {
          ':projectId': bulkOrderId,
        })
      )
      .then((result) => {
        setFetchedBulkOrderName(result?.data?.data?.bo_name);
        setIsBulkPage(true);
      })
      .catch((error) => {
        setIsBulkPage(false);
        NotifyError("Couldn't load bulk order name, please refresh");
      });
  };

  useEffect(() => {
    if (bulkOrderId) {
      fetchBulkOrderDetails();
    }
    // eslint-disable-next-line
  }, [bulkOrderId]);

  return (
    <RoleGuard except={UserRole.Guest}>
      <>
        {(bulkOrderId || bulkOrderName) && isBulkPage ? (
          <Tooltip title={bulkOrderName || fetchedBulkOrderName}>
            <Link to={`/?tab=${TAB_CATEGORY.bulkView}&bulkId=${bulkOrderId}`}>
              <div
                className={classNames(
                  'bulk-order-name-chip-container',
                  className
                )}
                onClick={() => {
                  onClick && onClick();
                }}
                style={{
                  maxWidth: '128px',
                }}
              >
                <div className='order-name-background '>
                  <div className='bulk-icon'>
                    <BulkOrderIcon />
                  </div>

                  <Text type='p33' className='overflow'>
                    {bulkOrderName || fetchedBulkOrderName}
                  </Text>
                </div>
              </div>
            </Link>
          </Tooltip>
        ) : null}
      </>
    </RoleGuard>
  );
};

export default BulkOrderNameChip;
