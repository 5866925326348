import {
    CREATE_FEEDBACK,
    CREATE_NOTES,
    REMOVE_ATTACHMENTS,
    RESET_ATTACHMENTS,
    SET_ATTACHMENTS,
    SET_IS_FILTER,
    SET_IS_NOTES_PANEL_EXPANDED,
    SHOW_RESOLVE_NOTES,
    STORE_ORDER_NOTES,
    STORE_ORDER_NOTES_ORIGINAL
} from './action_types';

export const createNotes = (payload) => ({
  type: CREATE_NOTES,
  payload,
});
export const createFeedback = (payload) => ({
  type: CREATE_FEEDBACK,
  payload,
});
export const storeNotes = (payload) => ({
  type: STORE_ORDER_NOTES,
  payload,
});

export const storeNotesOriginal = (payload) => ({
  type: STORE_ORDER_NOTES_ORIGINAL,
  payload,
});

export const setResolveCheck = (payload) => ({
  type: SHOW_RESOLVE_NOTES,
  payload,
});

export const setIsFilterCheck = (payload) => ({
  type: SET_IS_FILTER,
  payload,
});

export const setIsNotesPanelExpanded = (payload) => ({
  type: SET_IS_NOTES_PANEL_EXPANDED,
  payload,
});

export const setAttachments = (payload) => ({
  type: SET_ATTACHMENTS,
  payload,
});

export const resetAttachments = (payload) => ({
  type: RESET_ATTACHMENTS,
  payload,
});

export const removeAttachments = (payload) => ({
  type: REMOVE_ATTACHMENTS,
  payload,
});
