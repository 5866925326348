import React from 'react';
import AccessGuard, {
  AccessGuardProps,
} from '@/modules/project/guards/AccessGuard';
import useAuth from '@/modules/auth/hooks';
import { SRUserTypes } from '../../../common/types';
import Wrapper from '../../../common/components/Wrapper';

const SRUserTypeAccessGuard = ({
  children,
  right,
  ...rest
}: AccessGuardProps) => {
  const { user } = useAuth();
  const { sr_user_type_id } = user;

  if (sr_user_type_id === SRUserTypes.CartoAdmin) {
    return <Wrapper>{children}</Wrapper>;
  }

  return (
    <AccessGuard right={right} {...rest}>
      {children}
    </AccessGuard>
  );
};

SRUserTypeAccessGuard.displayName = 'SRUserTypeAccessGuard';

export default SRUserTypeAccessGuard;
