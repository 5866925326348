import React from 'react';

const PointTool = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <rect
      x='8'
      y='8'
      width='8'
      height='8'
      rx='4'
      stroke='#333333'
      stroke-width='2'
    />
  </svg>
);

export default PointTool;
