import React from 'react';

const CutTool = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='24'
    viewBox='0 0 20 24'
    fill='none'
    {...props}
  >
    <path
      d='M10.7202 11.8206V12.0276L10.8665 12.174L12.5485 13.8567C8.71133 17.5042 4.20368 18.8338 1.0674 18.6009L14.9603 4.70703L16.2928 6.03892L10.8666 11.4661L10.7202 11.6125V11.8196V11.8206Z'
      stroke='#333333'
    />
  </svg>
);

export default CutTool;
