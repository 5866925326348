// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon37IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon37Icon(props: Icon37IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 20 20"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M5.008 11.38l4.459-4.564c.152-.16.33-.24.533-.24a.81.81 0 01.533.24l4.46 4.565a.644.644 0 01.198.48.646.646 0 01-.093.346.69.69 0 01-.24.246.646.646 0 01-.346.094.65.65 0 01-.492-.211L9.719 7.93h.568L5.98 12.336a.638.638 0 01-.492.21.646.646 0 01-.345-.093.74.74 0 01-.247-.246.679.679 0 01-.087-.346c0-.093.015-.181.046-.263a.642.642 0 01.153-.217z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon37Icon;
/* prettier-ignore-end */
