import { NotifyError } from '@/helpers/notification-utils';
import {
  BellOutlined,
  HomeOutlined,
  UserAddOutlined,
} from '@/components/elements/icons/antd-icons';
import {
  Badge,
  Button,
  Divider,
  Image,
  Menu,
  Popover,
  Row,
  Switch,
  Tooltip,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Dashboards } from '../../../assets/icons/Dashboards.svg';

import { ReactComponent as FeedIcon } from '../../../assets/feedIcon.svg';
import ExportDisabled from '../../../assets/images/ExportDisabled.svg';
import SnapNavDisabled from '../../../assets/images/SnapNavDisabled.svg';
import { ReactComponent as Bell } from '../../../assets/images/Bell.svg';
import { ReactComponent as MapPinLogo } from '../../../assets/mapPin.svg';
import { ReactComponent as PipeIcon } from '../../../assets/nvabar/pipe_icon.svg';
import { ReactComponent as SmallLogo } from '../../../assets/Siterecon Logomark.svg';
import {
  clearUserInfo,
  kmlFromNav,
  onShareClick,
  onShareMapId,
  setActiveTool,
  setMapClassnames,
  toogleMeasureTool,
} from '@/store/user/actions';

import { Link, withRouter } from 'react-router-dom';

import {
  findLayerStatus,
  getShareView,
  getViewInfo,
  trackEvents,
} from '@/helpers/utilities';
import Text from '../../elements/text/Text';
import WithRouterPlus from '../../../modules/router-plus/hoc/with-router-plus';
import './style.less';

import * as API_ENDPOINTS from '../../../helpers/constants/APIEndpoints';
import { replaceParams } from '@/helpers/utilities/linkUtils';
import { _isEmpty } from '@/helpers/utilities/lodashUtils';
import {
  bulkOrderReduxCleanup,
  closeBulkOrderDetailsModal,
  closeBulkOrderListModal,
  makeBulkOrderDetailsModalVisible,
} from '@/store/bulkOrder/actions';
import {
  addAllTags,
  addOrderIdForTag,
  addSuggestedTags,
  addTagsForAOrder,
  isBulkOrderTagModal,
  resetOrderData,
  uploadLayerOnMapModalOpen,
} from '@/store/order/actions';
import BulkOrderNameChip from '../../elements/bulk-order-name-chip/BulkOrderNameChip';
import KeyboardDetail from '../../elements/keyboard';
import AddTag from '../../pages/myProperties/components/properties/components/modals/addTag';
import {
  convertArrayToString,
  extractSuggestedTags,
  TAB_CATEGORY,
} from '@myProperties/utils/enum';
import {
  ORDER_STATUS_ALLOWED_FOR_GEOJSON_UPLOAD,
  ORDER_STATUS_ALLOWED_FOR_TABULA_PLACEMENT,
} from '@/components/utils';
import BulkProjectUpload from '../bulk-project-upload/BulkProjectUpload';
import FeedbackModal from '../feedback-modal/FeedbackModal';
import OrderQueueModal from '../OrderQueue/OrderQueueModal';
import { isResponsive } from '@myProperties/utils/properties.utils';

import DisabledIconTool from '@/components/containers/navbar/tools/project/disabled/DisabledIconTool';
import InviteMembersModal from '@/components/pages/team-management/modal/invite-members';
import { AccessRight, BetaFeature, UserRole } from '@/modules/common/types';
import RoleGuard, { WithRole } from '@/modules/auth/guards/RoleGuard';
import {
  closeOrderQueueModal,
} from '@/store/orderQueue/actions';
import {
  closeInviteModal,
  openInviteModal,
  resetAction,
} from '@/store/teamManagement/actions';
import {
  closeSharePropertyModal,
  openSharePropertyModal,
  toggleNearmapDebug,
} from '@/store/ui/actions';
import { ReactComponent as SitereconLogo } from '../../../components/containers/Partner/assets/sitercon.svg';
import { setBetaFeatureDefault } from '@/store/betaRelease/actions';
import { setDefault } from '@/store/configurations/actions';
import { clearOrderArray, resetDashboardState } from '@/store/dashboard/action';
import ShareProptertyModal from '../../pages/myProperties/components/properties/components/modals/shareProperty/index.tsx';
import BrandLogo from '../brand-logo/BrandLogo';

import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import OrderStatusGuard from '@/modules/project/guards/OrderStatus';
import WithAuth from '@/modules/auth/WithAuth';
import BetaReleaseGuard from '@/modules/common/guards/BetaRelease';
import SnapshotFlowDD from '@/components/containers/navbar/tools/project/Snapshot';
import ExportTool from '@/components/containers/navbar/tools/project/Export';
import MapRoleGuard from '@/modules/project/guards/MapRoleGuard';
import SRUserTypeAccessGuard from '@/modules/project/guards/SRUserTypeAccessGuard';
import AddTagTool from '@/components/containers/navbar/tools/project/AddTag';
import UploadGeoJson from '@/components/containers/navbar/tools/project/UploadGeoJson';
import UploadToTabula from '@/components/containers/navbar/tools/project/UploadToTabula';
import MapNavbarCenter from './map-navbar-center/MapNavbarCenter';
import PropertyName from '@/components/pages/myProperties/components/properties/components/DefaultCard/components/property-name';
import TagIconNav from '@/components/containers/navbar/assets/TagIconBorder.svg';
import UpdateOrderForm from './updateOrder';
import { getListOfTags } from '../../pages/myProperties/utils/dashboard.utils';
import { showNotification } from '../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../storybook/NotificationToast/types';
import SubscriptionHeaderWidgetOnNavbar from './actions/SubscriptionHeaderWidgetOnNavbar';
import NotificationBar from './notification-bar';
import ProfileDropdownWrapper from './profile-dropdown/wrapper';
import WorkspaceSwitcher from './actions/WorkspaceSwitcher/index'
import Tools from "./tools/Tools";
import { InsightsDashboardEvt } from '../../../segment';
import { setIsNotesPanelExpanded } from '../../../store/notes/actions';
import { toggleSnapshotV1Flow, toggleSnapshotV2Flow, togglePropertyReport } from "../../../store/order/actions";
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import NearmapLimitInfo from '../nearmap-oblique-selection-dd/components/NearmapLimitInfo';
import { MapViewType } from '../../pages/project/components/MapControls';
import { switchBasemap } from '../../../store/ui/actions';
import BetaTag from '@/components/elements/BetaTag';
import FullScreenLoader from '../../../modules/common/components/FullScreenLoader'
import { UserRoleId, ViewType } from '../../../modules/common/types';
import CreatePropertyDD from '../create-property-dd/CreatePropertyDD';

const axiosInstance = getAxiosInstance();

export const HelpItemsModalTypes = {
  FEEDBACK: 'FEEDBACK',
  WHATS_NEW: 'WHATS_NEW',
  KEYBOARD_SHORTCUTS: 'KEYBOARD_SHORTCUTS',
};

export const BULK_PATH = 'properties';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showJobTitleModal: false,
      showEmailVerifyModal: false,
      current: this.props.location.pathname,
      isFeedbackModalVisible: false,
      notifications: [],
      feedbackDesc: '',
      visible: false,
      profileMenuVisible: false,
      feedCount: 0,
      isBulkUploadModalOpen: false,
      openedKeyboardShortcutMenu: false,
      visiblePopHelp: false,
      activeBulkOrderDetails: null,
      addTagVisible: false,
      visibleNotification: false,
      isExportDownloaded: true,
      propertyName: props.propertyName,
    };
  }

  componentDidMount() {
    // this.props.setBetaFeatureDefault();
    getListOfTags().then((tags) => {
      const tagTemp = convertArrayToString(tags?.data?.tag, 'name');
      this.props.addAllTags(tagTemp);
      this.props.addSuggestedTags(extractSuggestedTags(tags?.data?.tag));
    });

    // Do not delete this.getFeedCounts() code. Intentionally commenting out.
    // this.getFeedCounts();
    // this.timer = setInterval(() => {
    //   if (window.location.pathname === '/feed') this.getFeedCounts();
    // }, 10000);
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);
  }

  dismissAddTag = (value) => this.setState({ addTagVisible: value });

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      propertyName: nextProps.propertyName,
    });
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        current: nextProps.location.pathname,
        propertyName: nextProps.propertyName,
      });
    }
  };

  handleTabulaUploadOrder = () => {
    axiosInstance
      .put(
        replaceParams(API_ENDPOINTS.REPUBLISH_ORDER, {
          ':orderId': this.props.orderId,
        })
      )
      .then((result) => {
        showNotification(
          NOTIFICATIONS_TYPES.SUCCESS, 'Uploaded to tabula');
      })
      .catch((error) => {
        NotifyError('Call to tabula failed');
      });
  };

  handleUserInvite = () => {
    this.props.openInviteModal();
    trackEvents('team-management__invite-members_clicked_navbar');
  };

  onExportExcel = (values) => {
    if (
      _isEmpty(getViewInfo(this.props.currentViewData, this.props.reduxView))
    ) {
      NotifyError('Export Excel not allow because base view does not exist.');
      return;
    }
    trackEvents('navbar__excel-export', {
      orderId: getShareView(this.props.currentViewData, this.props.reduxView)
        .orderId,
      viewId: getViewInfo(this.props.currentViewData, this.props.reduxView)
        .viewId,
      isBaseView: this.props.currentViewData.isBaseView,
      layerCount: this.props.layerList.length,
      layerList: findLayerStatus(this.props.selectDeselectFeature),
    });
    let orderHash = this.props.match.params.hash;
    if (!orderHash) {
      orderHash = this.props.selectedOrderList?.data?.orderHash;
    }
    this.setState({ isExportDownloaded: false });
    axiosInstance
      .get(
        replaceParams(API_ENDPOINTS.EXPORT_EXCEL, {
          ':viewId': values,
          ':hash': orderHash,
        }),
        {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          responseType: 'arraybuffer',
        }
      )
      .then((result) => {
        var blob = new Blob([result.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        var URL = window.URL || window.webkitURL;
        var downloadUrl = URL.createObjectURL(blob);
        document.location = downloadUrl;
        this.setState({ isExportDownloaded: true });
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Excel export initiated. Your file is being downloaded.');
      })
      .catch((error) => {
        this.setState({ isExportDownloaded: true });
        console.error('error===>', error);
      });
  };

  updatePropName = (value) => {
    this.setState({ propertyName: value });
  };

  render() {
    const { current, feedCount } = this.state;
    const { currentViewData } = this.props;

    let whatNavBarToShow = 'NO_NAVBAR';
    if (this.props.isNavbarHidden) {
      whatNavBarToShow = 'NO_NAVBAR';
    } else if (
      current !== '/createOrder' &&
      current.substring(1, 8) !== 'project' &&
      current.substring(1, 11) !== BULK_PATH
    ) {
      whatNavBarToShow = 'NAVBAR_1';
    } else if (this.props.propertyReportId !== -1) {
      whatNavBarToShow = 'REPORTS_STUDIO_NAVBAR';
    } else if (this.props.snapshotV2Flow) {
      whatNavBarToShow = 'SNAPSHOT_STUDIO_NAVBAR';
    } else {
      whatNavBarToShow = 'NAVBAR_2';
    }

    return (
      <div>
        <NotificationBar />
        {this.props.fullScreenLoaderVisibility.isVisible && <FullScreenLoader message={this.props.fullScreenLoaderVisibility.message} />}
        {isResponsive(this.props?.viewWidth) &&
          current !== '/createOrder' &&
          current.substring(1, 8) !== 'project' &&
          current.substring(1, 11) !== BULK_PATH &&
          !this.props.showBulkLayout && (
            <CreatePropertyDD
              clearOrderArray={() => {
                this.props.clearOrderArray({
                  tab: TAB_CATEGORY.me,
                });
                this.props.clearOrderArray({
                  tab: TAB_CATEGORY.all,
                });
              }}
              setIsBulkUploadModalOpen={() => {
                this.setState({ isBulkUploadModalOpen: true });
              }}
            />
          )}

        {whatNavBarToShow === 'NAVBAR_1' && (
          <nav id='navbar' ref={this.wrapperRef}>
            <div className='wrapper-first'>
              <Link to='/' className='navbar-logo-wrapper'>
                <BrandLogo brandLogoClasses='navbar-logo' />
              </Link>

              {!isResponsive(this.props?.viewWidth) && (
                <Menu
                  mode='horizontal'
                  selectedKeys={[current]}
                  style={{
                    color: 'grey',
                    textAlign: 'right',
                    position: 'relative',
                    bottom: '4px',
                    left: '30px',
                  }}
                  forceSubMenuRender={true}
                >
                  <Menu.Item key='/' className='navbar-item-li myproperty'>
                    <Link to='/' className='navbar-item-a'>
                      <HomeOutlined
                        style={{
                          fontSize: '16px',
                        }}
                      />
                      <span className='my-property-style home-property-style'>
                        My Properties
                      </span>
                    </Link>
                  </Menu.Item>

                  <BetaReleaseGuard feature={BetaFeature.Dashboards}>
                    <Menu.Item key='/reports' className='navbar-item-li'>
                      <Link to='/reports' className='navbar-item-a'>
                        <Dashboards />
                        <span className='dashboards-style home-property-style' onClick={() => trackEvents(InsightsDashboardEvt.DashboardTabClicked)}>
                          Dashboards
                        </span>
                      </Link>
                    </Menu.Item>
                  </BetaReleaseGuard>

                  {
                    <BetaReleaseGuard feature={BetaFeature.Insights}>
                      <RoleGuard roles={[UserRole.Admin, UserRole.Creator]}>
                        <Menu.Item key='/insights' className='navbar-item-li' onClick={() => trackEvents(InsightsDashboardEvt.SiteReconInsightsClicked, { source: 'insights-tab' })}>
                          <Link to='/insights' className='navbar-item-a' rel='noopener noreferrer' target="_blank">
                            <span className='dashboards-style home-property-style'>
                              Insights
                            </span>
                            &nbsp;
                            <Text type={'p32'} color={'dark-grey'} className='beta-navbar'>
                              Beta
                            </Text>
                          </Link>
                        </Menu.Item>
                      </RoleGuard>
                    </BetaReleaseGuard>
                  }

                  <BetaReleaseGuard feature={BetaFeature.Feed}>
                    <Menu.Item
                      key='/feed'
                      className='navbar-item-li myproperty'
                    >
                      <Link to='/feed' className='navbar-item-a'>
                        <Badge
                          size='small'
                          style={{ backgroundColor: 'red' }}
                          offset={[-20, -3]}
                          count={feedCount}
                        >
                          <FeedIcon
                            style={{
                              fontSize: '16px',
                              height: '16px',
                              width: '16px',
                              marginBottom: '-2px',
                            }}
                          />
                        </Badge>
                        <span className='my-property-style home-property-style '>
                          Feed
                        </span>
                      </Link>
                    </Menu.Item>
                  </BetaReleaseGuard>
                </Menu>
              )}
            </div>
            {!['app', 'prod'].includes(process.env.REACT_APP_ENV) && <WorkspaceSwitcher />}
            <div className='wrapper'>
              {!isResponsive(this.props?.viewWidth) && (
                <SubscriptionHeaderWidgetOnNavbar />
              )}

              {!isResponsive(this.props?.viewWidth) &&
                <CreatePropertyDD
                  clearOrderArray={() => {
                    this.props.clearOrderArray({
                      tab: TAB_CATEGORY.me,
                    });
                    this.props.clearOrderArray({
                      tab: TAB_CATEGORY.all,
                    });
                  }}
                  setIsBulkUploadModalOpen={() => {
                    this.setState({ isBulkUploadModalOpen: true });
                  }}
                />
              }
              <Menu
                mode='horizontal'
                selectedKeys={[current]}
                style={{ color: 'grey', textAlign: 'right' }}
                forceSubMenuRender={true}
              >
                <RoleGuard except={[UserRole.Viewer, UserRole.Guest]}>
                  {!isResponsive(this.props?.viewWidth) && (
                    <Menu.Item
                      key='/settings/teamManagement'
                      className='navbar-item-li invite-user'
                    >
                      <Tooltip placement='top' title='Invite'>
                        <div
                          className='navbar-item-a'
                          onClick={this.handleUserInvite}
                        >
                          <UserAddOutlined
                            style={{ fontSize: '16px', margin: 0 }}
                          />
                        </div>
                      </Tooltip>
                    </Menu.Item>
                  )}
                </RoleGuard>

                <Menu.Item className='navbar-item-li'>
                  <Popover
                    placement='bottomRight'
                    trigger='click'
                    overlayClassName='notification-popover'
                    visible={this.state.visibleNotification}
                    onVisibleChange={(visible) => {
                      this.setState({ visibleNotification: visible });
                    }}
                    content={
                      <div className='notification-container-class'>
                        <div>No notifications !</div>
                      </div>
                    }
                  >
                    <Tooltip placement='top' title='Notifications'>
                      <a
                        className='ant-dropdown-link navbar-item-a ant-dropdown-link-gray'
                        onClick={(e) => e.preventDefault()}
                        style={{ border: 'none' }}
                      >
                        <BellOutlined style={{ fontSize: '16px' }} />
                        {this.state.notifications.length > 0 ? (
                          <div className='new-notification'>
                            {
                              this.state.notifications.filter(
                                (item) =>
                                  item.status === 'unread' &&
                                  item.event !== 'NOTE_UPDATED'
                              ).length
                            }
                          </div>
                        ) : null}
                      </a>
                    </Tooltip>
                  </Popover>
                </Menu.Item>
              </Menu>
              <ProfileDropdownWrapper />
            </div>
            <FeedbackModal
              visible={this.state.isFeedbackModalVisible}
              onOk={() => this.setState({ isFeedbackModalVisible: false })}
              onCancel={() => this.setState({ isFeedbackModalVisible: false })}
            />
          </nav>
        )}
        {whatNavBarToShow === 'NAVBAR_2' && (
          <nav
            id='navbar-create'
            ref={this.wrapperRef}
            className={
              current.substring(1, 11) === BULK_PATH
                ? 'navbar--background'
                : ''
            }
          >
            {/* Address and Icon */}
            <div className='icon-address'>
              {/* icon */}
              {!this.props?.configurations?.isChannelPartner ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    cursor: 'pointer',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    this.props.history.push('/');

                    this.props.bulkOrderReduxCleanup();
                    this.props.resetOrderData();

                    this.props.redirect(
                      {},
                      { mode: 'search', keepQueryParams: false }
                    );
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <SitereconLogo
                      style={{ height: '20px', width: '20px' }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={'partner-nav-container'}
                  onClick={() => {
                    if (this.props.match?.params?.bulkOrderId) {
                      this.props.history.push(
                        `/?tab=${TAB_CATEGORY.bulkView}&bulkId=${this.props.match?.params?.bulkOrderId}`
                      );
                    } else {
                      this.props.history.push('/');
                    }
                    this.props.bulkOrderReduxCleanup();
                    this.props.resetOrderData();
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <SitereconLogo
                      style={{
                        height: '25px',
                        width: '20px',
                        marginRight: '10px',
                      }}
                    />
                    <PipeIcon />
                    <Image
                      preview={false}
                      src={this.props?.configurations?.orgIcon}
                      style={{ width: '25px', marginLeft: '10px' }}
                    />
                  </div>
                </div>
              )}

              {/* Address and property info */}
              <div
                className='address'
              // style={
              //   {paddingLeft: this.props.shareBtn? '70px' : '7px'}
              // }
              >
                <MapNavbarCenter
                  logo={
                    this.props?.configurations?.isChannelPartner ? (
                      <MapPinLogo />
                    ) : (
                      <SmallLogo />
                    )
                  }
                  property={
                    <div
                      id='propertyAddress'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {!this.props?.propertyName &&
                        current.substring(1, 11) !== BULK_PATH ? (
                        <Link to='/createOrder'>
                          <p
                            style={{ marginBottom: '0px' }}
                            className='order-text'
                          >
                            Create Order
                          </p>
                        </Link>
                      ) : (
                        <>
                          <RoleGuard
                            roles={UserRole.Admin}
                            or={
                              this.props.orderCreatorId ===
                              this.props.userId
                            }
                          >
                            {this.props?.bulkOrderId &&
                              this.props.match.params.hash && (
                                <BulkOrderNameChip
                                  bulkOrderId={this.props?.bulkOrderId}
                                  onClick={() =>
                                    this.props.makeBulkOrderDetailsModalVisible()
                                  }
                                />
                              )}
                          </RoleGuard>
                          <Tooltip
                            title={
                              this.state.propertyName
                                ? this.state.propertyName
                                : this.state.orderAddress
                            }
                          >
                            {this.props?.propertyName && (
                              <RoleGuard
                                roles={[UserRole.Admin, UserRole.Creator]}
                                fallback={
                                  <Tooltip
                                    title={
                                      this.state.propertyName
                                        ? this.state.propertyName
                                        : this.props.orderAddress
                                    }
                                    placement='top'
                                    overlayStyle={{ maxWidth: '500px' }}
                                  >
                                    <p
                                      style={{
                                        fontWeight: 500,
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        maxWidth: '700px',
                                      }}
                                      className='text-ellipsis'
                                    >
                                      {this.state.propertyName
                                        ? this.state.propertyName
                                        : this.props.orderAddress}
                                    </p>
                                  </Tooltip>
                                }
                              >
                                <div className='overflow'>
                                  <PropertyName
                                    value={
                                      this.state.propertyName
                                        ? this.state.propertyName
                                        : this.state.orderAddress
                                    }
                                    orderId={this.props.orderIdForTag}
                                    isMapPage={true}
                                    style={{
                                      fontWeight: 500,
                                      fontSize: '16px',
                                      lineHeight: '20px',
                                    }}
                                    inputStyle={{
                                      color: '#333333',
                                      maxWidth: '200px',
                                    }}
                                  // updatePropName={updatePropName}
                                  />
                                </div>
                              </RoleGuard>
                            )}
                          </Tooltip>
                        </>
                      )}
                    </div>
                  }
                  showPropertyName={
                    !(
                      !this.props.propertyName &&
                      current.substring(1, 11) !== BULK_PATH
                    )
                  }
                />
              </div>

              {!(
                !this.state?.propertyName &&
                current.substring(1, 11) !== BULK_PATH
              ) && (
                  <UpdateOrderForm
                    currentViewData={currentViewData}
                    props={this.props}
                    updatePropName={this.updatePropName}
                  />
                )}
            </div>

            {false && !['app', 'prod'].includes(process.env.REACT_APP_ENV) && <NearmapLimitInfo />}

            {/* Nearmap tile debug switch started */}
            {!['app', 'prod'].includes(process.env.REACT_APP_ENV) &&
              <div>
                <Typography
                  className='third-party-button-text pr-4'
                  fontWeight={600}
                  fontSize={'12px'}
                  lineHeight={'18px'}
                >
                  Nearmap Debug
                </Typography>
                <Switch defaultChecked={false} size='small' onChange={(checked, _) => {
                  this.props.toggleNearmapDebug(checked);
                }} />
              </div>
            }
            {/* Nearmap tile debug switch started */}

            {/* right half */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/***** Add Tags - Start *****/}
              {current !== '/createOrder' && (
                <div style={{ marginRight: '16px', display: 'flex' }}>
                  <SRUserTypeAccessGuard
                    right={AccessRight.Edit}
                    fallback={<img src={TagIconNav} alt='TagIconNav' />}
                    tooltip
                  >
                    <AddTagTool
                      currentPath={current}
                      orderIdForTag={this.props.orderIdForTag}
                      addOrderIdForTag={this.props.addOrderIdForTag}
                      selectedOrderList={this.props.selectedOrderList}
                      addTagsForAOrder={this.props.addTagsForAOrder}
                      bulkOrderSummeryData={this.props.bulkOrderSummeryData}
                      isBulkOrderTagModal={this.props.isBulkOrderTagModal}
                      orderTags={this.props?.orderTags}
                      setAddTagVisible={(value) =>
                        this.setState({ addTagVisible: value })
                      }
                      isRouteAvail={false}
                    />
                  </SRUserTypeAccessGuard>
                </div>
              )}
              {/***** Add Tags - End *****/}

              {/* Divider */}
              {current !== '/createOrder' && (
                <div>
                  <Divider
                    type='vertical'
                    style={{
                      height: '30px',
                      border: '0.25px solid #C4C4C4',
                    }}
                  />
                </div>
              )}

              {/* Tools */}
              <div
                style={{
                  marginLeft: '16px',
                  display: 'flex',
                  marginRight: '6px',
                }}
              >
                {/***** Upload Geojson - Start *******/}
                <BetaReleaseGuard feature={BetaFeature.GeoJsonUpload}>
                  <OrderStatusGuard
                    status={ORDER_STATUS_ALLOWED_FOR_GEOJSON_UPLOAD}
                  >
                    <UploadGeoJson
                      currentViewData={this.props.currentViewData}
                      onClick={this.props.uploadLayerOnMapModalOpen}
                    />
                  </OrderStatusGuard>
                </BetaReleaseGuard>
                {/***** Upload Geojson - End *******/}

                {/***** Upload Order To Tabula - Start *******/}
                <BetaReleaseGuard feature={BetaFeature.TabulaUpload}>
                  <OrderStatusGuard
                    status={ORDER_STATUS_ALLOWED_FOR_TABULA_PLACEMENT}
                  >
                    <UploadToTabula
                      onClick={this.handleTabulaUploadOrder}
                    />
                  </OrderStatusGuard>
                </BetaReleaseGuard>
                {/***** Upload Order To Tabula - End *******/}

                {/***** Snapshot Tool - Start *****/}
                {current !== '/createOrder' && (
                  <SRUserTypeAccessGuard
                    right={AccessRight.Edit}
                    fallback={
                      <div className='disabled-tool-container'>
                        <DisabledIconTool
                          title='Snapshot'
                          icon={SnapNavDisabled}
                        />
                      </div>
                    }
                  >
                    <SnapshotFlowDD
                      available={this.props.shareBtn}
                      editing={this.props.editingToolVal}
                      currentViewData={currentViewData}
                      layerList={this.props.layerList}
                      selectDeselectFeature={
                        this.props.selectDeselectFeature
                      }
                      snapshotV1Flow={this.props.snapshotV1Flow}
                      snapshotV2Flow={this.props.snapshotV2Flow}
                      toggleSnapshotV1Flow={(checked) => {
                        this.props.setIsNotesPanelExpanded(false);
                        this.props.toggleSnapshotV1Flow(checked);
                      }}
                      toggleSnapshotV2Flow={(checked) => {
                        this.props.switchBasemap(MapViewType.SATELLITE);
                        this.props.setIsNotesPanelExpanded(false);
                        this.props.toggleSnapshotV2Flow(checked);
                      }}
                      setIsNotesPanelExpanded={this.props.setIsNotesPanelExpanded}
                      togglePropertyReport={this.props.togglePropertyReport}
                      selectedNearmap={this.props.selectedNearmap}
                    />
                  </SRUserTypeAccessGuard>
                )}
                {/***** Snapshot Tool - End *****/}

                {/***** Export Tool - Start *****/}
                {current !== '/createOrder' && this.props.currentViewData?.viewType === ViewType.STATIC && (
                  <SRUserTypeAccessGuard
                    right={AccessRight.Edit}
                    fallback={
                      <div className='disabled-tool-container'>
                        <DisabledIconTool
                          title='Export'
                          icon={ExportDisabled}
                        />
                      </div>
                    }
                  >
                    <ExportTool
                      available={this.props.shareBtn}
                      editing={this.props.editingToolVal}
                      reduxView={this.props.reduxView}
                      currentViewData={currentViewData}
                      layerList={this.props.layerList}
                      selectDeselectFeature={
                        this.props.selectDeselectFeature
                      }
                      onExportExcel={this.onExportExcel}
                      kmlFromNav={this.props.kmlFromNav}
                      isDownloaded={this.state.isExportDownloaded}
                    />
                  </SRUserTypeAccessGuard>
                )}
                {/***** Export Tool - End *****/}

                {/* Measure Tool */}
                <Tools />
              </div>

              {/* Share Button */}
              {this.props.shareBtn && (
                <MapRoleGuard except={UserRole.Guest}>
                  <Tooltip title={[UserRoleId.Guest, UserRoleId.Viewer].includes(this.props.userRoleId) ? 'You are not authorised to perform this action.' : ''}>
                    <div style={{ marginRight: '9px' }}>
                      <Button
                        type='primary'
                        className='share-btn'
                        disabled={[UserRoleId.Guest, UserRoleId.Viewer].includes(this.props.userRoleId)}
                        onClick={() => {
                          this.props.openSharePropertyModal(true);
                          trackEvents(
                            'property-sharing__share_clicked_order-page',
                            {
                              orderId: this.props.orderId,
                              propertyName: this.state.propertyName,
                            }
                          );
                        }}
                      >

                        <Text type='p75'>Share</Text>
                      </Button>
                    </div>
                  </Tooltip>
                </MapRoleGuard>
              )}

              {/* Divider */}
              <div>
                <Divider
                  type='vertical'
                  style={{ height: '30px', border: '0.25px solid #C4C4C4' }}
                />
              </div>

              {/* Notifications Button */}
              <Popover
                placement='bottomRight'
                trigger='click'
                visible={this.state.visibleNotification}
                onVisibleChange={(visible) => {
                  this.setState({ visibleNotification: visible });
                }}
                content={
                  <div className='notification-container-class'>
                    <div>No notifications !</div>
                  </div>
                }
              >
                <div style={{ marginRight: '16px' }} className='help-btn'>
                  <Tooltip placement='top' title='Notifications'>
                    <div style={{ cursor: 'pointer', height: '16px' }}>
                      <Bell />
                      {this.state.notifications.length > 0 ? (
                        <div className='new-notification-nav'>
                          {
                            this.state.notifications.filter(
                              (item) =>
                                item.status === 'unread' &&
                                item.event !== 'NOTE_UPDATED'
                            ).length
                          }
                        </div>
                      ) : null}
                    </div>
                  </Tooltip>
                </div>
              </Popover>

              {/* Account Details */}
              <ProfileDropdownWrapper isMapPage={true} />
            </div>

            {this.props.bulkOrderListVisible && (
              <RecentBulkProjectsModal
                onOk={this.props.closeBulkOrderListModal}
                onCancel={this.props.closeBulkOrderListModal}
              />
            )}
          </nav>
        )}
        {['REPORTS_STUDIO_NAVBAR', 'SNAPSHOT_STUDIO_NAVBAR'].includes(whatNavBarToShow) &&
          <nav
            id='navbar-create'
            ref={this.wrapperRef}
          >
            <Row className='pl-4 pr-2 w-100' align='middle' justify='space-between'>
              <Row align='middle' className='d-flex' style={{ gap: '16px' }}>
                <SitereconLogo style={{ height: '20px', width: '20px' }} />
                {whatNavBarToShow === 'REPORTS_STUDIO_NAVBAR' && <Typography style={{ color: '#333' }} fontSize={16} fontWeight={500}> Reports Studio <BetaTag /></Typography>}
                {whatNavBarToShow === 'SNAPSHOT_STUDIO_NAVBAR' && <Typography style={{ color: '#333' }} fontSize={16} fontWeight={500}> Snapshot Studio <BetaTag /></Typography>}
              </Row>
              <ProfileDropdownWrapper isMapPage={true} />
            </Row>
          </nav>
          /**<Typography style={{color: '#333'}} fontSize={16} fontWeight={500} lineHeight={20}> Reports Studio <Typography style={{color:'#1055EB', fontStyle: 'italic' }} fontSize={10} fontWeight={600} lineHeight={12.8}>Beta</Typography></Typography> */
        }

        <FeedbackModal
          visible={this.state.isFeedbackModalVisible}
          onOk={() => this.setState({ isFeedbackModalVisible: false })}
          onCancel={() => this.setState({ isFeedbackModalVisible: false })}
        />

        {this.props.isInviteModalOpen && (
          <div className='invite-modal-overlay'>
            <InviteMembersModal
              isModalVisible={this.props.isInviteModalOpen}
              setModalVisible={() => this.props.closeInviteModal()}
            />
          </div>
        )}
        {this.props.isSharePropertyModalVisible && (
          <div className='invite-modal-overlay'>
            <ShareProptertyModal
              isModalVisible={this.props.isSharePropertyModalVisible}
              setModalVisible={(value) =>
                this.props.closeSharePropertyModal(value)
              }
              title={this.props?.address}
              orderHash={this.props.match.params.hash}
              orderId={currentViewData?.orderId}
            />
          </div>
        )}

        {/* 
         Plato preview image gets mounted in this div
        */}
        <div id='plato-preview-modal-div'></div>
        <div id='event-success-modal-div'></div>

        {this.props.isOrderQueueModalOpen && (
          <div className='order-queue-overlay'>
            <OrderQueueModal
              onClose={() => this.props.closeOrderQueueModal()}
            />
          </div>
        )}
        <BulkProjectUpload
          onBulkProjectClick={() => {
            this.setState({ isBulkUploadModalOpen: true });
          }}
          visible={this.state.isBulkUploadModalOpen}
          onOk={() => {
            this.setState({ isBulkUploadModalOpen: false });
          }}
          onCancel={() => {
            this.setState({ isBulkUploadModalOpen: false });
          }}
          onClick={() => {
            this.setState({ isBulkUploadModalOpen: false });
          }}
        />

        {this.state.addTagVisible && (
          <AddTag
            key={this.props?.orderId}
            visible={true}
            setVisible={this.dismissAddTag}
            isRouteAvail={false}
          />
        )}
        <KeyboardDetail />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    measureTool: state.user.measureTool,
    address: state.user.address,
    shareBtn: state.user.shareBtn,
    shareClick: state.user.shareClick,
    showingDetailsOfBulkOrder: state.bulkOrder.bulkOrderToShowDetailsOf,
    bulkOrderDetailsModalVisible: state.bulkOrder.bulkOrderDetailsModalVisible,
    bulkOrderListVisible: state.bulkOrder.bulkOrderListVisible,
    propertyName: state.order.orderData?.propertyName,
    customerName: state.order.orderData?.customerName,
    customerId: state.order.orderData?.customerId,
    description: state.order.orderData?.description,
    orderAddress: state.order.orderData?.address,
    bulkOrderId: state.order.orderData?.bulk_order,
    orderId: state.order.orderData?.id,
    orderIdForTag: state.order.orderData?.orderNo,
    reduxView: state.order.reduxView,
    currentViewData: state.order.currentViewData,
    editingToolVal: state.order.editingToolVal,
    selectedOrderList: state.bulkOrder.selectedOrderList,
    orderList: state.bulkOrder.orderList,
    viewWidth: state.user.viewWidth,
    showBulkLayout: state?.user?.showBulkLayout,
    bulkOrderSummeryData: state?.bulkOrder?.bulkOrderSummeryData,
    layerList: state.order.layerList,
    selectDeselectFeature: state.order.selectDeselectFeature,
    orderTags: state?.order?.orderData?.tags,
    configurations: state.configurations,
    isOrderQueueModalOpen: state.orderQueue.orderQueueModalVisible,
    isInviteModalOpen: state.teamManagement.isInviteModalVisible,
    isSharePropertyModalVisible: state.ui.isSharePropertyModalVisible,
    isNavbarHidden: state.ui.isNavbarHidden,
    orderCreatorId: state.order.creatorId,
    activeTool: state.user.activeTool,
    snapshotV1Flow: state.order.snapshotV1Flow,
    snapshotV2Flow: state.order.snapshotV2Flow,
    propertyReportId: state.order.propertyReportId,
    selectedNearmap: state.order.selectedNearmap,
    fullScreenLoaderVisibility: state.ui.fullScreenLoaderVisibility,
    userRoleId: state.user.info.user_role_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearUserInfo: (payload) => dispatch(clearUserInfo(payload)),
    toogleMeasureTool: (payload) => dispatch(toogleMeasureTool(payload)),
    onShareClick: (payload) => dispatch(onShareClick(payload)),
    closeBulkOrderDetailsModal: () => dispatch(closeBulkOrderDetailsModal()),
    bulkOrderReduxCleanup: () => dispatch(bulkOrderReduxCleanup()),
    closeBulkOrderListModal: () => dispatch(closeBulkOrderListModal()),
    makeBulkOrderDetailsModalVisible: () =>
      dispatch(makeBulkOrderDetailsModalVisible()),
    resetOrderData: () => dispatch(resetOrderData()),
    kmlFromNav: (payload) => dispatch(kmlFromNav(payload)),
    setActiveTool: (payload) => dispatch(setActiveTool(payload)),
    onShareMapId: (payload) => dispatch(onShareMapId(payload)),
    setMapClassnames: (payload) => dispatch(setMapClassnames(payload)),
    addOrderIdForTag: (payload) => dispatch(addOrderIdForTag(payload)),
    addAllTags: (payload) => dispatch(addAllTags(payload)),
    addSuggestedTags: (payload) => dispatch(addSuggestedTags(payload)),
    addTagsForAOrder: (payload) => dispatch(addTagsForAOrder(payload)),
    isBulkOrderTagModal: (payload) => dispatch(isBulkOrderTagModal(payload)),
    resetDashboardState: () => dispatch(resetDashboardState()),
    setBetaFeatureDefault: (payload) =>
      dispatch(setBetaFeatureDefault(payload)),
    clearOrderArray: (payload) => dispatch(clearOrderArray(payload)),
    setDefaultConfiguration: (payload) => dispatch(setDefault(payload)),
    resetAction: () => dispatch(resetAction()),
    closeOrderQueueModal: () => dispatch(closeOrderQueueModal()),
    uploadLayerOnMapModalOpen: () => dispatch(uploadLayerOnMapModalOpen()),
    closeInviteModal: () => dispatch(closeInviteModal()),
    openInviteModal: () => dispatch(openInviteModal()),
    openSharePropertyModal: (payload) =>
      dispatch(openSharePropertyModal(payload)),
    closeSharePropertyModal: (payload) =>
      dispatch(closeSharePropertyModal(payload)),
    setIsNotesPanelExpanded: (payload) =>
      dispatch(setIsNotesPanelExpanded(payload)),
    toggleSnapshotV1Flow: (payload) => dispatch(toggleSnapshotV1Flow(payload)),
    toggleSnapshotV2Flow: (payload) => dispatch(toggleSnapshotV2Flow(payload)),
    toggleNearmapDebug: (payload) => dispatch(toggleNearmapDebug(payload)),
    togglePropertyReport: (payload) => dispatch(togglePropertyReport(payload)),
    switchBasemap: (payload) => dispatch(switchBasemap(payload)),
  };
};

export default WithRouterPlus(WithAuth(
  WithRole(connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar))))
);
