export enum AttributeDatatype {
  MultiSelect = 'multiselect',
}

type AttributeValues = {
  [type in AttributeDatatype]?: {
    values: string[];
    default: string[];
  };
};

export interface Attribute extends AttributeValues {
  attributeId: number;
  name: string;
  datatype: AttributeDatatype.MultiSelect;
  status: 'active' | 'inactive';
  maintainer: string;
  canBeOrdered: boolean;
  orgId: number;
}

export enum FeatureType {
  POLYGON = 'polygon',
  POINT = 'point',
  PATH = 'path',
  LINE = 'line',
}
