import React from "react";

const Unpinned = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
<svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.42013 13.1933L7.45228 12.2254L5.13749 14.6842C4.99895 14.833 4.94796 15.0433 5.00291 15.239C5.05785 15.4347 5.21084 15.5877 5.40656 15.6427C5.60227 15.6976 5.81265 15.6465 5.96142 15.508L8.42013 13.1933ZM13.1001 10.804L12.2289 11.9749C12.4511 12.224 12.5695 12.5486 12.5603 12.8823C12.551 13.2158 12.4146 13.5334 12.1789 13.7698L11.516 14.4328C11.3695 14.5792 11.1323 14.5793 10.9857 14.4328L6.21277 9.65981C6.06634 9.51337 6.06624 9.2759 6.21268 9.12947L6.87564 8.4665C7.11197 8.23091 7.42953 8.09453 7.76317 8.08519C8.09669 8.07593 8.42126 8.19436 8.67041 8.4164L9.84092 7.54484L13.1001 10.804ZM14.3407 9.13656L15.1748 8.01536L15.4936 8.33409C15.7778 8.61779 16.1918 8.72833 16.5796 8.62418C16.9675 8.52021 17.2705 8.21718 17.3746 7.82923C17.4787 7.44137 17.368 7.02737 17.0844 6.74306L13.9024 3.56109C13.6181 3.27747 13.2041 3.16675 12.8163 3.27091C12.4283 3.37496 12.1253 3.67799 12.0213 4.06585C11.9172 4.45371 12.0278 4.8678 12.3115 5.15203L12.6286 5.46914L11.5078 6.30369L14.3407 9.13656Z"
      fill="#999999"
    />
    <rect
      x="5.7402"
      y="3.77441"
      width="2.53759"
      height="14.5921"
      rx="1.2688"
      transform="rotate(-45 5.7402 3.77441)"
      fill="#999999"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);

export default Unpinned;
