import {
  ActionCenterData,
  ActionCenterServiceItem,
  ActionCenterServiceWithComponentId,
  ApiServiceItem,
  EstimationLayerType,
  Feature,
  FeatureWithMeasurement,
  Layer,
  LayerPanelData,
  LayerPanelDataItem,
  LayerPanelServiceDataItem,
  LayerWithComponents,
  Service,
  ServiceItem,
  ServiceItemAssignment,
  ServiceItemChange,
  ServiceItemWithMeasurement,
  SingleComponent,
} from '../api/types';
import { ID, NumericId } from '../../common/types';
import omit from 'lodash/omit';
import { CheckedServiceItem } from '../../../components/pages/project/projectComponents/EstimationLayerPanel/types';
import {
  ServiceItemColumnData,
  ServiceItemColumnDataType,
} from '../types/service-item.types';
import { isEmpty } from 'lodash';

/** Transforms list of {@link ApiServiceItem} to {@link ServiceItem} supported by FE code */
export const transformApiServiceItems = (
  data: ApiServiceItem[]
): ServiceItem[] => {
  if (!data) return [];

  return data.map((item) => ({
    id: item.service_item_id,
    index: item.service_item_index,
    name: item.service_item_name,
    serviceId: item.service_id,
    serviceIndex: item.service_index,
    serviceName: item.service_name,
    color: item.service_item_color,
    featureIds: item.feature_ids ?? [],
    isDefault: item.is_default,
    isArchived: item.is_archived,
  }));
};

export const getServices = (data: ServiceItem[]): Service[] => {
  const services: Record<NumericId, Service> = {};

  for (const serviceItem of data) {
    if (!services.hasOwnProperty(serviceItem.serviceId)) {
      services[serviceItem.serviceId] = {
        serviceId: serviceItem.serviceId,
        serviceIndex: serviceItem.serviceIndex,
        serviceName: serviceItem.serviceName,
        serviceItems: [],
      };
    }

    services[serviceItem.serviceId].serviceItems.push(
      omit(serviceItem, ['serviceId', 'serviceName'])
    );
  }

  return Object.values(services);
};

export const getLayerPanelData = (
  layers: Layer[],
  components: Record<NumericId, SingleComponent[]>,
  serviceItems: ServiceItem[]
): LayerPanelData => {
  const serviceItemsData: Record<NumericId, Set<NumericId>> = {};
  const componentMap: Record<NumericId, SingleComponent> = {};
  const serviceItemsMap: Record<NumericId, ServiceItem> = {};
  const layersMap: Record<NumericId, Layer> = {};

  const unassignedLayerComponents: LayerWithComponents[] = [];
  const takeoffLayerComponents: LayerWithComponents[] = [];

  for (const serviceItem of serviceItems) {
    serviceItemsMap[serviceItem.id] = serviceItem;
  }

  for (const layer of layers) {
    layersMap[layer.layerId] = layer;
  }

  const initSetIfNotExists = (serviceItemId: NumericId) => {
    if (!serviceItemsData.hasOwnProperty(serviceItemId)) {
      serviceItemsData[serviceItemId] = new Set();
    }
  };

  for (const layerComponents of Object.values(components)) {
    for (const component of layerComponents) {
      /** Create a hash map for { ComponentId: Component } for optimized access */
      componentMap[component.componentId] = component;
    }
  }

  for (const layer of layers) {
    const layerComponents = components[layer.layerId];
    const unassigned: SingleComponent[] = [];

    if (layer.serviceItemIds?.length) {
      for (const serviceItemId of layer.serviceItemIds) {
        /** If layer contains any serviceItemIds, we need to map all the associated layer components to respected service item data */
        initSetIfNotExists(serviceItemId);

        for (const component of layerComponents) {
          serviceItemsData[serviceItemId].add(component.componentId);
        }
      }
    }

    takeoffLayerComponents.push({
      ...layer,
      name: `All - ${layer.name}`,
      type: EstimationLayerType.TAKEOFF,
      components: layerComponents,
      layerId: `TAKEOFF:${layer.layerId}`,
      featureType: layer.featureType,
      style: {
        fillColor: layer.style.fillColor,
        symbolColor: layer.style.symbolColor,
        symbolType: layer.style.symbolType,
        strokeColor: layer.style.color,
      },
    });

    for (const component of layerComponents) {
      if (!component.serviceItemIds?.length && !layer.serviceItemIds?.length) {
        /** The component does not have any service items */
        unassigned.push(component);
        continue;
      }

      /** For all the components in layer, if service item IDs exist, we need to add that component related to service item data */
      for (const serviceItemId of component.serviceItemIds) {
        initSetIfNotExists(serviceItemId);

        serviceItemsData[serviceItemId].add(component.componentId);
      }
    }

    if (unassigned.length > 0) {
      unassignedLayerComponents.push({
        ...layer,
        name: `Unassigned - ${layer.name}`,
        components: unassigned,
        type: EstimationLayerType.UNASSIGNED,
        layerId: `UNASSIGNED:${layer.layerId}`,
      });
    }
  }

  const response: LayerPanelData = [];

  for (const [serviceItemId, componentIds] of Object.entries(
    serviceItemsData
  )) {
    if (!serviceItemsMap.hasOwnProperty(serviceItemId)) {
      continue;
    }

    response.push({
      ...serviceItemsMap[serviceItemId],
      type: EstimationLayerType.SERVICE_ITEM,
      components: Array.from(componentIds).map(
        (componentId) => componentMap[componentId]
      ),
    });
  }

  response.push(...unassignedLayerComponents, ...takeoffLayerComponents);

  return response;
};

export const getActionCenterData = (
  components: SingleComponent[],
  serviceItems: ServiceItem[],

  features: Feature[],

  /** Do we want to include archived service items? */
  removeArchived: boolean = true,

  /** Do we want to filter out service items based on featureId? */
  featureId?: NumericId,

  layerName?: string /** If components is an empty array, use this to pass to return the layer naem */
): ActionCenterData => {
  const assignedServiceItems: Record<NumericId, NumericId[]> = {};
  const serviceItemsMap: Record<NumericId, ServiceItem> = {};
  const componentFeatureIds: Set<NumericId> = new Set();
  const allAssignedServiceItemIds: Set<NumericId> = new Set();

  const getFeatureNameById = (id: NumericId) =>
    features?.find((feature) => feature.featureId === id)?.name ?? '';

  for (const serviceItem of serviceItems) {
    serviceItemsMap[serviceItem.id] = serviceItem;
  }

  for (const component of components) {
    componentFeatureIds.add(component.featureId!);

    for (const serviceItemId of component.serviceItemIds ?? []) {
      if (!assignedServiceItems.hasOwnProperty(serviceItemId)) {
        assignedServiceItems[serviceItemId] = [];
      }

      assignedServiceItems[serviceItemId].push(component.componentId);
      allAssignedServiceItemIds.add(serviceItemId);
    }
  }

  const services: ActionCenterServiceWithComponentId[] = getServices(
    serviceItems
  )
    .map((service) => {
      const isLegacyService = service.serviceName
        .toLowerCase()
        .includes('legacy');
      const serviceItems: ActionCenterServiceItem[] = [];

      for (const item of service.serviceItems) {
        if (removeArchived && item.isArchived) {
          /** Service item is archived, we don't want to show that */
          continue;
        }

        if (
          (removeArchived && item.isArchived) ||
          (item.isArchived && !assignedServiceItems[item.id]?.length)
        ) {
          /** Service item is archived, show it only if it is assigned to any component and `removeArchived` is `false` */
          continue;
        }

        if (
          !isLegacyService &&
          featureId &&
          !item.featureIds.includes(featureId)
        ) {
          continue;
        }

        if (
          !isLegacyService &&
          Array.from(componentFeatureIds).every(
            (componentFeatureIds) =>
              !item.featureIds.includes(componentFeatureIds)
          )
        ) {
          /** We need to check if the service item contains any of the required `featureIds` for selected list of components. If yes, we need to disable that (happens in next step). Otherwise, don't need to show that item */
          continue;
        }

        const actionCenterServiceItem: ActionCenterServiceItem = {
          ...item,
          assigned: 0,
          isArchived: item.isArchived ?? false,
          isDefault: item.isDefault ?? false,
          disabled: {
            isDisabled: false,
          },
        };

        /** We need to check if service item contains all the required `featureIds` for selected list of component. If any of featureId is missing, we need to disable it */
        if (
          !isLegacyService &&
          Array.from(componentFeatureIds).some(
            (componentFeatureId) =>
              !item.featureIds.includes(componentFeatureId)
          )
        ) {
          actionCenterServiceItem.disabled = {
            isDisabled: true,
            message: `Service item can only be assigned to ${item.featureIds
              .map(getFeatureNameById)
              .join(
                ', '
              )} features. You have active selections other than these.`,
          };
        }

        serviceItems.push(actionCenterServiceItem);
      }

      return {
        ...service,
        assigned: new Set(),
        serviceItems: serviceItems.sort((a, b) => {
          const aIsDisabled = a.disabled?.isDisabled ? 1 : 0;
          const bIsDisabled = b.disabled?.isDisabled ? 1 : 0;

          /** First, compare by disabled status */
          if (aIsDisabled !== bIsDisabled) {
            return aIsDisabled - bIsDisabled;
          }

          /** If both have the same disabled status, compare by index */
          return a.index - b.index;
        }),
      };
    })
    .filter((service) => service.serviceItems.length > 0)
    .sort((serviceFirst, serviceSecond) => {
      /** Sort services to move service containing disabled items at last, also the legacy items */
      const firstHasDisabledItems = serviceFirst.serviceItems.some(
        (item) => item.disabled?.isDisabled
      );
      const isFirstLegacy = serviceFirst.serviceName
        .toLowerCase()
        .includes('legacy');

      const secondHasDisabledItems = serviceSecond.serviceItems.some(
        (item) => item.disabled?.isDisabled
      );

      const isSecondLegacy = serviceSecond.serviceName
        .toLowerCase()
        .includes('legacy');

      if (firstHasDisabledItems && secondHasDisabledItems) {
        return 0;
      }

      if (firstHasDisabledItems && !secondHasDisabledItems) {
        return 1;
      }

      if (!firstHasDisabledItems && secondHasDisabledItems) {
        return -1;
      }

      /** !firstHasDisabledItems && !secondHasDisabledItems */

      if (isFirstLegacy && !isSecondLegacy) {
        return 1;
      }

      if (!isFirstLegacy && isSecondLegacy) {
        return -1;
      }

      /** Both are either legacy or non-legacy, so sort by serviceIndex */
      return serviceFirst.serviceIndex - serviceSecond.serviceIndex;
    });

  if (Object.keys(assignedServiceItems).length > 0) {
    services.forEach((service, index) => {
      service.serviceItems.forEach((serviceItem, serviceItemIndex) => {
        const assignedComponentIds = assignedServiceItems[serviceItem.id];

        if (assignedComponentIds) {
          (assignedComponentIds as NumericId[]).forEach((componentId) =>
            services[index].assigned.add(componentId)
          );

          services[index].serviceItems[serviceItemIndex].assigned =
            assignedComponentIds.length;
        }
      });
    });
  }

  return {
    totalSelected: components.length,
    selectedLayer:
      components[0]?.name ?? components[0]?.properties?.name ?? layerName,
    services: services.map((service: ActionCenterServiceWithComponentId) => {
      return {
        ...service,
        assigned: service.assigned.size,
      };
    }),
    // @ts-ignore
    serviceItemIds: allAssignedServiceItemIds,
  };
};

export const toPlasmicEstimationLayerPanel = (
  data: LayerPanelData,
  slaveCheckbox,
  currentSelectedMasterCheckbox,
  unCheckedMapState
) => {
  let masterExpandedState = {};
  let masterCheckBoxState = {};
  let unCheckedMapData = {};
  let slaveCheckBoxState = {};
  let slaveSelectionState = {};

  let _isFirstRownShown = false;

  // let masterCheckBoxUnCheckedState = {};
  // checkedStates
  let masterCheckBoxCheckedState = {};
  // uncheckedStates
  let masterCheckBoxUnCheckedState = {};
  let slaveCheckBoxUnCheckedState = {};
  let slaveCheckBoxCheckedState = {};

  const serviceItems = data
    .filter((item) => item.components.length !== 0)
    .map((item, mainIdx) => {
      const isLayer = !!(item as LayerWithComponents).layerId;
      const id = isLayer ? item.layerId : item.id;

      const _isMasterCheckboxPresent = slaveCheckbox[id];

      // Initialize checkbox states
      masterExpandedState[id] = false;
      masterCheckBoxState[id] =
        currentSelectedMasterCheckbox[id] !== undefined
          ? currentSelectedMasterCheckbox[id]
          : true;
      masterCheckBoxCheckedState[id] = true;
      masterCheckBoxUnCheckedState[id] = false;

      unCheckedMapData[id] =
        unCheckedMapState[id] !== undefined ? unCheckedMapState[id] : 0;
      slaveCheckBoxState[id] = {};
      slaveSelectionState[id] = {};
      slaveCheckBoxUnCheckedState[id] = {};
      slaveCheckBoxCheckedState[id] = {};

      let measurement = 0;

      let showBaseViewHeader = false;

      if (item.type === 'takeoff' && _isFirstRownShown === false) {
        showBaseViewHeader = true;
        _isFirstRownShown = true;
      }

      const components = item.components.map((component, idx) => {
        const componentId = component.componentId;
        // Initialize slave checkbox state

        slaveCheckBoxState[id][componentId] = _isMasterCheckboxPresent
          ? slaveCheckbox[id][componentId]
          : true;

        slaveSelectionState[id][componentId] = mainIdx === 0 ? true : false;

        slaveCheckBoxUnCheckedState[id][componentId] = false;

        slaveCheckBoxCheckedState[id][componentId] = true;

        if (component.unit === 'N') {
          measurement = idx + 1;
        } else {
          measurement += component.area;
        }

        return {
          id: componentId,
          parentId: id,
          name: isLayer
            ? component.componentName
            : `${component.name} ${component.componentName}`,
          measurement: component.area,
        };
      });

      return {
        id: id,
        name: item.name,
        color: item.color,
        unit: item.components[0]?.unit,
        type: item.type,
        showBaseViewHeader,
        measurement,
        style: isLayer ? item.style : null,
        featureType: isLayer ? item.featureType : null,
        components: components,
      };
    });

  return {
    header: ['Service items', 'Measurements'],
    serviceItems: serviceItems,
    masterCheckBoxState: masterCheckBoxState,
    masterCheckBoxCheckedState,
    masterCheckBoxUnCheckedState,
    masterExpandedState,
    slaveCheckBoxState: slaveCheckBoxState,
    slaveSelectionState,
    slaveCheckBoxUnCheckedState,
    slaveCheckBoxCheckedState,
    unCheckedMapData,
  };
};

const getComponentsMeasurement = (components: SingleComponent[]) => {
  return components
    .map((component) => component.area)
    .reduce((acc, val) => acc + val, 0);
};

export const toEstimationFFData = (
  data: LayerPanelData,
  layers: Layer[] = []
): [ServiceItemWithMeasurement[], FeatureWithMeasurement[]] => {
  const layersMap: Record<NumericId, Layer> = {};

  layers.forEach((layer) => {
    layersMap[layer.layerId] = layer;
  });

  const featuresWithMeasurement: Record<number, FeatureWithMeasurement> = {};

  const originalOutput: ServiceItemWithMeasurement[] = data
    .map((item) => {
      const layerComponents: Record<NumericId, SingleComponent[]> = {};
      for (const component of item.components) {
        if (!layerComponents.hasOwnProperty(component.layerId)) {
          layerComponents[component.layerId] = [];
        }
        layerComponents[component.layerId].push(component);
      }

      const linkedFeatures = Object.entries(layerComponents)
        .map(([layerId, components]) => ({
          feature_id: layersMap[layerId]?.featureId,
          measurement:
            layersMap[layerId]?.featureType === 'point'
              ? components.length
              : getComponentsMeasurement(components),
        }))
        .filter((item) => !!item.feature_id);

      Object.entries(layerComponents).forEach(([layerId, components]) => {
        const featureId = layersMap[layerId]?.featureId;
        if (!featureId) return;

        const measurement =
          layersMap[layerId]?.featureType === 'point'
            ? components.length
            : getComponentsMeasurement(components);
        const unit = components[0]?.unit || 'sq ft';
        const feature_name = layersMap[layerId]?.name || '';

        if (!featuresWithMeasurement[featureId]) {
          featuresWithMeasurement[featureId] = {
            feature_id: featureId,
            feature_name,
            measurement,
            unit,
          };
        } else {
          featuresWithMeasurement[featureId].measurement += measurement;
        }
      });

      if (item.id) {
        return {
          service_item_name: item.name,
          service_item_id: item.id,
          linked_features: linkedFeatures,
          measurement:
            item.components[0]?.unit === 'N'
              ? item.components.length
              : getComponentsMeasurement(item.components),
          unit: item.components[0]?.unit || 'sq ft',
        };
      } else {
        return null;
      }
    })
    .filter(Boolean) as ServiceItemWithMeasurement[];

  const summedUpFeatures = Object.values(featuresWithMeasurement);

  return [originalOutput, summedUpFeatures];
};

export const toCheckedItems = (data: LayerPanelData): CheckedServiceItem[] => {
  return data.map((item) => {
    const isLayer = !!(item as LayerWithComponents).layerId;
    const id = isLayer
      ? (item as LayerWithComponents).layerId
      : (item as LayerPanelServiceDataItem).id;

    return {
      id: id,
      name: item.name,
      checkedComponents: item.components.map(
        (component) => component.componentId
      ),
    };
  });
};

export const toCheckedItemsWithPreviousData = (
  data: LayerPanelData,
  previousData: LayerPanelData | null,
  previousCheckedItems: CheckedServiceItem[]
) => {
  console.log('DATA_UPDATE', data, previousData);

  const previousDataMap: Record<NumericId, LayerPanelDataItem> = {};

  const previousCheckedItemsMap: Record<NumericId, CheckedServiceItem> = {};
  const previousCheckedComponentIds = new Set<NumericId>();

  previousCheckedItems.forEach((item) => {
    previousCheckedItemsMap[item.id] = item;

    item.checkedComponents.forEach((componentId) => {
      previousCheckedComponentIds.add(componentId);
    });
  });

  previousData?.forEach((item) => {
    const id = item.layerId ?? item.id;
    previousDataMap[id] = item;
  });

  return data.map((item) => {
    const isLayer = !!(item as LayerWithComponents).layerId;
    const id = isLayer
      ? (item as LayerWithComponents).layerId
      : (item as LayerPanelServiceDataItem).id;

    const getNewAssignedComponentIds = () => {
      if (!previousDataMap[id]) return [];

      const previousItemComponentIds = previousDataMap[id].components.map(
        (_component: SingleComponent) => _component.componentId
      );

      return item.components
        .filter(
          (_component) =>
            !previousItemComponentIds.includes(_component.componentId)
        )
        .map((_component) => _component.componentId);
    };

    const isNewServiceItem = !previousCheckedItemsMap.hasOwnProperty(id);
    const serviceItemComponentIds = item.components.map(
      (component) => component.componentId
    );
    const newlyAssignedComponentIds = getNewAssignedComponentIds();

    if (!isNewServiceItem && !id.toString().includes('UNASSIGNED')) {
      const previousServiceItem: CheckedServiceItem =
        previousCheckedItemsMap[id];

      return {
        ...previousServiceItem,
        checkedComponents: [
          ...previousServiceItem.checkedComponents.filter((componentId) =>
            serviceItemComponentIds.includes(componentId)
          ),
          ...newlyAssignedComponentIds,
        ],
      };
    }

    /**  For new service item, we need to mark only currently selected items as "checked" */
    const checkedComponents = item.components
      .filter((component) =>
        previousCheckedComponentIds.has(component.componentId)
      )
      .map((component) => component.componentId);

    return {
      id: id,
      name: item.name,
      checkedComponents: [...checkedComponents, ...newlyAssignedComponentIds],
    };
  });
};

export const getServiceItemAssignments = (
  data: LayerPanelData
): ServiceItemAssignment[] => {
  const assignments: ServiceItemAssignment[] = [];

  for (const serviceItem of data) {
    if (!serviceItem.id) continue;

    const assignmentItem: ServiceItemAssignment = [
      serviceItem.id,
      ...serviceItem.components.map((component) => component.componentId),
    ];

    assignments.push(assignmentItem);
  }

  return assignments;
};

export const mutateServiceItemAssignments = (
  data: ServiceItemAssignment[],
  mutations: ServiceItemChange[],

  /** Currently selected component IDs */
  activeComponentIds: NumericId[]
) => {
  const processedServiceItems: NumericId[] = [];

  let assignments: ServiceItemAssignment[] = data.map((assignment) => {
    const [serviceItemId, ...componentIds] = assignment;

    processedServiceItems.push(serviceItemId);

    const mutation = mutations?.find(
      (_mutation) => _mutation.id === serviceItemId
    );

    if (!mutation) return assignment;

    if (mutation.checked === false) {
      /** We need to remove active component IDs from currently assigned IDs */
      return assignment.filter((id) => !activeComponentIds.includes(id));
    }

    /** mutation.checked === true: We need to add active component ID to assigned IDs if not already exists */
    for (const componentId of activeComponentIds) {
      if (!assignment.includes(componentId)) {
        assignment.push(componentId);
      }
    }
    return assignment;
  });

  for (const mutation of mutations) {
    if (!processedServiceItems.includes(mutation.id) && mutation.checked) {
      assignments.push([mutation.id, ...activeComponentIds]);
    }
  }

  return assignments;
};

export const mutateLayerPanelData = (
  data: LayerPanelData,
  mutations: ServiceItemChange[],
  /** Currently selected components */
  activeComponents: SingleComponent[],
  serviceItems: ServiceItem[],
  layers: any[]
): LayerPanelData => {
  const activeComponentsMap: Record<NumericId, SingleComponent> = {};

  const activeComponentIds = activeComponents.map((component) => {
    activeComponentsMap[component.componentId] = component;
    return component.componentId;
  });

  data = data.map((serviceItem) => {
    /** We need to update the `serviceItemIds` inside the `LayerPanelItem.components` */
    return {
      ...serviceItem,
      components: serviceItem.components.map((_component) => {
        if (activeComponentsMap.hasOwnProperty(_component.componentId)) {
          return {
            ..._component,
            serviceItemIds:
              activeComponentsMap[_component.componentId].serviceItemIds,
          };
        }

        return _component;
      }),
    };
  });

  /** Check if any components are completely unassigned now, we need to add them to "UNASSIGNED" service item */
  const newUnAssignedComponents: Record<ID, SingleComponent[]> = {};

  activeComponents.forEach((component) => {
    const unAssignedLayerId = `UNASSIGNED:${component.layerId}`;

    if (component.serviceItemIds.length === 0) {
      if (!newUnAssignedComponents.hasOwnProperty(unAssignedLayerId)) {
        newUnAssignedComponents[unAssignedLayerId] = [];
      }

      newUnAssignedComponents[unAssignedLayerId].push(component);
    }
  });

  if (Object.keys(newUnAssignedComponents).length > 0) {
    const unassignedLayers: LayerPanelDataItem[] = [];

    /** If Un-assigned layer does not exist in layers data and any component is newly unassigned, we need to add "UNASSIGNED" layer to data */
    for (const [layerId, _components] of Object.entries(
      newUnAssignedComponents
    )) {
      if (!data.some((serviceItem) => serviceItem.layerId === layerId)) {
        const relatedLayer = layers.find((_layer) =>
          layerId.includes(_layer.layerId)
        );

        if (!relatedLayer) continue;

        unassignedLayers.push({
          ...relatedLayer,
          name: `Unassigned - ${relatedLayer.name}`,
          components: _components,
          type: EstimationLayerType.UNASSIGNED,
          layerId: `UNASSIGNED:${layerId}`,
        });
      }
    }

    if (unassignedLayers.length > 0) {
      const takeoffLayersCount = data.filter(
        (item) => item.type === EstimationLayerType.TAKEOFF
      ).length;

      data.splice(-takeoffLayersCount, 0, ...unassignedLayers);
    }
  }

  const newAssignedComponentIds: Set<NumericId> = new Set();

  /** Check if there are completely new service item assignments which does not exist on layer panel data yet */
  for (const { id, checked } of mutations) {
    if (checked && !data.some((serviceItem) => serviceItem.id === id)) {
      const serviceItem = serviceItems.find(
        (serviceItem) => serviceItem.id === id
      )!;

      activeComponentIds.forEach((componentId) =>
        newAssignedComponentIds.add(componentId)
      );

      data.unshift({
        ...serviceItem,
        type: EstimationLayerType.SERVICE_ITEM,
        components: [...activeComponents],
      });
    }
  }

  return data
    .map((serviceItem) => {
      const isUnassignedLayer =
        serviceItem.hasOwnProperty('layerId') &&
        serviceItem.type === EstimationLayerType.UNASSIGNED;

      if (isUnassignedLayer) {
        /** We need to check if any of the components from unassigned layer has been assigned after mutations, if yes. We need to remove those components from unassigned */
        const updatedUnAssignedComponents = serviceItem.components.filter(
          (component) => !newAssignedComponentIds.has(component.componentId)
        );

        if (newUnAssignedComponents.hasOwnProperty(serviceItem.layerId)) {
          newUnAssignedComponents[serviceItem.layerId].forEach((component) => {
            /** Check if the component already exists in unassigned, if not. Add that */
            if (
              !updatedUnAssignedComponents.some((unassignedComponent) => {
                return unassignedComponent.layerId === component.componentId;
              })
            ) {
              updatedUnAssignedComponents.push(component);
            }
          });
        }

        return {
          ...serviceItem,
          components: updatedUnAssignedComponents,
        };
      }

      const mutation = mutations?.find((_mutation) => {
        return _mutation.id === (serviceItem as LayerPanelServiceDataItem).id;
      });

      if (!mutation) return serviceItem;

      if (mutation.checked === false) {
        /** We need to remove active component IDs from service item */
        return {
          ...serviceItem,
          components: serviceItem.components.filter(
            (component) => !activeComponentIds.includes(component.componentId)
          ),
        };
      }

      /** mutation.checked === true: We need to add active components to service item if not already exists */
      for (const component of activeComponents) {
        if (
          /** Check if activeComponent already exists in service item */
          !serviceItem.components.some(
            (_component) => _component.componentId === component.componentId
          )
        ) {
          newAssignedComponentIds.add(component.componentId);
          serviceItem.components.push(component);
        }
      }

      return serviceItem;
    })
    .filter((serviceItem) => serviceItem.components.length > 0);
};

export const mutateActionCenterData = (
  data: ActionCenterData,
  mutations: ServiceItemChange[]
): ActionCenterData => {
  const mutationsMap: Record<NumericId, boolean> = {};
  const newAssignedServiceItemIds: NumericId[] = [];

  for (const mutation of mutations) {
    mutationsMap[mutation.id] = mutation.checked;

    if (mutation.checked) {
      newAssignedServiceItemIds.push(mutation.id);
    }
  }

  const components = data.components.map((component) => {
    /** We need to filter out the service items which are "unchecked". i.e. set to `false` */
    const serviceItemIds = component.serviceItemIds.filter(
      (serviceItemId) => mutationsMap[serviceItemId] !== false
    );

    newAssignedServiceItemIds.forEach((serviceItemId) => {
      /** Add new assigned service item to component */
      if (!serviceItemIds.includes(serviceItemId)) {
        serviceItemIds.push(serviceItemId);
      }
    });

    return {
      ...component,
      serviceItemIds: serviceItemIds,
    };
  });

  return {
    ...data,
    components,
  };
};

export const isUnassignRequest = (
  previousAssignments: ServiceItemAssignment[],
  newAssignments: ServiceItemAssignment[]
) => {
  const totalPreviousAssignments = previousAssignments.reduce(
    (total, assignment) => {
      /** (length - 1) cause, first item in assignment array would always be service id */
      return total + (assignment.length - 1);
    },
    0
  );

  const totalNewAssignments = newAssignments.reduce((total, assignment) => {
    /** (length - 1) cause, first item in assignment array would always be service id */
    return total + (assignment.length - 1);
  }, 0);

  return totalPreviousAssignments > totalNewAssignments;
};

export const getAssignmentPayload = (
  previousAssignments: ServiceItemAssignment[],
  newAssignments: ServiceItemAssignment[],
  activeComponentIds: NumericId[],
  isUnassigning: boolean = false
) => {
  const previousAssignmentsMap: Record<NumericId, ServiceItemAssignment> = {};

  previousAssignments.forEach((assignment) => {
    if (!assignment.length) return;

    const [serviceItemId] = assignment;
    previousAssignmentsMap[serviceItemId] = assignment;
  });

  const assignmentPayload: ServiceItemAssignment[] = [];

  for (const assignment of newAssignments) {
    if (!assignment.length) continue;
    const [serviceItemId] = assignment;

    const matchingPreviousAssignment = previousAssignmentsMap[serviceItemId];

    if (isUnassigning && !matchingPreviousAssignment) {
      continue;
    }

    if (
      /** UNASSIGNMENT REQUEST */
      isUnassigning &&
      assignment.length >= matchingPreviousAssignment.length
    ) {
      /** If new assignment length (components count) is same as previous or great than previous, means nothing `unassigned` here */
      continue;
    }

    if (
      /** ASSIGNMENT REQUEST */
      !isUnassigning &&
      matchingPreviousAssignment &&
      assignment.length <= matchingPreviousAssignment.length
    ) {
      /** If new assignment length (components count) is same as previous or lesser than previous, means nothing `assigned` here */
      continue;
    }

    assignmentPayload.push(
      isUnassigning ? [assignment[0], ...activeComponentIds] : assignment
    );
  }

  return assignmentPayload;
};

export const getServiceItemAssignmentsByLayerAndComponents = (
  layers: Layer[],
  components: Record<NumericId, SingleComponent[]>
): ServiceItemAssignment[] => {
  const assignments: ServiceItemAssignment[] = [];

  const serviceItemsData: Record<NumericId, Set<NumericId>> = {};

  const initSetIfNotExists = (serviceItemId: NumericId) => {
    if (!serviceItemsData.hasOwnProperty(serviceItemId)) {
      serviceItemsData[serviceItemId] = new Set();
    }
  };

  for (const layer of layers) {
    const layerComponents = components[layer.layerId];

    if (!layerComponents) continue;

    if (layer.serviceItemIds?.length) {
      for (const serviceItemId of layer.serviceItemIds) {
        /** If layer contains any serviceItemIds, we need to map all the associated layer components to respected service item data */
        initSetIfNotExists(serviceItemId);

        for (const component of layerComponents) {
          serviceItemsData[serviceItemId].add(component.componentId);
        }
      }
    }

    for (const component of layerComponents) {
      /** For all the components in layer, if service item IDs exist, we need to add that component related to service item data */
      for (const serviceItemId of component.serviceItemIds ?? []) {
        initSetIfNotExists(serviceItemId);

        serviceItemsData[serviceItemId].add(component.componentId);
      }
    }
  }

  for (const [serviceItemId, componentIds] of Object.entries(
    serviceItemsData
  )) {
    assignments.push([Number(serviceItemId), ...Array.from(componentIds)]);
  }

  return assignments;
};

export const getComponentsWithMutations = (
  components: SingleComponent[],
  mutations: ServiceItemChange[]
) => {
  const result: Record<NumericId, SingleComponent> = {};

  const assignedServiceItemIds = mutations
    .filter((mutation) => mutation.checked)
    .map((mutation) => mutation.id);

  const unassignedServiceItemIds = mutations
    .filter((mutation) => !mutation.checked)
    .map((mutation) => mutation.id);

  for (const component of components) {
    const serviceItemIds =
      component.serviceItemIds?.filter(
        (id) => !unassignedServiceItemIds.includes(id)
      ) ?? [];

    assignedServiceItemIds.forEach((id) => {
      if (!serviceItemIds.includes(id)) {
        serviceItemIds.push(id);
      }
    });

    result[component.componentId] = {
      ...component,
      serviceItemIds,
    };
  }

  return result;
};

export const isUnassignAllRequest = (assignments: ServiceItemAssignment[]) => {
  return assignments.filter((assignment) => assignment.length > 1).length === 0;
};

const getServiceItemColumnData = (
  id: NumericId,
  serviceItems: Record<NumericId, ServiceItem>
): ServiceItemColumnData | null => {
  const serviceItem = serviceItems[id];
  if (!serviceItem) return null;

  const type = serviceItem.isArchived
    ? ServiceItemColumnDataType.ARCHIVED
    : serviceItem.serviceName.toLowerCase().includes('legacy')
    ? ServiceItemColumnDataType.LEGACY
    : ServiceItemColumnDataType.REGULAR;

  return {
    id: serviceItem.id,
    index: serviceItem.index,
    serviceIndex: serviceItem.serviceIndex,
    name: serviceItem.name,
    type: type,
  };
};

export const getComponentServiceItemColumnData = (
  component: SingleComponent,
  serviceItems: Record<NumericId, ServiceItem>
): ServiceItemColumnData[] => {
  if (isEmpty(serviceItems) || !component.serviceItemIds) return [];

  return component.serviceItemIds
    .map((serviceItemId) => {
      return getServiceItemColumnData(serviceItemId, serviceItems);
    })
    .filter((item) => !!item) as ServiceItemColumnData[];
};

export const getLayerServiceItemColumnData = (
  layerId: NumericId,
  components: Record<NumericId, SingleComponent[]>,
  serviceItems: Record<NumericId, ServiceItem>
): ServiceItemColumnData[] => {
  if (isEmpty(serviceItems)) return [];

  const serviceItemIds = new Set<NumericId>();

  const layerComponents = components[layerId];
  if (!layerComponents) return [];

  for (const component of layerComponents) {
    for (const serviceItemId of component.serviceItemIds) {
      serviceItemIds.add(serviceItemId);
    }
  }

  return Array.from(serviceItemIds)
    .map((serviceItemId) =>
      getServiceItemColumnData(serviceItemId, serviceItems)
    )
    .filter((item) => Boolean(item)) as ServiceItemColumnData[];

  return [];
};
