// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon51IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon51Icon(props: Icon51IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M3.292 4.667a2.292 2.292 0 012.291-2.292h12.834a2.292 2.292 0 012.291 2.292v14.666a2.292 2.292 0 01-2.291 2.292H5.583a2.292 2.292 0 01-2.291-2.292V4.667zm15.125-1.375H5.583c-.759 0-1.375.615-1.375 1.375v14.666c0 .76.616 1.375 1.375 1.375h12.834c.76 0 1.375-.615 1.375-1.375V4.667c0-.76-.616-1.375-1.375-1.375z"
        }
        fill={"currentColor"}
        stroke={"currentColor"}
        strokeWidth={".458"}
      ></path>

      <rect
        x={"6.5"}
        y={"6.042"}
        width={"11"}
        height={"3.667"}
        rx={".367"}
        fill={"currentColor"}
      ></rect>

      <rect
        x={"6.5"}
        y={"11.542"}
        width={"2.292"}
        height={"2.292"}
        rx={".458"}
        fill={"currentColor"}
      ></rect>

      <rect
        x={"6.5"}
        y={"15.667"}
        width={"2.292"}
        height={"2.292"}
        rx={".458"}
        fill={"currentColor"}
      ></rect>

      <rect
        x={"10.854"}
        y={"11.542"}
        width={"2.292"}
        height={"2.292"}
        rx={".458"}
        fill={"currentColor"}
      ></rect>

      <rect
        x={"10.854"}
        y={"15.667"}
        width={"2.292"}
        height={"2.292"}
        rx={".458"}
        fill={"currentColor"}
      ></rect>

      <rect
        x={"15.208"}
        y={"11.542"}
        width={"2.292"}
        height={"6.417"}
        rx={".458"}
        fill={"currentColor"}
      ></rect>
    </svg>
  );
}

export default Icon51Icon;
/* prettier-ignore-end */
