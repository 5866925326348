import { getBulkOrderDetails } from '@/components/api/bulkProject';
import { BULK_ORDER_ACTIONS } from './action_types';

export const openBulkOrderDetailsModal = (bulkOrderToShowDetailsOf) => ({
  type: BULK_ORDER_ACTIONS.OPEN_BULK_ORDER_DETAILS_MODAL,
  payload: bulkOrderToShowDetailsOf,
});

export const closeBulkOrderDetailsModal = () => ({
  type: BULK_ORDER_ACTIONS.CLOSE_BULK_ORDER_DETAILS_MODAL,
});

export const bulkOrderReduxCleanup = () => ({
  type: BULK_ORDER_ACTIONS.BULK_ORDER_REDUX_CLEANUP,
});

export const openBulkOrderListModal = () => ({
  type: BULK_ORDER_ACTIONS.OPEN_BULK_ORDER_LIST,
});

export const closeBulkOrderListModal = () => ({
  type: BULK_ORDER_ACTIONS.CLOSE_BULK_ORDER_LIST,
});

export const makeBulkOrderDetailsModalVisible = () => ({
  type: BULK_ORDER_ACTIONS.VISIBLE_BULK_ORDER_DETAILS_MODAL,
});

export const loadAndOpenBulkOrderDetailsModal = (orderId) => async (
  dispatch
) => {
  dispatch({
    type: BULK_ORDER_ACTIONS.LOAD_BULK_ORDER_DETAILS_STARTED,
  });
  try {
    const bulkOrderDetails = await getBulkOrderDetails(orderId);
    const {
      id: bulkOrderId,
      status: bulkOrderStatus,
      na: bulkOrderName,
    } = bulkOrderDetails.data;
    dispatch({
      type: BULK_ORDER_ACTIONS.LOAD_BULK_ORDER_DETAILS_SUCCESS,
      payload: {
        bulkOrderId,
        bulkOrderName,
        bulkOrderStatus,
      },
    });
  } catch (error) {
    dispatch({
      type: BULK_ORDER_ACTIONS.LOAD_BULK_ORDER_DETAILS_FAILURE,
      payload: error,
    });
  }
};

export const refetchBulkOrdersListData = () => ({
  type: BULK_ORDER_ACTIONS.REFETCH_BULK_ORDERS_LIST_DATA,
});

export const resetRefetchBulkOrdersListDataState = () => ({
  type: BULK_ORDER_ACTIONS.RESET_REFETCH_BULK_ORDERS_LIST_DATA_STATE,
});

export const addOrderList = (payload) => ({
  type : BULK_ORDER_ACTIONS.ORDER_LIST_BULK_ORDER,
  payload : payload ?? []
})

export const appendOrderList = (payload) => ({
  type : BULK_ORDER_ACTIONS.APPEND_ORDER_LIST,
  payload : payload ?? []
})

export const nextOrder = () => ({
  type : BULK_ORDER_ACTIONS.NEXT_SELECTED_ORDER,
})

export const prevOrder = () => ({
  type : BULK_ORDER_ACTIONS.PREV_SELECTED_ORDER,
})

export const selectIndexedOrder = (orderId) => ({
  type : BULK_ORDER_ACTIONS.INDEXED_SELECTED_ORDER,
  payload : { orderId }
})

export const confirmParcel = (body) => ({
  type : BULK_ORDER_ACTIONS.CONFIRM_PARCEL,
  payload : body
})

export const updateImagery = ({orderId , parcelData}) => ({
  type : BULK_ORDER_ACTIONS.UPDATE_PARCEL,
  payload : {orderId , parcelData}
})

export const updateSelectedOrderByIndex = (orderIndex) => ({
  type : BULK_ORDER_ACTIONS.UPDATE_SELECTED_ORDER_INDEX,
  payload : orderIndex
})


export const updateBulkOrderSummery = (payload) => ({
  type : BULK_ORDER_ACTIONS.UPDATE_SUMMERY,
  payload 
})

export const updateTagOrderList = (payload) => ({
  type : BULK_ORDER_ACTIONS.UPDATE_TAG_BY_ORDER_ID,
  payload 
})

export const skipAOrder = (payload) => ({
  type : BULK_ORDER_ACTIONS.SKIP_BULK_ORDER,
  payload 
})


export const deleteAOrder = (payload) => ({
  type : BULK_ORDER_ACTIONS.DELETE_BULK_ORDER
})


export const addMoreBulkOrderDetailsModalOpen = () => ({
  type: BULK_ORDER_ACTIONS.ADD_MORE_BULK_ORDER_DETAILS_MODAL_OPEN,
});

export const addMoreBulkOrderDetailsModalClose = () => ({
  type: BULK_ORDER_ACTIONS.ADD_MORE_BULK_ORDER_DETAILS_MODAL_CLOSE,
});