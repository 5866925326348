// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector140IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector140Icon(props: Vector140IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 29 3"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M23.453.075L0 2.938h28.415c-.933 0-2.333-.217-2.841-1.431C24.9-.098 24.427-.098 23.453.075z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector140Icon;
/* prettier-ignore-end */
