import React from 'react';

const Measure = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    {...props}
  >
    <g clip-path='url(#clip0_3687_27502)'>
      <path
        d='M17.4727 11.8477H6.15234V0.527344C6.15234 0.236109 5.91623 0 5.625 0H0.527344C0.236109 0 0 0.236109 0 0.527344V17.4727C0 17.7639 0.236109 18 0.527344 18H17.4727C17.7639 18 18 17.7639 18 17.4727V12.375C18 12.0838 17.7639 11.8477 17.4727 11.8477ZM1.05469 1.05469H5.09766V2.56641V2.90656C5.09766 3.14512 5.09766 2.95328 5.09766 3.24452C5.09766 3.53575 5.09766 3.41895 5.09766 3.50098V3.62109V3.69141H4.5C4.20877 3.69141 3.97266 3.92752 3.97266 4.21875C3.97266 4.50998 4.20877 4.74609 4.5 4.74609H5.09766V5.94141H3.9375C3.64627 5.94141 3.41016 6.17752 3.41016 6.46875C3.41016 6.75998 3.64627 6.99609 3.9375 6.99609H5.09766V8.19141H4.5C4.20877 8.19141 3.97266 8.42752 3.97266 8.71875C3.97266 9.00998 4.20877 9.24609 4.5 9.24609H5.09766V11.8477H1.05469V1.05469ZM1.05469 12.9023H5.09766V16.9453H1.05469V12.9023ZM16.9453 16.9453H6.15234V12.9023H8.75391V14.0625C8.75391 14.3537 8.99002 14.5898 9.28125 14.5898C9.57248 14.5898 9.80859 14.3537 9.80859 14.0625V12.9023H11.0039V13.5C11.0039 13.7912 11.24 14.0273 11.5312 14.0273C11.8225 14.0273 12.0586 13.7912 12.0586 13.5V12.9023H13.2539V14.0625C13.2539 14.3537 13.49 14.5898 13.7812 14.5898C14.0725 14.5898 14.3086 14.3537 14.3086 14.0625V12.9023H14.3789H14.5678H14.811H15.1576H15.4336H16.9453V16.9453Z'
        fill='#333333'
      />
      <path
        d='M3.61089 14.8029C3.54514 14.4891 3.23433 14.3138 2.94046 14.4014C2.63755 14.5002 2.51172 14.7982 2.58924 15.0592C2.68919 15.3649 2.98862 15.4875 3.24702 15.4104C3.52542 15.3197 3.6587 15.0584 3.61089 14.8029Z'
        fill='#333333'
      />
    </g>
    <defs>
      <clipPath id='clip0_3687_27502'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default Measure;
