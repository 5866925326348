import { NotifyError } from '@/helpers/notification-utils';
import { Button, Card, Col, Row, Select, Spin, Tooltip } from 'antd';
import { useMemo, useState } from 'react';

import { ReactComponent as CopyComponent } from '../../../../../assets/CopyComponent.svg';
import { ReactComponent as Cross } from '../../../../../assets/action-center/close_icon_cross.svg';
import { ReactComponent as Drag } from '../../../../../assets/action-center/drag-icon.svg';

import Text from '../../../../elements/text/Text.jsx';

import { useDispatch, useSelector } from 'react-redux';
import * as API_ENDPOINTS from '../../../../../helpers/constants/APIEndpoints';
import { trackEvents } from '../../../../../helpers/utilities';
import { replaceParams } from '../../../../../helpers/utilities/linkUtils';
import './style.less';

import { ReactComponent as DeleteLayer } from '../../../../../assets/action-center/delete-jan-23.svg';
import { getLayerDataWithType } from '../../../../../helpers/mapUtils/layerUtils';
import { _get } from '../../../../../helpers/utilities/lodashUtils';
import { updateOrdersTopologyWarnings } from '../../../../../store/order/actions';
import { ellipsis } from '../../../utils';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { SubscriptionChip } from '@/modules/subscription/components/Chips';
import { FeatureSubscriptionGuard } from '@/modules/subscription/guards/base';
import { SubscriptionFeature } from '@/modules/subscription/types';
import { downloadComponentGeoJson } from '@project/projectComponents/UploadGeoJSON/downloadGeoJSON';
import SRUserTypeAccessGuard from '@/modules/project/guards/SRUserTypeAccessGuard';
import { useViewType } from '@/modules/project/guards/ViewGuard';
import { AccessRight, ViewType } from '@/modules/common/types';
import LayerGuard from '../../../../../modules/project/guards/LayerGuard';
import { showNotification } from '../../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../storybook/NotificationToast/types';
import AreaPerimeterInfo from '../../../components/area-perimeter-info';
import ServiceCatalogue from '../../../../../modules/property/components/service-item/ServiceCatalogue';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { checkIfShareMapPage } from '../../../../utils';
import { setAttributeMultiPopupData } from '../../../../../store/attributeFeature/actions';
import { getServiceItemAssignmentsByLayerAndComponents } from '../../../../../modules/property/transformers/service-item.transformers';
const axiosInstance = getAxiosInstance();

export const AttributeLayerSelectionPopup = (props) => {
  const dispatch = useDispatch();
  const viewType = useViewType();
  const [editingAttribute, setEditingAttribute] = useState(null);

  let componentId;

  const view = useSelector((state) => state.order.currentViewData);
  const orderFeatures = useSelector((state) => state.order.orderFeatures);
  const layersData = orderFeatures?.layersData;
  const multiData = useSelector(
    (state) => state.feature.attributeMultiPopupData
  );
  const { data: featureListInfo } = useSelector(
    (state) => state.order.featureListInfo
  );

  const [layerName, layerId] = useMemo(() => {
    const FALLBACK = ['', null, null];

    const firstLayerKey = Object.keys(multiData || {})[0];

    if (!firstLayerKey) return FALLBACK;

    const metaData = firstLayerKey.split('&&');

    if (metaData.length < 3) return FALLBACK;

    return metaData;
  }, [Object.keys(multiData || {})]);

  const isSharePage = checkIfShareMapPage();

  if (multiData) {
    componentId = Object.values(multiData)[0];
  }

  const getComponentsWithFeatureId = (components) => {
    const { layersData } = props;

    if (!layerId || !layersData) return components;

    const layer = layersData.find((layer) => layer.layerId === Number(layerId));

    if (!layer) return components;

    return components.map((component) => ({
      ...component,
      featureId: layer.featureId,
    }));
  };

  const componentsWithFeatureId = useMemo(() => {
    return getComponentsWithFeatureId(Object.values(multiData || {})[0] ?? []);
  }, [Object.values(multiData || {})]);

  const filterData = (layerListInfo, keys) => {
    return layerListInfo.filter((el) => {
      return el.type === keys && el.isEditable;
    });
  };

  const geometryType =
    props.geometryType || props.popupData?.geometry.type?.toLowerCase();

  const layerDataWithType = filterData(
    getLayerDataWithType(layersData, featureListInfo),
    geometryType
  );

  const clickOutside = (e) => {
    if (e.target.localName !== 'input' && editingAttribute)
      setEditingAttribute(false);
  };

  // TODO: Ankit: This is a null pointer exception being fixed in a very hacky way
  // TODO: This is supposed to be revisited and fixed properly
  let area = 0;
  let perimeter = 0;
  let polygonPerimeter;

  if (typeof props.popupData === 'object') {
    area =
      props.popupData?.properties['Surface Area'] ||
      props.popupData?.properties['Area'] ||
      props.popupData?.properties['Ring Area'];

    perimeter = props.popupData?.properties['perimeter'];

    polygonPerimeter = props.popupData?.properties?.perimeter;
  }

  const onMoveFeature = (option, extra) => {
    const viewId = view.viewId;
    let reqData = {
      componentId: componentId.map((component) => component.componentId),
      targetLayerId: extra.layerId,
    };
    let layerData = {
      layerId: extra.layerId,
      name: extra.name,
    };
    axiosInstance
      .post(
        replaceParams(API_ENDPOINTS.MOVE_COMPONENT, {
          ':viewId': viewId,
          ':layerId': layerId,
        }),
        reqData
      )
      .then((result) => {
        const { data } = result;
        trackEvents('action-center__move', {
          type: 'single_select',
          viewId: viewId,
          orderId: view?.orderId,
          geometryType: geometryType,
          layerName: extra?.name,
          featureOwner: props.featureType[extra?.name].maintainer,
          isBaseView: view?.isBaseView,
          isEditable: view?.isEditable,
          itemsSelected: 1,
        });
        props.onUpdateLayerComponents(
          viewId,
          layerId,
          extra.layerId,
          componentId.map((component) => component.componentId),
          layerData,
          componentId
        );
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Move successfully');
        dispatch(
          updateOrdersTopologyWarnings({
            ..._get(result.data, 'sourceLayer', {}),
            layerId: layerId,
          })
        );
        dispatch(
          updateOrdersTopologyWarnings({
            ..._get(result.data, 'targetLayer', {}),
            layerId: extra.layerId,
          })
        );
        // props.onClose()
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onDeleteComponent = () => {
    const viewId = view.viewId;
    let reqData = {
      delete: componentId?.map((component) => component.componentId),
    };
    axiosInstance
      .post(
        replaceParams(API_ENDPOINTS.BULK_UPDATE_COMPONENT, {
          ':viewId': viewId,
          ':layerId': layerId,
        }),
        reqData
      )
      .then((res) => {
        trackEvents('action-center__delete', {
          type: 'single_select',
          viewId: viewId,
          orderId: view?.orderId,
          geometryType: geometryType,
          layerName: layerName,
          featureOwner: props.featureType[layerName].maintainer,
          isBaseView: view?.isBaseView,
          isEditable: view?.isEditable,
          itemsSelected: reqData.delete.length,
        });
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Deleted successfully');
        props.deleteComponents(
          layerId,
          componentId.map((component) => component.componentId)
        );
        dispatch(
          updateOrdersTopologyWarnings({
            ..._get(res.data, 'topologyWarning', {}),
            layerId: layerId,
          })
        );
        props.onClose();
        mouseHoveredOut();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const mouseHoveredIn = () => {
    document.getElementById('map-popover').classList.add('force-hide');
  };

  const mouseHoveredOut = () => {
    document.getElementById('map-popover').classList.remove('force-hide');
  };

  const handleApply = (components) => {
    const updatedMultiPopupData = { ...multiData };

    for (const [layerId, _components] of Object.entries(multiData ?? {})) {
      updatedMultiPopupData[layerId] = _components.map((_component) => ({
        ..._component,
        serviceItemIds:
          components[_component.componentId]?.serviceItemIds ??
          _component.serviceItemIds ??
          [],
      }));
    }

    dispatch(setAttributeMultiPopupData(updatedMultiPopupData));

    props.onServiceItemsChange?.(components);
  };

  return (
    <Card
      onClick={clickOutside}
      id={props.popupData?.id}
      className='layer-selection-popup layer-selection-popup--single'
      onMouseOver={mouseHoveredIn}
      onMouseOut={mouseHoveredOut}
    >
      <Box
        style={{ height: 16 }}
        className='flex-justify-align-center w-100 drag-handle'
      >
        <Drag />
      </Box>
      <Row justify={'space-between'} align={'top'}>
        <Col>
          <Row>
            <Tooltip title={layerName}>
              <Typography
                fontSize={12}
                lineHeight='20px'
                fontWeight={600}
                className='mr-2'
              >
                {ellipsis(layerName, 12)}
              </Typography>
            </Tooltip>
            <Typography
              fontSize={12}
              lineHeight='20px'
              style={{ color: '#666' }}
            >
              - {props?.componentName} selected
            </Typography>
          </Row>
          <Row style={{ marginTop: 6 }}>
            <AreaPerimeterInfo
              area={
                geometryType === 'point' || geometryType === 'polygon'
                  ? Number(area)
                  : Number(perimeter)
              }
              areaUnit={
                geometryType === 'point' || geometryType === 'polygon'
                  ? 'sq ft'
                  : 'ft'
              }
              perimeter={Number(polygonPerimeter)}
            />
          </Row>
        </Col>
        <Col>
          <Row align='top'>
            <Tooltip title='Delete from Layer'>
              <SRUserTypeAccessGuard
                right={AccessRight.Edit}
                tooltip='Delete from Layer'
              >
                <LayerGuard view={ViewType.STATIC} disabled tooltip>
                  <Button
                    type={'link'}
                    disabled={!props.isEditableView}
                    style={{ width: 24, height: 24 }}
                    icon={
                      <DeleteLayer
                        className='pointer'
                        cursor='pointer'
                        onClick={
                          !props.isEditableView
                            ? () => NotifyError('Operation not allowed!')
                            : onDeleteComponent
                        }
                      />
                    }
                  />
                </LayerGuard>
              </SRUserTypeAccessGuard>
            </Tooltip>
            <Tooltip title='Close'>
              <Button
                type={'link'}
                className={'ml-2'}
                style={{ width: 24, height: 24 }}
                icon={
                  <Cross
                    onClick={() => {
                      props.onClose();
                      mouseHoveredOut();
                    }}
                    className='pointer'
                  />
                }
              />
            </Tooltip>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 12 }}>
        <Col span={16}>
          <Text type='p67' color='dark-gray'>
            Move to
          </Text>
          <SRUserTypeAccessGuard right={AccessRight.Edit} tooltip='Move to'>
            <FeatureSubscriptionGuard
              feature={SubscriptionFeature.SingleLayerMove}
              fallbackProps={{
                onChange: () => { },
              }}
            >
              <LayerGuard view={ViewType.STATIC} disabled tooltip>
                <Tooltip title={view.isBaseView ? 'Editing restricted on this view' : ""}>
                  <Select
                    showSearch
                    value={layerName}
                    className={'layer-list'}
                    style={{ width: 100, marginLeft: 8 }}
                    size={'small'}
                    onChange={async (e, option) => {
                      onMoveFeature(e, option);
                    }}
                    disabled={!props.isEditableView}
                  >
                    {layerDataWithType?.map((layer, i) => (
                      <Select.Option
                        key={i}
                        value={layer.name}
                        name={layer.name}
                        layerId={layer.layerId}
                      >
                        {layer.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Tooltip>
              </LayerGuard>
            </FeatureSubscriptionGuard>
          </SRUserTypeAccessGuard>
        </Col>
        <Col span={8}>
          <Row justify='end' align='middle'>
            <Tooltip title='Duplicate Item'>
              <SRUserTypeAccessGuard
                right={AccessRight.Edit}
                tooltip='Duplicate Item'
              >
                <LayerGuard view={ViewType.STATIC} disabled tooltip>
                  <Button
                    type={'link'}
                    disabled={!props.isEditableView}
                    className={'ml-4'}
                    style={{ width: '20px', height: '20px' }}
                    icon={
                      <FeatureSubscriptionGuard
                        feature={SubscriptionFeature.SingleLayerDuplicate}
                      >
                        <CopyComponent
                          disabled={!props.isEditableView}
                          className='pointer'
                          cursor='pointer'
                          onClick={
                            !props.isEditableView
                              ? () => NotifyError('Operation not allowed!')
                              : () =>
                                props.onDuplicateComponent(
                                  componentId.map(
                                    (component) => component.componentId
                                  ),
                                  layerId
                                )
                          }
                        />
                      </FeatureSubscriptionGuard>
                    }
                  />
                </LayerGuard>
              </SRUserTypeAccessGuard>
            </Tooltip>
            {downloadComponentGeoJson(
              view?.viewId,
              layerId,
              props.popupData?.id,
              'large'
            )}
            <SubscriptionChip
              feature={SubscriptionFeature.MultiLayerMove}
              height={12}
              className='ml-2'
            />
          </Row>
        </Col>
      </Row>

      {props.loading && !isSharePage ? (
        <Box flex align='middle' justify='center' className='mt-6'>
          <Spin size='default' />
        </Box>
      ) : viewType === ViewType.STATIC && !isSharePage ? (
        <div className='end-content mt-4'>
          <ServiceCatalogue
            components={componentsWithFeatureId}
            initialAssignments={getServiceItemAssignmentsByLayerAndComponents(
              props.layersData,
              props.layerComponents
            )}
            orderHash={props.orderHash}
            onApply={handleApply}
          />
        </div>
      ) : (
        <></>
      )}
    </Card>
  );
};
