import { ComponentProps, CSSProperties, forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import './style.less';
import { TypographyVariant } from './types';

interface TypographyProps
  extends ComponentProps<'span'>,
    Pick<
      CSSProperties,
      'fontWeight' | 'fontSize' | 'opacity' | 'lineHeight' | 'letterSpacing'
    > {
  /** (Optional) {@link TypographyVariant} you want to use for typography. defaults to "body" */
  variant?: TypographyVariant;

  /** (Optional) Color you want to use for text. defaults to "neutral.700 */
  color?: string;

  /** (Optional) If true, text would be displayed in uppercase */
  uppercase?: boolean;

  align?: CSSProperties['textAlign'];
  component?: keyof JSX.IntrinsicElements;
}

const Typography = forwardRef<HTMLSpanElement, TypographyProps>(
  (
    {
      variant = 'body',
      color,
      fontWeight,
      fontSize,
      lineHeight,
      letterSpacing,
      align,
      opacity,
      uppercase,
      className,
      style,
      component,
      children,
      ...rest
    },
    ref
  ) => {
    const Component: keyof JSX.IntrinsicElements = useMemo(() => {
      if (component) {
        return component;
      }

      if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant))
        return variant as keyof JSX.IntrinsicElements;

      return 'span';
    }, [variant, component]);

    const colorClassName = useMemo(() => {
      if (color?.startsWith('#')) {
        /** A hex code is provided as color, so we directly want to use that as inline styling */
        return undefined;
      }
      return color ? `color--${color.replace(/\./g, '-')}` : undefined;
    }, [color]);

    const variantClassName = useMemo(() => {
      return variant ? `typography--${variant.replace(/\./g, '-')}` : undefined;
    }, [variant]);

    return (
      // @ts-ignore
      <Component
        // @ts-ignore
        ref={ref}
        className={classNames(
          'typography',
          colorClassName,
          variantClassName,
          className,
          { 'text-uppercase': uppercase }
        )}
        style={{
          textAlign: align,
          fontWeight,
          fontSize,
          lineHeight,
          letterSpacing,
          opacity,
          color: color?.startsWith('#') ? color : undefined,
          ...style,
        }}
        {...rest}
      >
        {children}
      </Component>
    );
  }
);

Typography.displayName = 'Typography';

export default Typography;
