import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToolKey } from '../../types';
import { ModifySyncState } from '../../../../../../../modules/common/types';
import { IStore } from '../../../../../../../store/types';

interface UseManualSyncTipVisibility {
  activeTool: any;
  isResetModificationsModalVisible: boolean;
}

const useManualSyncTipVisibility = ({
  activeTool,
  isResetModificationsModalVisible,
}: UseManualSyncTipVisibility) => {
  const [isManualSyncTipVisible, setIsManualSyncTipVisible] =
    useState<boolean>(false);

  const unSyncedModifiedChanges = useSelector<IStore, any[]>(
    (state) => state.map.modifyDraftEvents
  );
  const modifySyncState = useSelector<IStore, ModifySyncState>(
    (state: any) => state.map.modifySyncState
  );

  const hideManualSyncTip = () => setIsManualSyncTipVisible(false);

  useEffect(() => {
    if (activeTool != ToolKey.EDIT) {
      hideManualSyncTip();
      return;
    }

    if (activeTool == ToolKey.EDIT && unSyncedModifiedChanges?.length > 0) {
      setIsManualSyncTipVisible(true);
    }
  }, [activeTool, unSyncedModifiedChanges]);

  useEffect(() => {
    /** When status is changed to "SYNCED", We need to show "Saved" text for a while, then hide the ManualSync tip  */
    let statusChangeTimeout: NodeJS.Timeout;

    if (modifySyncState === ModifySyncState.SYNCED) {
      if (isResetModificationsModalVisible) {
        hideManualSyncTip();
      } else {
        statusChangeTimeout = setTimeout(() => {
          hideManualSyncTip();
        }, 3000);
      }
    }

    return () => {
      if (statusChangeTimeout) {
        clearTimeout(statusChangeTimeout);
      }
    };
  }, [modifySyncState]);

  return isManualSyncTipVisible;
};

export default useManualSyncTipVisibility;
