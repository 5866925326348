import { User } from '@/store/user/state';
import {
  UserDetails,
  Workspace as StorybookWorkspace,
} from '@/modules/storybook/Subscription/types';
import {
  SubscriptionPaymentStatus,
  SubscriptionState,
} from '@/components/subscription/helpers/enum';
import { UserRole } from '@/modules/storybook/helpers/types';
import { Nullable, NumericId } from '@common/types';
import { Workspace } from '@common/models/workspace';
import { differenceInDaysRounded } from '../../../utils';
import { getSubscriptionState } from '../../../subscription/helpers';
import pick from 'lodash/pick';

export class ProfileDropdownTransformer {
  constructor(private user: User, private _workspaces: Workspace[]) {}

  private isPaymentFailed = (workspace: Workspace) => {
    if (!workspace.lastPaymentStatus?.status) return false;

    return [
      SubscriptionPaymentStatus.EXPIRED,
      SubscriptionPaymentStatus.CANCELLED,
    ].includes(workspace.lastPaymentStatus.status);
  };

  private getTrialDays = (workspace: Workspace): number | undefined => {
    if (!workspace.subscriptionEndDate) {
      return undefined;
    }

    return differenceInDaysRounded(
      new Date(workspace.subscriptionEndDate),
      new Date()
    );
  };

  private getWorkspaceSubscriptionState = (
    data: Workspace
  ): SubscriptionState => {
    return getSubscriptionState({
      ...pick(data, [
        'basePackage',
        'previousSubscription',
        'lastPaymentStatus',
      ]),
      status: data.subscriptionStatus,
    });
  };

  /**
   * Transformer Functions which transforms API response to prop supported by {@link ProfileDropdown}
   **/
  public readonly userDetails = (): UserDetails => {
    return {
      name: this.user.first_name,
      email: this.user.email,
      avatar: this.user.profile_image,
    };
  };

  public readonly workspace = (data: Workspace): StorybookWorkspace => ({
    id: data.workspaceId,
    name: data.workspaceName,
    avatar: data.workspaceAvatar,
    isPaymentFailed: this.isPaymentFailed(data),
    planType: data.basePackage.platformAccessType,
    totalCredits: data.workspaceCredits,
    trialDaysLeft: this.getTrialDays(data),
    userRole: data.orderCreationRole as unknown as UserRole,
    subscriptionState: this.getWorkspaceSubscriptionState(data),
  });

  public readonly getWorkspaceById = (
    id: NumericId,
    args?: Partial<StorybookWorkspace>
  ): Nullable<StorybookWorkspace> => {
    const workspace = this._workspaces.find(
      (_workspace) => _workspace.workspaceId === id
    );

    return workspace ? { ...this.workspace(workspace), ...args } : null;
  };

  public readonly workspaces = (): StorybookWorkspace[] => {
    return this._workspaces.map(this.workspace);
  };
}
