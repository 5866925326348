import React from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { ReactComponent as Warning } from '@tm/assets/Warning.svg';
import Text from '@elements/text/Text';

interface RemoveMemberModalProps {
  deleteUserModal: boolean;
  showDeleteUserModal: () => void;
  hideDeleteUserModal: () => void;
  handleUserDelete: (option: any, email: string) => void;
  modalData: any;
}

const RemoveMemberModal = ({
  deleteUserModal,
  showDeleteUserModal,
  hideDeleteUserModal,
  handleUserDelete,
  modalData,
}: RemoveMemberModalProps) => {
  return (
    <div>
      <Modal
        title=''
        visible={deleteUserModal}
        footer={null}
        closable={false}
        width='512px'
        centered
        onCancel={hideDeleteUserModal}
        onOk={hideDeleteUserModal}
        className='delete-member-modal'
      >
        <div>
          <Row>
            <Col className='mb-4' span={4} style={{ textAlign: 'center' }}>
              <Warning />
            </Col>
            <Col className='mb-4' span={20}>
              <Row className='mb-2'>
                <Text type='h11' color='dark-gray'>
                  {`Remove “${
                    modalData?.user_name
                      ? `${modalData?.user_name}`
                      : `${modalData?.email}`
                  }”?`}
                </Text>
              </Row>
              <Row>
                <Text type='h8' color='gray-shade'>
                  Are you sure you want to remove the member?
                </Text>
              </Row>
            </Col>
          </Row>
          <Row style={{ justifyContent: 'right' }}>
            <Button
              style={{ border: '1px solid #D1D5DB', borderRadius: '6px' }}
              onClick={hideDeleteUserModal}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleUserDelete(modalData?.option, modalData?.email);
                hideDeleteUserModal();
              }}
              style={{
                background: '#DC2626',
                borderRadius: '6px',
                color: 'white',
              }}
              className='mr-3 ml-3'
            >
              Remove
            </Button>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default RemoveMemberModal;
