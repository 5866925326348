import { OrderView } from '@project/projectComponents/types';
import { replaceParams } from '@/helpers/utilities/linkUtils';
import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { ID } from '@common/types';

export const getOrderViews = (orderId: ID) => {
  return getAxiosInstance().get<Array<OrderView>>(
    replaceParams(API_ENDPOINTS.ORDER_VIEW, {
      ':orderId': orderId,
    })
  );
};
