import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OlMap from 'ol/Map';
import omit from 'lodash/omit';
import { useEstimationViewContext } from '../../../../../modules/property/context/EstimationView/context';
import {
  toCheckedItems,
  toCheckedItemsWithPreviousData,
  toPlasmicEstimationLayerPanel,
} from '../../../../../modules/property/transformers/service-item.transformers';
import { SelectedComponent } from '../../../../../modules/property/api/types';
import { useServiceItemLayers } from '../../../../../modules/property/hooks/useServiceItemLayers';
import { IStore } from '../../../../../store/types';
import { resetToParcel } from '@/helpers/mapUtils/mapInit';
import MapContext from '@/components/pages/project/MapContext';
import { ID, Nullable, NumericId } from '../../../../../modules/common/types';
import { OrderData } from '../../../../../store/order/state';
import { getFeatureList } from '@/store/order/thunks';
import { CheckedServiceItem, SelectedKeys } from './types';
import { trackEvents } from '../../../../../helpers/utilities';
import { removeAllMapLayers } from '@/helpers/mapUtils/mapInit';
import { CreateOrderEvt, MapControlsEvt } from '../../../../../segment';
import {
  toggleBasemap,
  toggleNearmap,
} from '../../../../../helpers/mapUtils/mapInit';
import {
  showHideBasemap,
  showHideNearmap,
  showHideParcel,
} from '../../../../../store/ui/actions';
import { setEstimationViewCheckedItems } from '@/store/order/actions';
import { useLayerItemHover } from '../../../../../modules/property/hooks/useLayerItemHover';
import { useParcelLayerToggle } from '../../../../../modules/property/hooks/useParcelLayerToggle';
import { useOnFeatureClick } from '../../../../../modules/property/hooks/useOnFeatureClick';
import { useComponentHighlight } from '../../../../../modules/property/hooks/useComponentHighlight';
import { useDragMultiSelect } from '../../../../../modules/property/hooks/useDragMultiSelect';
import usePrevious from '../../../../../hooks/usePrevious';
import { vhMinusPx } from '../helpers';
import { getMMMMDYYYYFormattedDate } from '../../../../../helpers/utils';
import './style.less';

import { FixedSizeList as List, areEqual } from 'react-window';
import EmLayerPanel3 from '../../../../PlasmicComponents/EmLayerPanel3';
import EmServiceItemRow from '../../../../PlasmicComponents/EmServiceItemRow';
import EmElementRow from '../../../../PlasmicComponents/EmElementRow';
import { produce } from 'immer';

const EstimationLayerPanel = () => {
  const mapRef: OlMap = useContext(MapContext);
  const dispatch = useDispatch();

  const { highlight } = useComponentHighlight();

  // default master checkbox state
  const defaultMasterSelectedCheckboxState = useRef({});
  const defaultMasterUnSelectedCheckboxState = useRef({});

  // default slave selected and unselected states
  const defaultSlaveSelectedState = useRef({});
  const defaultSlaveUnSelectedState = useRef({});

  // default slave checked and unchecked states
  const defaultSlaveCheckedState = useRef({});
  const defaultSlaveUnCheckState = useRef({});

  // default single selected master service item id
  const [currentSelectedMaster, setCurrentSelectedMaster] = useState(null);

  // default current selection master checkout map which holds
  const [currentSelectedMasterCheckbox, setCurrentSelectedMasterCheckbox] =
    useState({});

  const [masterExpandState, setMasterExpandState] = useState({});
  const [unCheckedMap, setUncheckedMap] = useState({}); // manage the unchecked toggle state of slaves

  const [slaveCheckbox, setSlaveCheckbox] = useState({});
  const [slaveSelection, setSlaveSelection] = useState({});

  const orderData = useSelector<IStore, OrderData | null>(
    (state) => state.order.orderData
  );

  const featureListInfo = useSelector<IStore, any>(
    (state) => state.order.featureListInfo
  );

  const viewId = useSelector<IStore, Nullable<NumericId>>(
    (state) => state.order.currentViewData?.viewId ?? null
  );

  const linkedViewId = useSelector<IStore, Nullable<NumericId>>(
    (state) => state.order.currentViewData?.linkedView ?? null
  );

  const selectedBasemap = useSelector<IStore, Boolean>(
    (state) => state.ui.selectedBasemap
  );

  const parcelStyle = JSON.parse(orderData?.parcel_style?.style);

  const isBasemapEnabled = useSelector<IStore, Boolean>(
    (state) => state.ui.isBasemapEnabled
  );

  const isParcelEnabled = useSelector<IStore, Boolean>(
    (state) => state.ui.isParcelEnabled
  );

  const isNearmapEnabled = useSelector<IStore, Boolean>(
    (state) => state.ui.isNearmapEnabled
  );

  const {
    data,
    dataViewId,
    actionCenterData,
    setActionCenterData,
    isServiceChipsVisible,
    setIsServiceChipsVisible,
  } = useEstimationViewContext();

  const previousData = usePrevious(data);

  const initialCheckedItems = useMemo(() => {
    if (!data) return [];

    return toCheckedItems(data);
  }, [data]);

  const [checkedItems, setCheckedItemsBase] =
    useState<CheckedServiceItem[]>(initialCheckedItems);

  const [checkedItemsViewId, setCheckedItemsViewId] =
    useState<Nullable<NumericId>>(null);

  const { handleHover } = useLayerItemHover();

  const { handleToggle: handleParcelToggle } = useParcelLayerToggle();

  const setCheckedItems = (dataOrCallback: any) => {
    setCheckedItemsBase(dataOrCallback);
    setCheckedItemsViewId(viewId);
  };

  useEffect(() => {
    dispatch(setEstimationViewCheckedItems(checkedItems));
  }, [checkedItems]);

  useEffect(() => {
    if (actionCenterData) {
      const id =
        actionCenterData.activeItem.id ?? actionCenterData.activeItem.layerId;
      const selectedComponents = actionCenterData.components.map(
        (component) => component.componentId
      );
      // Create a new copy of the state object to avoid mutation
      let newState = { ...slaveSelection };

      // Ensure that the newState[id] is initialized if it's undefined
      if (!newState[id]) {
        newState[id] = {};
      }

      setCurrentSelectedMaster(id);
      setSlaveSelection((prevState) =>
        produce(defaultSlaveUnSelectedState.current, (draft) => {
          if (!draft[id]) {
            draft[id] = {};
          }
          selectedComponents.forEach((componentId) => {
            draft[id][componentId] = true;
          });
        })
      );
    }
  }, [actionCenterData]);

  const selectedComponents: SelectedKeys = useMemo(() => {
    if (!actionCenterData) {
      return { id: '', name: '', selectedComponents: [] };
    }

    const id =
      actionCenterData.activeItem.id ?? actionCenterData.activeItem.layerId;

    return {
      id: id,
      name: actionCenterData.activeItem.name,
      selectedComponents: actionCenterData.components.map(
        (component) => component.componentId
      ),
    };
  }, [actionCenterData]);

  const serviceItems = useMemo(() => {
    const serviceItemData = data
      ? toPlasmicEstimationLayerPanel(
          data,
          slaveCheckbox,
          currentSelectedMasterCheckbox,
          unCheckedMap
        )
      : null;

    if (!serviceItemData || !orderData) return null;

    setCurrentSelectedMaster(serviceItemData.serviceItems[0]?.id);

    setMasterExpandState(serviceItemData.masterExpandedState);
    defaultMasterSelectedCheckboxState.current =
      serviceItemData.masterCheckBoxCheckedState;
    defaultMasterUnSelectedCheckboxState.current =
      serviceItemData.masterCheckBoxUnCheckedState;

    setUncheckedMap(serviceItemData.unCheckedMapData);
    setCurrentSelectedMasterCheckbox(serviceItemData.masterCheckBoxState);

    setSlaveCheckbox(serviceItemData.slaveCheckBoxState);
    setSlaveSelection(serviceItemData.slaveSelectionState);

    defaultSlaveSelectedState.current = serviceItemData.slaveCheckBoxState;
    defaultSlaveUnSelectedState.current =
      serviceItemData.slaveCheckBoxUnCheckedState;

    defaultSlaveCheckedState.current =
      serviceItemData.slaveCheckBoxCheckedState;
    defaultSlaveUnCheckState.current =
      serviceItemData.slaveCheckBoxUnCheckedState;

    const finalList = {
      ...serviceItemData,
      nearmap: {
        date: getMMMMDYYYYFormattedDate(orderData.imagerydate),
        checked: isNearmapEnabled,
        disabled: !orderData.imagerydate,
      },
      basemap: {
        checked: isBasemapEnabled,
        disabled: false,
      },
      parcel: {
        color: parcelStyle.fillColor,
        measurement: orderData.parcelArea,
        checked: isParcelEnabled, //Boolean(orderData.parcelJson),
        disabled: !orderData.parcelJson,
      },
    };

    return finalList;
  }, [data, isNearmapEnabled, isBasemapEnabled, isParcelEnabled]);

  const serviceItemComponentIdsMap = useMemo(() => {
    const result: Record<ID, NumericId[]> = {};

    serviceItems?.serviceItems.forEach((serviceItem) => {
      result[serviceItem.id] = serviceItem.components.map(
        (_component) => _component.id
      );
    });

    return result;
  }, [serviceItems]);

  const updateActionCenterDataToFirstItem = (forceUpdate: boolean = false) => {
    const initialSelectedServiceItem = serviceItems?.serviceItems[0] ?? null;

    const shouldUpdate = !actionCenterData || forceUpdate;

    if (initialSelectedServiceItem && shouldUpdate) {
      handleSelect({
        id: initialSelectedServiceItem.id,
        name: initialSelectedServiceItem.name,
        selectedComponents: initialSelectedServiceItem.components.map(
          (component) => component.id
        ),
      });
    }
  };

  const handleSelect = ({ id, selectedComponents }: SelectedComponent) => {
    const serviceItem = data!.find((item) => {
      return item.hasOwnProperty('layerId')
        ? item.layerId === id
        : item.id === id;
    });

    if (!serviceItem) return;

    if (selectedComponents.length === 0) {
      setActionCenterData(null);
      highlight([]);
      return;
    }
    const components = serviceItem.components.filter((component) =>
      selectedComponents.includes(component.componentId)
    );

    let finalComponents = components;

    // if component is still empty that means it is removed so adding default components to the list
    if (finalComponents.length === 0) {
      finalComponents = serviceItem.components;
    }

    highlight(finalComponents);

    setActionCenterData({
      activeItem: omit(serviceItem, ['components']),
      components: finalComponents,
    });
  };

  const handleMasterCheckboxToggle = (checked: boolean) => {
    if (checked) {
      /** Master checkbox is toggled on */
      setCheckedItems(toCheckedItems(data!));
      return;
    }

    setCheckedItems(
      toCheckedItems(data!).map((item) => ({ ...item, checkedComponents: [] }))
    );
  };

  const updateActionCenterActiveItemComponents = () => {
    if (!actionCenterData?.activeItem) return;

    const matchingServiceItem = serviceItems?.serviceItems.find((item) => {
      const activeItemId =
        actionCenterData.activeItem.layerId ?? actionCenterData.activeItem.id;

      return item.id === activeItemId;
    });

    if (!matchingServiceItem) {
      updateActionCenterDataToFirstItem(true);
      return;
    }

    handleSelect({
      id: matchingServiceItem.id,
      name: matchingServiceItem.name,
      selectedComponents: actionCenterData.components.map(
        (component) => component.componentId
      ),
    });
  };

  const changeActiveItemToNewlyAssigned = () => {
    if (!actionCenterData?.activeItem) return;

    if (actionCenterData.activeItem.name.toLowerCase().includes('unassigned')) {
      const serviceItem = data!.find((_item) => {
        const itemId = _item.layerId ?? _item.id;
        const activeItemId =
          actionCenterData.activeItem.layerId ?? actionCenterData.activeItem.id;

        return itemId === activeItemId;
      });

      if (!serviceItem) return;

      const serviceItemComponentIds = serviceItem.components.map(
        (component) => component.componentId
      );

      const actionCenterDataComponentIds = actionCenterData.components.map(
        (component) => component.componentId
      );

      if (
        actionCenterDataComponentIds.every(
          (componentId) => !serviceItemComponentIds.includes(componentId)
        )
      ) {
        /** Currently selected action center components no more exists in currently selected service item. So, we need to update the activeServiceItem */

        /**
         * TODO: For now, we are setting just to first item in this scenario. Set to new created service item
         *
         */

        updateActionCenterDataToFirstItem(true);
        setCheckedItems(toCheckedItems(data!));

        // const newServiceItem = data!.find((_item) => {
        //   return _item.components.some((component) =>
        //     actionCenterDataComponentIds.includes(component.componentId)
        //   );
        // });
        //
        // if (!newServiceItem) {
        //   updateActionCenterDataToFirstItem(true);
        //   return;
        // }
        //
        // handleSelect({
        //   id: newServiceItem.layerId ?? newServiceItem.id,
        //   name: newServiceItem.name,
        //   selectedComponents: newServiceItem.components
        //     .filter((component) =>
        //       actionCenterDataComponentIds.includes(component.componentId)
        //     )
        //     .map((component) => component.componentId),
        // });

        return;
      }
    }
  };

  useEffect(() => {
    removeAllMapLayers(mapRef);

    if (!featureListInfo?.data?.length) {
      dispatch(getFeatureList());
    }

    /** On initial render, display the parcel layer */
    handleParcelToggle(true);
  }, []);

  useEffect(() => {
    if (!viewId || !orderData) return;

    /** Whenever viewId is changed, we need to reset the map to parcel. TODO: Add a better solution for this */
    setTimeout(() => {
      resetToParcel(mapRef, {}, false, [
        Number(orderData.longitude),
        Number(orderData.latitude),
      ]);
    }, 3000);
  }, [viewId]);

  useEffect(() => {
    updateActionCenterDataToFirstItem();
  }, [serviceItems?.serviceItems]);

  useEffect(() => {
    /** Whenever assignments are checked, we need to recalculate the checked items. NOTE: We don't want to recalculate this data when complete view is changed, that would be handled by other useEffect */
    if (!data || dataViewId !== viewId) return;

    setCheckedItems(
      toCheckedItemsWithPreviousData(data, previousData ?? null, checkedItems)
    );

    /** Whenever assignments are updated, we need to pass the updated data to action-center */
    if (actionCenterData?.activeItem) {
      /** If current active item is unassigned service item, and after the assignments update that component is no more in unassigned state, we need to update the active service item to that */
      changeActiveItemToNewlyAssigned();
      updateActionCenterActiveItemComponents();
    }
  }, [data]);

  useEffect(() => {
    if (!data) return;

    /** Whenever viewId is changed, we need to reset the checkedKeys */
    setCheckedItems(toCheckedItems(data));
    updateActionCenterDataToFirstItem(true);
  }, [dataViewId]);

  /** Render/Hide Layers when `checkedItems` are changed */
  useServiceItemLayers(data ?? [], checkedItems ?? []);
  useOnFeatureClick();
  useDragMultiSelect();

  if (!serviceItems) return null;

  const handleServiceItemCheckboxToggle = (
    serviceItemId: NumericId,
    components: NumericId[]
  ) => {
    const serviceItem = serviceItems.serviceItems!.find((item) => {
      const itemId = item.id ?? item.layerId;
      return itemId === serviceItemId;
    });

    const serviceItemComponentsMap: Record<NumericId, NumericId[]> = {};

    serviceItems.serviceItems!.forEach((item) => {
      serviceItemComponentsMap[item.id] = item.components.map(
        (_component) => _component.id
      );
    });

    if (!serviceItem) return null;

    const serviceItemComponentIds = serviceItem.components.map(
      (component) => component.id
    );

    if (components.length === 0) {
      /** User has unchecked the service item */
      setCheckedItems((prevState) => {
        /** We need to also uncheck components from other service items */
        return prevState.map((item) => ({
          ...item,
          checkedComponents: item.checkedComponents.filter(
            (_componentId) => !serviceItemComponentIds.includes(_componentId)
          ),
        }));
      });

      return;
    }

    /** User has checked the service item */
    setCheckedItems((prevState) => {
      let serviceItemExists = false;
      const newItems = prevState.map((item) => {
        if (item.id === serviceItemId) {
          serviceItemExists = true;
          return { ...item, checkedComponents: components };
        }

        /** If other service items also contain these components, we need to enable them */
        const newComponentIds =
          serviceItemComponentsMap[item.id]?.filter((componentId) =>
            components.includes(componentId)
          ) ?? [];

        const newCheckedComponents = [...item.checkedComponents];
        newComponentIds.forEach((componentId) => {
          if (!newCheckedComponents.includes(componentId)) {
            newCheckedComponents.push(componentId);
          }
        });

        return { ...item, checkedComponents: newCheckedComponents };
      });

      if (!serviceItemExists) {
        newItems.push({
          id: serviceItemId,
          name: serviceItem.name,
          checkedComponents: components,
        });
      }

      return newItems;
    });
  };

  const handleComponentToggle = (componentId: NumericId, checked: boolean) => {
    const serviceItem = serviceItems.serviceItems!.find((item) => {
      return item.components.some((component) => component.id === componentId);
    });

    if (!serviceItem) return;

    setCheckedItems((prevState: CheckedServiceItem[]) => {
      return prevState.map((item) => {
        const components = item.checkedComponents;

        if (checked) {
          const allowedItemComponents =
            serviceItemComponentIdsMap[item.id] ?? [];

          /** Component is checked, if it is not already present in `checkedItems` data, add it */
          if (
            !components.includes(componentId) &&
            allowedItemComponents.includes(componentId)
          ) {
            components.push(componentId);
          }

          return { ...item, checkedComponents: components };
        }

        return {
          ...item,
          checkedComponents: item.checkedComponents.filter(
            (_componentId) => _componentId !== componentId
          ),
        };
      });
    });
  };

  const handleBaseMapToggle = (checked: boolean) => {
    trackEvents(MapControlsEvt.BaseMapToggleClicked);
    dispatch(showHideBasemap(checked));
    toggleBasemap(mapRef, checked, selectedBasemap);
  };

  const handleNearMapToggle = (checked: boolean) => {
    trackEvents(MapControlsEvt.NearmapToggleClicked);
    dispatch(showHideNearmap(checked));
    toggleNearmap(mapRef, checked);
  };

  const _handleParcelToggle = (checked: boolean) => {
    trackEvents(MapControlsEvt.ParcelToggleClicked);
    dispatch(showHideParcel(checked));
    handleParcelToggle(checked);
  };

  const Row = ({ data, index, style }) => {
    const measurement = data[index].measurement;
    const name = data[index].name;
    const parentId = data[index].parentId;
    const componentId = data[index].id;

    const toggleCount = slaveCheckbox[data[index].parentId][data[index].id]
      ? 1
      : 0;
    const isSelected = slaveSelection[data[index].parentId][data[index].id];

    return (
      <EmElementRow
        onHover={(a, info) => {
          handleHover({ id: componentId, info });
        }}
        onComponentToggle={(a, b, checked) => {
          let totalUpdates = [];
          setSlaveCheckbox((prevState) =>
            produce(prevState, (draft) => {
              if (!draft[parentId]) {
                draft[parentId] = {
                  [componentId]: checked,
                };
              } else if (!draft[parentId][componentId]) {
                draft[parentId][componentId] = checked;
              }
              Object.keys(draft).forEach((key) => {
                if (draft[key][componentId] !== undefined) {
                  draft[key][componentId] = checked;
                  totalUpdates.push({
                    parentId: key,
                    componentId,
                  });
                }
              });
            })
          );
          setUncheckedMap((prevState) =>
            produce(prevState, (draft) => {
              totalUpdates.forEach((data) => {
                const { parentId, componentId } = data;
                // console.log('setting uncheck map', draft[parentId]);
                if (draft[parentId] === undefined) {
                  if (checked) {
                    draft[parentId] = null;
                  } else {
                    draft[parentId] = 1;
                  }
                } else {
                  if (checked) {
                    // if (draft[parentId] === -1) {
                    //   draft[parentId] = draft[parentId] + 1
                    // } else {
                    draft[parentId] = draft[parentId] - 1;
                    // }
                    // let value = draft[parentId] - 1;
                    // if (value === 0) {
                    //   draft[parentId] = null
                    // } else {
                    //   draft[parentId] = value
                    // }
                    // draft[parentId] = draft[parentId] - 1;
                  } else {
                    draft[parentId] = draft[parentId] + 1;
                  }
                }

                // if (checked) {
                //   draft[parentId] = 1; Math.abs(draft[parentId] - 1)
                // } else {
                //   draft[parentId] = Math.abs(draft[parentId] + 1)
                // }
              });
            })
          );
          setTimeout(() => {
            handleComponentToggle(componentId, checked);
          }, 0);
        }}
        onComponentSingleSelect={() => {
          if (currentSelectedMaster !== parentId) {
            setCurrentSelectedMaster(parentId);
          }
          setSlaveSelection((prevState) =>
            produce(defaultSlaveUnSelectedState.current, (draft) => {
              if (!draft[parentId]) {
                draft[parentId] = {};
              }
              draft[parentId][componentId] = !isSelected;
            })
          );
          if (!isSelected) {
            handleSelect({ id: parentId, selectedComponents: [componentId] });
          } else {
            handleSelect({ id: parentId, selectedComponents: [] });
          }
        }}
        onComponentMultiSelect={(a, b, c) => {
          setSlaveSelection((prevState) =>
            produce(prevState, (draft) => {
              if (!draft[parentId]) {
                draft[parentId] = {};
              }
              draft[parentId][componentId] = !isSelected;
            })
          );
          let selectedComponents = Object.keys(slaveSelection[parentId])
            .filter((key) => slaveSelection[parentId][key] === true)
            .map((id) => Number(id));
          handleSelect({
            id: parentId,
            selectedComponents: [componentId, ...selectedComponents],
          });
        }}
        isActiveSelect={isSelected}
        toggleCount={toggleCount}
        mesurement={measurement}
        elementId={name}
        containerStyle={style}
      />
    );
  };

  const getListItemHeight = (len) => {
    let height = len * 36;
    if (height > 300) {
      return 300;
    } else {
      return height;
    }
  };

  // console.log('-- state --', currentSelectedMasterCheckbox);
  const getLayerItemSlot = () => {
    return serviceItems.serviceItems.map((serviceItem) => {
      const {
        id,
        showBaseViewHeader,
        type,
        name,
        measurement,
        featureType,
        components,
        style,
        unit,
      } = serviceItem;
      const len = components.length;

      const unCheckedElementCount = unCheckedMap[id];

      // if (id === 50553) {
      //   console.log("unCheckedElementCount", unCheckedElementCount, unCheckedMap[id]);
      // }

      let finalUnCheckedElementCount = unCheckedElementCount; //Math.abs(len - Math.abs(unCheckedElementCount));

      // if (finalUnCheckedElementCount > 0) {
      finalUnCheckedElementCount = len - finalUnCheckedElementCount;
      // }

      if (finalUnCheckedElementCount > len) {
        finalUnCheckedElementCount = len;
      }
      // if (finalUnCheckedElementCount === null) {
      //   finalUnCheckedElementCount = len;
      // }

      // if (finalUnCheckedElementCount === -1) {
      //   finalUnCheckedElementCount = 0;
      // }

      // if (currentSelectedMasterCheckbox[id] === false && Math.abs(unCheckedElementCount) === 0) {
      //   finalUnCheckedElementCount = 0;
      // }

      // if (id === 50553) {
      // console.log("final data", { name, len, finalUnCheckedElementCount });
      // }
      const currentState = masterExpandState[id];
      const isActive = currentSelectedMaster === id;
      const isCheckBox = currentSelectedMasterCheckbox[id];

      return (
        <>
          <EmServiceItemRow
            totalCount={len}
            toggledCount={finalUnCheckedElementCount}
            colorCode={serviceItem.color}
            styleCode={style}
            layerType={type}
            featureType={featureType}
            isFirstTakeoffLayer={showBaseViewHeader}
            unit={unit}
            onHover={(a, info) => {
              handleHover({ id, info });
            }}
            onServiceToggleChange={(a, checked) => {
              let uncheckMap = { ...unCheckedMap };
              let masterSlavemap = {};

              setSlaveCheckbox((prevState) =>
                produce(prevState, (draft) => {
                  if (!draft[id]) {
                    draft[id] = {};
                  }

                  let currentItems = checked
                    ? defaultSlaveCheckedState.current[id]
                    : defaultSlaveUnCheckState.current[id];
                  draft[id] = currentItems;

                  let masterKeys = [];

                  if (type === 'takeoff') {
                    let keys = Object.keys(draft).filter(
                      (key) => !String(key).startsWith('TAKEOFF')
                    );
                    masterKeys = keys;
                  }

                  if (type === 'serviceItem' || type === 'unassigned') {
                    let keys = Object.keys(draft);
                    masterKeys = keys;
                  }

                  Object.keys(currentItems).map((slaveKey, j) => {
                    masterKeys.map((masterKey) => {
                      if (draft[masterKey][slaveKey] !== undefined) {
                        if (masterSlavemap[masterKey] === undefined) {
                          masterSlavemap[masterKey] = {
                            ogCount: Object.keys(draft[masterKey]).length,
                            count: 0,
                          };
                        }

                        if (checked) {
                          if (draft[masterKey][slaveKey] === false) {
                            uncheckMap[masterKey] = uncheckMap[masterKey] - 1;
                          }
                          if (draft[masterKey][slaveKey] !== true) {
                            draft[masterKey][slaveKey] = true;
                          }
                          masterSlavemap[masterKey]['count'] -= 1;
                        } else {
                          if (draft[masterKey][slaveKey] === true) {
                            uncheckMap[masterKey] = uncheckMap[masterKey] + 1;
                          }
                          if (draft[masterKey][slaveKey] !== false) {
                            draft[masterKey][slaveKey] = false;
                          }
                          masterSlavemap[masterKey]['count'] += 1;
                        }
                      }
                    });
                  });
                })
              );
              setCurrentSelectedMasterCheckbox((prevState) =>
                produce(prevState, (draft) => {
                  if (!draft[id]) {
                    draft[id] = {};
                  }
                  Object.keys(draft).map((key) => {
                    if (masterSlavemap[key]) {
                      let ogCount = masterSlavemap[key].ogCount;
                      let count = masterSlavemap[key].count;
                      if (ogCount === Math.abs(count)) {
                        if (count < 0) {
                          draft[key] = true;
                        } else {
                          draft[key] = false;
                        }
                      }
                    }
                  });
                  draft[id] = checked;
                })
              );
              setUncheckedMap((prevState) =>
                produce(uncheckMap, (draft) => {
                  if (checked) {
                    draft[id] = 0;
                  } else {
                    draft[id] = Object.keys(
                      defaultSlaveCheckedState.current[id]
                    ).length;
                  }
                })
              );
              if (!isCheckBox) {
                let selectedComponents = Object.keys(
                  defaultSlaveCheckedState.current[id]
                ).map((key) => Number(key));
                setTimeout(() => {
                  handleServiceItemCheckboxToggle(id, selectedComponents);
                }, 0);
              } else {
                setTimeout(() => {
                  handleServiceItemCheckboxToggle(id, []);
                }, 0);
              }
            }}
            onExpandedStateChange={(state) => {
              setMasterExpandState({
                ...masterExpandState,
                [id]: state,
              });
            }}
            onSelect={() => {
              if (isActive === false) {
                setCurrentSelectedMaster(id);
                setSlaveSelection((prevState) =>
                  produce(defaultSlaveUnSelectedState.current, (draft) => {
                    if (!draft[id]) {
                      draft[id] = {};
                    }
                    draft[id] = defaultSlaveSelectedState.current[id];
                  })
                );
              } else {
                setCurrentSelectedMaster(null);
                setSlaveSelection((prevState) =>
                  produce(prevState, (draft) => {
                    if (!draft[id]) {
                      draft[id] = {};
                    }
                    draft[id] = defaultSlaveUnSelectedState.current[id];
                  })
                );
              }

              if (isActive === false) {
                let selectedComponents = Object.keys(slaveSelection[id]).map(
                  (key) => Number(key)
                );
                handleSelect({ id, selectedComponents });
              } else {
                handleSelect({ id, selectedComponents: [] });
              }
            }}
            isActive={isActive}
            expanded={currentState}
            measurement={measurement}
            name={serviceItem.name}
          />

          {currentState === true && (
            <List
              className='List'
              height={getListItemHeight(len)}
              itemCount={len}
              itemData={serviceItem.components}
              itemSize={35}
              width={'100%'}
            >
              {Row}
            </List>
          )}
        </>
      );
    });
  };

  const totalLayersCount = Object.keys(currentSelectedMasterCheckbox).length;
  const totalToggledLayersCount = Object.keys(
    currentSelectedMasterCheckbox
  ).filter((key) => currentSelectedMasterCheckbox[key] === true).length;

  return (
    <EmLayerPanel3
      totalLayersCounts={totalLayersCount}
      allElementsCount={totalLayersCount}
      allToggleElementsCount={totalToggledLayersCount}
      layerPanelHeight={vhMinusPx(100, 224)}
      layersList2={getLayerItemSlot()}
      baseLayersData={serviceItems}
      onMasterCheck={(checked) => {
        setIsServiceChipsVisible(checked);
        setCurrentSelectedMasterCheckbox(
          checked
            ? defaultMasterSelectedCheckboxState.current
            : defaultMasterUnSelectedCheckboxState.current
        );
        setSlaveCheckbox(
          checked
            ? defaultSlaveCheckedState.current
            : defaultSlaveUnCheckState.current
        );
        setUncheckedMap((prevState) =>
          produce(prevState, (draft) => {
            Object.keys(defaultMasterSelectedCheckboxState.current).forEach(
              (key) => {
                let item = defaultMasterSelectedCheckboxState.current[key];
                if (checked) {
                  draft[key] = 0;
                } else {
                  draft[key] = Object.keys(
                    defaultSlaveCheckedState.current[key]
                  ).length;
                }
              }
            );
          })
        );

        setTimeout(() => {
          handleMasterCheckboxToggle(checked);
        }, 0);
      }}
      onParcelToggle={_handleParcelToggle}
      onNearmapToggle={handleNearMapToggle}
      onBasemapToggle={handleBaseMapToggle}
      initialServiceChipsVisibility={isServiceChipsVisible}
      onServiceLabelChange={(checked: boolean) => {
        trackEvents(CreateOrderEvt.ServiceItemChipToggle, { visible: checked });
        setIsServiceChipsVisible(checked);
      }}
    />
  );

  // for refrence
  // return (
  //   <Box className='estimation-layer-panel'>
  //     {/* <EmLayerPanel
  //       serviceListProps={serviceItems}
  //       onMasterCheck={handleMasterCheckboxToggle}
  //       onComponentToggle={handleComponentToggle}
  //       onServiceItemToggle={handleServiceItemCheckboxToggle}
  //       onSelect={handleSelect}
  //       onHoverChange={handleHover}
  //       onNearmapToggle={handleNearMapToggle}
  //       onBasemapToggle={handleBaseMapToggle}
  //       onParcelToggle={handleParcelToggle}
  //       selected={selectedComponents}
  //       layerPanelHeight={vhMinusPx(100, 224)}
  //       checkedKeys2={checkedItems}
  //     /> */}
  //   </Box>
  // );
};

export default EstimationLayerPanel;
