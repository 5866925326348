import React, { forwardRef } from 'react';
import { useElementPositioningContext } from '../../../Contexts/ElementPositioningContext';

const withElementPositioningAndVisibility = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => {
  const WrappedComponent: React.FC<P> = forwardRef((props, ref) => {
    const contextValue = useElementPositioningContext();

    return <Component {...props} ref={ref} {...contextValue} />;
  });

  return WrappedComponent;
};

export default withElementPositioningAndVisibility;
