import { BETA_FEATURE_ACTIONS } from './action_types';
import { INITIAL_STATE } from '@/store/betaRelease/state';

export default (state = INITIAL_STATE, { type }: any) => {
  switch (type) {
    case BETA_FEATURE_ACTIONS.SHOW_FEED:
      return {
        ...state,
        isFeedVisible: true,
      };
    case BETA_FEATURE_ACTIONS.SHOW_ATTRIBUTES:
      return {
        ...state,
        attributesVisible: true,
      };
    case BETA_FEATURE_ACTIONS.SHOW_STREET_VIEW:
      return {
        ...state,
        isStreetViewVisible: true,
      };
    case BETA_FEATURE_ACTIONS.SHOW_ORDERQUEUE:
      return {
        ...state,
        isOrderQueueVisible: true,
      };
    case BETA_FEATURE_ACTIONS.HIDE_ORDER_RATING:
      return {
        ...state,
        isOrderRatingVisible: false,
      };
    case BETA_FEATURE_ACTIONS.ADD_MORE_BULK_ORDER:
      return {
        ...state,
        isAddMoreBulkOrder: true,
      };
    case BETA_FEATURE_ACTIONS.UPLOAD_GEO_JSON:
      return {
        ...state,
        isGeoJSONUpload: true,
      };
    case BETA_FEATURE_ACTIONS.ALLOW_PARCEL_REPAIR:
      return {
        ...state,
        allowParcelRepair: true,
      };
    case BETA_FEATURE_ACTIONS.ALLOW_IMAGE_REPAIR:
      return {
        ...state,
        allowImageryRepair: true,
      };
    case BETA_FEATURE_ACTIONS.ALLOW_TABULA_UPLOAD:
      return {
        ...state,
        allowTabulaUpload: true,
      };
    case BETA_FEATURE_ACTIONS.SHOW_DOWNLOAD_GEOJSON:
      return {
        ...state,
        isDownloadGeojsonVisible: true,
      };
    case BETA_FEATURE_ACTIONS.ALLOW_KML_EXPORT:
      return {
        ...state,
        isExportKmlFilesEnabled: true,
      };
    case BETA_FEATURE_ACTIONS.COPY_VIEW_ID:
      return {
        ...state,
        copyViewId: true,
      };
    case BETA_FEATURE_ACTIONS.IS_NEARMAP_PREVIEW_ENABLED:
      return {
        ...state,
        isNearmapPreviewEnabled: true,
      };
    case BETA_FEATURE_ACTIONS.IS_SERVICE_ITEM:
      return {
        ...state,
        isServiceItem: true,
      };
    case BETA_FEATURE_ACTIONS.SHOW_REPORTS:
      return {
        ...state,
        showReports: true,
      };
    case BETA_FEATURE_ACTIONS.ENABLE_ADD_WORKSPACE:
      return {
        ...state,
        isAddWorkspaceEnabled: true,
      };
    case BETA_FEATURE_ACTIONS.IS_ESTIMATIONS_ENABLED:
      return {
        ...state,
        isEstimationsEnabled: true,
      };
    case BETA_FEATURE_ACTIONS.ENABLE_INSIGHTS_DASHBOARD:
      return {
        ...state,
        enableInsightsDashboard: true,
      };
    case BETA_FEATURE_ACTIONS.DEFAULT:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
