import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { IQueueResponse } from '../utils/types';

const axiosInstance = getAxiosInstance();

export const getQueueOrder = async (): Promise<IQueueResponse> => {
  const URL = `/api/emporio/v1/queue`;

  return axiosInstance
    .get<IQueueResponse>(URL)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || error));
};

export const reqNewQueue = async () => {
  const URL = `/api/emporio/v1/request`;
  return axiosInstance
    .post(URL)
    .then((res) => res)
    .catch((err) => { });
};
