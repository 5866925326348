import React from 'react';

const ExclamationRed = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g id='ExclamationRed'>
      <path
        id='Icon'
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.19529 3.73236C7.74455 2.75588 9.15045 2.75588 9.69972 3.73235L13.7084 10.8589C14.2471 11.8166 13.555 13 12.4562 13H4.43882C3.33997 13 2.64788 11.8166 3.1866 10.8589L7.19529 3.73236ZM9.1658 10.845C9.1658 11.2417 8.84418 11.5634 8.44744 11.5634C8.0507 11.5634 7.72908 11.2417 7.72908 10.845C7.72908 10.4483 8.0507 10.1266 8.44744 10.1266C8.84418 10.1266 9.1658 10.4483 9.1658 10.845ZM8.44744 5.09809C8.0507 5.09809 7.72908 5.41971 7.72908 5.81645V7.97154C7.72908 8.36828 8.0507 8.6899 8.44744 8.6899C8.84418 8.6899 9.1658 8.36828 9.1658 7.97154V5.81645C9.1658 5.41971 8.84418 5.09809 8.44744 5.09809Z'
        fill='#EF4444'
      />
    </g>
  </svg>
);

export default ExclamationRed;
