import { ToolKey } from '../types';
import { ModifySyncState } from '../../../../../../modules/common/types';
import { Store } from '@/store';
import {
  modifySyncState as setModifySyncState,
  setLastSyncedOn,
} from '@/store/map/actions';
import { executeModification } from '@/helpers/mapUtils/tools/modifyTool.js';

const handleSaveUnSyncedModifications = () => {
  Store.dispatch(setLastSyncedOn(new Date()));
  Store.dispatch(setModifySyncState(ModifySyncState.SYNCING));
  executeModification();
};

export const saveIfAnyUnSyncedModifications = () => {
  const unSyncedChanges = Store.getState().map.modifyDraftEvents;
  const activeTool = Store.getState().user.activeTool;
  const modifySyncState = Store.getState().map.modifySyncState;

  if (
    activeTool === ToolKey.EDIT &&
    modifySyncState !== ModifySyncState.SYNCING &&
    unSyncedChanges.length > 0
  ) {
    handleSaveUnSyncedModifications();
    return true;
  }

  return false;
};
