import {
  SUBSCRIPTION_BILLING_HISTORY,
  SUBSCRIPTION_HOME_URL,
  SUBSCRIPTION_MANAGEMENT_URL,
  SUBSCRIPTION_UPDATE_CREDITS,
  TEAM_MANAGEMENT_URL,
} from '../../../modules/common/constants/urls';

export const UPDATE_ADDONS = SUBSCRIPTION_UPDATE_CREDITS;
export const PLANS_LEARN_MORE = SUBSCRIPTION_HOME_URL;
export const EXPLORE_SUBSCRIPTION_PLANS = SUBSCRIPTION_MANAGEMENT_URL;
export const UPDATE_PAYMENT = SUBSCRIPTION_HOME_URL;
export const TRAIL_OVER_UPGRADE_NOW = SUBSCRIPTION_MANAGEMENT_URL;
export const UPGRADE_TO_PROFESSIONAL = SUBSCRIPTION_MANAGEMENT_URL;
export const UPGRADE_TO_TEAMS = SUBSCRIPTION_MANAGEMENT_URL;
export const UPGRADE_TO_ENTERPRISE = SUBSCRIPTION_MANAGEMENT_URL;
export const MANAGE_ADD_ON = SUBSCRIPTION_HOME_URL;
export const SEE_BILLING_DETAILS = SUBSCRIPTION_BILLING_HISTORY;
export const PROFESSIONAL_MUCH_MORE = SUBSCRIPTION_HOME_URL;
export const TEAMS_MUCH_MORE = SUBSCRIPTION_HOME_URL;
export const ENTERPRISE_MUCH_MORE = SUBSCRIPTION_HOME_URL;
export const PLAN_EXPIRED = SUBSCRIPTION_MANAGEMENT_URL;
export const NO_ACTIVE_SUBSCRIPTION = SUBSCRIPTION_MANAGEMENT_URL;
export const REACTIVATE_SUBSCRIPTION = SUBSCRIPTION_MANAGEMENT_URL;
