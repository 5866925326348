import { ReduxSubscriptionGuard } from '@/modules/subscription/guards/base';
import { OrderStore } from '@/store/order/state';
import { ConditionFunction } from '@/modules/subscription/guards/base/ReduxSubscriptionGuard';
import { FC } from 'react';
import { SubscriptionFeature } from '@/modules/subscription/types';

const ALLOWED_VIEWS = 2;

/** Guard maximum number of views that can be added to a project according to current subscription plan  */
const AddViewGuard: FC = ({ children }) => {
  const handleCondition: ConditionFunction = (views, featureData) => {
    if (!featureData) return false;

    /** At any point of time, a project has a BaseView and other views */
    return (
      views.length - 1 <
      (Number(featureData.attribute?.totalDraftAllowed) || ALLOWED_VIEWS)
    );
  };

  return (
    <ReduxSubscriptionGuard<OrderStore, Array<any>>
      feature={SubscriptionFeature.MapViews}
      store='order'
      property='reduxView'
      condition={handleCondition}
      modal={{
        title:
          'You have reached to the maximum limit of views on this property.',
      }}
    >
      {children}
    </ReduxSubscriptionGuard>
  );
};

AddViewGuard.displayName = 'AddViewGuard';

export default AddViewGuard;
