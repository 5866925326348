// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon40IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon40Icon(props: Icon40IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 20 20"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M10.006 13.139c-.281 0-.54-.116-.774-.346L4.896 8.357a1.026 1.026 0 01-.199-.293.953.953 0 01-.064-.351.924.924 0 01.463-.815.881.881 0 01.468-.128c.262 0 .493.1.692.298l3.99 4.108h-.475l3.979-4.108a.944.944 0 01.685-.298c.172 0 .329.043.47.128a.9.9 0 01.333.34c.086.14.13.3.13.475a.877.877 0 01-.264.644l-4.33 4.436a1.2 1.2 0 01-.364.258 1.07 1.07 0 01-.404.088z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon40Icon;
/* prettier-ignore-end */
