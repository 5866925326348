import {
  addMoreBulkOrder,
  allowImageRepair,
  allowKmlExport,
  allowParcelRepair,
  allowTabulaUpload,
  copyViewId,
  hideOrderRating,
  isNearmapPreviewEnabled,
  isServiceItem,
  setBetaFeatureDefault,
  showAttributes,
  showDownloadGeojson,
  showFeed,
  showStreetView,
  uploadGeoJSON,
  showReports,
  enableAddWorkspace,
  isEstimationsEnabled,
  enableInsightsDashboard,
} from './actions';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';

const getBetaFeatures = (reqId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getAxiosInstance().get('/api/beta/features')
      if (response.status === 200) {
        if (response.data.features?.length) {
          response.data.features.map(feature => {
            if (feature === 'feed') {
              dispatch(showFeed());
            }
            if (feature === 'attributes') {
              dispatch(showAttributes());
            }
            if (feature === 'street_view') {
              dispatch(showStreetView());
            }
            if(feature === 'hide_order_ratings'){
              dispatch(hideOrderRating())
            }
            if(feature === 'add_more_bulk_flow'){
              dispatch(addMoreBulkOrder())
            }
            if(feature === 'upload_layer_on_map'){
              dispatch(uploadGeoJSON())
            }
            if(feature === 'allow_parcel_repair'){
              dispatch(allowParcelRepair())
            }
            if(feature === 'allow_imagery_repair'){
              dispatch(allowImageRepair())
            }
            if(feature === 'allow_tabula_upload'){
              dispatch(allowTabulaUpload())
            }
            if(feature === 'download_geojson'){
              dispatch(showDownloadGeojson())
            }

            if(feature === 'export_kml'){
              dispatch(allowKmlExport())
            }

            if(feature === 'copy_view_id'){
              dispatch(copyViewId())
            }

            if(feature === 'nearmap_preview'){
              dispatch(isNearmapPreviewEnabled())
            }

            if(feature === 'service_item'){
              dispatch(isServiceItem())
            }

            if(feature === 'show_reports'){
              dispatch(showReports())
            }

            if (feature === 'add_workspace') {
              dispatch(enableAddWorkspace())
            }

            if (feature === 'estimations_v2') {
              dispatch(isEstimationsEnabled())
            }

            if (feature === 'insights_dashboards') {
              dispatch(enableInsightsDashboard())
            }
          });
        } else {
          dispatch(setBetaFeatureDefault());
        }

        resolve(true);
      }
    } catch (error) {
      // Ignore the error.
      // The beta features will not be available if error occurs here
    }
  })
};

export default getBetaFeatures;
