import { useContext, useEffect } from 'react';
import { MapZoom } from '@siterecon/sr-component/dist/sr-component-library';
import MapContext from '@/components/pages/project/MapContext';
import { trackEvents } from '../../../../../helpers/utilities';
import { MapControlsEvt } from '../../../../../segment';

interface ZoomProps {
  mapRef?: any;
  darkMode?: boolean;
}

const Zoom = ({ mapRef, darkMode }: ZoomProps) => {
  const contextMapRef = useContext(MapContext);
  const olMapRef = mapRef ?? contextMapRef;

  if (!olMapRef) {
    return null;
  }

  useEffect(() => {
    trackEvents(MapControlsEvt.ZoomControlsVisible);
  }, []);

  const zoom = (amount: number) => {
    const view = olMapRef.getView();
    const zoom = view.getZoom();
    view.animate({ zoom: zoom + amount, duration: 200 });
  };

  const handleZoomIn = () => {
    zoom(1)
  };

  const handleZoomOut = () => {
    zoom(-1);
  };

  return (
    <MapZoom
      onZoomIn={handleZoomIn}
      onZoomOut={handleZoomOut}
      darkMode={darkMode}
    />
  );
};

export default Zoom;
