import { NotificationBarType } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { hideNotificationBar, showNotificationBar } from '@/store/ui/actions';
import { IStore } from '../../../../store/types';
import { NotificationBarData } from '../../../../store/ui/state';
import { indexOf } from 'lodash';
import { priorities } from './helpers';

export type ShowNotificationBarFunction = (
  type: NotificationBarType,
  data?: any
) => void;

export type HideNotificationBarFunction = (type: NotificationBarType) => void;

export type IsVisibleFunction = (type: NotificationBarType) => boolean;

interface UseNotificationBarHookResponse extends NotificationBarData {
  show: ShowNotificationBarFunction;

  hide: HideNotificationBarFunction;

  /** Checks if given {@link NotificationBarType} is currently visible or not */
  isVisible: IsVisibleFunction;
}

const useNotificationBar = (): UseNotificationBarHookResponse => {
  const dispatch = useDispatch();
  const {
    visible,
    type: activeNotificationBarType,
    data,
  } = useSelector<IStore, NotificationBarData>(
    (state) => state.ui.notificationBar
  );

  /** Checks if provided {@link NotificationBarType} has more priority over the existing NotificationBar in redux. If NotificationBar data is not available in current redux store, returns `true` */
  const hasMorePriority = (newType: NotificationBarType): boolean => {
    if (!activeNotificationBarType) {
      /** There is no data available in existing redux store */
      return true;
    }

    return (
      indexOf(priorities, newType) >=
      indexOf(priorities, activeNotificationBarType)
    );
  };

  const show: ShowNotificationBarFunction = (type, data) => {
    if (!hasMorePriority(type)) return;

    dispatch(
      showNotificationBar({
        type,
        data,
      })
    );
  };

  const hide: HideNotificationBarFunction = () => {
    dispatch(hideNotificationBar());
  };

  const isVisible: IsVisibleFunction = (type) => {
    return visible && type === activeNotificationBarType;
  };

  return {
    show,
    hide,
    visible,
    type: activeNotificationBarType,
    data,
    isVisible,
  };
};

export default useNotificationBar;
