export enum Steps {
  'confirm-address' = 0,
  'confirm-parcel' = 1,
  'customer-information' = 2,
  'select-features' = 3,
  'generate-measurements' = 4,
  'awaiting-approval' = 5,
}

export enum OrderSteps {
  CONFIRM_ADDRESS = 'confirm-address',
  CONFIRM_PARCEL = 'confirm-parcel',
  CUSTOMER_INFORMATION = 'customer-information',
  SELECT_FEATURES = 'select-features',
  GENERATE_MEASUREMENTS = 'generate-measurements',
  DONE = 'done',
}

export enum OrderType {
  REGULAR = 'regular',
  SELF_MEASURED = 'self_measured',
  INSTANT = 'instant_measurement',
  BULK_PROJECT = 'bulk_project',
}

export enum WidgetBarTabs {
  PARCEL = 'parcel',
  ORDER = 'order cart',
  VIEWS = 'views',
  LAYERS = 'layers',
  ESTIMATION = 'estimation',
  NOTES = 'notes',
  REPORTS = 'reports',
  SNAPSHOT = 'snapshot',
  BULK = 'bulk'
}

export enum MapServiceEnum {
  GOOGLE = 'google',
  BING = 'bing',
}