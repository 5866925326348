import React, { HTMLProps } from 'react';
import { Row } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import Text from '@/components/elements/text/Text';
import MrTakeoffLayerServiceRowChipCollection from '../../PlasmicComponents/MrTakeoffLayerServiceRowChipCollection';
import './style.less';

interface MoreChipProps {
  listToRender?: CheckboxValueType[];
  type?: string;
  fallback?: string;
  fallbackProps?: HTMLProps<any>;
}

const MoreChip = ({
  listToRender,
  type = 'p8',
  fallback,
  fallbackProps,
}: MoreChipProps) => {
  if (fallback && !listToRender?.length) {
    return (
      <Row justify='start'>
        <Text type={type} {...fallbackProps}>
          {fallback}
        </Text>
      </Row>
    );
  }

  return (
    <MrTakeoffLayerServiceRowChipCollection
      serviceItemRowChipData={listToRender}
    />
  );
};

export default MoreChip;
