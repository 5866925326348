export enum MapTool {
  Select = 'select',
  Drag = 'drag',
  Polygon = 'polygon',
  Modify = 'modify',
  Draw = 'draw',
  Cut = 'cut',
  LineSplit = 'slice',
  LassoSlice = 'doughnut',
  Path = 'path',
}

export enum SnapshotFeature {
  Measurement = 'showMeasurements',
  ExportSize = 'exportSizes',
  Layers = 'layers',
  Orientations = 'orientations',
  WSLogo = 'workspaceLogo'
}
