// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon50IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon50Icon(props: Icon50IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M18.588 9.28c.495 0 .934-.01 1.372.001.727.017 1.204.745.988 1.55-.653 2.435-1.323 4.864-1.986 7.295-.15.55-.481.828-.97.845-.573.02-1.147.026-1.72.026-3.88.003-7.76 0-11.64.003-.474 0-.996 0-1.299-.463-.173-.265-.203-.272-.316-1a1.61 1.61 0 01-.016-.231c0-3.649-.004-7.298.002-10.947.002-.871.446-1.356 1.206-1.356 1.552-.001 3.104.003 4.656-.003.526-.001.897.26 1.124.807.134.325.215.775.442.928.245.166.632.058.957.058 1.89.002 3.779 0 5.668.002.941 0 1.505.634 1.53 1.72.006.244 0 .488 0 .764h.002zM6.483 10.45L4.5 17.777h13.486l1.986-7.327H6.483h0zm-2.43 4.916l.034.006c.457-1.67.915-3.34 1.37-5.01.233-.86.482-1.08 1.257-1.08h10.838v-.627c-.001-.613-.069-.69-.602-.69-2.205 0-4.409-.005-6.613.003-.383.002-.64-.175-.802-.566-.124-.303-.263-.598-.375-.906-.088-.242-.21-.335-.451-.332-1.45.016-2.902.007-4.352.009-.098 0-.195.014-.304.021v9.172h0z"
        }
        fill={"currentColor"}
        stroke={"currentColor"}
        strokeWidth={".32"}
        strokeLinejoin={"round"}
      ></path>
    </svg>
  );
}

export default Icon50Icon;
/* prettier-ignore-end */
