import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AttributeLayerSelectionMulti } from '../popup';
import { _get } from '../../../../../helpers/utilities/lodashUtils';
import { getServiceItemAssignmentsByLayerAndComponents } from '../../../../../modules/property/transformers/service-item.transformers';
import './style.less';

export const MultiLayerPopup = (props) => {
  const multiData = useSelector(
    (state) => state.feature.attributeMultiPopupData
  );

  const closeHandle = () => {
    props?.closeHandle();
  };

  const layerId = useMemo(() => {
    const firstLayerKey = Object.keys(multiData || {})[0];

    if (!firstLayerKey) return null;

    return firstLayerKey.split('&&')[1];
  }, [Object.keys(multiData || {})]);

  return (
    <div id='multi-layer-popup'>
      <AttributeLayerSelectionMulti
        key={layerId}
        isBaseView={props.isBaseView}
        isEditableView={props.isEditableView}
        geometryType={props.geometryType}
        featureType={props.featureType}
        orderStatus={props.orderStatus}
        layersData={props.layersData}
        orderHash={props.orderHash}
        initialAssignments={getServiceItemAssignmentsByLayerAndComponents(
          props.layersData,
          props.layerComponents
        )}
        deleteComponents={props.deleteComponents}
        loadLayerComponents={props.loadLayerComponents}
        onUpdateLayerComponents={props.onUpdateLayerComponents}
        onDuplicateComponent={props.onDuplicateComponent}
        onMergeComponents={props.onMergeComponents}
        onClose={closeHandle}
        onServiceItemsChange={props.onServiceItemsChange}
      />
    </div>
  );
};
