import { useEffect, useState } from "react";

// const getEstimationTimeRemainingInMinutes = (dataDeliveredAt, pricingDeliveryTimeInHours) => {
//   if (!dataDeliveredAt || !pricingDeliveryTimeInHours || isNaN(pricingDeliveryTimeInHours)) {
//     return 0;
//   }

//   const deliveredAtDate = new Date(dataDeliveredAt);
//   if (isNaN(deliveredAtDate.getTime())) {
//     return 0;
//   }

//   const currentTime = new Date();
//   const targetTime = new Date(deliveredAtDate.getTime() + pricingDeliveryTimeInHours * 60 * 60 * 1000);
//   const timeDifference = targetTime.getTime() - currentTime.getTime();
//   const remainingMinutes = timeDifference / (1000 * 60);

//   return remainingMinutes > 0 ? remainingMinutes : 0;
// };

const getEstimationTimeRemainingInMinutes = (dataDeliveredAt, pricingDeliveryTimeInHours, estimatedTimeInHours, hoursBlockedBeforeAvailability) => {
  if (
    !dataDeliveredAt ||
    !pricingDeliveryTimeInHours ||
    isNaN(pricingDeliveryTimeInHours) ||
    !estimatedTimeInHours ||
    isNaN(estimatedTimeInHours)
  ) {
    return 0;
  }

  const deliveredAtDate = new Date(dataDeliveredAt);
  if (isNaN(deliveredAtDate.getTime())) {
    return 0;
  }

  const currentTime = new Date();

  // Time elapsed since delivery in milliseconds
  const timeElapsed = currentTime.getTime() - deliveredAtDate.getTime();

  // Convert timeElapsed to minutes
  const timeElapsedInMinutes = timeElapsed / (1000 * 60);

  // Add hoursBlockedBeforeAvailability to the estimated time if it's provided (not null)
  const totalHours = estimatedTimeInHours + pricingDeliveryTimeInHours + (hoursBlockedBeforeAvailability || 0);

  // Convert the total estimated time to minutes
  const totalEstimatedTimeInMinutes = totalHours * 60;

  // Remaining minutes is the difference between the total estimated time and the time elapsed
  const remainingMinutes = totalEstimatedTimeInMinutes - timeElapsedInMinutes;

  // Return remaining time, ensuring it's non-negative
  return remainingMinutes > 0 ? remainingMinutes : 0;
};


// Custom Hook that calculates and formats the remaining time
function useFormattedMinutesTimer(orderTileData) {
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    const startMinutes = getEstimationTimeRemainingInMinutes(
      orderTileData.dataDeliveredAt,
      orderTileData.pricingDeliveryTime,
      orderTileData.estimatedTime,
      orderTileData.hoursBlockedBeforeAvailability,
    );

    let totalSeconds = startMinutes * 60;

    const formatTime = (seconds) => {
      if (seconds <= 0) return "00h 00m";

      const days = Math.floor(seconds / 86400);
      const hours = Math.floor((seconds % 86400) / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      if (days > 0) {
        return `${String(days).padStart(2, "0")}d ${String(hours).padStart(2, "0")}h`;
      } else {
        return `${String(hours).padStart(2, "0")}h ${String(minutes).padStart(2, "0")}m`;
      }
    };

    const updateClock = () => {
      if (totalSeconds < 0) return;
      const formatted = formatTime(totalSeconds);
      setFormattedTime(formatted);
      totalSeconds -= 60;
    };

    const updateClockInitial = () => {
      if (totalSeconds < 0) return;
      const formatted = formatTime(totalSeconds);
      setFormattedTime(formatted);
    };

    updateClockInitial();
    const timerInterval = setInterval(() => {
      totalSeconds = Math.max(totalSeconds - 60, 0);
      updateClock();
    }, 60000);

    return () => clearInterval(timerInterval);
  }, [orderTileData]);

  return formattedTime;
}

export default useFormattedMinutesTimer;
