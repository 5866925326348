import { useEffect } from 'react';
import { saveIfAnyUnSyncedModifications } from '../helpers';

const useSyncOnOutsideClick = () => {
  useEffect(() => {
    const onOutsideClick = (event) => {
      const containers = [
        document.getElementById('ol-map--project'),
        document.getElementById('floating-tools'),
        ...document.getElementsByClassName('ant-popover'),
        ...document.getElementsByClassName('ant-modal'),
      ];

      if (containers.some((container) => container?.contains(event.target))) {
        /** If the clicked element is inside the map or floating tool container, we don't want to continue */
        return;
      }

      saveIfAnyUnSyncedModifications();
    };

    document.addEventListener('click', onOutsideClick);

    return () => {
      document.removeEventListener('click', onOutsideClick);

      /** When component is destroyed, we need to check if any unsaved changes are there, if yes. We'll sync that  */
      saveIfAnyUnSyncedModifications();
    };
  }, []);
};

export default useSyncOnOutsideClick;
