import { SHARE_MAP_PAGE } from '../../components/constants';

export const isAuthenticated = () => {
  if (typeof window == 'undefined') {
    return false;
  }
  if (localStorage.getItem('sr_token')) {
    return JSON.parse(localStorage.getItem('sr_token'));
  } else {
    return false;
  }
};

export const getToken = () => {
  const token = localStorage.getItem('sr_token');
  if (token) {
    return JSON.parse(token).token;
  }
};

export const getNewUser = () => {
  const token = localStorage.getItem('new_user');
  if (token) {
    return JSON.parse(token).token;
  }
};

export const setNewUser = (data) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('new_user', JSON.stringify(data));
  }
};

export const isUserAuthenticated = () => {
  if (typeof window == 'undefined') {
    return false;
  }
  if (window.location?.pathname.split('/')[1] === SHARE_MAP_PAGE) {
    return false;
  }
  if (localStorage.getItem('is_authenticated')) {
    return JSON.parse(localStorage.getItem('is_authenticated'));
  } else {
    return false;
  }
};

/*----------------------------------------------------------------------------------------------- */

export const logout = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('sr_token');
    localStorage.removeItem('mapkey');
    localStorage.removeItem('flutter.ff_token');
    localStorage.removeItem('new_user');
    localStorage.removeItem('sr_info');
    localStorage.removeItem('is_authenticated');
    localStorage.removeItem('sr_workspaces');
  }
};

/*----------------------------------------------------------------------------------------------- */

export const authenticate = (data) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('sr_token', JSON.stringify(data));
    localStorage.setItem('flutter.ff_token', "\"" + data?.token + "\"");
  }
};

export const userAuthenticate = (data) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('is_authenticated', JSON.stringify(data));
  }
};

export const userInfo = (data) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('sr_info', JSON.stringify(data));
  }
};

/**
 * Returns Authorization Header with Token from Local Storage
 * @returns {{Authorization: string}}
 */
export const authorizationHeader = () => {
  return {
    Authorization: `Token ${getToken()}`,
  };
};

export const switchWorkspaceIfLocallyAvailable = (workspaceId) => {
  let workspaces = localStorage.getItem('sr_workspaces');
  let srToken = localStorage.getItem('sr_token');

  if (!workspaces || !srToken) return false;

  workspaces = JSON.parse(workspaces);
  srToken = JSON.parse(srToken);

  const workspace = workspaces.find(
    (workspace) => String(workspace.id) === String(workspaceId)
  );

  if (!workspace || !workspace.token || !srToken.user) {
    return false;
  }

  const newSrToken = {
    token: workspace.token,
    user: { ...srToken.user, organization_id: workspace.id },
  };

  localStorage.setItem('sr_token', JSON.stringify(newSrToken));
  localStorage.setItem('flutter.ff_token', "\"" + newSrToken?.token + "\"");

  const srInfo = JSON.parse(localStorage.getItem('sr_info') || '');

  if (srInfo) {
    localStorage.setItem(
      'sr_info',
      JSON.stringify({
        user: { ...srInfo.user, organization_id: workspace.id },
      })
    );
  }

  return true;
};
