import { setAll, setDefault } from './actions';
import { _get } from '../../helpers/utilities/lodashUtils';
import { maskCredits, unmaskCredits } from '../user/actions';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';

const getConfigurations =
  ({ url, header }) =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { status, data } = await getAxiosInstance().get(url, {
          headers: header,
        });

        if (status !== 200) return;

        if (!Object.keys(data).length) {
          /** If data returned from API is empty, we don't need to proceed further */
          dispatch(setDefault());
          return;
        }
        if (!_get(data, 'maskCredits')) {
          dispatch(maskCredits());
        } else {
          dispatch(unmaskCredits());
        }
        dispatch(setAll(data));
      } catch (error) {
        /** @TODO: Handle the error case and set the configuration accordingly */
        dispatch(setDefault());
      }
    });
  };

export default getConfigurations;
