export const CREATE_NOTES = 'CREATE_NOTES';
export const CREATE_FEEDBACK = 'CREATE_FEEDBACK';
export const STORE_ORDER_NOTES = 'STORE_ORDER_NOTES';
export const STORE_ORDER_NOTES_ORIGINAL = 'STORE_ORDER_NOTES_ORIGINAL';
export const SHOW_RESOLVE_NOTES = 'SHOW_RESOLVE_NOTES';
export const SET_IS_FILTER = 'SET_IS_FILTER';
export const SET_IS_NOTES_PANEL_EXPANDED = 'SET_IS_NOTES_PANEL_EXPANDED';
export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';
export const RESET_ATTACHMENTS = 'RESET_ATTACHMENTS';
export const REMOVE_ATTACHMENTS = 'REMOVE_ATTACHMENTS';
