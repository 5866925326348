import React, { useEffect, useState } from "react";
import { getAxiosInstance } from "../../../../../helpers/utilities/api-utils";
import useViewSelectionOrCreationUtil from "../../../../../modules/property/hooks/useViewSelectionOrCreationUtil";
import EmPricingDeliveredModal from "../../../../PlasmicComponents/EmPricingDeliveredModal";
import { WidgetBarTabs } from "../../../../types";
import useBidConsume from "../hooks/useBidConsume";
import { EstimationTabs } from "../types";
import * as API_ENDPOINTS from '../../../../../helpers/constants/APIEndpoints';
import { replaceParams } from "../../../../../helpers/utilities/linkUtils";

const axiosInstance = getAxiosInstance();

const PricingDeliveryModal = ({
  handleWidgetBarTabChange,
  selectedWidgetBarTab,
  setActiveEstimationTab,
  orderTileData,
}) => {

  const [visible, setVisible] = useState(false);
  const { handleEstimationViewClick } = useViewSelectionOrCreationUtil();
  const bidConsume = useBidConsume();

  useEffect(() => {
    if (orderTileData.estimationStatus === "estimation_delivered" && orderTileData.isEstimationReviewed === false) {
      setVisible(true);
    }
  }, [])

  const markEstimationAsReviewedOnBackend = () => {
    axiosInstance
      .post(
        replaceParams(API_ENDPOINTS.REVIEW_ESTIMATION, {
          ':orderId': orderTileData.orderNo,
        })
      )
      .then((res) => { })
      .catch((err) => { });
  }

  const onReviewEstimates = () => {
    handleWidgetBarTabChange(
      WidgetBarTabs.ESTIMATION,
      selectedWidgetBarTab
    );
    handleEstimationViewClick();
    setTimeout(() => {
      setActiveEstimationTab(EstimationTabs.ESTIMATION)
    }, 600);
    markEstimationAsReviewedOnBackend();
    setVisible(false);
  }


  const onClose = () => {
    markEstimationAsReviewedOnBackend();
    setVisible(false);
  }

  const onRemindMeLater = () => {
    setVisible(false);
  }

  const pricingDelivered = {
    "totalService": orderTileData.serviceIds ? orderTileData.serviceIds.length : 0, // Check if serviceIds is not null
    "bidValue": orderTileData.bidValue,
    "grossMarginInPercentage": orderTileData.grossMarginPercentage,
    "deductedBidValue": orderTileData.bidValue,
    "workspaceBidCreated": bidConsume.workspaceBidCreated,
    "teamNotes": orderTileData.estimationNote,
  };
  
  if (!visible) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 12000, // Ensure it is on top
      }}
    >
      <EmPricingDeliveredModal
        onClose={onClose}
        onReviewEstimates={onReviewEstimates}
        onRemindMeLater={onRemindMeLater}
        bidConsume={bidConsume}
        pricingDelivered={pricingDelivered}
      />
    </div>
  );
};

export default PricingDeliveryModal;
