import React from 'react';
import ReactDOM from 'react-dom';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { Col } from 'antd';
import { pxToVhSR } from '../../../../components/utils';

const FullScreenLoader = ({ message }) => {
  const portalRoot = document.getElementById('root');

  if (!portalRoot) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box style={{ textAlign: 'center', color: 'white' }}>
          <Col style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
            <Box>
              <img
                style={{ height: pxToVhSR(50), width: pxToVhSR(50) }}
                src='https://fragmentor.siterecon.ai/common-assets/gif/circular-loader.gif'
                alt='loader'
              />
            </Box>
            <Typography
              style={{ color: '#fff' }}
              fontSize={pxToVhSR(14)}
              fontWeight={500}
              lineHeight={'22px'}
            >
              {message}
            </Typography>
          </Col>
        </Box>
      </div>,
      portalRoot
    )
  );
};

export default FullScreenLoader;
