// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vector411IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vector411Icon(props: Vector411IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 141 94"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M16.922 93.4h119.346c2.094 0 4.068-4.933 3.844-7.47-.224-2.536-1.767-4.577-3.322-6.286-1.555-1.71-3.266-3.394-4.036-5.767-1.375-4.236.699-8.85 2.552-12.808 2.188-4.679 4.184-9.591 4.976-14.89.79-5.297.259-11.075-2.27-15.492-2.53-4.416-7.288-7.097-11.539-5.618-3.232 1.125-5.651 4.287-8.298 6.809-2.647 2.522-6.207 4.533-9.291 2.906-3.021-1.594-4.261-5.939-4.988-9.85-1.107-5.962-1.843-12.21-4.498-17.397-2.655-5.186-7.956-9.017-12.686-6.98-3.4 1.463-5.595 5.476-7.082 9.48-3.23 8.7-4.276 18.401-7.386 27.166-3.11 8.764-9.306 17.055-17.141 17.255-5.358.136-10.312-3.564-14.203-8.054-3.89-4.49-7.053-9.855-11-14.27-3.947-4.416-9.024-7.938-14.371-7.497-5.347.441-10.568 6.102-10.02 12.597.31 3.683 2.267 6.79 3.776 10.005 1.51 3.215 2.6 7.28 1.14 10.528-1.797 4.001-6.453 4.653-8.846 8.162-2.765 4.055-1.497 10.26.732 14.779 3.006 6.097 9.328 9.663 14.61 12.692z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector411Icon;
/* prettier-ignore-end */
