import { successBottomMessage } from '../../components/elements/bottom-message';

export const copyToClipboard = (text, showSuccessMsg = true) => {
  let myTemporaryInputElement = document.createElement('input');
  myTemporaryInputElement.type = 'text';
  myTemporaryInputElement.value = text;
  document.body.appendChild(myTemporaryInputElement);
  myTemporaryInputElement.select();
  document.execCommand('Copy');
  document.body.removeChild(myTemporaryInputElement);
  if (showSuccessMsg) {
    successBottomMessage('Copied to clipboard');
  }
};
