// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon39IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon39Icon(props: Icon39IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M12.006 15.139c-.281 0-.54-.116-.774-.346l-4.336-4.436a1.026 1.026 0 01-.199-.292.953.953 0 01-.064-.352.924.924 0 01.463-.815.881.881 0 01.468-.128c.262 0 .493.1.692.298l3.99 4.108h-.475l3.979-4.108a.945.945 0 01.686-.298c.171 0 .328.043.468.128a.9.9 0 01.334.34c.086.14.13.3.13.475a.877.877 0 01-.264.644l-4.33 4.436a1.2 1.2 0 01-.364.258 1.07 1.07 0 01-.404.088z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon39Icon;
/* prettier-ignore-end */
