const TAB_SCREEN = 1200; // px;
const MOBILE_SCREEN = 680; // px

export const isTabletScreen = (width) => {
  if (width <= TAB_SCREEN && width > MOBILE_SCREEN) return true;
  return false;
};

export const isMobileScreen = (width) => {
  if (width <= MOBILE_SCREEN) return true;
  return false;
};

export const isLaptopScreen = (width) => {
  if (width > TAB_SCREEN) return true;
  return false;
};

export const isResponsive = (width) => {
  if (width < TAB_SCREEN) return true;
  return false;
};
