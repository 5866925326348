import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import {
    CHANGE_SUBSCRIPTION_PLAN,
    CONFIRM_COMMITMENT_PAYMENT,
    CONFIRM_SUBSCRIPTION_PAYMENT,
    CREATE_DRAFT_SUBSCRIPTION,
    GET_CURRENT_PLAN_DATA,
    GET_INVOICES,
    GET_SUBSCRIPTION_DATA,
    GET_SUBSCRIPTION_PLANS_DATA,
    PURCHASE_DRAFT_SUBSCRIPTION,
    REQUEST_SUBSCRIPTION_PLAN,
    STORE_FEEDBACK,
    SUBSCRIPTION_REMINDER,
    TRIAL_RECHARGE,
} from '@/helpers/constants/APIEndpoints';
import type {
    CurrentPlanApiResponse,
    CurrentPlanMembership,
    PurchaseSubscriptionApiResponse,
    StoreSubscriptionDataApiRequest,
    StoreSubscriptionDataApiResponse,
    SubscriptionDataApiResponse,
    TrialRechargeApiResponse,
    WorkplacePlan,
    WorkspacePlansApiResponse,
} from './types';
import {
    ChangeSubscriptionPlanApiRequest,
    InvoicesApiResponse,
    PaymentConfirmationApiResponse,
    RequestSubscriptionPlanApiRequest,
} from './types';
import { SubscriptionPlan } from '@/components/subscription/helpers/enum';
import { DISMISS_TRIAL } from '../../../helpers/constants/APIEndpoints';

/** Get Subscription related data for the user organization which is currently logged in */
export const getData = () => {
  return getAxiosInstance().get<SubscriptionDataApiResponse>(
    GET_SUBSCRIPTION_DATA
  );
};

/** API to be used when **Remind me Later** option is selected from upgrade subscription modal */
export const setReminder = () => {
  return getAxiosInstance().post(SUBSCRIPTION_REMINDER);
};

/** API to be called when user has dismissed trial init subscription modal */
export const dismissTrialPopup = () => {
  return getAxiosInstance().post(DISMISS_TRIAL);
};

export const getPlans = () => {
  return getAxiosInstance().get<WorkspacePlansApiResponse>(
    GET_SUBSCRIPTION_PLANS_DATA
  );
};

export const getCurrentPlan = () => {
  return getAxiosInstance().get<CurrentPlanApiResponse>(GET_CURRENT_PLAN_DATA);
};

export const storeSubscriptionData = (
  data: StoreSubscriptionDataApiRequest
) => {
  return getAxiosInstance().post<StoreSubscriptionDataApiResponse>(
    CREATE_DRAFT_SUBSCRIPTION,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
};

export const purchaseSubscriptionData = (draftId: string) => {
  return getAxiosInstance().post<PurchaseSubscriptionApiResponse>(
    PURCHASE_DRAFT_SUBSCRIPTION,
    {
      purchaseDraftId: draftId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
};

export const trialRechargeData = (amount: string) => {
  return getAxiosInstance().post<TrialRechargeApiResponse>(
    TRIAL_RECHARGE,
    {
      commitmentType: 'on_trial',
      amount: amount,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
};

export const changeSubscriptionPlan = (plan: SubscriptionPlan) => {
  const data: ChangeSubscriptionPlanApiRequest = {
    planType: plan,
  };

  return getAxiosInstance().post(CHANGE_SUBSCRIPTION_PLAN, data);
};

export const checkSubscriptionPaymentStatus = (paymentId: string) => {
  return getAxiosInstance().post<PaymentConfirmationApiResponse>(
    CONFIRM_SUBSCRIPTION_PAYMENT,
    {
      paymentId: paymentId,
    }
  );
};

export const checkCommitmentPaymentStatus = (paymentId: string) => {
  return getAxiosInstance().post<PaymentConfirmationApiResponse>(
    CONFIRM_COMMITMENT_PAYMENT,
    {
      paymentId: paymentId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
};

export const getInvoices = () => {
  return getAxiosInstance().get<InvoicesApiResponse>(GET_INVOICES);
};

export const requestSubscriptionPlan = (plan: SubscriptionPlan) => {
  const data: RequestSubscriptionPlanApiRequest = {
    planType: plan,
  };

  return getAxiosInstance().post(REQUEST_SUBSCRIPTION_PLAN, data);
};

export const storeFeedbackData = (data: any) => {
  return getAxiosInstance().post(STORE_FEEDBACK, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export type {
  WorkplacePlan,
  WorkspacePlansApiResponse,
  CurrentPlanApiResponse,
  SubscriptionDataApiResponse,
  CurrentPlanMembership,
  StoreSubscriptionDataApiResponse,
  StoreSubscriptionDataApiRequest,
  InvoicesApiResponse,
};
