import { UI_ACTIONS } from './action_types';
import { AlertContent, AlertVariant } from '@common/components/Alert';

export interface ShowAlertData extends AlertContent {
  variant: AlertVariant;
}

export const toggleSecondaryPanelVisibility = (data: any) => {
  return {
    type: UI_ACTIONS.TOGGLE_SECONDARY_PANEL_VISIBILITY,
    payload: data,
  };
};

export const setSecondaryPanelWidth = (data: any) => ({
  type: UI_ACTIONS.SET_SECONDARY_PANEL_WIDTH,
  payload: data,
});

export const toggleForgotPasswordModalVisibility = (data: any) => ({
  type: UI_ACTIONS.TOGGLE_FORGOT_PASSWORD_MODAL_VISIBILITY,
  payload: data,
});

export const toggleCheckoutLayoutBulk = (data: any) => ({
  type: UI_ACTIONS.TOGGLE_CHECKOUT_LAYOUT_BULK,
  payload: data,
});

export const toggleEditBulkPropertyView = (data: any) => ({
  type: UI_ACTIONS.VIEW_PROPERTY_DETAIL_BULK,
  payload: data,
});

export const globalAssetsData = (data: any) => ({
  type: UI_ACTIONS.GLOBAL_ASSETS,
  payload: data,
});

export const togglePaymentFailedToaster = (data: any) => ({
  type: UI_ACTIONS.TOGGLE_PAYMENT_FAILED_TOASTER,
  payload: data,
});

export const toggleCheckPaymentStatus = (data: any) => ({
  type: UI_ACTIONS.TOGGLE_CHECK_PAYMENT_STATUS,
  payload: data,
});

export const toggleOrdersListPanelVisibility = (data: any) => ({
  type: UI_ACTIONS.TOGGLE_ORDERS_LIST_PANEL_VISIBILITY,
  payload: data,
});

export const toggleLayerLeftPanel = (data: any) => ({
  type: UI_ACTIONS.TOGGLE_LAYER_LEFT_PANEL,
  payload: data,
});

export const visibleLayerPanel = (data: any) => ({
  type: UI_ACTIONS.VISIBLE_LAYER_PANEL,
  payload: data,
});

export const toggleActionCenterCard = (data: any) => ({
  type: UI_ACTIONS.TOGGLE_ACTION_CENTER_CARD,
  payload: data,
});

export const showAlert = (data: ShowAlertData) => ({
  type: UI_ACTIONS.SHOW_ALERT,
  payload: data,
});

export const closeAlert = () => ({
  type: UI_ACTIONS.CLOSE_ALERT,
});

export const openSharePropertyModal = (data: any) => ({
  type: UI_ACTIONS.OPEN_SHARE_PROPERTY_MODAL,
  payload: data,
});

export const closeSharePropertyModal = (data: any) => ({
  type: UI_ACTIONS.CLOSE_SHARE_PROPERTY_MODAL,
  payload: data,
});

export const hideNavbar = () => ({
  type: UI_ACTIONS.HIDE_NAVBAR_IF_UNAUTHORISED,
});

export const showHideVertices = (data: any) => ({
  type: UI_ACTIONS.SHOW_VERTICES,
  payload: data,
});

export const showNotificationBar = (data: any) => ({
  type: UI_ACTIONS.SHOW_NOTIFICATION_BAR,
  payload: data,
});

export const hideNotificationBar = () => ({
  type: UI_ACTIONS.HIDE_NOTIFICATION_BAR,
});

export const toggleNearmapDebug = (isDeugEnabled) => ({
  type: UI_ACTIONS.TOGGLE_NEARMAP_DEBUG,
  payload: isDeugEnabled,
});

export const showHideBasemap = (isBasemapVisible) => ({
  type: UI_ACTIONS.SHOW_HIDE_BASEMAP,
  payload: isBasemapVisible,
});

export const showHideParcel = (isParcelVisible) => ({
  type: UI_ACTIONS.SHOW_HIDE_PARCEL,
  payload: isParcelVisible,
});

export const showHideNearmap = (isNearmapVisible) => ({
  type: UI_ACTIONS.SHOW_HIDE_NEARMAP,
  payload: isNearmapVisible,
});

export const switchBasemap = (selectedBasemap) => ({
  type: UI_ACTIONS.SWTCH_BASEMAP_TYPE,
  payload: selectedBasemap,
});

export const setPropertyHasNearmap = (doesPropertyHaveNearmap) => ({
  type: UI_ACTIONS.SET_PROPERTY_HAS_NEARMAP,
  payload: doesPropertyHaveNearmap,
});

export const setCustomerInfoDialogVisibility = (visible) => ({
  type: UI_ACTIONS.SET_CUSTOMER_INFO_DIALOG_VISIBILITY,
  payload: visible,
});

export const toggleFullScreenLoader = (isVisible, message) => ({
  type: UI_ACTIONS.SHOW_FULL_SCREEN_LOADER,
  payload: {
    isVisible,
    message,
  },
});