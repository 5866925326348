/** REMOVE - to come from storybook */

import { FC, ReactNode, SVGProps } from 'react';
import { Nullable } from '../../common/types';

export type ISOTimestamp = string;

export enum UserRole {
  ADMIN = 'admin',
  CREATOR = 'creator',
  VIEWER = 'viewer',
  GUEST = 'guest',
}

export enum HeaderWidgetTheme {
  WARNING = 'warning',
  INFO = 'info',
  BUTTON = 'button',
}

export enum PlanType {
  TRIAL = 'trial',
  ESSENTIAL = 'essential',
  PROFESSIONAL = 'professional',
  TEAMS = 'teams',
  ENTERPRISE = 'enterprise',
  LEGACY_PRO = 'legacy-professional',
  LEGACY_ADV = 'legacy-advanced',
}

export interface ProfileDropdownWidgetDataType {
  icon: ReactNode;
  plan: PlanType.PROFESSIONAL | PlanType.TEAMS | PlanType.ENTERPRISE;
}

export interface ProfileDropdownWidgetDataObj {
  professional: ProfileDropdownWidgetDataType;
  teams: ProfileDropdownWidgetDataType;
  enterprise: ProfileDropdownWidgetDataType;
}

export interface PayAsYouGoCredits {
  takeoffCredits: number;
  balanceCredits: number;
  paygCredits: number;
  currentPlanStatus: {
    creditsAdded: number;
    renewalDate: ISOTimestamp;
    dueDays: number;
    creditsFrequency: CreditsFrequecy;
  };
}
export interface PayAsYouGoProps {
  userRole: UserRole.ADMIN | UserRole.CREATOR;
  plan: PlanType;
  credits: PayAsYouGoCredits;
  tncText?: string;
  onSubmit: (credits?: number) => void;
  onCheckout: (credits?: number) => void;
}

export interface HeaderWidgetProps {
  elements: {
    icon?: ReactNode;
    label?: string | ReactNode;
    subLabel?: string;
    onClick?: () => void;
  };
  action?: {
    label: string;
    onClick: () => void;
  };
  type: HeaderWidgetTheme;
}

export enum HeaderWidgetType {
  LOW_CREDITS = 'low-credits',
  PAYMENT_FAILED = 'payment-failed',
  TRIAL_OVER = 'trail-over',
  TRIAL_COUNTDOWN = 'trial-countdown',
  BUY_PLAN = 'buy-plan',
}
export interface PaymentFailedProps {
  onCancel?: () => void;
  onUpdatePayment?: () => void;
  numberOfDays?: number;
}
export interface UsageHistoryItemData {
  color?: string;
  label: UsageHistoryLabel;
  value: number | string;
}

export interface SubscriptionBilling {
  date: ISOTimestamp;
  amount: number;
  onShowBillingDetails?: () => void;
}

export interface SubscriptionPlan {
  icon?: ReactNode;
  title: PlanType;
  subtitle?: string;
  backgroundColor?: string;
  features?: PlanFeatureData[];
  actionLabel?: string;
  recommendedPlan?: SubscriptionPlan;
}

export type TSubscriptionPlans = {
  trial: SubscriptionPlan;
  essential: SubscriptionPlan;
  professional: SubscriptionPlan;
  teams: SubscriptionPlan;
  enterprise: SubscriptionPlan;
  legacyProfessional: SubscriptionPlan;
  legacyAdvanced: SubscriptionPlan;
};

export interface WorkspaceLevelData {
  balanceCredits: number;
  totalUsers: number;
  history?: UsageHistoryItemData[];
}

export enum PaymentStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export interface Payment {
  status?: PaymentStatus;
  amount?: Nullable<number>;
  nextBilling?: SubscriptionBilling;
  onManageAddOn?: () => void;
}

export interface SubscriptionWidgetProps {
  userRole: UserRole;
  workspaceData: WorkspaceLevelData;
  currentPlanData: PlanData;
  previousPlanData?: PreviousPlanData;
  payment?: Payment;
}

export interface PlanFeatureData {
  icon?: string;
  label: string;
}

export type CreditsFrequecy = 'month' | 'year';

export interface RechargeableCredits {
  credits?: number;
  creditsFrequency?: CreditsFrequecy;
}

export interface PlanData extends RechargeableCredits {
  plan: SubscriptionPlan;
  expirationDate?: ISOTimestamp;
  onAction?: () => void;
  onRecommendedPlanLearnMore?: () => void;
}

export interface PreviousPlanData {
  plan: SubscriptionPlan;
  expirationDate?: ISOTimestamp;
}

export enum UsageHistoryLabel {
  PROPERTIES_CREATED = 'Properties Created',
  ACRES_MAPPED = 'Acres Mapped',
  MAN_HOURS_SAVED = 'Man Hrs. Saved',
  SITE_REPORTS = 'Site Reports',
  SITE_WALK = 'Site-walk on Property',
}

export enum Source {
  'ios',
  'android',
  'chrome_extension',
  'web',
  'flutter',
}
