import React, { useEffect, useMemo } from 'react';
import { HeaderWidget } from '@siterecon/sr-component/dist/sr-component-library';
import { Box } from '@siterecon/sr-styles/dist/elements';
import { useSubscription } from '../../../../modules/subscription/hooks';
import { trackEvents } from '../../../../helpers/utilities';
import useSubscriptionHeaderWidget from './useSubscriptionHeaderWidget';
import { HeaderWidgetType } from '../../../../modules/subscription/storybook/types';
import {
  UPDATE_ADDONS,
  EXPLORE_SUBSCRIPTION_PLANS,
  TRAIL_OVER_UPGRADE_NOW,
} from '../../helpers/urls';
import { differenceInDaysRounded, openUrl } from '../../../utils';
import { SubscriptionState } from '../../helpers/enum';
import useRole from '../../../../modules/auth/guards/RoleGuard/useRole';
import {
  getHeaderWidgetClickSegmentEvent,
  getHeaderWidgetActionClickSegmentEvent,
} from '../../helpers';
import { HeaderWidgetEvt } from '../../../../segment';

const SubscriptionHeaderWidget = () => {
  const {
    subscription,
    subscriptionState,
    showSubscriptionWidget,
    showRepairSubscription,
    hideSubscriptionWidget,
  } = useSubscription();
  const { type } = useSubscriptionHeaderWidget();
  const { role, isViewer } = useRole();

  useEffect(() => {
    if (type) {
      trackEvents(HeaderWidgetEvt.Visible);
    }
  }, [type]);

  const trialCountdownDays = useMemo(() => {
    if (
      !subscription ||
      !subscription.endDate ||
      subscriptionState !== SubscriptionState.TRIAL_ONGOING
    ) {
      return 0;
    }

    return differenceInDaysRounded(new Date(subscription.endDate), new Date());
  }, [subscriptionState, subscription]);

  if (!subscription || !type) return null;

  const hideBuyPlan =
      subscriptionState && [
      SubscriptionState.PLAN_CANCELLED,
      SubscriptionState.MIGRATED_WORKSPACE,
    ].includes(subscriptionState) && isViewer;

  const handleUpdatePaymentMethod = () => {
    if (!subscription?.lastPaymentStatus) return;

    const { invoiceId, paymentLink } = subscription.lastPaymentStatus;

    if (invoiceId && paymentLink) {
      showRepairSubscription({
        id: invoiceId,
        url: paymentLink,
      });
    }
  };

  const headerWidgetClickHandler = () => {
    const event = getHeaderWidgetClickSegmentEvent(type);
    trackEvents(event);

    if (type === HeaderWidgetType.BUY_PLAN) {
      openUrl(EXPLORE_SUBSCRIPTION_PLANS);
    } else {
      showSubscriptionWidget();
    }
  };

  const getActionTargetUrl = () => {
    switch (type) {
      case HeaderWidgetType.LOW_CREDITS:
        return UPDATE_ADDONS;
      case HeaderWidgetType.TRIAL_OVER:
        return TRAIL_OVER_UPGRADE_NOW;
    }
  };

  const headerWidgetActionHandler = () => {
    const event = getHeaderWidgetActionClickSegmentEvent(type);

    if (type === HeaderWidgetType.LOW_CREDITS) {
      trackEvents(event, {
        workspaceBalance: subscription.workspaceAvailableCredits,
      });
    } else {
      trackEvents(event);
    }

    if (type === HeaderWidgetType.PAYMENT_FAILED) {
      handleUpdatePaymentMethod();
      return;
    }

    const redirectionLink: string | undefined = getActionTargetUrl();

    if (redirectionLink) {
      openUrl(redirectionLink);
    }
  };

  const subscriptionWidgetCloseHandler = () => {
    hideSubscriptionWidget();
  };

  return (
    <Box className='mr-4'>
      {!hideBuyPlan && (
        <HeaderWidget
          type={type}
          onClick={headerWidgetClickHandler}
          onAction={headerWidgetActionHandler}
          trialCountdownDays={trialCountdownDays}
          userRole={role}
        />
      )}
    </Box>
  );
};

export default SubscriptionHeaderWidget;
