import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { DRAWING_MODE_STYLE, RESTING_MODE_STYLE } from '../mapGlobals';
import Draw from 'ol/interaction/Draw';
import * as turf from '@turf/turf';
import { _get } from '../utilities/lodashUtils';
import {
  convertJSONStyleToOlStyle,
  displayWaterMarks,
  firToMapExtent,
  newVectorLayer,
  newVectorSource,
} from './layerUtils';
import { transform } from 'ol/proj';

let vectorSource = new VectorSource({
  crossOrigin: 'Anonymous',
});

let parcelLayer = new VectorLayer({
  source: vectorSource,
  style: RESTING_MODE_STYLE,
});
parcelLayer.on('error', (error) => console.error(error));
let parcelLayerDraw = new Draw({
  source: vectorSource,
  type: 'Polygon',
});
let layerAdded = false;
export const getParcelLayer = function (features, refreshSource) {
  if (refreshSource) {
    vectorSource = new VectorSource({
      crossOrigin: 'Anonymous',
    });
    parcelLayer = new VectorLayer({
      source: vectorSource,
      style: RESTING_MODE_STYLE,
    });
    parcelLayer.on('error', (error) => console.error(error));
  }
  if (features && features.length) {
    vectorSource.clear();
    vectorSource.addFeatures(features);
    // new GeoJSON().readFeatures(features, {
    //   featureProjection: 'EPSG:3857',
    // }))
  }
  return parcelLayer;
};

export const resetParcelLayerStyle = function () {
  parcelLayer.setStyle(RESTING_MODE_STYLE);
};
export const toggleParcelLayerOnMap = function (
  mapRef,
  toggle,
  optionalFeatures
) {
  if (toggle && !layerAdded) {
    mapRef.addLayer(parcelLayer);
    layerAdded = true;
  } else {
    layerAdded = false;
    mapRef.removeLayer(parcelLayer);
  }
  return parcelLayer;
};

export const parcelLayerDrawToggle = function (
  mapRef,
  toggleDraw,
  localParcelLayer = null
) {
  if (localParcelLayer && localParcelLayer.ol_uid !== parcelLayer?.ol_uid) {
    mapRef.removeLayer(parcelLayer);
    parcelLayer = localParcelLayer;
  }

  const workingLayer = localParcelLayer ?? parcelLayer;

  if (toggleDraw) {
    mapRef.removeInteraction(parcelLayerDraw);
    // localParcelLayer?.setStyle(DRAWING_MODE_STYLE);
    workingLayer.setStyle(DRAWING_MODE_STYLE);
    parcelLayerDraw = new Draw({
      source: workingLayer.getSource(),
      type: 'Polygon',
    });
    mapRef.addInteraction(parcelLayerDraw);
  } else {
    // localParcelLayer.setStyle(RESTING_MODE_STYLE);
    workingLayer.setStyle(RESTING_MODE_STYLE);
    mapRef.removeInteraction(parcelLayerDraw);
  }
  return parcelLayerDraw;
};

export const randomPointInPoly = (polygon) => {
  let bBox;
  let tempPolygon;
  if (polygon.type === 'MultiPolygon') {
    let polygons = turf.flatten(polygon);
    let min = 0;
    let max = polygons.features.length - 1;
    let randomIndex = parseInt(
      Math.floor(Math.random() * (max - min + 1)) + min
    );
    tempPolygon = polygons.features[randomIndex].geometry;
    bBox = turf.bbox(tempPolygon);
  } else {
    tempPolygon = polygon;
    bBox = turf.bbox(tempPolygon);
  }
  let x_min = bBox[0];
  let x_max = bBox[2];
  let y_min = bBox[1];
  let y_max = bBox[3];
  let lat = y_min + Math.random() * (y_max - y_min);
  let lng = x_min + Math.random() * (x_max - x_min);
  let point = turf.point([lng, lat]);
  let insidePolygon = turf.booleanContains(tempPolygon, point);

  if (insidePolygon) {
    return point;
  } else {
    return randomPointInPoly(tempPolygon);
  }
};

/** Helper function used to render parcel in static and estimation view */
export const renderParcelLayer = (
  mapRef,
  parcelJson,
  parcelStyle,
  orderSummary,
  configurations,
  search = '',
  showWatermark = true
) => {
  let source = newVectorSource(parcelJson ? [parcelJson] : []);

  let layerRef = newVectorLayer(
    source,
    convertJSONStyleToOlStyle('polygon', parcelStyle)
  );

  layerRef.setProperties({
    id: 'PARCEL',
  });

  mapRef.addLayer(layerRef);

  if (showWatermark) {
    let waterMarkPoint = randomPointInPoly(parcelJson);
    let waterMarkPointTransformed = transform(
      waterMarkPoint.geometry.coordinates,
      'EPSG:4326',
      'EPSG:3857'
    );
    displayWaterMarks(
      waterMarkPointTransformed,
      orderSummary,
      configurations,
      mapRef
    );
  }

  let searchParams = new URLSearchParams(search);
  let isFB = searchParams.has('fb');
  let isNotes = searchParams.has('n');

  if (!isFB && !isNotes) {
    firToMapExtent(mapRef, source);
  }

  return layerRef;
};
