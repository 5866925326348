import { ComponentType, FC, forwardRef } from 'react';
import { IObject } from '@/modules/common/types';
import { RedirectFunction } from '../hooks/use-router-plus';
import useRouterPlusRedirect from '../hooks/use-router-plus-redirect';
import useRouterPlusParams from '@/modules/router-plus/hooks/use-router-plus-params';
import useRouterPlusSearchParams from '@/modules/router-plus/hooks/use-router-plus-search-params';

interface WithRouterPlusProps {
  redirect: RedirectFunction;
}

const WithRouterPlus = <P extends IObject = any>(
  Component: ComponentType<P>
): FC<P & WithRouterPlusProps> => {
  const WrappedComponent: FC<P & WithRouterPlusProps> = forwardRef(
    (props, ref) => {
      const { redirect } = useRouterPlusRedirect();
      const params = useRouterPlusParams();
      const searchParams = useRouterPlusSearchParams();

      return (
        <Component
          {...props}
          ref={ref}
          redirect={redirect}
          params={params}
          searchParams={searchParams}
        />
      );
    }
  );

  return WrappedComponent;
};

export default WithRouterPlus;
