import * as turf from '@turf/turf';
import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { LineString } from 'ol/geom';
import Polygon from 'ol/geom/Polygon';

export const generatePropertiesFromFeature = function (
  featurejson,
  prevProps = {}
) {
  try {
    switch (featurejson?.geometry?.type) {
      case 'Polygon':
        return {
          ...prevProps,
          'Surface Area': calculateAreaFromJson(featurejson),
          perimeter: calculatePerimeterFromJson(featurejson),
          unit: 'sq ft',
        };
      case 'LineString':
        return {
          ...prevProps,
          Length: calculateLengthFromJson(featurejson),
          unit: 'ft',
          perimeter: null,
        };
      case 'Point':
        return {
          ...prevProps,
          unit: 'sq ft',
          'Ring Area': 12.56,
          perimeter: null,
        };
      default:
        return { ...prevProps };
    }
  } catch (e) {}
};
export const calculateAreaFromJson = function (featurejson) {
  let feature = new Feature({
    geometry: new Polygon(featurejson.geometry.coordinates),
  });
  let coordArr = featureToCoordArr(feature);
  let polygon = turf.polygon(coordArr);
  return (turf.area(polygon) * 10.7639).toFixed(2);
};
const calculateLengthFromJson = function (featurejson) {
  let feature = new Feature({
    geometry: new LineString(featurejson.geometry.coordinates),
  });
  let coordArr = featureToCoordArr(feature);
  let polygon = turf.lineString(coordArr);
  return (turf.length(polygon) * 3280.84).toFixed(2);
};

const calculatePerimeterFromJson = function (featurejson) {
  let feature = new Feature({
    geometry: new Polygon(featurejson.geometry.coordinates),
  });
  let coordArr = featureToCoordArr(feature);
  let polygon = turf.polygon(coordArr);
  let boundary = turf.polygonToLine(polygon);
  return (turf.length(boundary) * 3280.84).toFixed(2);
};

const featureToCoordArr = (feature) => {
  let geojson_geom = new GeoJSON();
  let projGeometry = feature.getGeometry().clone();
  let featurePolygon = geojson_geom.writeGeometry(projGeometry);
  let featureGeojson = JSON.parse(featurePolygon);
  return featureGeojson.coordinates;
};
