import { NumericId } from '../../common/types';
import { useEstimationViewContext } from '../context/EstimationView/context';
import { LayerWithComponents } from '../api/types';
import OlMap from 'ol/Map';
import MapContext from '@/components/pages/project/MapContext';
import { useContext, useRef } from 'react';
import VectorLayer from 'ol/layer/Vector';
import {
  hoveredFeatureStyle,
  pointHoverIconFeature,
} from '@/helpers/mapGlobals/styles';
import { Feature } from 'ol';
import { StyleLike } from 'ol/style/Style';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { setEstimationAssignedItemBarComponent } from '@/store/order/actions';
import { highlightedComponents } from './useOnFeatureClick';
import { IStore } from '../../../store/types';
import { FeatureType } from '../../../components/feature-attributes/types';

export interface HoverChangeFunctionPayload {
  id: NumericId | 0;
  info: 'serviceId' | 'componentId' | '';
}

export type HoverChangeFunction = (data: HoverChangeFunctionPayload) => void;

interface HoveredFeature {
  feature: Feature;
  style: StyleLike;
}

export const useLayerItemHover = () => {
  const mapRef: OlMap = useContext(MapContext);
  const dispatch = useDispatch();

  const hoveredFeatures = useRef<HoveredFeature[]>([]);
  const layerList = useSelector<IStore, any[]>(
    (state) => state.order.layerList
  );
  const { data } = useEstimationViewContext();

  const getComponentStyleByLayerId = (layerId: NumericId) => {
    return layerList.find((layer) => layer.layerId === layerId)!.style;
  };

  const handleServiceItemHover = (id: NumericId) => {
    if (!data) return;

    const serviceItem = data.find((item) => {
      const isLayer = !!(item as LayerWithComponents).layerId;
      return isLayer ? item.layerId === id : item.id === id;
    });

    if (!serviceItem) return;

    handleHoverOut();

    const componentIds = serviceItem.components.map(
      (component) => component.componentId
    );

    const hovered: HoveredFeature[] = [];

    /** We need to update the hover style for all the features under this component */
    mapRef.getLayers().forEach((layer) => {
      if (!layer.getClassName().includes('EstimationViewLayer')) {
        return;
      }

      const source = (layer as VectorLayer).getSource();

      source.getFeatures().forEach((feature) => {
        const featureComponentId =
          feature.getId() ?? feature.getProperties().componentId;

        if (!componentIds.includes(Number(featureComponentId))) {
          return;
        }

        hovered.push({ feature, style: feature.getStyle()! });

        feature.setStyle(getHoveredStyle(feature));
      });
    });

    hoveredFeatures.current = hovered;
  };

  const handleComponentHover = (id: NumericId) => {
    if (!data) return;

    handleHoverOut();

    dispatch(setEstimationAssignedItemBarComponent(id));

    mapRef.getLayers().forEach((layer) => {
      if (!layer.getClassName().includes('EstimationViewLayer')) {
        return;
      }

      const source = (layer as VectorLayer).getSource();

      source.getFeatures().forEach((feature) => {
        const featureComponentId =
          feature.getId() ?? feature.getProperties().componentId;

        if (Number(featureComponentId) === id) {
          hoveredFeatures.current = [{ feature, style: feature.getStyle()! }];
          feature.setStyle(getHoveredStyle(feature));
        }
      });
    });
  };

  const handleHoverOut = () => {
    dispatch(setEstimationAssignedItemBarComponent(null));

    const highlightedComponentIds = highlightedComponents.map(
      (component) => component.componentId
    );

    for (const { feature, style } of hoveredFeatures.current) {
      /** When removing style for hover out, we need to check if the component is highlighted with click action. If yes, we don't want to apply hover out style */

      const componentId =
        feature.getProperties().componentId ?? feature.getId();

      if (!highlightedComponentIds.includes(componentId)) {
        feature.setStyle(undefined);
      }
    }
  };

  const handleHover: HoverChangeFunction = ({ id, info }) => {
    if (!id) {
      handleHoverOut();
      return;
    }

    if (info === 'serviceId') {
      handleServiceItemHover(id);
      return;
    }

    handleComponentHover(id);
  };

  return { handleHover };
};

const getHoveredStyle = (feature: Feature) => {
  const featureType: FeatureType =
    feature.getProperties().featureType?.toLowerCase() ?? null;

  if (featureType === FeatureType.POINT) {
    return pointHoverIconFeature(
      feature.getProperties().style ?? feature.getStyle()
    );
  }

  return hoveredFeatureStyle;
};
