export const ORGANISATION_DATA = {
  ORGANISATION_SUGGESTION_REQUEST: 'ORGANISATION_SUGGESTION_REQUEST',
  ORGANISATION_SUGGESTION_SUCCESS: 'ORGANISATION_SUGGESTION_SUCCESS',
  ORGANISATION_SUGGESTION_FAILURE: 'ORGANISATION_SUGGESTION_FAILURE',
  GET_ORGANISATION_VIA_CODE_REQUEST: 'GET_ORGANISATION_VIA_CODE_REQUEST',
  GET_ORGANISATION_VIA_CODE_SUCCESS: 'GET_ORGANISATION_VIA_CODE_SUCCESS',
  GET_ORGANISATION_VIA_CODE_FAILURE: 'GET_ORGANISATION_VIA_CODE_FAILURE',
  ORGANISATION_TITLE_REQUEST: 'ORGANISATION_TITLE_REQUEST',
  ORGANISATION_TITLE_SUCCESS: 'ORGANISATION_TITLE_SUCCESS',
  ORGANISATION_TITLE_FAILURE: 'ORGANISATION_TITLE_FAILURE',
  UPDATE_ORGANISATION_TITLE_REQUEST: 'UPDATE_ORGANISATION_TITLE_REQUEST',
  UPDATE_ORGANISATION_TITLE_SUCCESS: 'UPDATE_ORGANISATION_TITLE_SUCCESS',
  UPDATE_ORGANISATION_TITLE_FAILURE: 'UPDATE_ORGANISATION_TITLE_FAILURE',
  NAME_OF_ORGANISATION: 'NAME_OF_ORGANISATION',
};

export const ORGANISATION_ACTIONS = {
  JOIN_ORGANISATION_REQUEST: 'JOIN_ORGANISATION_REQUEST',
  JOIN_ORGANISATION_SUCCESS: 'JOIN_ORGANISATION_SUCCESS',
  JOIN_ORGANISATION_FAILURE: 'JOIN_ORGANISATION_FAILURE',
  UPDATE_ORGANISATION_REQUEST: 'UPDATE_ORGANISATION_REQUEST',
  UPDATE_ORGANISATION_SUCCESS: 'UPDATE_ORGANISATION_SUCCESS',
  UPDATE_ORGANISATION_FAILURE: 'UPDATE_ORGANISATION_FAILURE',
  CREATE_ORGANISATION_REQUEST: 'CREATE_ORGANISATION_REQUEST',
  CREATE_ORGANISATION_SUCCESS: 'CREATE_ORGANISATION_SUCCESS',
  CREATE_ORGANISATION_FAILURE: 'CREATE_ORGANISATION_FAILURE',
};
