// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon53IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon53Icon(props: Icon53IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M3.622 4.1C2.79 4.1 2.1 4.825 2.1 5.7v12.8c0 .875.69 1.6 1.522 1.6h16.956c.832 0 1.522-.726 1.522-1.6V5.7c0-.875-.69-1.6-1.522-1.6H3.622zm0 1.371h16.956c.132 0 .218.09.218.23v9.085l-3.288-2.764a.644.644 0 00-.775-.036l-3.084 2.228-4.185-3.564a.638.638 0 00-.482-.15.636.636 0 00-.299.122L3.404 14.45V5.7c0-.139.086-.229.218-.229zm10 1.83c-1.073 0-1.957.928-1.957 2.056 0 1.128.884 2.057 1.957 2.057s1.956-.929 1.956-2.057S14.695 7.3 13.622 7.3zm0 1.37c.368 0 .652.3.652.686 0 .387-.284.686-.652.686-.368 0-.652-.299-.652-.686 0-.386.284-.685.652-.685zm-4.593 3.372l4.185 3.557a.644.644 0 00.775.036l3.077-2.229 3.73 3.136V18.5c0 .139-.086.229-.218.229H3.622c-.132 0-.218-.09-.218-.229v-2.378l5.625-4.079z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M16.733 11.986a.644.644 0 01.775.035l3.288 2.765V5.7c0-.138-.086-.228-.218-.228H3.622c-.132 0-.218.09-.218.228v8.75l5.279-3.828a.636.636 0 01.299-.122.638.638 0 01.482.15l4.185 3.564 3.084-2.228zm-3.079 2.101l3.02-2.182a.745.745 0 01.898.04l3.124 2.626v-8.87c0-.05-.015-.08-.032-.098-.017-.018-.043-.031-.086-.031H3.622c-.043 0-.07.013-.086.03-.017.018-.032.05-.032.098v8.554l5.12-3.712a.739.739 0 01.345-.141h.002a.735.735 0 01.558.173m0 0l4.125 3.513-4.125-3.513zm4.46 5.062a.644.644 0 01-.775-.036L9.03 12.043l-5.625 4.079V18.5c0 .139.086.229.217.229h16.957c.132 0 .218-.09.218-.229v-1.957l-3.73-3.136-3.077 2.229zm3.072-2.102l-3.015 2.184a.744.744 0 01-.897-.041L9.024 12.17l-5.52 4.003V18.5c0 .049.015.08.032.098.016.017.043.03.085.03h16.957c.042 0 .07-.013.086-.03.017-.018.031-.05.031-.098v-1.91l-3.634-3.056zM2 5.7C2 4.775 2.73 4 3.622 4h16.956c.892 0 1.622.775 1.622 1.7v12.8c0 .925-.73 1.7-1.622 1.7H3.622C2.73 20.2 2 19.425 2 18.5V5.7zm9.565 3.657c0-1.178.924-2.157 2.057-2.157 1.132 0 2.056.979 2.056 2.157 0 1.179-.924 2.157-2.056 2.157-1.133 0-2.057-.978-2.057-2.157zm2.609 0c0-.336-.244-.585-.552-.585-.308 0-.553.25-.553.585 0 .337.245.586.553.586.308 0 .552-.25.552-.586zM2.1 5.7c0-.875.69-1.6 1.522-1.6h16.956c.832 0 1.522.725 1.522 1.6v12.8c0 .875-.69 1.6-1.522 1.6H3.622c-.832 0-1.522-.725-1.522-1.6V5.7zm9.565 3.657c0-1.128.884-2.057 1.957-2.057 1.072 0 1.956.93 1.956 2.057 0 1.128-.883 2.057-1.956 2.057s-1.957-.929-1.957-2.057zm2.609 0c0-.386-.284-.685-.652-.685-.368 0-.652.299-.652.685 0 .387.284.686.652.686.368 0 .652-.299.652-.686z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon53Icon;
/* prettier-ignore-end */
