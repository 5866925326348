import { Tooltip } from 'antd';

interface DisabledIconToolProps {
  icon: any;
  title: string;
  tooltipText?: string;
}

const DisabledIconTool = ({
  icon,
  title,
  tooltipText = "You don't have permission",
}: DisabledIconToolProps) => {
  return (
    <div
      style={{
        marginTop: '4px',
        marginLeft: '4px',
        cursor: 'not-allowed',
        opacity: 0.5,
      }}
    >
      {typeof icon === 'string' ? (
        <>
          <Tooltip title={`${title} : ${tooltipText}`}>
            <img src={icon as string} alt={title} />
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title={`${title} : ${tooltipText}`}>{icon}</Tooltip>
        </>
      )}
    </div>
  );
};

export default DisabledIconTool;
