import { useEffect } from 'react';
import { ToolKey } from '../../types';
import { ModifySyncState } from '../../../../../../../modules/common/types';
import { Store } from '@/store';

interface UseSyncOnActiveToolChangeParams {
  activeTool: any;
  handleSaveUnSyncedModifications: () => void;
}

const useSyncOnActiveToolChange = ({
  activeTool,
  handleSaveUnSyncedModifications,
}: UseSyncOnActiveToolChangeParams) => {
  useEffect(() => {
    return () => {
      /** When active tool is changed from `modify` and there are unsaved changes on the page, we will sync them */
      const unSyncedChanges = Store.getState().map.modifyDraftEvents;
      const modifySyncState = Store.getState().map.modifySyncState;

      if (
        activeTool == ToolKey.EDIT &&
        modifySyncState !== ModifySyncState.SYNCING &&
        unSyncedChanges.length > 0
      ) {
        handleSaveUnSyncedModifications();
      }
    };
  }, [JSON.stringify(activeTool)]);
};

export default useSyncOnActiveToolChange;
