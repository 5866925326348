import { ReactComponent as TickIcon } from '@/assets/svgs/alert/tick.svg';
import { ReactComponent as CrossIcon } from '@/assets/svgs/alert/cross.svg';
import { Modal, ModalProps } from 'antd';
import { Box, Typography } from '@common/elements';
import './style.less';
import { FC, SVGProps } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '@/store/types';
import { AlertData } from '@/store/ui/state';
import useAlert from '@common/components/Alert/useAlert';
import classNames from 'classnames';

/** TODO: Add support for info, warning and other variants when needed */
export type AlertVariant = 'error' | 'success';

export interface AlertContent {
  title: string;
  message?: string;
}

interface AlertProps extends Pick<ModalProps, 'visible' | 'onCancel'> {
  content: AlertContent;

  /** (Optional) Variant of the alert we want to show. defaults to "success"  */
  variant: AlertVariant;
}

const getIcon = (variant: AlertVariant): FC<SVGProps<any>> => {
  switch (variant) {
    case 'error':
      return CrossIcon;
    default:
      return TickIcon;
  }
};

const Alert = () => {
  const { close } = useAlert();
  const { visible, content, variant } = useSelector<IStore, AlertData>(
    (state) => state.ui.alert
  );
  const { title, message } = content || {};
  const Icon = getIcon(variant);

  const onClose = () => close();

  return (
    <Modal
      centered
      visible={visible}
      footer={false}
      className={classNames('alert-modal', `alert-modal--${variant}`)}
      width='384px'
      closable={false}
      onCancel={onClose}
    >
      <Box className='px-6 py-4 text-center'>
        <Icon />
        <Typography variant='h4' className='mt-3' lineHeight={1.75}>
          {title}
        </Typography>
        {message && (
          <Typography fontWeight={400} lineHeight={1.75} className='mt-2'>
            {message}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export { useAlert };

export default Alert;
