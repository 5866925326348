import { LAYER_PANEL_ATTRIBUTE_COL_WIDTH } from '@/helpers/constants';
import {
  AccessRight,
  Nullable,
  NumericId,
  ViewType,
} from '../../../../modules/common/types';
import OlMap from 'ol/Map';
import LayerGroup from 'ol/layer/Group';
import {
  ServiceItem,
  SingleComponent,
} from '../../../../modules/property/api/types';
import { isEmpty } from 'lodash';
import { ParcelAttachmentType, ParcelAttachments, ViewCategory } from './types';
import { differenceInMinutes } from 'date-fns';

export const getServiceItemNamesListByAssignedItems = (
  serviceItemsList: ServiceItem[],
  assignedList: Number[]
): String[] => {
  const serviceItemsNameList: String[] = [];
  serviceItemsList.map((serviceItem) => {
    if (assignedList.includes(serviceItem.id)) {
      serviceItemsNameList.push(serviceItem.name);
    }
  });

  return serviceItemsNameList;
};

export const getPanelWidth = (
  attributes: any[] | number,
  numeric: boolean = false
): string | number => {
  const visibleAttributes =
    typeof attributes === 'number' ? attributes : attributes.length;

  const DEFAULT_WIDTH = 320;
  const PANEL_SETTING_COLLAPSED_WIDTH = 39;

  const width =
    visibleAttributes === 0
      ? DEFAULT_WIDTH + PANEL_SETTING_COLLAPSED_WIDTH
      : DEFAULT_WIDTH + visibleAttributes * LAYER_PANEL_ATTRIBUTE_COL_WIDTH;

  return numeric ? width : `${width}px`;
};

export const sumOfPx = (arr: string[]) => {
  let sum = 0;
  arr.map((str: string) => {
    sum = sum + Number(str.substring(0, str.indexOf('px')));
    return sum;
  });
  return `${sum}px`;
};

export const sumOfPxWithNumber = (str: string, num: number) => {
  return `${num + Number(str.substring(0, str.indexOf('px')))}px`;
};

export const vhMinusPx = (valueInVh, valueInPx) => {
  const vh = window.innerHeight * 0.01;
  const valueInVhInPx = valueInVh * vh;
  const difference = valueInVhInPx - valueInPx;
  return difference;
};

export const vwMinusPx = (valueInVw, valueInPx) => {
  const vw = window.innerWidth * 0.01;
  const valueInVwInPx = valueInVw * vw;
  const difference = valueInVwInPx - valueInPx;
  return difference;
};

// check if viewId is valid
export const isValidViewId = (viewId: string) => {
  return !isNaN(Number(viewId));
};

export const disableAllTools = (
  layersData: any[] | null,
  accessRights: AccessRight | null,
  srUserTypeId: number | null
): boolean => {
  if (layersData && layersData.length === 0) {
    return true;
  }
  if (srUserTypeId) {
    if (srUserTypeId === 1) return false;
    if (srUserTypeId === 2) return true;
  }
  if (accessRights && accessRights === AccessRight.View) {
    return true;
  }
  return false;
};

export const disableAllToolsTooltip = (
  layersData: any[] | null,
  accessRights: AccessRight | null,
  srUserTypeId: number | null
): string => {
  if (layersData && layersData.length === 0) {
    return 'No layers are added';
  }
  if (srUserTypeId) {
    if (srUserTypeId === 1) return '';
    if (srUserTypeId === 2) return 'You do not have permission to edit';
  }
  if (accessRights && accessRights === AccessRight.View) {
    return 'You do not have permission to edit';
  }
  return '';
};

export const setNearmapLayerToFullMap = (mapRef: OlMap) => {
  mapRef.getLayers().forEach(function (layer) {
    if (layer && layer.getProperties().name === 'nearmap') {
      if ((layer as unknown as LayerGroup).getLayers()) {
        (layer as unknown as LayerGroup).getLayers().forEach((nearmapLayer) => {
          nearmapLayer.setExtent(undefined);
        });
        return;
      }

      layer.setExtent(undefined);
      layer.setExtent(mapRef.getView().calculateExtent(mapRef.getSize()));
    }
  });
};

const toPlasmicParcelAttachmentType = (type: ParcelAttachmentType) => {
  switch (type) {
    case ParcelAttachmentType.IMAGE_JPG:
      return 'JPEG';
    case ParcelAttachmentType.IMAGE_PNG:
      return 'png';
    case ParcelAttachmentType.PDF:
      return 'pdf';
  }
};

export const getPlasmicParcelAttachments = (
  attachments: ParcelAttachments[]
) => {
  if (!attachments) return [];

  return attachments.map((attachment: ParcelAttachments) => {
    return {
      name: attachment.name,
      url: attachment.url,
      type: toPlasmicParcelAttachmentType(attachment.type),
    };
  });
};

export const getIsoTimeDifference = (
  date1: Nullable<string>,
  date2: Nullable<string> = null,
  absolute: boolean = false
) => {
  if (!date1) return 0;

  const dataDeliveryTime = new Date(date1);
  const currentTime = date2 ? new Date(date2) : new Date();

  const difference: number = differenceInMinutes(dataDeliveryTime, currentTime);

  return absolute ? Math.abs(difference) : difference;
};

// Function to extract the next view name based on selectedView and orderViews
export const extractNextViewName = (orderViews, selectedView) => {
  // Determine the view type and corresponding regex pattern
  console.log('selected view', selectedView);
  const isEstimationView = selectedView === ViewCategory.ESTIMATION;
  const pattern = isEstimationView ? /Estimation View\s*(\d+)/i : /Draft\s*(\d+)/i;

  // Filter the relevant views based on the selected view type
  const relevantViews = isEstimationView
    ? orderViews.filter((view) => [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(view.viewType))
    : orderViews.filter((view) => view.viewType === ViewType.STATIC);

  // Extract view numbers using the regex pattern
  const viewNumbers = relevantViews
    .map((view) => {
      const match = view.name.match(pattern);
      return match ? parseInt(match[1], 10) : null;
    })
    .filter((number): number is number => number !== null);

  // Find the highest number and increment it by one
  const highestViewNumber = viewNumbers.length > 0 ? Math.max(...viewNumbers) : 0;
  const nextViewNumber = highestViewNumber + 1;

  // Return the new view name based on the view type
  return isEstimationView ? `Estimation View ${nextViewNumber}` : `Draft ${nextViewNumber}`;
};

export const getEstimationTimeRemainingInMinutes = (dataDeliveredAt, pricingDeliveryTimeInHours) => {
  // Check for null or undefined values for both parameters
  if (!dataDeliveredAt || !pricingDeliveryTimeInHours || isNaN(pricingDeliveryTimeInHours)) {
    return 0; // Return 0 if the input is invalid
  }

  const deliveredAtDate = new Date(dataDeliveredAt);

  // Check if the deliveredAtDate is a valid date
  if (isNaN(deliveredAtDate.getTime())) {
    return 0; // Return 0 if the date is invalid
  }

  const currentTime = new Date();
  const targetTime = new Date(deliveredAtDate.getTime() + pricingDeliveryTimeInHours * 60 * 60 * 1000);

  const timeDifference = targetTime.getTime() - currentTime.getTime();
  const remainingMinutes = timeDifference / (1000 * 60);

  // Return remaining minutes if positive, otherwise return 0
  return remainingMinutes > 0 ? remainingMinutes : 0;
};
