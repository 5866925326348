import React from "react";

const Pinned = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
<svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.45233 12.225L8.42018 13.1929L5.96147 15.5076L5.96138 15.5077C5.81261 15.6462 5.60233 15.6972 5.40661 15.6423C5.2109 15.5873 5.0579 15.4343 5.00296 15.2386C4.94801 15.0429 4.99901 14.8326 5.13754 14.6838L7.45233 12.225ZM12.229 11.9745L15.1749 8.01496L15.4936 8.33369L15.4935 8.3336C15.7778 8.6173 16.1919 8.72793 16.5797 8.62377C16.9676 8.51981 17.2706 8.21678 17.3746 7.82883C17.4788 7.44097 17.3681 7.02697 17.0845 6.74266L13.9025 3.56069C13.6182 3.27707 13.2041 3.16635 12.8163 3.27051C12.4284 3.37456 12.1253 3.67759 12.0214 4.06545C11.9172 4.45331 12.0278 4.8674 12.3115 5.15163L12.6287 5.46874L8.67046 8.416C8.42131 8.19396 8.09674 8.07553 7.76322 8.08479C7.42958 8.09413 7.11202 8.23051 6.8757 8.4661L6.21273 9.12906C6.0663 9.2755 6.06639 9.51297 6.21282 9.65941L10.9858 14.4324L10.9857 14.4323C11.1322 14.5788 11.3696 14.5788 11.516 14.4324L12.179 13.7694C12.4147 13.533 12.5511 13.2154 12.5603 12.8819C12.5696 12.5482 12.451 12.2235 12.2289 11.9745L12.229 11.9745Z"
      fill="#666666"
    />
  </svg>
);

export default Pinned;
