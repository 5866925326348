import { AxiosResponse } from 'axios';
import {
  GetWorkspacesApiResponse,
  GetWorkspaceSummaryApiResponse,
} from './schema';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import {
  GET_WORKSPACES,
  GET_WORKSPACE_SUMMARY,
} from '@/helpers/constants/APIEndpoints';

const axios = getAxiosInstance();

export const getWorkspaces = (): Promise<
  AxiosResponse<GetWorkspacesApiResponse>
> => {
  return axios.get(GET_WORKSPACES);
};

export const getWorkspaceSummary = (): Promise<
  AxiosResponse<GetWorkspaceSummaryApiResponse>
> => {
  return axios.get(GET_WORKSPACE_SUMMARY);
};
