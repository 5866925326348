import { ORGANISATION_ACTIONS, ORGANISATION_DATA } from './action_types';
import { API_STATUS, AUTH_STATE, } from '@/components/containers/auth-page/types';

const initState = {
  organisationData: null,
  isNameUpdated: false,
};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case ORGANISATION_DATA.ORGANISATION_TITLE_REQUEST:
    case ORGANISATION_DATA.ORGANISATION_SUGGESTION_REQUEST:
      return {
        ...state,
        orgDataFetchStatus: 'LOADING',
      };
    case ORGANISATION_DATA.ORGANISATION_TITLE_SUCCESS:
    case ORGANISATION_DATA.ORGANISATION_SUGGESTION_SUCCESS:
      return {
        ...state,
        organisationData: payload,
        orgDataFetchStatus: 'SUCCESS',
      };
    case ORGANISATION_DATA.ORGANISATION_TITLE_FAILURE:
    case ORGANISATION_DATA.ORGANISATION_SUGGESTION_FAILURE:
      return {
        ...state,
        orgDataFetchStatus: 'FAILURE',
      };

    case ORGANISATION_DATA.GET_ORGANISATION_VIA_CODE_REQUEST:
      return {
        ...state,
        organisationDataViaCodeFailure: false,
      };
    case ORGANISATION_DATA.GET_ORGANISATION_VIA_CODE_SUCCESS:
      return {
        ...state,
        organisationDataViaCode: payload,
        organisationDataViaCodeFailure: true,
      };
    case ORGANISATION_DATA.GET_ORGANISATION_VIA_CODE_FAILURE:
      return {
        ...state,
        organisationDataViaCodeFailure: true,
      };
    case ORGANISATION_DATA.UPDATE_ORGANISATION_TITLE_REQUEST:
      return {
        ...state,
      };
    case ORGANISATION_DATA.UPDATE_ORGANISATION_TITLE_SUCCESS:
      return {
        ...state,
        isNameUpdated: true,
      };
    case ORGANISATION_DATA.UPDATE_ORGANISATION_TITLE_FAILURE:
      return {
        ...state,
        isNameUpdated: false,
      };
    case ORGANISATION_DATA.NAME_OF_ORGANISATION:
      return {
        ...state,
        isNameUpdated: false,
      };
    case ORGANISATION_ACTIONS.CREATE_ORGANISATION_REQUEST:
      return {
        ...state,
        orgCreateStatus: API_STATUS.LOADING,
        createWSError: null,
      };
    case ORGANISATION_ACTIONS.CREATE_ORGANISATION_FAILURE:
      return {
        ...state,
        orgCreateStatus: API_STATUS.FAILURE,
        createWSError: payload,
      };
    case ORGANISATION_ACTIONS.CREATE_ORGANISATION_SUCCESS:
      //Create organisation is done from only two places
      // 1. Add btn in workspaces section
      // 2. During signup
      return {
        ...state,
        user: {
          ...state.user,
          isAuthenticated: true,
          userActiveState: 'org-title',
        },
        orgCreateStatus: API_STATUS.SUCCESS,
      };

    case ORGANISATION_ACTIONS.JOIN_ORGANISATION_REQUEST:
      return {
        ...state,
        orgJoinStatus: API_STATUS.LOADING,
      };
    case ORGANISATION_ACTIONS.JOIN_ORGANISATION_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          userActiveState: !payload?.data?.job_title_id
            ? AUTH_STATE.ORG_TITLE
            : null,
          isAuthenticated: true,
        },
        isLoading: false,
        orgJoinStatus: API_STATUS.SUCCESS,
      };
    case ORGANISATION_ACTIONS.JOIN_ORGANISATION_FAILURE:
      return {
        ...state,
        orgJoinStatus: API_STATUS.FAILURE,
      };

    default:
      return state;
  }
};
