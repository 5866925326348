import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row, Select, Tooltip } from 'antd';
import { NotifyError } from '@/helpers/notification-utils';

import { ReactComponent as CopyComponent } from '../../../../../assets/CopyComponent.svg';
import { ReactComponent as Cross } from '../../../../../assets/action-center/close_icon_cross.svg';
import { ReactComponent as DeleteLayer } from '../../../../../assets/action-center/delete-jan-23.svg';
import { ReactComponent as Drag } from '../../../../../assets/action-center/drag-icon.svg';

import { ReactComponent as MergeItems } from '../../../../../assets/merge-items.svg';
import Text from '../../../../elements/text/Text.jsx';

import * as API_ENDPOINTS from '../../../../../helpers/constants/APIEndpoints';
import { getLayerDataWithType } from '../../../../../helpers/mapUtils/layerUtils';
import { getComponentArea } from '../../../../../helpers/mapUtils/mapCalculations';
import { trackEvents } from '../../../../../helpers/utilities';
import { replaceParams } from '../../../../../helpers/utilities/linkUtils';
import { _get } from '../../../../../helpers/utilities/lodashUtils';
import { updateOrdersTopologyWarnings } from '../../../../../store/order/actions';
import { ellipsis } from '../../../utils';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { SubscriptionChip } from '@/modules/subscription/components/Chips';
import { FeatureSubscriptionGuard } from '@/modules/subscription/guards/base';
import { SubscriptionFeature } from '@/modules/subscription/types';
import SRUserTypeAccessGuard from '@/modules/project/guards/SRUserTypeAccessGuard';
import { AccessRight, BetaFeature, ViewType } from '@/modules/common/types';
import './style.less';
import LayerGuard from '../../../../../modules/project/guards/LayerGuard';
import { showNotification } from '../../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../storybook/NotificationToast/types';
import AreaPerimeterInfo from '../../../components/area-perimeter-info';
import ServiceCatalogue from '../../../../../modules/property/components/service-item/ServiceCatalogue';
import { checkIfShareMapPage } from '../../../../utils';
import { setAttributeMultiPopupData } from '../../../../../store/attributeFeature/actions';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { Store } from '../../../../../store';

export const AttributeLayerSelectionMulti = (props) => {
  const dispatch = useDispatch();
  const axiosInstance = getAxiosInstance();

  const view = useSelector((state) => state.order.currentViewData);
  const multiData = useSelector(
    (state) => state.feature.attributeMultiPopupData
  );

  const layersData = useSelector(
    (state) => state.order.orderFeatures.layersData
  );

  const { data: featureListInfo } = useSelector(
    (state) => state.order.featureListInfo
  );

  const actionCenterComponents = Object.values(multiData)[0];

  const [layerName, layerId, areaUnit] = useMemo(() => {
    const FALLBACK = ['', null, null];

    const firstLayerKey = Object.keys(multiData || {})[0];

    if (!firstLayerKey) return FALLBACK;

    const metaData = firstLayerKey.split('&&');

    if (metaData.length < 3) return FALLBACK;

    return metaData;
  }, [Object.keys(multiData || {})]);

  const totalComponents = useMemo(() => {
    const layer =
      props.layersData?.find(
        (layer) => layer.layerId === Number(layerId)
      ) ?? null;

    return layer?.noOfComponents ?? 0;
  }, [props.layersData]);

  const isSharePage = checkIfShareMapPage();

  const filterData = (layerListInfo, keys) => {
    return layerListInfo.filter((el) => {
      return el.type === keys && el.isEditable;
    });
  };

  const layerDataWithType = filterData(
    getLayerDataWithType(layersData, featureListInfo),
    props.geometryType
  );

  const getComponentsWithFeatureId = (components) => {
    const { layersData } = props;

    if (!layerId || !layersData) return components;

    const layer = layersData.find((layer) => layer.layerId === Number(layerId));

    if (!layer) return components;

    return components.map((component) => ({
      ...component,
      featureId: layer.featureId,
    }));
  };

  const componentsWithFeatureId = useMemo(() => {
    return getComponentsWithFeatureId(Object.values(multiData || {})[0] ?? []);
  }, [Object.values(multiData) || {}]);

  const onDeleteComponent = () => {
    const viewId = view.viewId;
    let reqData = {
      delete: actionCenterComponents?.map((component) => component.componentId),
    };

    axiosInstance
      .post(
        replaceParams(API_ENDPOINTS.BULK_UPDATE_COMPONENT, {
          ':viewId': viewId,
          ':layerId': layerId,
        }),
        reqData
      )
      .then((res) => {
        trackEvents('action-center__delete', {
          type: 'multiselect',
          viewId: viewId,
          orderId: view?.orderId,
          geometryType: props.geometryType,
          layerName: layerName,
          featureOwner: props.featureType[layerName].maintainer,
          isBaseView: view?.isBaseView,
          isEditable: view?.isEditable,
          itemsSelected: reqData.delete.length,
        });
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Deleted successfully');
        props.deleteComponents(
          layerId,
          actionCenterComponents.map((component) => component.componentId)
        );
        dispatch(
          updateOrdersTopologyWarnings({
            ..._get(res.data, 'topologyWarning', {}),
            layerId: layerId,
          })
        );
        props.onClose();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const _onMergeComponents = () => {
    const viewId = view.viewId;
    if (!props.isEditableView) {
      NotifyError('Operation not allowed!');
      return;
    }

    props.onMergeComponents(
      actionCenterComponents.map((component) => component.componentId),
      layerId
    );
    trackEvents('action-center__merge', {
      viewId: viewId,
      orderId: view?.orderId,
      geometryType: props.geometryType,
      layerName: layerName,
      featureOwner: props.featureType[layerName].maintainer,
      isBaseView: view?.isBaseView,
      isEditable: view?.isEditable,
      itemsSelected: actionCenterComponents.length,
    });
  };

  const onMoveFeature = (option, extra) => {
    const viewId = view.viewId;
    let reqData = {
      componentId: actionCenterComponents.map(
        (component) => component.componentId
      ),
      targetLayerId: extra.layerId,
    };
    let layerData = {
      layerId: extra.layerId,
      name: extra.name,
    };
    axiosInstance
      .post(
        replaceParams(API_ENDPOINTS.MOVE_COMPONENT, {
          ':viewId': viewId,
          ':layerId': layerId,
        }),
        reqData
      )
      .then((result) => {
        trackEvents('action-center__move', {
          type: 'multiselect',
          viewId: viewId,
          orderId: view?.orderId,
          geometryType: props.geometryType,
          layerName: extra?.name,
          featureOwner: props.featureType[extra?.name].maintainer,
          isBaseView: view?.isBaseView,
          isEditable: view?.isEditable,
          itemsSelected: reqData.componentId.length,
        });
        props.onUpdateLayerComponents(
          viewId,
          layerId,
          extra.layerId,
          actionCenterComponents.map((component) => component.componentId),
          layerData,
          actionCenterComponents
        );
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Move successfully');
        dispatch(
          updateOrdersTopologyWarnings({
            ..._get(result.data, 'sourceLayer', {}),
            layerId: layerId,
          })
        );
        dispatch(
          updateOrdersTopologyWarnings({
            ..._get(result.data, 'targetLayer', {}),
            layerId: extra.layerId,
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getArea = () => {
    let addArea = 0;
    actionCenterComponents.length &&
      actionCenterComponents.forEach(function (component) {
        if (_get(component, 'geometry.type') === 'LineString') {
          addArea += getComponentArea('Line', component.properties);
        }
        if (_get(component, 'geometry.type') === 'Point') {
          addArea += getComponentArea('Point', component.properties);
        }
        if (_get(component, 'geometry.type') === 'Polygon') {
          addArea += getComponentArea('Polygon', component.properties);
        }
      });
    return Number(addArea);
  };

  const getPerimeter = () => {
    let totalPerimeter = 0;
    const isPolygon = actionCenterComponents
      ?.map((component) => _get(component, 'geometry.type'))
      .filter((type) => type.toLowerCase() === 'polygon')[0];
    if (!isPolygon) {
      return null;
    }
    actionCenterComponents?.forEach((component) => {
      if (component.properties?.perimeter) {
        totalPerimeter += component.properties.perimeter;
      }
    });

    return Number(totalPerimeter);
  };

  const handleApply = (components) => {
    const multiData = Store.getState().feature.attributeMultiPopupData ?? {};

    const updatedMultiPopupData = { ...multiData };

    for (const [layerId, _components] of Object.entries(multiData ?? {})) {
      const processedComponentIds = [];

      const updatedComponents = _components.map((_component) => {
        processedComponentIds.push(_component.componentId);

        return {
          ..._component,
          serviceItemIds:
            components[_component.componentId]?.serviceItemIds ??
            _component.serviceItemIds ??
            [],
        };
      });

      /** There was a bug that after updating the assignments, selected components count is getting changed it-self. To prevent that, following code (loop) is added */
      for (const component of Object.values(components)) {
        if (processedComponentIds.includes(component.componentId)) continue;
        updatedComponents.push(component);
      }

      updatedMultiPopupData[layerId] = updatedComponents;
    }

    dispatch(setAttributeMultiPopupData(updatedMultiPopupData));

    props.onServiceItemsChange?.(components);
  };

  return (
    <Card
      id={actionCenterComponents?.[0]?.id}
      className='layer-selection-popup'
    >
      <Box
        style={{ height: 16 }}
        className='flex-justify-align-center w-100 drag-handle'
      >
        <Drag />
      </Box>
      <Row justify={'space-between'} align={'top'}>
        <Col>
          <Row>
            <Tooltip title={layerName}>
              <Typography
                fontSize={12}
                lineHeight='20px'
                fontWeight={600}
                className='mr-2'
              >
                {ellipsis(layerName, 12)}
              </Typography>
            </Tooltip>
            <Typography
              fontSize={12}
              lineHeight='20px'
              style={{ color: '#666' }}
            >
              {actionCenterComponents?.length} / {totalComponents} selected
            </Typography>
          </Row>
          <Row style={{ marginTop: 6 }}>
            <AreaPerimeterInfo
              area={getArea()}
              areaUnit={areaUnit}
              perimeter={getPerimeter()}
            />
          </Row>
        </Col>
        <Col>
          <Row>
            <Tooltip title='Delete from Layer'>
              <SRUserTypeAccessGuard
                right={AccessRight.Edit}
                tooltip='Delete from Layer'
              >
                <LayerGuard view={ViewType.STATIC} disabled tooltip>
                  <Button
                    type={'link'}
                    disabled={!props.isEditableView}
                    icon={
                      <FeatureSubscriptionGuard
                        feature={SubscriptionFeature.MultiLayerDelete}
                      >
                        <DeleteLayer
                          className='pointer'
                          cursor='pointer'
                          disabled={!props.isEditableView}
                          onClick={
                            !props.isEditableView
                              ? () => NotifyError('Operation not allowed!')
                              : onDeleteComponent
                          }
                        />
                      </FeatureSubscriptionGuard>
                    }
                    style={{ width: 24, height: 24 }}
                  />
                </LayerGuard>
              </SRUserTypeAccessGuard>
            </Tooltip>
            <Tooltip title='Close'>
              <Button
                type={'link'}
                className={'ml-2'}
                icon={<Cross onClick={props.onClose} className='pointer' />}
                style={{ width: 24, height: 24 }}
              />
            </Tooltip>
          </Row>
        </Col>
      </Row>
      <div style={{ marginTop: 12 }}>
        <Row align={'middle'}>
          <Col span={16}>
            <Text type='p67' color='dark-gray'>
              Move to
            </Text>
            <SRUserTypeAccessGuard right={AccessRight.Edit} disabled>
              <FeatureSubscriptionGuard
                feature={SubscriptionFeature.MultiLayerMove}
                fallbackProps={{
                  onChange: () => { },
                }}
              >
                <LayerGuard view={ViewType.STATIC} disabled tooltip>
                  <Tooltip title={view.isBaseView ? 'Editing restricted on this view' : ''}>
                    <Select
                      listHeight={120}
                      disabled={!props.isEditableView}
                      value={layerName}
                      key={layerName}
                      className={'layer-list mx-2'}
                      style={{ width: 100 }}
                      size={'small'}
                      onChange={async (e, option) => {
                        onMoveFeature(e, option);
                      }}
                    >
                      {layerDataWithType?.map((layer, i) => (
                        <Select.Option
                          key={i}
                          value={layer.name}
                          name={layer.name}
                          layerId={layer.layerId}
                        >
                          {layer.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Tooltip>
                </LayerGuard>
              </FeatureSubscriptionGuard>
            </SRUserTypeAccessGuard>
          </Col>
          <Col span={8}>
            <Row justify='end' align='middle'>
              <Tooltip title='Duplicate Item'>
                <SRUserTypeAccessGuard
                  right={AccessRight.Edit}
                  tooltip='Duplicate Item'
                >
                  <LayerGuard view={ViewType.STATIC} disabled tooltip>
                    <Button
                      type={'link'}
                      disabled={!props.isEditableView}
                      style={{ width: 24, height: 24 }}
                      icon={
                        <FeatureSubscriptionGuard
                          feature={SubscriptionFeature.MultiLayerDuplicate}
                        >
                          <CopyComponent
                            className='pointer'
                            width={20}
                            height={20}
                            cursor='pointer'
                            disabled={!props.isEditableView}
                            style={{ verticalAlign: 'top' }}
                            onClick={
                              !props.isEditableView
                                ? () => NotifyError('Operation not allowed!')
                                : () =>
                                  props.onDuplicateComponent(
                                    actionCenterComponents.map(
                                      (component) => component.componentId
                                    ),
                                    layerId
                                  )
                            }
                          />
                        </FeatureSubscriptionGuard>
                      }
                    />
                  </LayerGuard>
                </SRUserTypeAccessGuard>
              </Tooltip>
              <Tooltip title='Merge Items'>
                <SRUserTypeAccessGuard
                  right={AccessRight.Edit}
                  tooltip='Merge Items'
                >
                  <LayerGuard view={ViewType.STATIC} disabled tooltip>
                    <Button
                      className={'ml-2'}
                      type={'link'}
                      style={{ width: 24, height: 24 }}
                      disabled={
                        !props.isEditableView ||
                        props.geometryType === 'point' ||
                        props.geometryType === 'line' ||
                        props.geometryType === 'path'
                      }
                      icon={
                        <FeatureSubscriptionGuard
                          feature={SubscriptionFeature.MultiLayerMerge}
                        >
                          <MergeItems
                            className='pointer'
                            cursor='pointer'
                            disabled={!props.isEditableView}
                            height={22}
                            style={{ verticalAlign: 'top' }}
                            onClick={_onMergeComponents}
                            width={22}
                          />
                        </FeatureSubscriptionGuard>
                      }
                    />
                  </LayerGuard>
                </SRUserTypeAccessGuard>
              </Tooltip>
              <SubscriptionChip
                feature={SubscriptionFeature.SingleLayerMove}
                height={12}
                className='ml-2'
              />
            </Row>
          </Col>
        </Row>
      </div>

      {!isSharePage && (
        <div className='end-content mt-4'>
          <ServiceCatalogue
            components={componentsWithFeatureId}
            initialAssignments={props.initialAssignments}
            orderHash={props.orderHash}
            onApply={handleApply}
          />
        </div>
      )}
    </Card>
  );
};
