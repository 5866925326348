import React from 'react';

const DownArrow = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    {...props}
  >
    <path
      d='M3 4L6 7L9 4'
      stroke='#333333'
      stroke-width='0.8'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);

export default DownArrow;
