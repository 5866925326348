import * as turf from '@turf/turf';

const mergePolygons = (...polygons) => {
  if (polygons.length < 2) {
    return returnUnchanged(polygons);
  }
  const temp = [...polygons];
  polygons.forEach((polygon) => {
    turf.truncate(polygon, { precision: 6, coordinates: 2, mutate: true });
  });
  for (let i = 0; i < polygons.length - 1; i++) {
    updatePolygonAddPoints(polygons[i], i + 1, polygons);
  }
  polygons.reverse();
  // updatePolygonAddPoints(polygons[0], 1, polygons);
  for (let i = 0; i < polygons.length - 1; i++) {
    updatePolygonAddPoints(polygons[i], i + 1, polygons);
  }

  let unionMultiPolygon = polygons[0];
  for (let i = 1; i < polygons.length; i++) {
    // const poly = turf.buffer(polygons[i], 1, {units: 'centimeters'});
    unionMultiPolygon = turf.union(unionMultiPolygon, polygons[i]);
  }
  const superFinalFeatures = [];
  // polygons.forEach((currentFeature, featureIndex) => {
  //   superFinalFeatures.push(currentFeature);
  // });
  turf.flattenEach(unionMultiPolygon, (feature, index, multiFeatureIndex) => {
    superFinalFeatures.push(feature);
  });

  return {
    delete: [...temp],
    indifferent: [],
    union: [...superFinalFeatures],
  };
};

const updatePolygonAddPoints = (firstPolygon, index, polygons) => {
  for (let i = index; i < polygons.length; i++) {
    let firstPolygonLines = turf.polygonToLine(firstPolygon);
    let secondPolygon = polygons[i];
    let secondPolygonIntoPoints = [];
    turf.coordEach(
      secondPolygon,
      (
        currentCoord,
        coordIndex,
        featureIndex,
        multiFeatureIndex,
        geometryIndex
      ) => {
        secondPolygonIntoPoints.push(currentCoord);
      },
      true
    );
    firstPolygonLines = injectPointsOnLine(
      firstPolygonLines,
      secondPolygonIntoPoints
    );
    firstPolygon = turf.lineToPolygon(firstPolygonLines);
  }
  polygons[index - 1] = firstPolygon;
};

const returnUnchanged = (polygons) => {
  return {
    delete: [],
    indifferent: [...polygons],
    union: [],
  };
};

const injectPointsOnLine = (polygonLines, pointCollection) => {
  pointCollection.forEach((point, index) => {
    let isPointOnLine = false;
    let selectedSegmentIndex = null;
    turf.segmentEach(
      polygonLines,
      (
        lineSegment,
        featureIndex,
        multiFeatureIndex,
        geometryIndex,
        segmentIndex
      ) => {
        let isOnVertex = false;
        turf.coordEach(
          lineSegment,
          (
            currentCoord,
            coordIndex,
            featureIndex,
            multiFeatureIndex,
            geometryIndex
          ) => {
            const distanceBetweenPoints = turf.pointToLineDistance(
              turf.point(point),
              lineSegment,
              { units: 'meters', method: 'geodesic' }
            );
            if (distanceBetweenPoints < 1) {
              isOnVertex = true;
            }
          },
          true
        );
        if (isOnVertex) {
          return;
        }
        const isPointOnLineSegment = turf.booleanPointOnLine(
          turf.point(point),
          lineSegment,
          { ignoreEndVertices: true }
        );
        const distanceOfPointFromSegment = turf.pointToLineDistance(
          turf.point(point),
          lineSegment,
          { units: 'meters', method: 'geodesic' }
        );
        // console.log(isPointOnLineSegment, distanceOfPointFromSegment);
        if (isPointOnLineSegment || distanceOfPointFromSegment < 1) {
          selectedSegmentIndex = segmentIndex;
          isPointOnLine = true;
        }
      }
    );
    if (isPointOnLine) {
      polygonLines = insertPointOnLineSegment(
        polygonLines,
        selectedSegmentIndex,
        point
      );
    }
  });
  return polygonLines;
};

const insertPointOnLineSegment = (
  polygonLines,
  selectedSegmentIndex,
  singlePoint
) => {
  let coords = turf.coordAll(polygonLines);
  coords.splice(selectedSegmentIndex + 1, 0, singlePoint);
  return turf.cleanCoords(turf.lineString(coords));
};

export default mergePolygons;
