import { NotifyError } from '@/helpers/notification-utils';
import { replaceParams } from '@/helpers/utilities/linkUtils';
import { _filter, _get } from '@/helpers/utilities/lodashUtils';
import {
  baseView,
  currentView,
  editableView,
  redViews,
  setAttributePanelVisibility,
  setOrderViews,
  setVisibleAttributesInLayerPanel,
} from '@/store/order/actions';
import { Button, Divider, Layout, Modal, Row, Spin } from 'antd';

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as API_ENDPOINTS from '../../../../helpers/constants/APIEndpoints';
import { trackEvents } from '@/helpers/utilities';
import LayerPanel from './LayerPanel';
import {
  toggleOrdersListPanelVisibility,
  toggleSecondaryPanelVisibility,
} from '@/store/ui/actions';
import '../../../containers/feature-hover-panel/style.less';
import '../style.less';
import MapMeasurementFailed from './MapMeasurementFailed';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import OrderStatusGuard from '@/modules/project/guards/OrderStatus';
import { OrderStatus } from '@/modules/common/types';
import { getPanelWidth } from '@project/projectComponents/helpers';
import { SHARE_MAP_PAGE } from '../../../constants';
import { showNotification } from '../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../storybook/NotificationToast/types';
import { withChangeUrlViewId } from './hooks/useChangeUrlViewId';
import EmViewDropDown from '../../../PlasmicComponents/EmViewDropDown2';
import EmLegacyEstimationViewAlert from '../../../PlasmicComponents/EmLegacyEstimationViewAlert';
import CoFeedbackStatus from '../../../PlasmicComponents/CoFeedbackStatus';
import EODTakeoffPricingStatus from '../../../PlasmicComponents/EodTakeoffPricingStatus';
import ViewSelectionPanel from './ViewSelectionPanel';
import EstimationViewPanel from './EstimationViewPanel/EstimationViewPanel';
import { ViewType } from '@/modules/common/types';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { EstimationTabs } from './types';
import { WidgetBarTabs } from '../../../types';
import { ToolsTypes } from '../../../containers/navbar/tools/toolsTypes';
import { removeCutInteraction } from '../../../../helpers/mapUtils/tools/cutTool';
import { removeLineSliceInteraction } from '../../../../helpers/mapUtils/tools/lineSliceTool';
import { removeDoughnutInteraction } from '../../../../helpers/mapUtils/tools/doughnutTool';
import { removeDrawInteraction } from '../../../../helpers/mapUtils/tools/drawTool';
import { removeMeasureInteraction } from '../../../../helpers/mapUtils/tools/measurementTool';
import { removeModifyInteraction } from '../../../../helpers/mapUtils/tools/modifyTool';
import { removeSelectInteraction } from '../../../../helpers/mapUtils/featuresUtils';
import { removeSnipInteraction } from '../../../../helpers/mapUtils/tools/snipTool';
import { resetCrossLayerSnap } from '../../../../helpers/mapUtils/tools/snapping';
import { removeDragBoxInteraction } from '../../../../helpers/mapUtils/tools/multiSelecteTool';
import { unByKey } from 'ol/Observable';
import { mapClickHandlerToTrackDrawPointsForHistory } from '../../../containers/map-tools-panel/MapToolsPanel';
import {
  setMapClassnames,
  setActiveTool,
} from '../../../../store/user/actions';
import withElementPositioningAndVisibility from '../../../../modules/property/hoc/withElementsPositioningAndVisibility';
import withViewSelectionOrCreationUtil from '../../../../modules/property/hoc/withViewSelectionOrCreationUtil';
import { ReactComponent as Cross } from '../../../../assets/action-center/close_icon_cross.svg';
import { withWidgetBarContext } from '../../../../modules/property/hoc/withWidgetBar';
import { getPlasmicOrderStatus } from '../utils/helpers';
import { getIsoTimeDifference } from './helpers';
import { CreateOrderEvt } from '../../../../segment';
import { SWITCH_TO_STATIC_VIEW_SOURCE, VIEW_PANEL_SOURCE } from '../../../../events/constants';
import PricingDeliveryModal from './PricingFlow/PricingSuccessModal';
import EODPricingStatusWrapper from './PricingFlow/EODPricingStatusWrapper';

const { Sider } = Layout;
const axiosInstance = getAxiosInstance();
class ProjectFeaturePanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoading: false,
      viewsList: [],
      selectedView: null,
      showAddViewModal: false,
      isDeleteView: [],
      isRenameView: [],
      selectedViewCpy: [],
      showAddLayerModal: false,
      showAddViewAfterLayer: false,
      isEditableBase: false,
      showApprovedOrderDetails: props.showApprovedOrderDetails,
      fromBaseView: false,
      current: this.props?.history?.location?.pathname,
      noOfExtraCols: 0,
      panelWidth: getPanelWidth(0),
      matches: window.matchMedia('(min-width: 768px)').matches,
      lastSavedTimeText: '',
      activeEstimationTab: EstimationTabs.SERVICE_MAP,
      isViewpanelPinned: false,
      showLegacyEstimationViewModal: false,
    };
  }

  componentDidMount() {
    this.loadOrderViews();
    this.state.matches ? this.setToggleFPanel(this.props.isLayerPanel) : '';
    // The below code block must be kept below the above code block
    if (this.props.isBulkView) {
      this.props.toggleSecondaryPanelVisibility(true);
    } else {
      this.props.toggleSecondaryPanelVisibility(false);
    }
  }

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    if (
      prevProps.showApprovedOrderDetails !== this.props.showApprovedOrderDetails
    ) {
      this.setState({
        showApprovedOrderDetails: this.props.showApprovedOrderDetails,
      });
    }
    if (prevProps.isLayerPanel !== this.props.isLayerPanel) {
      this.setToggleFPanel(this.props.isLayerPanel);
    }

    if (this.props.attributePanelVisible) {
      this.setState({
        noOfExtraCols: 1,
      });
    }

    this.setState({
      panelWidth: getPanelWidth(this.state.noOfExtraCols),
    });

    if (this.props.orderSummary.bulkOrderId) {
      if (prevProps.orderSummary?.orderId !== this.props.orderSummary.orderId) {
        this.loadOrderViews();
      }
    }

    if (
      [
        WidgetBarTabs.SNAPSHOT,
        WidgetBarTabs.REPORTS,
        WidgetBarTabs.NOTES,
      ].includes(this.props.selectedWidgetBarTab) ||
      this.props.selectedWidgetBarTab === null
    ) {
      this.setToggleFPanel(false);
    } else {
      if (this.props.selectedWidgetBarTab !== WidgetBarTabs.VIEWS) {
        this.setToggleFPanel(true);
      }
    }

    if (this.props.selectedWidgetBarTab !== WidgetBarTabs.LAYERS) {
      this.props.toggleApprovedOrderDetails(false);
      this.props.setOrderDetailsPanelOpen(false);
      this.props.setNormalLayerPanelOpen(false);
      this.props.toggleOrdersListPanelVisibility(false);
    }

    if (this.props.orderViews?.length !== prevProps.orderViews?.length) {
      /** New Views may have been added, we need to update local views list */
      this.setState({
        viewsList: this.props.orderViews,
      });
    }
  };

  loadOrderViews = (shouldChangeUrlViewId = false) => {
    const orderId = this.props.orderSummary.orderId;
    const isShareMapPage = window.location.pathname.includes(SHARE_MAP_PAGE);
    let selectedView;

    this.setState({
      isDataLoading: true,
    });
    axiosInstance
      .get(
        replaceParams(API_ENDPOINTS.ORDER_VIEW, {
          ':orderId': orderId,
        })
      )
      .then((res) => {
        const data = res.data;

        const searchQuery = new URLSearchParams(
          this.props.history.location.search
        );

        const viewId = searchQuery.get('viewId');
        let currentView = null;
        if (viewId) {
          currentView = _filter(data, (view) => +view.viewId === +viewId);
        } else {
          currentView = data;
        }

        this.props.setOrderViews(data);

        const hasBaseView =
          _filter(currentView, (view) => view.isBaseView).length > 0;
        const hasDefaultView =
          _filter(currentView, (view) => view.isDefault).length > 0;
        const staticViews = _filter(
          currentView,
          (view) => view.viewType === 'static'
        );

        const urlViewId = this.props.match.params.viewId;

        const selectedView =
          urlViewId &&
            urlViewId.length &&
            _filter(currentView, (view) => view.viewId === Number(urlViewId))
              .length > 0
            ? _filter(
              currentView,
              (view) => view.viewId === Number(urlViewId)
            )[0]
            : hasDefaultView
              ? _filter(currentView, (view) => view.isDefault)[0]
              : hasBaseView
                ? _filter(currentView, (view) => view.isBaseView)[0]
                : isShareMapPage
                  ? currentView[0]
                  : staticViews[staticViews.length - 1];

        if (shouldChangeUrlViewId) {
          this.props.changeViewId(String(selectedView.viewId));
        }
        if (
          ![OrderStatus.Draft, OrderStatus.MeasurementOngoing].includes(
            this.props.orderStatus
          )
        ) {
          this.props.handleWidgetBarTabChange(
            [ViewType.ESTIMATION, ViewType.DYNAMIC].includes(
              selectedView.viewType
            )
              ? WidgetBarTabs.ESTIMATION
              : WidgetBarTabs.LAYERS,
            this.props.selectedWidgetBarTab
          );
        }
        this.setState((prevState) => ({
          isDataLoading: false,
          viewsList: data,
          selectedView: selectedView,
        }));

        this.props.baseView({
          isBaseView: _filter(
            currentView,
            (view) => view.viewId === selectedView.viewId
          )[0].isBaseView,
        });
        this.props.isEditableView({
          isEditableView: _filter(
            currentView,
            (view) => view.viewId === selectedView.viewId
          )[0].isEditable,
        });
        this.props.currentView(selectedView);

        this.props.redViews(data);
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          isDataLoading: false,
        });
        console.error(err);
        NotifyError('Data Error', { api: 'load-views', orderId: orderId });
      });
  };

  setDataLoading = (state) => {
    let that = this;
    that.setState({ isDataLoading: state });
  };

  reloadViews = () => {
    let that = this;
    that.loadOrderViews();
  };

  setToggleFPanel = (option, shouldCloseAllFeatureCard = false) => {
    if (this.props.isBulkView) {
      this.props.toggleSecondaryPanelVisibility(!!option);
    }
    this.props.toggleFeaturePanel(!!option, shouldCloseAllFeatureCard);
  };

  toggleAddNewLayer = (option) => {
    const { currentViewData } = this.props;
    trackEvents('featurepanel__add-layer-click', {
      isBaseView: currentViewData?.isBaseView,
      orderId: currentViewData?.orderId,
      viewId: currentViewData?.viewId,
    });
    let that = this;
    that.setState({
      showAddLayerModal: !!option,
    });
  };

  toggleAddAfterLayer = (
    option,
    isEditableBase = false,
    fromBaseView = false
  ) => {
    let that = this;
    that.setState({
      showAddViewAfterLayer: !!option,
      isEditableBase: !!isEditableBase,
      fromBaseView,
    });
  };

  switchView = (view) => {
    if (
      /** We are switching from "static" view to "estimation" view */
      view.viewType === ViewType.ESTIMATION &&
      this.props.currentViewData &&
      this.props.currentViewData.viewType === ViewType.STATIC
    ) {
      if (this.props.setNoteActions) {
        this.props.setNoteActions(false);
      }

      /** We need to reset the active tool state */
      this.props.setActiveTool(ToolsTypes.SelectTool.action);

      if (mapClickHandlerToTrackDrawPointsForHistory) {
        //To remove point level tracking onclick interactions
        unByKey(mapClickHandlerToTrackDrawPointsForHistory);
        // setActiveDeActiveInteraction(that.props.mapRef, DragPan, true);
      }

      removeDragBoxInteraction(this.props.mapRef);

      removeCutInteraction(this.props.mapRef);
      removeLineSliceInteraction(this.props.mapRef);
      removeDoughnutInteraction(this.props.mapRef);
      removeDrawInteraction(this.props.mapRef);
      removeMeasureInteraction(this.props.mapRef);
      removeModifyInteraction(this.props.mapRef);
      removeSelectInteraction(this.props.mapRef);
      removeSnipInteraction(this.props.mapRef);
      resetCrossLayerSnap(this.props.mapRef);

      this.props.setMapClassnames('select');
    }

    this.changeView(view);
  };

  changeView = (view) => {
    trackEvents('featurepanel__view-change', {
      orderId: view.orderId,
      viewId: view.viewId,
      isBaseView: view.isBaseView,
      isEditable: view.isEditable,
    });
    // changePath(this.props.history);
    this.props.currentView(view);

    // if (
    //   [ViewType.ESTIMATION, ViewType.DYNAMIC].includes(
    //     view.viewType && this.props.orderStatus !== 1
    //   )
    // ) {
    //   this.props.handleWidgetBarTabChange(WidgetBarTabs.ESTIMATION, this.props.selectedWidgetBarTab);
    // }

    // if (view.viewType === ViewType.STATIC && this.props.orderStatus !== 1) {
    //   this.props.handleWidgetBarTabChange(WidgetBarTabs.LAYERS, this.props.selectedWidgetBarTab);
    // }

    this.props.baseView({
      isBaseView: view.isBaseView,
    });
    this.props.isEditableView({
      isEditableView: view.isEditable,
    });
    this.setState({
      noOfExtraCols: 0,
    });

    this.props.setAttributePanelVisibility(false);
    this.props.setVisibleAttributesInLayerPanel([]);
  };

  setRenameView = () => {
    let that = this;
    that.loadOrderViews();
    showNotification(
      NOTIFICATIONS_TYPES.SUCCESS,
      'Information updated successfully'
    );
  };

  deleteView = () => {
    let that = this;
    that.loadOrderViews(true);
    showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Deleted successfully');
  };

  createNewView = (data, isSelectedBaseView) => {
    let that = this;
    const { viewsList } = that.state;

    showNotification(
      NOTIFICATIONS_TYPES.SUCCESS,
      `A new view named ${_get(data, 'name')} is successfully created.`
    );
    that.setState(
      (prevState) => {
        let updateView = [];
        updateView.push({ ...data }, ...prevState.viewsList);
        that.props.redViews(updateView);
        return {
          viewsList: updateView,
          isDataLoading: false,
          showAddViewAfterLayer: false,
        };
      },
      () => {
        that.changeView(data);

        trackEvents('featurepanel__new-view', {
          orderId: data?.orderId,
          viewId: data?.viewId,
          viewName: data?.name,
          viewCount: viewsList.length,
          isCopiedFromBaseView: isSelectedBaseView,
        });
      }
    );
    that.setState({
      isDataLoading: false,
      showAddViewAfterLayer: false,
      selectedView: data,
    });
    that.props.isEditableView({
      isBaseView: data.isBaseView,
    });
    that.props.baseView({
      isEditableView: data.isEditable,
    });
  };

  toggleApprovedOrderDetails = (val) => {
    this.props.toggleApprovedOrderDetails(val);
    this.toggleViewpanelPin(!val);
    this.props.showViewSelectionPanel(!val);
    if (val) {
      this.props.setOrderDetailsPanelOpen(true);
      this.props.setNormalLayerPanelOpen(false);
    }
  };

  linkedViewClickHandler = (viewId) => {
    const view = this.state.viewsList.find((v) => v.viewId === viewId);
    this.changeView(view);
  };

  setLastSavedTimeText = (text) => {
    this.setState({ lastSavedTimeText: text });
  };

  setActiveEstimationTab = (tab) => {
    this.setState({ activeEstimationTab: tab });
  };

  toggleViewpanelPin = (val) => {
    this.setState({ isViewpanelPinned: val });
    if (!val) {
      this.props.showViewSelectionPanel(false);
    }
  };

  setShowLegacyEstimationViewAlert = (show) => {
    this.setState({ showLegacyEstimationViewModal: show });
  };

  switchToLinkedViewHandler = () => {
    trackEvents(CreateOrderEvt.ViewPanelSwitchToLinkedView, {
      source: SWITCH_TO_STATIC_VIEW_SOURCE.VIEW_DROPDOWN_LINKED_VIEW_CLICK
    })
    this.props.handleStaticViewClick();
    this.props.handleWidgetBarTabChange(WidgetBarTabs.LAYERS, this.props.selectedWidgetBarTab);
  };

  createNewEstimationViewHandler = () => {
    this.props.handleEstimationViewClick();
    this.props.handleWidgetBarTabChange(WidgetBarTabs.ESTIMATION, this.props.selectedWidgetBarTab);
    this.setShowLegacyEstimationViewAlert(false);
  };

  render() {
    const { orderSummary, orderBasicDetails, currentViewData, orderTileData, openFPanel, isEstimationView, mapRef } =
      this.props;
    const {
      isDataLoading,
      viewsList,
      showAddLayerModal,
      showApprovedOrderDetails,
      current,
    } = this.state;

    return (
      <div
        className='timeline-container timeline-detail'
        style={{
          ...(openFPanel && !showApprovedOrderDetails
            ? { left: '56px', width: 'fit-content' }
            : { left: '56px', width: 0 }),
          display: [OrderStatus.Draft, OrderStatus.MeasurementOngoing].includes(
            this.props.orderStatus
          )
            ? 'none'
            : 'flex',
          flexDirection: 'column',
          overflow: 'initial',
          position: 'relative',
          left: this.state.isViewpanelPinned ? '304px' : 56,
          borderTop: '0.5px solid #DDD',
        }}
      >
        <PricingDeliveryModal
          orderTileData={orderTileData}
          handleWidgetBarTabChange={this.props.handleWidgetBarTabChange}
          selectedWidgetBarTab={this.props.selectedWidgetBarTab}
          setActiveEstimationTab={this.setActiveEstimationTab}
        />
        {/* Contains order id and cross icon - Show only in static views */}
        {currentViewData && currentViewData.viewType === 'static' && (
          <div
            className='feature-hover-panel-body'
            style={{ width: this.state.panelWidth }}
          >
            <OrderStatusGuard except={[OrderStatus.BoltFailed]}>
              {!showApprovedOrderDetails && (
                <>
                  {openFPanel && (
                    <div
                      style={{
                        position: 'absolute',
                        left: `calc(${getPanelWidth(
                          this.state.noOfExtraCols
                        )} - 32px)`,
                        cursor: 'pointer',
                        top: '5px',
                        height: '12px',
                        width: '12px',
                        padding: '8px',
                      }}
                      className='tour-close-panel'
                    >
                      <Button
                        type={'link'}
                        icon={
                          <Cross
                            cursor='pointer'
                            onClick={() => {
                              this.setToggleFPanel(false, true);
                              this.props.handleCloseWidgetBarTab(
                                WidgetBarTabs.LAYERS
                              );
                            }}
                          />
                        }
                        style={{ width: 24, height: 24, display: 'flex' }}
                        className={'flex-justify-align-center'}
                      />
                    </div>
                  )}
                </>
              )}
            </OrderStatusGuard>

            {openFPanel && !showApprovedOrderDetails && (
              <Box>
                <div
                  className='d-flex align-center pl-4 pr-2'
                  style={{ height: 44 }}
                >
                  <Typography
                    fontSize={12}
                    lineHeight={'16px'}
                    style={{
                      color: '#2563EB',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={this.props.handleOrderHistoryOpen}
                  >
                    Order Id: {orderSummary.orderId}
                  </Typography>
                </div>

                {current.split('/')[1] !== SHARE_MAP_PAGE && (
                  <div style={{ borderTop: '1px solid #E8E8E8' }} />
                )}
              </Box>
            )}
          </div>
        )}

        {this.props.openFPanel && (
          <Row
            align='middle'
            className='py-2'
            justify='space-between'
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <div
              style={{
                width:
                  currentViewData?.viewType === ViewType.STATIC ? 339 : 320,
              }}
            >
              <EmViewDropDown
                isLockedByUser={currentViewData?.siLockDetails?.lockedBy === this.props.currentUserName}
                isBaseView={currentViewData?.isBaseView}
                isPricingLocked={currentViewData?.isPricingLocked}
                siLockDetails={currentViewData?.siLockDetails}
                onSelectView={this.linkedViewClickHandler}
                onDropDown={() => {
                  if (!this.props.viewSelectionPanelVisible) {
                    trackEvents(CreateOrderEvt.ViewPanelVisible, {
                      source: VIEW_PANEL_SOURCE.VIEW_DROPDOWN,
                      orderStatus: this.props.orderStatus,
                    })
                  }
                  this.props.showViewSelectionPanel(true)
                }
                }
                activeViewName={currentViewData?.name}
                className='w-100'
                linkedView={
                  [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(
                    currentViewData?.viewType
                  ) &&
                  // find linked view with id 'linkedView' and pass the name
                  currentViewData?.linkedView &&
                  viewsList.find((v) => v.viewId === currentViewData.linkedView)
                    ?.name
                }
                id={currentViewData?.viewId}
                onLinkedViewClicked={this.switchToLinkedViewHandler}
              />
            </div>

            {currentViewData &&
              currentViewData.viewType === ViewType.ESTIMATION && (
                <Row
                  style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}
                >
                  {/* {currentViewData &&
                currentViewData.viewType !== ViewType.STATIC &&
                this.state.activeEstimationTab != EstimationTabs.SERVICE_MAP &&
                this.state.lastSavedTimeText !== '' && (
                  <div style={{ minWidth: '100px' }}>
                    <Typography
                      fontSize={10}
                      fontWeight={400}
                      lineHeight={'normal'}
                      style={{ color: '#999', textAlign: 'center' }}
                    >
                      Last Saved: {this.state.lastSavedTimeText}
                    </Typography>
                  </div>
                )} */}
                  <div className='tour-close-panel'>
                    <Button
                      type={'link'}
                      icon={
                        <Cross
                          cursor='pointer'
                          onClick={() => {
                            this.setToggleFPanel(false);
                            this.props.handleCloseWidgetBarTab(
                              WidgetBarTabs.ESTIMATION
                            );
                          }}
                        />
                      }
                      style={{ width: 24, height: 24, display: 'flex' }}
                      className={'flex-justify-align-center'}
                    />
                  </div>
                </Row>
              )}
          </Row>
        )}

        <ViewSelectionPanel
          isEstimationView={isEstimationView}
          showAddNewViewAlertModal={this.state.showAddViewAfterLayer}
          isOpen={this.props.viewSelectionPanelVisible}
          onClose={() => {
            this.props.showViewSelectionPanel(false);
          }}
          createNewView={this.createNewView}
          orderViews={viewsList}
          setDataLoading={this.setDataLoading}
          currentView={{
            isBaseView: currentViewData?.isBaseView,
            isDefault: currentViewData?.isDefault,
            isEditable: currentViewData?.isEditable,
            name: currentViewData?.name,
            orderId: currentViewData?.orderId,
            viewId: currentViewData?.viewId,
            viewType: currentViewData?.viewType,
            linkedView: currentViewData?.linkedView,
            isSIAssignmentLocked: currentViewData?.isSIAssignmentLocked,
            isPricingLocked: currentViewData?.isPricingLocked,
            siLockDetails: currentViewData?.siLockDetails,
          }}
          onChange={this.switchView}
          setShowAddNewViewAlertModal={(arg) =>
            this.setState({
              showAddViewAfterLayer: arg,
            })
          }
          reloadViews={this.reloadViews}
          toggleViewpanelPin={this.toggleViewpanelPin}
          isViewpanelPinned={this.state.isViewpanelPinned}
          openFPanel={this.props.openFPanel}
          handleWidgetBarTabChange={this.props.handleWidgetBarTabChange}
          orderStatus={this.props.orderStatus}
          isShareLinkPage={this.props.isShareLinkPage || false}
        />

        {openFPanel && currentViewData?.viewType === ViewType.STATIC && (
          <OrderStatusGuard
            status={[
              OrderStatus.AwaitingApproval,
              OrderStatus.ResolvingFeedback,
              OrderStatus.BoltInVerification,
            ]}
          >
            <>
              <Divider className='mb-2 mt-0' />
              {!this.props.isShareLinkPage &&
                <Box
                  className='px-2 mb-2'
                  style={{ width: this.state.panelWidth }}
                >
                  {/* Order status widget  */}
                  {isEstimationView ?
                    <EODPricingStatusWrapper
                      orderTileData={orderTileData}
                      orderStatus={getPlasmicOrderStatus(
                        this.props.orderSummary.status
                      )}
                      feedbackCount={
                        this.props.feedbackData?.open_count ||
                        this.props.feedbackData?.closed_count
                      }
                      onClick={() => {
                        this.props.setIsFeedbackPanelOpen(false);
                        this.props.setShowAllFeedbackCard(true);
                      }}
                      boltInVerificationTimer={getIsoTimeDifference(
                        this.props.boltEstimatedDeliveryTime,
                        null,
                        false
                      )}
                      feedbackType={
                        this.props.feedbackData?.open_count ? 'open' : 'closed'
                      }
                      takeoffDone={[3, 4, 5].includes(this.props.orderSummary.status) && (orderTileData.estimationStatus === "estimation_pending" || orderTileData.estimationStatus === "estimation_in_progress")}
                      pricingDone={[3, 4, 5].includes(this.props.orderSummary.status) && orderTileData.estimationStatus === "estimation_delivered"}
                      withoutChevron={false}
                    /> :
                    <CoFeedbackStatus
                      orderStatus={getPlasmicOrderStatus(
                        this.props.orderSummary.status
                      )}
                      feedbackCount={
                        this.props.feedbackData?.open_count ||
                        this.props.feedbackData?.closed_count
                      }
                      onOpenFeedbackModal={() => {
                        this.props.setIsFeedbackPanelOpen(false);
                        this.props.setShowAllFeedbackCard(true);
                      }}
                      boltInVerificationTimer={getIsoTimeDifference(
                        this.props.boltEstimatedDeliveryTime,
                        null,
                        false
                      )}
                      feedbackType={
                        this.props.feedbackData?.open_count ? 'open' : 'closed'
                      }
                    />}
                </Box>
              }
            </>
          </OrderStatusGuard>
        )}

        {currentViewData && currentViewData.viewType === ViewType.STATIC && (
          <div>
            <OrderStatusGuard
              except={[
                OrderStatus.Draft,
                OrderStatus.MeasurementOngoing,
                OrderStatus.BoltFailed,
              ]}
              fallback={<MapMeasurementFailed />}
            >
              <Sider
                id='feature-panel-sider'
                trigger={null}
                collapsible
                collapsedWidth={0}
                theme='light'
                width={openFPanel && !showApprovedOrderDetails ? '100%' : 0}
              >
                <div id='feature-hover-panel'>
                  {isDataLoading ? (
                    <div
                      style={{
                        padding: '15px 20px',
                        width: '270px',
                        height: '92vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Spin />
                    </div>
                  ) : (
                    <div>
                      <div
                        className={'feature-track'}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          borderTop: '1px solid #E8E8E8',
                        }}
                      >
                        <LayerPanel
                          mapRef={mapRef}
                          selectedView={currentViewData}
                          popoverRef={this.props.popoverRef}
                          showAddLayerModal={showAddLayerModal}
                          toggleAddNewLayer={this.toggleAddNewLayer}
                          orderTileData={orderTileData}
                          key={_get(currentViewData, 'viewId', 0)}
                          checkMap={this.props.checkMap}
                          disabledCheck={this.props.disabledCheck}
                          onBaseMapCheck={this.props.onBaseMapCheck}
                          onNearMapCheck={this.props.onNearMapCheck}
                          nearMapCheck={this.props.nearMapCheck}
                          expendedKeys={this.props.expendedKeys}
                          downloadMapImage={this.props.downloadMapImage}
                          openFPanel={
                            this.props.openFPanel && !showApprovedOrderDetails
                          }
                          showApprovedOrderDetails={showApprovedOrderDetails}
                          isEditableBase={this.state.isEditableBase}
                          toggleAddAfterLayer={this.toggleAddAfterLayer}
                          fetching_imagery={this.props.fetching_imagery}
                          loadOrderDetails={this.props.loadOrderDetails}
                          orderSummary={orderSummary}
                          isNearMap={this.props.isNearMap}
                          shortCutRef={this.props.shortCutRef}
                          isOperationAllowed={this.props.isOperationAllowed}
                          history={this.props.history}
                          selectedFeature={this.props.selectedFeature}
                          multiSelectFeature={this.props.multiSelectFeature}
                          unSetMultiSelectFeature={
                            this.props.unSetMultiSelectFeature
                          }
                          isLayerStyleAllowed={this.props.isLayerStyleAllowed} //is allowed layer style
                          clickFeatureAction={this.props.clickFeatureAction}
                          setNoteActions={this.props.setNoteActions}
                          isVerificationTimerVisible={
                            this.props.isVerificationTimerVisible
                          }
                          boltOrderStatus={this.props.boltOrderStatus}
                          boltEstimatedDeliveryTime={
                            this.props.boltEstimatedDeliveryTime
                          }
                          isParcelDisabled={this.props.isParcelDisabled}
                          setExtraCols={(n) => {
                            this.setState({ noOfExtraCols: n });
                            this.props.setExtraCols(n);
                          }}
                          isBulkView={this.props.isBulkView}
                          setShowAddNewViewAlertModal={(arg) =>
                            this.setState({
                              showAddViewAfterLayer: arg,
                            })
                          }
                          updateLayerDataValues={
                            this.props.updateLayerDataValues
                          }
                          updateLayerAreaInfo={this.props.updateLayerAreaInfo}
                          updatedLayers={this.props.updatedLayers}
                          updateLayerSelection={this.props.updateLayerSelection}
                          slider={this.props.slider}
                          isFeedbackPanelOpen={this.props.isFeedbackPanelOpen}
                          isViewPanelPinned={this.state.isViewpanelPinned}
                          setTertiaryPanelOpen={this.props.setTertiaryPanelOpen}
                          isOrderHistoryPanelVisible={
                            this.props.isOrderHistoryPanelVisible
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Sider>
            </OrderStatusGuard>
          </div>
        )}

        {currentViewData &&
          [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(
            currentViewData.viewType
          ) && (
            <div style={{ flex: 1, position: 'relative' }}>
              <EstimationViewPanel
                orderBasicDetails={orderBasicDetails}
                orderSummary={orderSummary}
                orderTileData={orderTileData}
                currentViewData={currentViewData}
                viewsList={viewsList}
                showViewSelectionPanel={this.props.showViewSelectionPanel}
                setLastSavedTimeText={this.setLastSavedTimeText}
                activeEstimationTab={this.state.activeEstimationTab}
                setActiveEstimationTab={this.setActiveEstimationTab}
                setShowLegacyEstimationViewAlert={
                  this.setShowLegacyEstimationViewAlert
                }
                openFPanel={this.props.openFPanel}
                toggleViewpanelPin={this.toggleViewpanelPin}
                linkedView={
                  [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(
                    currentViewData?.viewType
                  ) &&
                  // find linked view with id 'linkedView' and pass the name
                  currentViewData?.linkedView &&
                  viewsList.find((v) => v.viewId === currentViewData.linkedView)
                    ?.name
                }
                switchToLinkedViewHandler={this.switchToLinkedViewHandler}
              />
            </div>
          )}

        {this.state.showLegacyEstimationViewModal && (
          <Modal
            className='legacy-estimation-alert'
            visible={this.state.showLegacyEstimationViewModal}
            footer={null}
            closable={false}
            width={560}
            centered
          >
            <EmLegacyEstimationViewAlert
              onStayOnCurrentView={() =>
                this.setShowLegacyEstimationViewAlert(false)
              }
              onCreateNewEstimationView={this.createNewEstimationViewHandler}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcel: state.order.parcel,
    shareClick: state.user.shareClick,
    currentViewData: state.order.currentViewData,
    orderStatus: state.order.orderStatus,
    isBaseView: state.order.isBaseView,
    isEditableView: state.order.isEditableView,
    isOrdersListPanelVisible: state.ui.isOrdersListPanelVisible,
    isLayerPanel: state.ui.isLayerPanel,
    orderViews: state.order.orderViews,
    attributePanelVisible: state.order.attributePanelVisible,
    currentUserName: state.user.info.first_name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    currentView: (payload) => dispatch(currentView(payload)),
    baseView: (payload) => dispatch(baseView(payload)),
    redViews: (payload) => dispatch(redViews(payload)),
    isEditableView: (payload) => dispatch(editableView(payload)),
    toggleSecondaryPanelVisibility: (payload) =>
      dispatch(toggleSecondaryPanelVisibility(payload)),
    toggleOrdersListPanelVisibility: (payload) =>
      dispatch(toggleOrdersListPanelVisibility(payload)),
    setOrderViews: (payload) => dispatch(setOrderViews(payload)),
    setVisibleAttributesInLayerPanel: (payload) =>
      dispatch(setVisibleAttributesInLayerPanel(payload)),
    setAttributePanelVisibility: (payload) =>
      dispatch(setAttributePanelVisibility(payload)),
    setActiveTool: (payload) => dispatch(setActiveTool(payload)),
    setMapClassnames: (payload) => dispatch(setMapClassnames(payload)),
  };
};

export default withChangeUrlViewId(
  withRouter(
    withElementPositioningAndVisibility(
      withViewSelectionOrCreationUtil(
        withWidgetBarContext(
          connect(mapStateToProps, mapDispatchToProps)(ProjectFeaturePanel)
        )
      )
    )
  )
);
