import question from '@/assets/Question-mark.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus-icon.svg';
import { ReactComponent as SortDefault } from '@/assets/icons/sort-default.svg';
import { ReactComponent as SortAsc } from '@/assets/icons/sort-asc.svg';
import { ReactComponent as SortDesc } from '@/assets/icons/sort-desc.svg';
import { ReactComponent as ServiceItemKebab } from '@/assets/icons/service-item-kebab.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy-icon.svg';
import { DrawSegment } from '@/helpers/mapUtils/tools/toolSegment';
import { NotifyError } from '@/helpers/notification-utils';
import { catchError, getAxiosInstance } from '@/helpers/utilities/api-utils';
import { toggleLayerLeftPanel } from '@/store/ui/actions';
import ActionModal from '@elements/ActionModal';
import UploadGeoJSON from '@project/projectComponents/UploadGeoJSON/uploadGeoJSON';
import * as turf from '@turf/turf';
import WithAuth from '@/modules/auth/WithAuth';

import {
  Badge,
  Button,
  Checkbox,
  Col,
  Dropdown,
  Popover,
  Row,
  Spin,
  Switch,
  Tooltip,
  Tree,
  Typography,
} from 'antd';
import LoadingOutlined from '@ant-design/icons';
import './style.less';
import moment from 'moment';
import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import Point from 'ol/geom/Point';
import { transform } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import exclamatary from '../../../../assets/Exclamatary.svg';
import { ReactComponent as DeleteIconRed } from '../../../../assets/images/DeleteIconRed.svg';
import FilterPanelAttributeFilterIcon from '../../../../assets/svgs/layer-panel-attribute-filter.svg';
import { ReactComponent as TopologyWarning } from '../../../../assets/TopologyWarning.svg';
import { LAYER_PANEL_ATTRIBUTE_COL_WIDTH } from '../../../../helpers/constants';
import * as API_ENDPOINTS from '../../../../helpers/constants/APIEndpoints';
import { BULK_UPDATE_COMPONENT } from '../../../../helpers/constants/APIEndpoints';
import {
  MAP_GEOMETRY_TYPE_LINE,
  MAP_GEOMETRY_TYPE_POINT,
  MAP_GEOMETRY_TYPE_POLYGON,
} from '../../../../helpers/constants/mapConstants';
import {
  FEATURE_STATUS,
  MAINTAINER,
} from '../../../../helpers/constants/orderData';
import {
  CUT_SPLIT_STYLE_MODE,
  DRAW_STYLE_MODE,
  drawPathStyleFunction,
  PARCEL_BASE_STYLE,
  updatePathStyleFunction,
} from '../../../../helpers/mapGlobals/styles';
import { mapNavigation } from '../../../../helpers/mapUtils';
import { generatePropertiesFromFeature } from '../../../../helpers/mapUtils/autoSave/utils';
import {
  calculateAreaWithLayerId,
  clickLayerHighlight,
  displayComponentName,
  featureHoverIn,
  featureHoverOut,
} from '../../../../helpers/mapUtils/featuresUtils';
import {
  addFeaturesToSource,
  convertJSONStyleToOlStyle,
  getFeatureValue,
  getLayerUnits,
  getOffSet,
  newVectorLayer,
  newVectorSource,
  removeFeatureByComponentId,
  setStyleToLayer,
  topologyWarningStringsArray,
} from '../../../../helpers/mapUtils/layerUtils';
import { getComponentArea } from '../../../../helpers/mapUtils/mapCalculations';
import {
  createTileDebugLayer,
  removeTileDebugLayer,
  removeAllMapLayers,
} from '../../../../helpers/mapUtils/mapInit';
import { renderParcelLayer } from '../../../../helpers/mapUtils/parcelLayerUtils';
import { multiSelectTool } from '../../../../helpers/mapUtils/tools/multiSelecteTool';
import {
  onlyAllowNumber,
  trackEvents,
  truncateString,
} from '../../../../helpers/utilities';
import { amountFormat } from '../../../../helpers/utilities/currencyUtils';
import { replaceParams } from '../../../../helpers/utilities/linkUtils';
import {
  _debounce,
  _filter,
  _get,
} from '../../../../helpers/utilities/lodashUtils';
import {
  setAttributeFilterPanelVisible,
  setAttributeModalVisiblity,
  setAttributeMultiPopupData,
  setAttributePopupData,
  setCurrentLayer,
  setLinkNewAttributeVisible,
} from '../../../../store/attributeFeature/actions';
import {
  drawInteractionLastPointRedo,
  drawInteractionLastPointUndo,
  modifySyncState,
  setModifyDraftEvents,
} from '../../../../store/map/actions';
import {
  featureList,
  getLayerDetails,
  removeComponentTopologyWarnings,
  selectDeselectFeatures,
  setAttributePanelVisibility,
  setEditingRedux,
  setOrderFeatures,
  setOrderLayerComponents,
  setOrderLayers,
  updateLayerRefs,
  setVisibleAttributesInLayerPanel,
  updateOrdersTopologyWarnings,
  uploadLayerOnMapModalClose,
  setParcelStyle,
} from '../../../../store/order/actions';
import {
  kmlForLayerList,
  setActiveTool,
  setMapClassnames,
} from '../../../../store/user/actions';
import '../../../containers/antd-tree/styles.less';
import CopyLayerModal from '../../../containers/copy-layer/CopyLayer';
import MapToolsPanel from '../../../containers/map-tools-panel/MapToolsPanel';
import { AttributeFeature } from '../../../feature-attributes';
import { FeatureType } from '../../../feature-attributes/types';
import NewLayer from '../../../feature-attributes/add-feature';
import { AttributesFilter } from '../../../feature-attributes/filter-panel';
import EstimatedDeliveryTime from '../components/EstimatedDeliveryTime';
import StylePickerOptions from './StylePickerOptions';
import mergePolygons from '@project/projectComponents/MergePolygons';
import { addFeaturesToMap } from '@/helpers/mapUtils/tools/utilities';
import BetaReleaseGuard from '@/modules/common/guards/BetaRelease';
import { HIDDEN_MAP_TOOLS_PANEL_STATUS } from '@project/utils/data';
import OrderStatusGuard from '@/modules/project/guards/OrderStatus';
import {
  AccessRight,
  BetaFeature,
  OrderStatus,
  ViewType,
} from '@/modules/common/types';
import { WithRole } from '@/modules/auth/guards/RoleGuard';
import SRUserTypeAccessGuard from '@/modules/project/guards/SRUserTypeAccessGuard';
import { buildFilterRequestPayload } from '@/components/feature-attributes/filter-panel/helpers';
import {
  getAllAttributes,
  getAllAttributesWithDeleted,
  getAllServiceItems,
} from '@/store/attributeFeature/thunks';
import Text from '@/components/elements/text/Text';
import {
  getPanelWidth,
  disableAllTools,
  disableAllToolsTooltip,
} from './helpers';
import MoreChip from '@/components/feature-attributes/more-chip';
import TextIconButton from '../../../elements/text-icon-button';
import { successBottomMessage } from '../../../elements/bottom-message';
import { copyToClipboard } from '../../../../helpers/utilities/clickBoardUtils';
import { DEFAULT_PINNED_TOOLS } from '../../../containers/map-tools-panel/stories/Toolbar/constant';
import { showNotification } from '../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../storybook/NotificationToast/types';
import { ModifySyncState, SRUserTypes } from '../../../../modules/common/types';
import { ToolKey } from '../../../containers/map-tools-panel/stories/Toolbar/types';
import { saveIfAnyUnSyncedModifications } from '../../../containers/map-tools-panel/stories/Toolbar/MapToolBar/helpers';
import './style.less';
import { checkIfShareMapPage } from '../../../utils';
import EmTakeOffLayerChips from '../../../PlasmicComponents/EmTakeOffLayerChips';
import { transformComponentProperties } from '../../../../modules/property/api/transformers';
import {
  getComponentServiceItemColumnData,
  getLayerServiceItemColumnData,
  getServiceItemColumnData,
} from '../../../../modules/property/transformers/service-item.transformers';

const { TreeNode } = Tree;
const { Title } = Typography;
const ANTD_TREE_NODE_ACTIVE_COLOR = '#FDEEBB';
const ANTD_TREE_NODE_SELECTOR = '.ant-tree-treenode';
const DELETE_INTERACTION = 'deleteInteraction';
const DRAW_INTERACTION = 'drawInteraction';
const MERGE_TOOL_MESSAGE =
  'Non-Overlapping or Non-adjacent polygons cannot be merged.';
const EPSG_3857 = 'EPSG:3857';
const EPSG_4326 = 'EPSG:4326';
const FEATURE_STYLES = {
  Icon: new Style({
    image: new Icon({
      anchor: [0.5, 0.9],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      scale: 0.15,
      src: require('../../../../assets/icons/markerIconThree.svg').default,
    }),
  }),
};
const NO_PARCEL_ADDRESS_PIN = 'NO_PARCEL_ADDRESS_PIN';
const axiosInstance = getAxiosInstance();
class LayerPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layersData: [],
      layerComponents: {},
      loading: false,
      selectedLayer: null,
      treeCheckedKeys: [],
      layerRefs: {},
      selectedNodeKeys: [],
      parcelStyle: PARCEL_BASE_STYLE,
      isCopyLayer: false,
      featureType: {},
      deleteLayerLoading: false,
      tempComponentStorageForAutoSave: {
        //objects of component with key as componentId
      },
      factor: 0,
      isAllowedCal: false,
      expendedKeys: '',
      fixedValue: 0,
      treeCheckedName: [],
      treeCheckedStyle: null,
      layerComponentWithName: {},
      isDeleteComponent: null,
      componentDeleteLoading: false,
      undoStack: {},
      redoStack: {},
      stackOperationLoading: false,
      lastAutoSaved: {},
      autoSaveRequestTrack: {},
      masterLayerCheckState: { checked: true, indeterminate: false },
      updateAction: false,
      isParcelLayer: true,
      selectDeselectFeature: [],
      activeToolInteractionRef: null,
      appliedFilters: [],
      visibleAttributesInLayerPanel: [],
      attributePanelVisible: false,
      sort: 'false',
      siAttributeObj: {},
      serviceItemsMap: {},
    };
    this.updateStyle = _debounce(this.updateStyle, 200);
    this.syncUpdatedComponentToServers = _debounce(
      this.syncUpdatedComponentToServers,
      500
    );
    this.updateActionStatus = _debounce(this.updateActionStatus, 2000);
    this.layerFeatureNavigation = _debounce(this.layerFeatureNavigation, 2000);
  }

  loadPinnedTools = () => {
    axiosInstance
      .get('/api/templating/v1/tools')
      .then((res) => {
        let tools = res.data.tools;
        DEFAULT_PINNED_TOOLS.map((item) => {
          if (!tools.includes(item)) {
            tools.push(item);
          }
        });
        localStorage.setItem(
          'default_pinned',
          JSON.stringify(DEFAULT_PINNED_TOOLS)
        );
      })
      .catch(catchError);
  };

  setPinnedTools = (toolData) => {
    axiosInstance
      .post('/api/templating/v1/tools', { tools: toolData })
      .then((res) => {
        let tools = toolData;
        DEFAULT_PINNED_TOOLS.map((item) => {
          if (!tools.includes(item)) {
            tools.push(item);
          }
        });
        localStorage.setItem('default_pinned', JSON.stringify(tools));
      })
      .catch(catchError);
  };

  componentDidMount = () => {
    let that = this;
    that._isMounted = true;
    that.loadPinnedTools();
    that.loadFeatures();
    that.props.setEditingRedux(false);
    that.props.setMapClassnames('select'); // default selected tool
    that.props.setActiveTool('select');

    if (
      _get(that.props, 'orderTileData') &&
      _get(that.props, 'orderTileData.parcel_style')
    ) {
      let parcelStyle = _get(that.props, 'orderTileData.parcel_style');
      that.setState({
        parcelStyle: JSON.parse(parcelStyle.style),
      });

      that.props.setParcelStyle(JSON.parse(parcelStyle.style));
    } else {
      that.props.setParcelStyle(PARCEL_BASE_STYLE);
    }
    if (
      !that.props.isShared &&
      that.props.selectedView?.viewType !== ViewType.DYNAMIC
    ) {
      that.applyShortCutOperation();
    }
    this.props.setAttributePopupData(null);
  };

  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  };

  handleServiceItemsVisibilityChange = (visible) => {
    // if (!this.props.serviceItemsList.length) return;

    this.props.setAttributePanelVisibility(visible);

    let newListOfVisibility = [];

    this.props.visibleAttributesInLayerPanel.map((item) => {
      if (item !== 'SERVICE_ITEM') {
        newListOfVisibility.push(item);
      }
    });

    if (visible) {
      newListOfVisibility.push('SERVICE_ITEM');
      trackEvents('takeoff-map__service-item-column_visible');
    }

    if (this.props.setExtraCols) {
      this.props.setExtraCols(newListOfVisibility.length);
    }

    this.props.setVisibleAttributesInLayerPanel(newListOfVisibility);
    this.props.setTertiaryPanelOpen(visible);
  };

  setActiveToolRef = (interaction) => {
    this.setState({
      activeToolInteractionRef: interaction,
    });
  };

  applyShortCutOperation = () => {
    let that = this;
    //Create duplicate Layer
    that.props.shortCutRef.duplicateLayer = function () {
      if (that.state.selectedLayer) {
        that.handleModalOpen('isCopyLayer', true);
      }
    };
    //Layer Navigation slow
    that.props.shortCutRef.layerNavigationSlow = function (direction) {
      that.layerNavigation(direction, 'inches', 20);
    };
    //Layer Navigation fast
    that.props.shortCutRef.layerNavigationFast = function (direction) {
      that.layerNavigation(direction, 'metres', 10);
      // that.layerORMapNavigation(direction);
    };
    // Create Duplicate Component
    that.props.shortCutRef.duplicateComponent = function () {
      // that.props.setMapClassnames('drag duplicate');
      const { multiData } = that.props;
      if (typeof multiData !== 'object') return;
      const multiDataArr = Object.values(multiData || {});
      if (multiDataArr.length > 0) {
        let ids = multiDataArr[0].map((e) => e.id);
        that.onDuplicateComponent(ids);
      }
    };
    // Merge Components
    that.props.shortCutRef.mergeComponent = function () {
      const { multiData } = that.props;
      if (typeof multiData !== 'object') return;
      const multiDataArr = Object.values(multiData || {});
      if (multiDataArr.length > 0) {
        let ids = multiDataArr[0].map((e) => e.id);
        that.onMergeComponents(ids);
      }
    };
    // Undo with ctrl/command +z
    that.props.shortCutRef.undo = function () {
      const { selectedLayer } = that.state;
      const { drawingFeaturePointHistoryUndoStack } = that.props;

      if (
        that.props.activeTool === ToolKey.EDIT &&
        that.props.unSyncedModifiedChanges.length > 0
      ) {
        /** User is in "MODIFY" mode and there are un-synced changed. So, we don't want to allow "Undo" action  */
        showNotification(
          NOTIFICATIONS_TYPES.WARNING,
          'Undo action is not available when there are unsaved edits.'
        );
        return;
      }

      if (drawingFeaturePointHistoryUndoStack.length)
        that.performPointUndoOperation();
      else that.popFromUndoStack(selectedLayer?.id);
    };
    // redo with ctrl/command +y
    that.props.shortCutRef.redo = function () {
      const { selectedLayer } = that.state;
      const { drawingFeaturePointHistoryRedoStack } = that.props;

      if (
        that.props.activeTool === ToolKey.EDIT &&
        that.props.unSyncedModifiedChanges.length > 0
      ) {
        /** User is in "MODIFY" mode and there are un-synced changed. So, we don't want to allow "Redo" action  */
        showNotification(
          NOTIFICATIONS_TYPES.WARNING,
          'Redo action is not available when there are unsaved edits.'
        );
        return;
      }

      if (drawingFeaturePointHistoryRedoStack.length)
        that.performPointRedoOperation();
      else that.popFromRedoStack(selectedLayer?.id);
    };
    that.props.shortCutRef.deleteComponent = function () {
      const { selectedLayer } = that.state;
      if (selectedLayer) {
        that.deleteComponentWithKey(selectedLayer);
      }
    };
  };

  deleteComponentWithKey = (selectedLayer) => {
    let that = this;
    const { multiData, selectedView } = that.props;
    const layerId = selectedLayer?.id;
    const viewId = selectedView?.viewId;
    if (!selectedView?.isEditable) {
      NotifyError('Operation not allowed!');
      return;
    }

    let componentId;
    if (multiData) {
      componentId = Object.values(multiData)[0];
    } else {
      NotifyError('Operation not allowed!');
      return;
    }
    let reqData = {
      componentId: componentId?.map((component) => component.componentId),
    };
    axiosInstance
      .post(
        replaceParams(BULK_UPDATE_COMPONENT, {
          ':viewId': viewId,
          ':layerId': layerId,
        }),
        { delete: reqData.componentId }
      )
      .then((res) => {
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Deleted successfully');
        that.deleteComponents(
          layerId,
          componentId.map((component) => component.componentId)
        );
        that.props.setAttributeMultiPopupData(null);
        that.props.updateOrdersTopologyWarnings({
          ..._get(res.data, 'topologyWarning', {}),
          layerId: layerId,
        });
      })
      .catch(catchError);
  };

  layerORMapNavigation = (direction) => {
    const { mapRef } = this.props;
    mapNavigation(direction, mapRef);
  };

  updateActionStatus = () => {
    this.setState({ updateAction: false });
  };

  layerNavigation = (direction, type, distance) => {
    let that = this;
    const { selectedView } = that.props;
    const { selectedNodeKeys, layerRefs, updateAction } = that.state;
    const { editingToolVal } = that.props;
    if (!selectedView?.isEditable) {
      if (updateAction) {
        that.updateActionStatus();
        return;
      }
      that.setState({ updateAction: true });
      NotifyError('Operation not Allowed!');
      return;
    }
    if (selectedNodeKeys && selectedNodeKeys.length && editingToolVal) {
      that.props.setMapClassnames('drag');
      let [keys] = selectedNodeKeys;
      let [layerId, componentId] = keys.split('-');
      let selectedComponent;
      let vectorSource;
      vectorSource = layerRefs[layerId].getSource();
      vectorSource.forEachFeature((feature) => {
        if (`${feature.getId()}` === componentId) {
          selectedComponent = feature;
        }
      });
      if (!selectedComponent) {
        return false;
      }
      let geojson_geom = new GeoJSON();
      let drawGeometry = selectedComponent.getGeometry().clone();
      let drawCoordinates = geojson_geom.writeGeometry(
        drawGeometry.transform('EPSG:3857', 'EPSG:4326')
      );
      let drawnPolygon = JSON.parse(drawCoordinates);
      let operationFeature = turf.feature(drawnPolygon);
      let poly;
      let translatedPoly;
      let coordinatesPolygon = [];
      switch (selectedComponent.getGeometry().getType()) {
        case MAP_GEOMETRY_TYPE_LINE:
          poly = turf.lineString(
            _get(operationFeature, 'geometry.coordinates', [])
          );
          switch (direction) {
            case 'left':
              translatedPoly = turf.transformTranslate(poly, distance, 270, {
                units: type,
              });
              break;
            case 'right':
              translatedPoly = turf.transformTranslate(poly, distance, 90, {
                units: type,
              });
              break;
            case 'top':
              translatedPoly = turf.transformTranslate(poly, distance, 0, {
                units: type,
              });
              break;
            case 'bottom':
              translatedPoly = turf.transformTranslate(poly, distance, 180, {
                units: type,
              });
              break;
            default:
              break;
          }
          for (
            let i = 0;
            i < _get(translatedPoly, 'geometry.coordinates', []).length;
            i++
          ) {
            let pointTransform = transform(
              _get(translatedPoly, 'geometry.coordinates', [])[i],
              'EPSG:4326',
              'EPSG:3857'
            );
            coordinatesPolygon.push(pointTransform);
          }
          selectedComponent.getGeometry().setCoordinates(coordinatesPolygon);
          break;
        case MAP_GEOMETRY_TYPE_POINT:
          poly = turf.point(_get(operationFeature, 'geometry.coordinates', []));
          switch (direction) {
            case 'left':
              translatedPoly = turf.transformTranslate(poly, 8, 270, {
                units: type,
              });
              break;
            case 'right':
              translatedPoly = turf.transformTranslate(poly, 8, 90, {
                units: type,
              });
              break;
            case 'top':
              translatedPoly = turf.transformTranslate(poly, 8, 0, {
                units: type,
              });
              break;
            case 'bottom':
              translatedPoly = turf.transformTranslate(poly, 8, 180, {
                units: type,
              });
              break;
            default:
              break;
          }
          let pointTransform = transform(
            _get(translatedPoly, 'geometry.coordinates', []),
            'EPSG:4326',
            'EPSG:3857'
          );
          selectedComponent.getGeometry().setCoordinates(pointTransform);
          break;
        case MAP_GEOMETRY_TYPE_POLYGON:
          poly = turf.polygon([
            _get(operationFeature, 'geometry.coordinates', [])[0],
          ]);
          switch (direction) {
            case 'left':
              translatedPoly = turf.transformTranslate(poly, 8, 270, {
                units: type,
              });
              break;
            case 'right':
              translatedPoly = turf.transformTranslate(poly, 8, 90, {
                units: type,
              });
              break;
            case 'top':
              translatedPoly = turf.transformTranslate(poly, 8, 0, {
                units: type,
              });
              break;
            case 'bottom':
              translatedPoly = turf.transformTranslate(poly, 8, 180, {
                units: type,
              });
              break;
            default:
              break;
          }
          for (
            let i = 0;
            i < _get(translatedPoly, 'geometry.coordinates', [])[0].length;
            i++
          ) {
            let pointTransform = transform(
              _get(translatedPoly, 'geometry.coordinates', [])[0][i],
              'EPSG:4326',
              'EPSG:3857'
            );
            coordinatesPolygon.push(pointTransform);
          }
          selectedComponent.getGeometry().setCoordinates([coordinatesPolygon]);
          break;
        default:
          break;
      }
      ///change projection of feature
      let geoJSON_geom = new GeoJSON();
      let shiftNewGeometry = selectedComponent.getGeometry().clone();
      let shiftNewCoordinates = geoJSON_geom.writeGeometry(
        shiftNewGeometry.transform('EPSG:3857', 'EPSG:4326')
      );
      let shiftNewPolygon = JSON.parse(shiftNewCoordinates);
      let reqFeature = turf.feature(shiftNewPolygon);
      let properties = selectedComponent.getProperties();
      let reqData = {
        geoJson: reqFeature,
      };

      that.layerFeatureNavigation(
        properties.actualComponentId,
        { ...reqData, layerId: layerId },
        layerId
      );
    } else {
      that.layerORMapNavigation(direction);
    }
  };

  layerFeatureNavigation = (properties, reqData, layerId) => {
    let that = this;
    that.componentUpdatedEvent(properties, reqData, layerId);
  };

  getUnit = (addArea, layerData, layerComponents) => {
    const featureType = layerData?.featureType;
    let value = '';
    let unit = '';

    if (featureType === FeatureType.POINT) {
      unit = 'N';
      value = layerComponents[layerData.layerId]
        ? layerComponents[layerData.layerId].length
        : 0;
    }
    if (featureType === FeatureType.LINE) {
      unit = 'ft';
      value = amountFormat(addArea.toFixed(2));
    }
    if (featureType === FeatureType.PATH) {
      unit = 'ft';
      value = amountFormat(addArea.toFixed(2));
    }
    if (featureType === FeatureType.POLYGON) {
      unit = 'sq ft';
      value = amountFormat(addArea.toFixed(2));
    }

    return (
      <>
        {value}&nbsp;{unit}
      </>
    );
  };

  loadFeatures = (loadLayers = true) => {
    let that = this;

    that.setState({ loading: true });
    axiosInstance
      .get(API_ENDPOINTS.FEATURES, {
        params: {
          maintainer: MAINTAINER,
          status: FEATURE_STATUS,
          sendUserDetails: true,
        },
      })
      .then((res) => {
        const data = _get(res, 'data.data');
        that.props.featureList(res.data);
        let featureObject = {};
        data.forEach(function (feature) {
          featureObject[feature.name] = feature;
        });
        if (that.props.selectedView && loadLayers) {
          that.setState(
            {
              featureType: featureObject,
              loading: false,
              layersData: [],
              layerComponents: {},
            },
            function () {
              const { viewId } = this.props.selectedView;
              that.loadLayers(viewId);
            }
          );
        } else
          that.setState({
            featureType: featureObject,
            loading: false,
          });
      })
      .catch((error) => {
        that.setState({ loading: false });
      });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { updatedLayers } = this.props;
    const { treeCheckedKeys } = this.state;
    if (this.props.isNearmapDebugEnabled) {
      this.props.mapRef.addLayer(createTileDebugLayer());
    } else {
      removeTileDebugLayer(this.props.mapRef);
    }

    // fixed bug only calling this in case of treeCheckedKeys length changes to avoid maximum depth error
    if (
      this.props.updateLayerSelection &&
      treeCheckedKeys.length !== prevState.treeCheckedKeys.length
    ) {
      // Call the function only if it exists in props
      this.props.updateLayerSelection(this.state.treeCheckedKeys);
    }

    if (updatedLayers !== prevProps.updatedLayers && updatedLayers !== null) {
      const filteredKeys = treeCheckedKeys.filter((treeKey) => {
        if (typeof treeKey === 'string' && treeKey.includes('-')) {
          const keyParts = treeKey.split('-');
          const parsedKey = parseInt(keyParts[0], 10);
          return (
            updatedLayers.includes(treeKey) || updatedLayers.includes(parsedKey)
          );
        } else if (typeof treeKey === 'string') {
          const parsedKey = parseInt(treeKey, 10);
          return (
            updatedLayers.includes(treeKey) || updatedLayers.includes(parsedKey)
          );
        } else {
          return updatedLayers.includes(treeKey);
        }
      });

      this.setState({ treeCheckedKeys: filteredKeys });
    }

    let that = this;
    const { selectedNodeKeys, layersData } = that.state;
    if (that.props !== prevProps) {
      let fixedValue = 0;
      const { treeCheckedName, treeCheckedStyle } = this.state;
      if (
        that.props.expendedKeys &&
        that.props.expendedKeys !== prevProps.expendedKeys
      ) {
        fixedValue = calculateAreaWithLayerId(
          that.state.layerComponents[parseInt(that.props.expendedKeys.layerId)]
        );
        let chosenLayer = layersData.find((obj) => {
          return obj.layerId == that.props.expendedKeys.layerId;
        });
        this.props.setCurrentLayer(chosenLayer);
        that.setState(
          {
            fixedValue,
            expendedKeys: `${that.props.expendedKeys.layerId}`,
            selectedLayer: {
              id: `${that.props.expendedKeys.layerId}`,
              name: that.props.expendedKeys.name,
              featureType: chosenLayer?.featureType,
              featureId: chosenLayer?.featureId,
            },
            expendLayer: {
              area: that.props.expendedKeys.Area,
              areaUnit: that.props.expendedKeys.unit,
              name: that.props.expendedKeys.name,
            },
          },
          function () {
            that.props.setEditingRedux(true);
          }
        );
      }

      // Checking Multi-selected keys and highlighting them on layer panel
      let selectedKeys = [];
      if (
        that.props.multiData &&
        Object.keys(that.props.multiData)?.length > 0
      ) {
        let layersKeys = Object.keys(that.props.multiData)[0].split('&&');
        that.props.multiData[Object.keys(that.props.multiData)[0]].forEach(
          function (item) {
            selectedKeys.push(`${layersKeys[1]}-${item.id}`);
          }
        );
        that.setState(function (prevState) {
          return { selectedNodeKeys: selectedKeys };
        });
      } else {
        that.setState(function (prevState) {
          return { selectedNodeKeys: selectedKeys };
        });
      }
      if (!that.props.openFPanel) {
        that.setState({ isAllowedCal: false });
      } else {
        that.setState({ isAllowedCal: true });
      }
      if (that.props.isSnap !== prevProps.isSnap) {
        if (that.props.isSnap) {
          that.props.downloadMapImage(treeCheckedName, treeCheckedStyle);
        }
      }

      if (that.props.isShared && that.state.selectedLayer) {
        let sourceLayer = that.state.layerRefs[that.state.selectedLayer.id];
        const { featureListInfo } = that.props;
        const features = featureListInfo.data;
        const featureType = features
          .filter(
            (item) => item.featureId === that.state.selectedLayer.featureId
          )
          .map((item) => item.type)[0];

        multiSelectTool(
          this.props.mapRef,
          sourceLayer,
          that.state.selectedLayer,
          this.multiSelectCallBack,
          this.findLayer(that.state.selectedLayer.id),
          featureType
        );
      }
    }
    if (that.state.expendedKeys !== prevState.expendedKeys) {
      //commented so thatlayer retains colour after it is collapsed
      // that.removeHighlightFromLayerTreeNodes();
    }

    const timeout = setTimeout(() => {
      const highlightBackground = (selectedNodeKeys) => {
        const { layersData } = this.state;
        let chosenLayer = layersData.find((obj) => {
          return obj.layerId == selectedNodeKeys[0].split('-')[0];
        });
        if (
          selectedNodeKeys &&
          selectedNodeKeys.length &&
          document.getElementById(selectedNodeKeys[0])
        ) {
          if (
            prevState.selectedNodeKeys &&
            prevState.selectedNodeKeys.length &&
            document.getElementById(prevState.selectedNodeKeys[0]) &&
            !this.props.attributeMultiPopupData
          ) {
            prevState?.selectedNodeKeys?.forEach((f) => {
              if (document.getElementById(f)) {
                let elem = document.getElementById(f);
                // that.setDOMStyle(elem, '#FFFFFF');
                let parent = elem.parentElement.parentElement.parentElement;
                that.setDOMStyle(parent, '#FFFFFF');
                // document.getElementById(f).style.backgroundColor = '#ffff';
              }
            });
          }
          selectedNodeKeys?.forEach((f) => {
            if (document.getElementById(f)) {
              let elem = document.getElementById(f);
              let parent =
                document.getElementById(f).parentElement.parentElement
                  .parentElement;

              // that.setDOMStyle(
              //   elem,
              //   chosenLayer.isEditable ? '#FDEDBB' : '#E5E7EB'
              // );
              that.setDOMStyle(
                parent,
                chosenLayer.isEditable ? '#FDEDBB' : '#E5E7EB'
              );
              // document.getElementById(f).style.backgroundColor = '#FDEEBB';
            }
          });
          if (!that.props.isClickLeftPanel) {
            document.getElementById(selectedNodeKeys[0]).scrollIntoView({
              behavior: 'smooth',
              left: 0,
              block: 'center',
            });
          }

          setTimeout(() => {
            document.getElementsByClassName('feature-track')?.[0]?.scrollBy({
              top: 0,
              behavior: 'smooth',
            });
          }, 1000);
        }
      };

      if (selectedNodeKeys?.length >= 1) {
        highlightBackground(selectedNodeKeys);
      } else {
        prevState?.selectedNodeKeys?.forEach((f) => {
          if (document.getElementById(f)) {
            let elem = document.getElementById(f);
            // that.setDOMStyle(elem, '#FFFFFF');
            let parent = elem.parentElement.parentElement.parentElement;
            that.setDOMStyle(parent, '#FFFFFF');
          }
        });
      }
    }, 200);

    if (this.state.layerComponents !== prevState.layerComponents) {
      this.props.getLayerDetails(this.state.layerComponents);
    }

    if (this.props.updateLayerSelection && this.props.snapshotV2Flow) {
      // Call the function only if it exists in props
      this.props.updateLayerSelection(treeCheckedKeys);
    }

    this.props.updateLayerRefs(this.state.layerRefs);

    if (
      (Object.keys(this.state.serviceItemsMap).length === 0 &&
        this.props.serviceItemsList.length > 0) ||
      this.props.serviceItemsList.length !== prevProps.serviceItemsList.length
    ) {
      /** Populate data for `serviceItemsMap` */
      const serviceItemsMap = {};

      for (const serviceItem of this.props.serviceItemsList) {
        serviceItemsMap[serviceItem.id] = serviceItem;
      }

      this.setState({
        serviceItemsMap: serviceItemsMap,
      });
    }
  };

  setDOMStyle = (elem, value) => {
    if (typeof elem !== 'undefined' && elem !== null) {
      elem.style.backgroundColor = value;
    }
  };

  renderAddressPin = () => {
    // Don't Render Address Pin If We Have Parcel JSON
    const { parcelJson: parcelJSON } = this.props.orderTileData;
    if (parcelJSON) {
      return;
    }

    // Can't Render Address Pin If There's No Longitude Or Latitude Or Map Reference
    if (
      !this.props.orderTileData?.longitude ||
      !this.props.orderTileData?.latitude ||
      !this.props.mapRef
    ) {
      return;
    }

    const iconCoordinates = [
      this.props.orderTileData.longitude,
      this.props.orderTileData.latitude,
    ];

    const { mapRef: olMapRef } = this.props;
    const zIndex = 99999999;

    const projectionIconCoordinates = transform(
      [...iconCoordinates],
      EPSG_4326,
      EPSG_3857
    );

    const iconMarkerFeature = new Feature({
      geometry: new Point(projectionIconCoordinates),
      name: NO_PARCEL_ADDRESS_PIN,
    });
    iconMarkerFeature.setStyle(FEATURE_STYLES.Icon);

    const iconMarkerVectorSource = new VectorSource({
      features: [iconMarkerFeature],
    });

    const iconMarkerVectorLayer = newVectorLayer(iconMarkerVectorSource);

    olMapRef.addLayer(iconMarkerVectorLayer);
    iconMarkerVectorLayer.setZIndex(zIndex);
  };

  getTreeCheckedStyle = (layer) => {
    const layerFeatureType =
      layer.featureType ??
      _get(that.props.featureType?.[_get(layer, 'name')], 'type');
    switch (layerFeatureType) {
      case 'polygon':
        return _get(layer, 'style.fillColor');
      case 'point':
        return _get(layer, 'style.symbolColor');
      default:
        return _get(layer, 'style.color');
    }
  };

  loadLayers = (viewId) => {
    removeAllMapLayers(this.props.mapRef);
    this.props.setAttributeFilterPanelVisible(false);
    this.setState({
      layerRefs: {},
    });
    let that = this;
    const { featureListInfo } = that.props;
    axiosInstance
      .get(
        replaceParams(API_ENDPOINTS.VIEW_LAYERS, {
          ':viewId': viewId,
        })
      )
      .then((res) => {
        const data = res.data;
        let layerObject = {};
        const layerDataTemp = data.data.map((item) => {
          return {
            ...item,
            unit: getLayerUnits(item, featureListInfo.data),
            style: item.style || {},
            type: item.featureType,
          };
        });
        that.setState(
          {
            layersData: layerDataTemp,
          },
          function () {
            that.props.setOrderLayers(layerDataTemp);
            that.renderAddressPin();
          }
        );
        data.data.forEach(function (layer, index) {
          layerObject[layer.name] = layer;
          that.setState(
            function (prevState) {
              return {
                treeCheckedName: [
                  ...prevState.treeCheckedName,
                  { [layer.name]: true },
                ],
                treeCheckedStyle: {
                  ...prevState.treeCheckedStyle,
                  [layer.name]: that.getTreeCheckedStyle(layer),
                },
                selectDeselectFeature: [
                  ...prevState.selectDeselectFeature,
                  {
                    name: layer.name,
                    layerId: layer.layerId,
                    isSelected: true,
                  },
                ],
              };
            },
            function () {
              that.loadLayerComponents(viewId, layer);
              that.props.selectDeselectFeatures(
                that.state.selectDeselectFeature
              );
            }
          );
        });
        that.props.setOrderFeatures({
          features: layerObject,
          layersData: layerDataTemp,
        });
        that.addParcelLayer();
      })
      .catch(catchError);
  };

  loadLayerComponents = (viewId, layer, url = null) => {
    let that = this;
    const { selectedView } = this.props;
    that.setState({ loading: true });
    axiosInstance
      .get(
        url ||
          replaceParams(
            API_ENDPOINTS.VIEW_LAYERS_COMPONENT_WITHOUT_PAGINATION,
            {
              ':viewId': viewId,
              ':layerId': layer.layerId,
              ':offset': '0',
              ':limit': process.env.REACT_APP_FEATURE_PAGINATION_LIMIT || 100,
            }
          ),
        {
          params: {
            showTopologyWarning: selectedView?.isEditable
              ? true
              : process.env.REACT_APP_FEATURE_PAGINATION_LIMIT === 'true' ||
                process.env.REACT_APP_FEATURE_PAGINATION_LIMIT === 'True',
          },
        }
      )
      .then((res) => {
        res.data.data = transformComponentProperties(res.data.data ?? []);

        const data = res.data;
        let componentsData = [];
        if (data.data.length) {
          if (_get(res, 'headers.location')) {
            that.loadLayerComponents(
              viewId,
              layer,
              _get(res, 'headers.location')
            );
          }
          data.data.forEach(function (component, index) {
            componentsData.push(
              that.prepareComponentToAddToMap(component, layer, index)
            );
          });
        }
        that.setState(
          function (prevState) {
            return {
              layerComponents: {
                ...prevState.layerComponents,
                [layer.layerId]: [
                  ..._get(prevState.layerComponents, `${layer.layerId}`, []),
                  ...componentsData,
                ],
              },
              treeCheckedKeys: [
                ...prevState.treeCheckedKeys,
                `${layer.layerId}`,
                ...componentsData.map(
                  (comp) => `${layer.layerId}-${comp.componentId}`
                ),
              ],
              layerComponentWithName: {
                ...prevState.layerComponentWithName,
                [layer.name]: [
                  ..._get(
                    prevState.layerComponentWithName,
                    `${layer.name}`,
                    []
                  ),
                  ...componentsData,
                ],
              },
              loading: false,
            };
          },
          function () {
            that.addMapLayer(layer, componentsData);
            that.setParentObjects(false);
            that.props.setOrderLayerComponents(
              that.state.layerComponentWithName
            );
            that.props.updateOrdersTopologyWarnings({
              ..._get(data, 'topologyWarning', {}),
              layerId: layer.layerId,
            });
          }
        );
      })
      .catch((error) => {
        that.setState({ loading: false });
        console.error(error);
      });
  };

  prepareComponentToAddToMap = (
    component,
    layer = {},
    indexing,
    timeStamp = moment().startOf('year')
  ) => {
    let jsonObj = {
      ...JSON.parse(component.geoJson),
    };
    jsonObj.properties = jsonObj.properties || {};
    jsonObj.properties.attributes = component.attributes || {};
    jsonObj.properties.name = layer?.name;
    jsonObj.properties.layerId = layer?.layerId;
    jsonObj.properties.actualComponentId = component.componentId;
    jsonObj.properties.componentId = `${layer?.layerId}-${component.componentId}`;
    jsonObj.properties.id = component.componentId;
    jsonObj.properties.componentName = component.name;
    jsonObj.properties.perimeter =
      component?.perimeter || component.properties?.perimeter || null;
    jsonObj.properties.serviceItemIds = component?.serviceItemIds ?? [];

    if (indexing !== null) {
      jsonObj.properties.componentIndexing = displayComponentName(indexing + 1);
    }

    const area =
      component.area ??
      jsonObj.properties.measurement ??
      Number(jsonObj.properties['Surface Area'] || 0);

    if (area) {
      jsonObj.properties.area = area;
    }

    if (jsonObj.properties.uom) {
      jsonObj.properties.unit = jsonObj.properties.uom;
    }

    return {
      ...component,
      visible: true,
      ...jsonObj,
      id: component.componentId,
      timeStamp,
      // initialize area if it doesn't have a value already
      area: component.area || Number(jsonObj.properties['Surface Area'] || 0),
    };
  };

  addMapLayer = (
    layer,
    componentsData,
    updateRef = false,
    onlyClearSource = false
  ) => {
    let that = this;
    const { featureListInfo } = that.props;
    let mapRef = this.props.mapRef;
    // firToMapExtent(mapRef, source);

    that.setState(
      function (prevState) {
        if (prevState.layerRefs[layer.layerId] && !updateRef) {
          let newSource = prevState.layerRefs[layer.layerId].getSource();
          addFeaturesToSource(newSource, componentsData);
          return {};
        } else {
          if (prevState.layerRefs[layer.layerId]) {
            mapRef.removeLayer(prevState.layerRefs[layer.layerId]);
          }
          let source,
            layerRef = null;
          const features = featureListInfo.data;
          const featureType = features
            .filter((item) => item.featureId === _get(layer, 'featureId'))
            .map((item) => item.type)[0];
          if (onlyClearSource && prevState.layerRefs[layer.layerId]) {
            source = prevState.layerRefs[layer.layerId].getSource();
            source.clear();
            addFeaturesToSource(source, componentsData);
            layerRef = prevState.layerRefs[layer.layerId];
          } else {
            source = newVectorSource(componentsData);
            layerRef = newVectorLayer(source, [
              convertJSONStyleToOlStyle(featureType, layer.style),
            ]);
          }

          if (featureType === 'path') {
            layerRef.setStyle(
              updatePathStyleFunction(source.getFeatures(), layer.style)
            );
          }
          layerRef.set('id', layer.layerId);
          switch (featureType) {
            case 'polygon':
              layerRef.setZIndex(70);
              break;
            case 'line':
              layerRef.setZIndex(80);
              break;
            case 'point':
              layerRef.setZIndex(100);
              break;
            case 'path':
              layerRef.setZIndex(90);
              break;
          }
          mapRef.addLayer(layerRef);
          return {
            layerRefs: { ...prevState.layerRefs, [layer.layerId]: layerRef },
            kmlDownload: { ...prevState.kmlDownload, [layer.name]: layerRef },
          };
        }
      },
      function () {
        that.props.kmlForLayerList(that.state.kmlDownload);
        that.props.setOrderLayerComponents({
          ...that.state.layerComponentWithName,
          [layer?.name]: componentsData,
        });
        that.updateLayerPanelCheckBox(layer, componentsData);
        // that.resetLayerStyles(layer.layerId);
      }
    );
  };

  addParcelLayer = (isHideShow) => {
    const { selectedView, configurations, orderSummary } = this.props;
    trackEvents('layerpanel__parcel-toggle', {
      type: 'show',
      orderId: selectedView?.orderId,
      viewId: selectedView?.viewId,
      isBaseView: selectedView?.isBaseView,
      isEditable: selectedView?.isEditable,
    });

    let mapRef = this.props.mapRef;
    let { parcelStyle } = this.state;
    let parcelJson = _get(this.props, 'orderTileData.parcelJson');
    let { search } = this.props.history.location;

    const layerRef = renderParcelLayer(
      mapRef,
      JSON.parse(parcelJson),
      parcelStyle,
      orderSummary,
      configurations,
      search,
      !isHideShow
    );

    this.setState(function (prevState) {
      return { layerRefs: { ...prevState.layerRefs, parcel: layerRef } };
    });
  };

  handleModalOpen = (type, value) => {
    this.setState({ [type]: value });
  };

  handleDeleteLayerModal = (value) => {
    this.handleModalOpen('isDeleteLayer', value);
  };

  onSelectLayer = (value) => {
    const { layerAreaInfo, layersData } = this.state;

    let chosenLayer = layersData.find((obj) => {
      return obj.layerId == value.layerId;
    });
    this.props.setCurrentLayer(chosenLayer);
    let expendLayer = {
      ...layerAreaInfo[value.id],
      name: value.name,
    };
    const selectedLayer = value
      ? {
          ...value,
          featureType: chosenLayer?.featureType,
          featureId: chosenLayer?.featureId,
        }
      : null;

    this.setState({
      selectedLayer: selectedLayer,
      expendLayer,
      fixedValue: expendLayer.area,
    });
    this.onEditLayer(value.layerId, value);
    this.props.updateLayerAreaInfo(this.state.layerAreaInfo);
  };

  deleteLayer = (viewId) => {
    let that = this;
    const {
      layerComponents,
      layerRefs,
      selectedLayer,
      layersData,
      kmlDownload,
      layerComponentWithName,
      treeCheckedStyle,
      treeCheckedName,
      layerAreaInfo,
      selectDeselectFeature,
    } = that.state;
    const { orderFeatures } = that.props;
    that.setState({ deleteLayerLoading: true });
    axiosInstance
      .delete(
        replaceParams(API_ENDPOINTS.VIEW_LAYER_DETAILS, {
          ':viewId': viewId,
          ':layerId': _get(selectedLayer, 'id'),
        })
      )
      .then((res) => {
        const data = _get(res, 'data');
        let layerObject = orderFeatures;
        delete layerObject[`${_get(selectedLayer, 'name')}`];
        that.setState({ deleteLayerLoading: false });
        if (_get(data, 'status') !== 400) {
          that.props.mapRef.removeLayer(
            that.state.layerRefs[_get(selectedLayer, 'id')]
          );
          let tempLayers = layerComponents;
          let tempLayerRef = layerRefs;
          let tempKmlDownload = kmlDownload;
          let tempLayerComponentWithName = layerComponentWithName;
          let tempTreeCheckedStyle = treeCheckedStyle;
          let tempLayerAreaInfo = layerAreaInfo;
          if (layerComponents) {
            delete tempLayers[selectedLayer.id];
            delete tempLayerRef[selectedLayer.id];
            delete tempKmlDownload[selectedLayer.name];
            delete tempLayerComponentWithName[selectedLayer.name];
            delete tempTreeCheckedStyle[selectedLayer.name];
            delete tempLayerAreaInfo[selectedLayer.id];
            that.props.mapRef.removeLayer(layerRefs[selectedLayer.id]);
            let tempLayerData = _filter(layersData, function (obj) {
              return obj.layerId !== parseInt(selectedLayer.id);
            });

            let tempSelectDeselectFeature = _filter(
              selectDeselectFeature,
              function (obj) {
                return obj.layerId !== parseInt(selectedLayer.id);
              }
            );

            const tempTreeCheckedName = treeCheckedName?.filter((item) => {
              return !Object.keys(item).includes(selectedLayer.name);
            });

            this.props.setCurrentLayer(null);
            that.setState(
              function (prevState) {
                return {
                  layerComponents: tempLayers,
                  layersData: tempLayerData,
                  deleteLayerLoading: false,
                  kmlDownload: tempKmlDownload,
                  layerAreaInfo: tempLayerAreaInfo,
                  layerComponentWithName: tempLayerComponentWithName,
                  layerRefs: tempLayerRef,
                  treeCheckedStyle: tempTreeCheckedStyle,
                  treeCheckedName: tempTreeCheckedName,
                  expendLayer: null,
                  selectedLayer: null, //
                  expendedKeys: '',
                  selectDeselectFeature: tempSelectDeselectFeature,
                };
              },
              function () {
                that.props.setOrderLayers(that.state.layersData);
                that.props.updateLayerAreaInfo(that.state.layerAreaInfo);
                that.props.setOrderFeatures({
                  features: layerObject,
                  layersData: that.state.layersData,
                });
                that.props.setAttributePopupData(null);
                that.props.setEditingRedux(false);
                that.setParentObjects(false);
                that.props.selectDeselectFeatures(
                  that.state.selectDeselectFeature
                );
              }
            );
          }
          showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Deleted successfully');
          this.handleModalOpen('isDeleteLayer', false);
        } else {
          NotifyError(res.data.error);
        }
      })
      .catch((error) => {
        that.setState({ deleteLayerLoading: false });
        this.handleModalOpen('isDeleteLayer', false);
        console.error(error);
      });
  };

  onEditLayer = (layerId, layer) => {
    this.treeChange(layerId, layer);
    this.props.setEditingRedux(true); //editable mode open
    this.onSelect([layerId]); // selected node
    this.setState({ expendedKeys: `${layerId}`, lastAutoSaved: null });
  };

  treeChange = (layerId, layer) => {
    const { layerRefs } = this.state;
    for (let lyr in layerRefs) {
      if (layerRefs[lyr] === layerRefs[layerId]) {
        layerRefs[layerId].setZIndex(10);
      } else {
        layerRefs[lyr].setZIndex(0);
      }
    }
  };

  setParentObjects = (isNewlayer = false) => {
    let that = this;
    const { layerComponents, layersData } = that.state;
    let { featureListInfo } = that.props;
    for (let layerObj in layerComponents) {
      let addArea = 0;
      let areaUnit = '';
      let name = '';
      let featureType = null;
      let addPerimeter = 0;

      layersData.length &&
        layersData.forEach(function (layer) {
          if (layer.layerId === +layerObj) {
            areaUnit = layer.unit;
            featureType = layer.featureType;
          }
        });
      layerComponents[layerObj].length &&
        layerComponents[layerObj].forEach(function (component) {
          addArea += getFeatureValue(component);
          name = component.properties.name;
          if (featureType?.toLowerCase() === 'polygon') {
            addPerimeter += Number(component.properties?.perimeter);
          } else {
            addPerimeter = null;
          }
        });
      that.setState(
        function (prevState) {
          const featureType = featureListInfo.data
            .filter((item) => item.featureId === layersData[0].featureId)
            .map((item) => item.type)[0];
          return {
            layerAreaInfo: {
              ...prevState.layerAreaInfo,
              [layerObj]: {
                area: addArea.toFixed(2),
                areaUnit,
                name,
                perimeter: addPerimeter,
              },
            },
            expendLayer: {
              area: isNewlayer
                ? layersData[layersData.length - 1]?.layerId
                : layersData[0]?.layerId,
              areaUnit: areaUnit,
              name: isNewlayer
                ? layersData[layersData.length - 1]?.name
                : layersData[0]?.name,
            },
          };
        },
        function () {
          const featureType = featureListInfo.data
            .filter((item) => item.featureId === layersData[0].featureId)
            .map((item) => item.type)[0];
          that.removeHighlightFromLayerTreeNodes();
          if (that.props.updateLayerAreaInfo) {
            // Call the function only if it exists in props
            that.props.updateLayerAreaInfo(that.state.layerAreaInfo);
          }

          if (layersData && isNewlayer) {
            that.highlightActiveLayerTreeNode(
              layersData[layersData.length - 1]?.layerId,
              layersData[layersData.length - 1].isEditable
            );
            that.openFeatureAttributePopup(
              layersData[layersData.length - 1],
              +that.state.layerAreaInfo[
                layersData[layersData.length - 1]?.layerId
              ]?.area,
              that.state.layerAreaInfo[
                layersData[layersData.length - 1]?.layerId
              ]?.areaUnit,
              featureType,
              +that.state.layerAreaInfo[
                layersData[layersData.length - 1]?.layerId
              ]?.perimeter,
              'autoSelectOnNewLayer'
            );
          } else {
            that.highlightActiveLayerTreeNode(
              layersData[0]?.layerId,
              layersData[0]?.isEditable
            );

            if (that.state.layerAreaInfo[layersData[0]?.layerId]) {
              that.openFeatureAttributePopup(
                layersData[0],
                +that.state.layerAreaInfo[layersData[0]?.layerId]?.area,
                that.state.layerAreaInfo[layersData[0]?.layerId]?.areaUnit,
                featureType,
                +that.state.layerAreaInfo[layersData[0]?.layerId]?.perimeter,
                'autoSelectOnLayerLoad'
              );
            }
          }
        }
      );
    }
  };

  onExpendTree = (keys, nodeData) => {
    const { expanded: isTreeExpanded } = nodeData;
    let that = this;
    const { layerAreaInfo, layersData } = that.state;

    let chosenLayer = layersData.find((obj) => {
      return obj.layerId == nodeData.node.key;
    });
    if (!isTreeExpanded) {
      // When The Tree Is Expanded, It Is Automatically Highlighted. So, We Are Just Highlighting The Currently Active (But Un-Expanded) Tree Node
      this.highlightActiveLayerTreeNode(
        nodeData?.node?.key,
        chosenLayer.isEditable
      );
    } else {
      // When Tree Is Expanded, Remove The Previously Highlighted Layers
      this.removeHighlightFromLayerTreeNodes(keys, nodeData);
    }

    this.props.setCurrentLayer(chosenLayer);
    that.expendedLayerReset(nodeData.node);
    this.clearAllMultiSelectedData();
    layersData.forEach(function (layer) {
      if (
        layer.layerId === nodeData.node.key &&
        _get(that.props.selectedView, 'isEditable') &&
        nodeData.expanded
      ) {
        that.onEditLayer(layer.layerId, layer);
      }
    });

    if (nodeData.expanded) {
      let expendLayer = {
        ...layerAreaInfo[nodeData.node.key],
        name: nodeData.node.name,
      };
      this.props.setEditingRedux(true);
      that.onSelect([keys[1]]);
      that.setState(function (prevState) {
        const layerName =
          nodeData.node.name || nodeData.node.heading || chosenLayer.name;

        const treeCheckedName = [
          ...prevState.treeCheckedName,
          { [layerName]: true },
        ];

        const treeCheckedStyle = {
          ...prevState.treeCheckedStyle,

          [layerName]: that.getTreeCheckedStyle(chosenLayer),
        };

        return {
          layerAreaInfo: {
            ...prevState.layerAreaInfo,
            [nodeData.node.key]: { ...expendLayer },
          },
          expendLayer,
          expendedKeys: nodeData.node.key.toString(),
          treeCheckedKeys: [...prevState.treeCheckedKeys, nodeData.node.key],
          treeCheckedName,
          treeCheckedStyle,
          selectedLayer: {
            id: nodeData.node.key,
            name: nodeData.node.name,
            featureType: chosenLayer?.featureType,
            featureId: chosenLayer?.featureId,
          },
          fixedValue: nodeData.node.area?.toFixed(2),
        };
      });
    } else {
      // this.props.setEditingRedux(true);
      that.setState(function (prevState) {
        return {
          expendLayer: null,
          expendedKeys: '',
          treeCheckedKeys: [...prevState.treeCheckedKeys, nodeData.node.key],
        };
      });
    }
    that.props.updateLayerAreaInfo(that.state.layerAreaInfo);
  };

  highlightActiveLayerTreeNode = (layerId, editable) => {
    const treeNodesObject = document.querySelectorAll(ANTD_TREE_NODE_SELECTOR);
    const treeNodesArr = Object.values(treeNodesObject);
    const activeTreeNode = treeNodesArr.find((node) => {
      return node.querySelector(`[id="${layerId}"]`);
    });

    if (activeTreeNode) {
      activeTreeNode.style.backgroundColor = editable
        ? ANTD_TREE_NODE_ACTIVE_COLOR
        : '#E5E7EB';
    }
  };

  removeHighlightFromLayerTreeNodes = (keys, nodeData) => {
    const treeNodesObject = document.querySelectorAll(ANTD_TREE_NODE_SELECTOR);
    const treeNodesArr = Object.values(treeNodesObject);

    treeNodesArr.forEach((treeNode) => {
      const currBackgroundColor = treeNode.style.backgroundColor;
      if (currBackgroundColor) {
        treeNode.style.backgroundColor = '';
      }
    });
  };

  expendedLayerReset = (expendedLayer) => {
    let that = this;
    const { layerRefs, layerComponents, layersData } = that.state;
    const layerName = layersData
      .filter((layer) => layer.layerId.toString() === expendedLayer?.key)
      .map((layer) => layer.name)?.[0];
    let layer = {
      layerId: expendedLayer?.key,
      name: layerName,
    };

    const selectedLayer = layersData.filter(
      (layer) => layer.layerId.toString() === expendedLayer?.key
    );
    if (layerRefs) {
      layerRefs[expendedLayer?.key]?.getSource().clear();
      let componentsForForged = [];
      let i = 0;
      layerComponents[expendedLayer?.key]?.forEach(function (component) {
        componentsForForged.push(
          that.prepareComponentToAddToMap(component, layer, i++)
        );
      });
      that.addMapLayer(layer, componentsForForged, false);
    }
  };

  setFactor = (values) => {
    const { selectedView } = this.props;
    const { selectedLayer, featureType } = this.state;
    trackEvents('layerpanel__estimation-div', {
      orderId: selectedView?.orderId,
      viewId: selectedView?.viewId,
      isBaseView: selectedView?.isBaseView,
      isEditable: selectedView?.isEditable,
      layerName: selectedLayer?.name,
      // Below two lines are incorrect logic - Ankit 09/02/2023
      featureOwner: featureType[selectedLayer?.name].maintainer,
      geometryType: featureType[selectedLayer?.name].type,
    });
    if (onlyAllowNumber(values)) {
      this.setState({
        factor: values,
      });
    }
  };

  onDeleteComponent = async (e, options, layerId) => {
    let that = this;
    e.preventDefault();
    e.stopPropagation();
    that.setState(
      { isDeleteComponent: { options, layerId } },
      async function () {
        if (saveIfAnyUnSyncedModifications()) {
          /** Wait for a few moments so any un-synced modifications would be saved */
          await new Promise((r) => setTimeout(r, 700));
        }

        that.deleteComponent(that.state.isDeleteComponent);
      }
    );
  };

  deleteComponent = (
    data,
    hideNotification = false,
    undoStackUpdateNotRequired = null
  ) => {
    let that = this;
    let timeStamp = data.timeStamp || moment();
    const { options, layerId } = data;
    const { selectedView } = that.props;
    const { selectedLayer } = that.state;
    this.setState(function (prevState) {
      return {
        componentDeleteLoading: true,
        autoSaveRequestTrack: {
          ...prevState.autoSaveRequestTrack,
          [options.componentId]: true,
        },
      };
    });

    if (
      selectedView.isBaseView &&
      that.props.srUserTypeId !== SRUserTypes.CartoAdmin
    ) {
      that.props.setShowAddNewViewAlertModal(true);
      return;
    }

    axiosInstance
      .post(
        replaceParams(BULK_UPDATE_COMPONENT, {
          ':viewId': selectedView.viewId,
          ':layerId': selectedLayer.id,
        }),
        { delete: [options.componentId] }
      )
      .then((res) => {
        that.props.setAttributeMultiPopupData(null);
        this.deleteComponents(
          layerId,
          [options.componentId],
          timeStamp,
          undoStackUpdateNotRequired
        );
        that.props.setAttributeMultiPopupData(null);
        that.props.updateOrdersTopologyWarnings({
          ..._get(res.data, 'topologyWarning', {}),
          layerId: selectedLayer.id,
        });

        if (!hideNotification)
          showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Deleted successfully');
      })
      .catch((error) => {
        console.error(error);
        this.setState(function (prevState) {
          return {
            componentDeleteLoading: false,
            autoSaveRequestTrack: {
              ...prevState.autoSaveRequestTrack,
              [options.componentId]: undefined,
            },
          };
        });
      });
  };

  deleteComponents = (
    layerId,
    componentId,
    timeStamp = moment(),
    undoStackUpdateNotRequired = null
  ) => {
    let that = this;
    const { layerRefs } = that.state;
    componentId.forEach((ids) => {
      removeFeatureByComponentId(layerRefs[layerId], ids);
    });
    let updateStackComponent = [];
    that.setState(
      function (prevState) {
        let updateComponent = [];
        if (
          prevState.layerComponents[layerId] &&
          prevState.layerComponents[layerId].length
        ) {
          let idCount = 0;
          prevState.layerComponents[layerId].map((component) => {
            if (!componentId.includes(component.componentId)) {
              idCount = idCount + 1;
              component.properties.componentIndexing = `ID${idCount}`;
              updateComponent.push(component);
            } else {
              updateStackComponent.push({
                ...component,
                timeStamp,
                type: 'deleteInteraction',
              });
            }
          });
        }
        return {
          layerComponents: {
            ...prevState.layerComponents,
            [layerId]: updateComponent,
          },
          isDeleteComponent: null,
          componentDeleteLoading: false,
          lastAutoSaved: { ...prevState.lastAutoSaved, [layerId]: timeStamp },
          autoSaveRequestTrack: {
            ...prevState.autoSaveRequestTrack,
            [componentId]: undefined,
          },
        };
      },
      function () {
        that.updateComponentsAfterDelete(layerId);
        if (!undoStackUpdateNotRequired)
          that.pushToUndoStack(timeStamp, updateStackComponent, layerId);
      }
    );
  };

  updateComponentsAfterDelete = async (layerId, forceUpdate = false) => {
    let { layerComponents, layersData, layerRefs } = this.state;
    let that = this;
    this.setState(
      function (prevState) {
        layersData.forEach(function (layer) {
          if (layerId) {
            if (
              layer.layerId === Number(layerId) ||
              layer.layerId === layerId
            ) {
              layerRefs[layerId].getSource().clear();
              let componentsForForged = [];
              let i = 0;
              layerComponents[layerId]?.forEach(function (component) {
                if (!component.hiddenOfAttributeFilter) {
                  componentsForForged.push(
                    that.prepareComponentToAddToMap(
                      component,
                      layer,
                      Number(component.properties.componentIndexing.slice(2)) -
                        1
                    )
                  );
                }
              });
              that.addMapLayer(layer, componentsForForged, forceUpdate);
            }
          } else {
            layerRefs[layer.layerId].getSource().clear();
            let componentsForForged = [];
            let i = 0;
            layerComponents[layer.layerId]?.forEach(function (component) {
              if (!component.hiddenOfAttributeFilter) {
                componentsForForged.push(
                  that.prepareComponentToAddToMap(
                    component,
                    layer,
                    Number(component.properties.componentIndexing.slice(2)) - 1
                  )
                );
              }
            });
            that.addMapLayer(layer, componentsForForged, forceUpdate);
          }
        });

        return {};
      },
      function () {
        let { activeTool } = this.props;
        if (activeTool === 'select') {
          that.resetLayerStyles(layerId, true);
        }
      }
    );
  };

  updateLayerPanelCheckBox = (layer, componentsData = []) => {
    let that = this;
    that.setState(function (prevState) {
      return {
        treeCheckedKeys: [
          ...prevState.treeCheckedKeys,
          layer.layerId,
          ...componentsData.map(
            (comp) => `${layer.layerId}-${comp.componentId}`
          ),
        ],
      };
    });
  };

  findLayer = (layerId) => {
    const { layerList } = this.props;
    let result;
    layerList &&
      layerList.length &&
      layerList.forEach((item) => {
        if (item.layerId === Number(layerId)) {
          result = item;
        }
      });
    return result;
  };

  multiSelectCallBack = (
    /* all components selected with current virtual box */
    components,
    /* active layer */
    selectedLayer,
    newComponentHighlighting = false
  ) => {
    let that = this;
    const { layerList, featureListInfo } = this.props;
    let ext = {
      ctrlKey: true,
    };
    let selectedLayerObj = {
      ...selectedLayer,
      layerId: selectedLayer.id,
    };
    // Need to find layer details to form the key
    const layer = that.findLayer(selectedLayer.id);
    const key = layer.name + '&&' + layer.layerId + '&&' + layer.unit;
    // componentsAlreadySelected contains items that are already selected on screen
    let componentsAlreadySelected = that.props.multiData || {};
    let selectedItemGeoJsonArray = componentsAlreadySelected?.[key]
      ? componentsAlreadySelected?.[key]
      : [];

    // the current selection may contain components that have already been selected previously
    // Hence this variable newlySelectedComponents keeps the list of newly selected components on that layer
    let newlySelectedComponents = [];
    for (let component of components) {
      let match = false;
      for (let selectedItem of selectedItemGeoJsonArray) {
        if (component.componentId === selectedItem.componentId) {
          match = true;
        }
      }
      if (!match) {
        newlySelectedComponents.push(component);
      }
    }
    // Finally concat the list of all selected components and push it to the store
    let finalList = [];
    if (!newComponentHighlighting) {
      finalList.push(...selectedItemGeoJsonArray, ...newlySelectedComponents);
    }
    finalList.push(...newlySelectedComponents);
    this.props.setAttributeMultiPopupData({
      [key]: finalList,
    });
    that.singleMultiPopup(finalList, selectedLayerObj, ext, true).then(() => {
      let sourceLayer = that.state.layerRefs[selectedLayer.id];
      for (let component of finalList) {
        sourceLayer &&
          clickLayerHighlight(
            sourceLayer,
            component.componentId,
            true,
            layerList,
            featureListInfo.data
          );
      }
    });
  };

  onUpdateLayerComponents = async (
    viewId,
    layerId,
    targetLayerId,
    keys,
    layerData,
    moveData
  ) => {
    let that = this;
    const { layersData } = that.state;
    let areaValue = 0;
    let perimeterValue;
    layersData.forEach((item) => {});
    let dataToSet = [];
    let chosenLayer = layersData.find((obj) => {
      return obj.layerId === targetLayerId;
    });
    this.props.setCurrentLayer(chosenLayer);
    that.setState(
      function (prevState) {
        let sourceLayerComponent = [...prevState.layerComponents[+layerId]];
        let newSourceLayerComponent = [];
        let newTargetLayerComponent = [
          ...prevState.layerComponents[targetLayerId],
        ];
        areaValue += parseFloat(prevState.layerAreaInfo[targetLayerId].area);
        perimeterValue += parseFloat(
          prevState.layerAreaInfo[targetLayerId]?.perimeter
        );
        let val;

        for (let i = 0; i < sourceLayerComponent.length; i++) {
          let foundKey = false;
          moveData.forEach((key) => {
            if (sourceLayerComponent[i].componentId === key.id) {
              sourceLayerComponent[i].properties.componentIndexing = `ID${
                newTargetLayerComponent.length + 1
              }`;
              newTargetLayerComponent.push(sourceLayerComponent[i]);
              areaValue += getFeatureValue(sourceLayerComponent[i]);
              perimeterValue += sourceLayerComponent[i].properties?.perimeter;
              const areaUnit = sourceLayerComponent[i]?.properties?.unit;
              val = layerData.name + '&&' + layerData.layerId + '&&' + areaUnit;
              key.properties.componentIndexing = `ID${newTargetLayerComponent.length}`;
              dataToSet.push(
                newTargetLayerComponent[newTargetLayerComponent.length - 1]
              );
              foundKey = true;
            }
          });
          if (!foundKey) {
            newSourceLayerComponent.push(sourceLayerComponent[i]);
          }
        }
        let newData = {
          [val]: dataToSet,
        };
        // that.props.setAttributeMultiPopupData(newData);
        return {
          layerComponents: {
            ...prevState.layerComponents,
            [+layerId]: newSourceLayerComponent,
            [targetLayerId]: newTargetLayerComponent,
          },
          // selectedNodeKeys: keys.map(key => `${targetLayerId}-${key}`),
          expendedKeys: `${targetLayerId}`,
          expendLayer: {
            ...prevState.layerAreaInfo[targetLayerId],
            name: layerData.name,
          },
          selectedLayer: {
            id: `${targetLayerId}`,
            name: layerData.name,
            featureType: layerData.featureType,
            featureId: layerData.featureId,
          },
          fixedValue: areaValue.toFixed(2),
        };
      },
      function () {
        that.updateComponentsAfterDelete(layerId, true);
        that.updateComponentsAfterDelete(targetLayerId, true);
        that.multiSelectCallBack(dataToSet, {
          id: targetLayerId,
          name: layerData.name,
        });
      }
    );
    that.props.updateLayerAreaInfo(that.state.layerAreaInfo);
  };

  renderTreeNode = (props) => {
    let that = this;
    let treeData = [];
    let { topologyWarning, featureListInfo } = that.props;

    let { layersData, layerComponents } = props;
    let visibleAttributesInLayerPanel =
      that.props.visibleAttributesInLayerPanel;

    const isAAorRF = [
      OrderStatus.AwaitingApproval,
      OrderStatus.ResolvingFeedback,
    ].includes(this.props.orderSummary.status);

    for (let i = 0; i < layersData.length; i++) {
      let children = [];
      let addArea = 0;
      let areaUnit = '';
      let areaValue = 0;
      let addPerimeter = 0;
      let perimeterValue = 0;
      let layerComponentsMapping = {};
      let status = '';
      let type = '';
      let layerTopoWarnings = topologyWarning[layersData[i].layerId] || {
        overlap: {},
        kink: {},
        duplicate: {},
        nullGeometry: {},
      };
      areaUnit = layersData[i].unit;
      if (layerComponents[layersData[i].layerId]?.length) {
        layerComponents[layersData[i].layerId].forEach(function (
          component,
          index
        ) {
          layerComponentsMapping[component.componentId] = displayComponentName(
            index + 1
          );
        });
      }
      if (layerComponents[layersData[i].layerId]) {
        if (this.state.sort !== 'false') {
          layerComponents[layersData[i].layerId].sort((a, b) => {
            return this.state.sort === 'asc'
              ? a.area - b.area
              : b.area - a.area;
          });
        } else {
          layerComponents[layersData[i].layerId].sort((a, b) => {
            return (
              Number(a.properties.componentIndexing.slice(2)) -
              Number(b.properties.componentIndexing.slice(2))
            );
          });
        }

        for (
          let j = 0;
          j < layerComponents[layersData[i].layerId].length;
          j++
        ) {
          let componentData = layerComponents[layersData[i].layerId][j];
          areaUnit = layersData[i].unit;
          status = _get(that.state.featureType[layersData[i].name], 'status');
          type =
            status !== 'closed'
              ? _get(that.state.featureType[layersData[i].name], 'type') ||
                componentData.geometry?.type
              : componentData.geometry?.type;
          areaValue =
            getComponentArea(
              _get(that.state.featureType[layersData[i].name], 'type') ||
                componentData.geometry?.type,
              componentData.properties
            ) || 0;

          perimeterValue = componentData.properties?.perimeter;

          let topoWarningString = topologyWarningStringsArray(
            layerTopoWarnings,
            layerComponentsMapping,
            componentData.componentId,
            _get(that.state.featureType[layersData[i].name], 'type')
          );
          if (
            !_get(
              layerComponents[layersData[i].layerId][j],
              'hiddenOfAttributeFilter'
            )
          ) {
            children.push(
              <TreeNode
                title={
                  <Row
                    id={`${layersData[i].layerId}-${componentData.componentId}`}
                    key={'childNode'}
                    area={areaValue}
                    heading={layersData[i].name}
                    onClick={(e) => {
                      this.props.toggleLayerLeftPanel(true);
                      this.singleMultiPopup(
                        {
                          ...componentData,
                          leftPanelSelect: true,
                          properties: {
                            ...componentData.properties,
                            componentIndexing:
                              componentData.properties.componentIndexing,
                          },
                        },
                        layersData[i],
                        e
                      );
                    }}
                  >
                    <Col style={{ width: '102px' }}>
                      <Row justify='space-between' wrap={false}>
                        <Col span={10}>
                          <Text
                            type={'p17'}
                            color={
                              layersData[i].isEditable
                                ? 'dark-gray'
                                : 'gray-shade'
                            }
                          >
                            {truncateString(
                              componentData.properties.componentIndexing,
                              10
                            )}
                          </Text>
                        </Col>
                        <Col span={14} style={{ textAlignLast: 'end' }}>
                          <Row>
                            <Col span={11}>
                              <Row
                                className='w-100 h-100 pl-2'
                                justify='center'
                                align='middle'
                              >
                                {topoWarningString.length &&
                                componentData.warningStatus !== 'ignored' &&
                                layersData[i].warningStatus !== 'ignored' ? (
                                  <Tooltip
                                    title={
                                      <ul>
                                        {topoWarningString.map((list) => (
                                          <li style={{ fontSize: 12 }}>
                                            {list}
                                          </li>
                                        ))}
                                        <Title
                                          level={5}
                                          align={'right'}
                                          style={{
                                            color: 'white',
                                            marginBottom: 0,
                                            fontWeight: 600,
                                            fontSize: 13,
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            this.ignoreTopoChecks(
                                              layersData[i].layerId,
                                              componentData.componentId
                                            );
                                            trackEvents(
                                              'layerpanel__topocheck_ignore-single',
                                              {
                                                viewId:
                                                  that.props?.selectedView
                                                    ?.viewId,
                                                orderId:
                                                  that.props?.selectedView
                                                    ?.orderId,
                                                geometryType:
                                                  that.state.featureType[
                                                    that.state?.selectedLayer
                                                      ?.name
                                                  ].type,
                                                layerName:
                                                  that.state?.selectedLayer
                                                    ?.name,
                                                featureOwner:
                                                  that.state.featureType[
                                                    that.state?.selectedLayer
                                                      ?.name
                                                  ].maintainer,
                                                isBaseView:
                                                  that.props?.isBaseView,
                                                isEditableView:
                                                  that.props?.isEditableView,
                                                errorType: topoWarningString,
                                              }
                                            );
                                          }}
                                        >
                                          Ignore
                                        </Title>
                                      </ul>
                                    }
                                  >
                                    <TopologyWarning
                                      style={{
                                        top: '5px',
                                        position: 'absolute',
                                      }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </Row>
                            </Col>
                            <Col
                              span={11}
                              onClick={(e) =>
                                this.onDeleteComponent(
                                  e,
                                  componentData,
                                  layersData[i].layerId
                                )
                              }
                            >
                              <Row
                                className='w-100 h-100 pl-1'
                                justify='center'
                                align='middle'
                              >
                                <SRUserTypeAccessGuard right={AccessRight.Edit}>
                                  {layersData[i].isEditable && (
                                    <Button
                                      type={'link'}
                                      disabled={
                                        isAAorRF ||
                                        this.state.isDeleteComponent?.options
                                          ?.componentId ===
                                          componentData.componentId
                                      }
                                      icon={
                                        <DeleteIconRed width={12} height={12} />
                                      }
                                      onClick={(e) =>
                                        this.onDeleteComponent(
                                          e,
                                          componentData,
                                          layersData[i].layerId
                                        )
                                      }
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                  )}
                                </SRUserTypeAccessGuard>
                              </Row>
                            </Col>
                            <Col span={2}>
                              <span
                                style={{
                                  borderLeft: '1px solid lightgrey',
                                  height: '31px',
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      style={{
                        width: '140px',
                        paddingRight: '19px',
                      }}
                      className='d-flex align-center justify-end layer__component'
                    >
                      <Text
                        type={'p17'}
                        color={
                          layersData[i].isEditable ? 'dark-gray' : 'gray-shade'
                        }
                        className='component__measurements'
                      >
                        {amountFormat(
                          getComponentArea(
                            _get(
                              that.state.featureType[layersData[i].name],
                              'type'
                            ) || componentData.geometry?.type,
                            componentData.properties
                          )
                        )}
                        &nbsp;{componentData.properties['unit']}
                      </Text>
                      <TextIconButton
                        icon={<CopyIcon />}
                        text='Copy'
                        type='p92'
                        variant='primary:alt'
                        onClick={(e) =>
                          this.copyMeasurementHandler(
                            e,
                            amountFormat(
                              getComponentArea(
                                _get(
                                  that.state.featureType[layersData[i].name],
                                  'type'
                                ) || componentData.geometry?.type,
                                componentData.properties
                              )
                            )
                          )
                        }
                        className='component__copy-button'
                      />
                    </Col>

                    {visibleAttributesInLayerPanel.length === 0 && (
                      <Col
                        style={{
                          width: '39px',
                          borderLeft: '1px solid lightgrey',
                        }}
                      />
                    )}
                    {visibleAttributesInLayerPanel.map((attributeId) => {
                      return (
                        <>
                          <Col>
                            <span
                              style={{
                                borderLeft: '1px solid lightgrey',
                                height: '34px',
                              }}
                            />
                          </Col>
                          <Col
                            key={attributeId}
                            className='d-flex justify-end align-center'
                            style={{
                              width: `${LAYER_PANEL_ATTRIBUTE_COL_WIDTH}px`,
                              textAlignLast: 'end',
                              paddingRight: '5px',
                            }}
                          >
                            <span className='w-100 pl-2'>
                              <MoreChip
                                type='p8'
                                listToRender={getComponentServiceItemColumnData(
                                  componentData,
                                  this.state.serviceItemsMap
                                )}
                                fallback={'No Service Items'}
                                fallbackProps={{
                                  className: 'italic',
                                  color: 'light-gray',
                                }}
                                truncateChars={13}
                              />
                            </span>
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                }
                className={layersData[i].isEditable ? '' : 'non-editable-layer'}
                key={`${layersData[i].layerId}-${componentData.componentId}`}
                parentNode={`${layersData[i].layerId}`}
                area={areaValue}
                areaUnit={areaUnit}
                parentNodeName={layersData[i].name}
                layerIndex={componentData.componentId}
                heading={layersData[i].name}
              />
            );
            addArea += areaValue;
            addPerimeter += Number(perimeterValue);
          }
        }
      }

      let topoWarningString = topologyWarningStringsArray(
        layerTopoWarnings,
        layerComponentsMapping,
        null,
        _get(that.state.featureType[layersData[i].name], 'type')
      );

      const features = featureListInfo.data;
      const featureType = features
        .filter((item) => item.featureId === _get(layersData[i], 'featureId'))
        .map((item) => item.type)[0];
      treeData.push(
        <TreeNode
          title={
            <div
              onClick={() =>
                this.openFeatureAttributePopup(
                  layersData[i],
                  addArea,
                  areaUnit,
                  featureType,
                  addPerimeter
                )
              }
              className={'parentNode layer-panel-layer-info'}
            >
              <Row
                id={`${layersData[i].layerId}`}
                onClick={() =>
                  that.onExpendTree([layersData[i].layerId], {
                    expanded: true,
                    node: {
                      key: `${layersData[i].layerId}`,
                      area: addArea,
                    },
                  })
                }
              >
                <Col style={{ width: '125px' }}>
                  <Row justify='space-between' wrap={false}>
                    <Tooltip title={layersData[i].name} placement='topLeft'>
                      <Col
                        span={layerTopoWarnings.count ? 14 : 18}
                        className='text-ellipsis pr-2'
                      >
                        <Text
                          type={'p17'}
                          color={
                            layersData[i].isEditable
                              ? 'dark-gray'
                              : 'gray-shade'
                          }
                        >
                          {layersData[i].name}
                        </Text>
                      </Col>
                    </Tooltip>
                    <Col
                      span={layerTopoWarnings.count ? 10 : 6}
                      style={{ textAlignLast: 'end' }}
                    >
                      <Row>
                        <Col span={layerTopoWarnings.count ? 11 : 0}>
                          <Row
                            align='middle'
                            justify='center'
                            className='h-100'
                          >
                            {layerTopoWarnings.count &&
                            layersData[i].warningStatus !== 'ignored' ? (
                              <Tooltip
                                title={
                                  <ul
                                    style={{
                                      paddingLeft: 20,
                                      marginBottom: 0,
                                      paddingRight: 20,
                                    }}
                                  >
                                    {topoWarningString.map((list) => (
                                      <li style={{ fontSize: 12 }}>{list}</li>
                                    ))}
                                    <Title
                                      level={5}
                                      align={'right'}
                                      style={{
                                        color: 'white',
                                        marginBottom: 0,
                                        fontWeight: 600,
                                        fontSize: 13,
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        this.ignoreTopoChecks(
                                          layersData[i].layerId
                                        );
                                        trackEvents(
                                          'layerpanel__topocheck_ignore-multiple',
                                          {
                                            viewId:
                                              that.props?.selectedView?.viewId,
                                            orderId:
                                              that.props?.selectedView?.orderId,
                                            geometryType:
                                              that.state.featureType[
                                                that.state?.selectedLayer?.name
                                              ].type,
                                            geometryCategory:
                                              that.state.featureType[
                                                that.state?.selectedLayer?.name
                                              ].category,
                                            layerName:
                                              that.state?.selectedLayer?.name,
                                            featureOwner:
                                              that.state.featureType[
                                                that.state?.selectedLayer?.name
                                              ].maintainer,
                                            isBaseView: that.props?.isBaseView,
                                            isEditableView:
                                              that.props?.isEditableView,
                                            errorType: topoWarningString,
                                            triggerType: 'layer-panel',
                                            noOfComponents:
                                              layerComponents[
                                                layersData[i].layerId
                                              ].length,
                                          }
                                        );
                                      }}
                                    >
                                      Ignore All
                                    </Title>
                                  </ul>
                                }
                              >
                                <TopologyWarning
                                  style={{
                                    position: 'absolute',
                                    top: '6px',
                                  }}
                                />
                              </Tooltip>
                            ) : null}
                          </Row>
                        </Col>

                        <Col
                          span={layerTopoWarnings.count ? 11 : 20}
                          style={
                            !layerTopoWarnings.count && { paddingLeft: '3px' }
                          }
                        >
                          <Row
                            align='middle'
                            justify='center'
                            className='h-100'
                          >
                            <EmTakeOffLayerChips
                              featureType={featureType}
                              styleObject={{
                                strokeColor: layersData[i].style?.color,
                                fillColor: layersData[i].style?.fillColor,
                                symbolColor: layersData[i].style?.symbolColor,
                                symbolType: layersData[i].style?.symbolType,
                              }}
                            />
                          </Row>
                        </Col>
                        <Col span={layerTopoWarnings.count ? 2 : 4}>
                          <span
                            style={{
                              borderLeft: '1px solid lightgrey',
                              height: '31px',
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col
                  style={{
                    width: '140px',
                    paddingRight: '19px',
                  }}
                  className='d-flex align-center justify-end layer-parent-node'
                >
                  <span id={`${layersData[i].layerId}-area`}>
                    <Text
                      type={'p17'}
                      color={
                        layersData[i].isEditable ? 'dark-gray' : 'gray-shade'
                      }
                      className='layer__measurements'
                    >
                      {this.getUnit(
                        addArea,
                        layersData[i],
                        layerComponents,
                        featureType,
                        addPerimeter
                      )}
                    </Text>

                    <TextIconButton
                      icon={<CopyIcon />}
                      text='Copy'
                      type='p92'
                      variant='primary:alt'
                      onClick={(e) =>
                        this.copyMeasurementHandler(
                          e,
                          amountFormat(addArea.toFixed(2))
                        )
                      }
                      className='layer__copy-button'
                    />
                  </span>
                </Col>

                {visibleAttributesInLayerPanel.length === 0 && (
                  <Col
                    style={{
                      width: '39px',
                      borderLeft: '1px solid lightgrey',
                    }}
                  />
                )}
                {visibleAttributesInLayerPanel.map((attributeId) => {
                  return (
                    <>
                      <Col>
                        <span
                          style={{
                            borderLeft: '1px solid lightgrey',
                            height: '34px',
                          }}
                        />
                      </Col>
                      <Col
                        key={attributeId}
                        className='d-flex align-center justify-end'
                        style={{
                          width: `${LAYER_PANEL_ATTRIBUTE_COL_WIDTH}px`,
                          textAlignLast: 'end',
                          paddingRight: '3px',
                        }}
                      >
                        <span className='w-100 pl-2'>
                          <MoreChip
                            type='p8'
                            listToRender={getLayerServiceItemColumnData(
                              layersData[i].layerId,
                              layerComponents,
                              this.state.serviceItemsMap
                            )}
                            fallback={'No Service Items'}
                            fallbackProps={{
                              className: 'italic',
                              color: 'light-gray',
                            }}
                          />
                        </span>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </div>
          }
          key={`${layersData[i].layerId}`}
          className={layersData[i].isEditable ? '' : 'non-editable-layer'}
          children={children}
          name={`${layersData[i].name}`}
          area={addArea}
          areaUnit={areaUnit}
          heading={layersData[i].name}
          parentNode={`${layersData[i].layerId}`}
        />
      );
    }
    return treeData;
  };

  ignoreTopoChecks = (layerId, componentId = null) => {
    const { selectedView } = this.props;
    const that = this;
    axiosInstance
      .patch(
        componentId
          ? replaceParams(API_ENDPOINTS.UPDATE_COMPONENT, {
              ':viewId': _get(selectedView, 'viewId'),
              ':layerId': layerId,
              ':componentId': componentId,
            })
          : replaceParams(API_ENDPOINTS.VIEW_LAYER_DETAILS, {
              ':viewId': _get(selectedView, 'viewId'),
              ':layerId': layerId,
            }),
        { warningStatus: 'ignored' }
      )
      .then(() => {
        if (componentId) {
          that.setState(function (prevState) {
            let newLayersComponentsData = [];
            prevState.layerComponents[layerId].forEach(function (component) {
              if (component.componentId === componentId) {
                newLayersComponentsData.push({
                  ...component,
                  warningStatus: 'ignored',
                });
              } else {
                newLayersComponentsData.push(component);
              }
            });
            return {
              layerComponents: {
                ...prevState.layerComponents,
                [layerId]: newLayersComponentsData,
              },
            };
          });
          that.props.removeComponentTopologyWarnings({ layerId, componentId });
        } else {
          that.setState(function (prevState) {
            let newLayersData = [];
            prevState.layersData.forEach(function (layer) {
              if (layer.layerId === layerId) {
                newLayersData.push({ ...layer, warningStatus: 'ignored' });
              } else {
                newLayersData.push(layer);
              }
            });
            return {
              layersData: [...newLayersData],
            };
          });
          that.props.updateOrdersTopologyWarnings({ layerId });
        }
      })
      .catch(catchError);
  };

  onMouseEnter = (event) => {
    let that = this;
    const { selectedLayer, layerRefs, layersData } = that.state;
    if (event.node.title.key && event.node.title.key === 'childNode') {
      let sourceLayer = layerRefs[selectedLayer.id];
      featureHoverIn(
        sourceLayer,
        event.node.layerIndex,
        that.props.mapRef,
        layersData
      );
    }
  };

  onMouseLeave = (event) => {
    let that = this;
    const { selectedLayer, layerRefs, layersData } = that.state;
    if (event.node.title.key && event.node.title.key === 'childNode') {
      let sourceLayer = layerRefs[selectedLayer.id];
      featureHoverOut(sourceLayer, that.props.popoverRef);
    }
  };

  addSumFixed = (value) => {
    this.setState({
      fixedValue: value.toFixed(2),
    });
  };

  onTreeCheck = (values, event) => {
    let that = this;
    const { selectedView } = this.props;
    const { selectedLayer, layerRefs, featureType } = that.state;
    that.masterLayerCheck(values, event.halfCheckedKeys);
    that.updateState(event);
    if (event.checked) {
      trackEvents('layerpanel__layer-toggle', {
        type: 'show',
        geometry: featureType[event?.node?.heading]?.type,
        layerName: event?.node?.heading,
        featureOwner: featureType[event?.node?.heading]?.maintainer,
        orderId: selectedView?.orderId,
        viewId: selectedView?.viewId,
        isBaseView: selectedView?.isBaseView,
        isEditable: selectedView?.isEditable,
      });
      if (
        event.node.title.props !== undefined &&
        event.node.title.props.area !== undefined
      ) {
        that.setState(
          function (prevState) {
            return {
              fixedValue:
                parseFloat(prevState.fixedValue) + event.node.title.props.area,
            };
          },
          function () {
            that.addSumFixed(that.state.fixedValue);
          }
        );
      } else {
        if (event.node.heading === _get(selectedLayer, 'name')) {
          // add cureenrarea
          that.addSumFixed(event.node.area);
        }
      }
      this.setState(function (prevState) {
        let previousCheckedKeys = [
          ...prevState.treeCheckedKeys,
          event.node.key,
        ];
        let treeCheckedName = prevState.treeCheckedName || [];
        let treeCheckedStyle = {};

        const layerName =
          event.node.name || event.node.heading || event.node.parentNodeName;

        const isAlreadyExistsInTreeCheckName = treeCheckedName.some((layer) => {
          return Object.keys(layer).includes(layerName);
        });

        if (!isAlreadyExistsInTreeCheckName) {
          treeCheckedName.push({ [layerName]: true });
        }

        treeCheckedStyle = {
          ...prevState.treeCheckedStyle,
          [event.node.name]: layerRefs[parseInt(event.node.key)]?.getStyle()[0][
            'stroke_'
          ]
            ? layerRefs[parseInt(event.node.key)]?.getStyle()[0]['stroke_'][
                'color_'
              ]
            : 'white',
        };

        if (event.node.children) {
          event.node.children.forEach(function (child) {
            previousCheckedKeys.push(child.key);
          });
          that.toggleLayerFeature(true, true, event.node.key, event.node.key);
        } else {
          that.toggleLayerFeature(
            true,
            false,
            event.node.key.split('-')[0],
            event.node.key.split('-')[1]
          );
        }
        return {
          treeCheckedKeys: values,
          treeCheckedName,
          treeCheckedStyle,
        };
      });
    } else {
      trackEvents('layerpanel__layer-toggle', {
        type: 'hide',
        geometry: featureType[event?.node?.heading]?.type,
        layerName: event?.node?.heading,
        featureOwner: featureType[event?.node?.heading]?.maintainer,
        orderId: selectedView?.orderId,
        viewId: selectedView?.viewId,
        isBaseView: selectedView?.isBaseView,
        isEditable: selectedView?.isEditable,
      });
      if (
        event.node.title.props !== undefined &&
        event.node.title.props.area !== undefined
      ) {
        that.setState(
          function (prevState) {
            return {
              fixedValue:
                parseFloat(prevState.fixedValue) - event.node.title.props.area,
            };
          },
          function () {
            that.addSumFixed(that.state.fixedValue);
          }
        );
      } else {
        if (event.node.heading === _get(selectedLayer, 'name')) {
          //add0
          that.addSumFixed(0);
        }
      }
      this.setState(function (prevState) {
        let previousCheckedKeys = [...prevState.treeCheckedKeys];
        let treeCheckedName = prevState.treeCheckedName;
        let treeCheckedStyle = prevState.treeCheckedStyle;

        const layerName =
          event.node.name || event.node.heading || event.node.parentNodeName;

        previousCheckedKeys = _filter(previousCheckedKeys, function (obj) {
          return obj.toString() !== event.node.key.toString();
        });

        if (event.node.children) {
          event.node.children.forEach(function (child) {
            previousCheckedKeys = _filter(previousCheckedKeys, function (obj) {
              return obj !== child.key;
            });
          });

          that.toggleLayerFeature(false, true, event.node.key, event.node.key);
        } else {
          that.toggleLayerFeature(
            false,
            false,
            event.node.key.split('-')[0],
            event.node.key.split('-')[1]
          );
        }

        /** After unchecking the child layer (i.e. ID1), if there is no other related layers (Other IDs in same layer) checked. We need to remove layerName from treeCheckedName and treeCheckedStyle */
        const isRelatedLayerChecked = previousCheckedKeys.some((key) => {
          const parentKey = event.node.parentNode.toString();

          return (
            key.toString().startsWith(parentKey) && key.toString() !== parentKey
          );
        });

        if (!isRelatedLayerChecked) {
          treeCheckedName = _filter(prevState.treeCheckedName, function (name) {
            return Object.keys(name)[0] !== layerName;
          });

          treeCheckedStyle = {};

          for (let styl in prevState.treeCheckedStyle) {
            if (styl !== layerName) {
              treeCheckedStyle = {
                ...treeCheckedStyle,
                [styl]: prevState.treeCheckedStyle[styl],
              };
            }
          }
        }

        return {
          treeCheckedKeys: values,
          treeCheckedName,
          treeCheckedStyle,
        };
      });
    }
  };

  showHideMapLayer = (values, isChecked) => {
    let that = this;
    const { selectedView } = this.props;
    const { selectedLayer, layerRefs, featureType } = that.state;
    // that.masterLayerCheck(values, event.halfCheckedKeys);
    // that.updateState(event);
    if (isChecked) {
      if (
        event.node.title.props !== undefined &&
        event.node.title.props.area !== undefined
      ) {
        that.setState(
          function (prevState) {
            return {
              fixedValue:
                parseFloat(prevState.fixedValue) + event.node.title.props.area,
            };
          },
          function () {
            that.addSumFixed(that.state.fixedValue);
          }
        );
      } else {
        if (event.node.heading === _get(selectedLayer, 'name')) {
          // add cureenrarea
          that.addSumFixed(event.node.area);
        }
      }
      this.setState(function (prevState) {
        let previousCheckedKeys = [
          ...prevState.treeCheckedKeys,
          event.node.key,
        ];
        let treeCheckedName = [];
        let treeCheckedStyle = {};
        if (event.node.children) {
          event.node.children.forEach(function (child) {
            previousCheckedKeys.push(child.key);
          });
          that.toggleLayerFeature(true, true, event.node.key, event.node.key);
          treeCheckedName.push(...prevState.treeCheckedName, {
            [event.node.name]: true,
          });
          treeCheckedStyle = {
            ...prevState.treeCheckedStyle,
            [event.node.name]: layerRefs[
              parseInt(event.node.key)
            ]?.getStyle()[0]['stroke_']
              ? layerRefs[parseInt(event.node.key)]?.getStyle()[0]['stroke_'][
                  'color_'
                ]
              : 'white',
          };
        } else {
          that.toggleLayerFeature(
            true,
            false,
            event.node.key.split('-')[0],
            event.node.key.split('-')[1]
          );
        }
        return {
          treeCheckedKeys: values,
          treeCheckedName,
          treeCheckedStyle,
        };
      });
    } else {
      if (
        event.node.title.props !== undefined &&
        event.node.title.props.area !== undefined
      ) {
        that.setState(
          function (prevState) {
            return {
              fixedValue:
                parseFloat(prevState.fixedValue) - event.node.title.props.area,
            };
          },
          function () {
            that.addSumFixed(that.state.fixedValue);
          }
        );
      } else {
        if (event.node.heading === _get(selectedLayer, 'name')) {
          //add0
          that.addSumFixed(0);
        }
      }
      this.setState(function (prevState) {
        let previousCheckedKeys = [...prevState.treeCheckedKeys];
        let treeCheckedName = [];
        let treeCheckedStyle = {};
        previousCheckedKeys = _filter(previousCheckedKeys, function (obj) {
          return obj !== event.node.key;
        });
        if (event.node.children) {
          event.node.children.forEach(function (child) {
            previousCheckedKeys = _filter(previousCheckedKeys, function (obj) {
              return obj !== child.key;
            });
          });
          that.toggleLayerFeature(false, true, event.node.key, event.node.key);
          treeCheckedName = _filter(prevState.treeCheckedName, function (name) {
            return Object.keys(name)[0] !== event.node.name;
          });
          for (let styl in prevState.treeCheckedStyle) {
            if (styl !== event.node.name) {
              treeCheckedStyle = {
                ...treeCheckedStyle,
                [styl]: prevState.treeCheckedStyle[styl],
              };
            }
          }
        } else {
          that.toggleLayerFeature(
            false,
            false,
            event.node.key.split('-')[0],
            event.node.key.split('-')[1]
          );
        }
        return {
          treeCheckedKeys: values,
          treeCheckedName,
          treeCheckedStyle,
        };
      });
    }
  };

  updateState = (value) => {
    let that = this;
    if (value.checked) {
      that.setState(
        function (prevState) {
          let previousSelectDeselectKeys = [...prevState.selectDeselectFeature];
          if (!value.halfCheckedKeys.length) {
            previousSelectDeselectKeys.forEach((item, index) => {
              if (item.layerId === +value?.node?.parentNode) {
                previousSelectDeselectKeys.splice(index, 1, {
                  ...item,
                  isSelected: true,
                });
              }
            });
          }
          return {
            selectDeselectFeature: previousSelectDeselectKeys,
          };
        },
        function () {
          that.props.selectDeselectFeatures(that.state.selectDeselectFeature);
        }
      );
    } else {
      that.setState(
        function (prevState) {
          let previousSelectDeselectKeys = [...prevState.selectDeselectFeature];
          if (!value.halfCheckedKeys.length) {
            previousSelectDeselectKeys.forEach((item, index) => {
              if (item.layerId === +value?.node?.parentNode) {
                previousSelectDeselectKeys.splice(index, 1, {
                  ...item,
                  isSelected: false,
                });
              }
            });
          }
          return {
            selectDeselectFeature: previousSelectDeselectKeys,
          };
        },
        function () {
          that.props.selectDeselectFeatures(that.state.selectDeselectFeature);
        }
      );
    }
  };

  resetLayerStyles = (layerId = null, allowed = false) => {
    let { layersData, layerRefs, selectedLayer } = this.state;
    let { multiData, activeTool, featureListInfo, layerList } = this.props;
    if (allowed) {
      layersData.forEach(function (layer) {
        const features = featureListInfo.data;
        const featureType = features
          .filter((item) => item.featureId === layer.featureId)
          .map((item) => item.type)[0];
        if (!layerId || layer.layerId === +layerId) {
          if (featureType === FeatureType.PATH) {
            layerRefs[layer.layerId]?.setStyle(
              updatePathStyleFunction(
                layerRefs[layer.layerId].getSource().getFeatures(),
                layer.style
              )
            );
          } else {
            setStyleToLayer(
              layerRefs[layer.layerId],
              convertJSONStyleToOlStyle(featureType, layer.style)
            );
          }
        }
      });
    } else if (multiData) {
      let selectedComponent = Object.values(multiData)[0];
      for (let component of selectedComponent) {
        layerRefs[layerId] &&
          clickLayerHighlight(
            layerRefs[layerId],
            component.componentId,
            true,
            layerList,
            featureListInfo.data
          );
      }
    } else if (
      activeTool === 'line' ||
      activeTool === 'polygon' ||
      activeTool === 'point' ||
      activeTool === 'modify'
    ) {
      const { layersData } = this.state;
      let tempLayerData = _filter(layersData, function (obj) {
        return obj.layerId === parseInt(selectedLayer.id);
      })?.[0];
      if (tempLayerData.featureType === FeatureType.POINT) {
        layerRefs[layerId]?.setStyle(
          DRAW_STYLE_MODE(
            'Point',
            layerRefs[layerId].getStyle(),
            tempLayerData?.style
          )
        );
      } else {
        const featureType =
          tempLayerData.featureType === 'point'
            ? 'Point'
            : tempLayerData.featureType === 'polygon'
            ? 'Polygon'
            : 'LineString';
        layerRefs[layerId]?.setStyle(
          DRAW_STYLE_MODE(featureType, layerRefs[layerId].getStyle())
        );
      }
    } else if (
      activeTool === 'slice' ||
      activeTool === 'cut' ||
      activeTool === 'doughnut'
    ) {
      layerRefs[layerId]?.setStyle(CUT_SPLIT_STYLE_MODE);
    } else if (activeTool === FeatureType.PATH) {
      let tempLayerData = _filter(layersData, function (obj) {
        return obj.layerId === parseInt(selectedLayer.id);
      })?.[0];
      layerRefs[layerId]?.setStyle(
        drawPathStyleFunction(
          layerRefs[layerId].getSource().getFeatures(),
          tempLayerData?.style
        )
      );
    }
  };

  updateStyle = (layerType, layerId, newStyle, key) => {
    const { selectedView } = this.props;
    let { selectedLayer, featureType } = this.state;
    trackEvents('layerpanel__style-change', {
      geometryType: featureType[selectedLayer?.name]?.type,
      layerName: selectedLayer?.name,
      featureOwner: featureType[selectedLayer?.name]?.maintainer,
      orderId: selectedView?.orderId,
      viewId: selectedView?.viewId,
      isBaseView: selectedView?.isBaseView,
      isEditable: selectedView?.isEditable,
      type: key,
    });
    let that = this;
    this.setState(
      function (prevState) {
        if (layerId === 'parcel') {
          this.props.setParcelStyle(newStyle);
          return { parcelStyle: newStyle };
        }
        let layers = [...prevState.layersData];
        layers.forEach(function (layer) {
          if (layer.layerId === layerId) {
            layer.style = newStyle;
          }
        });
        return { layerData: layers };
      },
      function (state) {
        let layerRef = that.state.layerRefs;
        if (layerType === FeatureType.PATH) {
          layerRef[layerId].setStyle(
            updatePathStyleFunction(
              layerRef[layerId].getSource().getFeatures(),
              newStyle
            )
          );
        } else {
          setStyleToLayer(
            layerRef[layerId],
            convertJSONStyleToOlStyle(layerType, newStyle)
          );
        }
        if (layerId === 'parcel') {
          that.updateParcelLayerStyleToServer(layerId, newStyle);
        } else {
          that.updateLayerStyleToServer(layerId, newStyle);
        }
      }
    );
  };

  updateLayerStyleToServer = (layerId, newStyle) => {
    const { selectedView, isLayerStyleAllowed, srUserTypeId } = this.props;
    if (srUserTypeId !== 1) {
      if (!isLayerStyleAllowed || this.props.access !== AccessRight.Edit) {
        return;
      }
    }
    axiosInstance
      .patch(
        replaceParams(API_ENDPOINTS.VIEW_LAYER_DETAILS, {
          ':viewId': _get(selectedView, 'viewId'),
          ':layerId': layerId,
        }),
        { style: { ...newStyle } }
      )
      .then((res) => {})
      .catch((error) => {
        NotifyError("Layer style couldn't be saved.", {
          api: 'update-layer-style',
          layerId: layerId,
        });
        console.error(error);
      });
  };

  updateParcelLayerStyleToServer = (layerId, newStyle) => {
    let bodyFormData = new FormData();

    if (
      !this.props.isLayerStyleAllowed ||
      this.props.access !== AccessRight.Edit
    ) {
      return true;
    }
    let orderHash = this.props.orderSummary.orderHashed;
    if (!orderHash) {
      bodyFormData.append(
        'orderHashed',
        this.props.selectedOrderList?.data?.orderHash
      );
    } else {
      bodyFormData.append('orderHashed', this.props.orderSummary.orderHashed);
    }
    bodyFormData.append(
      'style',
      JSON.stringify({ ['Parcel']: { ...newStyle } })
    );
    getAxiosInstance()
      .post('/api/order/updateParcelStyle/', bodyFormData)
      .then((res) => {})
      .catch((error) => {
        NotifyError("Parcel style couldn't be saved.", {
          api: 'update-parcel-style',
          orderHash: orderHash,
        });
        console.error(error);
      });
  };

  addOrRemoveFeatures = (layerId, isRemove) => {
    let layerComponents = this.state.layerComponents;
    let layerRef = this.state.layerRefs;
    if (isRemove) {
      layerComponents[layerId].forEach(function (feat) {
        removeFeatureByComponentId(layerRef[layerId], feat.componentId);
      });
    } else {
      layerComponents[layerId].forEach(function (feat) {
        addFeaturesToSource(layerRef[layerId].getSource(), [feat]);
      });
    }
  };

  toggleLayerFeature = (toggleOption, isLayer, layerId, toggleId) => {
    let that = this;
    if (isLayer) {
      /*When layer is toggled*/
      let layers = this.state.layersData;
      if (toggleOption) {
        layers.forEach(function (layer) {
          if (layer.layerId === Number(layerId) || layer.layerId === layerId) {
            that.addMapLayer(
              layer,
              that.state.layerComponents[layerId],
              true,
              true
            );
          }
        });
      } else {
        that.addOrRemoveFeatures(layerId, true);
        // that.props.mapRef.removeLayer(that.state.layerRefs[layerId]);
      }
    } else {
      /*When feature is toggled*/
      let layerComponents = this.state.layerComponents;
      let layerRef = this.state.layerRefs;
      if (!layerComponents[layerId]) return;
      if (toggleOption) {
        layerComponents[layerId].forEach(function (feat) {
          if (
            feat.componentId === toggleId ||
            feat.componentId === Number(toggleId)
          ) {
            addFeaturesToSource(layerRef[layerId].getSource(), [feat]);
            that.resetLayerStyles(layerId);
          }
        });
      } else {
        layerComponents[layerId].forEach(function (feat) {
          if (
            feat.componentId === toggleId ||
            feat.componentId === Number(toggleId)
          ) {
            removeFeatureByComponentId(layerRef[layerId], toggleId);
            that.resetLayerStyles(layerId);
          }
        });
      }
    }
  };

  toggleParcel = (option) => {
    let that = this;
    const { selectedView } = this.props;
    that.setState({ isParcelLayer: option });
    if (option) {
      that.addParcelLayer(true);
    } else {
      trackEvents('layerpanel__parcel-toggle', {
        type: 'hide',
        orderId: selectedView?.orderId,
        viewId: selectedView?.viewId,
        isBaseView: selectedView?.isBaseView,
        isEditable: selectedView?.isEditable,
      });
      that.props.mapRef.removeLayer(that.state.layerRefs['parcel']);
    }
  };

  onSelect = (selectedNodeKeys) => {
    // this.setState({ selectedNodeKeys });
  };

  onSetSelectedNode = (selectedNodeKeys) => {
    this.setState({ selectedNodeKeys });
  };

  handleLayerComponents = (layerId, newFeature) => {
    this.setState(function (prevState) {
      let componentsData = [];
      prevState.layerComponents[layerId].forEach(function (component) {
        componentsData.push({
          ...component,
        });
      });
      let lastObjID = componentsData[componentsData.length - 1];
      componentsData.push({
        visible: true,
        ...newFeature,
        layerIndex: _get(lastObjID, 'layerIndex') + 1,
        name: `ID${_get(lastObjID, 'layerIndex') + 1}`,
        componentId: _get(lastObjID, 'componentId') + 1,
      });
      return {
        layerComponents: {
          ...prevState.layerComponents,
          [layerId]: componentsData,
        },
        treeCheckedKeys: [
          ...prevState.treeCheckedKeys,
          layerId,
          ...componentsData.map((comp) => `${layerId}-${comp.componentId}`),
        ],
      };
    });
  };

  componentUpdatedEvent = async (
    componentId = null,
    updatedComponentObject,
    layerId,
    undoStackUpdateNotRequired = null,
    timeStamp = moment(),
    updateWithoutDelay = false
  ) => {
    /***
     * Use Only when component's geojson or geometry is updated.
     * ***/

    let arrayOfComponentObjects = [];
    if (!Array.isArray(updatedComponentObject) && updatedComponentObject) {
      arrayOfComponentObjects.push({
        ...updatedComponentObject,
        componentId,
        undoStackUpdateNotRequired,
      });
    } else {
      arrayOfComponentObjects = updatedComponentObject;
    }

    return new Promise((resolve) => {
      let that = this;
      that.setState(
        function (prevState) {
          let componentsForStorage = {};
          let newAreaValue =
            prevState.layerAreaInfo[prevState.selectedLayer.id]?.area;
          arrayOfComponentObjects.forEach(function (componentObject) {
            let componentIdForStorage =
              componentObject.componentId || `temp_${Math.random()}`;
            newAreaValue += getFeatureValue(componentObject.geoJson);

            componentsForStorage[componentIdForStorage] = {
              timeStamp,
              ...componentObject,
              geoJson: JSON.stringify({
                ...componentObject.geoJson,
                properties: generatePropertiesFromFeature(
                  componentObject.geoJson,
                  { ..._get(componentObject.geoJson, 'properties') }
                ),
              }),
              tempId: componentIdForStorage,
              undoStackUpdateNotRequired:
                componentObject.undoStackUpdateNotRequired ||
                undoStackUpdateNotRequired,
            };
          });
          return {
            tempComponentStorageForAutoSave: {
              ...prevState.tempComponentStorageForAutoSave,
              [layerId]: {
                ...prevState.tempComponentStorageForAutoSave[layerId],
                ...componentsForStorage,
              },
            },
            layerAreaInfo: {
              ...prevState.layerAreaInfo,
              [prevState.selectedLayer.id]: {
                ...prevState.layerAreaInfo[prevState.selectedLayer.id],
                area: newAreaValue,
              },
            },
            fixedValue: newAreaValue,
            expendLayer: {
              ...prevState.layerAreaInfo[prevState.selectedLayer.id],
              area: newAreaValue,
            },
          };
        },
        async function () {
          // let tempObjectsCount = Object.keys(that.state.tempComponentStorageForAutoSave);
          that.props.updateLayerAreaInfo(that.state.layerAreaInfo);
          if (!updateWithoutDelay) {
            await that.syncUpdatedComponentToServers();
          } else {
            await that.forcedSyncUpdatedComponentToServers();
          }

          resolve();
        }
      );
    });
  };

  syncUpdatedComponentToServers = async () => {
    /**8 This is the debounced method that will only be served when there is no wait for it.
     * Call the given forcedSyncUpdatedComponentToServers method directly if the server sync is required at once.
     */
    return this.forcedSyncUpdatedComponentToServers();
  };

  forcedSyncUpdatedComponentToServers = async () => {
    let that = this;
    const { selectedView } = that.props;
    const { selectedLayer } = that.state;
    let componentsForSave = {};

    return new Promise((resolve, reject) => {
      that.setState(
        function (prevState) {
          componentsForSave = prevState.tempComponentStorageForAutoSave;
          return { tempComponentStorageForAutoSave: {} };
        },
        function () {
          Object.keys(componentsForSave).forEach(function (layerId) {
            if (!Object.keys(componentsForSave[layerId]).length) return;
            let reqData = {
              undelete: [],
              delete: [],
              post: [],
              put: [],
            };
            let reqDataMap = {
              put: {},
              delete: {},
              undelete: {},
            };
            Object.keys(componentsForSave[layerId]).forEach(function (key) {
              if (
                componentsForSave[layerId][key]['type'] === 'deleteInteraction'
              ) {
                reqData.delete.push(key);
                reqDataMap.delete[key] = componentsForSave[layerId][key];
              } else if (
                componentsForSave[layerId][key]['type'] ===
                'unDeleteInteraction'
              ) {
                reqData.undelete.push(key);
                reqDataMap.undelete[key] = componentsForSave[layerId][key];
              } else if (key.split('_')[0] == 'temp') {
                reqData.post.push({ ...componentsForSave[layerId][key] });
              } else {
                reqData.put.push({
                  geoJson: componentsForSave[layerId][key].geoJson,
                  componentId: key,
                });
                reqDataMap.put[key] = componentsForSave[layerId][key];
              }
            });
            getAxiosInstance()
              .post(
                replaceParams(BULK_UPDATE_COMPONENT, {
                  ':viewId': selectedView.viewId,
                  ':layerId': layerId,
                }),
                reqData
              )
              .then((res) => {
                DrawSegment(
                  reqData.post,
                  selectedLayer,
                  _get(that.state.featureType[selectedLayer?.name], 'type')
                );
                that.setState(
                  function (prevState) {
                    let layerComponents = [
                      ...prevState.layerComponents[layerId],
                    ];
                    let updatedLayerComponents = [
                      ...prevState.layerComponents[layerId],
                    ];
                    let undoStackItems = [];
                    if (reqData.delete.length) {
                      layerComponents = updatedLayerComponents;
                      updatedLayerComponents = [];
                      layerComponents.forEach(function (layerComponent) {
                        if (!reqDataMap.delete[layerComponent.componentId]) {
                          updatedLayerComponents.push(layerComponent);
                        }
                        if (
                          reqDataMap.delete[layerComponent.componentId] &&
                          !reqDataMap.delete[layerComponent.componentId]
                            .undoStackUpdateNotRequired
                        )
                          undoStackItems.push({
                            ...layerComponent,
                            ...reqDataMap.delete[layerComponent.componentId],
                            layerId: layerId,
                            componentId: layerComponent.componentId,
                            geoJson:
                              reqDataMap.delete[layerComponent.componentId]
                                .geoJson,
                            ...JSON.parse(
                              reqDataMap.delete[layerComponent.componentId]
                                .geoJson
                            ),
                            type: 'deleteInteraction',
                          });
                      });
                    }
                    if (reqData.undelete.length) {
                      reqData.undelete.forEach(function (componentId) {
                        updatedLayerComponents.push(
                          that.prepareComponentToAddToMap(
                            {
                              geoJson: reqDataMap.undelete[componentId].geoJson,
                              ...JSON.parse(
                                reqDataMap.undelete[componentId].geoJson
                              ),
                              componentId: componentId,
                            },
                            {
                              layerId: selectedLayer?.id,
                              name: selectedLayer?.name,
                            },
                            null
                          )
                        );
                        if (
                          reqDataMap.undelete[componentId] &&
                          !reqDataMap.undelete[componentId]
                            .undoStackUpdateNotRequired
                        ) {
                          undoStackItems.push({
                            ...reqDataMap.undelete[componentId],
                            layerId: layerId,
                            componentId: componentId,
                            geoJson: reqDataMap.undelete[componentId].geoJson,
                            ...JSON.parse(
                              reqDataMap.undelete[componentId].geoJson
                            ),
                            type: 'drawInteraction',
                          });
                        }
                      });
                    }
                    if (reqData.put.length) {
                      /*** Case of Update
                       */
                      layerComponents = updatedLayerComponents;
                      updatedLayerComponents = [];
                      layerComponents.forEach(function (layerComponent) {
                        if (reqDataMap.put[layerComponent.componentId]) {
                          updatedLayerComponents.push(
                            that.prepareComponentToAddToMap(
                              {
                                geoJson:
                                  reqDataMap.put[layerComponent.componentId]
                                    .geoJson,
                                ...JSON.parse(
                                  reqDataMap.put[layerComponent.componentId]
                                    .geoJson
                                ),
                                componentId: layerComponent.componentId,
                                name: layerComponent.name,
                              },
                              {
                                layerId: selectedLayer?.id,
                                name: selectedLayer?.name,
                              },
                              null
                            )
                          );
                          if (
                            !reqDataMap.put[layerComponent.componentId]
                              ?.undoStackUpdateNotRequired
                          )
                            undoStackItems.push({
                              ...reqDataMap.put[layerComponent.componentId],
                              prev: { ...layerComponent },
                              new: {
                                ...layerComponent,
                                layerId: layerId,
                                componentId: layerComponent.componentId,
                                geoJson:
                                  reqDataMap.put[layerComponent.componentId]
                                    .geoJson,
                                ...JSON.parse(
                                  reqDataMap.put[layerComponent.componentId]
                                    .geoJson
                                ),
                              },
                              type: 'modifyInteraction',
                            });
                        } else {
                          updatedLayerComponents.push(layerComponent);
                        }
                      });
                    }
                    if (reqData.post.length) {
                      /*** Case of New Features
                       */
                      let newComponentIdDict = {};
                      res.data.post.forEach(function (item) {
                        newComponentIdDict[item.tempId] = item.componentId;
                      });
                      const serviceItemIds = res.data.serviceItemIds ?? [];
                      reqData.post.forEach(function (component) {
                        updatedLayerComponents.push(
                          that.prepareComponentToAddToMap(
                            {
                              ...component,
                              ...JSON.parse(component.geoJson),
                              componentId: newComponentIdDict[component.tempId],
                              serviceItemIds,
                            },
                            {
                              layerId: selectedLayer?.id,
                              name: selectedLayer?.name,
                            },
                            null
                          )
                        );
                        if (!component.undoStackUpdateNotRequired)
                          undoStackItems.push({
                            ...component,
                            componentId: newComponentIdDict[component.tempId],
                            type: 'drawInteraction',
                          });
                      });
                    }
                    that.pushToUndoStack(null, undoStackItems, layerId);
                    updatedLayerComponents.forEach((lc, index) => {
                      lc.properties.componentIndexing = displayComponentName(
                        index + 1
                      );
                    });
                    return {
                      layerComponents: {
                        ...prevState.layerComponents,
                        [layerId]: updatedLayerComponents,
                      },
                      lastAutoSaved: {
                        ...prevState.lastAutoSaved,
                        [layerId]: moment(),
                      },
                    };
                  },
                  function () {
                    that.updateComponentsAfterDelete(layerId);
                    that.props.updateOrdersTopologyWarnings({
                      ..._get(res.data, 'topologyWarning', {}),
                      layerId: layerId,
                    });
                  }
                );

                that.props.modifySyncState(ModifySyncState.SYNCED);
                that.props.setModifyDraftEvents([]);
                resolve();
              })
              .catch((error) => {
                that.setState(function (prevState) {
                  return {
                    tempComponentStorageForAutoSave: {
                      ...prevState.tempComponentStorageForAutoSave,
                      [layerId]: componentsForSave[layerId],
                    },
                  };
                });
                that.props.modifySyncState(ModifySyncState.UNSYNCED);
                reject(error);
              });
          });
        }
      );
    });
  };

  pushToUndoStack = (timeObj = moment(), action, layerId) => {
    this.setState(function (prevState) {
      if (Array.isArray(action)) {
        if (action.length === 0) {
          return {};
        }
        let undoStack = prevState.undoStack[layerId]
          ? { ...prevState.undoStack[layerId] }
          : {};
        action.forEach(function (actionItem) {
          if (undoStack[moment(actionItem.timeStamp || timeObj).format('x')]) {
            undoStack = {
              ...undoStack,
              [moment(actionItem.timeStamp || timeObj).format('x')]: [
                ..._get(
                  undoStack,
                  `${moment(actionItem.timeStamp || timeObj).format('x')}`,
                  []
                ),
                { action: actionItem, layerId: layerId },
              ],
            };
          } else {
            undoStack = {
              ...undoStack,
              [moment(actionItem.timeStamp || timeObj).format('x')]: [
                { action: actionItem, layerId: layerId },
              ],
            };
          }
        });
        return {
          undoStack: {
            ...prevState.undoStack,
            [layerId]: {
              ...prevState.undoStack[layerId],
              ...undoStack,
            },
          },
          redoStack: { ...prevState.redoStack, [layerId]: {} },
        };
      }
      if (
        _get(
          prevState.undoStack[layerId],
          `${moment(action.timeStamp || timeObj).format('x')}`
        )
      ) {
        return {
          undoStack: {
            ...prevState.undoStack,
            [layerId]: {
              ...prevState.undoStack[layerId],
              [moment(action.timeStamp || timeObj).format('x')]: [
                ..._get(
                  prevState.undoStack[layerId],
                  `${moment(action.timeStamp || timeObj).format('x')}`
                ),
                {
                  action,
                  layerId: layerId,
                },
              ],
            },
          },
          redoStack: { ...prevState.redoStack, [layerId]: {} },
        };
      } else {
        return {
          undoStack: {
            ...prevState.undoStack,
            [layerId]: {
              ...prevState.undoStack[layerId],
              [moment(action.timeStamp || timeObj).format('x')]: [
                { action, layerId: layerId },
              ],
            },
          },
          redoStack: { ...prevState.redoStack, [layerId]: {} },
        };
      }
    });
  };

  clearUndoStack = (layerId) => {
    this.setState(function (prevState) {
      return {
        undoStack: { ...prevState.undoStack, [layerId]: {} },
        redoStack: { ...prevState.redoStack, [layerId]: {} },
      };
    });
  };

  popFromUndoStack = async (layerId) => {
    return new Promise(async (resolve) => {
      let that = this;
      let timeStamp = moment();
      let undoStackTimeKeys = Object.keys(
        _get(this.state.undoStack, `${[layerId]}`, {})
      );

      let undoStack = this.state.undoStack;

      this.setState({
        stackOperationLoading: true,
      });

      this.setState(
        function (prevState) {
          if (undoStackTimeKeys.length === 0) {
            NotifyError('History not available for undo');
            return {
              stackOperationLoading: false,
            };
          } else {
            let poppedUndoStack = {};
            let valuesForRedoStack = [];

            for (let index = 0; index < undoStackTimeKeys.length; index++) {
              const undoStackTimeObj = undoStackTimeKeys[index];

              if (index !== undoStackTimeKeys.length - 1) {
                poppedUndoStack[undoStackTimeObj] =
                  prevState.undoStack[layerId][undoStackTimeObj];
              } else {
                valuesForRedoStack =
                  prevState.undoStack[layerId][undoStackTimeObj];
              }
            }

            return {
              undoStack: {
                ...prevState.undoStack,
                [layerId]: poppedUndoStack,
              },
              stackOperationLoading: false,
              redoStack: {
                ...prevState.redoStack,
                [layerId]: {
                  ...prevState.redoStack[layerId],
                  [timeStamp.format('x')]: valuesForRedoStack,
                },
              },
            };
          }
        },
        async () => {
          if (undoStackTimeKeys.length > 0) {
            for (let index = 0; index < undoStackTimeKeys.length; index++) {
              const undoStackTimeObj = undoStackTimeKeys[index];

              if (index === undoStackTimeKeys.length - 1) {
                // Popping the last update according to timestamp keys
                await that.performUndoOperations(
                  undoStack[layerId][undoStackTimeObj],
                  timeStamp,
                  layerId
                );
              }
            }
          }

          resolve();
        }
      );
    });
  };

  performUndoOperations = async (operations, timeStamp, layerId) => {
    return new Promise(async (resolve) => {
      let that = this;
      if (operations.length) {
        let componentStackForUndo = [];
        operations.forEach(function (operation) {
          if (operation.action.type === 'drawInteraction')
            componentStackForUndo.push({
              ...operation.action,
              undoStackUpdateNotRequired: true,
              // geoJson: JSON.parse(operation.action.geoJson),
              options: { componentId: operation.action.componentId },
              timeStamp,
              layerId,
              type: 'deleteInteraction',
            });
          else if (operation.action.type === 'modifyInteraction')
            componentStackForUndo.push({
              ...operation.action.prev,
              timeStamp,
              layerId,
              undoStackUpdateNotRequired: true,
              refreshRequired: true,
              componentId: operation.action.prev.componentId,
              geoJson: JSON.parse(operation.action.prev.geoJson),
            });
          else if (operation.action.type === 'deleteInteraction')
            componentStackForUndo.push({
              ...operation.action,
              undoStackUpdateNotRequired: true,
              geoJson: JSON.parse(operation.action.geoJson),
              options: { componentId: operation.action.componentId },
              timeStamp,
              layerId,
              type: 'unDeleteInteraction',
            });
        });

        await that.componentUpdatedEvent(
          null,
          componentStackForUndo,
          layerId,
          true,
          timeStamp,
          true
        );

        resolve();
      }
    });
  };

  popFromRedoStack = (layerId) => {
    let that = this;
    let timeStamp = moment();
    this.setState(
      {
        stackOperationLoading: true,
      },
      function () {
        this.setState(function (prevState) {
          let redoStackTimeKeys = Object.keys(
            _get(prevState.redoStack, `${[layerId]}`, {})
          );
          if (redoStackTimeKeys.length === 0) {
            NotifyError('History not available for redo');
            return {
              stackOperationLoading: false,
            };
          } else {
            let poppedRedoStack = {};
            let valuesForUndoStack = [];
            redoStackTimeKeys.forEach(function (redoStackTimeObj, index) {
              if (index !== redoStackTimeKeys.length - 1) {
                poppedRedoStack[redoStackTimeObj] =
                  prevState.redoStack[layerId][redoStackTimeObj];
              } else {
                // Popping the last update according to timestamp keys
                that.performRedoOperations(
                  prevState.redoStack[layerId][redoStackTimeObj],
                  timeStamp,
                  layerId
                );
                valuesForUndoStack =
                  prevState.redoStack[layerId][redoStackTimeObj];
              }
            });
            return {
              redoStack: { ...prevState.redoStack, [layerId]: poppedRedoStack },
              stackOperationLoading: false,
              undoStack: {
                ...prevState.undoStack,
                [layerId]: {
                  ...prevState.undoStack[layerId],
                  [timeStamp.format('x')]: valuesForUndoStack,
                },
              },
            };
          }
        });
      }
    );
  };

  performRedoOperations = (operations, timeStamp, layerId) => {
    let that = this;
    if (operations.length) {
      let componentStackForRedo = [];
      operations.forEach(function (operation) {
        if (operation.action.type === 'drawInteraction')
          componentStackForRedo.push({
            ...operation.action,
            undoStackUpdateNotRequired: true,
            geoJson: JSON.parse(operation.action.geoJson),
            options: { componentId: operation.action.componentId },
            timeStamp,
            layerId,
            type: 'unDeleteInteraction',
          });
        else if (operation.action.type === 'modifyInteraction')
          componentStackForRedo.push({
            ...operation.action.new,
            undoStackUpdateNotRequired: true,
            refreshRequired: true,
            geoJson: JSON.parse(operation.action.new.geoJson),
            timeStamp,
            layerId,
            action: operation.action.new.componentId,
          });
        else if (operation.action.type === 'deleteInteraction')
          componentStackForRedo.push({
            ...operation.action,
            undoStackUpdateNotRequired: true,
            geoJson: JSON.parse(operation.action.geoJson),
            options: { componentId: operation.action.componentId },
            timeStamp,
            layerId,
            type: 'deleteInteraction',
          });
      });
      that.componentUpdatedEvent(
        null,
        componentStackForRedo,
        layerId,
        true,
        timeStamp,
        true
      );
    }
  };

  performPointUndoOperation = () => {
    if (this.state.activeToolInteractionRef) {
      this.state.activeToolInteractionRef.removeLastPoint();
      this.props.drawInteractionLastPointUndo();
    }
  };

  performPointRedoOperation = () => {
    if (
      this.state.activeToolInteractionRef &&
      this.props.drawingFeaturePointHistoryRedoStack?.length
    ) {
      this.state.activeToolInteractionRef.appendCoordinates([
        this.props.drawingFeaturePointHistoryRedoStack[
          this.props.drawingFeaturePointHistoryRedoStack.length - 1
        ],
      ]);
      this.props.drawInteractionLastPointRedo();
    }
  };

  setAddLayerObjects = (values) => {
    let that = this;
    that.addMapLayer(values, [], false);
    that.setState(function (prevState) {
      return {
        layersData: [...prevState.layersData, values],
        layerAreaInfo: {
          ...prevState.layerAreaInfo,
          [values.layerId]: { area: 0, areaUnit: '' },
        },
        layerComponents: { ...prevState.layerComponents, [values.layerId]: [] },
        treeCheckedName: [
          ...prevState.treeCheckedName,
          { [values.name]: true },
        ],
        treeCheckedStyle: {
          ...prevState.treeCheckedStyle,
          [values.name]: _get(values, 'style.color'),
        },
        treeCheckedKeys: [...prevState.treeCheckedKeys, `${values.layerId}`],
        layerComponentWithName: {
          ...prevState.layerComponentWithName,
          [values.name]: [
            ..._get(prevState.layerComponentWithName, `${values.name}`, []),
          ],
        },
      };
    });
    that.props.updateLayerAreaInfo(that.state.layerAreaInfo);
  };

  setExtraState = (value) => {
    this.setState({ ...value });
  };

  openFeatureAttributePopup = (
    val,
    area,
    areaUnit,
    type,
    perimeter,
    source
  ) => {
    if (
      source === 'autoSelectOnLayerLoad' &&
      (this.props.attributePopupData ||
        this.props.attributeSinglePopupData ||
        this.props.multiData)
    ) {
      /** When the layers are loaded, and user clicks on a layer panel -> layer even before auto selecting the initial one, we don't want to update that to initial */
      return;
    }

    this.props.setCurrentLayer(val);
    const perimeter_final =
      type?.toLowerCase() === 'polygon' ? perimeter : null;
    const featureDetails = {
      ...val,
      area,
      areaUnit,
      perimeter: perimeter_final,
      edit: () => this.onEditLayer(val.layerId, val),
      delete: () => {
        this.handleModalOpen('isDeleteLayer', true);
        this.onSelectLayer({ name: val.name, id: val.layerId });
        this.props.setAttributePopupData(null);
      },
      copy: () => {
        this.handleModalOpen('isCopyLayer', true);
        this.onSelectLayer({ name: val.name, id: val.layerId });
      },
      updateStyle: (style, key) =>
        this.updateStyle(type, val.layerId, style, key),
    };

    this.setState({
      selectedLayer: {
        //
        id: val.layerId,
        name: val.name,
        featureType: val.featureType,
        featureId: val.featureId,
      },
      fixedValue: area,
    });
    this.props.setAttributePopupData(featureDetails);
  };

  clearAllMultiSelectedData = () => {
    let that = this;
    const { layerList, featureListInfo } = this.props;
    const attributesMulti = this.props.multiData || {};
    if (Object.keys(attributesMulti).length) {
      attributesMulti[Object.keys(attributesMulti)[0]].forEach(function (
        feature
      ) {
        let split = Object.keys(attributesMulti)[0].split('&&');
        let sourceLayer = that.state.layerRefs[split[1]];
        sourceLayer &&
          clickLayerHighlight(
            sourceLayer,
            feature.componentId,
            false,
            layerList,
            featureListInfo.data
          );
      });
    }
    this.props.setAttributeMultiPopupData(null);
  };

  createSelectionMapKey = (components, layer) => {
    let areaUnit;
    if (Array.isArray(components) && components.length) {
      areaUnit = components[0]?.properties?.unit;
    } else {
      areaUnit = components?.properties?.unit;
    }
    return layer.name + '&&' + layer.layerId + '&&' + areaUnit;
  };

  /**
   *
   * @param componentData
   * @param layersData
   * @param event
   * @param onlySelectAllowed
   * @returns {Promise<void>}
   */
  singleMultiPopup = async (
    componentData,
    layersData,
    event,
    onlySelectAllowed = false
  ) => {
    if (this.props.activeTool && this.props.activeTool !== 'select') return;
    const { layerList, featureListInfo } = this.props;
    let sourceLayer = this.state.layerRefs[layersData.layerId];
    let chosenLayer = this.state.layersData.find((obj) => {
      return obj.layerId == layersData.layerId;
    });
    sourceLayer.isEditable = chosenLayer.isEditable;
    let val = this.createSelectionMapKey(componentData, layersData);
    const attributesMulti = this.props.multiData;
    let newData = attributesMulti?.[val] ? attributesMulti?.[val] : [];

    let dataToSet = [];
    if (event?.ctrlKey || event?.metaKey) {
      if (Array.isArray(componentData)) {
        if (!onlySelectAllowed) {
          dataToSet = [...newData];
        }
        for (let i = 0; componentData.length > i; i++) {
          const isExist = newData.find(
            (component) =>
              componentData[i].componentId === component.componentId
          );
          if (!isExist) {
            dataToSet = [...dataToSet, componentData[i]];
            sourceLayer &&
              clickLayerHighlight(
                sourceLayer,
                componentData[i].componentId,
                true,
                layerList,
                featureListInfo.data
              );
          } else if (!onlySelectAllowed) {
            for (let j = 0; newData.length > j; j++) {
              if (newData[j]['componentId'] !== componentData[i].componentId) {
                dataToSet.push(newData[j]);
              } else {
                sourceLayer &&
                  clickLayerHighlight(
                    sourceLayer,
                    componentData[i].componentId,
                    false,
                    layerList,
                    featureListInfo.data
                  );
              }
            }
          } else {
            dataToSet = [...newData];
          }
        }
      } else {
        const isExist = newData.find(
          (component) => componentData.componentId === component.componentId
        );
        if (!isExist) {
          dataToSet = [...newData, componentData];
          sourceLayer &&
            clickLayerHighlight(
              sourceLayer,
              componentData.componentId,
              true,
              layerList,
              featureListInfo.data
            );
        } else if (!onlySelectAllowed) {
          for (let j = 0; newData.length > j; j++) {
            if (newData[j]['componentId'] !== componentData.componentId) {
              dataToSet.push(newData[j]);
            } else {
              sourceLayer &&
                clickLayerHighlight(
                  sourceLayer,
                  componentData.componentId,
                  false,
                  layerList,
                  featureListInfo.data
                );
            }
          }
        }
      }
    } else {
      const isExist = newData.find(
        (component) => componentData.componentId === component.componentId
      );
      if (!isExist) {
        dataToSet = [componentData];
        newData.forEach(function (feature) {
          sourceLayer &&
            clickLayerHighlight(
              sourceLayer,
              feature.componentId,
              false,
              layerList,
              featureListInfo.data
            );
        });
        sourceLayer &&
          clickLayerHighlight(
            sourceLayer,
            componentData.componentId,
            true,
            layerList,
            featureListInfo.data
          );
      } else {
        if (newData.length > 1) {
          dataToSet = [componentData];
          newData.forEach(function (feature) {
            sourceLayer &&
              clickLayerHighlight(
                sourceLayer,
                feature.componentId,
                false,
                layerList,
                featureListInfo.data
              );
          });
          sourceLayer &&
            clickLayerHighlight(
              sourceLayer,
              componentData.componentId,
              true,
              layerList,
              featureListInfo.data
            );
        } else {
          this.clearAllMultiSelectedData();
          dataToSet = [];
          sourceLayer &&
            clickLayerHighlight(
              sourceLayer,
              componentData.componentId,
              false,
              layerList,
              featureListInfo.data
            );
        }
      }
    }
    newData = {
      [val]: dataToSet,
    };

    const dataWithServiceItemIds = {};

    Object.entries(newData).forEach(([key, components]) => {
      dataWithServiceItemIds[key] = components.map((component) => {
        const layerId = component.layerId ?? component.properties.layerId;

        if (component.serviceItemIds || !layerId) {
          return component;
        }

        const componentLayer = this.state.layerComponents[layerId];

        const matchingComponent = componentLayer?.find(
          (_component) =>
            Number(_component.componentId) === Number(component.componentId)
        );
        if (!matchingComponent) return component;

        return { ...component, ...matchingComponent };
      });
    });

    this.props.setAttributeMultiPopupData(dataWithServiceItemIds);
    this.props.setCurrentLayer(sourceLayer);
  };

  onDuplicateComponent = (selectedComponent) => {
    let that = this;
    const { selectedView, mapRef } = that.props;
    const { selectedLayer, featureType } = that.state;
    let offSetValue = getOffSet(mapRef);
    let reqData = {
      componentId: selectedComponent,
      targetLayerId: selectedLayer?.id,
      offsetX: offSetValue,
      offsetY: offSetValue,
    };
    let ext = {
      ctrlKey: false,
    };
    let newLayerComponents = [];
    let undoStackItems = [];
    axiosInstance
      .post(
        replaceParams(API_ENDPOINTS.COPY_COMPONENT, {
          ':viewId': selectedView?.viewId,
          ':layerId': selectedLayer?.id,
        }),
        reqData
      )
      .then((result) => {
        const { data } = result.data;
        if (data.length) {
          trackEvents('action-center__duplicate', {
            type:
              selectedComponent.length > 1 ? 'multiselect' : 'single_select',
            viewId: selectedView?.viewId,
            orderId: selectedView?.orderId,
            geometryType: featureType[selectedLayer?.name].type,
            layerName: selectedLayer?.name,
            featureOwner: featureType[selectedLayer?.name].maintainer,
            isBaseView: selectedView?.isBaseView,
            isEditable: selectedView?.isEditable,
            itemsSelected: selectedComponent.length,
          });
          that.setState(
            function (prevState) {
              data?.forEach((item) => {
                let JsonObject = {
                  ...JSON.parse(item.geoJson),
                  id: item.componentId,
                  ...item,
                };
                JsonObject.properties = {
                  ...JsonObject.properties,
                  ...item,
                  id: item.componentId,
                  componentIndexing: displayComponentName(
                    prevState.layerComponents[selectedLayer?.id].length + 1
                  ),
                };
                newLayerComponents.push(JsonObject);
                undoStackItems.push({
                  ...item,
                  componentId: item.componentId,
                  type: 'drawInteraction',
                });
              });

              return {
                layerComponents: {
                  ...prevState.layerComponents,
                  [selectedLayer?.id]: [
                    ...prevState.layerComponents[selectedLayer?.id],
                    ...newLayerComponents,
                  ],
                },
              };
            },
            function () {
              that.pushToUndoStack(moment(), undoStackItems, selectedLayer?.id);
              that.updateComponentsAfterDelete(selectedLayer?.id);
              that.props.updateOrdersTopologyWarnings({
                ..._get(result.data, 'topologyWarning', {}),
                layerId: selectedLayer?.id,
              });
              that.multiSelectCallBack(
                newLayerComponents,
                {
                  id: selectedLayer?.id,
                  name: selectedLayer?.name,
                },
                true
              );
            }
          );
        }
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Copy successfully');
      })
      .catch((error) => {});
  };

  // TODO: This Should Be A Helper Function!
  getPolygonsFromFeatures = (features = []) => {
    if (Array.isArray(features) && features.length > 0) {
      return features.map((feature) => {
        const featureProperties = { ...feature.getProperties() };
        delete featureProperties.geometry;

        const featureGeoJSONGeom = new GeoJSON();
        const featureGeometry = _get(feature, 'values_.geometry');
        const featureCoordinates = featureGeoJSONGeom.writeGeometry(
          featureGeometry.transform('EPSG:3857', 'EPSG:4326')
        );
        return turf.polygon(JSON.parse(featureCoordinates).coordinates, {
          ...featureProperties,
        });
      });
    }
    return [];
  };

  prepareGeometriesForUpdate(
    geometries = [],
    timestamp = moment(),
    interactionType = DELETE_INTERACTION
  ) {
    if (geometries && Array.isArray(geometries) && geometries.length > 0) {
      return geometries.map((geometryItem) => {
        const { geometry, properties } = geometryItem;
        return {
          componentId:
            interactionType === DRAW_INTERACTION
              ? `temp_${Math.random()}`
              : properties.actualComponentId,
          geoJson: { geometry, properties: {}, type: geometryItem.type },
          timestamp,
          type: interactionType,
        };
      });
    }
    return [];
  }

  onMergeComponents = (selectedComponents = []) => {
    if (selectedComponents.length === 0) {
      return;
    }
    const { selectedLayer } = this.state;
    const parcelLayer = this.state.layerRefs[selectedLayer.id];

    const initialParcelLayerFeatures = parcelLayer.getSource().getFeatures();
    const selectedParcelLayerFeatures = [];
    const otherPolygons = [];
    initialParcelLayerFeatures.forEach((iPLFeature) => {
      if (selectedComponents.indexOf(iPLFeature.id_) > -1) {
        selectedParcelLayerFeatures.push(iPLFeature);
      } else {
        otherPolygons.push(iPLFeature);
      }
    });
    const otherFinalPolygons = this.getPolygonsFromFeatures(otherPolygons);
    const selectedParcelLayerPolygons = this.getPolygonsFromFeatures(
      selectedParcelLayerFeatures
    );
    const result = mergePolygons(...selectedParcelLayerPolygons);

    const deletePolygons = result.delete;
    const indifferentPolygons = result.indifferent;
    const unionPolygons = result.union;
    this.props.mapRef.removeLayer(parcelLayer);
    addFeaturesToMap(
      [...unionPolygons, ...otherFinalPolygons],
      parcelLayer,
      parcelLayer.getStyle()
    );
    this.props.mapRef.addLayer(parcelLayer);
    if (
      unionPolygons.length === 0 &&
      deletePolygons.length === 0 &&
      indifferentPolygons.length === selectedParcelLayerPolygons.length
    ) {
      NotifyError(MERGE_TOOL_MESSAGE);
    }

    const timestamp = moment();
    const deletePolygonsPrepared = this.prepareGeometriesForUpdate(
      deletePolygons,
      timestamp
    );
    const unionPolygonsPrepared = this.prepareGeometriesForUpdate(
      unionPolygons,
      timestamp,
      DRAW_INTERACTION
    );
    const operationPolygons = [
      ...deletePolygonsPrepared,
      ...unionPolygonsPrepared,
    ];
    if (operationPolygons.length > 0) {
      this.componentUpdatedEvent(
        null,
        operationPolygons,
        selectedLayer.id,
        null,
        timestamp
      );
      this.props.setAttributeMultiPopupData(null);
    } else if (indifferentPolygons.length > 0) {
      this.updateComponentsAfterDelete(selectedLayer.id);
    }
  };

  /**
   *
   * Collection of components which needs to be updated with new attributes.
   * We are assuming each component with have at max one attribute as we have only service-item (multiselect) now
   * @param {MultiSelectApiAttribute[]} updatedComponents
   *
   *
   * @param {number} layerId
   *
   * Attributes which needs to be updated across complete layer
   * @param {any} [updatedLayerAttribute]
   */
  updateComponentsAfterAttributeUpdates = (
    updatedComponents,
    layerId,
    updatedLayerAttribute
  ) => {
    const componentIds = updatedComponents.map(
      (component) => component.componentId
    );

    let that = this;
    let newLayerComponents = {};

    this.setState(
      function (prevState) {
        let components = [...prevState.layerComponents[layerId]];

        components = components.map((item) => {
          const updatedComponent = updatedComponents.find(
            (component) => component.componentId === item.id
          );

          if (updatedComponent) {
            const existingAttributes = item.attributes || [];
            const { componentId, ...updatedAttributeData } = updatedComponent;
            let attributeAlreadyExists = false;

            const newAttributes =
              existingAttributes.map((attr) => {
                if (updatedComponent.attributeId !== attr.attributeId) {
                  return attr;
                }

                attributeAlreadyExists = true;

                return { ...attr, ...updatedAttributeData };
              }) || [];

            if (!attributeAlreadyExists) {
              newAttributes.push(updatedAttributeData);
            }

            return {
              ...item,
              attributes: newAttributes,
              properties: {
                ...item.properties,
                attributes: newAttributes,
              },
            };
          }

          if (updatedLayerAttribute) {
            const existingAttributes = item.attributes || [];
            let attributeAlreadyExists = false;

            const newAttributes = existingAttributes.map((attribute) => {
              if (attribute.attributeId === updatedLayerAttribute.attributeId) {
                attributeAlreadyExists = true;
                return { ...attribute, ...updatedLayerAttribute };
              }
              return attribute;
            });

            if (!attributeAlreadyExists) {
              newAttributes.push(updatedLayerAttribute);
            }

            return {
              ...item,
              attributes: newAttributes,
              properties: {
                ...item.properties,
                attributes: newAttributes,
              },
            };
          }

          return item;
        });

        newLayerComponents = {
          ...prevState.layerComponents,
          [layerId]: components,
        };

        return {
          layerComponents: newLayerComponents,
        };
      },
      function () {
        that.updateComponentsAfterDelete(layerId);

        if (that.props.multiData?.length) {
          const newMultiData = { ...that.props.multiData };

          if (newLayerComponents[layerId]) {
            Object.keys(newMultiData).forEach((key, index) => {
              if (index !== 0) return;

              newMultiData[key] = newLayerComponents[layerId].filter(
                (component) => {
                  return componentIds
                    .map((id) => Number(id))
                    .includes(Number(component.id));
                }
              );
            });
          }

          that.props.setAttributeMultiPopupData(newMultiData);
        }
      }
    );
  };

  addSingleLayerData = (newLayer) => {
    let that = this;
    newLayer.isEditable = true;
    newLayer.featureType = _get(that.state.featureType[newLayer?.name], 'type');
    const { featureListInfo, orderFeatures } = that.props;
    let layerObject = { ...orderFeatures, [newLayer.name]: newLayer };

    that.setState(
      function (prevState) {
        return {
          layersData: [
            ...prevState.layersData,
            {
              ...newLayer,
              unit: getLayerUnits(newLayer, featureListInfo.data),
              style: newLayer.style || {},
            },
          ],
          treeCheckedName: [
            ...prevState.treeCheckedName,
            { [newLayer.name]: true },
          ],
          treeCheckedStyle: {
            ...prevState.treeCheckedStyle,
            [newLayer.name]: _get(newLayer, 'style.color'),
          },
          treeCheckedKeys: [
            ...prevState.treeCheckedKeys,
            `${newLayer.layerId}`,
          ],
          layerAreaInfo: {
            ...prevState.layerAreaInfo,
            [newLayer.layerId]: {
              area: 0,
              areaUnit: getLayerUnits(newLayer, featureListInfo.data),
              name: newLayer.name,
              perimeter:
                newLayer.featureType.toLowerCase() === 'polygon' ? 0 : null,
            },
          },
          layerComponents: {
            ...prevState.layerComponents,
            [newLayer.layerId]: [
              ..._get(prevState.layerComponents, `${newLayer.layerId}`, []),
            ],
          },
          selectDeselectFeature: [
            ...prevState.selectDeselectFeature,
            {
              name: newLayer.name,
              layerId: newLayer.layerId,
              isSelected: true,
            },
          ],
          expendedKeys: `${newLayer.layerId}`,
        };
      },
      function () {
        that.props.setOrderLayers(that.state.layersData);
        that.props.setOrderFeatures({
          features: layerObject,
          layersData: that.state.layersData,
        });
        that.addMapLayer(newLayer, []);
        that.setParentObjects(true);
        that.props.selectDeselectFeatures(that.state.selectDeselectFeature);
        that.props.updateLayerAreaInfo(that.state.layerAreaInfo);
      }
    );
  };

  handleSaveAndApplyFeature = (featureId, attributes) => {
    const existingLayer = this.state.layersData?.find(
      (layer) => Number(layer.featureId) === Number(featureId)
    );

    if (!existingLayer) return;

    const data = [];

    if (existingLayer) {
      this.props.getAllServiceItems(
        selectedView?.orderId,
        this.props.user.organization_id
      );
      // attributes.forEach((attribute) => {
      //   /** Check if attribute was already added or is a new attribute */
      //   if (!existingLayer.attributeIds.includes(attribute.attributeId)) {
      //     data.push({
      //       layerId: existingLayer.layerId,
      //       attributeValue: {
      //         attributeId: attribute.attributeId,
      //         [attribute.datatype]: attribute[attribute.datatype].default,
      //       },
      //     });
      //   }
      // });

      // if (data.length) {
      //   getAxiosInstance()
      //     .post(
      //       `api/templating/v1/view/${this.props.selectedView?.viewId}/layer/attribute`,
      //       {
      //         layerAttribute: data,
      //       }
      //     )
      //     .then((result) => {
      //       this.loadFeatures();
      //       this.props.getAllAttributes();
      //       this.props.getAllAttributesWithDeleted();
      //     });
      // }
    }
  };

  globalLayerVisible = (option) => {
    let that = this;
    const { layerRefs, treeCheckedName, layersData } = that.state;
    const { selectedView } = this.props;
    let layerRef = layerRefs;
    trackEvents('layerpanel__master-layer-checked', {
      checkedLayer: option,
      orderId: selectedView?.orderId,
      viewId: selectedView?.viewId,
      isBaseView: selectedView?.isBaseView,
      isEditable: selectedView?.isEditable,
    });

    if (option) {
      const treeCheckedKeys = [];
      const treeCheckedName = [];
      const treeCheckedStyle = {};

      for (let lyr in layerRef) {
        if (lyr !== 'parcel') {
          treeCheckedKeys.push(`${layerRef[lyr].getProperties().id}`);
          that.toggleLayerFeature(option, true, lyr, null);
        }
      }

      for (let layer of layersData) {
        treeCheckedName.push({ [layer.name]: true });
        treeCheckedStyle[layer.name] = this.getTreeCheckedStyle(layer);
      }

      that.setState({
        treeCheckedKeys,
        treeCheckedName,
        treeCheckedStyle,
        masterLayerCheckState: {
          indeterminate: false,
          checked: true,
        },
      });
    } else {
      for (let lyr in layerRef) {
        if (lyr !== 'parcel') {
          that.setState({
            treeCheckedKeys: [],
            treeCheckedName: [],
            treeCheckedStyle: {},
            masterLayerCheckState: {
              indeterminate: false,
              checked: false,
            },
          });
          // that.props.mapRef.removeLayer(layerRef[lyr]);
          that.addOrRemoveFeatures(lyr, true);
        }
      }
    }
  };

  masterLayerCheck = (checkedKeys = [], halfCheckedKeys = []) => {
    const { selectedView } = this.props;
    const { treeCheckedName } = this.state;
    if (halfCheckedKeys.length) {
      this.setState({
        masterLayerCheckState: {
          indeterminate: true,
          checked: false,
        },
      });
    } else {
      let checkedKeysObject = {};
      checkedKeys.forEach(function (key) {
        checkedKeysObject[key] = true;
      });
      this.setState(function (prevState) {
        let anyLayerOn = false;
        let anyLayerOff = false;
        prevState.layersData.forEach(function (layer) {
          if (checkedKeysObject[layer.layerId]) {
            anyLayerOn = true;
          } else {
            anyLayerOff = true;
          }
        });
        return {
          masterLayerCheckState: {
            indeterminate: anyLayerOff && anyLayerOn,
            checked: !anyLayerOff,
          },
        };
      });
    }
  };

  applyAttributeFilters = (filters) => {
    let that = this;
    const { selectedView } = that.props;
    const viewId = selectedView?.viewId;
    this.setState({
      appliedFilters: filters,
    });

    let requestPayload = buildFilterRequestPayload(filters);

    axiosInstance
      .post(
        replaceParams(API_ENDPOINTS.ORDER_VIEW_ATTRIBUTE_FILTERS, {
          ':viewId': viewId,
        }),
        requestPayload
      )
      .then((result) => {
        that.props.setAttributeMultiPopupData(null);
        const dataList = result.data.data;
        let filteredLayerComponentSet = {};
        dataList.forEach(function (dataObj) {
          if (filteredLayerComponentSet[dataObj.layerId]) {
            filteredLayerComponentSet[dataObj.layerId] = {
              ...filteredLayerComponentSet[dataObj.layerId],
              [dataObj.componentId]: true,
            };
          } else {
            filteredLayerComponentSet[dataObj.layerId] = {
              [dataObj.componentId]: true,
            };
          }
        });
        that.setState(
          function (prevState) {
            let newLayerComponents = {};
            prevState.layersData.forEach(function (layer) {
              prevState?.layerComponents[layer.layerId]?.forEach(function (
                layerComponent
              ) {
                if (newLayerComponents[layer.layerId]) {
                  newLayerComponents[layer.layerId] = [
                    ...newLayerComponents[layer.layerId],
                    {
                      ...layerComponent,
                      hiddenOfAttributeFilter: !_get(
                        filteredLayerComponentSet,
                        `${layer.layerId}.${layerComponent.componentId}`,
                        false
                      ),
                    },
                  ];
                } else {
                  newLayerComponents[layer.layerId] = [
                    {
                      ...layerComponent,
                      hiddenOfAttributeFilter: !_get(
                        filteredLayerComponentSet,
                        `${layer.layerId}.${layerComponent.componentId}`,
                        false
                      ),
                    },
                  ];
                }
              });
            });
            return { layerComponents: newLayerComponents };
          },
          function () {
            that.updateComponentsAfterDelete();
          }
        );
      })
      .catch(catchError);
  };

  updateFeatureListInfo = () => {
    let that = this;
    const { featureListInfo } = that.props;
    let featureObject = {};
    featureListInfo?.data?.forEach(function (feature) {
      featureObject[feature.name] = feature;
    });
    that.setState({
      featureType: featureObject,
    });
  };

  addLayerHandler = () => {
    let that = this;

    // find current view from orderviews
    const currentView = that.props.orderViews.find(
      (view) => view.viewId === that.props.selectedView.viewId
    );
    // find it current view is editable
    const isEditableView = currentView?.isEditable;

    if (isEditableView) {
      that.props.toggleAddNewLayer(true);
      that.props.setAttributeModalVisiblity(true);
    } else {
      that.props.toggleAddAfterLayer(true, false, false);
    }
    that.props.setAttributeMultiPopupData(null);
  };

  handleSort = () => {
    const { selectedView } = this.props;
    const { selectedLayer, featureType } = this.state;
    trackEvents('layerpanel__sort-changed', {
      orderId: selectedView?.orderId,
      viewId: selectedView?.viewId,
      isBaseView: selectedView?.isBaseView,
      isEditable: selectedView?.isEditable,
      geometryType: featureType[selectedLayer?.name].type,
      layerName: selectedLayer?.name,
      sortValue:
        this.state.sort === 'false'
          ? 'asc'
          : this.state.sort === 'asc'
          ? 'desc'
          : 'false',
    });
    this.setState({
      sort:
        this.state.sort === 'false'
          ? 'asc'
          : this.state.sort === 'asc'
          ? 'desc'
          : 'false',
    });
  };

  copyMeasurementHandler = (e, content) => {
    e.preventDefault();
    e.stopPropagation();
    copyToClipboard(content);
    successBottomMessage('Copied to Clipboard');
  };

  handleServiceItemsChange = (components) => {
    this.setState((prevState) => {
      const newLayerComponents = {};

      for (const [layerId, _components] of Object.entries(
        prevState.layerComponents ?? {}
      )) {
        newLayerComponents[layerId] = _components.map((_component) => ({
          ..._component,
          serviceItemIds:
            components[_component.componentId]?.serviceItemIds ??
            _component.serviceItemIds ??
            [],
        }));
      }

      return {
        layerComponents: newLayerComponents,
      };
    });

    const componentsToBeUpdated = Object.keys(components);

    /** Update the existing feature properties on OlMap */
    this.props.mapRef.getLayers().forEach((layer) => {
      layer.getSource?.().forEachFeature?.((feature) => {
        let componentId =
          feature.componentId ?? feature.getProperties().componentId;

        if (componentId?.includes('-')) {
          componentId = componentId.split('-')[1];
        }

        if (componentsToBeUpdated.includes(componentId)) {
          const serviceItemIds =
            components[componentId]?.serviceItemIds ??
            feature.getProperties().serviceItemIds ??
            [];

          feature.setProperties({
            ...feature.getProperties(),
            serviceItemIds,
          });
        }
      });
    });

    const updatedReduxOrderLayerComponents = {};

    for (const [layerName, _components] of Object.entries(
      this.props.reduxLayerComponents ?? {}
    )) {
      updatedReduxOrderLayerComponents[layerName] = _components.map(
        (_component) => ({
          ..._component,
          serviceItemIds:
            components[_component.componentId]?.serviceItemIds ??
            _component.serviceItemIds ??
            [],
        })
      );
    }

    this.props.setOrderLayerComponents(updatedReduxOrderLayerComponents);

    /** TODO: Update layerComponents in redux */
  };

  render() {
    let {
      layersData,
      layerComponents,
      treeCheckedKeys,
      loading,
      parcelStyle,
      selectedNodeKeys,
      expendLayer,
      factor,
      expendedKeys,
      selectedLayer,
      featureType,
      masterLayerCheckState,
      appliedFilters,
    } = this.state;
    let {
      nearMapDates,
      isNearMap,
      isAttributeFilterPanelVisible,
      isNewAttributeAvailableToLink,
      currentViewType,
    } = this.props;
    let that = this;

    const getGeometryType = () => {
      if (!selectedLayer) {
        return undefined;
      }

      const layer = layersData.find(
        (_layer) => _layer.layerId === selectedLayer.id
      );

      return layer
        ? layer.featureType
        : _get(featureType[selectedLayer.name], 'type');
    };

    let geometryType = getGeometryType();

    const isAAorRF =
      this.props.orderSummary.status === OrderStatus.AwaitingApproval ||
      this.props.orderSummary.status === OrderStatus.ResolvingFeedback;

    const setLayerPanelStyle = () => {
      if (isAAorRF) {
        return {
          height: this.props.isNotificationBarVisible
            ? 'calc(100vh - 132px)'
            : `calc(100vh - ${isAAorRF ? '210' : '88'}px)`,
        };
      } else if (this.props.isNotificationBarVisible) {
        return { height: 'calc(100vh - 196px)' };
      } else {
        return { height: 'calc(100vh - 146px)' };
      }
    };

    const treeNodes = this.renderTreeNode({
      layersData,
      layerComponents,
    });
    return (
      <section
        className={'layer-panel d-flex flex-column'}
        style={setLayerPanelStyle()}
      >
        <div className='layers-tree-container flex-1'>
          <div id='tree-div' className='tree-div'>
            {!isAttributeFilterPanelVisible ? (
              <Row className='layer-panel-master' align='middle'>
                <Col
                  style={{ width: '170px', borderRight: '1px solid lightgray' }}
                >
                  <Row
                    align='middle'
                    justify='space-between'
                    className='w-100 h-100'
                  >
                    <Checkbox
                      className={'master-check-box'}
                      {...masterLayerCheckState}
                      onChange={(e) =>
                        this.globalLayerVisible(e.target.checked)
                      }
                    >
                      <Text type={'p17'} color='dark-gray'>
                        Layers{' '}
                        {layersData.length > 0 ? (
                          <Text type={'p92'} style={{ color: '#666666' }}>
                            {' '}
                            ({layersData.length}){' '}
                          </Text>
                        ) : (
                          <></>
                        )}
                      </Text>
                    </Checkbox>

                    <BetaReleaseGuard feature={BetaFeature.Attributes}>
                      {isNewAttributeAvailableToLink ? (
                        <Button
                          size={'small'}
                          type={'link'}
                          icon={<TopologyWarning style={{ marginTop: 4 }} />}
                          onClick={() => {
                            this.props.setLinkNewAttributeVisible(true);
                          }}
                        />
                      ) : null}
                    </BetaReleaseGuard>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <SRUserTypeAccessGuard right={AccessRight.Edit} tooltip>
                        <Tooltip title='Add Layer' placement='top'>
                          <Button
                            onClick={that.addLayerHandler}
                            className='add-layer-btn'
                            disabled={this.state.loading}
                          >
                            <PlusIcon />
                          </Button>
                        </Tooltip>
                      </SRUserTypeAccessGuard>
                    </div>
                  </Row>
                </Col>
                <Col
                  style={{
                    width: '141px',
                    borderRight: '1px solid lightgray',
                  }}
                  className='pl-2'
                >
                  <Row align='middle' justify='start' className='w-100 h-100'>
                    <Col span={18}>
                      <Text
                        type='p17'
                        style={{ marginRight: '4px' }}
                        color='dark-gray'
                      >
                        Measurements
                      </Text>
                    </Col>
                    <Col span={6}>
                      <Row justify='end' className='w-100'>
                        <div
                          className='sort-button'
                          style={{ marginRight: '2px' }}
                          onClick={this.handleSort}
                        >
                          <Tooltip title='Sort measurements' placement='top'>
                            {this.state.sort === 'false' ? (
                              <SortDefault />
                            ) : this.state.sort === 'asc' ? (
                              <SortAsc />
                            ) : (
                              <SortDesc />
                            )}
                          </Tooltip>
                        </div>
                      </Row>
                    </Col>

                    <BetaReleaseGuard feature={BetaFeature.Attributes}>
                      <Button
                        size={'small'}
                        type={'link'}
                        icon={
                          <img src={FilterPanelAttributeFilterIcon} alt='' />
                        }
                        onClick={() => {
                          this.props.setAttributeFilterPanelVisible(true);
                        }}
                      >
                        {appliedFilters.length ? (
                          <Badge
                            status={'processing'}
                            color={'#f50'}
                            style={{
                              position: 'absolute',
                              left: 20,
                              top: -7,
                            }}
                          />
                        ) : null}
                      </Button>
                    </BetaReleaseGuard>
                  </Row>
                </Col>
                <Col flex={1}>
                  <Row align='middle' className='w-100 h-100 px-2'>
                    <Col span={18}>
                      {this.props.visibleAttributesInLayerPanel.length !==
                        0 && (
                        <Text
                          type='p17'
                          color='dark-gray'
                          style={{ marginRight: '4px' }}
                        >
                          Service Items
                        </Text>
                      )}
                    </Col>
                    <Col span={6}>
                      <Row justify='end'>
                        <Dropdown
                          trigger='click'
                          placement='bottomLeft'
                          overlayClassName='panel-settings-dropdown'
                          overlay={
                            <Row className='px-3 pt-2 pb-4'>
                              <Row className='pb-4'>
                                <Text type='p79' color='light-gray'>
                                  Panel Settings
                                </Text>
                              </Row>
                              <Row>
                                <Col className='mr-2'>
                                  <Text type='p17' color='dark-gray'>
                                    {'Show Service Items'}
                                  </Text>
                                </Col>
                                <Col className='service-item-show-switch'>
                                  <Tooltip
                                    title='This is not accessible in the current view.'
                                    overlayClassName={
                                      currentViewType == ViewType.STATIC
                                        ? 'dn-none'
                                        : ''
                                    }
                                  >
                                    <Switch
                                      disabled={
                                        [
                                          ViewType.DYNAMIC,
                                          ViewType.ESTIMATION,
                                        ].includes(currentViewType) ||
                                        checkIfShareMapPage()
                                      }
                                      checked={this.props.visibleAttributesInLayerPanel.includes(
                                        'SERVICE_ITEM'
                                      )}
                                      onChange={
                                        this.handleServiceItemsVisibilityChange
                                      }
                                    />
                                  </Tooltip>
                                </Col>
                              </Row>
                            </Row>
                          }
                        >
                          <div className='sort-button si-kebab'>
                            <ServiceItemKebab />
                          </div>
                        </Dropdown>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : null}
            {isAttributeFilterPanelVisible ? (
              <AttributesFilter
                appliedFilters={appliedFilters}
                applyAttributeFilters={this.applyAttributeFilters}
                layersData={layersData}
              />
            ) : (
              <>
                <div>
                  <Spin spinning={loading}>
                    <Tree
                      checkable
                      disabled={loading}
                      onMouseEnter={this.onMouseEnter}
                      onMouseLeave={this.onMouseLeave}
                      onSelect={this.onSelect}
                      selectedKeys={selectedNodeKeys}
                      onCheck={this.onTreeCheck}
                      checkedKeys={treeCheckedKeys}
                      onExpand={this.onExpendTree}
                      expandedKeys={[expendedKeys]}
                    >
                      {treeNodes.map((data) => data)}
                    </Tree>
                  </Spin>
                  <div style={{ borderTop: '1px solid #E8E8E8' }} />
                  <div>
                    <Checkbox
                      className={'layer-panel-parcel'}
                      defaultChecked={
                        !this.props.isParcelDisabled && that.state.isParcelLayer
                      }
                      disabled={this.props.isParcelDisabled}
                      checked={
                        !this.props.isParcelDisabled && that.state.isParcelLayer
                      }
                      onChange={(e) => this.toggleParcel(e.target.checked)}
                    >
                      <Row>
                        <Col>
                          <Row
                            justify={'space-between'}
                            style={{ width: '133px' }}
                          >
                            <Col>
                              <Text
                                type={'p17'}
                                color='dark-gray'
                                className={'pl-2'}
                              >
                                Parcel
                              </Text>
                            </Col>
                            <Col>
                              {/* ### Warning Icon ### */}
                              {this.props.isParcelDisabled && (
                                <Tooltip title='Parcel data not available'>
                                  <img
                                    alt=''
                                    src={exclamatary}
                                    style={{
                                      marginBottom: '2px',
                                      cursor: 'pointer',
                                      marginLeft: '30px',
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </Col>
                            <Col>
                              <Popover
                                placement='leftTop'
                                content={
                                  <StylePickerOptions
                                    layerData={{ layerId: 'parcel' }}
                                    updateStyle={this.updateStyle}
                                    layerType={'parcel'}
                                    style={parcelStyle}
                                  />
                                }
                                trigger='click'
                              >
                                <span
                                  className='parcel-dot'
                                  style={{
                                    backgroundColor: parcelStyle.fillColor,
                                    border: `2px solid ${parcelStyle.color}`,
                                    marginRight: 10,
                                  }}
                                />
                              </Popover>
                              <span
                                style={{
                                  borderRight: '1px solid lightgrey',
                                  height: '34px',
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          style={{
                            width: '140px',
                            textAlignLast: 'end',
                            paddingRight: '19px',
                          }}
                        >
                          {!this.props.isParcelDisabled && (
                            <Text type={'p17'} color='dark-gray'>
                              {parseFloat(
                                _get(this.props.orderTileData, 'parcelArea', 0)
                              ).toFixed(2)}{' '}
                              acres
                            </Text>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <div style={{ borderTop: '1px solid #E8E8E8' }} />
                      </Row>
                    </Checkbox>

                    {this.props.orderTileData.imagerydate && (
                      <Checkbox
                        className={'layer-panel-parcel'}
                        checked={this.props.isNearmapEnabled}
                        disabled={!this.props.hasNearmap}
                        onChange={(e) => {
                          this.props.onNearMapCheck(e.target.checked);
                        }}
                      >
                        <Row align='middle'>
                          <Text type={'p17'} color='dark-gray' className='pl-2'>
                            Nearmap
                          </Text>
                          {this.props.selectedNearmapDate && (
                            <span>
                              [
                              {moment(
                                this.props.selectedNearmapDate,
                                'YYYYMMDD'
                              ).format('LL')}
                              ]
                            </span>
                          )}
                        </Row>
                      </Checkbox>
                    )}

                    <Checkbox
                      className={'layer-panel-parcel'}
                      checked={this.props.isBasemapEnabled}
                      onChange={(e) =>
                        this.props.onBaseMapCheck(e.target.checked)
                      }
                    >
                      <Text type={'p17'} color='dark-gray' className='pl-2'>
                        Basemap
                      </Text>
                    </Checkbox>
                  </div>
                </div>
              </>
            )}
            {that.state.isDeleteLayer && (
              <ActionModal
                isVisible={that.state.isDeleteLayer}
                setModalVisible={that.handleDeleteLayerModal}
                title={'Confirmation'}
                icon={question}
                extraData={_get(that.props.selectedView, 'viewId')}
                content={'Are you sure you want to delete this layer?'}
                onOK={that.deleteLayer}
                loading={that.state.deleteLayerLoading}
              />
            )}
            {this.state.isCopyLayer ? (
              <CopyLayerModal
                // key={Math.random()}
                selectedView={this.props.selectedView}
                isOpen={this.state.isCopyLayer}
                handleModalOpen={this.handleModalOpen}
                selectedLayer={this.state.selectedLayer}
                onDelete={this.deleteLayer}
                onSelectLayer={this.onSelectLayer}
              />
            ) : null}

            <OrderStatusGuard or={this.props.selectedView && this.props.selectedView.isBaseView === false} except={HIDDEN_MAP_TOOLS_PANEL_STATUS}>
              <SRUserTypeAccessGuard right={AccessRight.Edit}>
                <MapToolsPanel
                  setPinnedTools={this.setPinnedTools}
                  selectedLayer={this.state.selectedLayer}
                  disableAllTools={disableAllTools(
                    that.state.layersData,
                    this.props.orderTileData?.access_rights,
                    this.props.srUserTypeId
                  )}
                  disableAllToolsTooltip={disableAllToolsTooltip(
                    that.state.layersData,
                    this.props.orderTileData?.access_rights,
                    this.props.srUserTypeId
                  )}
                  mapRef={this.props.mapRef}
                  layerRefs={this.state.layerRefs}
                  handleLayerComponents={this.handleLayerComponents}
                  selectedView={this.props.selectedView}
                  componentUpdatedEvent={this.componentUpdatedEvent}
                  deleteComponent={this.deleteComponent}
                  forcedSyncUpdatedComponentToServers={
                    this.forcedSyncUpdatedComponentToServers
                  }
                  resetLayerStyles={this.resetLayerStyles}
                  setExtraState={this.setExtraState}
                  openFPanel={this.props.openFPanel}
                  featureType={this.state.featureType}
                  showApprovedOrderDetails={this.props.showApprovedOrderDetails}
                  stackOperationLoading={this.state.stackOperationLoading}
                  popFromUndoStack={this.popFromUndoStack}
                  popFromRedoStack={this.popFromRedoStack}
                  shortCutRef={this.props.shortCutRef}
                  layerComponents={this.state.layerComponents}
                  selectedNodeKeys={this.state.selectedNodeKeys}
                  selectedFeature={this.props.selectedFeature}
                  singleMultiPopup={this.singleMultiPopup}
                  onSelect={that.onSetSelectedNode}
                  clickFeatureAction={this.props.clickFeatureAction}
                  toggleAddAfterLayer={that.props.toggleAddAfterLayer}
                  layersData={that.state.layersData}
                  setNoteActions={that.props.setNoteActions}
                  setActiveToolRef={this.setActiveToolRef}
                  accessRight={this.props.orderTileData?.access_rights}
                  popoverRef={this.props.popoverRef}
                  panelWidth={getPanelWidth(
                    this.props.visibleAttributesInLayerPanel
                  )}
                  attributePanelVisible={this.state.attributePanelVisible}
                  performPointUndoOperation={this.performPointUndoOperation}
                  performPointRedoOperation={this.performPointRedoOperation}
                  clearAllMultiSelectedData={that.clearAllMultiSelectedData}
                  undoStack={this.state.undoStack}
                  redoStack={this.state.redoStack}
                  autoSaveRequestTrack={this.state.autoSaveRequestTrack}
                  setCurrentLayer={this.props.setCurrentLayer}
                  addLayerHandler={this.addLayerHandler}
                  onExpandTree={(layerId, area) => {
                    that.onExpendTree([layerId], {
                      expanded: true,
                      node: {
                        key: `${layerId}`,
                        area: area,
                        name: that.state.layersData.find(
                          (item) => item.layerId === layerId
                        ).name,
                      },
                    });
                  }}
                  isBulkView={this.props.isBulkView}
                  ignoreTopoChecks={this.ignoreTopoChecks}
                  onResetModifications={this.updateComponentsAfterDelete}
                  isViewPanelPinned={this.props.isViewPanelPinned}
                  isOrderHistoryPanelVisible={
                    this.props?.isOrderHistoryPanelVisible
                  }
                />
              </SRUserTypeAccessGuard>
            </OrderStatusGuard>

            {this.props.showAddLayerModal || this.props.isEditableBase ? (
              <NewLayer
                loadLayer={this.loadLayers}
                loadFeatures={this.loadFeatures}
                addSingleLayerData={that.addSingleLayerData}
                onSaveAndApplyFeature={this.handleSaveAndApplyFeature}
                layerData={this.state.layersData}
                isBaseView={_get(this.props.selectedView, 'isBaseView')}
                isEditableView={_get(this.props.selectedView, 'isEditable')}
                toggleAddNewLayer={that.props.toggleAddNewLayer}
                updateFeatureListInfo={that.updateFeatureListInfo}
                // key={this.state.layersData?.length}
              />
            ) : null}

            {/* <SRUserTypeAccessGuard right={AccessRight.Edit}>
              {that.props.isEditableView && (
                <UndoRedoToolsPanel
                  popFromUndoStack={this.popFromUndoStack}
                  popFromRedoStack={this.popFromRedoStack}
                  stackOperationLoading={this.state.stackOperationLoading}
                  undoStack={this.state.undoStack}
                  redoStack={this.state.redoStack}
                  selectedLayer={that.state.selectedLayer}
                  layerComponents={this.state.layerComponents}
                  lastAutoSaved={this.state.lastAutoSaved}
                  autoSaveRequestTrack={this.state.autoSaveRequestTrack}
                  openFPanel={this.props.openFPanel}
                  ignoreTopoChecks={this.ignoreTopoChecks}
                  showApprovedOrderDetails={this.props.showApprovedOrderDetails}
                  selectedLayerType={_get(
                    that.state.featureType[selectedLayer?.name],
                    'type'
                  )}
                  clearAllMultiSelectedData={that.clearAllMultiSelectedData}
                  selectedView={that.props?.selectedView}
                  featureType={that.state.featureType}
                  performPointUndoOperation={this.performPointUndoOperation}
                  performPointRedoOperation={this.performPointRedoOperation}
                  panelWidth={getPanelWidth(
                    this.props.visibleAttributesInLayerPanel
                  )}
                />
              )}
            </SRUserTypeAccessGuard> */}
            <UploadGeoJSON
              visible={that.props.uploadLayerOnMapModal}
              onCancel={that.props.uploadLayerOnMapModalClose}
              selectedView={that.props?.selectedView}
              loadData={that.loadLayers}
            />
          </div>
        </div>
        <div style={{ position: 'fixed', top: 50, left: 0 }}>
          {that.props.isActionCenterCard && (
            <AttributeFeature
              popoverRef={this.props.popoverRef}
              multiSelectFeature={this.props.multiSelectFeature}
              unSetMultiSelectFeature={this.props.unSetMultiSelectFeature}
              deleteComponents={this.deleteComponents}
              clearAllMultiSelectedData={this.clearAllMultiSelectedData}
              isBaseView={_get(this.props.selectedView, 'isBaseView')}
              isEditableView={_get(this.props.selectedView, 'isEditable')}
              viewType={_get(this.props.selectedView, 'viewType')}
              loadLayerComponents={this.loadLayerComponents}
              onUpdateLayerComponents={this.onUpdateLayerComponents}
              geometryType={geometryType}
              onDuplicateComponent={this.onDuplicateComponent}
              selectedLayer={that.state.selectedLayer}
              layerComponents={this.state.layerComponents}
              updateComponentsAfterAttributeUpdates={
                this.updateComponentsAfterAttributeUpdates
              }
              toggleAddNewLayer={this.props.toggleAddNewLayer}
              toggleAddAfterLayer={that.props.toggleAddAfterLayer}
              layersData={this.state.layersData}
              openFPanel={this.props.openFPanel}
              showApprovedOrderDetails={this.props.showApprovedOrderDetails}
              featureType={this.state.featureType}
              featureId={
                this.state.featureType[this.state.selectedLayer?.name]
                  ?.featureId
              }
              onMergeComponents={this.onMergeComponents}
              isUndoRedo={!that.props.isShared && that.props.isEditableView}
              layerRefs={this.state.layerRefs[this.state.selectedLayer?.id]}
              resetLayerStyles={this.resetLayerStyles}
              layerSource={this.state.layerRefs}
              visibleAttributes={this.props.visibleAttributesInLayerPanel}
              panelWidth={getPanelWidth(
                this.props.visibleAttributesInLayerPanel
              )}
              orderHash={this.props.orderTileData?.orderHash}
              onServiceItemsChange={this.handleServiceItemsChange}
            />
          )}
        </div>
        {/* {this.props.openFPanel && this.props.isVerificationTimerVisible && (
            <SRUserTypeAccessGuard right={AccessRight.Edit}>
              <EstimatedDeliveryTime
                time={this.props.orderSummary.estimatedDeliveryTime}
                boltStatus={this.props.boltOrderStatus}
                boltEstimatedDeliveryTime={this.props.boltEstimatedDeliveryTime}
              />
            </SRUserTypeAccessGuard>
          )} */}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editingToolVal: state.order.editingToolVal,
    isSnap: state.user.onSnap,
    snapshotV2Flow: state.order.snapshotV2Flow,
    nearMapDates: state.order.nearMapDates,
    multiData: state.feature.attributeMultiPopupData,
    isBaseView: state.order.isBaseView,
    isEditableView: state.order.isEditableView,
    layerList: state.order.layerList,
    reduxLayerComponents: state.order.layerComponents,
    featureListInfo: state.order.featureListInfo,
    orderFeatures: state.order?.orderFeatures?.features,
    activeTool: state.user.activeTool,
    srUserTypeId: state.user.info.sr_user_type_id,
    topologyWarning: state.order.topologyWarning,
    selectDeselectFeature: state.order.selectDeselectFeature,
    isAttributeFilterPanelVisible: state.feature.isAttributeFilterPanelVisible,
    isNewAttributeAvailableToLink: state.feature.isNewAttributeAvailableToLink,
    configurations: state.configurations,
    isClickLeftPanel: state.ui.isClickLeftPanel,
    drawingFeaturePointHistoryRedoStack:
      state.map.drawingFeaturePointHistoryRedoStack,
    drawingFeaturePointHistoryUndoStack:
      state.map.drawingFeaturePointHistoryUndoStack,
    uploadLayerOnMapModal: state.order.uploadLayerOnMapModal,
    isActionCenterCard: state.ui.isActionCenterCard,
    selectedOrderList: state.bulkOrder.selectedOrderList,
    isShared: state.order.isSharedOrder,
    access: state.order.userAccessRight || AccessRight.View,
    orderViews: state.order.orderViews,
    visibleAttributesInLayerPanel: state.order.visibleAttributesInLayerPanel,
    attributeSinglePopupData: state.feature.attributeSinglePopupData,
    attributePopupData: state.feature.attributePopupData,
    isNotificationBarVisible: state.ui.notificationBar.visible,
    allAttributes: state.feature.withDeletedAttributes,
    serviceItemsList: state.feature.serviceItemsList,
    currentViewType: state.order.currentViewData?.viewType,
    selectedNearmapDate: state.order.selectedNearmap?.date,
    parcelStyle: state.order.parcelStyle,
    isNearmapDebugEnabled: state.ui.isNearmapDebugEnabled,
    unSyncedModifiedChanges: state.map.modifyDraftEvents,
    isNearmapEnabled: state.ui.isNearmapEnabled,
    isBasemapEnabled: state.ui.isBasemapEnabled,
    hasNearmap: state.ui.hasNearmap,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEditingRedux: (payload) => dispatch(setEditingRedux(payload)),
    kmlForLayerList: (payload) => dispatch(kmlForLayerList(payload)),
    setOrderFeatures: (payload) => dispatch(setOrderFeatures(payload)),
    setOrderLayerComponents: (payload) =>
      dispatch(setOrderLayerComponents(payload)),
    setCurrentLayer: (payload) => dispatch(setCurrentLayer(payload)),
    setAttributePopupData: (payload) =>
      dispatch(setAttributePopupData(payload)),
    setAttributeMultiPopupData: (payload) =>
      dispatch(setAttributeMultiPopupData(payload)),
    getLayerDetails: (payload) => dispatch(getLayerDetails(payload)),
    setMapClassnames: (payload) => dispatch(setMapClassnames(payload)),
    setActiveTool: (payload) => dispatch(setActiveTool(payload)),
    setOrderLayers: (payload) => dispatch(setOrderLayers(payload)),
    updateLayerRefs: (payload) => dispatch(updateLayerRefs(payload)),
    featureList: (payload) => dispatch(featureList(payload)),
    setAttributeModalVisiblity: (payload) =>
      dispatch(setAttributeModalVisiblity(payload)),
    updateOrdersTopologyWarnings: (payload) =>
      dispatch(updateOrdersTopologyWarnings(payload)),
    removeComponentTopologyWarnings: (payload) =>
      dispatch(removeComponentTopologyWarnings(payload)),
    selectDeselectFeatures: (payload) =>
      dispatch(selectDeselectFeatures(payload)),
    drawInteractionLastPointRedo: (payload) =>
      dispatch(drawInteractionLastPointRedo(payload)),
    drawInteractionLastPointUndo: (payload) =>
      dispatch(drawInteractionLastPointUndo(payload)),
    setAttributeFilterPanelVisible: (payload) =>
      dispatch(setAttributeFilterPanelVisible(payload)),
    setLinkNewAttributeVisible: (payload) =>
      dispatch(setLinkNewAttributeVisible(payload)),
    toggleLayerLeftPanel: (payload) => dispatch(toggleLayerLeftPanel(payload)),
    uploadLayerOnMapModalClose: (payload) =>
      dispatch(uploadLayerOnMapModalClose(payload)),
    getAllAttributes: () => dispatch(getAllAttributes()),
    getAllAttributesWithDeleted: () => dispatch(getAllAttributesWithDeleted()),
    getAllServiceItems: () => dispatch(getAllServiceItems()),
    setVisibleAttributesInLayerPanel: (payload) =>
      dispatch(setVisibleAttributesInLayerPanel(payload)),
    setAttributePanelVisibility: (payload) =>
      dispatch(setAttributePanelVisibility(payload)),
    setParcelStyle: (payload) => dispatch(setParcelStyle(payload)),
    modifySyncState: (payload) => dispatch(modifySyncState(payload)),
    setModifyDraftEvents: (payload) => dispatch(setModifyDraftEvents(payload)),
  };
};

export default WithAuth(
  WithRole(connect(mapStateToProps, mapDispatchToProps)(LayerPanel))
);
