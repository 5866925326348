export const PIN_CHANGE_SOURCE = {
  SEARCH: 'SEARCH',
  MAP_CLICK: 'MAP_CLICK',
  MANUAL: 'MANUAL',
}

export const CREATE_ORDER_SOURCE = {
  MANUAL: 'MANUAL',
  REGULAR: 'REGULAR',
}

export const VIEW_PANEL_SOURCE = {
  WIDGET_BAR: 'WIDGET_BAR',
  VIEW_DROPDOWN: 'VIEW_DROPDOWN'
}

export const SWITCH_TO_STATIC_VIEW_SOURCE = {
  WIDGET_BAR_LAYERS_TAB_CLICK: 'WIDGET_BAR_LAYERS_TAB_CLICK',
  VIEW_DROPDOWN_LINKED_VIEW_CLICK: 'VIEW_DROPDOWN_LINKED_VIEW_CLICK',
}

export const ACTION_CENTER_TYPE = {
  ESTIMATION: 'estimation',
  NONESTIMATION: 'non-estimation',
}

export const ORDER_TYPE = {
  ESTIMATION: 'estimation',
  TAKEOFF: 'takeoff',
}

const getIpadStatus = () => {
  const userAgentCheck = /iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
  const dimensionCheck = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const cssCheck = window.getComputedStyle(document.body, ':after').content === 'iPad';
  return userAgentCheck || dimensionCheck || cssCheck;
}

export const isThisDeviceIpad = getIpadStatus();
