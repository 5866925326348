import { ORGANISATION_ACTIONS, ORGANISATION_DATA } from './action_types';

export const getOrganisationSuggestionRequest = () => ({
  type: ORGANISATION_DATA.ORGANISATION_SUGGESTION_REQUEST,
});
export const getOrganisationSuggestionSuccess = (data) => ({
  type: ORGANISATION_DATA.ORGANISATION_SUGGESTION_SUCCESS,
  payload: data,
});
export const getOrganisationSuggestionFailure = () => ({
  type: ORGANISATION_DATA.ORGANISATION_SUGGESTION_FAILURE,
});

export const getOrganisationViaCodeRequest = () => ({
  type: ORGANISATION_DATA.GET_ORGANISATION_VIA_CODE_REQUEST,
});
export const getOrganisationViaCodeSuccess = (data) => ({
  type: ORGANISATION_DATA.GET_ORGANISATION_VIA_CODE_SUCCESS,
  payload: data,
});
export const getOrganisationViaCodeFailure = () => ({
  type: ORGANISATION_DATA.GET_ORGANISATION_VIA_CODE_FAILURE,
});

export const getOrganisationRequest = () => ({
  type: ORGANISATION_DATA.ORGANISATION_TITLE_REQUEST,
});
export const getOrganisationsuccess = (data) => ({
  type: ORGANISATION_DATA.ORGANISATION_TITLE_SUCCESS,
  payload: data,
});
export const getOrganisationFailure = () => ({
  type: ORGANISATION_DATA.ORGANISATION_TITLE_FAILURE,
});
export const updateOrganisationRequest = () => ({
  type: ORGANISATION_DATA.UPDATE_ORGANISATION_TITLE_REQUEST,
});
export const updateOrganisationsuccess = (data) => ({
  type: ORGANISATION_DATA.UPDATE_ORGANISATION_TITLE_SUCCESS,
  payload: data,
});
export const updateOrganisationFailure = () => ({
  type: ORGANISATION_DATA.UPDATE_ORGANISATION_TITLE_FAILURE,
});
export const nameOfOrganisationUpdated = () => ({
  type: ORGANISATION_DATA.NAME_OF_ORGANISATION,
});

export const createOrganisationRequest = () => ({
  type: ORGANISATION_ACTIONS.CREATE_ORGANISATION_REQUEST
});
export const createOrganisationSuccess = () => ({
  type: ORGANISATION_ACTIONS.CREATE_ORGANISATION_SUCCESS,
});
export const createOrganisationFailure = (data) => ({
  type: ORGANISATION_ACTIONS.CREATE_ORGANISATION_FAILURE,
  payload: data,
});


export const joinOrganisationRequest = () => ({
  type: ORGANISATION_ACTIONS.JOIN_ORGANISATION_REQUEST,
});
export const joinOrganisationSuccess = (payload) => ({
  type: ORGANISATION_ACTIONS.JOIN_ORGANISATION_SUCCESS,
  data: payload,
});
export const joinOrganisationFailure = () => ({
  type: ORGANISATION_ACTIONS.JOIN_ORGANISATION_FAILURE,
});
