import Chip from './common/Chip';
import Avatar from './common/Avatar';
import DropdownComponent from './DropdownComponent';
import { IUser } from './common/types';
import { Row } from 'antd';
import { useState } from 'react';
import RemoveMemberModal from './components/RemoveMemberModal';
import { ID, UserRoleId } from '@/modules/common/types';
import { useRole } from '@/modules/auth/guards/RoleGuard';

const Strings = {
  roleDropdown: {
    optionsWithRemove: [
      { label: 'View', value: 'view' },
      { label: 'Edit', value: 'edit' },
      { label: 'Remove', value: 'delete' },
    ],
    optionsWithoutRemove: [
      { label: 'View', value: 'view' },
      { label: 'Edit', value: 'edit' },
    ],
    optionsForDeactivated: [
      { label: 'View', value: 'view' },
      { label: 'Remove', value: 'delete' },
    ],
  },
};

interface IUsersProps {
  data: Array<IUser>;
  userEmails: string[];
  orderId: number;
  loggedUserId: ID;
  onSingleRoleChange: (option: any, email: string) => void;
  propertyOwner: IUser;
  onSingleUserDelete: (option: any, email: string) => void;
  loggedUserEmail: string;
}

const capitalize = (aStr: string): string => {
  if (aStr?.length > 0) {
    return aStr.charAt(0).toUpperCase() + aStr.slice(1);
  }
  return '';
};

const Users = ({
  data,
  userEmails,
  orderId,
  loggedUserId,
  onSingleRoleChange,
  onSingleUserDelete,
  propertyOwner,
  loggedUserEmail,
}: IUsersProps) => {
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const { isAdmin } = useRole();

  const canChangeAccessForUser = (user: IUser) => {
    /** Property creator and Admin can add/remove or change rights to every user */
    if (isAdmin || loggedUserEmail === propertyOwner.owner_email) return true;

    return user.sharer_id === loggedUserId;
  };

  return (
    <>
      {propertyOwner &&
        typeof propertyOwner === 'object' &&
        Object.keys(propertyOwner).length > 0 && (
          <>
            <Row
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem',
              }}
            >
              <Row style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Row>
                  <Avatar
                    name={propertyOwner.owner_name}
                    email={propertyOwner.owner_email}
                    is_deactivated={propertyOwner.is_owner_deactivated}
                  />
                </Row>
                <Row
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      color: '#333333',
                      fontSize: 12,
                      fontWeight: 400,
                      lineHeight: '18px',
                      marginBottom: 0,
                    }}
                  >
                    {propertyOwner.owner_email === loggedUserEmail ? (
                      `${propertyOwner.owner_name} (You)`
                    ) : (
                      <>
                        {propertyOwner.is_owner_deactivated ? (
                          <>
                            {propertyOwner.owner_name}
                            <span style={{ fontStyle: 'italic' }}>
                              (Deactivated)
                            </span>
                          </>
                        ) : (
                          <>{propertyOwner.owner_name}</>
                        )}
                      </>
                    )}
                  </span>
                  <span
                    style={{
                      color: !propertyOwner.owner_name ? '#333333' : '#999999',
                      fontSize: 10,
                      fontWeight: 400,
                      lineHeight: '15px',
                    }}
                  >
                    {propertyOwner.owner_email}
                  </span>
                </Row>
                {propertyOwner.owner_email && <Chip content='Creator' />}
              </Row>
              <div style={{ marginRight: '3rem' }}>
                <DropdownComponent
                  classFromParent='specific-user-access-dd'
                  disabled={true}
                  iconStyles={{ opacity: '0.3', cursor: 'not-allowed' }}
                  labelStyles={{ color: '#999999' }}
                  options={Strings.roleDropdown.optionsWithoutRemove}
                  selectedOption={{
                    label: 'Edit',
                    value: 'edit',
                  }}
                  setSelectedOption={(option) => {
                    if (option.value === 'view' || option.value === 'edit') {
                      onSingleRoleChange(option, propertyOwner.owner_email);
                    }
                  }}
                />
              </div>
            </Row>
          </>
        )}
      {data &&
        data.length > 0 &&
        data
          .filter((invitee) => Boolean(invitee.email && !invitee.is_deleted))
          .map((invitee, index) => {
            const { user_name, email, user_role_id, access_rights, user_id } =
              invitee;
            const isAllowedToChangeAccess = canChangeAccessForUser(invitee);

            return (
              <Row
                key={index}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '1rem',
                }}
              >
                <Row style={{ alignItems: 'flex-start' }}>
                  <Row>
                    <Avatar
                      name={user_name}
                      email={email}
                      is_deactivated={invitee.is_user_deactivated}
                    />
                  </Row>
                  <Row
                    style={{
                      flexDirection: 'column',
                    }}
                  >
                    {user_name && (
                      <span
                        style={{
                          color: `${
                            invitee.is_user_deactivated ? '#999999' : '#333333'
                          }`,
                          fontSize: 12,
                          fontWeight: 400,
                          lineHeight: '18px',
                          marginBottom: 0,
                        }}
                      >
                        {email === loggedUserEmail ? (
                          `${user_name} (You)`
                        ) : (
                          <>
                            {invitee.is_user_deactivated ? (
                              <>
                                {user_name}
                                <span style={{ fontStyle: 'italic' }}>
                                  (Deactivated)
                                </span>
                              </>
                            ) : (
                              <>{user_name}</>
                            )}
                          </>
                        )}
                      </span>
                    )}
                    <span
                      style={{
                        color: invitee.is_user_deactivated
                          ? '#999999'
                          : '#333333',
                        fontSize: 10,
                        fontWeight: 400,
                        lineHeight: '15px',
                      }}
                    >
                      {email}
                    </span>
                  </Row>
                  {(invitee.user_role_id === UserRoleId.Guest ||
                    !userEmails.includes(invitee.email)) && (
                    <Chip content='Guest' backgroundColor='#274DD3' />
                  )}
                </Row>
                <Row style={{ marginRight: '3rem' }}>
                  <DropdownComponent
                    classFromParent='specific-user-access-dd'
                    componentClass='specific-user-dd'
                    disabled={!isAllowedToChangeAccess}
                    iconStyles={
                      isAllowedToChangeAccess
                        ? { opacity: '0.7' }
                        : { opacity: '0.3', cursor: 'not-allowed' }
                    }
                    labelStyles={{
                      color: isAllowedToChangeAccess ? '#666666' : '#999999',
                      opacity: '1',
                    }}
                    options={
                      invitee.is_user_deactivated ||
                      invitee.user_role_id === UserRoleId.Guest ||
                      invitee.user_role_id === UserRoleId.Viewer ||
                      !userEmails.includes(invitee.email)
                        ? Strings.roleDropdown.optionsForDeactivated
                        : loggedUserEmail === email
                        ? Strings.roleDropdown.optionsWithoutRemove
                        : Strings.roleDropdown.optionsWithRemove
                    }
                    selectedOption={{
                      label: capitalize(access_rights),
                      value: access_rights,
                    }}
                    setSelectedOption={(option) => {
                      if (option.value === 'view' || option.value === 'edit') {
                        onSingleRoleChange(option, email);
                      } else {
                        if (loggedUserEmail !== email) {
                          setDeleteUserModal(true);
                          setModalData({
                            option: option,
                            email: email,
                            user_name: user_name,
                          });
                        }
                      }
                    }}
                  />
                </Row>
              </Row>
            );
          })}
      {deleteUserModal && (
        <RemoveMemberModal
          deleteUserModal={deleteUserModal}
          showDeleteUserModal={() => setDeleteUserModal(true)}
          hideDeleteUserModal={() => setDeleteUserModal(false)}
          handleUserDelete={(option, email) =>
            onSingleUserDelete(option, email)
          }
          modalData={modalData}
        />
      )}
    </>
  );
};

export default Users;
