import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import './style.less';
import { Input } from 'antd';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const FormInput = ({ containerStyle, handleChange, label, value, type, ...otherProps }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    if (type === 'email' && value !== '') {
      if (EMAIL_REGEX.test(value)) {
        setHasErrors(false);
      } else {
        setHasErrors(true);
      }
    }
  }, [value]);

  return (
    <div className={classNames('form-input-group', otherProps.className)} style={{ ...otherProps.style, ...containerStyle }}>
      {/* <label className='form-input-label'>{label}</label> */}
      <Input
        id="inputID"
        placeholder={label}
        {...otherProps}
        type={type}
        value={value}
        onChange={handleChange}
        className={`form-input ${isFocused ? 'blue' : ''} ${
          hasErrors ? 'red' : ''
        }`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
        }}
      />
    </div>
  );
};

FormInput.defaultProps = {
  containerStyle: {},
}

export default FormInput;
