import { useSelector } from 'react-redux';
import { SubscriptionType } from '../../../../../modules/common/models/subscription';
import { IStore } from '../../../../../store/types';

const useBidConsume = () => {
  const subscriptionData = useSelector<IStore, SubscriptionType>(
    (state) => state.subscription.subscription
  );

  const bidConsume: any = {};

  if (subscriptionData && subscriptionData.bidDetails) {
    bidConsume.plan = subscriptionData.bidDetails.planType || 'annual';
    bidConsume.totalAnnualPlan = subscriptionData.bidDetails.totalLimit;
    bidConsume.workspaceBidCreated =
      subscriptionData.bidDetails.totalLimit -
      (subscriptionData.bidDetails.usableBalance +
        subscriptionData.bidDetails.frozenBalance);
    bidConsume.lockedAmount = 0; // Placeholder for future use
  }

  return bidConsume;
};

export default useBidConsume;
