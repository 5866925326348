import { BULK_ORDER_ACTIONS } from './action_types';
import { bulkOrderParcelStatus } from '../../components/pages/bulkProject/utils/enum';
import { getUnconfirmedCard } from '../../components/pages/myProperties/utils/enum';
import update from 'immutability-helper';

const INITIAL_STATE = {
  bulkOrderDetailsModalVisible: false,
  bulkOrderToShowDetailsOf: null,
  bulkOrderListVisible: false,
  loadingBulkOrderDetails: false,
  refetchBulkOrdersListData: false,
  orderList: [],
  selectedOrderList: {
    prev: null,
    data: null,
    next: null,
  },
  bulkOrderSummeryData: null,
  addMoreBulkOrderDetailsModalVisible: false,
};

const data = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case BULK_ORDER_ACTIONS.OPEN_BULK_ORDER_DETAILS_MODAL:
      return {
        ...state,
        bulkOrderDetailsModalVisible: true,
        bulkOrderToShowDetailsOf: payload,
      };
    case BULK_ORDER_ACTIONS.CLOSE_BULK_ORDER_DETAILS_MODAL:
      return {
        ...state,
        bulkOrderDetailsModalVisible: false,
      };
    case BULK_ORDER_ACTIONS.VISIBLE_BULK_ORDER_DETAILS_MODAL:
      return {
        ...state,
        bulkOrderDetailsModalVisible: true,
      };
    case BULK_ORDER_ACTIONS.BULK_ORDER_REDUX_CLEANUP:
      return INITIAL_STATE;
    case BULK_ORDER_ACTIONS.OPEN_BULK_ORDER_LIST:
      return {
        ...state,
        bulkOrderListVisible: true,
      };
    case BULK_ORDER_ACTIONS.CLOSE_BULK_ORDER_LIST:
      return {
        ...state,
        bulkOrderListVisible: false,
      };
    case BULK_ORDER_ACTIONS.LOAD_BULK_ORDER_DETAILS_STARTED:
      return {
        ...state,
        loadingBulkOrderDetails: true,
      };
    case BULK_ORDER_ACTIONS.LOAD_BULK_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loadingBulkOrderDetails: false,
        bulkOrderToShowDetailsOf: payload,
      };
    case BULK_ORDER_ACTIONS.LOAD_BULK_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loadingBulkOrderDetails: false,
      };
    case BULK_ORDER_ACTIONS.REFETCH_BULK_ORDERS_LIST_DATA:
      return {
        ...state,
        refetchBulkOrdersListData: true,
      };
    case BULK_ORDER_ACTIONS.RESET_REFETCH_BULK_ORDERS_LIST_DATA_STATE:
      return {
        ...state,
        refetchBulkOrdersListData: false,
      };

    case BULK_ORDER_ACTIONS.ORDER_LIST_BULK_ORDER:
      // const indexOfFirstNonApproveProperty = payload.findIndex(
      //   (property) =>
      //     getStatusCode(property.status) === AWAITING_APPROVAL_ORDER_STATUS_CODE
      // );

      // if there is property not confirmed
      // if (indexOfFirstNotConfirmProperty !== -1)
      //   return {
      //     ...state,
      //     orderList: payload,
      //     selectedOrderList: {
      //       prev: payload[indexOfFirstNotConfirmProperty - 1] ?? null,
      //       data: payload[indexOfFirstNotConfirmProperty] ?? null,
      //       next: payload[indexOfFirstNotConfirmProperty + 1] ?? null,
      //     },
      //   };
      // else if (indexOfFirstNonApproveProperty !== -1)
      //   return {
      //     ...state,
      //     orderList: payload,
      //     selectedOrderList: {
      //       prev: payload[indexOfFirstNonApproveProperty - 1] ?? null,
      //       data: payload[indexOfFirstNonApproveProperty] ?? null,
      //       next: payload[indexOfFirstNonApproveProperty + 1] ?? null,
      //     },
      //   };
      // if index does not find
      // else {
      return {
        ...state,
        orderList: payload,
        selectedOrderList: {
          prev: null,
          data: payload[0] ?? null,
          next: payload[1] ?? null,
        },
      };
    // }
    case BULK_ORDER_ACTIONS.NEXT_SELECTED_ORDER:
      const currentIndex = state.orderList.findIndex(
        (val) => val?.id === state?.selectedOrderList?.data?.id
      );
      return {
        ...state,
        selectedOrderList: {
          prev: state.orderList[currentIndex],
          data: state.orderList[currentIndex + 1] || null,
          next: state.orderList[currentIndex + 2] || null,
        },
      };
    case BULK_ORDER_ACTIONS.INDEXED_SELECTED_ORDER:
      const selectedIndex = state.orderList.findIndex(
        (val) => val?.id === payload?.orderId
      );
      return {
        ...state,
        selectedOrderList: {
          prev: state.orderList[selectedIndex - 1] || null,
          data: state.orderList[selectedIndex] || null,
          next: state.orderList[selectedIndex + 1] || null,
        },
      };

    case BULK_ORDER_ACTIONS.PREV_SELECTED_ORDER:
      const currentIndex2 = state.orderList.findIndex(
        (val) => val?.id === state?.selectedOrderList?.data?.id
      );
      return {
        ...state,
        selectedOrderList: {
          prev: state.orderList[currentIndex2 - 2] || null,
          data: state.orderList[currentIndex2 - 1] || null,
          next: state.orderList[currentIndex2],
        },
      };

    case BULK_ORDER_ACTIONS.CONFIRM_PARCEL:
      const currentParcelIndex = state.orderList.findIndex(
        (val) => val?.id === payload?.orderId
      );

      const nextUnconfirmedParcelIndex = state.orderList.findIndex(
        (val, i) =>
          i !== currentParcelIndex &&
          i > currentParcelIndex &&
          val?.propertyStatus === bulkOrderParcelStatus.draft
      );

      let unConfirmOrderIndex = -1;

      if (nextUnconfirmedParcelIndex === -1) {
        unConfirmOrderIndex = getUnconfirmedCard(
          state.orderList,
          currentParcelIndex
        );
      }

      let tempOrderList = [...state.orderList];

      tempOrderList = tempOrderList.reduce((prevState, value, i) => {
        if (i === currentParcelIndex)
          return [
            ...prevState,
            {
              ...value,
              status: bulkOrderParcelStatus.confirmed,
              imageryDate: payload?.imageryDate,
            },
          ];

        return [...prevState, value];
      }, []);

      return {
        ...state,
        orderList: tempOrderList,

        selectedOrderList:
          //  will point next draft parcel
          nextUnconfirmedParcelIndex !== -1
            ? {
                prev: state?.orderList[nextUnconfirmedParcelIndex - 1] || null,
                data: state?.orderList[nextUnconfirmedParcelIndex],
                next: state?.orderList[nextUnconfirmedParcelIndex + 1] || null,
              }
            : unConfirmOrderIndex !== -1
            ? {
                prev: state?.orderList[unConfirmOrderIndex - 1] || null,
                data: state?.orderList[unConfirmOrderIndex],
                next: state?.orderList[unConfirmOrderIndex + 1] || null,
              }
            : //  will point next parcel
            state?.selectedOrderList?.next
            ? {
                prev: tempOrderList[currentParcelIndex],
                data: tempOrderList[currentParcelIndex + 1] || null,
                next: tempOrderList[currentParcelIndex + 2] || null,
              }
            : {
                prev: tempOrderList[currentParcelIndex - 1] || null,
                data: tempOrderList[currentParcelIndex] || null,
                next: tempOrderList[currentParcelIndex + 1] || null,
              },
      };

    case BULK_ORDER_ACTIONS.UPDATE_PARCEL:
      const { parcelData, orderId } = payload;

      const currParcelIndx = state.orderList.findIndex(
        (val) => val?.id === orderId
      );

      let tmpOrder = [...state.orderList];

      tmpOrder = tmpOrder.reduce((prevState, value, i) => {
        if (i === currParcelIndx)
          return [...prevState, { ...value, ...parcelData }];

        return [...prevState, value];
      }, []);

      return {
        ...state,
        orderList: tmpOrder,
        selectedOrderList: {
          ...state?.selectedOrderList,
          data: tmpOrder[currParcelIndx] || null,
        },
      };

    case BULK_ORDER_ACTIONS.UPDATE_SELECTED_ORDER_INDEX:
      return {
        ...state,

        selectedOrderList: {
          prev: state.orderList[+payload - 1] || null,
          data: state.orderList[+payload] || null,
          next: state.orderList[+payload + 1] || null,
        },
      };
    case BULK_ORDER_ACTIONS.UPDATE_SUMMERY:
      return {
        ...state,
        bulkOrderSummeryData: payload,
      };

    case BULK_ORDER_ACTIONS.UPDATE_COST_TIME:
      return {
        ...state,
        bulkCostAndTime: {
          ...payload,
        },
      };
    case BULK_ORDER_ACTIONS.APPEND_ORDER_LIST:
      return {
        ...state,
        orderList: update(state.orderList, { $push: payload }),
        selectedOrderList: update(state.selectedOrderList, {
          next: { $set: payload[0] },
        }),
      };

    case BULK_ORDER_ACTIONS.UPDATE_TAG_BY_ORDER_ID:
      const { tagArray, orderID } = payload;

      const tagOrderIndex = state.orderList.findIndex(
        (val) => val?.id === orderID
      );

      let tem = [...state.orderList];

      tem = tem.reduce((prevState, value, i) => {
        if (i === tagOrderIndex)
          return [...prevState, { ...value, tag: tagArray }];

        return [...prevState, value];
      }, []);

      return {
        ...state,
        orderList: tem,
        selectedOrderList: {
          ...state?.selectedOrderList,
          data: tem[tagOrderIndex] || null,
        },
      };

    case BULK_ORDER_ACTIONS.SKIP_BULK_ORDER: {
      const { orderId, skipReasons } = payload;

      const orderDataIndex = state.orderList.findIndex(
        (val) => val?.id === orderId
      );
      const nextUnconfirmedOrderD = state.orderList.findIndex(
        (val, i) =>
          i !== orderDataIndex &&
          i > orderDataIndex &&
          val?.propertyStatus === bulkOrderParcelStatus.draft
      );

      let unconfiremedIndex = -1;

      if (nextUnconfirmedOrderD === -1) {
        unconfiremedIndex = getUnconfirmedCard(state.orderList, orderDataIndex);
      }

      let tempOrderData = state.orderList.map((t) => {
        if (t.id === orderId)
          return {
            ...t,
            status: bulkOrderParcelStatus.skipped,
            propertyStatus: bulkOrderParcelStatus.skipped,
            skipDesc: skipReasons,
          };
        else return t;
      });

      return {
        ...state,
        orderList: tempOrderData,

        selectedOrderList:
          //  will point next draft parcel
          nextUnconfirmedOrderD !== -1
            ? {
                prev: state?.orderList[nextUnconfirmedOrderD - 1] || null,
                data: state?.orderList[nextUnconfirmedOrderD],
                next: state?.orderList[nextUnconfirmedOrderD + 1] || null,
              }
            : unconfiremedIndex !== -1
            ? {
                prev: state?.orderList[unconfiremedIndex - 1] || null,
                data: state?.orderList[unconfiremedIndex],
                next: state?.orderList[unconfiremedIndex + 1] || null,
              }
            : //  will point next parcel
            state?.selectedOrderList?.next
            ? {
                prev: tempOrderData[orderDataIndex],
                data: tempOrderData[orderDataIndex + 1] || null,
                next: tempOrderData[orderDataIndex + 2] || null,
              }
            : {
                prev: tempOrderData[orderDataIndex - 1] || null,
                data: tempOrderData[orderDataIndex] || null,
                next: tempOrderData[orderDataIndex + 1] || null,
              },
      };
    }

    case BULK_ORDER_ACTIONS.DELETE_BULK_ORDER: {
      const orderDataIndex = state.orderList.findIndex(
        (val) => val?.id === state.selectedOrderList.data.id
      );

      const tempOrderList = state.orderList.filter(
        (t) => t.id !== state.selectedOrderList.data.id
      );

      return {
        ...state,
        orderList: tempOrderList,
        selectedOrderList: {
          prev:
            orderDataIndex >= tempOrderList.length
              ? null
              : tempOrderList[orderDataIndex - 1] ?? null,
          data:
            orderDataIndex >= tempOrderList.length
              ? tempOrderList[0]
              : tempOrderList[orderDataIndex] ?? null,
          next:
            orderDataIndex >= tempOrderList.length
              ? 1
              : tempOrderList[orderDataIndex + 1] ?? null,
        },
      };
    }

    case BULK_ORDER_ACTIONS.ADD_MORE_BULK_ORDER_DETAILS_MODAL_OPEN:
      return {
        ...state,
        addMoreBulkOrderDetailsModalVisible: true,
      };
    case BULK_ORDER_ACTIONS.ADD_MORE_BULK_ORDER_DETAILS_MODAL_CLOSE:
      return {
        ...state,
        addMoreBulkOrderDetailsModalVisible: false,
      };

    default:
      return state;
  }
};

export default data;
