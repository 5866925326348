import { IStore } from '@/store/types';
import { IObject } from '@/modules/common/types';
import { SubscriptionGuardProps } from '@/modules/subscription/guards/base/BaseSubscriptionGuard';
import { SubscriptionGuard } from '@/modules/subscription/guards/base';
import { useSelector } from 'react-redux';
import { SubscriptionFeatureData } from '@/modules/subscription/types';

export type ConditionFunction<V = any> = (
  value: V,
  featureValue: SubscriptionFeatureData | null
) => boolean;

interface ReduxSubscriptionGuardProps<
  Store = IObject,
  V = any,
  FeatureValue = any
> extends Omit<SubscriptionGuardProps, 'condition'> {
  /** Name of store on which you want to apply the condition */
  store: keyof IStore;

  /** (optional)  a key of selected store for which you need to apply the condition  */
  property: keyof Store;

  /** (optional) A conditional function which you want to pass to guard child component.  */
  condition: ConditionFunction<V>;
}

const ReduxSubscriptionGuard = <
  Store extends IObject = IObject,
  V = any
>({
  store,
  property,
  feature,
  condition,
  children,
  ...rest
}: ReduxSubscriptionGuardProps<Store, V>) => {
  const reduxValue = useSelector<IStore, V>((state) =>
    property ? state[store][property] : state[store]
  );

  const reduxCondition = (value: SubscriptionFeatureData | null) =>
    condition(reduxValue, value);

  return (
    <SubscriptionGuard feature={feature} condition={reduxCondition} {...rest}>
      {children}
    </SubscriptionGuard>
  );
};

ReduxSubscriptionGuard.displayName = 'ReduxSubscriptionGuard';

export default ReduxSubscriptionGuard;
