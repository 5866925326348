// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon47IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon47Icon(props: Icon47IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g
        clipPath={"url(#62c2Prk_dAv3a)"}
        stroke={"#666"}
        strokeWidth={"1.5"}
        strokeMiterlimit={"10"}
      >
        <path
          d={
            "M11.067 6.592l-1.167-.2m-1.042-.175l-2.041-.35M16.383 7.5l-2.108-.358M6.142 17.217L4.908 7.658m13.225 2.225l-.658 4.409m-2.142 2.641l-6.792 1.775m4.692-11.741l-1.125-.2m-7.283.883a1.992 1.992 0 100-3.983 1.992 1.992 0 000 3.983zm13.516 2.242a1.992 1.992 0 100-3.984 1.992 1.992 0 000 3.984zm-1.125 8.366a1.992 1.992 0 100-3.983 1.992 1.992 0 000 3.983zM6.592 21.15a1.992 1.992 0 100-3.983 1.992 1.992 0 000 3.983z"
          }
        ></path>
      </g>

      <defs>
        <clipPath id={"62c2Prk_dAv3a"}>
          <path
            fill={"#fff"}
            transform={"translate(2 2)"}
            d={"M0 0h20v20H0z"}
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon47Icon;
/* prettier-ignore-end */
