/** Returns DEFAULT position of elements on map page */

import { isThisDeviceIpad } from "../../../events/constants";

export const PADDING_4 = 4;
export const PADDING_8 = 8;
export const PADDING_16 = 16;

export const navbarP = {
  height: isThisDeviceIpad ? 120 : 50,
  position: {
    top: 0,
    left: 0,
  },
};

export const widgetBarP = {
  width: 56,
  position: {
    left: 0,
  },
};

/** Panels: Layer, Estimation layer, Notes, Reports, Snapshot, View */
export const primaryPanelP = {
  position: {
    top: navbarP.height,
    left: widgetBarP.width,
  },
};

export const viewPanelP = {
  width: 248,
  position: {
    top: primaryPanelP.position.top,
    left: primaryPanelP.position.left,
  },
};

export const estimationViewTabsWidthP = {
  serviceMap: 456,
  esitmation: 856,
  breakdown: 856,
};

export const layerPanelStaticP = {
  width: 359,
  position: {
    top: primaryPanelP.position.top,
    left: primaryPanelP.position.left,
  },
};

export const layerPanelEstimationP = {
  width: estimationViewTabsWidthP.serviceMap,
  position: {
    top: primaryPanelP.position.top,
    left: primaryPanelP.position.left,
  },
};

export const nearmapDropdownP = {
  position: {
    bottom: 200,
    left: widgetBarP.width + layerPanelStaticP.width + PADDING_16,
  },
};

export const mapToolBarP = {
  height: 88,
  position: {
    top: navbarP.height,
    left: widgetBarP.width + layerPanelStaticP.width,
    right: 0,
  },
};

export const mapToolBarEstimationP = {
  position: {
    left: widgetBarP.width + layerPanelEstimationP.width,
  },
};

export const notesReportsSnapsPanelP = {
  width: 350,
  position: {
    top: primaryPanelP.position.top,
    left: primaryPanelP.position.left,
  },
};

export const actionCenterP = {
  width: 284,
  position: {
    top: navbarP.height + PADDING_16,
    right: 16,
  },
};

export const quickTipEstimationP = {
  position: {
    top: mapToolBarP.position.top,
    left: widgetBarP.width + layerPanelEstimationP.width,
    right: mapToolBarP.position.right,
  },
};

export const mapControlsP = {
  position: {
    bottom: 16,
    right: 16,
  },
};

export const estimationBreakdownTabP = {
  width: 868,
};

export const primaryPanelCollapsedLeftP = {
  nearmapDropdown: 16,
  mapToolBar: widgetBarP.width,
  quickTip: widgetBarP.width,
};

export const orderDetailsPanelP = {
  width: 280,
  position: {
    top: navbarP.height,
    left: widgetBarP.width,
  },
};

export const tertiaryPanelP = {
  width: 109,
  position: {
    top: navbarP.height,
  },
};

export const awaitingApprovalCardP = {
  width: 366,
  position: {
    top: PADDING_16,
    left: widgetBarP.width + layerPanelStaticP.width + PADDING_16,
  },
};

export const parcelConfirmationPanelP = {
  width: 264,
  position: {
    top: 0,
    left: 0,
  },
};

export const orderCartPanelP = {
  width: 264,
  position: {
    top: navbarP.height,
    left: widgetBarP.width,
  },
};

export const parcelToolbarP = {
  position: {
    top: PADDING_16,
    left: parcelConfirmationPanelP.width + PADDING_16,
  },
};

export const orderHistoryPanelP = {
  width: 264,
  position: {
    left: widgetBarP.width,
  },
};

export const featureSelectionPopupP = {
  width: 616,
  position: {
    top: PADDING_16,
    left: orderCartPanelP.width + PADDING_16,
  },
};

export const catalogueSelectionPopupP = {
  width: 616,
  position: {
    top: PADDING_16,
    left: orderCartPanelP.width + PADDING_16,
  },
};
