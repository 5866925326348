import { NotifyError } from '@/helpers/notification-utils';

import { trackEvents } from '@/helpers/utilities';
import {
  createOrganisationFailure,
  createOrganisationRequest,
  createOrganisationSuccess,
  getOrganisationFailure,
  getOrganisationRequest,
  getOrganisationsuccess,
  getOrganisationSuggestionFailure,
  getOrganisationSuggestionRequest,
  getOrganisationSuggestionSuccess,
  getOrganisationViaCodeFailure,
  getOrganisationViaCodeRequest,
  getOrganisationViaCodeSuccess,
  joinOrganisationFailure,
  joinOrganisationRequest,
  joinOrganisationSuccess,
  nameOfOrganisationUpdated,
  updateOrganisationFailure,
  updateOrganisationRequest,
  updateOrganisationsuccess,
} from './actions';
import { getOrgListThunk } from '../teamManagement/thunk';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { getNamesArrayFromObjArray } from '../../helpers/utilities';
import { showNotification } from '../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../components/storybook/NotificationToast/types';
import { getWorkspaces } from '@/store/workspace/thunk';

const axiosInstance = getAxiosInstance();

export const getOrganisationSuggestionsThunk = () => (dispatch) => {
  dispatch(getOrganisationSuggestionRequest());

  axiosInstance
    .get('/api/idam/v1/user/workspace/suggestions')
    .then(async (response) => {
      dispatch(getOrganisationSuggestionSuccess(response.data.data));
    })
    .catch((err) => {
      NotifyError(err.message);
      dispatch(getOrganisationSuggestionFailure());
    });
};

export const getOrganisationThunk = (payload) => (dispatch) => {
  dispatch(getOrganisationRequest());

  axiosInstance
    .get(`/api/v1/organization/${payload}`)
    .then(async (response) => {
      const data = response.data;
      dispatch(getOrganisationsuccess(data));
    })
    .catch((err) => {
      NotifyError(err.message);
      dispatch(getOrganisationFailure());
    });
};

export const updateOrganisationThunk = (payload) => (dispatch) => {
  dispatch(updateOrganisationRequest());

  var data = new FormData();
  data.append('na', payload.organisation);
  data.append('logo', payload.organisationImage);

  axiosInstance
    .put(`/api/v1/organization/${payload.organisationId}`, data)
    .then(async (response) => {
      if (response.status === 200) {
        dispatch(updateOrganisationsuccess(payload));
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, response.data.msg);
        dispatch(getOrgListThunk(undefined, true));
        dispatch(getWorkspaces());
        dispatch(getOrganisationThunk(payload.organisationId));
      } else {
        NotifyError('Failed');
        dispatch(updateOrganisationFailure());
      }
    })
    .catch((err) => {
      NotifyError(err.message);
      dispatch(updateOrganisationFailure());
    });
};
export const nameUpdatedOrganisationThunk = () => (dispatch) => {
  dispatch(nameOfOrganisationUpdated());
};

export const getOrganisationViaCodeThunk = (payload) => (dispatch) => {
  dispatch(getOrganisationViaCodeRequest());
  const handleError = () => {
    dispatch(getOrganisationViaCodeFailure());
    trackEvents('org-invitation-form_submit__failure', {
      email: payload.email,
      inviteCode: payload.inviteCode,
    });
  };

  axiosInstance
    .post('/api/idam/v1/workspace/invite-code', {
      inviteCode: payload.inviteCode,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(getOrganisationViaCodeSuccess(response.data));
      } else {
        handleError();
      }
    })
    .catch(handleError);
};

export const createOrganisationThunk = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(createOrganisationRequest());
    axiosInstance
      .post('/api/v1/organization/', {
        na: payload.organisation,
      })
      .then((response) => {
        showNotification(
          NOTIFICATIONS_TYPES.SUCCESS,
          'Workspace created successfully!'
        );
        const authPathnames = ['auth', 'signup'];
        const isSignupPage = authPathnames.some((path) =>
          window.location.pathname.includes(path)
        );
        if (isSignupPage) {
          trackEvents('signup_process_complete');
        }
        dispatch(createOrganisationSuccess(payload));
        dispatch(getOrgListThunk(undefined, true));
        dispatch(getWorkspaces());
        trackEvents('create-new-org_submit__successful', {
          createdBy: payload.email,
          createDate: new Date(),
          newWorkspaceName: payload.organisation,
          hasExistingWorkspace: Boolean(payload.orgData.length),
          existingWorkspaceCount: Number(payload.orgData.length),
          existingWorkspaceNames: getNamesArrayFromObjArray(payload.orgData),
        });
        resolve(response);
      })
      .catch((error) => {
        dispatch(
          createOrganisationFailure(error?.response?.data?.msg || error?.msg)
        );
        trackEvents('create-new-org_submit__failure', {
          email: payload.email,
          orgName: payload.organisation,
        });
        reject(error);
      });
  });
};

export const joinOrganisationThunk = (payload) => (dispatch) => {
  dispatch(joinOrganisationRequest());
  axiosInstance
    .post('/api/v1/organization/join/', {
      inv_code: payload.inviteCode,
    })
    .then((response) => {
      if (response.status === 200) {
        showNotification(
          NOTIFICATIONS_TYPES.SUCCESS,
          'Workspace joined Successfully'
        );
        dispatch(joinOrganisationSuccess(payload));
        trackEvents('org-invitation-form_submit__successful', {
          email: payload.email,
          inviteCode: payload.inviteCode,
        });
      } else {
        NotifyError('Workspace Code is Incorrect', '', 3000);
        dispatch(joinOrganisationFailure());
        trackEvents('org-invitation-form_submit__failure', {
          email: payload.email,
          inviteCode: payload.inviteCode,
        });
      }
    })
    .catch(() => {
      NotifyError('Workspace Code is Incorrect', '', 3000);
      dispatch(joinOrganisationFailure());
      trackEvents('org-invitation-form_submit__failure', {
        email: payload.email,
        inviteCode: payload.inviteCode,
      });
    });
};

export const joinOrgThunk = (payload) => (dispatch) => {
  dispatch(joinOrganisationRequest());
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('org_id', payload.orgId);
    formData.append('org_name', payload.orgName);
    formData.append('admin_id', payload.adminId);
    formData.append('admin_email', payload.adminEmail);

    axiosInstance
      .post('/api/user/orgJoin/', formData)
      .then(async (res) => {
        const data = res.data;
        if (res.status === 200) {
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            'Workspace joined Successfully'
          );
          dispatch(joinOrganisationSuccess(payload));
          trackEvents('select-workspace-form_submit__successful', {
            orgName: payload.orgName,
            adminEmail: payload.adminEmail,
            email: payload.email,
          });
          resolve(true);
        } else {
          NotifyError(data.error);
          dispatch(joinOrganisationFailure());
          trackEvents('select-workspace-form_submit__failure', {
            orgName: payload.orgName,
            adminEmail: payload.adminEmail,
            email: payload.email,
          });
        }
        resolve(true);
      })
      .catch((err) => {
        dispatch(joinOrganisationFailure());
        NotifyError(err?.message);
        trackEvents('select-workspace-form_submit__failure', {
          orgName: payload.orgName,
          adminEmail: payload.adminEmail,
          email: payload.email,
        });
      });
  });
};
