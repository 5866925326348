import { Button, Popover, Row, Select, Tooltip } from 'antd';
import {
  convertTagsToArray,
  STATUSES_BEFORE_BULK_ORDER_PLACEMENT,
} from '@myProperties/utils/enum';
import TagIconNavDisabled from '@myProperties/components/properties/assets/tagDisabled.svg';
import TagIconNav from '@/components/containers/navbar/assets/TagIconBorder.svg';
import React, { useCallback, useState } from 'react';
import { BULK_PATH } from '@/components/containers/navbar/Navbar';
import Text from '../../../../elements/text/Text';
import './style.less';
import { ReactComponent as Plus } from '../../../../../assets/icons/plus-grey.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import PropertyApi from '../../../../pages/myProperties/api/properties';
import { trackEvents } from '../../../../../helpers/utilities';
import {
  addAllTags,
  updateOrderTags,
} from '../../../../../store/order/actions';
import { updateTagOrderList } from '../../../../../store/bulkOrder/actions';
import { convertTagArrayToObject } from '../../../../pages/myProperties/utils/enum';
import { updateDashboardTags } from '../../../../../store/dashboard/action';
import useActiveTab from '@/components/pages/myProperties/hooks/useActiveTab';
import { showNotification } from '../../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../storybook/NotificationToast/types';

interface AddTagToolProps {
  currentPath: string;
  addOrderIdForTag: any;
  orderIdForTag: any;
  selectedOrderList: any;
  addTagsForAOrder: any;
  bulkOrderSummeryData: any;
  isBulkOrderTagModal: any;
  orderTags: any;
  setAddTagVisible: any;
  isRouteAvail: any;
}

const AddTagTool = ({
  currentPath,
  orderIdForTag,
  selectedOrderList,
  addOrderIdForTag,
  addTagsForAOrder,
  bulkOrderSummeryData,
  isBulkOrderTagModal,
  setAddTagVisible,
  orderTags,
  isRouteAvail = true,
}: AddTagToolProps) => {
  const dispatch = useDispatch();
  const activeTab = useActiveTab();

  const [searchValue, setSearchValue] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);
  const { allAvailableTags, tags, suggestedTags, bulkOrderTagModal } =
    useSelector((state: any) => state.order);
  const [btnLoading, setBtnLoading] = useState(false);
  const { orderData } = useSelector((state: any) => state.order);

  const { Option } = Select;

  const [openAddTags, setOpenAddTags] = useState(false);

  const hideAddTags = () => {
    setOpenAddTags(false);
  };

  const handleOpenAddTagsChange = (newOpen: boolean) => {
    setOpenAddTags(newOpen);
  };

  const PopoverContent = () => {
    return (
      <div className='sr-header-add-tags'>
        <Row
          className='row'
          style={{
            borderBottom: '1px solid #EAEAEA',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Text type='h3'>Add tags</Text>
          <div onClick={hideAddTags} style={{ cursor: 'pointer' }}>
            <CloseIcon />
          </div>
        </Row>
        <Row className='row'>
          <Select
            className='addTagModal__select'
            mode='multiple'
            style={{ width: '100%' }}
            placeholder='Enter tag name or select from suggested '
            onSearch={handleSearch}
            searchValue={searchValue}
            onChange={handleTag}
            value={selectedValues}
            onSelect={handleSelect}
            open={searchValue !== ''}
            defaultActiveFirstOption={false}
            showSearch={true}
            onClick={handleClick}
            dropdownClassName='tag-dropdowns'
            bordered={false}
            // onDeselect={handleDD}
          >
            {searchValue && !allAvailableTags.includes(searchValue) && (
              <Option value={searchValue}>
                <Text type={'p58'} color='light-gray'>
                  Add New Tag
                </Text>{' '}
                "{searchValue}"
              </Option>
            )}
            {allAvailableTags.map((value, i) => (
              <Option key={i} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </Row>
        {/* Suggested */}
        <Row className='suggested-row'>
          <Text type='p19_2'>Suggested:</Text>
          {suggestedTags.map((element) => {
            return (
              <div
                className='suggested-tag'
                onClick={() => handelSuggestedTagClick(element)}
              >
                <div className='overflow'>
                  <Text type='p23'>{element}</Text>
                </div>
              </div>
            );
          })}
        </Row>
        {/* Button */}
        <Row className='row'>
          <Button
            className={
              !(tags.length || selectedValues.length) ? 'disabled' : ''
            }
            style={{ height: '42px', width: '100%' }}
            type='primary'
            onClick={submitTag}
            loading={btnLoading}
            disabled={!(tags.length || selectedValues.length)}
          >
            Add tags
          </Button>
        </Row>
      </div>
    );
  };

  const addTags = (e) => {
    if (currentPath === '/createOrder') e.preventDefault();
    else {
      // setAddTagVisible(true);

      setSelectedValues(
        orderTags
          ? orderTags.map((ele) => {
              return ele.tagName;
            })
          : selectedOrderList?.data?.tag.map((ele) => {
              return ele.tagName;
            })
      );
      addOrderIdForTag(
        currentPath.substring(1, 8) !== 'project'
          ? orderIdForTag
          : selectedOrderList?.data?.id ?? orderIdForTag
      );
      if (currentPath.substring(1, 11) === BULK_PATH) {
        addTagsForAOrder(
          selectedOrderList?.data?.tag?.length > 0
            ? convertTagsToArray(selectedOrderList?.data?.tag)
            : []
        );
        if (
          STATUSES_BEFORE_BULK_ORDER_PLACEMENT.map((status) =>
            status.toLowerCase()
          ).includes(bulkOrderSummeryData?.status)
        ) {
          isBulkOrderTagModal(true);
        } else {
          isBulkOrderTagModal(false);
        }
      } else {
        addTagsForAOrder(
          orderTags.length > 0 ? convertTagsToArray(orderTags) : []
        );
        isBulkOrderTagModal(false);
      }
    }
  };

  const handleSearch = useCallback((e) => {
    setSearchValue((prevState) => {
      if (e) return e;
      else if (prevState.length === 1) return e;
      return prevState;
    });
    // if(e)
    // setSearchValue(e);
    // else if(e.t)
  }, []);

  const handleTag = useCallback((e) => {
    setSelectedValues(e);
  }, []);

  const handleSelect = useCallback((e) => {
    setSearchValue('');
  }, []);

  const handleClick = useCallback(
    (e) => {
      if (e.target.className === 'ant-select-item-option-content') return;
      if (!searchValue) return;

      setSelectedValues((prevState: any) => {
        if (!prevState.includes(searchValue)) {
          const demoSearch = [...prevState, searchValue];
          setSearchValue('');
          return demoSearch;
        }

        setSearchValue('');
        return prevState;
      });
    },
    [searchValue]
  );

  const handelSuggestedTagClick = useCallback((tagValue) => {
    setSelectedValues((prevState: any) => {
      if (!prevState.includes(tagValue)) return [...prevState, tagValue];

      // if already added to list then pass origin list
      return prevState;
    });
  }, []);

  const handleSuccessPromise = useCallback(() => {
    showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Tag updated successfully');
    if (!isRouteAvail) {
      // setVisible(false);
    } else {
      history.back();
    }

    if (selectedOrderList?.data) {
      return dispatch(
        updateTagOrderList({
          orderID: selectedOrderList?.data?.id,
          tagArray: convertTagArrayToObject(selectedValues),
        })
      );
    } else if (orderData) {
      dispatch(updateOrderTags(convertTagArrayToObject(selectedValues)));
    }
    dispatch(
      updateDashboardTags({
        tab: activeTab,
        data: {
          orderId: orderIdForTag,
          tags: convertTagArrayToObject(selectedValues),
        },
      })
    );
  }, [
    activeTab,
    dispatch,
    history,
    isRouteAvail,
    orderData,
    orderIdForTag,
    selectedOrderList?.data,
    selectedValues,
  ]);

  const handleBulkAddTagBeforeOrderPlace = useCallback(() => {
    PropertyApi.addTagApiBeforeOrderPlaced(selectedValues, orderIdForTag)
      .then(() => {
        setBtnLoading(false);
        handleSuccessPromise();
      })
      .catch((err) => {
        console.error(err);
        setBtnLoading(false);
        new Error(err);
      });
  }, [handleSuccessPromise, orderIdForTag, selectedValues]);

  const submitTag = useCallback(async () => {
    if (!orderIdForTag) {
      if (!isRouteAvail) {
        // setVisible(false);

        return;
      }

      history.back();
    }

    setBtnLoading(true);

    if (bulkOrderTagModal) {
      return handleBulkAddTagBeforeOrderPlace();
    }

    await PropertyApi.addTagApi(selectedValues, orderIdForTag)
      .then(() => {
        setBtnLoading(false);
        let tempTag = [...selectedValues, ...allAvailableTags];
        dispatch(addAllTags(tempTag));
        trackEvents('tag_updated', {
          orderID: orderIdForTag,
          updated_tag_list: selectedValues,
        });
        handleSuccessPromise();
      })
      .catch((err) => {
        console.error(err);
        setBtnLoading(false);
        new Error(err);
      });
    hideAddTags();
  }, [
    orderIdForTag,
    bulkOrderTagModal,
    selectedValues,
    isRouteAvail,
    history,
    handleBulkAddTagBeforeOrderPlace,
    handleSuccessPromise,
  ]);

  return (
    <Row className='add-tags-section'>
      <Row>
        <div style={{ marginRight: '5px' }}>
          {currentPath === '/createOrder' ? (
            <Tooltip
              title={'This option is available after the order is created'}
            >
              <img width='15px' src={TagIconNavDisabled} alt='TagIconNav' />
            </Tooltip>
          ) : (
            <img src={TagIconNav} alt='TagIconNav' />
          )}
        </div>

        <div>
          {currentPath === '/createOrder' ? (
            <Tooltip
              title={'This option is available after the order is created'}
            >
              <div
                className='add-property-tags'
                style={{
                  cursor: 'pointer',
                }}
              >
                <Text type='p23'>+ Property tag</Text>
              </div>
            </Tooltip>
          ) : currentPath.substring(1, 8) !== 'project' ? (
            selectedOrderList?.data?.tag?.length > 0 ? (
              // When tags present in bulk ordeer
              <div style={{ display: 'flex' }}>
                {/* First Tag */}
                <div className='added-tag'>
                  <div className='overflow'>
                    <Text type='p23'>
                      {selectedOrderList?.data?.tag[0].tagName}
                    </Text>
                  </div>
                </div>

                {/* Second tag */}
                {selectedOrderList?.data?.tag?.length > 1 && (
                  <div className='added-tag'>
                    <div className='overflow'>
                      <Text type='p23'>
                        {selectedOrderList?.data?.tag[1].tagName}
                      </Text>
                    </div>
                  </div>
                )}

                {/* + x tags */}
                <Popover
                  trigger='click'
                  content={
                    <div
                      style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}
                    >
                      {selectedOrderList?.data?.tag.slice(2).map((element) => {
                        return (
                          <div className='tag-container'>
                            <div className='overflow'>
                              <Text type='p30'>{element.tagName}</Text>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  }
                  overlayClassName='sr-header-view-tags'
                  placement='bottom'
                >
                  {selectedOrderList?.data?.tag?.length > 2 && (
                    <div className='remaining-tags'>
                      <Text type='p23'>
                        +{selectedOrderList?.data?.tag.length - 2}
                      </Text>
                    </div>
                  )}
                </Popover>

                {/* Plus Symbol */}
                <Popover
                  trigger='click'
                  content={PopoverContent}
                  placement='bottom'
                  visible={openAddTags}
                  onVisibleChange={handleOpenAddTagsChange}
                >
                  <Tooltip
                    title={
                      currentPath === '/createOrder'
                        ? 'This option is available after the order is created'
                        : 'Add Tag'
                    }
                  >
                    <div
                      className='add-plus'
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        addTags(e);
                      }}
                    >
                      <Plus />
                    </div>
                  </Tooltip>
                </Popover>
              </div>
            ) : (
              // When no tags present in bulk order
              <Popover
                trigger='click'
                content={PopoverContent}
                placement='bottom'
                visible={openAddTags}
                onVisibleChange={handleOpenAddTagsChange}
              >
                <Tooltip title={'Add Tag'}>
                  <div
                    className='add-property-tags'
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      addTags(e);
                    }}
                  >
                    <Text type='p23'>+ Property tag</Text>
                  </div>
                </Tooltip>
              </Popover>
            )
          ) : orderTags?.length > 0 ? (
            // When tags present in single order
            <div style={{ display: 'flex' }}>
              {/* First Tag */}
              <div className='added-tag'>
                <div className='overflow'>
                  <Text type='p23'>{orderTags[0].tagName}</Text>
                </div>
              </div>

              {/* Second tag */}
              {orderTags?.length > 1 && (
                <div className='added-tag'>
                  <div className='overflow'>
                    <Text type='p23'>{orderTags[1].tagName}</Text>
                  </div>
                </div>
              )}

              {/* + x tags */}
              <Popover
                trigger='click'
                content={
                  <div
                    style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}
                  >
                    {orderTags.slice(2).map((element) => {
                      return (
                        <div className='tag-container'>
                          <div className='overflow'>
                            <Text type='p30'>{element.tagName}</Text>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                }
                overlayClassName='sr-header-view-tags'
                placement='bottom'
              >
                {orderTags?.length > 2 && (
                  <div className='remaining-tags'>
                    <Text type='p23'>+{orderTags.length - 2}</Text>
                  </div>
                )}
              </Popover>

              {/* Plus Symbol */}
              <Popover
                trigger='click'
                content={PopoverContent}
                placement='bottom'
                visible={openAddTags}
                onVisibleChange={handleOpenAddTagsChange}
              >
                <Tooltip
                  title={
                    currentPath === '/createOrder'
                      ? 'This option is available after the order is created'
                      : 'Add Tag'
                  }
                >
                  <div
                    className='add-plus'
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      addTags(e);
                    }}
                  >
                    <Plus />
                  </div>
                </Tooltip>
              </Popover>
            </div>
          ) : (
            // When no tags present in single order
            <Popover
              trigger='click'
              content={PopoverContent}
              placement='bottom'
              visible={openAddTags}
              onVisibleChange={handleOpenAddTagsChange}
            >
              <Tooltip title={'Add Tag'}>
                <div
                  className='add-property-tags'
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    addTags(e);
                  }}
                >
                  <Text type='p23'>+ Property tag</Text>
                </div>
              </Tooltip>
            </Popover>
          )}
        </div>
      </Row>
    </Row>
  );
};

export default AddTagTool;
