import { Action } from 'redux';
import { WorkspaceActionType } from '@/store/workspace/action.types';
import { Workspace, WorkspaceSummary } from '@common/models/workspace';

export interface WorkspaceAction<D = any> extends Action<WorkspaceActionType> {
  payload?: D;
}

export const setWorkspaces = (data: Workspace[]): WorkspaceAction => ({
  type: WorkspaceActionType.SET_WORKSPACES,
  payload: data,
});

export const setWorkspaceSummary = (
  data: WorkspaceSummary
): WorkspaceAction => ({
  type: WorkspaceActionType.SET_SUMMARY,
  payload: data,
});
