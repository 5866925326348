import Pinned from '../../assets/common/pinned';
import Unpinned from '../../assets/common/unpinned';
import React from 'react';
import { Typography } from '@siterecon/sr-styles/dist/elements';
import Box from '@siterecon/sr-styles/dist/elements/Box';
import { SubscriptionChip } from '../../../../../../modules/subscription/components/Chips';
import { SubscriptionPlanIcons } from '../../../../../../modules/subscription/components/Chips/constant';

export const toolbarMenuItemSimple = (ele: any, handlePinning: any, isEssential: boolean) => {
  return (
    <Box
      className='tool'
      onClick={() => {
        if (!ele.perm) handlePinning(ele.key);
      }}
      key={ele.label}
    >
      <Box className='image'>{ele.icon}</Box>
      <Typography fontSize={12} fontWeight={500} lineHeight={'18px'}>
        {ele.label}{' '}
        {ele.shortcut && (
          <Typography
            fontSize={10}
            fontWeight={400}
            lineHeight={'15px'}
            style={{ color: '#999999' }}
          >
            {'('}
            {ele.shortcut}
            {')'}
          </Typography>
        )}{' '}
      </Typography>

      {isEssential && ele.label === 'Path' &&
        <SubscriptionChip iconProp={SubscriptionPlanIcons.PROFESSIONAL} />
      }

      {ele.perm ? (
        <></>
      ) : (
        <Box className='pin'>{ele.pinned ? <Pinned /> : <Unpinned />}</Box>
      )}
    </Box>
  );
};
