import { BetaFeature, GuardProps } from '@/modules/common/types';
import { WrapperProps } from '@/modules/common/components/Wrapper';
import useBetaRelease from '@/modules/common/guards/BetaRelease/useBetaRelease';
import WithBetaRelease from '@/modules/common/guards/BetaRelease/WithBetaRelease';
import BaseGuard from '@/modules/common/guards/BaseGuard';
import { FC, ReactNode, useMemo } from 'react';

interface BetaReleaseGuardProps extends GuardProps {
  /** Feature name which we want to guard. Use features from {@link BetaFeature} enum for consistency */
  feature: BetaFeature;

  /** (Optional) A Fall-back component which should be rendered if feature is not enabled */
  fallback?: ReactNode;

  /** (Optional) A boolean to finally take the opposite of canRender variable */
  negate?: boolean;
}

const BetaReleaseGuard: FC<BetaReleaseGuardProps & WrapperProps> = ({
  feature,
  children,
  fallback,
  or,
  negate,
  ...rest
}) => {
  const enabled = useBetaRelease(feature);

  const canRender = useMemo(() => {
    let canRender = false;
    if (typeof or !== 'undefined') {
      canRender = enabled || or;
    }
    canRender = enabled;
    if (negate) {
      return !canRender;
    }
    return canRender;
  }, [enabled, or]);

  return (
    <BaseGuard condition={canRender} fallback={fallback} {...rest}>
      {children}
    </BaseGuard>
  );
};

BetaReleaseGuard.displayName = 'BetaReleaseGuard';

export { useBetaRelease, WithBetaRelease };

export default BetaReleaseGuard;
