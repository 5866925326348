import { Row, Select } from 'antd';
import React from 'react';
import Text from '../../../../../../../../elements/text/Text';

const { Option } = Select;

const ListPeople = ({ showDropDown, guest, name, me, role, email }) => {
  return (
    <Row align='center' justify='space-between' className='mb-4'>
      <Row justify='start' align='middle' style={{ flex: 1 }}>
        <Row
          className='addPeopleModal__profile mr-2'
          justify='center'
          align='middle'
        >
          <Text type='p29' color='dark-gray'>
            {name ? name[0] : email[0]}
          </Text>
        </Row>
        <Text type='p23' color='dark-gray'>
          {name ? name : email} {me ? '(You)' : ''}
        </Text>
        {guest && (
          <Row
            wrap={false}
            className='guestTag ml-2'
            justify='center'
            align='middle'
          >
            <Text type='p35' color='white'>
              GUEST
            </Text>
          </Row>
        )}
      </Row>

      {!showDropDown && (
        <Text type='p50' color='dark-gray' className='px-2'>
          {role}
        </Text>
      )}

      {showDropDown && (
        <Select defaultValue='View' bordered={false}>
          <Option value='Can Edit'>Can Edit</Option>
          <Option value='View'>View</Option>
        </Select>
      )}
    </Row>
  );
};

export default ListPeople;
