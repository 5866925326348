import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { replaceParams } from '@/helpers/utilities/linkUtils';
import './style.less';
import { copyToClipboard } from '@/helpers/utilities/clickBoardUtils';
import { ReactComponent as CopyIcon } from '@/assets/copyImage.svg';
import { Tooltip } from 'antd';
import React from 'react';
import { NotifyError } from '@/helpers/notification-utils';
import BetaReleaseGuard from '@/modules/common/guards/BetaRelease';
import { BetaFeature } from '@/modules/common/types';
import { showNotification } from '../../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../storybook/NotificationToast/types';

const axiosInstance = getAxiosInstance();

const downloadGeojson = (url) => {
  axiosInstance
    .get(url, {
      Accept: 'application/json',
      responseType: 'text',
    })
    .then((result) => {
      copyToClipboard(JSON.stringify(result.data));
      showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'GeoJson copied');
    });
};

const copyGeoJsonIcon = (size, fn) => {
  let iconSize = '10';
  if (size === 'large') {
    iconSize = '20';
  }

  return (
    <BetaReleaseGuard feature={BetaFeature.GeoJsonDownload}>
      <Tooltip placement='top' title='Copy GeoJson'>
        <span style={{ cursor: 'pointer' }} onClick={fn} className='d-flex'>
          <CopyIcon height={iconSize} />
        </span>
      </Tooltip>
    </BetaReleaseGuard>
  );
};

export const downloadLayerGeoJson = (viewId, layerId, size = 'small') => {
  const onDownloadRequest = (viewId, layerId) => {
    if (!viewId || !layerId) {
      NotifyError('No layer is selected');
    }
    const url = replaceParams(API_ENDPOINTS.DOWNLOAD_LAYER_GEOJSON, {
      ':viewId': viewId,
      ':layerId': layerId,
    });
    downloadGeojson(url);
  };

  return copyGeoJsonIcon(size, () => onDownloadRequest(viewId, layerId));
};

export const downloadComponentGeoJson = (
  viewId,
  layerId,
  componentId,
  size = 'small'
) => {
  const onDownloadRequest = (viewId, layerId, componentId) => {
    if (!viewId || !layerId || !componentId) {
      NotifyError('No layer component is selected');
    }
    const url = replaceParams(API_ENDPOINTS.DOWNLOAD_LAYER_COMPONENT_GEOJSON, {
      ':viewId': viewId,
      ':layerId': layerId,
      ':componentId': componentId,
    });
    downloadGeojson(url);
  };

  return copyGeoJsonIcon(size, () =>
    onDownloadRequest(viewId, layerId, componentId)
  );
};
