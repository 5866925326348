import React, { cloneElement, forwardRef, useMemo } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import { IObject } from '@/modules/common/types';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';

export interface WrapperProps extends IObject {
  disabled?: boolean;
  tooltip?: string | TooltipProps;
}

interface WrapperComponentProps extends WrapperProps {
  children: JSX.Element;
}

const WrapperPropKeys = ['disabled', 'tooltip'];

const Wrapper = forwardRef<HTMLElement, WrapperComponentProps>(
  ({ disabled, tooltip, children, onClick, ...rest }, ref) => {
    const isDisabled = useMemo(() => {
      if (disabled || typeof tooltip !== 'undefined') return true;

      if (
        React.isValidElement(children) &&
        typeof (children.props as any)?.disabled !== 'undefined'
      ) {
        /** If the provided children already has a disabled prop */
        return (children.props as any)?.disabled;
      }

      return false;
    }, [disabled, children, tooltip]);

    const content = cloneElement(
      React.isValidElement(children) ? children : <>{children}</>,
      {
        disabled: isDisabled,
        ...rest,
        ref: ref,

        /** Override the default onClick event of children if disabled is provided */
        onClick: isDisabled ? undefined : children.props?.onClick || onClick,
      }
    );

    if (tooltip) {
      const tooltipProps: TooltipProps =
        typeof tooltip === 'string' ? { title: tooltip } : tooltip;

      return <Tooltip {...tooltipProps}>{content}</Tooltip>;
    }

    if (React.isValidElement(content)) {
      return content as JSX.Element;
    }

    return <>{content}</>;
  }
);

/** Extracts wrapper Props from given list of props */
export const extractProps = (props: any): [WrapperProps, IObject] => {
  if (!props || !Object.keys(props).length) return [{}, {}];

  /** Filter the Props which are provided as "undefined" */
  const filteredProps = omitBy(props, (value) => typeof value === 'undefined');

  return [
    pick(filteredProps, WrapperPropKeys),
    omit(filteredProps, WrapperPropKeys),
  ];
};

export default Wrapper;
