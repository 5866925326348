import React from 'react';
import useViewSelectionOrCreationUtil from '../hooks/useViewSelectionOrCreationUtil';

const withViewSelectionOrCreationUtil = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => {
  const WrappedComponent: React.FC<P> = (props) => {
    const {
      handleEstimationViewClick,
      handleStaticViewClick,
      getLinkedStaticView,
    } = useViewSelectionOrCreationUtil();

    return (
      <Component
        {...props}
        handleEstimationViewClick={handleEstimationViewClick}
        handleStaticViewClick={handleStaticViewClick}
        getLinkedStaticView={getLinkedStaticView}
      />
    );
  };

  return WrappedComponent;
};

export default withViewSelectionOrCreationUtil;