import React from 'react';
import './style.less';

const transformColor = (color) => {
  let value;
  switch (color) {
    case 'black':
      value = '#000000';
      break;
    case 'dark-gray':
      value = '#333333';
      break;
    case 'light-gray':
      value = '#999999';
      break;
    case 'light-brown':
      value = '#BA6000';
      break;
    case 'dark-brown':
      value = '#D08B5B';
      break;
    case 'nero':
      value = '#252525';
      break;
    case 'silver':
      value = '#BFBFBF';
      break;
    case 'magenta':
      value = '#7B61FF';
      break;
    case 'white':
      value = '#ffffff';
      break;
    case 'error':
      value = '#BA6000';
      break;
    case 'error2':
      value = '#FF000F';
      break;
    case 'gray-shade':
      value = '#666666';
      break;
    case 'light-green':
      value = '#15CF5F';
      break;
    case 'yellow':
      value = '#ffd02b';
      break;
    case 'primary-dark':
      value = '#F3A612';
      break;
    case 'blue':
      value = '#2575EE';
      break;
    case 'blue-dark':
      value = '#2575EE';
      break;
    case 'error-red':
      value = '#FF2424';
      break;
    case 'error-red2':
      value = '#EE1313';
      break;
    case 'error-red3':
      value = '#ff5151';
      break;
    case 'text-gray':
      value = '#242634';
      break;
    case 'light-color':
      value = '#3C3C434D';
      break;
    case 'dark-blue':
      value = '#274DD3';
      break;
    case 'success':
      value = '#6cbb2f';
      break;
    case 'dark-blue2':
      value = '#0A3AE3';
      break;
    case 'red':
      value = '#EB5757';
      break;
    case 'sky-blue':
      value = '#15929A';
      break;
    case 'blue-3':
      value = '#5E69E2';
      break;
    case 'gray-color':
      value = '#242634';
      break;
    case 'dark-2':
      value = '#464E5F';
      break;
    case 'green-2':
      value = '#6CBB2F';
      break;
    case 'green-3':
      value = '#6DD307';
      break;
    case 'light-blue-2':
      value = '#0094FF';
      break;
    case 'error-3':
      value = '#E44949';
      break;
    case 'sky-blue-2':
      value = '#13C2C2';
      break;
    case 'approve-green':
      value = '#52C41A';
      break;
    case 'orange':
      value = '#FF7700';
      break;
    case 'green':
      value = '#83BC75';
      break;
    case 'copy-code':
      value = '#3E60D5';
      break;
    case 'delete-red':
      value = '#DC2626';
      break;
    case 'mid-dark':
      value = '#111827';
      break;
    case 'light-dark':
      value = '#6B7280';
      break;
    case 'link':
      return '#1055EB';
    default:
      break;
  }
  return value;
};

const Text = ({
  type = 'p1',
  color = 'black',
  component: Component = 'span',
  underlined = false,
  ...props
}) => {
  const transformedColor = transformColor(color);
  const opacity = props.opacity ? props.opacity : '1';

  return (
    <Component
      {...props}
      style={{
        color: transformedColor,
        marginTop: props.marginTop,
        fontWeight: props.fontWeight,
        opacity: opacity,
        textAlign: props.align || 'inherit',
        ...props.style,
      }}
      className={`text text--${type} ${underlined ? 'text-underline' : ''} ${
        props.className ? props.className : ''
      }`}
    >
      {props.children}
    </Component>
  );
};
export default Text;
