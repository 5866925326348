// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Subtract10IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Subtract10Icon(props: Subtract10IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 25 27"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M1 4.056A3.056 3.056 0 014.056 1h17.11a3.055 3.055 0 013.056 3.056V23.61a3.055 3.055 0 01-3.055 3.056H4.056A3.056 3.056 0 011 23.61V4.056zm20.167-1.834H4.056a1.834 1.834 0 00-1.834 1.834V23.61c0 1.013.821 1.833 1.834 1.833h17.11c1.013 0 1.834-.82 1.834-1.833V4.056c0-1.013-.82-1.834-1.833-1.834z"
        }
        fill={"currentColor"}
        stroke={"currentColor"}
        strokeWidth={".611"}
      ></path>
    </svg>
  );
}

export default Subtract10Icon;
/* prettier-ignore-end */
