import { DownloadOutlined } from '@/components/elements/icons/antd-icons';
import { Button, Row } from 'antd';
import { useCallback } from 'react';
import { FileUploadStatus } from '../../bulk-project-upload/upload-file/UploadStatus';

const Footer = ({
  errorBulkOrderDetails,
  setVisible,
  setCSVFileUpdating,
  setOpen,
  setTypeOfModal,
}) => {
  const uploadSheetFunction = useCallback(() => {
    setVisible(true);
    setCSVFileUpdating(true);
    setOpen(false);
    setTypeOfModal(FileUploadStatus.uploadFile);
  }, [setCSVFileUpdating, setVisible, setOpen, setTypeOfModal]);

  const downloadableCSVLink = errorBulkOrderDetails?.file?.csv_link;
  const handleDownloadClick = useCallback(() => {
    if (downloadableCSVLink) {
      const tempElement = document.createElement('a');
      tempElement.href = downloadableCSVLink;
      tempElement.download = 'orderList.csv';
      document.body.appendChild(tempElement); // This Is Required For Logic To Work In FireFox
      tempElement.click();
      document.body.removeChild(tempElement);
    }
  }, [downloadableCSVLink]);

  return (
    <Row
      align='middle'
      justify='space-between'
      wrap={false}
      className={'mb-5 footer'}
    >
      {errorBulkOrderDetails && (
        <Row onClick={handleDownloadClick} style={{ cursor: 'pointer' }}>
          <Button
            type='text'
            className={'csv-btn-error'}
            icon={<DownloadOutlined style={{ fontSize: '12px' }} />}
          >
            Download Error Sheet
          </Button>
        </Row>
      )}

      <Row onClick={uploadSheetFunction} style={{ cursor: 'pointer' }}>
        <Button className={'csv-btn-update'} type={'primary'}>
          Update CSV
        </Button>
      </Row>
    </Row>
  );
};

export default Footer;
