// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Group427320404IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Group427320404Icon(props: Group427320404IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 2 10"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <ellipse
        cx={"1"}
        cy={".999"}
        rx={"1"}
        ry={".999"}
        fill={"currentColor"}
      ></ellipse>

      <ellipse
        cx={"1"}
        cy={"4.518"}
        rx={"1"}
        ry={".999"}
        fill={"currentColor"}
      ></ellipse>

      <ellipse
        cx={"1"}
        cy={"8.038"}
        rx={"1"}
        ry={".999"}
        fill={"currentColor"}
      ></ellipse>
    </svg>
  );
}

export default Group427320404Icon;
/* prettier-ignore-end */
