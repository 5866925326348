import { Store } from '@/store';
import { toggleActionCenterCard, visibleLayerPanel } from '@/store/ui/actions';

export const replaceParams = (link, params) => {
  return link.replace(/:\w+/g, function(segment) {
    return params[segment] || segment;
  });
};

export const changeURLPath = (history) => {
  window.history.replaceState(
    null,
    '',
    `${history.location.pathname}`,
  );
};

export const checkLayerPanelOrActionCenter = () => {
  const params = new URLSearchParams(window.location.search);
  const isVisibleLayer = params.get('visibleLayer');
  const isActionCenter = params.get('actionCenter');
  if (JSON.parse(isVisibleLayer) === false) {
    Store.dispatch(visibleLayerPanel(false));
  } else {
    Store.dispatch(visibleLayerPanel(true));
  }

  if (JSON.parse(isActionCenter) === false) {
    Store.dispatch(toggleActionCenterCard(false));
  } else {
    Store.dispatch(toggleActionCenterCard(true));
  }
};

export const changeSearchURLParams = () => {
  window.onclick = function(event) {
    const params = new URLSearchParams(window.location.search);
    params.delete('actionCenter');
    params.delete('visibleLayer');
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}${
        params.toString() ? `?${params.toString()}` : ''
      }`,
    );
    Store.dispatch(toggleActionCenterCard(true));
    Store.dispatch(visibleLayerPanel(true));
  };
};