import { config } from '@/modules/common/config';

export interface BetaReleaseStore {
  isFeedVisible: boolean;
  attributesVisible: boolean;
  isStreetViewVisible: boolean;
  isOrderQueueVisible: boolean;
  isOrderRatingVisible: boolean;
  isAddMoreBulkOrder: boolean;
  isGeoJSONUpload: boolean;
  allowParcelRepair: boolean;
  allowImageryRepair: boolean;
  allowTabulaUpload: boolean;
  isDownloadGeojsonVisible: boolean;
  isExportKmlFilesEnabled: boolean;
  copyViewId: boolean;
  isNearmapPreviewEnabled: boolean;
  isServiceItem: boolean;
  showReports: boolean;
  isAddWorkspaceEnabled: boolean;
  isEstimationsEnabled: boolean;
  enableInsightsDashboard: boolean;
}

export const INITIAL_STATE: BetaReleaseStore = {
  isFeedVisible: false,
  attributesVisible: false,
  isStreetViewVisible: false,
  isOrderQueueVisible: config.isOrderQueueEnabled,
  isOrderRatingVisible: true,
  isAddMoreBulkOrder: false,
  isGeoJSONUpload: false,
  allowParcelRepair: false,
  allowImageryRepair: false,
  allowTabulaUpload: false,
  isDownloadGeojsonVisible: false,
  isExportKmlFilesEnabled: false,
  copyViewId: false,
  isNearmapPreviewEnabled: false,
  isServiceItem: false,
  showReports: false,
  isAddWorkspaceEnabled: false,
  isEstimationsEnabled: false,
  enableInsightsDashboard: false
};
