import { format } from 'date-fns';
import moment from 'moment';
import { bulkOrderParcelStatus } from '../../bulkProject/utils/enum';
import { roleById } from '@/modules/auth/helpers';
import { AccessRight } from '@/modules/common/types';
import { FilterVersion } from './types';

export const TAB_CATEGORY = {
  me: 'me',
  all: 'all',
  bulk: 'bulk',
  shared: 'shared',
  bulkView: 'bulkView',
};

export const MODAL_TYPE = {
  archive: 'archive',
  unArchive: 'unArchive',
  delete: 'delete',
  duplicate: 'duplicate',
  editCustomer: 'editCustomer',
};

export const SORT = {
  latest: '-createdAt',
  oldest: 'createdAt',
};

export const getStatusCode = (status) => {
  if (!status) return;

  switch (status?.toLowerCase()) {
    case 'draft':
    case 'skipped':
      return 1;
    case 'in progress':
      return 2;
    case 'awaiting approval':
      return 3;
    case 'approved':
      return 4;
    case 'resolving feedback':
      return 5;
    case 'archived':
    case 'archieved':
      return 6;
    case 'self measured':
      return 7;
    case 'bolt failed':
      return 8;
    case 'bolt preview':
      return 9;
    case 'bolt approved':
      return 10;
    case 'bolt in verification':
      return 11;
    case 'confirmed':
      return 12; // 12 Isn't A Status Code In DB - This Is Just For Frontend
    default:
      return 0;
  }
};

export const getStatusFromStatus = (statusCode) => {
  if (!statusCode) return;

  switch (statusCode) {
    case 1:
      return 'Draft';
    case 2:
      return 'In Progress';
    case 3:
      return 'Awaiting Approval';
    case 4:
      return 'Approved';
    case 5:
      return 'Resolving Feedback';
    case 6:
      return 'Archived';
    case 7:
      return 'Self Measured';
    case 8:
      return 'Bolt Failed';
    case 9:
      return 'Bolt Preview';
    case 10:
      return 'Bolt Approved';
    case 11:
      return 'Bolt In Verification';
    case 12: // 12 Isn't A Status Code In DB - This Is Just For Frontend
      return 'Confirmed';
    default:
      return 0;
  }
};

export const convertTagArrayToObject = (array) => {
  return array.map((val) => ({ tagName: val, tagId: 0 }));
};

export const convertArrayToStringForSearch = (array) => {
  let temp = array.reduce((result, value) => {
    return [...result, value?.tag];
  }, []);

  return temp.join();
};

/**
 * This Isn't Being Used Anywhere.
 */
export const convertStructureEmptyStateForOrder = (array) => {
  return array.map((item) => ({
    id: item.id,
    address: item.address,
    parcelArea: parseFloat(item.area).toFixed(2),
    orderHash: item.orderHash,
    bulkOrderId: item?.bulkorderId ?? '',
    bulkOrderName: item?.bulkorderName ?? '',
    estimatedTime: item?.estimatedTime ?? '0',
    estimatedCost: item?.estimatedCost ?? '0',
    latitude: item?.latitude ?? 0,
    longitude: item?.longitude ?? 0,
    status: item?.status ?? 4,
    orderId: item?.id,
    dateCreated: item?.updated_at,
    updatedAt: item?.updated_at,
    key: `${item.id}_${item?.orderHash.split('-')[0]}`,
    propertyLink: item?.property_link,
    thumbnailUrl: item?.thumbnail_url,
    userName: item?.userName ?? '',
    customerId: item?.userId ?? '',
    prospect: {
      id: item?.userId,
      name: item?.customerName ?? '',
      propertyName: item?.property_name ?? '',
    },
    feedback: item?.feedbackCount ?? 0,
    notesCount: item?.notesCount ?? 0,
    city: item?.city ?? '',
    state: item?.state ?? '',
    view: item?.view ?? '',
    shared_by: item?.sharedBy,
    tags: item?.tag ?? [],
    placedAt: item.created_at,
    refundAmount: item?.refundAmount ?? 0,
  }));
};

export const convertIntoArray = (array) => {
  return array.reduce((result, value) => {
    return [...result, value?.tag];
  }, []);
};

export const getFilterValues = (filterArray, groupName) => {
  return filterArray.filter((value) => value?.group === groupName);
};

export const bulkDataFormat = (dataArray = []) => {
  return dataArray.map((item) => ({
    id: item.id,
    key: item.id,
    cr_at: item.created_at,
    is_complt: item.is_completed,
    na: item.name,
    status: bulkProjectStatusFormat(
      item.status,
      item?.statistics,
      item.total_orders
    ),
    total_orders: item.total_orders,
    stats: item?.statistics,
    description: item?.description,
    confirmed_parcel: item?.confirmed_parcel,
  }));
};

export const bulkProjectStatusFormat = (status, summeryStatus, totalOrders) => {
  if (!status) return;

  switch (status.toLowerCase()) {
    case 'created': // 1
      return 'Created';
    case 'processing': // 2
      return 'Processing';
    case 'awaiting confirmation': // 3
      return 'Parcel Review';
    case 'order placed':
      // if (summeryStatus?.['Draft']) return 'In Progress';
      // 5
      if (summeryStatus?.['Resolving Feedback']) return 'Resolving Feedback';
      // 4
      if (summeryStatus?.['In Progress']) {
        // if (summeryStatus?.['In Progress'] === totalOrders)
        //   return 'Awaiting Approval';
        return 'In Progress';
      }
      // 4
      if (summeryStatus?.['Awaiting Approval']) {
        // if (summeryStatus?.['Awaiting Approval'] === totalOrders)
        //   return 'Approved';
        return 'Awaiting Approval';
      }
      if (summeryStatus?.['Self Measured']) return 'Self Measured';
      if (summeryStatus?.['Approved']) return 'Approved';
      return 'In Progress';
    case 'completed':
      // if (summeryStatus?.['Draft']) return 'In Progress'; //skip order not consider after place order
      if (summeryStatus?.['Resolving Feedback']) return 'Resolving Feedback';
      if (summeryStatus?.['In Progress']) {
        if (summeryStatus?.['In Progress'] === totalOrders)
          return 'Awaiting Approval';
        return 'In Progress';
      }
      if (summeryStatus?.['Awaiting Approval']) {
        // if (summeryStatus?.['Awaiting Approval'] === totalOrders)
        //   return 'Approved';
        return 'Awaiting Approval';
      }
      if (summeryStatus?.['Self Measured']) return 'Self Measured';
      if (summeryStatus?.['Approved']) return 'Approved';
      return 'Approved';
    default:
      return 'Unknown';
  }
};

export const convertTagsToArray = (tagArray) => {
  return tagArray.reduce(
    (totalArray, value) => [...totalArray, value?.tagName],
    []
  );
};

export const getAllDataFormat = (dataArray = []) => {
  return dataArray.map((item) => ({
    id: item?.order?.orderNo,
    access: item?.order?.access_rights || AccessRight.View,
    isSubscriptionRestricted: item?.order?.isSubscriptionRestricted ?? true,
    isDeleted: item.is_deleted,
    isUserDeactivated: item.is_user_deactivated,
    defaultAccess: item.order.defaultAccess,
    address: item.order.address,
    parcelArea: item.order.parcelArea
      ? parseFloat(item.order.parcelArea).toFixed(2)
      : 0.0,
    orderHash: item.order.orderHash,
    bulkOrderId: item.order.bulkorderId,
    bulkOrderName: item.order.bulkorderName,
    estimatedTime: item.order.estimatedTime,
    estimatedCost: item.order.estimatedCost,
    latitude: item.order.latitude,
    longitude: item.order.longitude,
    status: item.order.status,
    orderId: item.order.orderNo,
    dateCreated: item.order.createdAt,
    updatedAt: item.order.updatedAt,
    key: `${item?.order?.orderNo}_${item.order.orderHash.split('-')[0]}`,
    userName: item.user.userName,
    customerId: item.user.userId,
    prospect: {
      id: item.user.userId,
      name: item.order.customerName,
      customerId: item.order?.customerId,
      description: item.order?.description,
      propertyName: item.order.propertyName,
    },
    feedback: item.feedbackCount,
    notesCount: item.notesCount,
    city: item.city,
    state: item.state,
    view: item.view,
    shared_by: item.sharedBy,
    tags: item.tag,
    placedAt: item.order.placedAt,
    refundAmount: item.order.refundAmount,
    sharedWith: Array.isArray(item?.sharedWith) ? item?.sharedWith : [],
    createdBy: item?.created_by,
    source: item?.order?.source,
    access_rights: item?.order?.access_rights,
  }));
};

export const generateDate = (date) => {
  return moment(date).format('yyyy-MM-DD');
};

export const handleFilterTag = (filterArray, groupName, value) => {
  let tempArray = Array.from(filterArray);
  let returnArray = [];

  if (tempArray.some((value) => value?.group === groupName)) {
    tempArray.forEach((tmp) => {
      if (tmp?.group === groupName) tmp.tag = value;
    });
    returnArray = tempArray;
  } else {
    returnArray = [
      ...tempArray,
      {
        group: groupName,
        tag: value,
      },
    ];
  }

  return returnArray;
};

export const convertArrayToString = (array, propertyName) => {
  let tempArray = array?.reduce((result, value) => {
    if (value[propertyName] && !result.includes(value[propertyName]))
      return [...result, value[propertyName]];
    else return result;
  }, []);

  return tempArray;
};

const escapeRegExp = (string) => {
  if (!string) return string;

  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const textHighlight = (text, highlightText) => {
  if (!text) return;

  const parts = text.split(
    new RegExp(`(${escapeRegExp(highlightText)})`, 'gi')
  );

  return parts.map((part, i) => (
    <span
      key={i}
      style={
        part?.toLowerCase() === highlightText?.toLowerCase()
          ? { fontWeight: '500' }
          : {}
      }
    >
      {part}
    </span>
  ));
};

export const bulkStatusColor = (status) => {
  switch (status) {
    case 'Created':
      return 'tag--warning__secondary';
    case 'Processing':
      return 'tag--violet';
    case 'Parcel Review':
      return 'tag--brown';
    case 'In Progress':
      return 'tag--lite';
    case 'Awaiting Approval':
      return 'tag--info';
    case 'Approved':
      return 'tag--success';
    case 'Self Measured':
      return 'tag--brown';
    case 'Resolving Feedback':
      return 'tag--danger';
    case 'Archived':
      return 'tag--archive';
    case 'Confirmed':
      return 'tag--success-secondary';
    default:
      return 'tag--success-secondary';
  }
};

export const getListOfFilters = (value) => {
  switch (value.group) {
    case 'Order':
      return ' ID: ' + value?.tag;
    case 'CustomerId':
      return ' Cust. ID: ' + value?.tag;
    case 'minArea':
      return ' Min: ' + value?.tag + ' acres ';
    case 'maxArea':
      return ' Max: ' + value?.tag + ' acres ';
    case 'customerName':
      return ' Cust. Name: ' + value?.tag;
    case 'Bulk':
      return ' Project name: ' + value?.tag;
    case 'Created':
      return (
        moment(value?.tag?.split('<->')[0]?.trim()).format('MMM D, YYYY') +
        ' to ' +
        moment(value?.tag?.split('<->')[1]?.trim()).format('MMM D, YYYY')
      );
    case 'CreatedBy':
      return ' Created By: ' + value?.tag.split('|_*_|')[1];
    default:
      return value.tag;
  }
};

export const exportFormattedOrderDataToExcelSheet = (orderData) => {
  const { address, parcelArea, latitude, longitude, prospect, orderId } =
    orderData;

  return {
    // Code : ,
    'Order ID': orderId,
    UserID: prospect?.id,
    Address: address,
    // 'Link to the measurement' : ,
    'ParcelArea (Acre)': parcelArea,
    longitude: longitude,
    latitude: latitude,
    customerName: prospect?.name,
    propertyName: prospect?.propertyName,
    // 'Retention Ponds (Sq Ft)' : ,
    // 'Turf (Sq Ft)' : ,
    // 'Palm Tree (#)' : ,
    // 'Tree (#)' : ,
    // 'Mulch Beds (Sq Ft)' : ,
    // 'Rock Beds (Sq Ft)' : ,
    // 'Mulch coverage (shrub/green coverage) (Sq Ft)' : ,
    // 'Mulch type' : ,
    // 'Retention type' : ,
    // 'Rock_beds_coverage (shrub/green coverage) (Sq Ft)' : ,
    // 'Out Parcel Native Turf (Sq Ft)' : ,
  };
};

export const momentFormat = () => {
  return {
    future: 'in %s',
    past: '%s ago',
    s: '%ds ago',
    ss: '%ds ago',
    m: '%dm ago',
    mm: '%dm ago',
    h: '%dh ago',
    hh: '%dh ago',
    d: '%dd ago',
    dd: '%dd ago',
    w: '%dw ago',
    ww: '%dw ago',
    M: '%d month ago',
    MM: '%d months ago',
    y: '%d year ago',
    yy: '%d years ago',
  };
};

export const momentUnmountFormat = () => {
  return {
    future: 'in %s',
    past: '%s ago',
    s: '%d sec',
    ss: '%d secs',
    m: '%d min',
    mm: '%d mins',
    h: '%d hour',
    hh: '%d hours',
    d: '%d day',
    dd: '%d days',
    w: '%d week',
    ww: '%d weeks',
    M: '%d month',
    MM: '%d months',
    y: '%d year',
    yy: '%d years',
  };
};

export const extractSuggestedTags = (tagsArray) => {
  return tagsArray.reduce((totalArray, value) => {
    if (value?.maintainer === 'siterecon') return [...totalArray, value?.name];

    return totalArray;
  }, []);
};

export const filterEnum = [
  'Draft',
  'In Progress',
  'Awaiting Approval',
  'Resolving Feedback',
  'Approved',
  'Archived',
  'Self Measured',
  'Bolt Preview',
  'Bolt In Verification',
  'Bolt Approved',
  'Bolt Failed',
];

export const bulkFilterName = [
  'Created',
  'Parcel Review',
  'In Progress',
  'Awaiting Approval',
  'Resolving Feedback',
  'Approved',
];

export const bulkFilterBeforeOrderPlace = ['Draft', 'Confirmed'];
export const bulkFilterAfterOrderPlace = filterEnum.slice(0, 6); // >>> filterEnum <<<

export const generateInitialName = (name) => {
  if (name)
    return name.split(' ').reduce((prevState, value) => {
      if (prevState?.length >= 2) return prevState;

      return prevState + value[0];
    }, '');
};

export const formatListOfPeopleData = (peopleArray) => {
  return peopleArray.map((people, i) => ({
    id: people?.id,
    name: people?.fn,
    email: people?.em,
    profileImage: people?.profile_pic,
    role: roleById(people?.role_id),
    guest: false,
  }));
};

export const formatBulkOrderListData = (borderData) => {
  return borderData.map((item) => ({
    id: item.id,
    isDeleted: item.is_deleted,
    access: item.access || item.accessRights,
    address: item.address,
    parcelArea: item.parcelArea ? parseFloat(item.parcelArea).toFixed(2) : 0.0,
    parcelJson: item.parcelJson,
    orderHash: item.orderHash,
    bulkOrderId: item.bulkorderId,
    bulkOrderName: item.bulkorderName,
    estimatedTime: item.estimatedTime,
    estimatedCost: item.estimatedCost,
    latitude: item.latitude,
    longitude: item.longitude,
    status: getStatusCode(item.status),
    orderId: item.id,
    dateCreated: item.updatedAt,
    updatedAt: item.updatedAt,
    key: item.orderHash ? `${item.id}_${item.orderHash.split('-')[0]}` : '',
    userName: item.userName,
    customerId: item.customerId,
    prospect: {
      id: item.userId,
      name: item.customerName,
      customerId: item.customerId,
      description: item.description,
      propertyName: item.propertyName,
    },
    feedback: item.feedbackCount,
    notesCount: item.notesCount,
    city: item.city,
    state: item.state,
    view: item.view,
    shared_by: item.sharedBy,
    tags: item.tag,
    placedAt: item.createdAt,
    refundAmount: item.refundAmount,
    sharedWith: Array.isArray(item?.sharedWith) ? item?.sharedWith : [],
    createdBy: item?.created_by,
  }));
};

export const getTotalBulkCount = (status, statusObj, totalOrder) => {
  if (!status) return;
  if (!statusObj) return;
  if (!totalOrder) return;

  switch (status.toLowerCase()) {
    case 'created':
      return 0;
    case 'processing':
      return 0;
    case 'parcel review':
      return statusObj['Draft'] === 0
        ? totalOrder - statusObj['In Progress']
        : totalOrder - statusObj['Draft'];
    case 'in progress':
      return statusObj['In Progress'];
    case 'awaiting approval':
      return statusObj['Awaiting Approval'];
    case 'resolving feedback':
      return statusObj['Resolving Feedback'];
    case 'Self Measured':
      return statusObj['Self Measured'];
    case 'approved':
      return statusObj['Approved'];
    default:
      return statusObj['Draft'] || 0;
  }
};

export const getStatsConverter = (statusCode) => {
  switch (statusCode) {
    case 1:
    case 8:
      return 'Draft';
    case 2:
      return 'In Progress';
    case 3:
      return 'Awaiting Approval';
    case 5:
      return 'Resolving Feedback';
    case 4:
      return 'Approved';
    case 6:
      return 'Archived';
    case 7:
      return 'Self Measured';
    default:
      return 'Draft';
  }
};

export const validateEstimateTime = (estimatedDate) => {
  if (!estimatedDate) return;

  let formattedDate = estimatedDate.split('T');
  let date = formattedDate[0];
  let time = formattedDate[1].split('.')[0];

  return date + 'T' + time;
};

export const BULK_STATUS_CONST = {
  Created: 'Created',
  Awaiting_Approval: 'Awaiting Approval',
  Parcel_Review: 'Parcel Review',
  In_Progress: 'In Progress',
  Draft: 'Draft',
  Confirmed: 'Confirmed',
  Approved: 'Approved',
  Processing: 'Processing',
  Order_placed: 'Order Placed',
  Completed: 'Completed',
  Awaiting_Confirmation: 'Awaiting Confirmation',
  Resolving_Feedback: 'Resolving Feedback',
  Archived: 'Archived',
};

/**
 *
 * @param {Array} totalArray
 * @param {Number} currentIndex
 * @returns {Number}
 */
export const getUnconfirmedCard = (totalArray, currentIndex) => {
  return totalArray.findIndex(
    (val, i) =>
      i !== currentIndex && val?.propertyStatus === bulkOrderParcelStatus.draft
  );
};

export const getStatusFilterValue = (status) => {
  switch (status) {
    case 'In Progress':
      return 'Measurement Ongoing';
    default:
      return status;
  }
};

const getFilterKeys = (version = FilterVersion.V2) => {
  if (version === FilterVersion.LEGACY) {
    return {
      status: 'status',
      orderId: 'oid',
      createdAfter: 'sdate',
      createdBefore: 'edate',
      customerId: 'pid',
      minParcelArea: 'min_area',
      maxParcelArea: 'max_area',
      cities: 'city',
      states: 'state',
      orderTags: 'tag',
      bulkprojectnames: 'bulkprojectnames',
      customerNames: 'pname',
      includeSampleOrders: 'includeSampleOrders',
    };
  }

  return {
    status: 'status',
    orderId: 'orderId',
    createdAfter: 'createdAfter',
    createdBefore: 'createdBefore',
    customerId: 'customerId',
    minParcelArea: 'minParcelArea',
    maxParcelArea: 'maxParcelArea',
    cities: 'cities',
    states: 'states',
    orderTags: 'orderTags',
    bulkprojectnames: 'bulkprojectnames',
    customerNames: 'customerNames',
    includeSampleOrders: 'includeSampleOrders',
  };
};

/**
 * Formats dashboard filters to filter parameters supported by `/dashboard-list` API
 *
 * @param filter
 * @param version {FilterVersion} 'legacy'|'v2' (Legacy filter params are used for bulk order filters)
 * @returns {{maxParcelArea: (*|undefined), cities: undefined, orderId: (number|undefined), customerId: (*|undefined), customerNames: undefined, createdBefore: (string|undefined), createdAfter: (string|undefined), minParcelArea: (*|undefined), status: (string|undefined), states: undefined, orderTags: undefined, bulkprojectnames: undefined}}
 */
export const getApiFilterParams = (filter, version = FilterVersion.V2) => {
  const keys = getFilterKeys(version);

  const status = getFilterValues(filter, 'Status');
  const orderId = getFilterValues(filter, 'Order')[0];
  const created = getFilterValues(filter, 'Created')[0];
  const customerId = getFilterValues(filter, 'CustomerId')[0];
  const minArea = getFilterValues(filter, 'minArea')[0];
  const maxArea = getFilterValues(filter, 'maxArea')[0];
  const bulkArray = getFilterValues(filter, 'Bulk');

  const city = convertArrayToStringForSearch(getFilterValues(filter, 'City'));
  const state = convertArrayToStringForSearch(getFilterValues(filter, 'State'));
  const tag = convertArrayToStringForSearch(getFilterValues(filter, 'Tag'));
  const bulk = convertArrayToStringForSearch(bulkArray);
  const pname = convertArrayToStringForSearch(
    getFilterValues(filter, 'customerName')
  );

  const statusFilter = [
    status.map((value) => getStatusFilterValue(value?.tag)),
  ].join();

  return {
    [keys.status]: statusFilter || undefined,
    [keys.orderId]: +orderId?.tag || undefined,
    [keys.createdAfter]: created
      ? format(new Date(created?.tag?.split('<->')[0]?.trim()), 'yyyy-MM-dd') ??
      undefined
      : undefined,
    [keys.createdBefore]: created
      ? format(new Date(created?.tag?.split('<->')[1]?.trim()), 'yyyy-MM-dd') ??
      undefined
      : undefined,
    [keys.customerId]: customerId?.tag || undefined,
    [keys.minParcelArea]: minArea?.tag || undefined,
    [keys.maxParcelArea]: maxArea?.tag || undefined,
    [keys.cities]: city || undefined,
    [keys.states]: state || undefined,
    [keys.orderTags]: tag || undefined,
    [keys.bulkprojectnames]: bulk || undefined,
    [keys.customerNames]: pname || undefined,
    [keys.includeSampleOrders]: true,
  };
};

export const applyFilterParams = ({
  filter,
  search,
  page,
  pageLength,
  sort,
  version = FilterVersion.LEGACY,
}) => {
  let paginationParams = () => {
    if (version === FilterVersion.LEGACY) {
      return {
        p: page ?? undefined,
        psz: pageLength ?? undefined,
        sort: sort ?? undefined,
      };
    }

    return {
      offset: page ? page - 1 : undefined,
      limit: pageLength ?? undefined,
      sort: sort ?? undefined,
    };
  };

  return {
    ...paginationParams(),
    ...getApiFilterParams(filter, version),
    keyword: search || undefined,
  };
};

export const STATUSES_BEFORE_BULK_ORDER_PLACEMENT = [
  BULK_STATUS_CONST.Created,
  BULK_STATUS_CONST.Parcel_Review,
  BULK_STATUS_CONST.Processing,
  BULK_STATUS_CONST.Awaiting_Confirmation,
];
