import useNotificationBar from './useNotificationBar';
import { NotificationBarType } from './types';
import useAuth from '../../../../modules/auth/hooks';
import EmailVerifyForm from '../../auth-form/signup/EmailVerifyForm';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import {
  CloseOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
} from '@/components/elements/icons/antd-icons';
import { NotificationBar } from '@siterecon/sr-component/dist/sr-component-library';
import { NotificationBarVariant } from './types';

enum NotificationBarStatus {
  DEFAULT = 'default',
  SUCCESS = 'success',
}

const AUTO_HIDE_TIMEOUT = 3000;

const TYPE = NotificationBarType.VerifyEmail;

const VerifyEmailNotificationBar = () => {
  const { user } = useAuth();
  const { isVisible, show, hide } = useNotificationBar();

  const [status, setStatus] = useState<NotificationBarStatus>(
    NotificationBarStatus.DEFAULT
  );
  const [isEmailVerifyModalVisible, setIsEmailVerifyModalVisible] =
    useState<boolean>(false);

  const showEmailVerifyModal = () => setIsEmailVerifyModalVisible(true);

  const closeEmailVerifyModal = () => setIsEmailVerifyModalVisible(false);

  const handleEmailVerifyModalSuccess = () => {
    setStatus(NotificationBarStatus.SUCCESS);
    closeEmailVerifyModal();

    /** When user successfully verifies the email, we show success notification bar and after a few seconds we have to auto-hide this notification bar */
    setTimeout(() => hide(TYPE), AUTO_HIDE_TIMEOUT);
  };

  const handleNotificationBarClose = () => hide(TYPE);

  useEffect(() => {
    if (user.email_verified === '0') {
      /** If email of the user is not verified, we need to show verify email notification bar.  */
      show(TYPE);
      return;
    }
  }, [user]);

  if (!isVisible(TYPE)) {
    return null;
  }

  const NotificationBarByStatus = () => {
    if (status === NotificationBarStatus.DEFAULT) {
      return (
        <NotificationBar
          className='justify-center'
          message='Verify your email to be a part of your organisation’s workspace.'
          icon={<ExclamationCircleOutlined />}
          onClose={handleNotificationBarClose}
          action={{
            label: 'Verify',
            onClick: showEmailVerifyModal,
          }}
        />
      );
    }

    return (
      <NotificationBar
        variant={NotificationBarVariant.SUCCESS}
        className='justify-center'
        message='Your email address has been successfully verified !'
        icon={<CheckOutlined />}
        onClose={handleNotificationBarClose}
      />
    );
  };

  return (
    <>
      {NotificationBarByStatus()}
      <Modal
        visible={isEmailVerifyModalVisible}
        onCancel={closeEmailVerifyModal}
        footer={null}
        closable={true}
        closeIcon={<CloseOutlined />}
        width='450px'
        centered
        className='email-verify-wrapper'
      >
        <EmailVerifyForm
          showEmailVerifyModal={isEmailVerifyModalVisible}
          handleToggleEmailVerifyModal={handleEmailVerifyModalSuccess}
        />
      </Modal>
    </>
  );
};

export default VerifyEmailNotificationBar;
