import { forwardRef, Ref, useEffect, useState } from 'react';
import { Input, Row, Tooltip } from 'antd';
import './style.less';
import { Typography } from '@siterecon/sr-styles/dist/elements';

interface EditableTextProps {
  isEditModeOn: boolean;
  value: string;
  handleChange?: () => {};
  handleBlur: (e: any) => void;
  handleDoubleClick: (e: any) => void;
  handleEnter: (text: string) => void;
  handleEmptyString?: (valid: boolean) => void;
  handleKeydown: (e: any) => void;
  showTooltip: boolean;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  setInput?: any;
  props?: any;
  isMapPage?: any;
}

const EditableText = forwardRef(
  (
    {
      isEditModeOn,
      value,
      handleChange,
      handleBlur,
      handleDoubleClick,
      handleEnter,
      handleEmptyString,
      handleKeydown,
      showTooltip,
      style,
      inputStyle,
      setInput,
      isMapPage,
      ...props
    }: EditableTextProps,
    ref: Ref<any>
  ) => {
    const [inputValue, setInputValue] = useState<string>(value);
    const [hasErrors, setHasErrors] = useState(false);
    const [isEmptyString, setIsEmptyString] = useState(false);
    const [inputwidth, setinputWidth] = useState(200);

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    useEffect(() => {
      const inputEl = document.getElementById('inputText');
      if (inputEl && value) {
        inputEl.setAttribute('size', (value.length - 9).toString());
      }
    }, [isEditModeOn, value]);

    const inputChangeHandler = (e: any) => {
      const inputText = e.target.value;
      setInputValue(inputText);
      e.target.setAttribute('size', inputText.length);
      if (inputText.length === 0) {
        setHasErrors(true);
        if (handleEmptyString) {
          handleEmptyString(true);
        }
        return;
      } else {
        setHasErrors(false);
        if (handleEmptyString) {
          handleEmptyString(false);
        }
      }
    };

    const inputBlurHandler = (e: any) => {
      setInputValue(e.target.value);
      setHasErrors(false);
      handleBlur(e);
    };

    const dynamicIncrease = (e) => {
      if (isMapPage) {
        let length = e.target.value.length;

        if (inputwidth <= 300) {
          setinputWidth(30 + length * 10);
        }
      }
    };

    const keydownHandler = (e: any) => {
      // escape key
      if (e.keyCode === 27) {
        setInputValue(value);
        handleKeydown(e);
      }
      // enter key
      else if (e.keyCode === 13) {
        handleEnter(inputValue);
      } else {
        handleKeydown(e);
      }
    };

    useEffect(() => {
      setInput(inputValue);
    }, [inputValue]);

    return (
      <Row className='editable-text'>
        {isEditModeOn ? (
          <Input
            type='text'
            id='inputText'
            ref={ref as any}
            value={inputValue}
            onChange={(e) => {
              inputChangeHandler(e);
              if (isMapPage) {
                dynamicIncrease(e);
              }
            }}
            onBlur={(e: any) => inputBlurHandler(e)}
            onKeyDown={(e) => {
              keydownHandler(e);
            }}
            style={{
              ...style,
              ...inputStyle,
              width: isMapPage ? inputwidth : '',
            }}
            className={`text-ellipsis ${hasErrors ? 'red' : 'blue'} ${
              isEmptyString ? 'error-action' : ''
            }`}
            autoFocus
          />
        ) : (
          <Row
            onDoubleClick={(e) => handleDoubleClick(e)}
            className='final-text text-ellipsis'
            style={{ maxWidth: isMapPage ? '315px' : '' }}
          >
            {showTooltip ? (
              <Tooltip
                title={inputValue}
                placement='top'
                overlayStyle={{ maxWidth: '500px' }}
              >
                <Typography style={style}>
                  {inputValue}
                </Typography>
              </Tooltip>
            ) : (
              <Typography className='text-ellipsis' style={style}>
                {inputValue}
              </Typography>
            )}
          </Row>
        )}
      </Row>
    );
  }
);

export default EditableText;
