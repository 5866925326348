import { AlertContent, AlertVariant } from '@common/components/Alert';
import { IObject, Nullable } from '@common/types';
import { NotificationBarType } from '../../components/containers/navbar/notification-bar/types';

export interface AlertData {
  visible: boolean;
  content: AlertContent | null;
  variant: AlertVariant;
}

export interface NotificationBarData {
  /** Is NotificationBar visible? */
  visible: boolean;

  /** Kind of {@link NotificationBarType} which is currently visible in Navbar */
  type: Nullable<NotificationBarType>;

  /** (Optional) Extra data which would be used by currently visible NotificationBar */
  data: Nullable<any>;
}

export interface UiStore extends IObject {
  alert: AlertData;
  notificationBar: NotificationBarData;
}

const NotificationBarInitialData: NotificationBarData = {
  visible: false,
  type: null,
  data: null,
};

export const INITIAL_STATE: UiStore = {
  secondaryPanelWidth: 0, // 0px
  isSecondaryPanelVisible: true,
  isForgotPasswordModalVisible: false,
  isBulkCheckoutVisible: false,
  isFailedPaymentToasterVisible: false,
  isPaymentStatusChecked: false,
  showEditPropertyViewBulk: false,
  isOrdersListPanelVisible: true,
  isClickLeftPanel: false,
  isLayerPanel: true,
  isActionCenterCard: true,
  isEventSuccessModalVisible: false,
  eventSuccessModalContent: {
    heading: '',
    para: '',
  },
  isSharePropertyModalVisible: false,
  isNavbarHidden: false,
  showVertices: [],
  alert: {
    visible: false,
    content: null,
    variant: 'success',
  },
  notificationBar: NotificationBarInitialData,
  isNearmapDebugEnabled: false,
  isBasemapEnabled: true,
  isParcelEnabled: true,
  isNearmapEnabled: true,
  selectedBasemap: 'satellite',
  hasNearmap: true,
  fullScreenLoaderVisibility: {
    isVisible: false,
    message: ''
  },
  isCustomerInfoDialogVisible: false,
};
