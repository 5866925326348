import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getFeatureList } from '../../../store/order/thunks';

import { FeatureList } from './feature-list';
import { CustomFeatureForm } from './custom-feature-form';
import { CustomFeatureDesc } from './custom-feature-desc';

import { ReactComponent as CloseIcon } from '../../../assets/cross.svg';

import './style.less';

import {
  setAttributeModalVisiblity,
  setCreationFormValue,
  setFeatureToVisible,
} from '../../../store/attributeFeature/actions';
import { customFeatureStyle } from '../../../helpers/mapUtils';

const NewLayer = (props) => {
  const dispatch = useDispatch();
  const { data: featureListInfo } = useSelector(
    (state) => state.order.featureListInfo
  ) || [];
  const attributeVisiblity = useSelector(
    (state) => state.feature.attributeModalVisible
  );

  const currFeature = useSelector((state) => state.feature.featureToVisible);

  const [selectedName, setName] = useState('');
  const [page, setPage] = useState(1);
  const [type, setType] = useState('old');
  const customStyle = customFeatureStyle();

  useEffect(() => {
    dispatch(getFeatureList());
  }, [selectedName]);

  useEffect(() => {
    const temp = featureListInfo.filter(
      (feature) => feature.status === 'active'
    );

    if (currFeature) {
      const idx = featureListInfo.findIndex(
        (f) => f.featureId === currFeature.id
      );
      setName(currFeature);
      dispatch(setCreationFormValue(temp[idx]));
      dispatch(setFeatureToVisible(null));
    }
  }, [currFeature, featureListInfo]);

  useEffect(() => {
    if (attributeVisiblity && featureListInfo) {
      setFeatureList();
    }
  }, [attributeVisiblity, featureListInfo]);

  const setFeatureList = () => {
    if (featureListInfo && featureListInfo?.length !== 0) {
      const temp = featureListInfo.filter(
        (feature) => feature.status === 'active'
      );

      setName((p) => {
        if (p) {
          const idx = temp.findIndex((f) => f.featureId === p.id);
          dispatch(setCreationFormValue(temp[idx]));
          return p;
        } else {
          dispatch(setCreationFormValue(temp[0]));
          return { name: temp[0]?.name, id: temp[0].featureId };
        }
      });
    }
  };

  const filterFeature = (name, id) => {
    setPage(1);
    setName({ name: name, id: id });
    let filterArr = featureListInfo?.filter((val) => {
      if (val.featureId === id) {
        return val;
      }
    });
    dispatch(setCreationFormValue(filterArr?.[0]));
    setType('old');
  };

  const switchPages = (page) => {
    setPage(page);
  };

  return (
    <div className='new-layer'>
      <Modal
        visible={attributeVisiblity}
        footer={null}
        width={750}
        height={650}
        onCancel={() => {
          dispatch(setAttributeModalVisiblity(false));
        }}
        closeIcon={
          <CloseIcon
            width={11}
            height={11}
            onClick={() => {
              dispatch(setAttributeModalVisiblity(false));
              setName('');
              dispatch(setFeatureToVisible(null));
              props.toggleAddNewLayer(false);
              props.loadFeatures(false);
            }}
          />
        }
        wrapClassName='layer-modal'
      >
        <div className='divide-feature'>
          <FeatureList
            {...props}
            selectedName={selectedName}
            filterFeature={filterFeature}
            switchToCreateFeatureForm={switchPages}
          />

          {page === 1 ? (
            <CustomFeatureDesc
              loadLayer={props.loadLayer}
              loadFeatures={props.loadFeatures}
              type={type}
              setType={setType}
              setName={filterFeature}
              addSingleLayerData={props.addSingleLayerData}
              isBaseView={props.isBaseView}
              isEditableView={props.isEditableView}
              customStyle={customStyle}
              selectedName={selectedName}
              updateFeatureListInfo={props.updateFeatureListInfo}
              onSaveAndApplyFeature={props.onSaveAndApplyFeature}
            />
          ) : (
            <CustomFeatureForm
              onCancel={switchPages}
              pageHandler={switchPages}
              setType={setType}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
export default NewLayer;
