import { Col, Progress, Row, Spin, Typography } from 'antd';
import React, { useCallback } from 'react';
import { FileUploadStatus } from '../../UploadStatus';
import Text from '../../../../../elements/text/Text';
import CSVUploadIcon from '../../assets/csvBackground.svg';
import { ReactComponent as LoadingIcon } from '../../assets/loading-icon.svg';

const ProcessingFile = ({
  typeOfModal,
  uploadPercentage,
  setUploadPercentage,
  uploadBulkOrderCSVFile,
  filesData,
  setTypeOfModal,
  cancelAxiosToken,
  errorMessage,
}) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      setTypeOfModal(FileUploadStatus.uploadingFailed);
      cancelAxiosToken();
      setUploadPercentage(100);
    },
    [setTypeOfModal, cancelAxiosToken, setUploadPercentage]
  );

  const handleRetry = useCallback(() => {
    setUploadPercentage(0);
    // uploadBulkOrderCSVFile(filesData);

    // call uploadModalAgain
    setTypeOfModal(FileUploadStatus.uploadFile);
  }, [setUploadPercentage, setTypeOfModal]);

  return (
    <Row
      style={{ flexDirection: 'column' }}
      className='upload__processing px-6 pb-6'
    >
      <Row
        className='w-100 progress__wrapper pt-5 pb-3 px-4 mt-3'
        wrap={false}
        align={'middle'}
        style={
          typeOfModal === FileUploadStatus.uploadingFailed
            ? { borderColor: '#FF2424' }
            : {}
        }
      >
        {uploadPercentage > 99 &&
        typeOfModal == FileUploadStatus.processingFile ? (
          <Col span={24}>
            <Row justify={'space-around'} align={'middle'}>
              <Col>
                <Typography.Title level={4}>
                  <Spin
                    indicator={<LoadingIcon style={{ fontSize: 52 }} spin />}
                  />
                  Processing File
                  {/*<LoadingOutlined class={'mr-3'} /> Processing File*/}
                </Typography.Title>
              </Col>
            </Row>
          </Col>
        ) : (
          <>
            <Row
              className='background__csv__image mr-5'
              justify='center'
              align='middle'
            >
              <img src={CSVUploadIcon} alt='CSVUploadIcon' />
              <Text>CSV</Text>
            </Row>
            <Row
              className='w-100'
              style={{ flexDirection: 'column', position: 'relative' }}
            >
              <Row align='middle' justify='space-between' wrap={false}>
                <Text type='p76' color='dark-gray'>
                  Project.csv
                </Text>

                {/* 
        {typeOfModal === FileUploadStatus.processingFile && (
          <img src={CrossIcon} alt='CrossIcon' onClick={handleClick} />
        )} */}
              </Row>

              <Progress
                percent={uploadPercentage}
                size='small'
                showInfo={false}
                strokeColor={
                  typeOfModal === FileUploadStatus.uploadingFailed
                    ? '#FF2424'
                    : null
                }
              />

              {typeOfModal === FileUploadStatus.uploadingFailed && (
                <Text
                  type='p77'
                  color='gray-shade'
                  className='retry__button'
                  onClick={handleRetry}
                >
                  Retry
                </Text>
              )}
              {typeOfModal === FileUploadStatus.uploadingFailed && (
                <Text type='p36' color={'error-red'} className='mb-5'>
                  {errorMessage
                    ? errorMessage
                    : 'Some Network error! Please try again'}
                </Text>
              )}
            </Row>
          </>
        )}
      </Row>

      {/* <Row align='middle' justify='center' className='mb-11'>
        {typeOfModal !== FileUploadStatus.uploadingFailed && (
          <Button
            style={{
              opacity: 0.6,
              pointerEvents: 'none',
              height: '36px',
            }}
            className={'save-button'}
            type='primary'
          >
            Submit
          </Button>
        )}

        {typeOfModal === FileUploadStatus.uploadingFailed && (
          <Button
            style={{ height: '36px' }}
            className={'save-button'}
            type='primary'
            onClick={handleRetry}
          >
            Retry
          </Button>
        )}
      </Row> */}
    </Row>
  );
};

export default ProcessingFile;
