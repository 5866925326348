import React from 'react';
import ContentLoader from 'react-content-loader';

const MyLoader = () => (
  <ContentLoader
    speed={2}
    width={560}
    height={371}
    viewBox='0 0 560 371'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
  >
    <rect x='227' y='89' rx='4' ry='4' width='25' height='9' />
    <rect x='12' y='87' rx='5' ry='5' width='126' height='11' />
    <rect x='143' y='89' rx='4' ry='4' width='83' height='9' />
    <rect x='199' y='55' rx='5' ry='5' width='58' height='11' />
    <rect x='12' y='53' rx='6' ry='6' width='56' height='12' />
    <rect x='507' y='94' rx='4' ry='4' width='25' height='9' />
    <rect x='292' y='92' rx='5' ry='5' width='126' height='11' />
    <rect x='423' y='94' rx='4' ry='4' width='83' height='9' />
    <rect x='479' y='60' rx='5' ry='5' width='58' height='11' />
    <rect x='292' y='58' rx='6' ry='6' width='56' height='12' />
    <rect x='12' y='132' rx='4' ry='4' width='260' height='86' />
    <rect x='13' y='133' rx='4' ry='4' width='259' height='85' />
    <circle cx='246' cy='159' r='12' />
    <circle cx='246' cy='159' r='12' />
    <path d='M 241.506 160.503 l 4.64 -1.504 v -4.788 a 4.79 4.79 0 11 -4.64 6.292 z' />
    <path d='M 246.646 158.999 v -4.248 a 4.29 4.29 0 11 -4.479 6.064 l 4.133 -1.34 l 0.346 -0.112 v -0.364 z' />
    <rect x='22' y='147' rx='6' ry='6' width='197' height='12' />
    <rect x='22' y='165' rx='6' ry='6' width='197' height='12' />
    <circle cx='104' cy='197' r='2' />
    <rect x='22' y='191' rx='4' ry='4' width='78' height='8' />
    <rect x='107' y='191' rx='4' ry='4' width='59' height='7' />
    <rect x='207' y='177' rx='6' ry='6' width='53' height='12' />
    <rect x='12' y='234' rx='4' ry='4' width='260' height='86' />
    <rect x='13' y='235' rx='4' ry='4' width='259' height='85' />
    <circle cx='246' cy='261' r='12' />
    <circle cx='246' cy='261' r='12' />
    <rect x='22' y='249' rx='6' ry='6' width='197' height='12' />
    <rect x='22' y='267' rx='6' ry='6' width='197' height='12' />
    <circle cx='104' cy='299' r='2' />
    <rect x='22' y='293' rx='4' ry='4' width='78' height='8' />
    <rect x='107' y='293' rx='4' ry='4' width='59' height='7' />
    <rect x='207' y='279' rx='6' ry='6' width='53' height='12' />
    <rect x='12' y='336' rx='4' ry='4' width='260' height='86' />
    <rect x='13' y='337' rx='4' ry='4' width='259' height='85' />
    <circle cx='246' cy='363' r='12' />
    <circle cx='246' cy='363' r='12' />
    <rect x='22' y='351' rx='6' ry='6' width='197' height='12' />
    <rect x='22' y='369' rx='6' ry='6' width='197' height='12' />
    <rect x='290' y='134' rx='4' ry='4' width='260' height='86' />
    <rect x='291' y='135' rx='4' ry='4' width='259' height='85' />
    <circle cx='524' cy='161' r='12' />
    <circle cx='524' cy='161' r='12' />
    <path d='M 519.506 162.503 l 4.64 -1.504 v -4.788 a 4.79 4.79 0 11 -4.64 6.292 z' />
    <path d='M 524.646 160.999 v -4.248 a 4.29 4.29 0 11 -4.479 6.064 l 4.133 -1.34 l 0.346 -0.112 v -0.364 z' />
    <rect x='300' y='149' rx='6' ry='6' width='197' height='12' />
    <rect x='300' y='167' rx='6' ry='6' width='197' height='12' />
    <circle cx='382' cy='199' r='2' />
    <rect x='300' y='193' rx='4' ry='4' width='78' height='8' />
    <rect x='385' y='193' rx='4' ry='4' width='59' height='7' />
    <rect x='485' y='179' rx='6' ry='6' width='53' height='12' />
    <rect x='290' y='236' rx='4' ry='4' width='260' height='86' />
    <rect x='291' y='237' rx='4' ry='4' width='259' height='85' />
    <circle cx='524' cy='263' r='12' />
    <circle cx='524' cy='263' r='12' />
    <rect x='300' y='251' rx='6' ry='6' width='197' height='12' />
    <rect x='300' y='269' rx='6' ry='6' width='197' height='12' />
    <circle cx='382' cy='301' r='2' />
    <rect x='300' y='295' rx='4' ry='4' width='78' height='8' />
    <rect x='385' y='295' rx='4' ry='4' width='59' height='7' />
    <rect x='485' y='281' rx='6' ry='6' width='53' height='12' />
    <rect x='290' y='338' rx='4' ry='4' width='260' height='86' />
    <rect x='291' y='339' rx='4' ry='4' width='259' height='85' />
    <circle cx='524' cy='365' r='12' />
    <circle cx='524' cy='365' r='12' />
    <rect x='300' y='353' rx='6' ry='6' width='197' height='12' />
    <rect x='300' y='371' rx='6' ry='6' width='197' height='12' />
  </ContentLoader>
);

export default MyLoader;
