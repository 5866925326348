/** Magic code: To rotate nearmap  */

import {
    ObliqueType,
    ViewOption,
  } from '../../components/containers/nearmap-oblique-selection-dd/types';
  
  const VERT = 0;
  const NORTH = 0;
  const EAST = 90;
  const SOUTH = 180;
  const WEST = 270;
  
  /** Difference of direction angle with respect to NORTH as 0 DEG  */
  export const HEADINGS = {
    Vert: VERT,
    North: NORTH,
    East: EAST,
    South: SOUTH,
    West: WEST,
  };
  
  export const TILE_SIZE = [256, 256];
  
  /** Convert degrees to radians */
  export function degreesToRadians(deg) {
    return deg * (Math.PI / 180);
  }
  
  /** Calculate the angle remainder from 360 */
  export function modulus360(deg) {
    if (deg < 0) {
      return 360 + (deg % 360);
    }
  
    return deg % 360;
  }
  
  /** Transforms {@link ViewOption} to {@link ObliqueType} supported by Nearmap APIs */
  export const getNearmapObliqueType = (viewType) => {
    if (viewType === ViewOption.TOP) {
      return ObliqueType.VERT;
    }
  
    return viewType || 'Vert';
  };
  
  /** Required view rotation */
  export const getViewRotation = (heading) => {
    return degreesToRadians(modulus360(360 - heading));
  };
  
  /** Fetches the Image from given URL */
  function fetchImageData(url) {
    return fetch(url, { redirect: 'manual' })
      .then(function (resp) {
        if (resp.status === 200) {
          return resp.blob().then(function (data) {
            return window.URL.createObjectURL(data);
          });
        }
  
        // Handle other http status cases here
        return null;
      })
      .catch(function (reason) {
        // Handle exceptions here
        return null;
      });
  }
  
  /** Creates an HTML <img /> element with given image data which will be further used by `canvas` to perform rotation */
  function loadImage(data) {
    return new Promise(function (resolve) {
      const img = document.createElement('img');
      img.addEventListener('load', function () {
        resolve(img);
      });
      // Falling back to an empty image in case of any errors
      img.addEventListener('error', function () {
        resolve(img);
      });
  
      // Assign image data
      img.src = data;
    });
  }
  
  /** Rotates the provided image to the given `heading (degrees)`.
   *
   * How we are converting image ?
   * We have created a virtual HTML `canvas` element.
   * Then according to the `obliqueType` we'll rotate the canvas completely.
   * We'll change the canvas origin using ctx.translate()
   * Draw the Image on canvas at (0, 0)
   *
   * Now, we'll simply call canvas.toDataURI(), which will convert the canvas to base64 rotated image URI
   **/
  function rotateImage(ctx, img, tileWidth, tileHeight, heading) {
    /** Canvas accepts radians to rotate, so we need to convert degrees to radians */
    const rotation = degreesToRadians(heading);
    
    switch (heading) {
      case NORTH:
        ctx.translate(0, 0);
        break;
      case SOUTH:
        ctx.translate(tileWidth, tileHeight);
        break;
      case EAST:
        ctx.translate(tileWidth, 0);
        break;
      case WEST:
        ctx.translate(0, tileHeight);
        break;
    }
  
    ctx.rotate(rotation);
    ctx.save();
  
    ctx.drawImage(img, 0, 0);
    ctx.restore();
  }
  
  /** Rotates the nearmap tile */
  function rotateTile(data, heading) {
    const [tileWidth, tileHeight] = TILE_SIZE;
    const canvasAndCtx = createCanvas(tileWidth, tileHeight);
    const [canvas, ctx] = canvasAndCtx;
  
    return loadImage(data).then((img) => {
      rotateImage(ctx, img, tileWidth, tileHeight, heading);
  
      return canvas.toDataURL();
    });
  }
  
  function createCanvas(width, height) {
    const canvas = document.createElement('canvas');
  
    canvas.width = width;
    canvas.height = height;
  
    return [canvas, canvas.getContext('2d')];
  }
  
  /** Fetches nearmap image and rotates it according to selected `obliqueType` */
  export function tileLoadFunction(imageTile, src, obliqueType) {
    let img = imageTile.getImage();
  
    fetchImageData(src).then((imgData) => {
      /** If `obliqueType` is VERT (Top View), we don't need to perform any rotations or any other operations */
      if (imgData && obliqueType !== ObliqueType.VERT) {
        rotateTile(imgData, HEADINGS[obliqueType]).then((rotatedImgData) => img.src = rotatedImgData || '');
      }
  
      img.src = imgData || '';
    });
  }
  