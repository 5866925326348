import React from 'react';

const DragTool = (props: React.SVGProps<SVGElement>) => (
  // @ts-ignore
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      d='M17.947 10.8151L15.8952 9.63023V11.2958L12.7042 11.2959V8.1045H14.3698L13.1849 6.05232L12 4L10.8151 6.05246L9.63022 8.10464H11.2958V11.296L8.1045 11.2959V9.63037L6.05232 10.8153L4 12L6.05246 13.185L8.10464 14.3699V12.7043H11.296V15.8956H9.6305L10.8154 17.9474L12 20L13.185 17.947L14.3699 15.8952H12.7043L12.7042 12.7042H15.8955V14.3697L17.9473 13.1849L20 12L17.947 10.8151ZM12.4877 12.4872H11.5127V11.513H12.4877V12.4872Z'
      fill='#333333'
    />
  </svg>
);

export default DragTool;
